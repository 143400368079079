/*
 Highcharts Gantt JS v10.3.3 (2023-01-20)

 (c) 2017-2021 Lars Cabrera, Torstein Honsi, Jon Arild Nygard & Oystein Moseng

 License: www.highcharts.com/license
*/
(function (W, I) {
  "object" === typeof module && module.exports ? (I["default"] = I, module.exports = W.document ? I(W) : I) : "function" === typeof define && define.amd ? define("highcharts/highcharts-gantt", function () {
    return I(W);
  }) : (W.Highcharts && W.Highcharts.error(16, !0), W.Highcharts = I(W));
})("undefined" !== typeof window ? window : this, function (W) {
  function I(c, g, y, A) {
    c.hasOwnProperty(g) || (c[g] = A.apply(null, y), "function" === typeof CustomEvent && W.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: g,
        module: c[g]
      }
    })));
  }
  var g = {};
  I(g, "Core/Globals.js", [], function () {
    var c;
    (function (c) {
      c.SVG_NS = "http://www.w3.org/2000/svg";
      c.product = "Highcharts";
      c.version = "10.3.3";
      c.win = "undefined" !== typeof W ? W : {};
      c.doc = c.win.document;
      c.svg = c.doc && c.doc.createElementNS && !!c.doc.createElementNS(c.SVG_NS, "svg").createSVGRect;
      c.userAgent = c.win.navigator && c.win.navigator.userAgent || "";
      c.isChrome = -1 !== c.userAgent.indexOf("Chrome");
      c.isFirefox = -1 !== c.userAgent.indexOf("Firefox");
      c.isMS = /(edge|msie|trident)/i.test(c.userAgent) && !c.win.opera;
      c.isSafari = !c.isChrome && -1 !== c.userAgent.indexOf("Safari");
      c.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(c.userAgent);
      c.isWebKit = -1 !== c.userAgent.indexOf("AppleWebKit");
      c.deg2rad = 2 * Math.PI / 360;
      c.hasBidiBug = c.isFirefox && 4 > parseInt(c.userAgent.split("Firefox/")[1], 10);
      c.hasTouch = !!c.win.TouchEvent;
      c.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"];
      c.noop = function () {};
      c.supportsPassiveEvents = function () {
        var g = !1;
        if (!c.isMS) {
          var L = Object.defineProperty({}, "passive", {
            get: function () {
              g = !0;
            }
          });
          c.win.addEventListener && c.win.removeEventListener && (c.win.addEventListener("testPassive", c.noop, L), c.win.removeEventListener("testPassive", c.noop, L));
        }
        return g;
      }();
      c.charts = [];
      c.dateFormats = {};
      c.seriesTypes = {};
      c.symbolSizes = {};
      c.chartCount = 0;
    })(c || (c = {}));
    "";
    return c;
  });
  I(g, "Core/Utilities.js", [g["Core/Globals.js"]], function (c) {
    function g(h, b, d, l) {
      var v = b ? "Highcharts error" : "Highcharts warning";
      32 === h && (h = "" + v + ": Deprecated member");
      var n = r(h),
        J = n ? "" + v + " #" + h + ": www.highcharts.com/errors/" + h + "/" : h.toString();
      if ("undefined" !== typeof l) {
        var E = "";
        n && (J += "?");
        H(l, function (h, b) {
          E += "\n - ".concat(b, ": ").concat(h);
          n && (J += encodeURI(b) + "=" + encodeURI(h));
        });
        J += E;
      }
      u(c, "displayError", {
        chart: d,
        code: h,
        message: J,
        params: l
      }, function () {
        if (b) throw Error(J);
        f.console && -1 === g.messages.indexOf(J) && console.warn(J);
      });
      g.messages.push(J);
    }
    function y(h, b) {
      var d = {};
      H(h, function (v, f) {
        if (C(h[f], !0) && !h.nodeType && b[f]) v = y(h[f], b[f]), Object.keys(v).length && (d[f] = v);else if (C(h[f]) || h[f] !== b[f] || f in h && !(f in b)) d[f] = h[f];
      });
      return d;
    }
    function A(h, b) {
      return parseInt(h, b || 10);
    }
    function B(h) {
      return "string" === typeof h;
    }
    function z(h) {
      h = Object.prototype.toString.call(h);
      return "[object Array]" === h || "[object Array Iterator]" === h;
    }
    function C(h, b) {
      return !!h && "object" === typeof h && (!b || !z(h));
    }
    function x(h) {
      return C(h) && "number" === typeof h.nodeType;
    }
    function t(h) {
      var b = h && h.constructor;
      return !(!C(h, !0) || x(h) || !b || !b.name || "Object" === b.name);
    }
    function r(h) {
      return "number" === typeof h && !isNaN(h) && Infinity > h && -Infinity < h;
    }
    function a(h) {
      return "undefined" !== typeof h && null !== h;
    }
    function q(h, b, d) {
      var v = B(b) && !a(d),
        f,
        l = function (b, d) {
          a(b) ? h.setAttribute(d, b) : v ? (f = h.getAttribute(d)) || "class" !== d || (f = h.getAttribute(d + "Name")) : h.removeAttribute(d);
        };
      B(b) ? l(d, b) : H(b, l);
      return f;
    }
    function m(h, b) {
      var d;
      h || (h = {});
      for (d in b) h[d] = b[d];
      return h;
    }
    function p() {
      for (var h = arguments, b = h.length, d = 0; d < b; d++) {
        var f = h[d];
        if ("undefined" !== typeof f && null !== f) return f;
      }
    }
    function k(h, b) {
      c.isMS && !c.svg && b && a(b.opacity) && (b.filter = "alpha(opacity=".concat(100 * b.opacity, ")"));
      m(h.style, b);
    }
    function e(h) {
      return Math.pow(10, Math.floor(Math.log(h) / Math.LN10));
    }
    function D(h, b) {
      return 1E14 < h ? h : parseFloat(h.toPrecision(b || 14));
    }
    function G(h, b, d) {
      var v = c.getStyle || G;
      if ("width" === b) return b = Math.min(h.offsetWidth, h.scrollWidth), d = h.getBoundingClientRect && h.getBoundingClientRect().width, d < b && d >= b - 1 && (b = Math.floor(d)), Math.max(0, b - (v(h, "padding-left", !0) || 0) - (v(h, "padding-right", !0) || 0));
      if ("height" === b) return Math.max(0, Math.min(h.offsetHeight, h.scrollHeight) - (v(h, "padding-top", !0) || 0) - (v(h, "padding-bottom", !0) || 0));
      f.getComputedStyle || g(27, !0);
      if (h = f.getComputedStyle(h, void 0)) {
        var l = h.getPropertyValue(b);
        p(d, "opacity" !== b) && (l = A(l));
      }
      return l;
    }
    function H(h, b, d) {
      for (var v in h) Object.hasOwnProperty.call(h, v) && b.call(d || h[v], h[v], v, h);
    }
    function K(h, b, d) {
      function v(b, d) {
        var v = h.removeEventListener || c.removeEventListenerPolyfill;
        v && v.call(h, b, d, !1);
      }
      function f(d) {
        var f;
        if (h.nodeName) {
          if (b) {
            var l = {};
            l[b] = !0;
          } else l = d;
          H(l, function (h, b) {
            if (d[b]) for (f = d[b].length; f--;) v(b, d[b][f].fn);
          });
        }
      }
      var l = "function" === typeof h && h.prototype || h;
      if (Object.hasOwnProperty.call(l, "hcEvents")) {
        var n = l.hcEvents;
        b ? (l = n[b] || [], d ? (n[b] = l.filter(function (h) {
          return d !== h.fn;
        }), v(b, d)) : (f(n), n[b] = [])) : (f(n), delete l.hcEvents);
      }
    }
    function u(h, b, d, f) {
      d = d || {};
      if (w.createEvent && (h.dispatchEvent || h.fireEvent && h !== c)) {
        var v = w.createEvent("Events");
        v.initEvent(b, !0, !0);
        d = m(v, d);
        h.dispatchEvent ? h.dispatchEvent(d) : h.fireEvent(b, d);
      } else if (h.hcEvents) {
        d.target || m(d, {
          preventDefault: function () {
            d.defaultPrevented = !0;
          },
          target: h,
          type: b
        });
        v = [];
        for (var l = h, n = !1; l.hcEvents;) Object.hasOwnProperty.call(l, "hcEvents") && l.hcEvents[b] && (v.length && (n = !0), v.unshift.apply(v, l.hcEvents[b])), l = Object.getPrototypeOf(l);
        n && v.sort(function (b, h) {
          return b.order - h.order;
        });
        v.forEach(function (b) {
          !1 === b.fn.call(h, d) && d.preventDefault();
        });
      }
      f && !d.defaultPrevented && f.call(h, d);
    }
    var n = c.charts,
      w = c.doc,
      f = c.win;
    (g || (g = {})).messages = [];
    Math.easeInOutSine = function (b) {
      return -.5 * (Math.cos(Math.PI * b) - 1);
    };
    var l = Array.prototype.find ? function (b, d) {
      return b.find(d);
    } : function (b, d) {
      var h,
        v = b.length;
      for (h = 0; h < v; h++) if (d(b[h], h)) return b[h];
    };
    H({
      map: "map",
      each: "forEach",
      grep: "filter",
      reduce: "reduce",
      some: "some"
    }, function (b, d) {
      c[d] = function (h) {
        var v;
        g(32, !1, void 0, (v = {}, v["Highcharts.".concat(d)] = "use Array.".concat(b), v));
        return Array.prototype[b].apply(h, [].slice.call(arguments, 1));
      };
    });
    var d,
      b = function () {
        var b = Math.random().toString(36).substring(2, 9) + "-",
          v = 0;
        return function () {
          return "highcharts-" + (d ? "" : b) + v++;
        };
      }();
    f.jQuery && (f.jQuery.fn.highcharts = function () {
      var b = [].slice.call(arguments);
      if (this[0]) return b[0] ? (new c[B(b[0]) ? b.shift() : "Chart"](this[0], b[0], b[1]), this) : n[q(this[0], "data-highcharts-chart")];
    });
    l = {
      addEvent: function (b, d, f, l) {
        void 0 === l && (l = {});
        var h = "function" === typeof b && b.prototype || b;
        Object.hasOwnProperty.call(h, "hcEvents") || (h.hcEvents = {});
        h = h.hcEvents;
        c.Point && b instanceof c.Point && b.series && b.series.chart && (b.series.chart.runTrackerClick = !0);
        var v = b.addEventListener || c.addEventListenerPolyfill;
        v && v.call(b, d, f, c.supportsPassiveEvents ? {
          passive: void 0 === l.passive ? -1 !== d.indexOf("touch") : l.passive,
          capture: !1
        } : !1);
        h[d] || (h[d] = []);
        h[d].push({
          fn: f,
          order: "number" === typeof l.order ? l.order : Infinity
        });
        h[d].sort(function (b, h) {
          return b.order - h.order;
        });
        return function () {
          K(b, d, f);
        };
      },
      arrayMax: function (b) {
        for (var h = b.length, d = b[0]; h--;) b[h] > d && (d = b[h]);
        return d;
      },
      arrayMin: function (b) {
        for (var h = b.length, d = b[0]; h--;) b[h] < d && (d = b[h]);
        return d;
      },
      attr: q,
      clamp: function (b, d, f) {
        return b > d ? b < f ? b : f : d;
      },
      cleanRecursively: y,
      clearTimeout: function (b) {
        a(b) && clearTimeout(b);
      },
      correctFloat: D,
      createElement: function (b, d, f, l, n) {
        b = w.createElement(b);
        d && m(b, d);
        n && k(b, {
          padding: "0",
          border: "none",
          margin: "0"
        });
        f && k(b, f);
        l && l.appendChild(b);
        return b;
      },
      css: k,
      defined: a,
      destroyObjectProperties: function (b, d) {
        H(b, function (h, f) {
          h && h !== d && h.destroy && h.destroy();
          delete b[f];
        });
      },
      discardElement: function (b) {
        b && b.parentElement && b.parentElement.removeChild(b);
      },
      erase: function (b, d) {
        for (var h = b.length; h--;) if (b[h] === d) {
          b.splice(h, 1);
          break;
        }
      },
      error: g,
      extend: m,
      extendClass: function (b, d) {
        var h = function () {};
        h.prototype = new b();
        m(h.prototype, d);
        return h;
      },
      find: l,
      fireEvent: u,
      getMagnitude: e,
      getNestedProperty: function (b, d) {
        for (b = b.split("."); b.length && a(d);) {
          var h = b.shift();
          if ("undefined" === typeof h || "__proto__" === h) return;
          d = d[h];
          if (!a(d) || "function" === typeof d || "number" === typeof d.nodeType || d === f) return;
        }
        return d;
      },
      getStyle: G,
      inArray: function (b, d, f) {
        g(32, !1, void 0, {
          "Highcharts.inArray": "use Array.indexOf"
        });
        return d.indexOf(b, f);
      },
      isArray: z,
      isClass: t,
      isDOMElement: x,
      isFunction: function (b) {
        return "function" === typeof b;
      },
      isNumber: r,
      isObject: C,
      isString: B,
      keys: function (b) {
        g(32, !1, void 0, {
          "Highcharts.keys": "use Object.keys"
        });
        return Object.keys(b);
      },
      merge: function () {
        var b,
          d = arguments,
          f = {},
          l = function (b, d) {
            "object" !== typeof b && (b = {});
            H(d, function (h, f) {
              "__proto__" !== f && "constructor" !== f && (!C(h, !0) || t(h) || x(h) ? b[f] = d[f] : b[f] = l(b[f] || {}, h));
            });
            return b;
          };
        !0 === d[0] && (f = d[1], d = Array.prototype.slice.call(d, 2));
        var n = d.length;
        for (b = 0; b < n; b++) f = l(f, d[b]);
        return f;
      },
      normalizeTickInterval: function (b, d, f, l, n) {
        var h = b;
        f = p(f, e(b));
        var v = b / f;
        d || (d = n ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === l && (1 === f ? d = d.filter(function (b) {
          return 0 === b % 1;
        }) : .1 >= f && (d = [1 / f])));
        for (l = 0; l < d.length && !(h = d[l], n && h * f >= b || !n && v <= (d[l] + (d[l + 1] || d[l])) / 2); l++);
        return h = D(h * f, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: H,
      offset: function (b) {
        var d = w.documentElement;
        b = b.parentElement || b.parentNode ? b.getBoundingClientRect() : {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        };
        return {
          top: b.top + (f.pageYOffset || d.scrollTop) - (d.clientTop || 0),
          left: b.left + (f.pageXOffset || d.scrollLeft) - (d.clientLeft || 0),
          width: b.width,
          height: b.height
        };
      },
      pad: function (b, d, f) {
        return Array((d || 2) + 1 - String(b).replace("-", "").length).join(f || "0") + b;
      },
      pick: p,
      pInt: A,
      relativeLength: function (b, d, f) {
        return /%$/.test(b) ? d * parseFloat(b) / 100 + (f || 0) : parseFloat(b);
      },
      removeEvent: K,
      splat: function (b) {
        return z(b) ? b : [b];
      },
      stableSort: function (b, d) {
        var h = b.length,
          f,
          l;
        for (l = 0; l < h; l++) b[l].safeI = l;
        b.sort(function (b, h) {
          f = d(b, h);
          return 0 === f ? b.safeI - h.safeI : f;
        });
        for (l = 0; l < h; l++) delete b[l].safeI;
      },
      syncTimeout: function (b, d, f) {
        if (0 < d) return setTimeout(b, d, f);
        b.call(0, f);
        return -1;
      },
      timeUnits: {
        millisecond: 1,
        second: 1E3,
        minute: 6E4,
        hour: 36E5,
        day: 864E5,
        week: 6048E5,
        month: 24192E5,
        year: 314496E5
      },
      uniqueKey: b,
      useSerialIds: function (b) {
        return d = p(b, d);
      },
      wrap: function (b, d, f) {
        var h = b[d];
        b[d] = function () {
          var b = arguments,
            d = this;
          return f.apply(this, [function () {
            return h.apply(d, arguments.length ? arguments : b);
          }].concat([].slice.call(arguments)));
        };
      }
    };
    "";
    return l;
  });
  I(g, "Core/Chart/ChartDefaults.js", [], function () {
    return {
      alignThresholds: !1,
      panning: {
        enabled: !1,
        type: "x"
      },
      styledMode: !1,
      borderRadius: 0,
      colorCount: 10,
      allowMutatingData: !0,
      defaultSeriesType: "line",
      ignoreHiddenSeries: !0,
      spacing: [10, 10, 15, 10],
      resetZoomButton: {
        theme: {
          zIndex: 6
        },
        position: {
          align: "right",
          x: -10,
          y: 10
        }
      },
      zoomBySingleTouch: !1,
      zooming: {
        singleTouch: !1,
        resetButton: {
          theme: {
            zIndex: 6
          },
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        }
      },
      width: null,
      height: null,
      borderColor: "#335cad",
      backgroundColor: "#ffffff",
      plotBorderColor: "#cccccc"
    };
  });
  I(g, "Core/Color/Color.js", [g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g) {
    var L = g.isNumber,
      A = g.merge,
      B = g.pInt;
    g = function () {
      function g(C) {
        this.rgba = [NaN, NaN, NaN, NaN];
        this.input = C;
        var x = c.Color;
        if (x && x !== g) return new x(C);
        if (!(this instanceof g)) return new g(C);
        this.init(C);
      }
      g.parse = function (c) {
        return c ? new g(c) : g.None;
      };
      g.prototype.init = function (c) {
        var x;
        if ("object" === typeof c && "undefined" !== typeof c.stops) this.stops = c.stops.map(function (a) {
          return new g(a[1]);
        });else if ("string" === typeof c) {
          this.input = c = g.names[c.toLowerCase()] || c;
          if ("#" === c.charAt(0)) {
            var t = c.length;
            var r = parseInt(c.substr(1), 16);
            7 === t ? x = [(r & 16711680) >> 16, (r & 65280) >> 8, r & 255, 1] : 4 === t && (x = [(r & 3840) >> 4 | (r & 3840) >> 8, (r & 240) >> 4 | r & 240, (r & 15) << 4 | r & 15, 1]);
          }
          if (!x) for (r = g.parsers.length; r-- && !x;) {
            var a = g.parsers[r];
            (t = a.regex.exec(c)) && (x = a.parse(t));
          }
        }
        x && (this.rgba = x);
      };
      g.prototype.get = function (c) {
        var x = this.input,
          t = this.rgba;
        if ("object" === typeof x && "undefined" !== typeof this.stops) {
          var r = A(x);
          r.stops = [].slice.call(r.stops);
          this.stops.forEach(function (a, q) {
            r.stops[q] = [r.stops[q][0], a.get(c)];
          });
          return r;
        }
        return t && L(t[0]) ? "rgb" === c || !c && 1 === t[3] ? "rgb(" + t[0] + "," + t[1] + "," + t[2] + ")" : "a" === c ? "".concat(t[3]) : "rgba(" + t.join(",") + ")" : x;
      };
      g.prototype.brighten = function (c) {
        var x = this.rgba;
        if (this.stops) this.stops.forEach(function (r) {
          r.brighten(c);
        });else if (L(c) && 0 !== c) for (var t = 0; 3 > t; t++) x[t] += B(255 * c), 0 > x[t] && (x[t] = 0), 255 < x[t] && (x[t] = 255);
        return this;
      };
      g.prototype.setOpacity = function (c) {
        this.rgba[3] = c;
        return this;
      };
      g.prototype.tweenTo = function (c, x) {
        var t = this.rgba,
          r = c.rgba;
        if (!L(t[0]) || !L(r[0])) return c.input || "none";
        c = 1 !== r[3] || 1 !== t[3];
        return (c ? "rgba(" : "rgb(") + Math.round(r[0] + (t[0] - r[0]) * (1 - x)) + "," + Math.round(r[1] + (t[1] - r[1]) * (1 - x)) + "," + Math.round(r[2] + (t[2] - r[2]) * (1 - x)) + (c ? "," + (r[3] + (t[3] - r[3]) * (1 - x)) : "") + ")";
      };
      g.names = {
        white: "#ffffff",
        black: "#000000"
      };
      g.parsers = [{
        regex: /rgba\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]?(?:\.[0-9]+)?)\s*\)/,
        parse: function (c) {
          return [B(c[1]), B(c[2]), B(c[3]), parseFloat(c[4], 10)];
        }
      }, {
        regex: /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/,
        parse: function (c) {
          return [B(c[1]), B(c[2]), B(c[3]), 1];
        }
      }];
      g.None = new g("");
      return g;
    }();
    "";
    return g;
  });
  I(g, "Core/Color/Palettes.js", [], function () {
    return {
      colors: "#7cb5ec #434348 #90ed7d #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b #91e8e1".split(" ")
    };
  });
  I(g, "Core/Time.js", [g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g) {
    var L = c.win,
      A = g.defined,
      B = g.error,
      z = g.extend,
      C = g.isObject,
      x = g.merge,
      t = g.objectEach,
      r = g.pad,
      a = g.pick,
      q = g.splat,
      m = g.timeUnits,
      p = c.isSafari && L.Intl && L.Intl.DateTimeFormat.prototype.formatRange,
      k = c.isSafari && L.Intl && !L.Intl.DateTimeFormat.prototype.formatRange;
    g = function () {
      function e(e) {
        this.options = {};
        this.variableTimezone = this.useUTC = !1;
        this.Date = L.Date;
        this.getTimezoneOffset = this.timezoneOffsetFunction();
        this.update(e);
      }
      e.prototype.get = function (e, k) {
        if (this.variableTimezone || this.timezoneOffset) {
          var D = k.getTime(),
            p = D - this.getTimezoneOffset(k);
          k.setTime(p);
          e = k["getUTC" + e]();
          k.setTime(D);
          return e;
        }
        return this.useUTC ? k["getUTC" + e]() : k["get" + e]();
      };
      e.prototype.set = function (e, k, a) {
        if (this.variableTimezone || this.timezoneOffset) {
          if ("Milliseconds" === e || "Seconds" === e || "Minutes" === e && 0 === this.getTimezoneOffset(k) % 36E5) return k["setUTC" + e](a);
          var D = this.getTimezoneOffset(k);
          D = k.getTime() - D;
          k.setTime(D);
          k["setUTC" + e](a);
          e = this.getTimezoneOffset(k);
          D = k.getTime() + e;
          return k.setTime(D);
        }
        return this.useUTC || p && "FullYear" === e ? k["setUTC" + e](a) : k["set" + e](a);
      };
      e.prototype.update = function (e) {
        void 0 === e && (e = {});
        var k = a(e.useUTC, !0);
        this.options = e = x(!0, this.options, e);
        this.Date = e.Date || L.Date || Date;
        this.timezoneOffset = (this.useUTC = k) && e.timezoneOffset || void 0;
        this.getTimezoneOffset = this.timezoneOffsetFunction();
        this.variableTimezone = k && !(!e.getTimezoneOffset && !e.timezone);
      };
      e.prototype.makeTime = function (e, p, m, q, u, n) {
        if (this.useUTC) {
          var w = this.Date.UTC.apply(0, arguments);
          var f = this.getTimezoneOffset(w);
          w += f;
          var l = this.getTimezoneOffset(w);
          f !== l ? w += l - f : f - 36E5 !== this.getTimezoneOffset(w - 36E5) || k || (w -= 36E5);
        } else w = new this.Date(e, p, a(m, 1), a(q, 0), a(u, 0), a(n, 0)).getTime();
        return w;
      };
      e.prototype.timezoneOffsetFunction = function () {
        var e = this,
          k = this.options,
          p = k.getTimezoneOffset,
          a = k.moment || L.moment;
        if (!this.useUTC) return function (e) {
          return 6E4 * new Date(e.toString()).getTimezoneOffset();
        };
        if (k.timezone) {
          if (a) return function (e) {
            return 6E4 * -a.tz(e, k.timezone).utcOffset();
          };
          B(25);
        }
        return this.useUTC && p ? function (e) {
          return 6E4 * p(e.valueOf());
        } : function () {
          return 6E4 * (e.timezoneOffset || 0);
        };
      };
      e.prototype.dateFormat = function (e, k, p) {
        if (!A(k) || isNaN(k)) return c.defaultOptions.lang && c.defaultOptions.lang.invalidDate || "";
        e = a(e, "%Y-%m-%d %H:%M:%S");
        var D = this,
          u = new this.Date(k),
          n = this.get("Hours", u),
          w = this.get("Day", u),
          f = this.get("Date", u),
          l = this.get("Month", u),
          d = this.get("FullYear", u),
          b = c.defaultOptions.lang,
          h = b && b.weekdays,
          v = b && b.shortWeekdays;
        u = z({
          a: v ? v[w] : h[w].substr(0, 3),
          A: h[w],
          d: r(f),
          e: r(f, 2, " "),
          w: w,
          b: b.shortMonths[l],
          B: b.months[l],
          m: r(l + 1),
          o: l + 1,
          y: d.toString().substr(2, 2),
          Y: d,
          H: r(n),
          k: n,
          I: r(n % 12 || 12),
          l: n % 12 || 12,
          M: r(this.get("Minutes", u)),
          p: 12 > n ? "AM" : "PM",
          P: 12 > n ? "am" : "pm",
          S: r(u.getSeconds()),
          L: r(Math.floor(k % 1E3), 3)
        }, c.dateFormats);
        t(u, function (b, d) {
          for (; -1 !== e.indexOf("%" + d);) e = e.replace("%" + d, "function" === typeof b ? b.call(D, k) : b);
        });
        return p ? e.substr(0, 1).toUpperCase() + e.substr(1) : e;
      };
      e.prototype.resolveDTLFormat = function (e) {
        return C(e, !0) ? e : (e = q(e), {
          main: e[0],
          from: e[1],
          to: e[2]
        });
      };
      e.prototype.getTimeTicks = function (e, k, p, q) {
        var u = this,
          n = [],
          w = {},
          f = new u.Date(k),
          l = e.unitRange,
          d = e.count || 1,
          b;
        q = a(q, 1);
        if (A(k)) {
          u.set("Milliseconds", f, l >= m.second ? 0 : d * Math.floor(u.get("Milliseconds", f) / d));
          l >= m.second && u.set("Seconds", f, l >= m.minute ? 0 : d * Math.floor(u.get("Seconds", f) / d));
          l >= m.minute && u.set("Minutes", f, l >= m.hour ? 0 : d * Math.floor(u.get("Minutes", f) / d));
          l >= m.hour && u.set("Hours", f, l >= m.day ? 0 : d * Math.floor(u.get("Hours", f) / d));
          l >= m.day && u.set("Date", f, l >= m.month ? 1 : Math.max(1, d * Math.floor(u.get("Date", f) / d)));
          if (l >= m.month) {
            u.set("Month", f, l >= m.year ? 0 : d * Math.floor(u.get("Month", f) / d));
            var h = u.get("FullYear", f);
          }
          l >= m.year && u.set("FullYear", f, h - h % d);
          l === m.week && (h = u.get("Day", f), u.set("Date", f, u.get("Date", f) - h + q + (h < q ? -7 : 0)));
          h = u.get("FullYear", f);
          q = u.get("Month", f);
          var v = u.get("Date", f),
            E = u.get("Hours", f);
          k = f.getTime();
          !u.variableTimezone && u.useUTC || !A(p) || (b = p - k > 4 * m.month || u.getTimezoneOffset(k) !== u.getTimezoneOffset(p));
          k = f.getTime();
          for (f = 1; k < p;) n.push(k), k = l === m.year ? u.makeTime(h + f * d, 0) : l === m.month ? u.makeTime(h, q + f * d) : !b || l !== m.day && l !== m.week ? b && l === m.hour && 1 < d ? u.makeTime(h, q, v, E + f * d) : k + l * d : u.makeTime(h, q, v + f * d * (l === m.day ? 1 : 7)), f++;
          n.push(k);
          l <= m.hour && 1E4 > n.length && n.forEach(function (b) {
            0 === b % 18E5 && "000000000" === u.dateFormat("%H%M%S%L", b) && (w[b] = "day");
          });
        }
        n.info = z(e, {
          higherRanks: w,
          totalRange: l * d
        });
        return n;
      };
      e.prototype.getDateFormat = function (e, k, p, a) {
        var u = this.dateFormat("%m-%d %H:%M:%S.%L", k),
          n = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          w = "millisecond";
        for (f in m) {
          if (e === m.week && +this.dateFormat("%w", k) === p && "00:00:00.000" === u.substr(6)) {
            var f = "week";
            break;
          }
          if (m[f] > e) {
            f = w;
            break;
          }
          if (n[f] && u.substr(n[f]) !== "01-01 00:00:00.000".substr(n[f])) break;
          "week" !== f && (w = f);
        }
        return this.resolveDTLFormat(a[f]).main;
      };
      return e;
    }();
    "";
    return g;
  });
  I(g, "Core/Defaults.js", [g["Core/Chart/ChartDefaults.js"], g["Core/Color/Color.js"], g["Core/Globals.js"], g["Core/Color/Palettes.js"], g["Core/Time.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z) {
    g = g.parse;
    var C = z.merge,
      x = {
        colors: A.colors,
        symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
        lang: {
          loading: "Loading...",
          months: "January February March April May June July August September October November December".split(" "),
          shortMonths: "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),
          weekdays: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
          decimalPoint: ".",
          numericSymbols: "kMGTPE".split(""),
          resetZoom: "Reset zoom",
          resetZoomTitle: "Reset zoom level 1:1",
          thousandsSep: " "
        },
        global: {},
        time: {
          Date: void 0,
          getTimezoneOffset: void 0,
          timezone: void 0,
          timezoneOffset: 0,
          useUTC: !0
        },
        chart: c,
        title: {
          text: "Chart title",
          align: "center",
          margin: 15,
          widthAdjust: -44
        },
        subtitle: {
          text: "",
          align: "center",
          widthAdjust: -44
        },
        caption: {
          margin: 15,
          text: "",
          align: "left",
          verticalAlign: "bottom"
        },
        plotOptions: {},
        labels: {
          style: {
            position: "absolute",
            color: "#333333"
          }
        },
        legend: {
          enabled: !0,
          align: "center",
          alignColumns: !0,
          className: "highcharts-no-tooltip",
          layout: "horizontal",
          labelFormatter: function () {
            return this.name;
          },
          borderColor: "#999999",
          borderRadius: 0,
          navigation: {
            activeColor: "#003399",
            inactiveColor: "#cccccc"
          },
          itemStyle: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis"
          },
          itemHoverStyle: {
            color: "#000000"
          },
          itemHiddenStyle: {
            color: "#cccccc"
          },
          shadow: !1,
          itemCheckboxStyle: {
            position: "absolute",
            width: "13px",
            height: "13px"
          },
          squareSymbol: !0,
          symbolPadding: 5,
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          title: {
            style: {
              fontWeight: "bold"
            }
          }
        },
        loading: {
          labelStyle: {
            fontWeight: "bold",
            position: "relative",
            top: "45%"
          },
          style: {
            position: "absolute",
            backgroundColor: "#ffffff",
            opacity: .5,
            textAlign: "center"
          }
        },
        tooltip: {
          enabled: !0,
          animation: y.svg,
          borderRadius: 3,
          dateTimeLabelFormats: {
            millisecond: "%A, %b %e, %H:%M:%S.%L",
            second: "%A, %b %e, %H:%M:%S",
            minute: "%A, %b %e, %H:%M",
            hour: "%A, %b %e, %H:%M",
            day: "%A, %b %e, %Y",
            week: "Week from %A, %b %e, %Y",
            month: "%B %Y",
            year: "%Y"
          },
          footerFormat: "",
          headerShape: "callout",
          hideDelay: 500,
          padding: 8,
          shape: "callout",
          shared: !1,
          snap: y.isTouchDevice ? 25 : 10,
          headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">\u25cf</span> {series.name}: <b>{point.y}</b><br/>',
          backgroundColor: g("#f7f7f7").setOpacity(.85).get(),
          borderWidth: 1,
          shadow: !0,
          stickOnContact: !1,
          style: {
            color: "#333333",
            cursor: "default",
            fontSize: "12px",
            whiteSpace: "nowrap"
          },
          useHTML: !1
        },
        credits: {
          enabled: !0,
          href: "https://www.highcharts.com?credits",
          position: {
            align: "right",
            x: -10,
            verticalAlign: "bottom",
            y: -5
          },
          style: {
            cursor: "pointer",
            color: "#999999",
            fontSize: "9px"
          },
          text: "Highcharts.com"
        }
      };
    x.chart.styledMode = !1;
    "";
    var t = new B(C(x.global, x.time));
    c = {
      defaultOptions: x,
      defaultTime: t,
      getOptions: function () {
        return x;
      },
      setOptions: function (r) {
        C(!0, x, r);
        if (r.time || r.global) y.time ? y.time.update(C(x.global, x.time, r.global, r.time)) : y.time = t;
        return x;
      }
    };
    "";
    return c;
  });
  I(g, "Core/Animation/Fx.js", [g["Core/Color/Color.js"], g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var L = c.parse,
      B = g.win,
      z = y.isNumber,
      C = y.objectEach;
    return function () {
      function c(t, r, a) {
        this.pos = NaN;
        this.options = r;
        this.elem = t;
        this.prop = a;
      }
      c.prototype.dSetter = function () {
        var t = this.paths,
          r = t && t[0];
        t = t && t[1];
        var a = this.now || 0,
          q = [];
        if (1 !== a && r && t) {
          if (r.length === t.length && 1 > a) for (var m = 0; m < t.length; m++) {
            for (var p = r[m], k = t[m], e = [], D = 0; D < k.length; D++) {
              var G = p[D],
                c = k[D];
              z(G) && z(c) && ("A" !== k[0] || 4 !== D && 5 !== D) ? e[D] = G + a * (c - G) : e[D] = c;
            }
            q.push(e);
          } else q = t;
        } else q = this.toD || [];
        this.elem.attr("d", q, void 0, !0);
      };
      c.prototype.update = function () {
        var t = this.elem,
          r = this.prop,
          a = this.now,
          q = this.options.step;
        if (this[r + "Setter"]) this[r + "Setter"]();else t.attr ? t.element && t.attr(r, a, null, !0) : t.style[r] = a + this.unit;
        q && q.call(t, a, this);
      };
      c.prototype.run = function (t, r, a) {
        var q = this,
          m = q.options,
          p = function (e) {
            return p.stopped ? !1 : q.step(e);
          },
          k = B.requestAnimationFrame || function (e) {
            setTimeout(e, 13);
          },
          e = function () {
            for (var p = 0; p < c.timers.length; p++) c.timers[p]() || c.timers.splice(p--, 1);
            c.timers.length && k(e);
          };
        t !== r || this.elem["forceAnimate:" + this.prop] ? (this.startTime = +new Date(), this.start = t, this.end = r, this.unit = a, this.now = this.start, this.pos = 0, p.elem = this.elem, p.prop = this.prop, p() && 1 === c.timers.push(p) && k(e)) : (delete m.curAnim[this.prop], m.complete && 0 === Object.keys(m.curAnim).length && m.complete.call(this.elem));
      };
      c.prototype.step = function (t) {
        var r = +new Date(),
          a = this.options,
          q = this.elem,
          m = a.complete,
          p = a.duration,
          k = a.curAnim;
        if (q.attr && !q.element) t = !1;else if (t || r >= p + this.startTime) {
          this.now = this.end;
          this.pos = 1;
          this.update();
          var e = k[this.prop] = !0;
          C(k, function (k) {
            !0 !== k && (e = !1);
          });
          e && m && m.call(q);
          t = !1;
        } else this.pos = a.easing((r - this.startTime) / p), this.now = this.start + (this.end - this.start) * this.pos, this.update(), t = !0;
        return t;
      };
      c.prototype.initPath = function (t, r, a) {
        function q(k, n) {
          for (; k.length < x;) {
            var w = k[0],
              f = n[x - k.length];
            f && "M" === w[0] && (k[0] = "C" === f[0] ? ["C", w[1], w[2], w[1], w[2], w[1], w[2]] : ["L", w[1], w[2]]);
            k.unshift(w);
            e && (w = k.pop(), k.push(k[k.length - 1], w));
          }
        }
        function m(k, n) {
          for (; k.length < x;) if (n = k[Math.floor(k.length / D) - 1].slice(), "C" === n[0] && (n[1] = n[5], n[2] = n[6]), e) {
            var w = k[Math.floor(k.length / D)].slice();
            k.splice(k.length / 2, 0, n, w);
          } else k.push(n);
        }
        var p = t.startX,
          k = t.endX;
        a = a.slice();
        var e = t.isArea,
          D = e ? 2 : 1;
        r = r && r.slice();
        if (!r) return [a, a];
        if (p && k && k.length) {
          for (t = 0; t < p.length; t++) if (p[t] === k[0]) {
            var G = t;
            break;
          } else if (p[0] === k[k.length - p.length + t]) {
            G = t;
            var c = !0;
            break;
          } else if (p[p.length - 1] === k[k.length - p.length + t]) {
            G = p.length - t;
            break;
          }
          "undefined" === typeof G && (r = []);
        }
        if (r.length && z(G)) {
          var x = a.length + G * D;
          c ? (q(r, a), m(a, r)) : (q(a, r), m(r, a));
        }
        return [r, a];
      };
      c.prototype.fillSetter = function () {
        c.prototype.strokeSetter.apply(this, arguments);
      };
      c.prototype.strokeSetter = function () {
        this.elem.attr(this.prop, L(this.start).tweenTo(L(this.end), this.pos), void 0, !0);
      };
      c.timers = [];
      return c;
    }();
  });
  I(g, "Core/Animation/AnimationUtilities.js", [g["Core/Animation/Fx.js"], g["Core/Utilities.js"]], function (c, g) {
    function L(a) {
      return t(a) ? r({
        duration: 500,
        defer: 0
      }, a) : {
        duration: a ? 500 : 0,
        defer: 0
      };
    }
    function A(a, p) {
      for (var k = c.timers.length; k--;) c.timers[k].elem !== a || p && p !== c.timers[k].prop || (c.timers[k].stopped = !0);
    }
    var B = g.defined,
      z = g.getStyle,
      C = g.isArray,
      x = g.isNumber,
      t = g.isObject,
      r = g.merge,
      a = g.objectEach,
      q = g.pick;
    return {
      animate: function (q, p, k) {
        var e,
          D = "",
          m,
          H;
        if (!t(k)) {
          var g = arguments;
          k = {
            duration: g[2],
            easing: g[3],
            complete: g[4]
          };
        }
        x(k.duration) || (k.duration = 400);
        k.easing = "function" === typeof k.easing ? k.easing : Math[k.easing] || Math.easeInOutSine;
        k.curAnim = r(p);
        a(p, function (u, n) {
          A(q, n);
          H = new c(q, k, n);
          m = void 0;
          "d" === n && C(p.d) ? (H.paths = H.initPath(q, q.pathArray, p.d), H.toD = p.d, e = 0, m = 1) : q.attr ? e = q.attr(n) : (e = parseFloat(z(q, n)) || 0, "opacity" !== n && (D = "px"));
          m || (m = u);
          "string" === typeof m && m.match("px") && (m = m.replace(/px/g, ""));
          H.run(e, m, D);
        });
      },
      animObject: L,
      getDeferredAnimation: function (a, p, k) {
        var e = L(p),
          q = 0,
          m = 0;
        (k ? [k] : a.series).forEach(function (k) {
          k = L(k.options.animation);
          q = p && B(p.defer) ? e.defer : Math.max(q, k.duration + k.defer);
          m = Math.min(e.duration, k.duration);
        });
        a.renderer.forExport && (q = 0);
        return {
          defer: Math.max(0, q - m),
          duration: Math.min(q, m)
        };
      },
      setAnimation: function (a, p) {
        p.renderer.globalAnimation = q(a, p.options.chart.animation, !0);
      },
      stop: A
    };
  });
  I(g, "Core/Renderer/HTML/AST.js", [g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g) {
    var L = c.SVG_NS,
      A = g.attr,
      B = g.createElement,
      z = g.css,
      C = g.error,
      x = g.isFunction,
      t = g.isString,
      r = g.objectEach,
      a = g.splat,
      q = (g = c.win.trustedTypes) && x(g.createPolicy) && g.createPolicy("highcharts", {
        createHTML: function (k) {
          return k;
        }
      }),
      m = q ? q.createHTML("") : "";
    try {
      var p = !!new DOMParser().parseFromString(m, "text/html");
    } catch (k) {
      p = !1;
    }
    x = function () {
      function k(e) {
        this.nodes = "string" === typeof e ? this.parseMarkup(e) : e;
      }
      k.filterUserAttributes = function (e) {
        r(e, function (p, a) {
          var q = !0;
          -1 === k.allowedAttributes.indexOf(a) && (q = !1);
          -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(a) && (q = t(p) && k.allowedReferences.some(function (e) {
            return 0 === p.indexOf(e);
          }));
          q || (C(33, !1, void 0, {
            "Invalid attribute in config": "".concat(a)
          }), delete e[a]);
          t(p) && e[a] && (e[a] = p.replace(/</g, "&lt;"));
        });
        return e;
      };
      k.parseStyle = function (e) {
        return e.split(";").reduce(function (e, k) {
          k = k.split(":").map(function (e) {
            return e.trim();
          });
          var p = k.shift();
          p && k.length && (e[p.replace(/-([a-z])/g, function (e) {
            return e[1].toUpperCase();
          })] = k.join(":"));
          return e;
        }, {});
      };
      k.setElementHTML = function (e, p) {
        e.innerHTML = k.emptyHTML;
        p && new k(p).addToDOM(e);
      };
      k.prototype.addToDOM = function (e) {
        function p(e, q) {
          var m;
          a(e).forEach(function (e) {
            var n = e.tagName,
              w = e.textContent ? c.doc.createTextNode(e.textContent) : void 0,
              f = k.bypassHTMLFiltering;
            if (n) if ("#text" === n) var l = w;else if (-1 !== k.allowedTags.indexOf(n) || f) {
              n = c.doc.createElementNS("svg" === n ? L : q.namespaceURI || L, n);
              var d = e.attributes || {};
              r(e, function (b, h) {
                "tagName" !== h && "attributes" !== h && "children" !== h && "style" !== h && "textContent" !== h && (d[h] = b);
              });
              A(n, f ? d : k.filterUserAttributes(d));
              e.style && z(n, e.style);
              w && n.appendChild(w);
              p(e.children || [], n);
              l = n;
            } else C(33, !1, void 0, {
              "Invalid tagName in config": n
            });
            l && q.appendChild(l);
            m = l;
          });
          return m;
        }
        return p(this.nodes, e);
      };
      k.prototype.parseMarkup = function (e) {
        var a = [];
        e = e.trim().replace(/ style=(["'])/g, " data-style=$1");
        if (p) e = new DOMParser().parseFromString(q ? q.createHTML(e) : e, "text/html");else {
          var m = B("div");
          m.innerHTML = e;
          e = {
            body: m
          };
        }
        var r = function (e, p) {
          var n = e.nodeName.toLowerCase(),
            w = {
              tagName: n
            };
          "#text" === n && (w.textContent = e.textContent || "");
          if (n = e.attributes) {
            var f = {};
            [].forEach.call(n, function (d) {
              "data-style" === d.name ? w.style = k.parseStyle(d.value) : f[d.name] = d.value;
            });
            w.attributes = f;
          }
          if (e.childNodes.length) {
            var l = [];
            [].forEach.call(e.childNodes, function (d) {
              r(d, l);
            });
            l.length && (w.children = l);
          }
          p.push(w);
        };
        [].forEach.call(e.body.childNodes, function (e) {
          return r(e, a);
        });
        return a;
      };
      k.allowedAttributes = "aria-controls aria-describedby aria-expanded aria-haspopup aria-hidden aria-label aria-labelledby aria-live aria-pressed aria-readonly aria-roledescription aria-selected class clip-path color colspan cx cy d dx dy disabled fill height href id in markerHeight markerWidth offset opacity orient padding paddingLeft paddingRight patternUnits r refX refY role scope slope src startOffset stdDeviation stroke stroke-linecap stroke-width style tableValues result rowspan summary target tabindex text-align text-anchor textAnchor textLength title type valign width x x1 x2 xlink:href y y1 y2 zIndex".split(" ");
      k.allowedReferences = "https:// http:// mailto: / ../ ./ #".split(" ");
      k.allowedTags = "a abbr b br button caption circle clipPath code dd defs div dl dt em feComponentTransfer feFuncA feFuncB feFuncG feFuncR feGaussianBlur feOffset feMerge feMergeNode filter h1 h2 h3 h4 h5 h6 hr i img li linearGradient marker ol p path pattern pre rect small span stop strong style sub sup svg table text textPath thead title tbody tspan td th tr u ul #text".split(" ");
      k.emptyHTML = m;
      k.bypassHTMLFiltering = !1;
      return k;
    }();
    "";
    return x;
  });
  I(g, "Core/FormatUtilities.js", [g["Core/Defaults.js"], g["Core/Utilities.js"]], function (c, g) {
    function L(r, a, q, m) {
      r = +r || 0;
      a = +a;
      var p = A.lang,
        k = (r.toString().split(".")[1] || "").split("e")[0].length,
        e = r.toString().split("e"),
        D = a;
      if (-1 === a) a = Math.min(k, 20);else if (!C(a)) a = 2;else if (a && e[1] && 0 > e[1]) {
        var G = a + +e[1];
        0 <= G ? (e[0] = (+e[0]).toExponential(G).split("e")[0], a = G) : (e[0] = e[0].split(".")[0] || 0, r = 20 > a ? (e[0] * Math.pow(10, e[1])).toFixed(a) : 0, e[1] = 0);
      }
      G = (Math.abs(e[1] ? e[0] : r) + Math.pow(10, -Math.max(a, k) - 1)).toFixed(a);
      k = String(t(G));
      var c = 3 < k.length ? k.length % 3 : 0;
      q = x(q, p.decimalPoint);
      m = x(m, p.thousandsSep);
      r = (0 > r ? "-" : "") + (c ? k.substr(0, c) + m : "");
      r = 0 > +e[1] && !D ? "0" : r + k.substr(c).replace(/(\d{3})(?=\d)/g, "$1" + m);
      a && (r += q + G.slice(-a));
      e[1] && 0 !== +r && (r += "e" + e[1]);
      return r;
    }
    var A = c.defaultOptions,
      B = c.defaultTime,
      z = g.getNestedProperty,
      C = g.isNumber,
      x = g.pick,
      t = g.pInt;
    return {
      dateFormat: function (r, a, q) {
        return B.dateFormat(r, a, q);
      },
      format: function (r, a, q) {
        var m = "{",
          p = !1,
          k = /f$/,
          e = /\.([0-9])/,
          D = A.lang,
          G = q && q.time || B;
        q = q && q.numberFormatter || L;
        for (var t = []; r;) {
          var c = r.indexOf(m);
          if (-1 === c) break;
          var u = r.slice(0, c);
          if (p) {
            u = u.split(":");
            m = z(u.shift() || "", a);
            if (u.length && "number" === typeof m) if (u = u.join(":"), k.test(u)) {
              var n = parseInt((u.match(e) || ["", "-1"])[1], 10);
              null !== m && (m = q(m, n, D.decimalPoint, -1 < u.indexOf(",") ? D.thousandsSep : ""));
            } else m = G.dateFormat(u, m);
            t.push(m);
          } else t.push(u);
          r = r.slice(c + 1);
          m = (p = !p) ? "}" : "{";
        }
        t.push(r);
        return t.join("");
      },
      numberFormat: L
    };
  });
  I(g, "Core/Renderer/RendererUtilities.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.clamp,
      y = c.pick,
      A = c.stableSort,
      B;
    (function (c) {
      function C(c, t, r) {
        var a = c,
          q = a.reducedLen || t,
          m = function (e, k) {
            return (k.rank || 0) - (e.rank || 0);
          },
          p = function (e, k) {
            return e.target - k.target;
          },
          k,
          e = !0,
          D = [],
          G = 0;
        for (k = c.length; k--;) G += c[k].size;
        if (G > q) {
          A(c, m);
          for (G = k = 0; G <= q;) G += c[k].size, k++;
          D = c.splice(k - 1, c.length);
        }
        A(c, p);
        for (c = c.map(function (e) {
          return {
            size: e.size,
            targets: [e.target],
            align: y(e.align, .5)
          };
        }); e;) {
          for (k = c.length; k--;) q = c[k], m = (Math.min.apply(0, q.targets) + Math.max.apply(0, q.targets)) / 2, q.pos = g(m - q.size * q.align, 0, t - q.size);
          k = c.length;
          for (e = !1; k--;) 0 < k && c[k - 1].pos + c[k - 1].size > c[k].pos && (c[k - 1].size += c[k].size, c[k - 1].targets = c[k - 1].targets.concat(c[k].targets), c[k - 1].align = .5, c[k - 1].pos + c[k - 1].size > t && (c[k - 1].pos = t - c[k - 1].size), c.splice(k, 1), e = !0);
        }
        a.push.apply(a, D);
        k = 0;
        c.some(function (e) {
          var p = 0;
          return (e.targets || []).some(function () {
            a[k].pos = e.pos + p;
            if ("undefined" !== typeof r && Math.abs(a[k].pos - a[k].target) > r) return a.slice(0, k + 1).forEach(function (e) {
              return delete e.pos;
            }), a.reducedLen = (a.reducedLen || t) - .1 * t, a.reducedLen > .1 * t && C(a, t, r), !0;
            p += a[k].size;
            k++;
            return !1;
          });
        });
        A(a, p);
        return a;
      }
      c.distribute = C;
    })(B || (B = {}));
    return B;
  });
  I(g, "Core/Renderer/SVG/SVGElement.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Color/Color.js"], g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var L = c.animate,
      z = c.animObject,
      C = c.stop,
      x = y.deg2rad,
      t = y.doc,
      r = y.svg,
      a = y.SVG_NS,
      q = y.win,
      m = A.addEvent,
      p = A.attr,
      k = A.createElement,
      e = A.css,
      D = A.defined,
      G = A.erase,
      H = A.extend,
      K = A.fireEvent,
      u = A.isArray,
      n = A.isFunction,
      w = A.isString,
      f = A.merge,
      l = A.objectEach,
      d = A.pick,
      b = A.pInt,
      h = A.syncTimeout,
      v = A.uniqueKey;
    c = function () {
      function E() {
        this.element = void 0;
        this.onEvents = {};
        this.opacity = 1;
        this.renderer = void 0;
        this.SVG_NS = a;
        this.symbolCustomAttribs = "x y width height r start end innerR anchorX anchorY rounded".split(" ");
      }
      E.prototype._defaultGetter = function (b) {
        b = d(this[b + "Value"], this[b], this.element ? this.element.getAttribute(b) : null, 0);
        /^[\-0-9\.]+$/.test(b) && (b = parseFloat(b));
        return b;
      };
      E.prototype._defaultSetter = function (b, d, h) {
        h.setAttribute(d, b);
      };
      E.prototype.add = function (b) {
        var d = this.renderer,
          h = this.element;
        b && (this.parentGroup = b);
        "undefined" !== typeof this.textStr && "text" === this.element.nodeName && d.buildText(this);
        this.added = !0;
        if (!b || b.handleZ || this.zIndex) var f = this.zIndexSetter();
        f || (b ? b.element : d.box).appendChild(h);
        if (this.onAdd) this.onAdd();
        return this;
      };
      E.prototype.addClass = function (b, d) {
        var h = d ? "" : this.attr("class") || "";
        b = (b || "").split(/ /g).reduce(function (b, d) {
          -1 === h.indexOf(d) && b.push(d);
          return b;
        }, h ? [h] : []).join(" ");
        b !== h && this.attr("class", b);
        return this;
      };
      E.prototype.afterSetters = function () {
        this.doTransform && (this.updateTransform(), this.doTransform = !1);
      };
      E.prototype.align = function (b, h, f) {
        var l = {},
          v = this.renderer,
          F = v.alignedObjects,
          n,
          e,
          J;
        if (b) {
          if (this.alignOptions = b, this.alignByTranslate = h, !f || w(f)) this.alignTo = n = f || "renderer", G(F, this), F.push(this), f = void 0;
        } else b = this.alignOptions, h = this.alignByTranslate, n = this.alignTo;
        f = d(f, v[n], "scrollablePlotBox" === n ? v.plotBox : void 0, v);
        n = b.align;
        var k = b.verticalAlign;
        v = (f.x || 0) + (b.x || 0);
        F = (f.y || 0) + (b.y || 0);
        "right" === n ? e = 1 : "center" === n && (e = 2);
        e && (v += (f.width - (b.width || 0)) / e);
        l[h ? "translateX" : "x"] = Math.round(v);
        "bottom" === k ? J = 1 : "middle" === k && (J = 2);
        J && (F += (f.height - (b.height || 0)) / J);
        l[h ? "translateY" : "y"] = Math.round(F);
        this[this.placed ? "animate" : "attr"](l);
        this.placed = !0;
        this.alignAttr = l;
        return this;
      };
      E.prototype.alignSetter = function (b) {
        var d = {
          left: "start",
          center: "middle",
          right: "end"
        };
        d[b] && (this.alignValue = b, this.element.setAttribute("text-anchor", d[b]));
      };
      E.prototype.animate = function (b, f, v) {
        var n = this,
          e = z(d(f, this.renderer.globalAnimation, !0));
        f = e.defer;
        d(t.hidden, t.msHidden, t.webkitHidden, !1) && (e.duration = 0);
        0 !== e.duration ? (v && (e.complete = v), h(function () {
          n.element && L(n, b, e);
        }, f)) : (this.attr(b, void 0, v || e.complete), l(b, function (b, d) {
          e.step && e.step.call(this, b, {
            prop: d,
            pos: 1,
            elem: this
          });
        }, this));
        return this;
      };
      E.prototype.applyTextOutline = function (b) {
        var d = this.element;
        -1 !== b.indexOf("contrast") && (b = b.replace(/contrast/g, this.renderer.getContrast(d.style.fill)));
        var h = b.split(" ");
        b = h[h.length - 1];
        if ((h = h[0]) && "none" !== h && y.svg) {
          this.fakeTS = !0;
          h = h.replace(/(^[\d\.]+)(.*?)$/g, function (b, d, h) {
            return 2 * Number(d) + h;
          });
          this.removeTextOutline();
          var f = t.createElementNS(a, "tspan");
          p(f, {
            "class": "highcharts-text-outline",
            fill: b,
            stroke: b,
            "stroke-width": h,
            "stroke-linejoin": "round"
          });
          b = d.querySelector("textPath") || d;
          [].forEach.call(b.childNodes, function (b) {
            var d = b.cloneNode(!0);
            d.removeAttribute && ["fill", "stroke", "stroke-width", "stroke"].forEach(function (b) {
              return d.removeAttribute(b);
            });
            f.appendChild(d);
          });
          var l = 0;
          [].forEach.call(b.querySelectorAll("text tspan"), function (b) {
            l += Number(b.getAttribute("dy"));
          });
          h = t.createElementNS(a, "tspan");
          h.textContent = "\u200b";
          p(h, {
            x: Number(d.getAttribute("x")),
            dy: -l
          });
          f.appendChild(h);
          b.insertBefore(f, b.firstChild);
        }
      };
      E.prototype.attr = function (b, d, h, f) {
        var v = this.element,
          F = this.symbolCustomAttribs,
          n,
          e = this,
          w,
          J;
        if ("string" === typeof b && "undefined" !== typeof d) {
          var k = b;
          b = {};
          b[k] = d;
        }
        "string" === typeof b ? e = (this[b + "Getter"] || this._defaultGetter).call(this, b, v) : (l(b, function (d, h) {
          w = !1;
          f || C(this, h);
          this.symbolName && -1 !== F.indexOf(h) && (n || (this.symbolAttr(b), n = !0), w = !0);
          !this.rotation || "x" !== h && "y" !== h || (this.doTransform = !0);
          w || (J = this[h + "Setter"] || this._defaultSetter, J.call(this, d, h, v), !this.styledMode && this.shadows && /^(width|height|visibility|x|y|d|transform|cx|cy|r)$/.test(h) && this.updateShadows(h, d, J));
        }, this), this.afterSetters());
        h && h.call(this);
        return e;
      };
      E.prototype.clip = function (b) {
        return this.attr("clip-path", b ? "url(" + this.renderer.url + "#" + b.id + ")" : "none");
      };
      E.prototype.crisp = function (b, d) {
        d = d || b.strokeWidth || 0;
        var h = Math.round(d) % 2 / 2;
        b.x = Math.floor(b.x || this.x || 0) + h;
        b.y = Math.floor(b.y || this.y || 0) + h;
        b.width = Math.floor((b.width || this.width || 0) - 2 * h);
        b.height = Math.floor((b.height || this.height || 0) - 2 * h);
        D(b.strokeWidth) && (b.strokeWidth = d);
        return b;
      };
      E.prototype.complexColor = function (b, d, h) {
        var n = this.renderer,
          e,
          F,
          Q,
          w,
          k,
          J,
          E,
          p,
          a,
          q,
          m = [],
          M;
        K(this.renderer, "complexColor", {
          args: arguments
        }, function () {
          b.radialGradient ? F = "radialGradient" : b.linearGradient && (F = "linearGradient");
          if (F) {
            Q = b[F];
            k = n.gradients;
            J = b.stops;
            a = h.radialReference;
            u(Q) && (b[F] = Q = {
              x1: Q[0],
              y1: Q[1],
              x2: Q[2],
              y2: Q[3],
              gradientUnits: "userSpaceOnUse"
            });
            "radialGradient" === F && a && !D(Q.gradientUnits) && (w = Q, Q = f(Q, n.getRadialAttr(a, w), {
              gradientUnits: "userSpaceOnUse"
            }));
            l(Q, function (b, d) {
              "id" !== d && m.push(d, b);
            });
            l(J, function (b) {
              m.push(b);
            });
            m = m.join(",");
            if (k[m]) q = k[m].attr("id");else {
              Q.id = q = v();
              var N = k[m] = n.createElement(F).attr(Q).add(n.defs);
              N.radAttr = w;
              N.stops = [];
              J.forEach(function (b) {
                0 === b[1].indexOf("rgba") ? (e = g.parse(b[1]), E = e.get("rgb"), p = e.get("a")) : (E = b[1], p = 1);
                b = n.createElement("stop").attr({
                  offset: b[0],
                  "stop-color": E,
                  "stop-opacity": p
                }).add(N);
                N.stops.push(b);
              });
            }
            M = "url(" + n.url + "#" + q + ")";
            h.setAttribute(d, M);
            h.gradient = m;
            b.toString = function () {
              return M;
            };
          }
        });
      };
      E.prototype.css = function (d) {
        var h = this.styles,
          n = {},
          v = this.element,
          w = !h;
        d.color && (d.fill = d.color);
        h && l(d, function (b, d) {
          h && h[d] !== b && (n[d] = b, w = !0);
        });
        if (w) {
          h && (d = H(h, n));
          if (null === d.width || "auto" === d.width) delete this.textWidth;else if ("text" === v.nodeName.toLowerCase() && d.width) var F = this.textWidth = b(d.width);
          this.styles = d;
          F && !r && this.renderer.forExport && delete d.width;
          var Q = f(d);
          v.namespaceURI === this.SVG_NS && ["textOutline", "textOverflow", "width"].forEach(function (b) {
            return Q && delete Q[b];
          });
          e(v, Q);
          this.added && ("text" === this.element.nodeName && this.renderer.buildText(this), d.textOutline && this.applyTextOutline(d.textOutline));
        }
        return this;
      };
      E.prototype.dashstyleSetter = function (h) {
        var f = this["stroke-width"];
        "inherit" === f && (f = 1);
        if (h = h && h.toLowerCase()) {
          var l = h.replace("shortdashdotdot", "3,1,1,1,1,1,").replace("shortdashdot", "3,1,1,1").replace("shortdot", "1,1,").replace("shortdash", "3,1,").replace("longdash", "8,3,").replace(/dot/g, "1,3,").replace("dash", "4,3,").replace(/,$/, "").split(",");
          for (h = l.length; h--;) l[h] = "" + b(l[h]) * d(f, NaN);
          h = l.join(",").replace(/NaN/g, "none");
          this.element.setAttribute("stroke-dasharray", h);
        }
      };
      E.prototype.destroy = function () {
        var b = this,
          d = b.element || {},
          h = b.renderer,
          f = d.ownerSVGElement,
          n = h.isSVG && "SPAN" === d.nodeName && b.parentGroup || void 0;
        d.onclick = d.onmouseout = d.onmouseover = d.onmousemove = d.point = null;
        C(b);
        if (b.clipPath && f) {
          var F = b.clipPath;
          [].forEach.call(f.querySelectorAll("[clip-path],[CLIP-PATH]"), function (b) {
            -1 < b.getAttribute("clip-path").indexOf(F.element.id) && b.removeAttribute("clip-path");
          });
          b.clipPath = F.destroy();
        }
        if (b.stops) {
          for (f = 0; f < b.stops.length; f++) b.stops[f].destroy();
          b.stops.length = 0;
          b.stops = void 0;
        }
        b.safeRemoveChild(d);
        for (h.styledMode || b.destroyShadows(); n && n.div && 0 === n.div.childNodes.length;) d = n.parentGroup, b.safeRemoveChild(n.div), delete n.div, n = d;
        b.alignTo && G(h.alignedObjects, b);
        l(b, function (d, h) {
          b[h] && b[h].parentGroup === b && b[h].destroy && b[h].destroy();
          delete b[h];
        });
      };
      E.prototype.destroyShadows = function () {
        (this.shadows || []).forEach(function (b) {
          this.safeRemoveChild(b);
        }, this);
        this.shadows = void 0;
      };
      E.prototype.dSetter = function (b, d, h) {
        u(b) && ("string" === typeof b[0] && (b = this.renderer.pathToSegments(b)), this.pathArray = b, b = b.reduce(function (b, d, h) {
          return d && d.join ? (h ? b + " " : "") + d.join(" ") : (d || "").toString();
        }, ""));
        /(NaN| {2}|^$)/.test(b) && (b = "M 0 0");
        this[d] !== b && (h.setAttribute(d, b), this[d] = b);
      };
      E.prototype.fadeOut = function (b) {
        var h = this;
        h.animate({
          opacity: 0
        }, {
          duration: d(b, 150),
          complete: function () {
            h.hide();
          }
        });
      };
      E.prototype.fillSetter = function (b, d, h) {
        "string" === typeof b ? h.setAttribute(d, b) : b && this.complexColor(b, d, h);
      };
      E.prototype.getBBox = function (b, h) {
        var f = this.alignValue,
          l = this.element,
          v = this.renderer,
          F = this.styles,
          w = this.textStr,
          k = v.cache,
          p = v.cacheKeys,
          a = l.namespaceURI === this.SVG_NS;
        h = d(h, this.rotation, 0);
        var N = v.styledMode ? l && E.prototype.getStyle.call(l, "font-size") : F && F.fontSize,
          J;
        if (D(w)) {
          var q = w.toString();
          -1 === q.indexOf("<") && (q = q.replace(/[0-9]/g, "0"));
          q += ["", h, N, this.textWidth, f, F && F.textOverflow, F && F.fontWeight].join();
        }
        q && !b && (J = k[q]);
        if (!J) {
          if (a || v.forExport) {
            try {
              var u = this.fakeTS && function (b) {
                var d = l.querySelector(".highcharts-text-outline");
                d && e(d, {
                  display: b
                });
              };
              n(u) && u("none");
              J = l.getBBox ? H({}, l.getBBox()) : {
                width: l.offsetWidth,
                height: l.offsetHeight,
                x: 0,
                y: 0
              };
              n(u) && u("");
            } catch (U) {
              "";
            }
            if (!J || 0 > J.width) J = {
              x: 0,
              y: 0,
              width: 0,
              height: 0
            };
          } else J = this.htmlGetBBox();
          if (v.isSVG && (v = J.width, b = J.height, a && (J.height = b = {
            "11px,17": 14,
            "13px,20": 16
          }["" + (N || "") + ",".concat(Math.round(b))] || b), h)) {
            a = Number(l.getAttribute("y") || 0) - J.y;
            f = {
              right: 1,
              center: .5
            }[f || 0] || 0;
            F = h * x;
            N = (h - 90) * x;
            var m = v * Math.cos(F);
            h = v * Math.sin(F);
            u = Math.cos(N);
            F = Math.sin(N);
            v = J.x + f * (v - m) + a * u;
            N = v + m;
            u = N - b * u;
            m = u - m;
            a = J.y + a - f * h + a * F;
            f = a + h;
            b = f - b * F;
            h = b - h;
            J.x = Math.min(v, N, u, m);
            J.y = Math.min(a, f, b, h);
            J.width = Math.max(v, N, u, m) - J.x;
            J.height = Math.max(a, f, b, h) - J.y;
          }
          if (q && ("" === w || 0 < J.height)) {
            for (; 250 < p.length;) delete k[p.shift()];
            k[q] || p.push(q);
            k[q] = J;
          }
        }
        return J;
      };
      E.prototype.getStyle = function (b) {
        return q.getComputedStyle(this.element || this, "").getPropertyValue(b);
      };
      E.prototype.hasClass = function (b) {
        return -1 !== ("" + this.attr("class")).split(" ").indexOf(b);
      };
      E.prototype.hide = function () {
        return this.attr({
          visibility: "hidden"
        });
      };
      E.prototype.htmlGetBBox = function () {
        return {
          height: 0,
          width: 0,
          x: 0,
          y: 0
        };
      };
      E.prototype.init = function (b, d) {
        this.element = "span" === d ? k(d) : t.createElementNS(this.SVG_NS, d);
        this.renderer = b;
        K(this, "afterInit");
      };
      E.prototype.on = function (b, d) {
        var h = this.onEvents;
        if (h[b]) h[b]();
        h[b] = m(this.element, b, d);
        return this;
      };
      E.prototype.opacitySetter = function (b, d, h) {
        this.opacity = b = Number(Number(b).toFixed(3));
        h.setAttribute(d, b);
      };
      E.prototype.removeClass = function (b) {
        return this.attr("class", ("" + this.attr("class")).replace(w(b) ? new RegExp("(^| )".concat(b, "( |$)")) : b, " ").replace(/ +/g, " ").trim());
      };
      E.prototype.removeTextOutline = function () {
        var b = this.element.querySelector("tspan.highcharts-text-outline");
        b && this.safeRemoveChild(b);
      };
      E.prototype.safeRemoveChild = function (b) {
        var d = b.parentNode;
        d && d.removeChild(b);
      };
      E.prototype.setRadialReference = function (b) {
        var d = this.element.gradient && this.renderer.gradients[this.element.gradient];
        this.element.radialReference = b;
        d && d.radAttr && d.animate(this.renderer.getRadialAttr(b, d.radAttr));
        return this;
      };
      E.prototype.setTextPath = function (b, d) {
        var h = this;
        d = f(!0, {
          enabled: !0,
          attributes: {
            dy: -5,
            startOffset: "50%",
            textAnchor: "middle"
          }
        }, d);
        var l = this.renderer.url,
          n = this.text || this,
          F = n.textPath,
          e = d.attributes,
          w = d.enabled;
        b = b || F && F.path;
        F && F.undo();
        b && w ? (d = m(n, "afterModifyTree", function (d) {
          if (b && w) {
            var f = b.attr("id");
            f || b.attr("id", f = v());
            var F = {
              x: 0,
              y: 0
            };
            D(e.dx) && (F.dx = e.dx, delete e.dx);
            D(e.dy) && (F.dy = e.dy, delete e.dy);
            n.attr(F);
            h.attr({
              transform: ""
            });
            h.box && (h.box = h.box.destroy());
            F = d.nodes.slice(0);
            d.nodes.length = 0;
            d.nodes[0] = {
              tagName: "textPath",
              attributes: H(e, {
                "text-anchor": e.textAnchor,
                href: "" + l + "#".concat(f)
              }),
              children: F
            };
          }
        }), n.textPath = {
          path: b,
          undo: d
        }) : (n.attr({
          dx: 0,
          dy: 0
        }), delete n.textPath);
        this.added && (n.textCache = "", this.renderer.buildText(n));
        return this;
      };
      E.prototype.shadow = function (b, d, h) {
        var f = [],
          n = this.element,
          F = this.oldShadowOptions,
          v = this.parentGroup,
          e = v && 90 === v.rotation;
        v = {
          color: "#000000",
          offsetX: e ? -1 : 1,
          offsetY: e ? -1 : 1,
          opacity: .15,
          width: 3
        };
        var w = !1,
          k;
        !0 === b ? k = v : "object" === typeof b && (k = H(v, b));
        k && (k && F && l(k, function (b, d) {
          b !== F[d] && (w = !0);
        }), w && this.destroyShadows(), this.oldShadowOptions = k);
        if (!k) this.destroyShadows();else if (!this.shadows) {
          v = k.opacity / k.width;
          var E = e ? "translate(".concat(k.offsetY, ", ").concat(k.offsetX, ")") : "translate(".concat(k.offsetX, ", ").concat(k.offsetY, ")");
          for (e = 1; e <= k.width; e++) {
            var a = n.cloneNode(!1);
            var q = 2 * k.width + 1 - 2 * e;
            p(a, {
              stroke: b.color || "#000000",
              "stroke-opacity": v * e,
              "stroke-width": q,
              transform: E,
              fill: "none"
            });
            a.setAttribute("class", (a.getAttribute("class") || "") + " highcharts-shadow");
            h && (p(a, "height", Math.max(p(a, "height") - q, 0)), a.cutHeight = q);
            d ? d.element.appendChild(a) : n.parentNode && n.parentNode.insertBefore(a, n);
            f.push(a);
          }
          this.shadows = f;
        }
        return this;
      };
      E.prototype.show = function (b) {
        void 0 === b && (b = !0);
        return this.attr({
          visibility: b ? "inherit" : "visible"
        });
      };
      E.prototype["stroke-widthSetter"] = function (b, d, h) {
        this[d] = b;
        h.setAttribute(d, b);
      };
      E.prototype.strokeWidth = function () {
        if (!this.renderer.styledMode) return this["stroke-width"] || 0;
        var d = this.getStyle("stroke-width"),
          h = 0;
        if (d.indexOf("px") === d.length - 2) h = b(d);else if ("" !== d) {
          var f = t.createElementNS(a, "rect");
          p(f, {
            width: d,
            "stroke-width": 0
          });
          this.element.parentNode.appendChild(f);
          h = f.getBBox().width;
          f.parentNode.removeChild(f);
        }
        return h;
      };
      E.prototype.symbolAttr = function (b) {
        var h = this;
        "x y r start end width height innerR anchorX anchorY clockwise".split(" ").forEach(function (f) {
          h[f] = d(b[f], h[f]);
        });
        h.attr({
          d: h.renderer.symbols[h.symbolName](h.x, h.y, h.width, h.height, h)
        });
      };
      E.prototype.textSetter = function (b) {
        b !== this.textStr && (delete this.textPxLength, this.textStr = b, this.added && this.renderer.buildText(this));
      };
      E.prototype.titleSetter = function (b) {
        var h = this.element,
          f = h.getElementsByTagName("title")[0] || t.createElementNS(this.SVG_NS, "title");
        h.insertBefore ? h.insertBefore(f, h.firstChild) : h.appendChild(f);
        f.textContent = String(d(b, "")).replace(/<[^>]*>/g, "").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      };
      E.prototype.toFront = function () {
        var b = this.element;
        b.parentNode.appendChild(b);
        return this;
      };
      E.prototype.translate = function (b, d) {
        return this.attr({
          translateX: b,
          translateY: d
        });
      };
      E.prototype.updateShadows = function (b, d, h) {
        var f = this.shadows;
        if (f) for (var l = f.length; l--;) h.call(f[l], "height" === b ? Math.max(d - (f[l].cutHeight || 0), 0) : "d" === b ? this.d : d, b, f[l]);
      };
      E.prototype.updateTransform = function () {
        var b = this.element,
          h = this.matrix,
          f = this.rotation;
        f = void 0 === f ? 0 : f;
        var l = this.scaleX,
          n = this.scaleY,
          v = this.translateX,
          e = this.translateY;
        v = ["translate(" + (void 0 === v ? 0 : v) + "," + (void 0 === e ? 0 : e) + ")"];
        D(h) && v.push("matrix(" + h.join(",") + ")");
        f && v.push("rotate(" + f + " " + d(this.rotationOriginX, b.getAttribute("x"), 0) + " " + d(this.rotationOriginY, b.getAttribute("y") || 0) + ")");
        (D(l) || D(n)) && v.push("scale(" + d(l, 1) + " " + d(n, 1) + ")");
        v.length && !(this.text || this).textPath && b.setAttribute("transform", v.join(" "));
      };
      E.prototype.visibilitySetter = function (b, d, h) {
        "inherit" === b ? h.removeAttribute(d) : this[d] !== b && h.setAttribute(d, b);
        this[d] = b;
      };
      E.prototype.xGetter = function (b) {
        "circle" === this.element.nodeName && ("x" === b ? b = "cx" : "y" === b && (b = "cy"));
        return this._defaultGetter(b);
      };
      E.prototype.zIndexSetter = function (d, h) {
        var f = this.renderer,
          l = this.parentGroup,
          v = (l || f).element || f.box,
          n = this.element;
        f = v === f.box;
        var e = !1;
        var w = this.added;
        var k;
        D(d) ? (n.setAttribute("data-z-index", d), d = +d, this[h] === d && (w = !1)) : D(this[h]) && n.removeAttribute("data-z-index");
        this[h] = d;
        if (w) {
          (d = this.zIndex) && l && (l.handleZ = !0);
          h = v.childNodes;
          for (k = h.length - 1; 0 <= k && !e; k--) {
            l = h[k];
            w = l.getAttribute("data-z-index");
            var E = !D(w);
            if (l !== n) if (0 > d && E && !f && !k) v.insertBefore(n, h[k]), e = !0;else if (b(w) <= d || E && (!D(d) || 0 <= d)) v.insertBefore(n, h[k + 1] || null), e = !0;
          }
          e || (v.insertBefore(n, h[f ? 3 : 0] || null), e = !0);
        }
        return e;
      };
      return E;
    }();
    c.prototype.strokeSetter = c.prototype.fillSetter;
    c.prototype.yGetter = c.prototype.xGetter;
    c.prototype.matrixSetter = c.prototype.rotationOriginXSetter = c.prototype.rotationOriginYSetter = c.prototype.rotationSetter = c.prototype.scaleXSetter = c.prototype.scaleYSetter = c.prototype.translateXSetter = c.prototype.translateYSetter = c.prototype.verticalAlignSetter = function (b, d) {
      this[d] = b;
      this.doTransform = !0;
    };
    "";
    return c;
  });
  I(g, "Core/Renderer/RendererRegistry.js", [g["Core/Globals.js"]], function (c) {
    var g;
    (function (g) {
      g.rendererTypes = {};
      var L;
      g.getRendererType = function (c) {
        void 0 === c && (c = L);
        return g.rendererTypes[c] || g.rendererTypes[L];
      };
      g.registerRendererType = function (y, z, C) {
        g.rendererTypes[y] = z;
        if (!L || C) L = y, c.Renderer = z;
      };
    })(g || (g = {}));
    return g;
  });
  I(g, "Core/Renderer/SVG/SVGLabel.js", [g["Core/Renderer/SVG/SVGElement.js"], g["Core/Utilities.js"]], function (c, g) {
    var L = this && this.__extends || function () {
        var c = function (a, q) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, p) {
            a.__proto__ = p;
          } || function (a, p) {
            for (var k in p) p.hasOwnProperty(k) && (a[k] = p[k]);
          };
          return c(a, q);
        };
        return function (a, q) {
          function m() {
            this.constructor = a;
          }
          c(a, q);
          a.prototype = null === q ? Object.create(q) : (m.prototype = q.prototype, new m());
        };
      }(),
      A = g.defined,
      B = g.extend,
      z = g.isNumber,
      C = g.merge,
      x = g.pick,
      t = g.removeEvent;
    return function (r) {
      function a(q, m, p, k, e, D, c, t, g, u) {
        var n = r.call(this) || this;
        n.paddingLeftSetter = n.paddingSetter;
        n.paddingRightSetter = n.paddingSetter;
        n.init(q, "g");
        n.textStr = m;
        n.x = p;
        n.y = k;
        n.anchorX = D;
        n.anchorY = c;
        n.baseline = g;
        n.className = u;
        n.addClass("button" === u ? "highcharts-no-tooltip" : "highcharts-label");
        u && n.addClass("highcharts-" + u);
        n.text = q.text(void 0, 0, 0, t).attr({
          zIndex: 1
        });
        var w;
        "string" === typeof e && ((w = /^url\((.*?)\)$/.test(e)) || n.renderer.symbols[e]) && (n.symbolKey = e);
        n.bBox = a.emptyBBox;
        n.padding = 3;
        n.baselineOffset = 0;
        n.needsBox = q.styledMode || w;
        n.deferredAttr = {};
        n.alignFactor = 0;
        return n;
      }
      L(a, r);
      a.prototype.alignSetter = function (a) {
        a = {
          left: 0,
          center: .5,
          right: 1
        }[a];
        a !== this.alignFactor && (this.alignFactor = a, this.bBox && z(this.xSetting) && this.attr({
          x: this.xSetting
        }));
      };
      a.prototype.anchorXSetter = function (a, m) {
        this.anchorX = a;
        this.boxAttr(m, Math.round(a) - this.getCrispAdjust() - this.xSetting);
      };
      a.prototype.anchorYSetter = function (a, m) {
        this.anchorY = a;
        this.boxAttr(m, a - this.ySetting);
      };
      a.prototype.boxAttr = function (a, m) {
        this.box ? this.box.attr(a, m) : this.deferredAttr[a] = m;
      };
      a.prototype.css = function (q) {
        if (q) {
          var m = {};
          q = C(q);
          a.textProps.forEach(function (a) {
            "undefined" !== typeof q[a] && (m[a] = q[a], delete q[a]);
          });
          this.text.css(m);
          "fontSize" in m || "fontWeight" in m ? this.updateTextPadding() : ("width" in m || "textOverflow" in m) && this.updateBoxSize();
        }
        return c.prototype.css.call(this, q);
      };
      a.prototype.destroy = function () {
        t(this.element, "mouseenter");
        t(this.element, "mouseleave");
        this.text && this.text.destroy();
        this.box && (this.box = this.box.destroy());
        c.prototype.destroy.call(this);
      };
      a.prototype.fillSetter = function (a, m) {
        a && (this.needsBox = !0);
        this.fill = a;
        this.boxAttr(m, a);
      };
      a.prototype.getBBox = function () {
        this.textStr && 0 === this.bBox.width && 0 === this.bBox.height && this.updateBoxSize();
        var a = this.padding,
          m = x(this.paddingLeft, a);
        return {
          width: this.width,
          height: this.height,
          x: this.bBox.x - m,
          y: this.bBox.y - a
        };
      };
      a.prototype.getCrispAdjust = function () {
        return this.renderer.styledMode && this.box ? this.box.strokeWidth() % 2 / 2 : (this["stroke-width"] ? parseInt(this["stroke-width"], 10) : 0) % 2 / 2;
      };
      a.prototype.heightSetter = function (a) {
        this.heightSetting = a;
      };
      a.prototype.onAdd = function () {
        this.text.add(this);
        this.attr({
          text: x(this.textStr, ""),
          x: this.x || 0,
          y: this.y || 0
        });
        this.box && A(this.anchorX) && this.attr({
          anchorX: this.anchorX,
          anchorY: this.anchorY
        });
      };
      a.prototype.paddingSetter = function (a, m) {
        z(a) ? a !== this[m] && (this[m] = a, this.updateTextPadding()) : this[m] = void 0;
      };
      a.prototype.rSetter = function (a, m) {
        this.boxAttr(m, a);
      };
      a.prototype.shadow = function (a) {
        a && !this.renderer.styledMode && (this.updateBoxSize(), this.box && this.box.shadow(a));
        return this;
      };
      a.prototype.strokeSetter = function (a, m) {
        this.stroke = a;
        this.boxAttr(m, a);
      };
      a.prototype["stroke-widthSetter"] = function (a, m) {
        a && (this.needsBox = !0);
        this["stroke-width"] = a;
        this.boxAttr(m, a);
      };
      a.prototype["text-alignSetter"] = function (a) {
        this.textAlign = a;
      };
      a.prototype.textSetter = function (a) {
        "undefined" !== typeof a && this.text.attr({
          text: a
        });
        this.updateTextPadding();
      };
      a.prototype.updateBoxSize = function () {
        var q = this.text,
          m = q.element.style,
          p = {},
          k = this.padding,
          e = this.bBox = z(this.widthSetting) && z(this.heightSetting) && !this.textAlign || !A(q.textStr) ? a.emptyBBox : q.getBBox();
        this.width = this.getPaddedWidth();
        this.height = (this.heightSetting || e.height || 0) + 2 * k;
        m = this.renderer.fontMetrics(m && m.fontSize, q);
        this.baselineOffset = k + Math.min((this.text.firstLineMetrics || m).b, e.height || Infinity);
        this.heightSetting && (this.baselineOffset += (this.heightSetting - m.h) / 2);
        this.needsBox && !q.textPath && (this.box || (q = this.box = this.symbolKey ? this.renderer.symbol(this.symbolKey) : this.renderer.rect(), q.addClass(("button" === this.className ? "" : "highcharts-label-box") + (this.className ? " highcharts-" + this.className + "-box" : "")), q.add(this)), q = this.getCrispAdjust(), p.x = q, p.y = (this.baseline ? -this.baselineOffset : 0) + q, p.width = Math.round(this.width), p.height = Math.round(this.height), this.box.attr(B(p, this.deferredAttr)), this.deferredAttr = {});
      };
      a.prototype.updateTextPadding = function () {
        var a = this.text;
        if (!a.textPath) {
          this.updateBoxSize();
          var m = this.baseline ? 0 : this.baselineOffset,
            p = x(this.paddingLeft, this.padding);
          A(this.widthSetting) && this.bBox && ("center" === this.textAlign || "right" === this.textAlign) && (p += {
            center: .5,
            right: 1
          }[this.textAlign] * (this.widthSetting - this.bBox.width));
          if (p !== a.x || m !== a.y) a.attr("x", p), a.hasBoxWidthChanged && (this.bBox = a.getBBox(!0)), "undefined" !== typeof m && a.attr("y", m);
          a.x = p;
          a.y = m;
        }
      };
      a.prototype.widthSetter = function (a) {
        this.widthSetting = z(a) ? a : void 0;
      };
      a.prototype.getPaddedWidth = function () {
        var a = this.padding,
          m = x(this.paddingLeft, a);
        a = x(this.paddingRight, a);
        return (this.widthSetting || this.bBox.width || 0) + m + a;
      };
      a.prototype.xSetter = function (a) {
        this.x = a;
        this.alignFactor && (a -= this.alignFactor * this.getPaddedWidth(), this["forceAnimate:x"] = !0);
        this.xSetting = Math.round(a);
        this.attr("translateX", this.xSetting);
      };
      a.prototype.ySetter = function (a) {
        this.ySetting = this.y = Math.round(a);
        this.attr("translateY", this.ySetting);
      };
      a.emptyBBox = {
        width: 0,
        height: 0,
        x: 0,
        y: 0
      };
      a.textProps = "color direction fontFamily fontSize fontStyle fontWeight lineHeight textAlign textDecoration textOutline textOverflow width".split(" ");
      return a;
    }(c);
  });
  I(g, "Core/Renderer/SVG/Symbols.js", [g["Core/Utilities.js"]], function (c) {
    function g(c, t, r, a, q) {
      var m = [];
      if (q) {
        var p = q.start || 0,
          k = C(q.r, r);
        r = C(q.r, a || r);
        var e = (q.end || 0) - .001;
        a = q.innerR;
        var D = C(q.open, .001 > Math.abs((q.end || 0) - p - 2 * Math.PI)),
          G = Math.cos(p),
          g = Math.sin(p),
          x = Math.cos(e),
          u = Math.sin(e);
        p = C(q.longArc, .001 > e - p - Math.PI ? 0 : 1);
        m.push(["M", c + k * G, t + r * g], ["A", k, r, 0, p, C(q.clockwise, 1), c + k * x, t + r * u]);
        B(a) && m.push(D ? ["M", c + a * x, t + a * u] : ["L", c + a * x, t + a * u], ["A", a, a, 0, p, B(q.clockwise) ? 1 - q.clockwise : 0, c + a * G, t + a * g]);
        D || m.push(["Z"]);
      }
      return m;
    }
    function y(c, t, r, a, q) {
      return q && q.r ? A(c, t, r, a, q) : [["M", c, t], ["L", c + r, t], ["L", c + r, t + a], ["L", c, t + a], ["Z"]];
    }
    function A(c, t, r, a, q) {
      q = q && q.r || 0;
      return [["M", c + q, t], ["L", c + r - q, t], ["C", c + r, t, c + r, t, c + r, t + q], ["L", c + r, t + a - q], ["C", c + r, t + a, c + r, t + a, c + r - q, t + a], ["L", c + q, t + a], ["C", c, t + a, c, t + a, c, t + a - q], ["L", c, t + q], ["C", c, t, c, t, c + q, t]];
    }
    var B = c.defined,
      z = c.isNumber,
      C = c.pick;
    return {
      arc: g,
      callout: function (c, t, r, a, q) {
        var m = Math.min(q && q.r || 0, r, a),
          p = m + 6,
          k = q && q.anchorX;
        q = q && q.anchorY || 0;
        var e = A(c, t, r, a, {
          r: m
        });
        if (!z(k)) return e;
        c + k >= r ? q > t + p && q < t + a - p ? e.splice(3, 1, ["L", c + r, q - 6], ["L", c + r + 6, q], ["L", c + r, q + 6], ["L", c + r, t + a - m]) : e.splice(3, 1, ["L", c + r, a / 2], ["L", k, q], ["L", c + r, a / 2], ["L", c + r, t + a - m]) : 0 >= c + k ? q > t + p && q < t + a - p ? e.splice(7, 1, ["L", c, q + 6], ["L", c - 6, q], ["L", c, q - 6], ["L", c, t + m]) : e.splice(7, 1, ["L", c, a / 2], ["L", k, q], ["L", c, a / 2], ["L", c, t + m]) : q && q > a && k > c + p && k < c + r - p ? e.splice(5, 1, ["L", k + 6, t + a], ["L", k, t + a + 6], ["L", k - 6, t + a], ["L", c + m, t + a]) : q && 0 > q && k > c + p && k < c + r - p && e.splice(1, 1, ["L", k - 6, t], ["L", k, t - 6], ["L", k + 6, t], ["L", r - m, t]);
        return e;
      },
      circle: function (c, t, r, a) {
        return g(c + r / 2, t + a / 2, r / 2, a / 2, {
          start: .5 * Math.PI,
          end: 2.5 * Math.PI,
          open: !1
        });
      },
      diamond: function (c, t, r, a) {
        return [["M", c + r / 2, t], ["L", c + r, t + a / 2], ["L", c + r / 2, t + a], ["L", c, t + a / 2], ["Z"]];
      },
      rect: y,
      roundedRect: A,
      square: y,
      triangle: function (c, t, r, a) {
        return [["M", c + r / 2, t], ["L", c + r, t + a], ["L", c, t + a], ["Z"]];
      },
      "triangle-down": function (c, t, r, a) {
        return [["M", c, t], ["L", c + r, t], ["L", c + r / 2, t + a], ["Z"]];
      }
    };
  });
  I(g, "Core/Renderer/SVG/TextBuilder.js", [g["Core/Renderer/HTML/AST.js"], g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var L = g.doc,
      B = g.SVG_NS,
      z = g.win,
      C = y.attr,
      x = y.extend,
      t = y.fireEvent,
      r = y.isString,
      a = y.objectEach,
      q = y.pick;
    return function () {
      function m(a) {
        var k = a.styles;
        this.renderer = a.renderer;
        this.svgElement = a;
        this.width = a.textWidth;
        this.textLineHeight = k && k.lineHeight;
        this.textOutline = k && k.textOutline;
        this.ellipsis = !(!k || "ellipsis" !== k.textOverflow);
        this.noWrap = !(!k || "nowrap" !== k.whiteSpace);
        this.fontSize = k && k.fontSize;
      }
      m.prototype.buildSVG = function () {
        var a = this.svgElement,
          k = a.element,
          e = a.renderer,
          m = q(a.textStr, "").toString(),
          G = -1 !== m.indexOf("<"),
          t = k.childNodes;
        e = this.width && !a.added && e.box;
        var g = /<br.*?>/g,
          u = [m, this.ellipsis, this.noWrap, this.textLineHeight, this.textOutline, this.fontSize, this.width].join();
        if (u !== a.textCache) {
          a.textCache = u;
          delete a.actualWidth;
          for (u = t.length; u--;) k.removeChild(t[u]);
          G || this.ellipsis || this.width || a.textPath || -1 !== m.indexOf(" ") && (!this.noWrap || g.test(m)) ? "" !== m && (e && e.appendChild(k), m = new c(m), this.modifyTree(m.nodes), m.addToDOM(k), this.modifyDOM(), this.ellipsis && -1 !== (k.textContent || "").indexOf("\u2026") && a.attr("title", this.unescapeEntities(a.textStr || "", ["&lt;", "&gt;"])), e && e.removeChild(k)) : k.appendChild(L.createTextNode(this.unescapeEntities(m)));
          r(this.textOutline) && a.applyTextOutline && a.applyTextOutline(this.textOutline);
        }
      };
      m.prototype.modifyDOM = function () {
        var a = this,
          k = this.svgElement,
          e = C(k.element, "x");
        k.firstLineMetrics = void 0;
        for (var m; m = k.element.firstChild;) if (/^[\s\u200B]*$/.test(m.textContent || " ")) k.element.removeChild(m);else break;
        [].forEach.call(k.element.querySelectorAll("tspan.highcharts-br"), function (u, n) {
          u.nextSibling && u.previousSibling && (0 === n && 1 === u.previousSibling.nodeType && (k.firstLineMetrics = k.renderer.fontMetrics(void 0, u.previousSibling)), C(u, {
            dy: a.getLineHeight(u.nextSibling),
            x: e
          }));
        });
        var c = this.width || 0;
        if (c) {
          var q = function (u, n) {
              var w = u.textContent || "",
                f = w.replace(/([^\^])-/g, "$1- ").split(" "),
                l = !a.noWrap && (1 < f.length || 1 < k.element.childNodes.length),
                d = a.getLineHeight(n),
                b = 0,
                h = k.actualWidth;
              if (a.ellipsis) w && a.truncate(u, w, void 0, 0, Math.max(0, c - parseInt(a.fontSize || 12, 10)), function (b, d) {
                return b.substring(0, d) + "\u2026";
              });else if (l) {
                w = [];
                for (l = []; n.firstChild && n.firstChild !== u;) l.push(n.firstChild), n.removeChild(n.firstChild);
                for (; f.length;) f.length && !a.noWrap && 0 < b && (w.push(u.textContent || ""), u.textContent = f.join(" ").replace(/- /g, "-")), a.truncate(u, void 0, f, 0 === b ? h || 0 : 0, c, function (b, d) {
                  return f.slice(0, d).join(" ").replace(/- /g, "-");
                }), h = k.actualWidth, b++;
                l.forEach(function (b) {
                  n.insertBefore(b, u);
                });
                w.forEach(function (b) {
                  n.insertBefore(L.createTextNode(b), u);
                  b = L.createElementNS(B, "tspan");
                  b.textContent = "\u200b";
                  C(b, {
                    dy: d,
                    x: e
                  });
                  n.insertBefore(b, u);
                });
              }
            },
            r = function (e) {
              [].slice.call(e.childNodes).forEach(function (n) {
                n.nodeType === z.Node.TEXT_NODE ? q(n, e) : (-1 !== n.className.baseVal.indexOf("highcharts-br") && (k.actualWidth = 0), r(n));
              });
            };
          r(k.element);
        }
      };
      m.prototype.getLineHeight = function (a) {
        var k;
        a = a.nodeType === z.Node.TEXT_NODE ? a.parentElement : a;
        this.renderer.styledMode || (k = a && /(px|em)$/.test(a.style.fontSize) ? a.style.fontSize : this.fontSize || this.renderer.style.fontSize || 12);
        return this.textLineHeight ? parseInt(this.textLineHeight.toString(), 10) : this.renderer.fontMetrics(k, a || this.svgElement.element).h;
      };
      m.prototype.modifyTree = function (a) {
        var k = this,
          e = function (m, p) {
            var c = m.attributes;
            c = void 0 === c ? {} : c;
            var q = m.children,
              u = m.style;
            u = void 0 === u ? {} : u;
            var n = m.tagName,
              w = k.renderer.styledMode;
            if ("b" === n || "strong" === n) w ? c["class"] = "highcharts-strong" : u.fontWeight = "bold";else if ("i" === n || "em" === n) w ? c["class"] = "highcharts-emphasized" : u.fontStyle = "italic";
            u && u.color && (u.fill = u.color);
            "br" === n ? (c["class"] = "highcharts-br", m.textContent = "\u200b", (p = a[p + 1]) && p.textContent && (p.textContent = p.textContent.replace(/^ +/gm, ""))) : "a" === n && q && q.some(function (f) {
              return "#text" === f.tagName;
            }) && (m.children = [{
              children: q,
              tagName: "tspan"
            }]);
            "#text" !== n && "a" !== n && (m.tagName = "tspan");
            x(m, {
              attributes: c,
              style: u
            });
            q && q.filter(function (f) {
              return "#text" !== f.tagName;
            }).forEach(e);
          };
        a.forEach(e);
        t(this.svgElement, "afterModifyTree", {
          nodes: a
        });
      };
      m.prototype.truncate = function (a, k, e, m, c, q) {
        var p = this.svgElement,
          u = p.renderer,
          n = p.rotation,
          w = [],
          f = e ? 1 : 0,
          l = (k || e || "").length,
          d = l,
          b,
          h = function (b, d) {
            d = d || b;
            var h = a.parentNode;
            if (h && "undefined" === typeof w[d]) if (h.getSubStringLength) try {
              w[d] = m + h.getSubStringLength(0, e ? d + 1 : d);
            } catch (O) {
              "";
            } else u.getSpanWidth && (a.textContent = q(k || e, b), w[d] = m + u.getSpanWidth(p, a));
            return w[d];
          };
        p.rotation = 0;
        var v = h(a.textContent.length);
        if (m + v > c) {
          for (; f <= l;) d = Math.ceil((f + l) / 2), e && (b = q(e, d)), v = h(d, b && b.length - 1), f === l ? f = l + 1 : v > c ? l = d - 1 : f = d;
          0 === l ? a.textContent = "" : k && l === k.length - 1 || (a.textContent = b || q(k || e, d));
        }
        e && e.splice(0, d);
        p.actualWidth = v;
        p.rotation = n;
      };
      m.prototype.unescapeEntities = function (m, k) {
        a(this.renderer.escapes, function (e, a) {
          k && -1 !== k.indexOf(e) || (m = m.toString().replace(new RegExp(e, "g"), a));
        });
        return m;
      };
      return m;
    }();
  });
  I(g, "Core/Renderer/SVG/SVGRenderer.js", [g["Core/Renderer/HTML/AST.js"], g["Core/Color/Color.js"], g["Core/Globals.js"], g["Core/Renderer/RendererRegistry.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Renderer/SVG/SVGLabel.js"], g["Core/Renderer/SVG/Symbols.js"], g["Core/Renderer/SVG/TextBuilder.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C, x, t) {
    var r = y.charts,
      a = y.deg2rad,
      q = y.doc,
      m = y.isFirefox,
      p = y.isMS,
      k = y.isWebKit,
      e = y.noop,
      D = y.SVG_NS,
      G = y.symbolSizes,
      H = y.win,
      K = t.addEvent,
      u = t.attr,
      n = t.createElement,
      w = t.css,
      f = t.defined,
      l = t.destroyObjectProperties,
      d = t.extend,
      b = t.isArray,
      h = t.isNumber,
      v = t.isObject,
      E = t.isString,
      J = t.merge,
      M = t.pick,
      O = t.pInt,
      P = t.uniqueKey,
      T;
    y = function () {
      function F(b, d, h, f, l, n, v) {
        this.width = this.url = this.style = this.isSVG = this.imgCount = this.height = this.gradients = this.globalAnimation = this.defs = this.chartIndex = this.cacheKeys = this.cache = this.boxWrapper = this.box = this.alignedObjects = void 0;
        this.init(b, d, h, f, l, n, v);
      }
      F.prototype.init = function (b, d, h, f, l, n, v) {
        var F = this.createElement("svg").attr({
            version: "1.1",
            "class": "highcharts-root"
          }),
          e = F.element;
        v || F.css(this.getStyle(f));
        b.appendChild(e);
        u(b, "dir", "ltr");
        -1 === b.innerHTML.indexOf("xmlns") && u(e, "xmlns", this.SVG_NS);
        this.isSVG = !0;
        this.box = e;
        this.boxWrapper = F;
        this.alignedObjects = [];
        this.url = this.getReferenceURL();
        this.createElement("desc").add().element.appendChild(q.createTextNode("Created with Highcharts 10.3.3"));
        this.defs = this.createElement("defs").add();
        this.allowHTML = n;
        this.forExport = l;
        this.styledMode = v;
        this.gradients = {};
        this.cache = {};
        this.cacheKeys = [];
        this.imgCount = 0;
        this.setSize(d, h, !1);
        var a;
        m && b.getBoundingClientRect && (d = function () {
          w(b, {
            left: 0,
            top: 0
          });
          a = b.getBoundingClientRect();
          w(b, {
            left: Math.ceil(a.left) - a.left + "px",
            top: Math.ceil(a.top) - a.top + "px"
          });
        }, d(), this.unSubPixelFix = K(H, "resize", d));
      };
      F.prototype.definition = function (b) {
        return new c([b]).addToDOM(this.defs.element);
      };
      F.prototype.getReferenceURL = function () {
        if ((m || k) && q.getElementsByTagName("base").length) {
          if (!f(T)) {
            var b = P();
            b = new c([{
              tagName: "svg",
              attributes: {
                width: 8,
                height: 8
              },
              children: [{
                tagName: "defs",
                children: [{
                  tagName: "clipPath",
                  attributes: {
                    id: b
                  },
                  children: [{
                    tagName: "rect",
                    attributes: {
                      width: 4,
                      height: 4
                    }
                  }]
                }]
              }, {
                tagName: "rect",
                attributes: {
                  id: "hitme",
                  width: 8,
                  height: 8,
                  "clip-path": "url(#".concat(b, ")"),
                  fill: "rgba(0,0,0,0.001)"
                }
              }]
            }]).addToDOM(q.body);
            w(b, {
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 9E5
            });
            var d = q.elementFromPoint(6, 6);
            T = "hitme" === (d && d.id);
            q.body.removeChild(b);
          }
          if (T) return H.location.href.split("#")[0].replace(/<[^>]*>/g, "").replace(/([\('\)])/g, "\\$1").replace(/ /g, "%20");
        }
        return "";
      };
      F.prototype.getStyle = function (b) {
        return this.style = d({
          fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
          fontSize: "12px"
        }, b);
      };
      F.prototype.setStyle = function (b) {
        this.boxWrapper.css(this.getStyle(b));
      };
      F.prototype.isHidden = function () {
        return !this.boxWrapper.getBBox().width;
      };
      F.prototype.destroy = function () {
        var b = this.defs;
        this.box = null;
        this.boxWrapper = this.boxWrapper.destroy();
        l(this.gradients || {});
        this.gradients = null;
        b && (this.defs = b.destroy());
        this.unSubPixelFix && this.unSubPixelFix();
        return this.alignedObjects = null;
      };
      F.prototype.createElement = function (b) {
        var d = new this.Element();
        d.init(this, b);
        return d;
      };
      F.prototype.getRadialAttr = function (b, d) {
        return {
          cx: b[0] - b[2] / 2 + (d.cx || 0) * b[2],
          cy: b[1] - b[2] / 2 + (d.cy || 0) * b[2],
          r: (d.r || 0) * b[2]
        };
      };
      F.prototype.buildText = function (b) {
        new x(b).buildSVG();
      };
      F.prototype.getContrast = function (b) {
        b = g.parse(b).rgba.map(function (b) {
          b /= 255;
          return .03928 >= b ? b / 12.92 : Math.pow((b + .055) / 1.055, 2.4);
        });
        b = .2126 * b[0] + .7152 * b[1] + .0722 * b[2];
        return 1.05 / (b + .05) > (b + .05) / .05 ? "#FFFFFF" : "#000000";
      };
      F.prototype.button = function (b, h, f, l, n, F, e, a, w, k) {
        void 0 === n && (n = {});
        var E = this.label(b, h, f, w, void 0, void 0, k, void 0, "button"),
          Q = this.styledMode;
        b = n.states || {};
        var N = 0;
        n = J(n);
        delete n.states;
        var m = J({
          color: "#333333",
          cursor: "pointer",
          fontWeight: "normal"
        }, n.style);
        delete n.style;
        var u = c.filterUserAttributes(n);
        E.attr(J({
          padding: 8,
          r: 2
        }, u));
        if (!Q) {
          u = J({
            fill: "#f7f7f7",
            stroke: "#cccccc",
            "stroke-width": 1
          }, u);
          F = J(u, {
            fill: "#e6e6e6"
          }, c.filterUserAttributes(F || b.hover || {}));
          var q = F.style;
          delete F.style;
          e = J(u, {
            fill: "#e6ebf5",
            style: {
              color: "#000000",
              fontWeight: "bold"
            }
          }, c.filterUserAttributes(e || b.select || {}));
          var D = e.style;
          delete e.style;
          a = J(u, {
            style: {
              color: "#cccccc"
            }
          }, c.filterUserAttributes(a || b.disabled || {}));
          var r = a.style;
          delete a.style;
        }
        K(E.element, p ? "mouseover" : "mouseenter", function () {
          3 !== N && E.setState(1);
        });
        K(E.element, p ? "mouseout" : "mouseleave", function () {
          3 !== N && E.setState(N);
        });
        E.setState = function (b) {
          1 !== b && (E.state = N = b);
          E.removeClass(/highcharts-button-(normal|hover|pressed|disabled)/).addClass("highcharts-button-" + ["normal", "hover", "pressed", "disabled"][b || 0]);
          Q || (E.attr([u, F, e, a][b || 0]), b = [m, q, D, r][b || 0], v(b) && E.css(b));
        };
        Q || (E.attr(u).css(d({
          cursor: "default"
        }, m)), k && E.text.css({
          pointerEvents: "none"
        }));
        return E.on("touchstart", function (b) {
          return b.stopPropagation();
        }).on("click", function (b) {
          3 !== N && l.call(E, b);
        });
      };
      F.prototype.crispLine = function (b, d, h) {
        void 0 === h && (h = "round");
        var l = b[0],
          n = b[1];
        f(l[1]) && l[1] === n[1] && (l[1] = n[1] = Math[h](l[1]) - d % 2 / 2);
        f(l[2]) && l[2] === n[2] && (l[2] = n[2] = Math[h](l[2]) + d % 2 / 2);
        return b;
      };
      F.prototype.path = function (h) {
        var f = this.styledMode ? {} : {
          fill: "none"
        };
        b(h) ? f.d = h : v(h) && d(f, h);
        return this.createElement("path").attr(f);
      };
      F.prototype.circle = function (b, d, h) {
        b = v(b) ? b : "undefined" === typeof b ? {} : {
          x: b,
          y: d,
          r: h
        };
        d = this.createElement("circle");
        d.xSetter = d.ySetter = function (b, d, h) {
          h.setAttribute("c" + d, b);
        };
        return d.attr(b);
      };
      F.prototype.arc = function (b, d, h, f, l, n) {
        v(b) ? (f = b, d = f.y, h = f.r, b = f.x) : f = {
          innerR: f,
          start: l,
          end: n
        };
        b = this.symbol("arc", b, d, h, h, f);
        b.r = h;
        return b;
      };
      F.prototype.rect = function (b, d, h, f, l, n) {
        l = v(b) ? b.r : l;
        var F = this.createElement("rect");
        b = v(b) ? b : "undefined" === typeof b ? {} : {
          x: b,
          y: d,
          width: Math.max(h, 0),
          height: Math.max(f, 0)
        };
        this.styledMode || ("undefined" !== typeof n && (b["stroke-width"] = n, b = F.crisp(b)), b.fill = "none");
        l && (b.r = l);
        F.rSetter = function (b, d, h) {
          F.r = b;
          u(h, {
            rx: b,
            ry: b
          });
        };
        F.rGetter = function () {
          return F.r || 0;
        };
        return F.attr(b);
      };
      F.prototype.setSize = function (b, d, h) {
        this.width = b;
        this.height = d;
        this.boxWrapper.animate({
          width: b,
          height: d
        }, {
          step: function () {
            this.attr({
              viewBox: "0 0 " + this.attr("width") + " " + this.attr("height")
            });
          },
          duration: M(h, !0) ? void 0 : 0
        });
        this.alignElements();
      };
      F.prototype.g = function (b) {
        var d = this.createElement("g");
        return b ? d.attr({
          "class": "highcharts-" + b
        }) : d;
      };
      F.prototype.image = function (b, d, f, l, n, v) {
        var F = {
            preserveAspectRatio: "none"
          },
          e = function (b, d) {
            b.setAttributeNS ? b.setAttributeNS("http://www.w3.org/1999/xlink", "href", d) : b.setAttribute("hc-svg-href", d);
          };
        h(d) && (F.x = d);
        h(f) && (F.y = f);
        h(l) && (F.width = l);
        h(n) && (F.height = n);
        var a = this.createElement("image").attr(F);
        d = function (d) {
          e(a.element, b);
          v.call(a, d);
        };
        v ? (e(a.element, "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="), f = new H.Image(), K(f, "load", d), f.src = b, f.complete && d({})) : e(a.element, b);
        return a;
      };
      F.prototype.symbol = function (b, h, l, v, F, e) {
        var a = this,
          k = /^url\((.*?)\)$/,
          E = k.test(b),
          N = !E && (this.symbols[b] ? b : "circle"),
          m = N && this.symbols[N],
          Q;
        if (m) {
          "number" === typeof h && (Q = m.call(this.symbols, Math.round(h || 0), Math.round(l || 0), v || 0, F || 0, e));
          var p = this.path(Q);
          a.styledMode || p.attr("fill", "none");
          d(p, {
            symbolName: N || void 0,
            x: h,
            y: l,
            width: v,
            height: F
          });
          e && d(p, e);
        } else if (E) {
          var c = b.match(k)[1];
          var J = p = this.image(c);
          J.imgwidth = M(G[c] && G[c].width, e && e.width);
          J.imgheight = M(G[c] && G[c].height, e && e.height);
          var D = function (b) {
            return b.attr({
              width: b.width,
              height: b.height
            });
          };
          ["width", "height"].forEach(function (b) {
            J[b + "Setter"] = function (b, d) {
              this[d] = b;
              b = this.alignByTranslate;
              var h = this.element,
                l = this.width,
                n = this.height,
                v = this.imgwidth,
                F = this.imgheight,
                a = this["img" + d];
              if (f(a)) {
                var w = 1;
                e && "within" === e.backgroundSize && l && n ? (w = Math.min(l / v, n / F), a = Math.round(a * w), u(h, {
                  width: Math.round(v * w),
                  height: Math.round(F * w)
                })) : h && h.setAttribute(d, a);
                b || this.translate(((l || 0) - a * w) / 2, ((n || 0) - a * w) / 2);
              }
            };
          });
          f(h) && J.attr({
            x: h,
            y: l
          });
          J.isImg = !0;
          f(J.imgwidth) && f(J.imgheight) ? D(J) : (J.attr({
            width: 0,
            height: 0
          }), n("img", {
            onload: function () {
              var b = r[a.chartIndex];
              0 === this.width && (w(this, {
                position: "absolute",
                top: "-999em"
              }), q.body.appendChild(this));
              G[c] = {
                width: this.width,
                height: this.height
              };
              J.imgwidth = this.width;
              J.imgheight = this.height;
              J.element && D(J);
              this.parentNode && this.parentNode.removeChild(this);
              a.imgCount--;
              if (!a.imgCount && b && !b.hasLoaded) b.onload();
            },
            src: c
          }), this.imgCount++);
        }
        return p;
      };
      F.prototype.clipRect = function (b, d, h, f) {
        var l = P() + "-",
          n = this.createElement("clipPath").attr({
            id: l
          }).add(this.defs);
        b = this.rect(b, d, h, f, 0).add(n);
        b.id = l;
        b.clipPath = n;
        b.count = 0;
        return b;
      };
      F.prototype.text = function (b, d, h, l) {
        var n = {};
        if (l && (this.allowHTML || !this.forExport)) return this.html(b, d, h);
        n.x = Math.round(d || 0);
        h && (n.y = Math.round(h));
        f(b) && (n.text = b);
        b = this.createElement("text").attr(n);
        if (!l || this.forExport && !this.allowHTML) b.xSetter = function (b, d, h) {
          for (var f = h.getElementsByTagName("tspan"), l = h.getAttribute(d), n = 0, v; n < f.length; n++) v = f[n], v.getAttribute(d) === l && v.setAttribute(d, b);
          h.setAttribute(d, b);
        };
        return b;
      };
      F.prototype.fontMetrics = function (b, d) {
        b = !this.styledMode && /px/.test(b) || !H.getComputedStyle ? b || d && d.style && d.style.fontSize || this.style && this.style.fontSize : d && B.prototype.getStyle.call(d, "font-size");
        b = /px/.test(b) ? O(b) : 12;
        d = 24 > b ? b + 3 : Math.round(1.2 * b);
        return {
          h: d,
          b: Math.round(.8 * d),
          f: b
        };
      };
      F.prototype.rotCorr = function (b, d, h) {
        var f = b;
        d && h && (f = Math.max(f * Math.cos(d * a), 4));
        return {
          x: -b / 3 * Math.sin(d * a),
          y: f
        };
      };
      F.prototype.pathToSegments = function (b) {
        for (var d = [], f = [], l = {
            A: 8,
            C: 7,
            H: 2,
            L: 3,
            M: 3,
            Q: 5,
            S: 5,
            T: 3,
            V: 2
          }, n = 0; n < b.length; n++) E(f[0]) && h(b[n]) && f.length === l[f[0].toUpperCase()] && b.splice(n, 0, f[0].replace("M", "L").replace("m", "l")), "string" === typeof b[n] && (f.length && d.push(f.slice(0)), f.length = 0), f.push(b[n]);
        d.push(f.slice(0));
        return d;
      };
      F.prototype.label = function (b, d, h, f, l, n, v, F, e) {
        return new z(this, b, d, h, f, l, n, v, F, e);
      };
      F.prototype.alignElements = function () {
        this.alignedObjects.forEach(function (b) {
          return b.align();
        });
      };
      return F;
    }();
    d(y.prototype, {
      Element: B,
      SVG_NS: D,
      escapes: {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;"
      },
      symbols: C,
      draw: e
    });
    A.registerRendererType("svg", y, !0);
    "";
    return y;
  });
  I(g, "Core/Renderer/HTML/HTMLElement.js", [g["Core/Globals.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var L = this && this.__extends || function () {
        var a = function (k, e) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (e, a) {
            e.__proto__ = a;
          } || function (e, a) {
            for (var k in a) a.hasOwnProperty(k) && (e[k] = a[k]);
          };
          return a(k, e);
        };
        return function (k, e) {
          function m() {
            this.constructor = k;
          }
          a(k, e);
          k.prototype = null === e ? Object.create(e) : (m.prototype = e.prototype, new m());
        };
      }(),
      B = c.isFirefox,
      z = c.isMS,
      C = c.isWebKit,
      x = c.win,
      t = y.css,
      r = y.defined,
      a = y.extend,
      q = y.pick,
      m = y.pInt;
    return function (p) {
      function k() {
        return null !== p && p.apply(this, arguments) || this;
      }
      L(k, p);
      k.compose = function (e) {
        if (-1 === k.composedClasses.indexOf(e)) {
          k.composedClasses.push(e);
          var a = k.prototype,
            m = e.prototype;
          m.getSpanCorrection = a.getSpanCorrection;
          m.htmlCss = a.htmlCss;
          m.htmlGetBBox = a.htmlGetBBox;
          m.htmlUpdateTransform = a.htmlUpdateTransform;
          m.setSpanRotation = a.setSpanRotation;
        }
        return e;
      };
      k.prototype.getSpanCorrection = function (e, a, k) {
        this.xCorr = -e * k;
        this.yCorr = -a;
      };
      k.prototype.htmlCss = function (e) {
        var k = "SPAN" === this.element.tagName && e && "width" in e,
          m = q(k && e.width, void 0);
        if (k) {
          delete e.width;
          this.textWidth = m;
          var p = !0;
        }
        e && "ellipsis" === e.textOverflow && (e.whiteSpace = "nowrap", e.overflow = "hidden");
        this.styles = a(this.styles, e);
        t(this.element, e);
        p && this.htmlUpdateTransform();
        return this;
      };
      k.prototype.htmlGetBBox = function () {
        var e = this.element;
        return {
          x: e.offsetLeft,
          y: e.offsetTop,
          width: e.offsetWidth,
          height: e.offsetHeight
        };
      };
      k.prototype.htmlUpdateTransform = function () {
        if (this.added) {
          var e = this.renderer,
            a = this.element,
            k = this.translateX || 0,
            p = this.translateY || 0,
            c = this.x || 0,
            u = this.y || 0,
            n = this.textAlign || "left",
            w = {
              left: 0,
              center: .5,
              right: 1
            }[n],
            f = this.styles;
          f = f && f.whiteSpace;
          t(a, {
            marginLeft: k,
            marginTop: p
          });
          !e.styledMode && this.shadows && this.shadows.forEach(function (b) {
            t(b, {
              marginLeft: k + 1,
              marginTop: p + 1
            });
          });
          this.inverted && [].forEach.call(a.childNodes, function (b) {
            e.invertChild(b, a);
          });
          if ("SPAN" === a.tagName) {
            var l = this.rotation,
              d = this.textWidth && m(this.textWidth),
              b = [l, n, a.innerHTML, this.textWidth, this.textAlign].join(),
              h = void 0;
            h = !1;
            if (d !== this.oldTextWidth) {
              if (this.textPxLength) var v = this.textPxLength;else t(a, {
                width: "",
                whiteSpace: f || "nowrap"
              }), v = a.offsetWidth;
              (d > this.oldTextWidth || v > d) && (/[ \-]/.test(a.textContent || a.innerText) || "ellipsis" === a.style.textOverflow) && (t(a, {
                width: v > d || l ? d + "px" : "auto",
                display: "block",
                whiteSpace: f || "normal"
              }), this.oldTextWidth = d, h = !0);
            }
            this.hasBoxWidthChanged = h;
            b !== this.cTT && (h = e.fontMetrics(a.style.fontSize, a).b, !r(l) || l === (this.oldRotation || 0) && n === this.oldAlign || this.setSpanRotation(l, w, h), this.getSpanCorrection(!r(l) && this.textPxLength || a.offsetWidth, h, w, l, n));
            t(a, {
              left: c + (this.xCorr || 0) + "px",
              top: u + (this.yCorr || 0) + "px"
            });
            this.cTT = b;
            this.oldRotation = l;
            this.oldAlign = n;
          }
        } else this.alignOnAdd = !0;
      };
      k.prototype.setSpanRotation = function (e, a, k) {
        var m = {},
          p = z && !/Edge/.test(x.navigator.userAgent) ? "-ms-transform" : C ? "-webkit-transform" : B ? "MozTransform" : x.opera ? "-o-transform" : void 0;
        p && (m[p] = m.transform = "rotate(" + e + "deg)", m[p + (B ? "Origin" : "-origin")] = m.transformOrigin = 100 * a + "% " + k + "px", t(this.element, m));
      };
      k.composedClasses = [];
      return k;
    }(g);
  });
  I(g, "Core/Renderer/HTML/HTMLRenderer.js", [g["Core/Renderer/HTML/AST.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Renderer/SVG/SVGRenderer.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var L = this && this.__extends || function () {
        var c = function (a, q) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, p) {
            a.__proto__ = p;
          } || function (a, p) {
            for (var k in p) p.hasOwnProperty(k) && (a[k] = p[k]);
          };
          return c(a, q);
        };
        return function (a, q) {
          function m() {
            this.constructor = a;
          }
          c(a, q);
          a.prototype = null === q ? Object.create(q) : (m.prototype = q.prototype, new m());
        };
      }(),
      z = A.attr,
      C = A.createElement,
      x = A.extend,
      t = A.pick;
    return function (r) {
      function a() {
        return null !== r && r.apply(this, arguments) || this;
      }
      L(a, r);
      a.compose = function (c) {
        -1 === a.composedClasses.indexOf(c) && (a.composedClasses.push(c), c.prototype.html = a.prototype.html);
        return c;
      };
      a.prototype.html = function (a, m, p) {
        var k = this.createElement("span"),
          e = k.element,
          q = k.renderer,
          r = q.isSVG,
          H = function (e, a) {
            ["opacity", "visibility"].forEach(function (n) {
              e[n + "Setter"] = function (w, f, l) {
                var d = e.div ? e.div.style : a;
                g.prototype[n + "Setter"].call(this, w, f, l);
                d && (d[f] = w);
              };
            });
            e.addedSetters = !0;
          };
        k.textSetter = function (e) {
          e !== this.textStr && (delete this.bBox, delete this.oldTextWidth, c.setElementHTML(this.element, t(e, "")), this.textStr = e, k.doTransform = !0);
        };
        r && H(k, k.element.style);
        k.xSetter = k.ySetter = k.alignSetter = k.rotationSetter = function (e, a) {
          "align" === a ? k.alignValue = k.textAlign = e : k[a] = e;
          k.doTransform = !0;
        };
        k.afterSetters = function () {
          this.doTransform && (this.htmlUpdateTransform(), this.doTransform = !1);
        };
        k.attr({
          text: a,
          x: Math.round(m),
          y: Math.round(p)
        }).css({
          position: "absolute"
        });
        q.styledMode || k.css({
          fontFamily: this.style.fontFamily,
          fontSize: this.style.fontSize
        });
        e.style.whiteSpace = "nowrap";
        k.css = k.htmlCss;
        r && (k.add = function (a) {
          var m = q.box.parentNode,
            n = [];
          if (this.parentGroup = a) {
            var w = a.div;
            if (!w) {
              for (; a;) n.push(a), a = a.parentGroup;
              n.reverse().forEach(function (f) {
                function l(b, d) {
                  f[d] = b;
                  "translateX" === d ? h.left = b + "px" : h.top = b + "px";
                  f.doTransform = !0;
                }
                var d = z(f.element, "class"),
                  b = f.styles || {};
                w = f.div = f.div || C("div", d ? {
                  className: d
                } : void 0, {
                  position: "absolute",
                  left: (f.translateX || 0) + "px",
                  top: (f.translateY || 0) + "px",
                  display: f.display,
                  opacity: f.opacity,
                  cursor: b.cursor,
                  pointerEvents: b.pointerEvents,
                  visibility: f.visibility
                }, w || m);
                var h = w.style;
                x(f, {
                  classSetter: function (b) {
                    return function (d) {
                      this.element.setAttribute("class", d);
                      b.className = d;
                    };
                  }(w),
                  on: function () {
                    n[0].div && k.on.apply({
                      element: n[0].div,
                      onEvents: f.onEvents
                    }, arguments);
                    return f;
                  },
                  translateXSetter: l,
                  translateYSetter: l
                });
                f.addedSetters || H(f);
              });
            }
          } else w = m;
          w.appendChild(e);
          k.added = !0;
          k.alignOnAdd && k.htmlUpdateTransform();
          return k;
        });
        return k;
      };
      a.composedClasses = [];
      return a;
    }(y);
  });
  I(g, "Core/Axis/AxisDefaults.js", [], function () {
    var c;
    (function (c) {
      c.defaultXAxisOptions = {
        alignTicks: !0,
        allowDecimals: void 0,
        panningEnabled: !0,
        zIndex: 2,
        zoomEnabled: !0,
        dateTimeLabelFormats: {
          millisecond: {
            main: "%H:%M:%S.%L",
            range: !1
          },
          second: {
            main: "%H:%M:%S",
            range: !1
          },
          minute: {
            main: "%H:%M",
            range: !1
          },
          hour: {
            main: "%H:%M",
            range: !1
          },
          day: {
            main: "%e. %b"
          },
          week: {
            main: "%e. %b"
          },
          month: {
            main: "%b '%y"
          },
          year: {
            main: "%Y"
          }
        },
        endOnTick: !1,
        gridLineDashStyle: "Solid",
        gridZIndex: 1,
        labels: {
          autoRotation: void 0,
          autoRotationLimit: 80,
          distance: void 0,
          enabled: !0,
          indentation: 10,
          overflow: "justify",
          padding: 5,
          reserveSpace: void 0,
          rotation: void 0,
          staggerLines: 0,
          step: 0,
          useHTML: !1,
          x: 0,
          zIndex: 7,
          style: {
            color: "#666666",
            cursor: "default",
            fontSize: "11px"
          }
        },
        maxPadding: .01,
        minorGridLineDashStyle: "Solid",
        minorTickLength: 2,
        minorTickPosition: "outside",
        minPadding: .01,
        offset: void 0,
        opposite: !1,
        reversed: void 0,
        reversedStacks: !1,
        showEmpty: !0,
        showFirstLabel: !0,
        showLastLabel: !0,
        startOfWeek: 1,
        startOnTick: !1,
        tickLength: 10,
        tickPixelInterval: 100,
        tickmarkPlacement: "between",
        tickPosition: "outside",
        title: {
          align: "middle",
          rotation: 0,
          useHTML: !1,
          x: 0,
          y: 0,
          style: {
            color: "#666666"
          }
        },
        type: "linear",
        uniqueNames: !0,
        visible: !0,
        minorGridLineColor: "#f2f2f2",
        minorGridLineWidth: 1,
        minorTickColor: "#999999",
        lineColor: "#ccd6eb",
        lineWidth: 1,
        gridLineColor: "#e6e6e6",
        gridLineWidth: void 0,
        tickColor: "#ccd6eb"
      };
      c.defaultYAxisOptions = {
        reversedStacks: !0,
        endOnTick: !0,
        maxPadding: .05,
        minPadding: .05,
        tickPixelInterval: 72,
        showLastLabel: !0,
        labels: {
          x: -8
        },
        startOnTick: !0,
        title: {
          rotation: 270,
          text: "Values"
        },
        stackLabels: {
          animation: {},
          allowOverlap: !1,
          enabled: !1,
          crop: !0,
          overflow: "justify",
          formatter: function () {
            var c = this.axis.chart.numberFormatter;
            return c(this.total || 0, -1);
          },
          style: {
            color: "#000000",
            fontSize: "11px",
            fontWeight: "bold",
            textOutline: "1px contrast"
          }
        },
        gridLineWidth: 1,
        lineWidth: 0
      };
      c.defaultLeftAxisOptions = {
        labels: {
          x: -15
        },
        title: {
          rotation: 270
        }
      };
      c.defaultRightAxisOptions = {
        labels: {
          x: 15
        },
        title: {
          rotation: 90
        }
      };
      c.defaultBottomAxisOptions = {
        labels: {
          autoRotation: [-45],
          x: 0
        },
        margin: 15,
        title: {
          rotation: 0
        }
      };
      c.defaultTopAxisOptions = {
        labels: {
          autoRotation: [-45],
          x: 0
        },
        margin: 15,
        title: {
          rotation: 0
        }
      };
    })(c || (c = {}));
    return c;
  });
  I(g, "Core/Foundation.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.addEvent,
      y = c.isFunction,
      A = c.objectEach,
      B = c.removeEvent,
      z;
    (function (c) {
      c.registerEventOptions = function (c, t) {
        c.eventOptions = c.eventOptions || {};
        A(t.events, function (r, a) {
          c.eventOptions[a] !== r && (c.eventOptions[a] && (B(c, a, c.eventOptions[a]), delete c.eventOptions[a]), y(r) && (c.eventOptions[a] = r, g(c, a, r)));
        });
      };
    })(z || (z = {}));
    return z;
  });
  I(g, "Core/Axis/Tick.js", [g["Core/FormatUtilities.js"], g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var L = g.deg2rad,
      B = y.clamp,
      z = y.correctFloat,
      C = y.defined,
      x = y.destroyObjectProperties,
      t = y.extend,
      r = y.fireEvent,
      a = y.isNumber,
      q = y.merge,
      m = y.objectEach,
      p = y.pick;
    g = function () {
      function k(e, a, k, m, p) {
        this.isNewLabel = this.isNew = !0;
        this.axis = e;
        this.pos = a;
        this.type = k || "";
        this.parameters = p || {};
        this.tickmarkOffset = this.parameters.tickmarkOffset;
        this.options = this.parameters.options;
        r(this, "init");
        k || m || this.addLabel();
      }
      k.prototype.addLabel = function () {
        var e = this,
          k = e.axis,
          m = k.options,
          q = k.chart,
          g = k.categories,
          u = k.logarithmic,
          n = k.names,
          w = e.pos,
          f = p(e.options && e.options.labels, m.labels),
          l = k.tickPositions,
          d = w === l[0],
          b = w === l[l.length - 1],
          h = (!f.step || 1 === f.step) && 1 === k.tickInterval;
        l = l.info;
        var v = e.label,
          E;
        g = this.parameters.category || (g ? p(g[w], n[w], w) : w);
        u && a(g) && (g = z(u.lin2log(g)));
        if (k.dateTime) if (l) {
          var J = q.time.resolveDTLFormat(m.dateTimeLabelFormats[!m.grid && l.higherRanks[w] || l.unitName]);
          var M = J.main;
        } else a(g) && (M = k.dateTime.getXDateFormat(g, m.dateTimeLabelFormats || {}));
        e.isFirst = d;
        e.isLast = b;
        var O = {
          axis: k,
          chart: q,
          dateTimeLabelFormat: M,
          isFirst: d,
          isLast: b,
          pos: w,
          tick: e,
          tickPositionInfo: l,
          value: g
        };
        r(this, "labelFormat", O);
        var P = function (b) {
          return f.formatter ? f.formatter.call(b, b) : f.format ? (b.text = k.defaultLabelFormatter.call(b, b), c.format(f.format, b, q)) : k.defaultLabelFormatter.call(b, b);
        };
        m = P.call(O, O);
        var T = J && J.list;
        e.shortenLabel = T ? function () {
          for (E = 0; E < T.length; E++) if (t(O, {
            dateTimeLabelFormat: T[E]
          }), v.attr({
            text: P.call(O, O)
          }), v.getBBox().width < k.getSlotWidth(e) - 2 * f.padding) return;
          v.attr({
            text: ""
          });
        } : void 0;
        h && k._addedPlotLB && e.moveLabel(m, f);
        C(v) || e.movedLabel ? v && v.textStr !== m && !h && (!v.textWidth || f.style.width || v.styles.width || v.css({
          width: null
        }), v.attr({
          text: m
        }), v.textPxLength = v.getBBox().width) : (e.label = v = e.createLabel({
          x: 0,
          y: 0
        }, m, f), e.rotation = 0);
      };
      k.prototype.createLabel = function (e, a, k) {
        var m = this.axis,
          p = m.chart;
        if (e = C(a) && k.enabled ? p.renderer.text(a, e.x, e.y, k.useHTML).add(m.labelGroup) : null) p.styledMode || e.css(q(k.style)), e.textPxLength = e.getBBox().width;
        return e;
      };
      k.prototype.destroy = function () {
        x(this, this.axis);
      };
      k.prototype.getPosition = function (e, a, k, m) {
        var p = this.axis,
          u = p.chart,
          n = m && u.oldChartHeight || u.chartHeight;
        e = {
          x: e ? z(p.translate(a + k, void 0, void 0, m) + p.transB) : p.left + p.offset + (p.opposite ? (m && u.oldChartWidth || u.chartWidth) - p.right - p.left : 0),
          y: e ? n - p.bottom + p.offset - (p.opposite ? p.height : 0) : z(n - p.translate(a + k, void 0, void 0, m) - p.transB)
        };
        e.y = B(e.y, -1E5, 1E5);
        r(this, "afterGetPosition", {
          pos: e
        });
        return e;
      };
      k.prototype.getLabelPosition = function (e, a, k, m, p, u, n, w) {
        var f = this.axis,
          l = f.transA,
          d = f.isLinked && f.linkedParent ? f.linkedParent.reversed : f.reversed,
          b = f.staggerLines,
          h = f.tickRotCorr || {
            x: 0,
            y: 0
          },
          v = m || f.reserveSpaceDefault ? 0 : -f.labelOffset * ("center" === f.labelAlign ? .5 : 1),
          E = {};
        k = 0 === f.side ? k.rotation ? -8 : -k.getBBox().height : 2 === f.side ? h.y + 8 : Math.cos(k.rotation * L) * (h.y - k.getBBox(!1, 0).height / 2);
        C(p.y) && (k = 0 === f.side && f.horiz ? p.y + k : p.y);
        e = e + p.x + v + h.x - (u && m ? u * l * (d ? -1 : 1) : 0);
        a = a + k - (u && !m ? u * l * (d ? 1 : -1) : 0);
        b && (m = n / (w || 1) % b, f.opposite && (m = b - m - 1), a += f.labelOffset / b * m);
        E.x = e;
        E.y = Math.round(a);
        r(this, "afterGetLabelPosition", {
          pos: E,
          tickmarkOffset: u,
          index: n
        });
        return E;
      };
      k.prototype.getLabelSize = function () {
        return this.label ? this.label.getBBox()[this.axis.horiz ? "height" : "width"] : 0;
      };
      k.prototype.getMarkPath = function (e, a, k, m, p, u) {
        return u.crispLine([["M", e, a], ["L", e + (p ? 0 : -k), a + (p ? k : 0)]], m);
      };
      k.prototype.handleOverflow = function (e) {
        var a = this.axis,
          k = a.options.labels,
          m = e.x,
          c = a.chart.chartWidth,
          u = a.chart.spacing,
          n = p(a.labelLeft, Math.min(a.pos, u[3]));
        u = p(a.labelRight, Math.max(a.isRadial ? 0 : a.pos + a.len, c - u[1]));
        var w = this.label,
          f = this.rotation,
          l = {
            left: 0,
            center: .5,
            right: 1
          }[a.labelAlign || w.attr("align")],
          d = w.getBBox().width,
          b = a.getSlotWidth(this),
          h = {},
          v = b,
          E = 1,
          q;
        if (f || "justify" !== k.overflow) 0 > f && m - l * d < n ? q = Math.round(m / Math.cos(f * L) - n) : 0 < f && m + l * d > u && (q = Math.round((c - m) / Math.cos(f * L)));else if (c = m + (1 - l) * d, m - l * d < n ? v = e.x + v * (1 - l) - n : c > u && (v = u - e.x + v * l, E = -1), v = Math.min(b, v), v < b && "center" === a.labelAlign && (e.x += E * (b - v - l * (b - Math.min(d, v)))), d > v || a.autoRotation && (w.styles || {}).width) q = v;
        q && (this.shortenLabel ? this.shortenLabel() : (h.width = Math.floor(q) + "px", (k.style || {}).textOverflow || (h.textOverflow = "ellipsis"), w.css(h)));
      };
      k.prototype.moveLabel = function (a, k) {
        var e = this,
          p = e.label,
          c = e.axis,
          u = c.reversed,
          n = !1;
        p && p.textStr === a ? (e.movedLabel = p, n = !0, delete e.label) : m(c.ticks, function (f) {
          n || f.isNew || f === e || !f.label || f.label.textStr !== a || (e.movedLabel = f.label, n = !0, f.labelPos = e.movedLabel.xy, delete f.label);
        });
        if (!n && (e.labelPos || p)) {
          var w = e.labelPos || p.xy;
          p = c.horiz ? u ? 0 : c.width + c.left : w.x;
          c = c.horiz ? w.y : u ? c.width + c.left : 0;
          e.movedLabel = e.createLabel({
            x: p,
            y: c
          }, a, k);
          e.movedLabel && e.movedLabel.attr({
            opacity: 0
          });
        }
      };
      k.prototype.render = function (a, k, m) {
        var e = this.axis,
          c = e.horiz,
          u = this.pos,
          n = p(this.tickmarkOffset, e.tickmarkOffset);
        u = this.getPosition(c, u, n, k);
        n = u.x;
        var w = u.y;
        e = c && n === e.pos + e.len || !c && w === e.pos ? -1 : 1;
        c = p(m, this.label && this.label.newOpacity, 1);
        m = p(m, 1);
        this.isActive = !0;
        this.renderGridLine(k, m, e);
        this.renderMark(u, m, e);
        this.renderLabel(u, k, c, a);
        this.isNew = !1;
        r(this, "afterRender");
      };
      k.prototype.renderGridLine = function (e, a, k) {
        var m = this.axis,
          c = m.options,
          u = {},
          n = this.pos,
          w = this.type,
          f = p(this.tickmarkOffset, m.tickmarkOffset),
          l = m.chart.renderer,
          d = this.gridLine,
          b = c.gridLineWidth,
          h = c.gridLineColor,
          v = c.gridLineDashStyle;
        "minor" === this.type && (b = c.minorGridLineWidth, h = c.minorGridLineColor, v = c.minorGridLineDashStyle);
        d || (m.chart.styledMode || (u.stroke = h, u["stroke-width"] = b || 0, u.dashstyle = v), w || (u.zIndex = 1), e && (a = 0), this.gridLine = d = l.path().attr(u).addClass("highcharts-" + (w ? w + "-" : "") + "grid-line").add(m.gridGroup));
        if (d && (k = m.getPlotLinePath({
          value: n + f,
          lineWidth: d.strokeWidth() * k,
          force: "pass",
          old: e,
          acrossPanes: !1
        }))) d[e || this.isNew ? "attr" : "animate"]({
          d: k,
          opacity: a
        });
      };
      k.prototype.renderMark = function (a, k, m) {
        var e = this.axis,
          c = e.options,
          u = e.chart.renderer,
          n = this.type,
          w = e.tickSize(n ? n + "Tick" : "tick"),
          f = a.x;
        a = a.y;
        var l = p(c["minor" !== n ? "tickWidth" : "minorTickWidth"], !n && e.isXAxis ? 1 : 0);
        c = c["minor" !== n ? "tickColor" : "minorTickColor"];
        var d = this.mark,
          b = !d;
        w && (e.opposite && (w[0] = -w[0]), d || (this.mark = d = u.path().addClass("highcharts-" + (n ? n + "-" : "") + "tick").add(e.axisGroup), e.chart.styledMode || d.attr({
          stroke: c,
          "stroke-width": l
        })), d[b ? "attr" : "animate"]({
          d: this.getMarkPath(f, a, w[0], d.strokeWidth() * m, e.horiz, u),
          opacity: k
        }));
      };
      k.prototype.renderLabel = function (e, k, m, c) {
        var q = this.axis,
          u = q.horiz,
          n = q.options,
          w = this.label,
          f = n.labels,
          l = f.step;
        q = p(this.tickmarkOffset, q.tickmarkOffset);
        var d = e.x;
        e = e.y;
        var b = !0;
        w && a(d) && (w.xy = e = this.getLabelPosition(d, e, w, u, f, q, c, l), this.isFirst && !this.isLast && !n.showFirstLabel || this.isLast && !this.isFirst && !n.showLastLabel ? b = !1 : !u || f.step || f.rotation || k || 0 === m || this.handleOverflow(e), l && c % l && (b = !1), b && a(e.y) ? (e.opacity = m, w[this.isNewLabel ? "attr" : "animate"](e).show(!0), this.isNewLabel = !1) : (w.hide(), this.isNewLabel = !0));
      };
      k.prototype.replaceMovedLabel = function () {
        var a = this.label,
          k = this.axis,
          m = k.reversed;
        if (a && !this.isNew) {
          var c = k.horiz ? m ? k.left : k.width + k.left : a.xy.x;
          m = k.horiz ? a.xy.y : m ? k.width + k.top : k.top;
          a.animate({
            x: c,
            y: m,
            opacity: 0
          }, void 0, a.destroy);
          delete this.label;
        }
        k.isDirty = !0;
        this.label = this.movedLabel;
        delete this.movedLabel;
      };
      return k;
    }();
    "";
    return g;
  });
  I(g, "Core/Axis/Axis.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Axis/AxisDefaults.js"], g["Core/Color/Color.js"], g["Core/Defaults.js"], g["Core/Foundation.js"], g["Core/Globals.js"], g["Core/Axis/Tick.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C, x) {
    var t = c.animObject,
      r = A.defaultOptions,
      a = B.registerEventOptions,
      q = z.deg2rad,
      m = x.arrayMax,
      p = x.arrayMin,
      k = x.clamp,
      e = x.correctFloat,
      D = x.defined,
      G = x.destroyObjectProperties,
      H = x.erase,
      K = x.error,
      u = x.extend,
      n = x.fireEvent,
      w = x.isArray,
      f = x.isNumber,
      l = x.isString,
      d = x.merge,
      b = x.normalizeTickInterval,
      h = x.objectEach,
      v = x.pick,
      E = x.relativeLength,
      J = x.removeEvent,
      M = x.splat,
      O = x.syncTimeout,
      P = function (d, h) {
        return b(h, void 0, void 0, v(d.options.allowDecimals, .5 > h || void 0 !== d.tickAmount), !!d.tickAmount);
      };
    c = function () {
      function b(b, d) {
        this.zoomEnabled = this.width = this.visible = this.userOptions = this.translationSlope = this.transB = this.transA = this.top = this.ticks = this.tickRotCorr = this.tickPositions = this.tickmarkOffset = this.tickInterval = this.tickAmount = this.side = this.series = this.right = this.positiveValuesOnly = this.pos = this.pointRangePadding = this.pointRange = this.plotLinesAndBandsGroups = this.plotLinesAndBands = this.paddedTicks = this.overlap = this.options = this.offset = this.names = this.minPixelPadding = this.minorTicks = this.minorTickInterval = this.min = this.maxLabelLength = this.max = this.len = this.left = this.labelFormatter = this.labelEdge = this.isLinked = this.height = this.hasVisibleSeries = this.hasNames = this.eventOptions = this.coll = this.closestPointRange = this.chart = this.bottom = this.alternateBands = void 0;
        this.init(b, d);
      }
      b.prototype.init = function (b, d) {
        var h = d.isX;
        this.chart = b;
        this.horiz = b.inverted && !this.isZAxis ? !h : h;
        this.isXAxis = h;
        this.coll = this.coll || (h ? "xAxis" : "yAxis");
        n(this, "init", {
          userOptions: d
        });
        this.opposite = v(d.opposite, this.opposite);
        this.side = v(d.side, this.side, this.horiz ? this.opposite ? 0 : 2 : this.opposite ? 1 : 3);
        this.setOptions(d);
        var l = this.options,
          e = l.labels,
          F = l.type;
        this.userOptions = d;
        this.minPixelPadding = 0;
        this.reversed = v(l.reversed, this.reversed);
        this.visible = l.visible;
        this.zoomEnabled = l.zoomEnabled;
        this.hasNames = "category" === F || !0 === l.categories;
        this.categories = l.categories || (this.hasNames ? [] : void 0);
        this.names || (this.names = [], this.names.keys = {});
        this.plotLinesAndBandsGroups = {};
        this.positiveValuesOnly = !!this.logarithmic;
        this.isLinked = D(l.linkedTo);
        this.ticks = {};
        this.labelEdge = [];
        this.minorTicks = {};
        this.plotLinesAndBands = [];
        this.alternateBands = {};
        this.len = 0;
        this.minRange = this.userMinRange = l.minRange || l.maxZoom;
        this.range = l.range;
        this.offset = l.offset || 0;
        this.min = this.max = null;
        d = v(l.crosshair, M(b.options.tooltip.crosshairs)[h ? 0 : 1]);
        this.crosshair = !0 === d ? {} : d;
        -1 === b.axes.indexOf(this) && (h ? b.axes.splice(b.xAxis.length, 0, this) : b.axes.push(this), b[this.coll].push(this));
        this.series = this.series || [];
        b.inverted && !this.isZAxis && h && "undefined" === typeof this.reversed && (this.reversed = !0);
        this.labelRotation = f(e.rotation) ? e.rotation : void 0;
        a(this, l);
        n(this, "afterInit");
      };
      b.prototype.setOptions = function (b) {
        this.options = d(g.defaultXAxisOptions, "yAxis" === this.coll && g.defaultYAxisOptions, [g.defaultTopAxisOptions, g.defaultRightAxisOptions, g.defaultBottomAxisOptions, g.defaultLeftAxisOptions][this.side], d(r[this.coll], b));
        n(this, "afterSetOptions", {
          userOptions: b
        });
      };
      b.prototype.defaultLabelFormatter = function (b) {
        var d = this.axis;
        b = this.chart.numberFormatter;
        var h = f(this.value) ? this.value : NaN,
          l = d.chart.time,
          n = this.dateTimeLabelFormat,
          v = r.lang,
          a = v.numericSymbols;
        v = v.numericSymbolMagnitude || 1E3;
        var e = d.logarithmic ? Math.abs(h) : d.tickInterval,
          F = a && a.length;
        if (d.categories) var k = "".concat(this.value);else if (n) k = l.dateFormat(n, h);else if (F && 1E3 <= e) for (; F-- && "undefined" === typeof k;) d = Math.pow(v, F + 1), e >= d && 0 === 10 * h % d && null !== a[F] && 0 !== h && (k = b(h / d, -1) + a[F]);
        "undefined" === typeof k && (k = 1E4 <= Math.abs(h) ? b(h, -1) : b(h, -1, void 0, ""));
        return k;
      };
      b.prototype.getSeriesExtremes = function () {
        var b = this,
          d = b.chart,
          h;
        n(this, "getSeriesExtremes", null, function () {
          b.hasVisibleSeries = !1;
          b.dataMin = b.dataMax = b.threshold = null;
          b.softThreshold = !b.isXAxis;
          b.series.forEach(function (l) {
            if (l.visible || !d.options.chart.ignoreHiddenSeries) {
              var n = l.options,
                a = n.threshold;
              b.hasVisibleSeries = !0;
              b.positiveValuesOnly && 0 >= a && (a = null);
              if (b.isXAxis) {
                if (n = l.xData, n.length) {
                  n = b.logarithmic ? n.filter(b.validatePositiveValue) : n;
                  h = l.getXExtremes(n);
                  var e = h.min;
                  var F = h.max;
                  f(e) || e instanceof Date || (n = n.filter(f), h = l.getXExtremes(n), e = h.min, F = h.max);
                  n.length && (b.dataMin = Math.min(v(b.dataMin, e), e), b.dataMax = Math.max(v(b.dataMax, F), F));
                }
              } else if (l = l.applyExtremes(), f(l.dataMin) && (e = l.dataMin, b.dataMin = Math.min(v(b.dataMin, e), e)), f(l.dataMax) && (F = l.dataMax, b.dataMax = Math.max(v(b.dataMax, F), F)), D(a) && (b.threshold = a), !n.softThreshold || b.positiveValuesOnly) b.softThreshold = !1;
            }
          });
        });
        n(this, "afterGetSeriesExtremes");
      };
      b.prototype.translate = function (b, d, h, l, n, v) {
        var a = this.linkedParent || this,
          F = l && a.old ? a.old.min : a.min;
        if (!f(F)) return NaN;
        var k = a.minPixelPadding;
        n = (a.isOrdinal || a.brokenAxis && a.brokenAxis.hasBreaks || a.logarithmic && n) && a.lin2val;
        var w = 1,
          m = 0;
        l = l && a.old ? a.old.transA : a.transA;
        l || (l = a.transA);
        h && (w *= -1, m = a.len);
        a.reversed && (w *= -1, m -= w * (a.sector || a.len));
        d ? (v = (b * w + m - k) / l + F, n && (v = a.lin2val(v))) : (n && (b = a.val2lin(b)), b = w * (b - F) * l, v = (a.isRadial ? b : e(b)) + m + w * k + (f(v) ? l * v : 0));
        return v;
      };
      b.prototype.toPixels = function (b, d) {
        return this.translate(b, !1, !this.horiz, void 0, !0) + (d ? 0 : this.pos);
      };
      b.prototype.toValue = function (b, d) {
        return this.translate(b - (d ? 0 : this.pos), !0, !this.horiz, void 0, !0);
      };
      b.prototype.getPlotLinePath = function (b) {
        function d(b, d, h) {
          "pass" !== q && (b < d || b > h) && (q ? b = k(b, d, h) : M = !0);
          return b;
        }
        var h = this,
          l = h.chart,
          a = h.left,
          e = h.top,
          F = b.old,
          w = b.value,
          m = b.lineWidth,
          E = F && l.oldChartHeight || l.chartHeight,
          c = F && l.oldChartWidth || l.chartWidth,
          p = h.transB,
          u = b.translatedValue,
          q = b.force,
          J,
          r,
          g,
          t,
          M;
        b = {
          value: w,
          lineWidth: m,
          old: F,
          force: q,
          acrossPanes: b.acrossPanes,
          translatedValue: u
        };
        n(this, "getPlotLinePath", b, function (b) {
          u = v(u, h.translate(w, void 0, void 0, F));
          u = k(u, -1E5, 1E5);
          J = g = Math.round(u + p);
          r = t = Math.round(E - u - p);
          f(u) ? h.horiz ? (r = e, t = E - h.bottom, J = g = d(J, a, a + h.width)) : (J = a, g = c - h.right, r = t = d(r, e, e + h.height)) : (M = !0, q = !1);
          b.path = M && !q ? null : l.renderer.crispLine([["M", J, r], ["L", g, t]], m || 1);
        });
        return b.path;
      };
      b.prototype.getLinearTickPositions = function (b, d, h) {
        var f = e(Math.floor(d / b) * b);
        h = e(Math.ceil(h / b) * b);
        var l = [],
          n;
        e(f + b) === f && (n = 20);
        if (this.single) return [d];
        for (d = f; d <= h;) {
          l.push(d);
          d = e(d + b, n);
          if (d === a) break;
          var a = d;
        }
        return l;
      };
      b.prototype.getMinorTickInterval = function () {
        var b = this.options;
        return !0 === b.minorTicks ? v(b.minorTickInterval, "auto") : !1 === b.minorTicks ? null : b.minorTickInterval;
      };
      b.prototype.getMinorTickPositions = function () {
        var b = this.options,
          d = this.tickPositions,
          h = this.minorTickInterval,
          f = this.pointRangePadding || 0,
          l = this.min - f;
        f = this.max + f;
        var n = f - l,
          a = [];
        if (n && n / h < this.len / 3) {
          var v = this.logarithmic;
          if (v) this.paddedTicks.forEach(function (b, d, f) {
            d && a.push.apply(a, v.getLogTickPositions(h, f[d - 1], f[d], !0));
          });else if (this.dateTime && "auto" === this.getMinorTickInterval()) a = a.concat(this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(h), l, f, b.startOfWeek));else for (b = l + (d[0] - l) % h; b <= f && b !== a[0]; b += h) a.push(b);
        }
        0 !== a.length && this.trimTicks(a);
        return a;
      };
      b.prototype.adjustForMinRange = function () {
        var b = this.options,
          d = this.logarithmic,
          h = this.min,
          f = this.max,
          l = 0,
          n,
          a,
          e,
          k;
        this.isXAxis && "undefined" === typeof this.minRange && !d && (D(b.min) || D(b.max) || D(b.floor) || D(b.ceiling) ? this.minRange = null : (this.series.forEach(function (b) {
          e = b.xData;
          k = b.xIncrement ? 1 : e.length - 1;
          if (1 < e.length) for (n = k; 0 < n; n--) if (a = e[n] - e[n - 1], !l || a < l) l = a;
        }), this.minRange = Math.min(5 * l, this.dataMax - this.dataMin)));
        if (f - h < this.minRange) {
          var w = this.dataMax - this.dataMin >= this.minRange;
          var E = this.minRange;
          var c = (E - f + h) / 2;
          c = [h - c, v(b.min, h - c)];
          w && (c[2] = this.logarithmic ? this.logarithmic.log2lin(this.dataMin) : this.dataMin);
          h = m(c);
          f = [h + E, v(b.max, h + E)];
          w && (f[2] = d ? d.log2lin(this.dataMax) : this.dataMax);
          f = p(f);
          f - h < E && (c[0] = f - E, c[1] = v(b.min, f - E), h = m(c));
        }
        this.min = h;
        this.max = f;
      };
      b.prototype.getClosest = function () {
        var b;
        this.categories ? b = 1 : this.series.forEach(function (d) {
          var h = d.closestPointRange,
            f = d.visible || !d.chart.options.chart.ignoreHiddenSeries;
          !d.noSharedTooltip && D(h) && f && (b = D(b) ? Math.min(b, h) : h);
        });
        return b;
      };
      b.prototype.nameToX = function (b) {
        var d = w(this.options.categories),
          h = d ? this.categories : this.names,
          f = b.options.x;
        b.series.requireSorting = !1;
        D(f) || (f = this.options.uniqueNames && h ? d ? h.indexOf(b.name) : v(h.keys[b.name], -1) : b.series.autoIncrement());
        if (-1 === f) {
          if (!d && h) var l = h.length;
        } else l = f;
        "undefined" !== typeof l ? (this.names[l] = b.name, this.names.keys[b.name] = l) : b.x && (l = b.x);
        return l;
      };
      b.prototype.updateNames = function () {
        var b = this,
          d = this.names;
        0 < d.length && (Object.keys(d.keys).forEach(function (b) {
          delete d.keys[b];
        }), d.length = 0, this.minRange = this.userMinRange, (this.series || []).forEach(function (d) {
          d.xIncrement = null;
          if (!d.points || d.isDirtyData) b.max = Math.max(b.max, d.xData.length - 1), d.processData(), d.generatePoints();
          d.data.forEach(function (h, f) {
            if (h && h.options && "undefined" !== typeof h.name) {
              var l = b.nameToX(h);
              "undefined" !== typeof l && l !== h.x && (h.x = l, d.xData[f] = l);
            }
          });
        }));
      };
      b.prototype.setAxisTranslation = function () {
        var b = this,
          d = b.max - b.min,
          h = b.linkedParent,
          f = !!b.categories,
          a = b.isXAxis,
          e = b.axisPointRange || 0,
          k = 0,
          w = 0,
          m = b.transA;
        if (a || f || e) {
          var E = b.getClosest();
          h ? (k = h.minPointOffset, w = h.pointRangePadding) : b.series.forEach(function (d) {
            var h = f ? 1 : a ? v(d.options.pointRange, E, 0) : b.axisPointRange || 0,
              n = d.options.pointPlacement;
            e = Math.max(e, h);
            if (!b.single || f) d = d.is("xrange") ? !a : a, k = Math.max(k, d && l(n) ? 0 : h / 2), w = Math.max(w, d && "on" === n ? 0 : h);
          });
          h = b.ordinal && b.ordinal.slope && E ? b.ordinal.slope / E : 1;
          b.minPointOffset = k *= h;
          b.pointRangePadding = w *= h;
          b.pointRange = Math.min(e, b.single && f ? 1 : d);
          a && (b.closestPointRange = E);
        }
        b.translationSlope = b.transA = m = b.staticScale || b.len / (d + w || 1);
        b.transB = b.horiz ? b.left : b.bottom;
        b.minPixelPadding = m * k;
        n(this, "afterSetAxisTranslation");
      };
      b.prototype.minFromRange = function () {
        return this.max - this.range;
      };
      b.prototype.setTickInterval = function (b) {
        var d = this.chart,
          h = this.logarithmic,
          l = this.options,
          a = this.isXAxis,
          k = this.isLinked,
          w = l.tickPixelInterval,
          F = this.categories,
          m = this.softThreshold,
          E = l.maxPadding,
          c = l.minPadding,
          p = f(l.tickInterval) && 0 <= l.tickInterval ? l.tickInterval : void 0,
          u = f(this.threshold) ? this.threshold : null;
        this.dateTime || F || k || this.getTickAmount();
        var q = v(this.userMin, l.min);
        var J = v(this.userMax, l.max);
        if (k) {
          this.linkedParent = d[this.coll][l.linkedTo];
          var r = this.linkedParent.getExtremes();
          this.min = v(r.min, r.dataMin);
          this.max = v(r.max, r.dataMax);
          l.type !== this.linkedParent.options.type && K(11, 1, d);
        } else {
          if (m && D(u)) if (this.dataMin >= u) r = u, c = 0;else if (this.dataMax <= u) {
            var g = u;
            E = 0;
          }
          this.min = v(q, r, this.dataMin);
          this.max = v(J, g, this.dataMax);
        }
        h && (this.positiveValuesOnly && !b && 0 >= Math.min(this.min, v(this.dataMin, this.min)) && K(10, 1, d), this.min = e(h.log2lin(this.min), 16), this.max = e(h.log2lin(this.max), 16));
        this.range && D(this.max) && (this.userMin = this.min = q = Math.max(this.dataMin, this.minFromRange()), this.userMax = J = this.max, this.range = null);
        n(this, "foundExtremes");
        this.beforePadding && this.beforePadding();
        this.adjustForMinRange();
        !(F || this.axisPointRange || this.stacking && this.stacking.usePercentage || k) && D(this.min) && D(this.max) && (d = this.max - this.min) && (!D(q) && c && (this.min -= d * c), !D(J) && E && (this.max += d * E));
        f(this.userMin) || (f(l.softMin) && l.softMin < this.min && (this.min = q = l.softMin), f(l.floor) && (this.min = Math.max(this.min, l.floor)));
        f(this.userMax) || (f(l.softMax) && l.softMax > this.max && (this.max = J = l.softMax), f(l.ceiling) && (this.max = Math.min(this.max, l.ceiling)));
        m && D(this.dataMin) && (u = u || 0, !D(q) && this.min < u && this.dataMin >= u ? this.min = this.options.minRange ? Math.min(u, this.max - this.minRange) : u : !D(J) && this.max > u && this.dataMax <= u && (this.max = this.options.minRange ? Math.max(u, this.min + this.minRange) : u));
        f(this.min) && f(this.max) && !this.chart.polar && this.min > this.max && (D(this.options.min) ? this.max = this.min : D(this.options.max) && (this.min = this.max));
        this.tickInterval = this.min === this.max || "undefined" === typeof this.min || "undefined" === typeof this.max ? 1 : k && this.linkedParent && !p && w === this.linkedParent.options.tickPixelInterval ? p = this.linkedParent.tickInterval : v(p, this.tickAmount ? (this.max - this.min) / Math.max(this.tickAmount - 1, 1) : void 0, F ? 1 : (this.max - this.min) * w / Math.max(this.len, w));
        if (a && !b) {
          var t = this.min !== (this.old && this.old.min) || this.max !== (this.old && this.old.max);
          this.series.forEach(function (b) {
            b.forceCrop = b.forceCropping && b.forceCropping();
            b.processData(t);
          });
          n(this, "postProcessData", {
            hasExtremesChanged: t
          });
        }
        this.setAxisTranslation();
        n(this, "initialAxisTranslation");
        this.pointRange && !p && (this.tickInterval = Math.max(this.pointRange, this.tickInterval));
        b = v(l.minTickInterval, this.dateTime && !this.series.some(function (b) {
          return b.noSharedTooltip;
        }) ? this.closestPointRange : 0);
        !p && this.tickInterval < b && (this.tickInterval = b);
        this.dateTime || this.logarithmic || p || (this.tickInterval = P(this, this.tickInterval));
        this.tickAmount || (this.tickInterval = this.unsquish());
        this.setTickPositions();
      };
      b.prototype.setTickPositions = function () {
        var b = this.options,
          d = b.tickPositions,
          h = b.tickPositioner,
          l = this.getMinorTickInterval(),
          a = this.hasVerticalPanning(),
          v = "colorAxis" === this.coll,
          e = (v || !a) && b.startOnTick;
        a = (v || !a) && b.endOnTick;
        v = [];
        var k;
        this.tickmarkOffset = this.categories && "between" === b.tickmarkPlacement && 1 === this.tickInterval ? .5 : 0;
        this.minorTickInterval = "auto" === l && this.tickInterval ? this.tickInterval / 5 : l;
        this.single = this.min === this.max && D(this.min) && !this.tickAmount && (parseInt(this.min, 10) === this.min || !1 !== b.allowDecimals);
        if (d) v = d.slice();else if (f(this.min) && f(this.max)) {
          if (this.ordinal && this.ordinal.positions || !((this.max - this.min) / this.tickInterval > Math.max(2 * this.len, 200))) {
            if (this.dateTime) v = this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(this.tickInterval, b.units), this.min, this.max, b.startOfWeek, this.ordinal && this.ordinal.positions, this.closestPointRange, !0);else if (this.logarithmic) v = this.logarithmic.getLogTickPositions(this.tickInterval, this.min, this.max);else for (l = b = this.tickInterval; l <= 2 * b;) if (v = this.getLinearTickPositions(this.tickInterval, this.min, this.max), this.tickAmount && v.length > this.tickAmount) this.tickInterval = P(this, l *= 1.1);else break;
          } else v = [this.min, this.max], K(19, !1, this.chart);
          v.length > this.len && (v = [v[0], v[v.length - 1]], v[0] === v[1] && (v.length = 1));
          h && (this.tickPositions = v, (k = h.apply(this, [this.min, this.max])) && (v = k));
        }
        this.tickPositions = v;
        this.paddedTicks = v.slice(0);
        this.trimTicks(v, e, a);
        !this.isLinked && f(this.min) && f(this.max) && (this.single && 2 > v.length && !this.categories && !this.series.some(function (b) {
          return b.is("heatmap") && "between" === b.options.pointPlacement;
        }) && (this.min -= .5, this.max += .5), d || k || this.adjustTickAmount());
        n(this, "afterSetTickPositions");
      };
      b.prototype.trimTicks = function (b, d, h) {
        var f = b[0],
          l = b[b.length - 1],
          a = !this.isOrdinal && this.minPointOffset || 0;
        n(this, "trimTicks");
        if (!this.isLinked) {
          if (d && -Infinity !== f) this.min = f;else for (; this.min - a > b[0];) b.shift();
          if (h) this.max = l;else for (; this.max + a < b[b.length - 1];) b.pop();
          0 === b.length && D(f) && !this.options.tickPositions && b.push((l + f) / 2);
        }
      };
      b.prototype.alignToOthers = function () {
        var b = this,
          d = [this],
          h = b.options,
          l = "yAxis" === this.coll && this.chart.options.chart.alignThresholds,
          n = [],
          a;
        b.thresholdAlignment = void 0;
        if ((!1 !== this.chart.options.chart.alignTicks && h.alignTicks || l) && !1 !== h.startOnTick && !1 !== h.endOnTick && !b.logarithmic) {
          var v = function (b) {
              var d = b.options;
              return [b.horiz ? d.left : d.top, d.width, d.height, d.pane].join();
            },
            e = v(this);
          this.chart[this.coll].forEach(function (h) {
            var f = h.series;
            f.length && f.some(function (b) {
              return b.visible;
            }) && h !== b && v(h) === e && (a = !0, d.push(h));
          });
        }
        if (a && l) {
          d.forEach(function (d) {
            d = d.getThresholdAlignment(b);
            f(d) && n.push(d);
          });
          var k = 1 < n.length ? n.reduce(function (b, d) {
            return b + d;
          }, 0) / n.length : void 0;
          d.forEach(function (b) {
            b.thresholdAlignment = k;
          });
        }
        return a;
      };
      b.prototype.getThresholdAlignment = function (b) {
        (!f(this.dataMin) || this !== b && this.series.some(function (b) {
          return b.isDirty || b.isDirtyData;
        })) && this.getSeriesExtremes();
        if (f(this.threshold)) return b = k((this.threshold - (this.dataMin || 0)) / ((this.dataMax || 0) - (this.dataMin || 0)), 0, 1), this.options.reversed && (b = 1 - b), b;
      };
      b.prototype.getTickAmount = function () {
        var b = this.options,
          d = b.tickPixelInterval,
          h = b.tickAmount;
        !D(b.tickInterval) && !h && this.len < d && !this.isRadial && !this.logarithmic && b.startOnTick && b.endOnTick && (h = 2);
        !h && this.alignToOthers() && (h = Math.ceil(this.len / d) + 1);
        4 > h && (this.finalTickAmt = h, h = 5);
        this.tickAmount = h;
      };
      b.prototype.adjustTickAmount = function () {
        var b = this,
          d = b.finalTickAmt,
          h = b.max,
          l = b.min,
          n = b.options,
          a = b.tickPositions,
          k = b.tickAmount,
          w = b.thresholdAlignment,
          m = a && a.length,
          E = v(b.threshold, b.softThreshold ? 0 : null);
        var c = b.tickInterval;
        if (f(w)) {
          var p = .5 > w ? Math.ceil(w * (k - 1)) : Math.floor(w * (k - 1));
          n.reversed && (p = k - 1 - p);
        }
        if (b.hasData() && f(l) && f(h)) {
          w = function () {
            b.transA *= (m - 1) / (k - 1);
            b.min = n.startOnTick ? a[0] : Math.min(l, a[0]);
            b.max = n.endOnTick ? a[a.length - 1] : Math.max(h, a[a.length - 1]);
          };
          if (f(p) && f(b.threshold)) {
            for (; a[p] !== E || a.length !== k || a[0] > l || a[a.length - 1] < h;) {
              a.length = 0;
              for (a.push(b.threshold); a.length < k;) void 0 === a[p] || a[p] > b.threshold ? a.unshift(e(a[0] - c)) : a.push(e(a[a.length - 1] + c));
              if (c > 8 * b.tickInterval) break;
              c *= 2;
            }
            w();
          } else if (m < k) {
            for (; a.length < k;) a.length % 2 || l === E ? a.push(e(a[a.length - 1] + c)) : a.unshift(e(a[0] - c));
            w();
          }
          if (D(d)) {
            for (c = E = a.length; c--;) (3 === d && 1 === c % 2 || 2 >= d && 0 < c && c < E - 1) && a.splice(c, 1);
            b.finalTickAmt = void 0;
          }
        }
      };
      b.prototype.setScale = function () {
        var b = !1,
          d = !1;
        this.series.forEach(function (h) {
          b = b || h.isDirtyData || h.isDirty;
          d = d || h.xAxis && h.xAxis.isDirty || !1;
        });
        this.setAxisSize();
        var h = this.len !== (this.old && this.old.len);
        h || b || d || this.isLinked || this.forceRedraw || this.userMin !== (this.old && this.old.userMin) || this.userMax !== (this.old && this.old.userMax) || this.alignToOthers() ? (this.stacking && (this.stacking.resetStacks(), this.stacking.buildStacks()), this.forceRedraw = !1, this.getSeriesExtremes(), this.setTickInterval(), this.isDirty || (this.isDirty = h || this.min !== (this.old && this.old.min) || this.max !== (this.old && this.old.max))) : this.stacking && this.stacking.cleanStacks();
        b && this.panningState && (this.panningState.isDirty = !0);
        n(this, "afterSetScale");
      };
      b.prototype.setExtremes = function (b, d, h, f, l) {
        var a = this,
          e = a.chart;
        h = v(h, !0);
        a.series.forEach(function (b) {
          delete b.kdTree;
        });
        l = u(l, {
          min: b,
          max: d
        });
        n(a, "setExtremes", l, function () {
          a.userMin = b;
          a.userMax = d;
          a.eventArgs = l;
          h && e.redraw(f);
        });
      };
      b.prototype.zoom = function (b, d) {
        var h = this,
          f = this.dataMin,
          l = this.dataMax,
          a = this.options,
          e = Math.min(f, v(a.min, f)),
          k = Math.max(l, v(a.max, l));
        b = {
          newMin: b,
          newMax: d
        };
        n(this, "zoom", b, function (b) {
          var d = b.newMin,
            a = b.newMax;
          if (d !== h.min || a !== h.max) h.allowZoomOutside || (D(f) && (d < e && (d = e), d > k && (d = k)), D(l) && (a < e && (a = e), a > k && (a = k))), h.displayBtn = "undefined" !== typeof d || "undefined" !== typeof a, h.setExtremes(d, a, !1, void 0, {
            trigger: "zoom"
          });
          b.zoomed = !0;
        });
        return b.zoomed;
      };
      b.prototype.setAxisSize = function () {
        var b = this.chart,
          d = this.options,
          h = d.offsets || [0, 0, 0, 0],
          f = this.horiz,
          l = this.width = Math.round(E(v(d.width, b.plotWidth - h[3] + h[1]), b.plotWidth)),
          a = this.height = Math.round(E(v(d.height, b.plotHeight - h[0] + h[2]), b.plotHeight)),
          n = this.top = Math.round(E(v(d.top, b.plotTop + h[0]), b.plotHeight, b.plotTop));
        d = this.left = Math.round(E(v(d.left, b.plotLeft + h[3]), b.plotWidth, b.plotLeft));
        this.bottom = b.chartHeight - a - n;
        this.right = b.chartWidth - l - d;
        this.len = Math.max(f ? l : a, 0);
        this.pos = f ? d : n;
      };
      b.prototype.getExtremes = function () {
        var b = this.logarithmic;
        return {
          min: b ? e(b.lin2log(this.min)) : this.min,
          max: b ? e(b.lin2log(this.max)) : this.max,
          dataMin: this.dataMin,
          dataMax: this.dataMax,
          userMin: this.userMin,
          userMax: this.userMax
        };
      };
      b.prototype.getThreshold = function (b) {
        var d = this.logarithmic,
          h = d ? d.lin2log(this.min) : this.min;
        d = d ? d.lin2log(this.max) : this.max;
        null === b || -Infinity === b ? b = h : Infinity === b ? b = d : h > b ? b = h : d < b && (b = d);
        return this.translate(b, 0, 1, 0, 1);
      };
      b.prototype.autoLabelAlign = function (b) {
        var d = (v(b, 0) - 90 * this.side + 720) % 360;
        b = {
          align: "center"
        };
        n(this, "autoLabelAlign", b, function (b) {
          15 < d && 165 > d ? b.align = "right" : 195 < d && 345 > d && (b.align = "left");
        });
        return b.align;
      };
      b.prototype.tickSize = function (b) {
        var d = this.options,
          h = v(d["tick" === b ? "tickWidth" : "minorTickWidth"], "tick" === b && this.isXAxis && !this.categories ? 1 : 0),
          f = d["tick" === b ? "tickLength" : "minorTickLength"];
        if (h && f) {
          "inside" === d[b + "Position"] && (f = -f);
          var l = [f, h];
        }
        b = {
          tickSize: l
        };
        n(this, "afterTickSize", b);
        return b.tickSize;
      };
      b.prototype.labelMetrics = function () {
        var b = this.tickPositions && this.tickPositions[0] || 0;
        return this.chart.renderer.fontMetrics(this.options.labels.style.fontSize, this.ticks[b] && this.ticks[b].label);
      };
      b.prototype.unsquish = function () {
        var b = this.options.labels,
          d = this.horiz,
          h = this.tickInterval,
          l = this.len / (((this.categories ? 1 : 0) + this.max - this.min) / h),
          a = b.rotation,
          n = this.labelMetrics(),
          k = Math.max(this.max - this.min, 0),
          w = function (b) {
            var d = b / (l || 1);
            d = 1 < d ? Math.ceil(d) : 1;
            d * h > k && Infinity !== b && Infinity !== l && k && (d = Math.ceil(k / h));
            return e(d * h);
          },
          m = h,
          E = Number.MAX_VALUE;
        if (d) {
          if (!b.staggerLines) if (f(a)) var c = [a];else l < b.autoRotationLimit && (c = b.autoRotation);
          if (c) for (var p = d = void 0, u = 0, J = c; u < J.length; u++) {
            var r = J[u];
            if (r === a || r && -90 <= r && 90 >= r) if (d = w(Math.abs(n.h / Math.sin(q * r))), p = d + Math.abs(r / 360), p < E) {
              E = p;
              var g = r;
              m = d;
            }
          }
        } else m = w(n.h);
        this.autoRotation = c;
        this.labelRotation = v(g, f(a) ? a : 0);
        return b.step ? h : m;
      };
      b.prototype.getSlotWidth = function (b) {
        var d = this.chart,
          h = this.horiz,
          l = this.options.labels,
          a = Math.max(this.tickPositions.length - (this.categories ? 0 : 1), 1),
          n = d.margin[3];
        if (b && f(b.slotWidth)) return b.slotWidth;
        if (h && 2 > l.step) return l.rotation ? 0 : (this.staggerLines || 1) * this.len / a;
        if (!h) {
          b = l.style.width;
          if (void 0 !== b) return parseInt(String(b), 10);
          if (n) return n - d.spacing[3];
        }
        return .33 * d.chartWidth;
      };
      b.prototype.renderUnsquish = function () {
        var b = this.chart,
          d = b.renderer,
          h = this.tickPositions,
          f = this.ticks,
          a = this.options.labels,
          n = a.style,
          v = this.horiz,
          e = this.getSlotWidth(),
          k = Math.max(1, Math.round(e - 2 * a.padding)),
          w = {},
          m = this.labelMetrics(),
          E = n.textOverflow,
          c = 0;
        l(a.rotation) || (w.rotation = a.rotation || 0);
        h.forEach(function (b) {
          b = f[b];
          b.movedLabel && b.replaceMovedLabel();
          b && b.label && b.label.textPxLength > c && (c = b.label.textPxLength);
        });
        this.maxLabelLength = c;
        if (this.autoRotation) c > k && c > m.h ? w.rotation = this.labelRotation : this.labelRotation = 0;else if (e) {
          var p = k;
          if (!E) {
            var u = "clip";
            for (k = h.length; !v && k--;) {
              var q = h[k];
              if (q = f[q].label) q.styles && "ellipsis" === q.styles.textOverflow ? q.css({
                textOverflow: "clip"
              }) : q.textPxLength > e && q.css({
                width: e + "px"
              }), q.getBBox().height > this.len / h.length - (m.h - m.f) && (q.specificTextOverflow = "ellipsis");
            }
          }
        }
        w.rotation && (p = c > .5 * b.chartHeight ? .33 * b.chartHeight : c, E || (u = "ellipsis"));
        if (this.labelAlign = a.align || this.autoLabelAlign(this.labelRotation)) w.align = this.labelAlign;
        h.forEach(function (b) {
          var d = (b = f[b]) && b.label,
            h = n.width,
            l = {};
          d && (d.attr(w), b.shortenLabel ? b.shortenLabel() : p && !h && "nowrap" !== n.whiteSpace && (p < d.textPxLength || "SPAN" === d.element.tagName) ? (l.width = p + "px", E || (l.textOverflow = d.specificTextOverflow || u), d.css(l)) : d.styles && d.styles.width && !l.width && !h && d.css({
            width: null
          }), delete d.specificTextOverflow, b.rotation = w.rotation);
        }, this);
        this.tickRotCorr = d.rotCorr(m.b, this.labelRotation || 0, 0 !== this.side);
      };
      b.prototype.hasData = function () {
        return this.series.some(function (b) {
          return b.hasData();
        }) || this.options.showEmpty && D(this.min) && D(this.max);
      };
      b.prototype.addTitle = function (b) {
        var h = this.chart.renderer,
          f = this.horiz,
          l = this.opposite,
          a = this.options.title,
          n = this.chart.styledMode,
          v;
        this.axisTitle || ((v = a.textAlign) || (v = (f ? {
          low: "left",
          middle: "center",
          high: "right"
        } : {
          low: l ? "right" : "left",
          middle: "center",
          high: l ? "left" : "right"
        })[a.align]), this.axisTitle = h.text(a.text || "", 0, 0, a.useHTML).attr({
          zIndex: 7,
          rotation: a.rotation,
          align: v
        }).addClass("highcharts-axis-title"), n || this.axisTitle.css(d(a.style)), this.axisTitle.add(this.axisGroup), this.axisTitle.isNew = !0);
        n || a.style.width || this.isRadial || this.axisTitle.css({
          width: this.len + "px"
        });
        this.axisTitle[b ? "show" : "hide"](b);
      };
      b.prototype.generateTick = function (b) {
        var d = this.ticks;
        d[b] ? d[b].addLabel() : d[b] = new C(this, b);
      };
      b.prototype.getOffset = function () {
        var b = this,
          d = this,
          f = d.chart,
          l = d.horiz,
          a = d.options,
          e = d.side,
          k = d.ticks,
          w = d.tickPositions,
          m = d.coll,
          E = d.axisParent,
          c = f.renderer,
          p = f.inverted && !d.isZAxis ? [1, 0, 3, 2][e] : e,
          u = d.hasData(),
          q = a.title,
          J = a.labels,
          r = f.axisOffset;
        f = f.clipOffset;
        var g = [-1, 1, 1, -1][e],
          t = a.className,
          M,
          O = 0,
          G = 0,
          P = 0;
        d.showAxis = M = u || a.showEmpty;
        d.staggerLines = d.horiz && J.staggerLines || void 0;
        if (!d.axisGroup) {
          var x = function (d, h, f) {
            return c.g(d).attr({
              zIndex: f
            }).addClass("highcharts-".concat(m.toLowerCase()).concat(h, " ") + (b.isRadial ? "highcharts-radial-axis".concat(h, " ") : "") + (t || "")).add(E);
          };
          d.gridGroup = x("grid", "-grid", a.gridZIndex);
          d.axisGroup = x("axis", "", a.zIndex);
          d.labelGroup = x("axis-labels", "-labels", J.zIndex);
        }
        u || d.isLinked ? (w.forEach(function (b) {
          d.generateTick(b);
        }), d.renderUnsquish(), d.reserveSpaceDefault = 0 === e || 2 === e || {
          1: "left",
          3: "right"
        }[e] === d.labelAlign, v(J.reserveSpace, "center" === d.labelAlign ? !0 : null, d.reserveSpaceDefault) && w.forEach(function (b) {
          P = Math.max(k[b].getLabelSize(), P);
        }), d.staggerLines && (P *= d.staggerLines), d.labelOffset = P * (d.opposite ? -1 : 1)) : h(k, function (b, d) {
          b.destroy();
          delete k[d];
        });
        if (q && q.text && !1 !== q.enabled && (d.addTitle(M), M && !1 !== q.reserveSpace)) {
          d.titleOffset = O = d.axisTitle.getBBox()[l ? "height" : "width"];
          var T = q.offset;
          G = D(T) ? 0 : v(q.margin, l ? 5 : 10);
        }
        d.renderLine();
        d.offset = g * v(a.offset, r[e] ? r[e] + (a.margin || 0) : 0);
        d.tickRotCorr = d.tickRotCorr || {
          x: 0,
          y: 0
        };
        q = 0 === e ? -d.labelMetrics().h : 2 === e ? d.tickRotCorr.y : 0;
        u = Math.abs(P) + G;
        P && (u = u - q + g * (l ? v(J.y, d.tickRotCorr.y + 8 * g) : J.x));
        d.axisTitleMargin = v(T, u);
        d.getMaxLabelDimensions && (d.maxLabelDimensions = d.getMaxLabelDimensions(k, w));
        "colorAxis" !== m && (l = this.tickSize("tick"), r[e] = Math.max(r[e], (d.axisTitleMargin || 0) + O + g * d.offset, u, w && w.length && l ? l[0] + g * d.offset : 0), a = !d.axisLine || a.offset ? 0 : 2 * Math.floor(d.axisLine.strokeWidth() / 2), f[p] = Math.max(f[p], a));
        n(this, "afterGetOffset");
      };
      b.prototype.getLinePath = function (b) {
        var d = this.chart,
          h = this.opposite,
          f = this.offset,
          l = this.horiz,
          a = this.left + (h ? this.width : 0) + f;
        f = d.chartHeight - this.bottom - (h ? this.height : 0) + f;
        h && (b *= -1);
        return d.renderer.crispLine([["M", l ? this.left : a, l ? f : this.top], ["L", l ? d.chartWidth - this.right : a, l ? f : d.chartHeight - this.bottom]], b);
      };
      b.prototype.renderLine = function () {
        this.axisLine || (this.axisLine = this.chart.renderer.path().addClass("highcharts-axis-line").add(this.axisGroup), this.chart.styledMode || this.axisLine.attr({
          stroke: this.options.lineColor,
          "stroke-width": this.options.lineWidth,
          zIndex: 7
        }));
      };
      b.prototype.getTitlePosition = function () {
        var b = this.horiz,
          d = this.left,
          h = this.top,
          f = this.len,
          l = this.options.title,
          a = b ? d : h,
          v = this.opposite,
          e = this.offset,
          k = l.x,
          w = l.y,
          m = this.axisTitle,
          E = this.chart.renderer.fontMetrics(l.style.fontSize, m);
        m = m ? Math.max(m.getBBox(!1, 0).height - E.h - 1, 0) : 0;
        f = {
          low: a + (b ? 0 : f),
          middle: a + f / 2,
          high: a + (b ? f : 0)
        }[l.align];
        d = (b ? h + this.height : d) + (b ? 1 : -1) * (v ? -1 : 1) * (this.axisTitleMargin || 0) + [-m, m, E.f, -m][this.side];
        b = {
          x: b ? f + k : d + (v ? this.width : 0) + e + k,
          y: b ? d + w - (v ? this.height : 0) + e : f + w
        };
        n(this, "afterGetTitlePosition", {
          titlePosition: b
        });
        return b;
      };
      b.prototype.renderMinorTick = function (b, d) {
        var h = this.minorTicks;
        h[b] || (h[b] = new C(this, b, "minor"));
        d && h[b].isNew && h[b].render(null, !0);
        h[b].render(null, !1, 1);
      };
      b.prototype.renderTick = function (b, d, h) {
        var f = this.ticks;
        if (!this.isLinked || b >= this.min && b <= this.max || this.grid && this.grid.isColumn) f[b] || (f[b] = new C(this, b)), h && f[b].isNew && f[b].render(d, !0, -1), f[b].render(d);
      };
      b.prototype.render = function () {
        var b = this,
          d = b.chart,
          l = b.logarithmic,
          a = b.options,
          v = b.isLinked,
          e = b.tickPositions,
          k = b.axisTitle,
          w = b.ticks,
          m = b.minorTicks,
          E = b.alternateBands,
          c = a.stackLabels,
          p = a.alternateGridColor,
          u = b.tickmarkOffset,
          q = b.axisLine,
          J = b.showAxis,
          r = t(d.renderer.globalAnimation),
          g,
          M;
        b.labelEdge.length = 0;
        b.overlap = !1;
        [w, m, E].forEach(function (b) {
          h(b, function (b) {
            b.isActive = !1;
          });
        });
        if (b.hasData() || v) {
          var D = b.chart.hasRendered && b.old && f(b.old.min);
          b.minorTickInterval && !b.categories && b.getMinorTickPositions().forEach(function (d) {
            b.renderMinorTick(d, D);
          });
          e.length && (e.forEach(function (d, h) {
            b.renderTick(d, h, D);
          }), u && (0 === b.min || b.single) && (w[-1] || (w[-1] = new C(b, -1, null, !0)), w[-1].render(-1)));
          p && e.forEach(function (h, f) {
            M = "undefined" !== typeof e[f + 1] ? e[f + 1] + u : b.max - u;
            0 === f % 2 && h < b.max && M <= b.max + (d.polar ? -u : u) && (E[h] || (E[h] = new z.PlotLineOrBand(b)), g = h + u, E[h].options = {
              from: l ? l.lin2log(g) : g,
              to: l ? l.lin2log(M) : M,
              color: p,
              className: "highcharts-alternate-grid"
            }, E[h].render(), E[h].isActive = !0);
          });
          b._addedPlotLB || (b._addedPlotLB = !0, (a.plotLines || []).concat(a.plotBands || []).forEach(function (d) {
            b.addPlotBandOrLine(d);
          }));
        }
        [w, m, E].forEach(function (b) {
          var f = [],
            l = r.duration;
          h(b, function (b, d) {
            b.isActive || (b.render(d, !1, 0), b.isActive = !1, f.push(d));
          });
          O(function () {
            for (var d = f.length; d--;) b[f[d]] && !b[f[d]].isActive && (b[f[d]].destroy(), delete b[f[d]]);
          }, b !== E && d.hasRendered && l ? l : 0);
        });
        q && (q[q.isPlaced ? "animate" : "attr"]({
          d: this.getLinePath(q.strokeWidth())
        }), q.isPlaced = !0, q[J ? "show" : "hide"](J));
        k && J && (a = b.getTitlePosition(), k[k.isNew ? "attr" : "animate"](a), k.isNew = !1);
        c && c.enabled && b.stacking && b.stacking.renderStackTotals();
        b.old = {
          len: b.len,
          max: b.max,
          min: b.min,
          transA: b.transA,
          userMax: b.userMax,
          userMin: b.userMin
        };
        b.isDirty = !1;
        n(this, "afterRender");
      };
      b.prototype.redraw = function () {
        this.visible && (this.render(), this.plotLinesAndBands.forEach(function (b) {
          b.render();
        }));
        this.series.forEach(function (b) {
          b.isDirty = !0;
        });
      };
      b.prototype.getKeepProps = function () {
        return this.keepProps || b.keepProps;
      };
      b.prototype.destroy = function (b) {
        var d = this,
          f = d.plotLinesAndBands,
          l = this.eventOptions;
        n(this, "destroy", {
          keepEvents: b
        });
        b || J(d);
        [d.ticks, d.minorTicks, d.alternateBands].forEach(function (b) {
          G(b);
        });
        if (f) for (b = f.length; b--;) f[b].destroy();
        "axisLine axisTitle axisGroup gridGroup labelGroup cross scrollbar".split(" ").forEach(function (b) {
          d[b] && (d[b] = d[b].destroy());
        });
        for (var a in d.plotLinesAndBandsGroups) d.plotLinesAndBandsGroups[a] = d.plotLinesAndBandsGroups[a].destroy();
        h(d, function (b, h) {
          -1 === d.getKeepProps().indexOf(h) && delete d[h];
        });
        this.eventOptions = l;
      };
      b.prototype.drawCrosshair = function (b, d) {
        var h = this.crosshair,
          f = v(h && h.snap, !0),
          l = this.chart,
          a,
          e = this.cross;
        n(this, "drawCrosshair", {
          e: b,
          point: d
        });
        b || (b = this.cross && this.cross.e);
        if (h && !1 !== (D(d) || !f)) {
          f ? D(d) && (a = v("colorAxis" !== this.coll ? d.crosshairPos : null, this.isXAxis ? d.plotX : this.len - d.plotY)) : a = b && (this.horiz ? b.chartX - this.pos : this.len - b.chartY + this.pos);
          if (D(a)) {
            var k = {
              value: d && (this.isXAxis ? d.x : v(d.stackY, d.y)),
              translatedValue: a
            };
            l.polar && u(k, {
              isCrosshair: !0,
              chartX: b && b.chartX,
              chartY: b && b.chartY,
              point: d
            });
            k = this.getPlotLinePath(k) || null;
          }
          if (!D(k)) {
            this.hideCrosshair();
            return;
          }
          f = this.categories && !this.isRadial;
          e || (this.cross = e = l.renderer.path().addClass("highcharts-crosshair highcharts-crosshair-" + (f ? "category " : "thin ") + (h.className || "")).attr({
            zIndex: v(h.zIndex, 2)
          }).add(), l.styledMode || (e.attr({
            stroke: h.color || (f ? y.parse("#ccd6eb").setOpacity(.25).get() : "#cccccc"),
            "stroke-width": v(h.width, 1)
          }).css({
            "pointer-events": "none"
          }), h.dashStyle && e.attr({
            dashstyle: h.dashStyle
          })));
          e.show().attr({
            d: k
          });
          f && !h.width && e.attr({
            "stroke-width": this.transA
          });
          this.cross.e = b;
        } else this.hideCrosshair();
        n(this, "afterDrawCrosshair", {
          e: b,
          point: d
        });
      };
      b.prototype.hideCrosshair = function () {
        this.cross && this.cross.hide();
        n(this, "afterHideCrosshair");
      };
      b.prototype.hasVerticalPanning = function () {
        var b = this.chart.options.chart.panning;
        return !!(b && b.enabled && /y/.test(b.type));
      };
      b.prototype.validatePositiveValue = function (b) {
        return f(b) && 0 < b;
      };
      b.prototype.update = function (b, h) {
        var f = this.chart;
        b = d(this.userOptions, b);
        this.destroy(!0);
        this.init(f, b);
        f.isDirtyBox = !0;
        v(h, !0) && f.redraw();
      };
      b.prototype.remove = function (b) {
        for (var d = this.chart, h = this.coll, f = this.series, l = f.length; l--;) f[l] && f[l].remove(!1);
        H(d.axes, this);
        H(d[h], this);
        d[h].forEach(function (b, d) {
          b.options.index = b.userOptions.index = d;
        });
        this.destroy();
        d.isDirtyBox = !0;
        v(b, !0) && d.redraw();
      };
      b.prototype.setTitle = function (b, d) {
        this.update({
          title: b
        }, d);
      };
      b.prototype.setCategories = function (b, d) {
        this.update({
          categories: b
        }, d);
      };
      b.defaultOptions = g.defaultXAxisOptions;
      b.keepProps = "extKey hcEvents names series userMax userMin".split(" ");
      return b;
    }();
    "";
    return c;
  });
  I(g, "Core/Axis/DateTimeAxis.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.addEvent,
      y = c.getMagnitude,
      A = c.normalizeTickInterval,
      B = c.timeUnits,
      z;
    (function (c) {
      function x() {
        return this.chart.time.getTimeTicks.apply(this.chart.time, arguments);
      }
      function t(c) {
        "datetime" !== c.userOptions.type ? this.dateTime = void 0 : this.dateTime || (this.dateTime = new a(this));
      }
      var r = [];
      c.compose = function (a) {
        -1 === r.indexOf(a) && (r.push(a), a.keepProps.push("dateTime"), a.prototype.getTimeTicks = x, g(a, "init", t));
        return a;
      };
      var a = function () {
        function a(a) {
          this.axis = a;
        }
        a.prototype.normalizeTimeTickInterval = function (a, c) {
          var k = c || [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2]], ["week", [1, 2]], ["month", [1, 2, 3, 4, 6]], ["year", null]];
          c = k[k.length - 1];
          var e = B[c[0]],
            m = c[1],
            p;
          for (p = 0; p < k.length && !(c = k[p], e = B[c[0]], m = c[1], k[p + 1] && a <= (e * m[m.length - 1] + B[k[p + 1][0]]) / 2); p++);
          e === B.year && a < 5 * e && (m = [1, 2, 5]);
          a = A(a / e, m, "year" === c[0] ? Math.max(y(a / e), 1) : 1);
          return {
            unitRange: e,
            count: a,
            unitName: c[0]
          };
        };
        a.prototype.getXDateFormat = function (a, c) {
          var k = this.axis,
            e = k.chart.time;
          return k.closestPointRange ? e.getDateFormat(k.closestPointRange, a, k.options.startOfWeek, c) || e.resolveDTLFormat(c.year).main : e.resolveDTLFormat(c.day).main;
        };
        return a;
      }();
      c.Additions = a;
    })(z || (z = {}));
    return z;
  });
  I(g, "Core/Axis/LogarithmicAxis.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.addEvent,
      y = c.normalizeTickInterval,
      A = c.pick,
      B;
    (function (c) {
      function C(a) {
        var c = this.logarithmic;
        "logarithmic" !== a.userOptions.type ? this.logarithmic = void 0 : c || (this.logarithmic = new r(this));
      }
      function x() {
        var a = this.logarithmic;
        a && (this.lin2val = function (c) {
          return a.lin2log(c);
        }, this.val2lin = function (c) {
          return a.log2lin(c);
        });
      }
      var t = [];
      c.compose = function (a) {
        -1 === t.indexOf(a) && (t.push(a), a.keepProps.push("logarithmic"), g(a, "init", C), g(a, "afterInit", x));
        return a;
      };
      var r = function () {
        function a(a) {
          this.axis = a;
        }
        a.prototype.getLogTickPositions = function (a, m, c, k) {
          var e = this.axis,
            p = e.len,
            q = e.options,
            r = [];
          k || (this.minorAutoInterval = void 0);
          if (.5 <= a) a = Math.round(a), r = e.getLinearTickPositions(a, m, c);else if (.08 <= a) {
            var g = Math.floor(m),
              u,
              n = q = void 0;
            for (p = .3 < a ? [1, 2, 4] : .15 < a ? [1, 2, 4, 6, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9]; g < c + 1 && !n; g++) {
              var w = p.length;
              for (u = 0; u < w && !n; u++) {
                var f = this.log2lin(this.lin2log(g) * p[u]);
                f > m && (!k || q <= c) && "undefined" !== typeof q && r.push(q);
                q > c && (n = !0);
                q = f;
              }
            }
          } else m = this.lin2log(m), c = this.lin2log(c), a = k ? e.getMinorTickInterval() : q.tickInterval, a = A("auto" === a ? null : a, this.minorAutoInterval, q.tickPixelInterval / (k ? 5 : 1) * (c - m) / ((k ? p / e.tickPositions.length : p) || 1)), a = y(a), r = e.getLinearTickPositions(a, m, c).map(this.log2lin), k || (this.minorAutoInterval = a / 5);
          k || (e.tickInterval = a);
          return r;
        };
        a.prototype.lin2log = function (a) {
          return Math.pow(10, a);
        };
        a.prototype.log2lin = function (a) {
          return Math.log(a) / Math.LN10;
        };
        return a;
      }();
      c.Additions = r;
    })(B || (B = {}));
    return B;
  });
  I(g, "Core/Axis/PlotLineOrBand/PlotLineOrBandAxis.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.erase,
      y = c.extend,
      A = c.isNumber,
      B;
    (function (c) {
      var C = [],
        x;
      c.compose = function (c, a) {
        x || (x = c);
        -1 === C.indexOf(a) && (C.push(a), y(a.prototype, t.prototype));
        return a;
      };
      var t = function () {
        function c() {}
        c.prototype.getPlotBandPath = function (a, c, m) {
          void 0 === m && (m = this.options);
          var p = this.getPlotLinePath({
              value: c,
              force: !0,
              acrossPanes: m.acrossPanes
            }),
            k = [],
            e = this.horiz;
          c = !A(this.min) || !A(this.max) || a < this.min && c < this.min || a > this.max && c > this.max;
          a = this.getPlotLinePath({
            value: a,
            force: !0,
            acrossPanes: m.acrossPanes
          });
          m = 1;
          if (a && p) {
            if (c) {
              var q = a.toString() === p.toString();
              m = 0;
            }
            for (c = 0; c < a.length; c += 2) {
              var r = a[c],
                g = a[c + 1],
                t = p[c],
                u = p[c + 1];
              "M" !== r[0] && "L" !== r[0] || "M" !== g[0] && "L" !== g[0] || "M" !== t[0] && "L" !== t[0] || "M" !== u[0] && "L" !== u[0] || (e && t[1] === r[1] ? (t[1] += m, u[1] += m) : e || t[2] !== r[2] || (t[2] += m, u[2] += m), k.push(["M", r[1], r[2]], ["L", g[1], g[2]], ["L", u[1], u[2]], ["L", t[1], t[2]], ["Z"]));
              k.isFlat = q;
            }
          }
          return k;
        };
        c.prototype.addPlotBand = function (a) {
          return this.addPlotBandOrLine(a, "plotBands");
        };
        c.prototype.addPlotLine = function (a) {
          return this.addPlotBandOrLine(a, "plotLines");
        };
        c.prototype.addPlotBandOrLine = function (a, c) {
          var m = this,
            p = this.userOptions,
            k = new x(this, a);
          this.visible && (k = k.render());
          if (k) {
            this._addedPlotLB || (this._addedPlotLB = !0, (p.plotLines || []).concat(p.plotBands || []).forEach(function (a) {
              m.addPlotBandOrLine(a);
            }));
            if (c) {
              var e = p[c] || [];
              e.push(a);
              p[c] = e;
            }
            this.plotLinesAndBands.push(k);
          }
          return k;
        };
        c.prototype.removePlotBandOrLine = function (a) {
          var c = this.plotLinesAndBands,
            m = this.options,
            p = this.userOptions;
          if (c) {
            for (var k = c.length; k--;) c[k].id === a && c[k].destroy();
            [m.plotLines || [], p.plotLines || [], m.plotBands || [], p.plotBands || []].forEach(function (e) {
              for (k = e.length; k--;) (e[k] || {}).id === a && g(e, e[k]);
            });
          }
        };
        c.prototype.removePlotBand = function (a) {
          this.removePlotBandOrLine(a);
        };
        c.prototype.removePlotLine = function (a) {
          this.removePlotBandOrLine(a);
        };
        return c;
      }();
    })(B || (B = {}));
    return B;
  });
  I(g, "Core/Axis/PlotLineOrBand/PlotLineOrBand.js", [g["Core/Axis/PlotLineOrBand/PlotLineOrBandAxis.js"], g["Core/Utilities.js"]], function (c, g) {
    var y = g.arrayMax,
      L = g.arrayMin,
      B = g.defined,
      z = g.destroyObjectProperties,
      C = g.erase,
      x = g.fireEvent,
      t = g.merge,
      r = g.objectEach,
      a = g.pick;
    g = function () {
      function q(a, c) {
        this.axis = a;
        c && (this.options = c, this.id = c.id);
      }
      q.compose = function (a) {
        return c.compose(q, a);
      };
      q.prototype.render = function () {
        x(this, "render");
        var c = this,
          p = c.axis,
          k = p.horiz,
          e = p.logarithmic,
          q = c.options,
          g = q.color,
          H = a(q.zIndex, 0),
          C = q.events,
          u = {},
          n = p.chart.renderer,
          w = q.label,
          f = c.label,
          l = q.to,
          d = q.from,
          b = q.value,
          h = c.svgElem,
          v = [],
          E = B(d) && B(l);
        v = B(b);
        var J = !h,
          M = {
            "class": "highcharts-plot-" + (E ? "band " : "line ") + (q.className || "")
          },
          O = E ? "bands" : "lines";
        e && (d = e.log2lin(d), l = e.log2lin(l), b = e.log2lin(b));
        p.chart.styledMode || (v ? (M.stroke = g || "#999999", M["stroke-width"] = a(q.width, 1), q.dashStyle && (M.dashstyle = q.dashStyle)) : E && (M.fill = g || "#e6ebf5", q.borderWidth && (M.stroke = q.borderColor, M["stroke-width"] = q.borderWidth)));
        u.zIndex = H;
        O += "-" + H;
        (e = p.plotLinesAndBandsGroups[O]) || (p.plotLinesAndBandsGroups[O] = e = n.g("plot-" + O).attr(u).add());
        J && (c.svgElem = h = n.path().attr(M).add(e));
        if (v) v = p.getPlotLinePath({
          value: b,
          lineWidth: h.strokeWidth(),
          acrossPanes: q.acrossPanes
        });else if (E) v = p.getPlotBandPath(d, l, q);else return;
        !c.eventsAdded && C && (r(C, function (b, d) {
          h.on(d, function (b) {
            C[d].apply(c, [b]);
          });
        }), c.eventsAdded = !0);
        (J || !h.d) && v && v.length ? h.attr({
          d: v
        }) : h && (v ? (h.show(), h.animate({
          d: v
        })) : h.d && (h.hide(), f && (c.label = f = f.destroy())));
        w && (B(w.text) || B(w.formatter)) && v && v.length && 0 < p.width && 0 < p.height && !v.isFlat ? (w = t({
          align: k && E && "center",
          x: k ? !E && 4 : 10,
          verticalAlign: !k && E && "middle",
          y: k ? E ? 16 : 10 : E ? 6 : -4,
          rotation: k && !E && 90
        }, w), this.renderLabel(w, v, E, H)) : f && f.hide();
        return c;
      };
      q.prototype.renderLabel = function (a, c, k, e) {
        var m = this.axis,
          p = m.chart.renderer,
          q = this.label;
        q || (this.label = q = p.text(this.getLabelText(a), 0, 0, a.useHTML).attr({
          align: a.textAlign || a.align,
          rotation: a.rotation,
          "class": "highcharts-plot-" + (k ? "band" : "line") + "-label " + (a.className || ""),
          zIndex: e
        }).add(), m.chart.styledMode || q.css(t({
          textOverflow: "ellipsis"
        }, a.style)));
        e = c.xBounds || [c[0][1], c[1][1], k ? c[2][1] : c[0][1]];
        c = c.yBounds || [c[0][2], c[1][2], k ? c[2][2] : c[0][2]];
        k = L(e);
        p = L(c);
        q.align(a, !1, {
          x: k,
          y: p,
          width: y(e) - k,
          height: y(c) - p
        });
        q.alignValue && "left" !== q.alignValue || (a = a.clip ? m.width : m.chart.chartWidth, q.css({
          width: (90 === q.rotation ? m.height - (q.alignAttr.y - m.top) : a - (q.alignAttr.x - m.left)) + "px"
        }));
        q.show(!0);
      };
      q.prototype.getLabelText = function (a) {
        return B(a.formatter) ? a.formatter.call(this) : a.text;
      };
      q.prototype.destroy = function () {
        C(this.axis.plotLinesAndBands, this);
        delete this.axis;
        z(this);
      };
      return q;
    }();
    "";
    "";
    return g;
  });
  I(g, "Core/Tooltip.js", [g["Core/FormatUtilities.js"], g["Core/Globals.js"], g["Core/Renderer/RendererUtilities.js"], g["Core/Renderer/RendererRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A, B) {
    var z = c.format,
      C = g.doc,
      x = y.distribute,
      t = B.clamp,
      r = B.css,
      a = B.discardElement,
      q = B.extend,
      m = B.fireEvent,
      p = B.isArray,
      k = B.isNumber,
      e = B.isString,
      D = B.merge,
      G = B.pick,
      H = B.splat,
      K = B.syncTimeout;
    c = function () {
      function c(a, e) {
        this.allowShared = !0;
        this.container = void 0;
        this.crosshairs = [];
        this.distance = 0;
        this.isHidden = !0;
        this.isSticky = !1;
        this.now = {};
        this.options = {};
        this.outside = !1;
        this.chart = a;
        this.init(a, e);
      }
      c.prototype.applyFilter = function () {
        var a = this.chart;
        a.renderer.definition({
          tagName: "filter",
          attributes: {
            id: "drop-shadow-" + a.index,
            opacity: .5
          },
          children: [{
            tagName: "feGaussianBlur",
            attributes: {
              "in": "SourceAlpha",
              stdDeviation: 1
            }
          }, {
            tagName: "feOffset",
            attributes: {
              dx: 1,
              dy: 1
            }
          }, {
            tagName: "feComponentTransfer",
            children: [{
              tagName: "feFuncA",
              attributes: {
                type: "linear",
                slope: .3
              }
            }]
          }, {
            tagName: "feMerge",
            children: [{
              tagName: "feMergeNode"
            }, {
              tagName: "feMergeNode",
              attributes: {
                "in": "SourceGraphic"
              }
            }]
          }]
        });
      };
      c.prototype.bodyFormatter = function (a) {
        return a.map(function (a) {
          var f = a.series.tooltipOptions;
          return (f[(a.point.formatPrefix || "point") + "Formatter"] || a.point.tooltipFormatter).call(a.point, f[(a.point.formatPrefix || "point") + "Format"] || "");
        });
      };
      c.prototype.cleanSplit = function (a) {
        this.chart.series.forEach(function (n) {
          var f = n && n.tt;
          f && (!f.isActive || a ? n.tt = f.destroy() : f.isActive = !1);
        });
      };
      c.prototype.defaultFormatter = function (a) {
        var n = this.points || H(this);
        var f = [a.tooltipFooterHeaderFormatter(n[0])];
        f = f.concat(a.bodyFormatter(n));
        f.push(a.tooltipFooterHeaderFormatter(n[0], !0));
        return f;
      };
      c.prototype.destroy = function () {
        this.label && (this.label = this.label.destroy());
        this.split && this.tt && (this.cleanSplit(!0), this.tt = this.tt.destroy());
        this.renderer && (this.renderer = this.renderer.destroy(), a(this.container));
        B.clearTimeout(this.hideTimer);
        B.clearTimeout(this.tooltipTimeout);
      };
      c.prototype.getAnchor = function (a, e) {
        var f = this.chart,
          l = f.pointer,
          d = f.inverted,
          b = f.plotTop;
        f = f.plotLeft;
        a = H(a);
        a[0].series && a[0].series.yAxis && !a[0].series.yAxis.options.reversedStacks && (a = a.slice().reverse());
        if (this.followPointer && e) "undefined" === typeof e.chartX && (e = l.normalize(e)), a = [e.chartX - f, e.chartY - b];else if (a[0].tooltipPos) a = a[0].tooltipPos;else {
          var h = 0,
            n = 0;
          a.forEach(function (b) {
            if (b = b.pos(!0)) h += b[0], n += b[1];
          });
          h /= a.length;
          n /= a.length;
          this.shared && 1 < a.length && e && (d ? h = e.chartX : n = e.chartY);
          a = [h - f, n - b];
        }
        return a.map(Math.round);
      };
      c.prototype.getClassName = function (a, k, f) {
        var l = a.series,
          d = l.options;
        return [this.options.className, "highcharts-label", f && "highcharts-tooltip-header", k ? "highcharts-tooltip-box" : "highcharts-tooltip", !f && "highcharts-color-" + G(a.colorIndex, l.colorIndex), d && d.className].filter(e).join(" ");
      };
      c.prototype.getLabel = function () {
        var a = this,
          e = this.chart.styledMode,
          f = this.options,
          l = this.split && this.allowShared,
          d = f.style.pointerEvents || (this.shouldStickOnContact() ? "auto" : "none"),
          b,
          h = this.chart.renderer;
        if (a.label) {
          var v = !a.label.hasClass("highcharts-label");
          (l && !v || !l && v) && a.destroy();
        }
        if (!this.label) {
          if (this.outside) {
            v = this.chart.options.chart.style;
            var k = A.getRendererType();
            this.container = b = g.doc.createElement("div");
            b.className = "highcharts-tooltip-container";
            r(b, {
              position: "absolute",
              top: "1px",
              pointerEvents: d,
              zIndex: Math.max(this.options.style.zIndex || 0, (v && v.zIndex || 0) + 3)
            });
            g.doc.body.appendChild(b);
            this.renderer = h = new k(b, 0, 0, v, void 0, void 0, h.styledMode);
          }
          l ? this.label = h.g("tooltip") : (this.label = h.label("", 0, 0, f.shape, void 0, void 0, f.useHTML, void 0, "tooltip").attr({
            padding: f.padding,
            r: f.borderRadius
          }), e || this.label.attr({
            fill: f.backgroundColor,
            "stroke-width": f.borderWidth
          }).css(f.style).css({
            pointerEvents: d
          }).shadow(f.shadow));
          e && f.shadow && (this.applyFilter(), this.label.attr({
            filter: "url(#drop-shadow-" + this.chart.index + ")"
          }));
          if (a.outside && !a.split) {
            var c = this.label,
              m = c.xSetter,
              p = c.ySetter;
            c.xSetter = function (d) {
              m.call(c, a.distance);
              b.style.left = d + "px";
            };
            c.ySetter = function (d) {
              p.call(c, a.distance);
              b.style.top = d + "px";
            };
          }
          this.label.attr({
            zIndex: 8
          }).add();
        }
        return this.label;
      };
      c.prototype.getPosition = function (a, e, f) {
        var l = this.chart,
          d = this.distance,
          b = {},
          h = l.inverted && f.h || 0,
          n = this.outside,
          k = n ? C.documentElement.clientWidth - 2 * d : l.chartWidth,
          c = n ? Math.max(C.body.scrollHeight, C.documentElement.scrollHeight, C.body.offsetHeight, C.documentElement.offsetHeight, C.documentElement.clientHeight) : l.chartHeight,
          w = l.pointer.getChartPosition(),
          m = function (b) {
            var h = "x" === b;
            return [b, h ? k : c, h ? a : e].concat(n ? [h ? a * w.scaleX : e * w.scaleY, h ? w.left - d + (f.plotX + l.plotLeft) * w.scaleX : w.top - d + (f.plotY + l.plotTop) * w.scaleY, 0, h ? k : c] : [h ? a : e, h ? f.plotX + l.plotLeft : f.plotY + l.plotTop, h ? l.plotLeft : l.plotTop, h ? l.plotLeft + l.plotWidth : l.plotTop + l.plotHeight]);
          },
          p = m("y"),
          u = m("x"),
          q;
        m = !!f.negative;
        !l.polar && l.hoverSeries && l.hoverSeries.yAxis && l.hoverSeries.yAxis.reversed && (m = !m);
        var g = !this.followPointer && G(f.ttBelow, !l.inverted === m),
          r = function (f, a, l, e, v, k, c) {
            var m = n ? "y" === f ? d * w.scaleY : d * w.scaleX : d,
              E = (l - e) / 2,
              p = e < v - d,
              u = v + d + e < a,
              q = v - m - l + E;
            v = v + m - E;
            if (g && u) b[f] = v;else if (!g && p) b[f] = q;else if (p) b[f] = Math.min(c - e, 0 > q - h ? q : q - h);else if (u) b[f] = Math.max(k, v + h + l > a ? v : v + h);else return !1;
          },
          t = function (h, f, a, l, n) {
            var e;
            n < d || n > f - d ? e = !1 : b[h] = n < a / 2 ? 1 : n > f - l / 2 ? f - l - 2 : n - a / 2;
            return e;
          },
          D = function (b) {
            var d = p;
            p = u;
            u = d;
            q = b;
          },
          N = function () {
            !1 !== r.apply(0, p) ? !1 !== t.apply(0, u) || q || (D(!0), N()) : q ? b.x = b.y = 0 : (D(!0), N());
          };
        (l.inverted || 1 < this.len) && D();
        N();
        return b;
      };
      c.prototype.hide = function (a) {
        var n = this;
        B.clearTimeout(this.hideTimer);
        a = G(a, this.options.hideDelay);
        this.isHidden || (this.hideTimer = K(function () {
          n.getLabel().fadeOut(a ? void 0 : a);
          n.isHidden = !0;
        }, a));
      };
      c.prototype.init = function (a, e) {
        this.chart = a;
        this.options = e;
        this.crosshairs = [];
        this.now = {
          x: 0,
          y: 0
        };
        this.isHidden = !0;
        this.split = e.split && !a.inverted && !a.polar;
        this.shared = e.shared || this.split;
        this.outside = G(e.outside, !(!a.scrollablePixelsX && !a.scrollablePixelsY));
      };
      c.prototype.shouldStickOnContact = function (a) {
        return !(this.followPointer || !this.options.stickOnContact || a && !this.chart.pointer.inClass(a.target, "highcharts-tooltip"));
      };
      c.prototype.move = function (a, e, f, l) {
        var d = this,
          b = d.now,
          h = !1 !== d.options.animation && !d.isHidden && (1 < Math.abs(a - b.x) || 1 < Math.abs(e - b.y)),
          n = d.followPointer || 1 < d.len;
        q(b, {
          x: h ? (2 * b.x + a) / 3 : a,
          y: h ? (b.y + e) / 2 : e,
          anchorX: n ? void 0 : h ? (2 * b.anchorX + f) / 3 : f,
          anchorY: n ? void 0 : h ? (b.anchorY + l) / 2 : l
        });
        d.getLabel().attr(b);
        d.drawTracker();
        h && (B.clearTimeout(this.tooltipTimeout), this.tooltipTimeout = setTimeout(function () {
          d && d.move(a, e, f, l);
        }, 32));
      };
      c.prototype.refresh = function (a, e) {
        var f = this.chart,
          l = this.options,
          d = f.pointer,
          b = H(a),
          h = b[0],
          n = [],
          k = l.formatter || this.defaultFormatter,
          c = this.shared,
          w = f.styledMode,
          u = {};
        if (l.enabled && h.series) {
          B.clearTimeout(this.hideTimer);
          this.allowShared = !(!p(a) && a.series && a.series.noSharedTooltip);
          this.followPointer = !this.split && h.series.tooltipOptions.followPointer;
          a = this.getAnchor(a, e);
          var q = a[0],
            g = a[1];
          c && this.allowShared ? (d.applyInactiveState(b), b.forEach(function (b) {
            b.setState("hover");
            n.push(b.getLabelConfig());
          }), u = {
            x: h.category,
            y: h.y
          }, u.points = n) : u = h.getLabelConfig();
          this.len = n.length;
          k = k.call(u, this);
          c = h.series;
          this.distance = G(c.tooltipOptions.distance, 16);
          if (!1 === k) this.hide();else {
            if (this.split && this.allowShared) this.renderSplit(k, b);else {
              var F = q,
                r = g;
              e && d.isDirectTouch && (F = e.chartX - f.plotLeft, r = e.chartY - f.plotTop);
              if (f.polar || !1 === c.options.clip || b.some(function (b) {
                return d.isDirectTouch || b.series.shouldShowTooltip(F, r);
              })) e = this.getLabel(), l.style.width && !w || e.css({
                width: f.spacingBox.width + "px"
              }), e.attr({
                text: k && k.join ? k.join("") : k
              }), e.addClass(this.getClassName(h), !0), w || e.attr({
                stroke: l.borderColor || h.color || c.color || "#666666"
              }), this.updatePosition({
                plotX: q,
                plotY: g,
                negative: h.negative,
                ttBelow: h.ttBelow,
                h: a[2] || 0
              });else {
                this.hide();
                return;
              }
            }
            this.isHidden && this.label && this.label.attr({
              opacity: 1
            }).show();
            this.isHidden = !1;
          }
          m(this, "refresh");
        }
      };
      c.prototype.renderSplit = function (a, k) {
        function f(b, d, h, a, f) {
          void 0 === f && (f = !0);
          h ? (d = y ? 0 : ca, b = t(b - a / 2, X.left, X.right - a - (l.outside ? B : 0))) : (d -= L, b = f ? b - a - H : b + H, b = t(b, f ? b : X.left, X.right));
          return {
            x: b,
            y: d
          };
        }
        var l = this,
          d = l.chart,
          b = l.chart,
          h = b.chartWidth,
          n = b.chartHeight,
          c = b.plotHeight,
          w = b.plotLeft,
          m = b.plotTop,
          p = b.pointer,
          u = b.scrollablePixelsY;
        u = void 0 === u ? 0 : u;
        var g = b.scrollablePixelsX,
          F = b.scrollingContainer;
        F = void 0 === F ? {
          scrollLeft: 0,
          scrollTop: 0
        } : F;
        var r = F.scrollLeft;
        F = F.scrollTop;
        var D = b.styledMode,
          H = l.distance,
          K = l.options,
          N = l.options.positioner,
          X = l.outside && "number" !== typeof g ? C.documentElement.getBoundingClientRect() : {
            left: r,
            right: r + h,
            top: F,
            bottom: F + n
          },
          ba = l.getLabel(),
          z = this.renderer || d.renderer,
          y = !(!d.xAxis[0] || !d.xAxis[0].opposite);
        d = p.getChartPosition();
        var B = d.left;
        d = d.top;
        var L = m + F,
          A = 0,
          ca = c - u;
        e(a) && (a = [!1, a]);
        a = a.slice(0, k.length + 1).reduce(function (b, d, h) {
          if (!1 !== d && "" !== d) {
            h = k[h - 1] || {
              isHeader: !0,
              plotX: k[0].plotX,
              plotY: c,
              series: {}
            };
            var a = h.isHeader,
              e = a ? l : h.series;
            d = d.toString();
            var n = e.tt,
              v = h.isHeader;
            var E = h.series;
            n || (n = {
              padding: K.padding,
              r: K.borderRadius
            }, D || (n.fill = K.backgroundColor, n["stroke-width"] = K.borderWidth), n = z.label("", 0, 0, K[v ? "headerShape" : "shape"], void 0, void 0, K.useHTML).addClass(l.getClassName(h, !0, v)).attr(n).add(ba));
            n.isActive = !0;
            n.attr({
              text: d
            });
            D || n.css(K.style).shadow(K.shadow).attr({
              stroke: K.borderColor || h.color || E.color || "#333333"
            });
            e = e.tt = n;
            v = e.getBBox();
            d = v.width + e.strokeWidth();
            a && (A = v.height, ca += A, y && (L -= A));
            E = h.plotX;
            E = void 0 === E ? 0 : E;
            n = h.plotY;
            n = void 0 === n ? 0 : n;
            var p = h.series;
            if (h.isHeader) {
              E = w + E;
              var u = m + c / 2;
            } else {
              var q = p.xAxis,
                J = p.yAxis;
              E = q.pos + t(E, -H, q.len + H);
              p.shouldShowTooltip(0, J.pos - m + n, {
                ignoreX: !0
              }) && (u = J.pos + n);
            }
            E = t(E, X.left - H, X.right + H);
            "number" === typeof u ? (v = v.height + 1, n = N ? N.call(l, d, v, h) : f(E, u, a, d), b.push({
              align: N ? 0 : void 0,
              anchorX: E,
              anchorY: u,
              boxWidth: d,
              point: h,
              rank: G(n.rank, a ? 1 : 0),
              size: v,
              target: n.y,
              tt: e,
              x: n.x
            })) : e.isActive = !1;
          }
          return b;
        }, []);
        !N && a.some(function (b) {
          var d = (l.outside ? B : 0) + b.anchorX;
          return d < X.left && d + b.boxWidth < X.right ? !0 : d < B - X.left + b.boxWidth && X.right - d > d;
        }) && (a = a.map(function (b) {
          var d = f(b.anchorX, b.anchorY, b.point.isHeader, b.boxWidth, !1);
          return q(b, {
            target: d.y,
            x: d.x
          });
        }));
        l.cleanSplit();
        x(a, ca);
        var Z = B,
          I = B;
        a.forEach(function (b) {
          var d = b.x,
            h = b.boxWidth;
          b = b.isHeader;
          b || (l.outside && B + d < Z && (Z = B + d), !b && l.outside && Z + h > I && (I = B + d));
        });
        a.forEach(function (b) {
          var d = b.x,
            h = b.anchorX,
            a = b.pos,
            f = b.point.isHeader;
          a = {
            visibility: "undefined" === typeof a ? "hidden" : "inherit",
            x: d,
            y: (a || 0) + L,
            anchorX: h,
            anchorY: b.anchorY
          };
          if (l.outside && d < h) {
            var e = B - Z;
            0 < e && (f || (a.x = d + e, a.anchorX = h + e), f && (a.x = (I - Z) / 2, a.anchorX = h + e));
          }
          b.tt.attr(a);
        });
        a = l.container;
        u = l.renderer;
        l.outside && a && u && (b = ba.getBBox(), u.setSize(b.width + b.x, b.height + b.y, !1), a.style.left = Z + "px", a.style.top = d + "px");
      };
      c.prototype.drawTracker = function () {
        if (this.shouldStickOnContact()) {
          var a = this.chart,
            e = this.label,
            f = this.shared ? a.hoverPoints : a.hoverPoint;
          if (e && f) {
            var l = {
              x: 0,
              y: 0,
              width: 0,
              height: 0
            };
            f = this.getAnchor(f);
            var d = e.getBBox();
            f[0] += a.plotLeft - e.translateX;
            f[1] += a.plotTop - e.translateY;
            l.x = Math.min(0, f[0]);
            l.y = Math.min(0, f[1]);
            l.width = 0 > f[0] ? Math.max(Math.abs(f[0]), d.width - f[0]) : Math.max(Math.abs(f[0]), d.width);
            l.height = 0 > f[1] ? Math.max(Math.abs(f[1]), d.height - Math.abs(f[1])) : Math.max(Math.abs(f[1]), d.height);
            this.tracker ? this.tracker.attr(l) : (this.tracker = e.renderer.rect(l).addClass("highcharts-tracker").add(e), a.styledMode || this.tracker.attr({
              fill: "rgba(0,0,0,0)"
            }));
          }
        } else this.tracker && this.tracker.destroy();
      };
      c.prototype.styledModeFormat = function (a) {
        return a.replace('style="font-size: 10px"', 'class="highcharts-header"').replace(/style="color:{(point|series)\.color}"/g, 'class="highcharts-color-{$1.colorIndex} {series.options.className} {point.options.className}"');
      };
      c.prototype.tooltipFooterHeaderFormatter = function (a, e) {
        var f = a.series,
          l = f.tooltipOptions,
          d = f.xAxis,
          b = d && d.dateTime;
        d = {
          isFooter: e,
          labelConfig: a
        };
        var h = l.xDateFormat,
          n = l[e ? "footerFormat" : "headerFormat"];
        m(this, "headerFormatter", d, function (d) {
          b && !h && k(a.key) && (h = b.getXDateFormat(a.key, l.dateTimeLabelFormats));
          b && h && (a.point && a.point.tooltipDateKeys || ["key"]).forEach(function (b) {
            n = n.replace("{point." + b + "}", "{point." + b + ":" + h + "}");
          });
          f.chart.styledMode && (n = this.styledModeFormat(n));
          d.text = z(n, {
            point: a,
            series: f
          }, this.chart);
        });
        return d.text;
      };
      c.prototype.update = function (a) {
        this.destroy();
        D(!0, this.chart.options.tooltip.userOptions, a);
        this.init(this.chart, D(!0, this.options, a));
      };
      c.prototype.updatePosition = function (a) {
        var e = this.chart,
          f = this.distance,
          l = this.options,
          d = e.pointer,
          b = this.getLabel(),
          h = d.getChartPosition();
        d = h.left;
        var n = h.top,
          k = h.scaleX;
        h = h.scaleY;
        var c = (l.positioner || this.getPosition).call(this, b.width, b.height, a),
          m = (a.plotX || 0) + e.plotLeft;
        a = (a.plotY || 0) + e.plotTop;
        if (this.outside) {
          l.positioner && (c.x += d - f, c.y += n - f);
          f = l.borderWidth + 2 * f;
          this.renderer.setSize(b.width + f, b.height + f, !1);
          if (1 !== k || 1 !== h) r(this.container, {
            transform: "scale(".concat(k, ", ").concat(h, ")")
          }), m *= k, a *= h;
          m += d - c.x;
          a += n - c.y;
        }
        this.move(Math.round(c.x), Math.round(c.y || 0), m, a);
      };
      return c;
    }();
    "";
    return c;
  });
  I(g, "Core/Series/Point.js", [g["Core/Renderer/HTML/AST.js"], g["Core/Animation/AnimationUtilities.js"], g["Core/Defaults.js"], g["Core/FormatUtilities.js"], g["Core/Utilities.js"]], function (c, g, y, A, B) {
    var z = g.animObject,
      C = y.defaultOptions,
      x = A.format,
      t = B.addEvent,
      r = B.defined,
      a = B.erase,
      q = B.extend,
      m = B.fireEvent,
      p = B.getNestedProperty,
      k = B.isArray,
      e = B.isFunction,
      D = B.isNumber,
      G = B.isObject,
      H = B.merge,
      K = B.objectEach,
      u = B.pick,
      n = B.syncTimeout,
      w = B.removeEvent,
      f = B.uniqueKey;
    g = function () {
      function l() {
        this.category = void 0;
        this.formatPrefix = "point";
        this.id = void 0;
        this.isNull = !1;
        this.percentage = this.options = this.name = void 0;
        this.selected = !1;
        this.total = this.shapeArgs = this.series = void 0;
        this.visible = !0;
        this.x = void 0;
      }
      l.prototype.animateBeforeDestroy = function () {
        var d = this,
          b = {
            x: d.startXPos,
            opacity: 0
          },
          h = d.getGraphicalProps();
        h.singular.forEach(function (h) {
          d[h] = d[h].animate("dataLabel" === h ? {
            x: d[h].startXPos,
            y: d[h].startYPos,
            opacity: 0
          } : b);
        });
        h.plural.forEach(function (b) {
          d[b].forEach(function (b) {
            b.element && b.animate(q({
              x: d.startXPos
            }, b.startYPos ? {
              x: b.startXPos,
              y: b.startYPos
            } : {}));
          });
        });
      };
      l.prototype.applyOptions = function (d, b) {
        var h = this.series,
          a = h.options.pointValKey || h.pointValKey;
        d = l.prototype.optionsToObject.call(this, d);
        q(this, d);
        this.options = this.options ? q(this.options, d) : d;
        d.group && delete this.group;
        d.dataLabels && delete this.dataLabels;
        a && (this.y = l.prototype.getNestedProperty.call(this, a));
        this.formatPrefix = (this.isNull = this.isValid && !this.isValid()) ? "null" : "point";
        this.selected && (this.state = "select");
        "name" in this && "undefined" === typeof b && h.xAxis && h.xAxis.hasNames && (this.x = h.xAxis.nameToX(this));
        "undefined" === typeof this.x && h ? this.x = "undefined" === typeof b ? h.autoIncrement() : b : D(d.x) && h.options.relativeXValue && (this.x = h.autoIncrement(d.x));
        return this;
      };
      l.prototype.destroy = function () {
        function d() {
          if (b.graphic || b.graphics || b.dataLabel || b.dataLabels) w(b), b.destroyElements();
          for (k in b) b[k] = null;
        }
        var b = this,
          h = b.series,
          f = h.chart;
        h = h.options.dataSorting;
        var l = f.hoverPoints,
          e = z(b.series.chart.renderer.globalAnimation),
          k;
        b.legendItem && f.legend.destroyItem(b);
        l && (b.setState(), a(l, b), l.length || (f.hoverPoints = null));
        if (b === f.hoverPoint) b.onMouseOut();
        h && h.enabled ? (this.animateBeforeDestroy(), n(d, e.duration)) : d();
        f.pointCount--;
      };
      l.prototype.destroyElements = function (d) {
        var b = this;
        d = b.getGraphicalProps(d);
        d.singular.forEach(function (d) {
          b[d] = b[d].destroy();
        });
        d.plural.forEach(function (d) {
          b[d].forEach(function (b) {
            b && b.element && b.destroy();
          });
          delete b[d];
        });
      };
      l.prototype.firePointEvent = function (d, b, h) {
        var a = this,
          f = this.series.options;
        (f.point.events[d] || a.options && a.options.events && a.options.events[d]) && a.importEvents();
        "click" === d && f.allowPointSelect && (h = function (b) {
          a.select && a.select(null, b.ctrlKey || b.metaKey || b.shiftKey);
        });
        m(a, d, b, h);
      };
      l.prototype.getClassName = function () {
        return "highcharts-point" + (this.selected ? " highcharts-point-select" : "") + (this.negative ? " highcharts-negative" : "") + (this.isNull ? " highcharts-null-point" : "") + ("undefined" !== typeof this.colorIndex ? " highcharts-color-" + this.colorIndex : "") + (this.options.className ? " " + this.options.className : "") + (this.zone && this.zone.className ? " " + this.zone.className.replace("highcharts-negative", "") : "");
      };
      l.prototype.getGraphicalProps = function (d) {
        var b = this,
          h = [],
          a = {
            singular: [],
            plural: []
          },
          f;
        d = d || {
          graphic: 1,
          dataLabel: 1
        };
        d.graphic && h.push("graphic", "shadowGroup");
        d.dataLabel && h.push("dataLabel", "dataLabelPath", "dataLabelUpper", "connector");
        for (f = h.length; f--;) {
          var l = h[f];
          b[l] && a.singular.push(l);
        }
        ["graphic", "dataLabel", "connector"].forEach(function (h) {
          var f = h + "s";
          d[h] && b[f] && a.plural.push(f);
        });
        return a;
      };
      l.prototype.getLabelConfig = function () {
        return {
          x: this.category,
          y: this.y,
          color: this.color,
          colorIndex: this.colorIndex,
          key: this.name || this.category,
          series: this.series,
          point: this,
          percentage: this.percentage,
          total: this.total || this.stackTotal
        };
      };
      l.prototype.getNestedProperty = function (d) {
        if (d) return 0 === d.indexOf("custom.") ? p(d, this.options) : this[d];
      };
      l.prototype.getZone = function () {
        var d = this.series,
          b = d.zones;
        d = d.zoneAxis || "y";
        var h,
          a = 0;
        for (h = b[a]; this[d] >= h.value;) h = b[++a];
        this.nonZonedColor || (this.nonZonedColor = this.color);
        this.color = h && h.color && !this.options.color ? h.color : this.nonZonedColor;
        return h;
      };
      l.prototype.hasNewShapeType = function () {
        return (this.graphic && (this.graphic.symbolName || this.graphic.element.nodeName)) !== this.shapeType;
      };
      l.prototype.init = function (d, b, h) {
        this.series = d;
        this.applyOptions(b, h);
        this.id = r(this.id) ? this.id : f();
        this.resolveColor();
        d.chart.pointCount++;
        m(this, "afterInit");
        return this;
      };
      l.prototype.isValid = function () {
        return null !== this.x && D(this.y);
      };
      l.prototype.optionsToObject = function (d) {
        var b = this.series,
          h = b.options.keys,
          a = h || b.pointArrayMap || ["y"],
          f = a.length,
          e = {},
          n = 0,
          c = 0;
        if (D(d) || null === d) e[a[0]] = d;else if (k(d)) for (!h && d.length > f && (b = typeof d[0], "string" === b ? e.name = d[0] : "number" === b && (e.x = d[0]), n++); c < f;) h && "undefined" === typeof d[n] || (0 < a[c].indexOf(".") ? l.prototype.setNestedProperty(e, d[n], a[c]) : e[a[c]] = d[n]), n++, c++;else "object" === typeof d && (e = d, d.dataLabels && (b._hasPointLabels = !0), d.marker && (b._hasPointMarkers = !0));
        return e;
      };
      l.prototype.pos = function (d, b) {
        void 0 === b && (b = this.plotY);
        var h = this.plotX,
          a = this.series,
          f = a.chart,
          l = a.xAxis;
        a = a.yAxis;
        var e = 0,
          n = 0;
        if (D(h) && D(b)) return d && (e = l ? l.pos : f.plotLeft, n = a ? a.pos : f.plotTop), f.inverted && l && a ? [a.len - b + n, l.len - h + e] : [h + e, b + n];
      };
      l.prototype.resolveColor = function () {
        var d = this.series,
          b = d.chart.styledMode;
        var h = d.chart.options.chart.colorCount;
        delete this.nonZonedColor;
        if (d.options.colorByPoint) {
          if (!b) {
            h = d.options.colors || d.chart.options.colors;
            var a = h[d.colorCounter];
            h = h.length;
          }
          b = d.colorCounter;
          d.colorCounter++;
          d.colorCounter === h && (d.colorCounter = 0);
        } else b || (a = d.color), b = d.colorIndex;
        this.colorIndex = u(this.options.colorIndex, b);
        this.color = u(this.options.color, a);
      };
      l.prototype.setNestedProperty = function (d, b, h) {
        h.split(".").reduce(function (d, h, a, f) {
          d[h] = f.length - 1 === a ? b : G(d[h], !0) ? d[h] : {};
          return d[h];
        }, d);
        return d;
      };
      l.prototype.shouldDraw = function () {
        return !this.isNull;
      };
      l.prototype.tooltipFormatter = function (d) {
        var b = this.series,
          h = b.tooltipOptions,
          a = u(h.valueDecimals, ""),
          f = h.valuePrefix || "",
          l = h.valueSuffix || "";
        b.chart.styledMode && (d = b.chart.tooltip.styledModeFormat(d));
        (b.pointArrayMap || ["y"]).forEach(function (b) {
          b = "{point." + b;
          if (f || l) d = d.replace(RegExp(b + "}", "g"), f + b + "}" + l);
          d = d.replace(RegExp(b + "}", "g"), b + ":,." + a + "f}");
        });
        return x(d, {
          point: this,
          series: this.series
        }, b.chart);
      };
      l.prototype.update = function (d, b, h, a) {
        function f() {
          l.applyOptions(d);
          var a = n && l.hasMockGraphic;
          a = null === l.y ? !a : a;
          n && a && (l.graphic = n.destroy(), delete l.hasMockGraphic);
          G(d, !0) && (n && n.element && d && d.marker && "undefined" !== typeof d.marker.symbol && (l.graphic = n.destroy()), d && d.dataLabels && l.dataLabel && (l.dataLabel = l.dataLabel.destroy()), l.connector && (l.connector = l.connector.destroy()));
          c = l.index;
          e.updateParallelArrays(l, c);
          v.data[c] = G(v.data[c], !0) || G(d, !0) ? l.options : u(d, v.data[c]);
          e.isDirty = e.isDirtyData = !0;
          !e.fixedBox && e.hasCartesianSeries && (k.isDirtyBox = !0);
          "point" === v.legendType && (k.isDirtyLegend = !0);
          b && k.redraw(h);
        }
        var l = this,
          e = l.series,
          n = l.graphic,
          k = e.chart,
          v = e.options,
          c;
        b = u(b, !0);
        !1 === a ? f() : l.firePointEvent("update", {
          options: d
        }, f);
      };
      l.prototype.remove = function (d, b) {
        this.series.removePoint(this.series.data.indexOf(this), d, b);
      };
      l.prototype.select = function (d, b) {
        var h = this,
          a = h.series,
          f = a.chart;
        this.selectedStaging = d = u(d, !h.selected);
        h.firePointEvent(d ? "select" : "unselect", {
          accumulate: b
        }, function () {
          h.selected = h.options.selected = d;
          a.options.data[a.data.indexOf(h)] = h.options;
          h.setState(d && "select");
          b || f.getSelectedPoints().forEach(function (b) {
            var d = b.series;
            b.selected && b !== h && (b.selected = b.options.selected = !1, d.options.data[d.data.indexOf(b)] = b.options, b.setState(f.hoverPoints && d.options.inactiveOtherPoints ? "inactive" : ""), b.firePointEvent("unselect"));
          });
        });
        delete this.selectedStaging;
      };
      l.prototype.onMouseOver = function (d) {
        var b = this.series.chart,
          h = b.pointer;
        d = d ? h.normalize(d) : h.getChartCoordinatesFromPoint(this, b.inverted);
        h.runPointActions(d, this);
      };
      l.prototype.onMouseOut = function () {
        var d = this.series.chart;
        this.firePointEvent("mouseOut");
        this.series.options.inactiveOtherPoints || (d.hoverPoints || []).forEach(function (b) {
          b.setState();
        });
        d.hoverPoints = d.hoverPoint = null;
      };
      l.prototype.importEvents = function () {
        if (!this.hasImportedEvents) {
          var d = this,
            b = H(d.series.options.point, d.options).events;
          d.events = b;
          K(b, function (b, a) {
            e(b) && t(d, a, b);
          });
          this.hasImportedEvents = !0;
        }
      };
      l.prototype.setState = function (d, b) {
        var h = this.series,
          a = this.state,
          f = h.options.states[d || "normal"] || {},
          l = C.plotOptions[h.type].marker && h.options.marker,
          e = l && !1 === l.enabled,
          n = l && l.states && l.states[d || "normal"] || {},
          k = !1 === n.enabled,
          w = this.marker || {},
          p = h.chart,
          g = l && h.markerAttribs,
          r = h.halo,
          t,
          G = h.stateMarkerGraphic;
        d = d || "";
        if (!(d === this.state && !b || this.selected && "select" !== d || !1 === f.enabled || d && (k || e && !1 === n.enabled) || d && w.states && w.states[d] && !1 === w.states[d].enabled)) {
          this.state = d;
          g && (t = h.markerAttribs(this, d));
          if (this.graphic && !this.hasMockGraphic) {
            a && this.graphic.removeClass("highcharts-point-" + a);
            d && this.graphic.addClass("highcharts-point-" + d);
            if (!p.styledMode) {
              a = h.pointAttribs(this, d);
              var N = u(p.options.chart.animation, f.animation);
              var x = a.opacity;
              h.options.inactiveOtherPoints && D(x) && ((this.dataLabels || []).forEach(function (b) {
                b && !b.hasClass("highcharts-data-label-hidden") && b.animate({
                  opacity: x
                }, N);
              }), this.connector && this.connector.animate({
                opacity: x
              }, N));
              this.graphic.animate(a, N);
            }
            t && this.graphic.animate(t, u(p.options.chart.animation, n.animation, l.animation));
            G && G.hide();
          } else {
            if (d && n) {
              l = w.symbol || h.symbol;
              G && G.currentSymbol !== l && (G = G.destroy());
              if (t) if (G) G[b ? "animate" : "attr"]({
                x: t.x,
                y: t.y
              });else l && (h.stateMarkerGraphic = G = p.renderer.symbol(l, t.x, t.y, t.width, t.height).add(h.markerGroup), G.currentSymbol = l);
              !p.styledMode && G && "inactive" !== this.state && G.attr(h.pointAttribs(this, d));
            }
            G && (G[d && this.isInside ? "show" : "hide"](), G.element.point = this, G.addClass(this.getClassName(), !0));
          }
          f = f.halo;
          t = (G = this.graphic || G) && G.visibility || "inherit";
          f && f.size && G && "hidden" !== t && !this.isCluster ? (r || (h.halo = r = p.renderer.path().add(G.parentGroup)), r.show()[b ? "animate" : "attr"]({
            d: this.haloPath(f.size)
          }), r.attr({
            "class": "highcharts-halo highcharts-color-" + u(this.colorIndex, h.colorIndex) + (this.className ? " " + this.className : ""),
            visibility: t,
            zIndex: -1
          }), r.point = this, p.styledMode || r.attr(q({
            fill: this.color || h.color,
            "fill-opacity": f.opacity
          }, c.filterUserAttributes(f.attributes || {})))) : r && r.point && r.point.haloPath && r.animate({
            d: r.point.haloPath(0)
          }, null, r.hide);
          m(this, "afterSetState", {
            state: d
          });
        }
      };
      l.prototype.haloPath = function (d) {
        var b = this.pos();
        return b ? this.series.chart.renderer.symbols.circle(Math.floor(b[0]) - d, b[1] - d, 2 * d, 2 * d) : [];
      };
      return l;
    }();
    "";
    return g;
  });
  I(g, "Core/Pointer.js", [g["Core/Color/Color.js"], g["Core/Globals.js"], g["Core/Tooltip.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var B = c.parse,
      z = g.charts,
      C = g.noop,
      x = A.addEvent,
      t = A.attr,
      r = A.css,
      a = A.defined,
      q = A.extend,
      m = A.find,
      p = A.fireEvent,
      k = A.isNumber,
      e = A.isObject,
      D = A.objectEach,
      G = A.offset,
      H = A.pick,
      K = A.splat;
    c = function () {
      function c(a, e) {
        this.lastValidTouch = {};
        this.pinchDown = [];
        this.runChartClick = !1;
        this.eventsToUnbind = [];
        this.chart = a;
        this.hasDragged = !1;
        this.options = e;
        this.init(a, e);
      }
      c.prototype.applyInactiveState = function (a) {
        var e = [],
          f;
        (a || []).forEach(function (a) {
          f = a.series;
          e.push(f);
          f.linkedParent && e.push(f.linkedParent);
          f.linkedSeries && (e = e.concat(f.linkedSeries));
          f.navigatorSeries && e.push(f.navigatorSeries);
        });
        this.chart.series.forEach(function (a) {
          -1 === e.indexOf(a) ? a.setState("inactive", !0) : a.options.inactiveOtherPoints && a.setAllPointsToState("inactive");
        });
      };
      c.prototype.destroy = function () {
        var a = this;
        this.eventsToUnbind.forEach(function (a) {
          return a();
        });
        this.eventsToUnbind = [];
        g.chartCount || (c.unbindDocumentMouseUp && (c.unbindDocumentMouseUp = c.unbindDocumentMouseUp()), c.unbindDocumentTouchEnd && (c.unbindDocumentTouchEnd = c.unbindDocumentTouchEnd()));
        clearInterval(a.tooltipTimeout);
        D(a, function (e, f) {
          a[f] = void 0;
        });
      };
      c.prototype.getSelectionMarkerAttrs = function (a, e) {
        var f = this,
          l = {
            args: {
              chartX: a,
              chartY: e
            },
            attrs: {},
            shapeType: "rect"
          };
        p(this, "getSelectionMarkerAttrs", l, function (d) {
          var b = f.chart,
            h = f.mouseDownX;
          h = void 0 === h ? 0 : h;
          var l = f.mouseDownY;
          l = void 0 === l ? 0 : l;
          var n = f.zoomHor,
            k = f.zoomVert;
          d = d.attrs;
          d.x = b.plotLeft;
          d.y = b.plotTop;
          d.width = n ? 1 : b.plotWidth;
          d.height = k ? 1 : b.plotHeight;
          n && (b = a - h, d.width = Math.abs(b), d.x = (0 < b ? 0 : b) + h);
          k && (b = e - l, d.height = Math.abs(b), d.y = (0 < b ? 0 : b) + l);
        });
        return l;
      };
      c.prototype.drag = function (a) {
        var n = this.chart,
          f = n.options.chart,
          l = n.plotLeft,
          d = n.plotTop,
          b = n.plotWidth,
          h = n.plotHeight,
          k = this.mouseDownX || 0,
          c = this.mouseDownY || 0,
          m = e(f.panning) ? f.panning && f.panning.enabled : f.panning,
          p = f.panKey && a[f.panKey + "Key"],
          q = a.chartX,
          u = a.chartY,
          g = this.selectionMarker;
        g && g.touch || (q < l ? q = l : q > l + b && (q = l + b), u < d ? u = d : u > d + h && (u = d + h), this.hasDragged = Math.sqrt(Math.pow(k - q, 2) + Math.pow(c - u, 2)), 10 < this.hasDragged && (l = n.isInsidePlot(k - l, c - d, {
          visiblePlotOnly: !0
        }), u = this.getSelectionMarkerAttrs(q, u), q = u.shapeType, u = u.attrs, !n.hasCartesianSeries && !n.mapView || !this.zoomX && !this.zoomY || !l || p || g || (this.selectionMarker = g = n.renderer[q](), g.attr({
          "class": "highcharts-selection-marker",
          zIndex: 7
        }).add(), n.styledMode || g.attr({
          fill: f.selectionMarkerFill || B("#335cad").setOpacity(.25).get()
        })), g && g.attr(u), l && !g && m && n.pan(a, f.panning)));
      };
      c.prototype.dragStart = function (a) {
        var e = this.chart;
        e.mouseIsDown = a.type;
        e.cancelClick = !1;
        e.mouseDownX = this.mouseDownX = a.chartX;
        e.mouseDownY = this.mouseDownY = a.chartY;
      };
      c.prototype.getSelectionBox = function (a) {
        var e = {
          args: {
            marker: a
          },
          result: {}
        };
        p(this, "getSelectionBox", e, function (f) {
          f.result = {
            x: a.attr ? +a.attr("x") : a.x,
            y: a.attr ? +a.attr("y") : a.y,
            width: a.attr ? a.attr("width") : a.width,
            height: a.attr ? a.attr("height") : a.height
          };
        });
        return e.result;
      };
      c.prototype.drop = function (e) {
        var n = this,
          f = this.chart,
          l = this.hasPinched;
        if (this.selectionMarker) {
          var d = this.getSelectionBox(this.selectionMarker),
            b = d.x,
            h = d.y,
            c = d.width,
            m = d.height,
            u = {
              originalEvent: e,
              xAxis: [],
              yAxis: [],
              x: b,
              y: h,
              width: c,
              height: m
            },
            g = !!f.mapView;
          if (this.hasDragged || l) f.axes.forEach(function (d) {
            if (d.zoomEnabled && a(d.min) && (l || n[{
              xAxis: "zoomX",
              yAxis: "zoomY"
            }[d.coll]]) && k(b) && k(h) && k(c) && k(m)) {
              var f = d.horiz,
                v = "touchend" === e.type ? d.minPixelPadding : 0,
                w = d.toValue((f ? b : h) + v);
              f = d.toValue((f ? b + c : h + m) - v);
              u[d.coll].push({
                axis: d,
                min: Math.min(w, f),
                max: Math.max(w, f)
              });
              g = !0;
            }
          }), g && p(f, "selection", u, function (b) {
            f.zoom(q(b, l ? {
              animation: !1
            } : null));
          });
          k(f.index) && (this.selectionMarker = this.selectionMarker.destroy());
          l && this.scaleGroups();
        }
        f && k(f.index) && (r(f.container, {
          cursor: f._cursor
        }), f.cancelClick = 10 < this.hasDragged, f.mouseIsDown = this.hasDragged = this.hasPinched = !1, this.pinchDown = []);
      };
      c.prototype.findNearestKDPoint = function (a, k, f) {
        var l;
        a.forEach(function (d) {
          var b = !(d.noSharedTooltip && k) && 0 > d.options.findNearestPointBy.indexOf("y");
          d = d.searchPoint(f, b);
          if ((b = e(d, !0) && d.series) && !(b = !e(l, !0))) {
            b = l.distX - d.distX;
            var h = l.dist - d.dist,
              a = (d.series.group && d.series.group.zIndex) - (l.series.group && l.series.group.zIndex);
            b = 0 < (0 !== b && k ? b : 0 !== h ? h : 0 !== a ? a : l.series.index > d.series.index ? -1 : 1);
          }
          b && (l = d);
        });
        return l;
      };
      c.prototype.getChartCoordinatesFromPoint = function (a, e) {
        var f = a.series,
          l = f.xAxis;
        f = f.yAxis;
        var d = a.shapeArgs;
        if (l && f) {
          var b = H(a.clientX, a.plotX),
            h = a.plotY || 0;
          a.isNode && d && k(d.x) && k(d.y) && (b = d.x, h = d.y);
          return e ? {
            chartX: f.len + f.pos - h,
            chartY: l.len + l.pos - b
          } : {
            chartX: b + l.pos,
            chartY: h + f.pos
          };
        }
        if (d && d.x && d.y) return {
          chartX: d.x,
          chartY: d.y
        };
      };
      c.prototype.getChartPosition = function () {
        if (this.chartPosition) return this.chartPosition;
        var a = this.chart.container,
          e = G(a);
        this.chartPosition = {
          left: e.left,
          top: e.top,
          scaleX: 1,
          scaleY: 1
        };
        var f = a.offsetWidth;
        a = a.offsetHeight;
        2 < f && 2 < a && (this.chartPosition.scaleX = e.width / f, this.chartPosition.scaleY = e.height / a);
        return this.chartPosition;
      };
      c.prototype.getCoordinates = function (a) {
        var e = {
          xAxis: [],
          yAxis: []
        };
        this.chart.axes.forEach(function (f) {
          e[f.isXAxis ? "xAxis" : "yAxis"].push({
            axis: f,
            value: f.toValue(a[f.horiz ? "chartX" : "chartY"])
          });
        });
        return e;
      };
      c.prototype.getHoverData = function (a, k, f, l, d, b) {
        var h = [];
        l = !(!l || !a);
        var n = function (b) {
            return b.visible && !(!d && b.directTouch) && H(b.options.enableMouseTracking, !0);
          },
          c = {
            chartX: b ? b.chartX : void 0,
            chartY: b ? b.chartY : void 0,
            shared: d
          };
        p(this, "beforeGetHoverData", c);
        var w = k && !k.stickyTracking ? [k] : f.filter(function (b) {
          return b.stickyTracking && (c.filter || n)(b);
        });
        var u = l || !b ? a : this.findNearestKDPoint(w, d, b);
        k = u && u.series;
        u && (d && !k.noSharedTooltip ? (w = f.filter(function (b) {
          return c.filter ? c.filter(b) : n(b) && !b.noSharedTooltip;
        }), w.forEach(function (b) {
          var d = m(b.points, function (b) {
            return b.x === u.x && !b.isNull;
          });
          e(d) && (b.boosted && b.boost && (d = b.boost.getPoint(d)), h.push(d));
        })) : h.push(u));
        c = {
          hoverPoint: u
        };
        p(this, "afterGetHoverData", c);
        return {
          hoverPoint: c.hoverPoint,
          hoverSeries: k,
          hoverPoints: h
        };
      };
      c.prototype.getPointFromEvent = function (a) {
        a = a.target;
        for (var e; a && !e;) e = a.point, a = a.parentNode;
        return e;
      };
      c.prototype.onTrackerMouseOut = function (a) {
        a = a.relatedTarget || a.toElement;
        var e = this.chart.hoverSeries;
        this.isDirectTouch = !1;
        if (!(!e || !a || e.stickyTracking || this.inClass(a, "highcharts-tooltip") || this.inClass(a, "highcharts-series-" + e.index) && this.inClass(a, "highcharts-tracker"))) e.onMouseOut();
      };
      c.prototype.inClass = function (a, e) {
        for (var f; a;) {
          if (f = t(a, "class")) {
            if (-1 !== f.indexOf(e)) return !0;
            if (-1 !== f.indexOf("highcharts-container")) return !1;
          }
          a = a.parentElement;
        }
      };
      c.prototype.init = function (a, e) {
        this.options = e;
        this.chart = a;
        this.runChartClick = !(!e.chart.events || !e.chart.events.click);
        this.pinchDown = [];
        this.lastValidTouch = {};
        y && (a.tooltip = new y(a, e.tooltip));
        this.setDOMEvents();
      };
      c.prototype.normalize = function (a, e) {
        var f = a.touches,
          l = f ? f.length ? f.item(0) : H(f.changedTouches, a.changedTouches)[0] : a;
        e || (e = this.getChartPosition());
        f = l.pageX - e.left;
        l = l.pageY - e.top;
        f /= e.scaleX;
        l /= e.scaleY;
        return q(a, {
          chartX: Math.round(f),
          chartY: Math.round(l)
        });
      };
      c.prototype.onContainerClick = function (a) {
        var e = this.chart,
          f = e.hoverPoint;
        a = this.normalize(a);
        var l = e.plotLeft,
          d = e.plotTop;
        e.cancelClick || (f && this.inClass(a.target, "highcharts-tracker") ? (p(f.series, "click", q(a, {
          point: f
        })), e.hoverPoint && f.firePointEvent("click", a)) : (q(a, this.getCoordinates(a)), e.isInsidePlot(a.chartX - l, a.chartY - d, {
          visiblePlotOnly: !0
        }) && p(e, "click", a)));
      };
      c.prototype.onContainerMouseDown = function (a) {
        var e = 1 === ((a.buttons || a.button) & 1);
        a = this.normalize(a);
        if (g.isFirefox && 0 !== a.button) this.onContainerMouseMove(a);
        if ("undefined" === typeof a.button || e) this.zoomOption(a), e && a.preventDefault && a.preventDefault(), this.dragStart(a);
      };
      c.prototype.onContainerMouseLeave = function (a) {
        var e = z[H(c.hoverChartIndex, -1)],
          f = this.chart.tooltip;
        a = this.normalize(a);
        e && (a.relatedTarget || a.toElement) && (e.pointer.reset(), e.pointer.chartPosition = void 0);
        f && !f.isHidden && this.reset();
      };
      c.prototype.onContainerMouseEnter = function (a) {
        delete this.chartPosition;
      };
      c.prototype.onContainerMouseMove = function (a) {
        var e = this.chart,
          f = e.tooltip;
        a = this.normalize(a);
        this.setHoverChartIndex();
        a.preventDefault || (a.returnValue = !1);
        ("mousedown" === e.mouseIsDown || this.touchSelect(a)) && this.drag(a);
        e.openMenu || !this.inClass(a.target, "highcharts-tracker") && !e.isInsidePlot(a.chartX - e.plotLeft, a.chartY - e.plotTop, {
          visiblePlotOnly: !0
        }) || f && f.shouldStickOnContact(a) || (this.inClass(a.target, "highcharts-no-tooltip") ? this.reset(!1, 0) : this.runPointActions(a));
      };
      c.prototype.onDocumentTouchEnd = function (a) {
        var e = z[H(c.hoverChartIndex, -1)];
        e && e.pointer.drop(a);
      };
      c.prototype.onContainerTouchMove = function (a) {
        if (this.touchSelect(a)) this.onContainerMouseMove(a);else this.touch(a);
      };
      c.prototype.onContainerTouchStart = function (a) {
        if (this.touchSelect(a)) this.onContainerMouseDown(a);else this.zoomOption(a), this.touch(a, !0);
      };
      c.prototype.onDocumentMouseMove = function (a) {
        var e = this.chart,
          f = e.tooltip,
          l = this.chartPosition;
        a = this.normalize(a, l);
        !l || e.isInsidePlot(a.chartX - e.plotLeft, a.chartY - e.plotTop, {
          visiblePlotOnly: !0
        }) || f && f.shouldStickOnContact(a) || this.inClass(a.target, "highcharts-tracker") || this.reset();
      };
      c.prototype.onDocumentMouseUp = function (a) {
        var e = z[H(c.hoverChartIndex, -1)];
        e && e.pointer.drop(a);
      };
      c.prototype.pinch = function (a) {
        var e = this,
          f = e.chart,
          l = e.pinchDown,
          d = a.touches || [],
          b = d.length,
          h = e.lastValidTouch,
          k = e.hasZoom,
          c = {},
          n = 1 === b && (e.inClass(a.target, "highcharts-tracker") && f.runTrackerClick || e.runChartClick),
          m = {},
          u = e.chart.tooltip;
        u = 1 === b && H(u && u.options.followTouchMove, !0);
        var g = e.selectionMarker;
        1 < b ? e.initiated = !0 : u && (e.initiated = !1);
        k && e.initiated && !n && !1 !== a.cancelable && a.preventDefault();
        [].map.call(d, function (b) {
          return e.normalize(b);
        });
        "touchstart" === a.type ? ([].forEach.call(d, function (b, d) {
          l[d] = {
            chartX: b.chartX,
            chartY: b.chartY
          };
        }), h.x = [l[0].chartX, l[1] && l[1].chartX], h.y = [l[0].chartY, l[1] && l[1].chartY], f.axes.forEach(function (b) {
          if (b.zoomEnabled) {
            var d = f.bounds[b.horiz ? "h" : "v"],
              a = b.minPixelPadding,
              h = b.toPixels(Math.min(H(b.options.min, b.dataMin), b.dataMin)),
              l = b.toPixels(Math.max(H(b.options.max, b.dataMax), b.dataMax)),
              e = Math.max(h, l);
            d.min = Math.min(b.pos, Math.min(h, l) - a);
            d.max = Math.max(b.pos + b.len, e + a);
          }
        }), e.res = !0) : u ? this.runPointActions(e.normalize(a)) : l.length && (p(f, "touchpan", {
          originalEvent: a
        }, function () {
          g || (e.selectionMarker = g = q({
            destroy: C,
            touch: !0
          }, f.plotBox));
          e.pinchTranslate(l, d, c, g, m, h);
          e.hasPinched = k;
          e.scaleGroups(c, m);
        }), e.res && (e.res = !1, this.reset(!1, 0)));
      };
      c.prototype.pinchTranslate = function (a, e, f, l, d, b) {
        this.zoomHor && this.pinchTranslateDirection(!0, a, e, f, l, d, b);
        this.zoomVert && this.pinchTranslateDirection(!1, a, e, f, l, d, b);
      };
      c.prototype.pinchTranslateDirection = function (a, e, f, l, d, b, h, k) {
        var c = this.chart,
          v = a ? "x" : "y",
          n = a ? "X" : "Y",
          m = "chart" + n,
          p = a ? "width" : "height",
          u = c["plot" + (a ? "Left" : "Top")],
          w = c.inverted,
          q = c.bounds[a ? "h" : "v"],
          g = 1 === e.length,
          r = e[0][m],
          t = !g && e[1][m];
        e = function () {
          "number" === typeof H && 20 < Math.abs(r - t) && (G = k || Math.abs(x - H) / Math.abs(r - t));
          D = (u - x) / G + r;
          N = c["plot" + (a ? "Width" : "Height")] / G;
        };
        var N,
          D,
          G = k || 1,
          x = f[0][m],
          H = !g && f[1][m];
        e();
        f = D;
        if (f < q.min) {
          f = q.min;
          var C = !0;
        } else f + N > q.max && (f = q.max - N, C = !0);
        C ? (x -= .8 * (x - h[v][0]), "number" === typeof H && (H -= .8 * (H - h[v][1])), e()) : h[v] = [x, H];
        w || (b[v] = D - u, b[p] = N);
        b = w ? 1 / G : G;
        d[p] = N;
        d[v] = f;
        l[w ? a ? "scaleY" : "scaleX" : "scale" + n] = G;
        l["translate" + n] = b * u + (x - b * r);
      };
      c.prototype.reset = function (a, e) {
        var f = this.chart,
          l = f.hoverSeries,
          d = f.hoverPoint,
          b = f.hoverPoints,
          h = f.tooltip,
          k = h && h.shared ? b : d;
        a && k && K(k).forEach(function (b) {
          b.series.isCartesian && "undefined" === typeof b.plotX && (a = !1);
        });
        if (a) h && k && K(k).length && (h.refresh(k), h.shared && b ? b.forEach(function (b) {
          b.setState(b.state, !0);
          b.series.isCartesian && (b.series.xAxis.crosshair && b.series.xAxis.drawCrosshair(null, b), b.series.yAxis.crosshair && b.series.yAxis.drawCrosshair(null, b));
        }) : d && (d.setState(d.state, !0), f.axes.forEach(function (b) {
          b.crosshair && d.series[b.coll] === b && b.drawCrosshair(null, d);
        })));else {
          if (d) d.onMouseOut();
          b && b.forEach(function (b) {
            b.setState();
          });
          if (l) l.onMouseOut();
          h && h.hide(e);
          this.unDocMouseMove && (this.unDocMouseMove = this.unDocMouseMove());
          f.axes.forEach(function (b) {
            b.hideCrosshair();
          });
          this.hoverX = f.hoverPoints = f.hoverPoint = null;
        }
      };
      c.prototype.runPointActions = function (a, e, f) {
        var l = this.chart,
          d = l.tooltip && l.tooltip.options.enabled ? l.tooltip : void 0,
          b = d ? d.shared : !1,
          h = e || l.hoverPoint,
          k = h && h.series || l.hoverSeries;
        e = this.getHoverData(h, k, l.series, (!a || "touchmove" !== a.type) && (!!e || k && k.directTouch && this.isDirectTouch), b, a);
        h = e.hoverPoint;
        k = e.hoverSeries;
        var n = e.hoverPoints;
        e = k && k.tooltipOptions.followPointer && !k.tooltipOptions.split;
        var p = b && k && !k.noSharedTooltip;
        if (h && (f || h !== l.hoverPoint || d && d.isHidden)) {
          (l.hoverPoints || []).forEach(function (b) {
            -1 === n.indexOf(b) && b.setState();
          });
          if (l.hoverSeries !== k) k.onMouseOver();
          this.applyInactiveState(n);
          (n || []).forEach(function (b) {
            b.setState("hover");
          });
          l.hoverPoint && l.hoverPoint.firePointEvent("mouseOut");
          if (!h.series) return;
          l.hoverPoints = n;
          l.hoverPoint = h;
          h.firePointEvent("mouseOver", void 0, function () {
            d && h && d.refresh(p ? n : h, a);
          });
        } else e && d && !d.isHidden && (f = d.getAnchor([{}], a), l.isInsidePlot(f[0], f[1], {
          visiblePlotOnly: !0
        }) && d.updatePosition({
          plotX: f[0],
          plotY: f[1]
        }));
        this.unDocMouseMove || (this.unDocMouseMove = x(l.container.ownerDocument, "mousemove", function (b) {
          var d = z[c.hoverChartIndex];
          if (d) d.pointer.onDocumentMouseMove(b);
        }), this.eventsToUnbind.push(this.unDocMouseMove));
        l.axes.forEach(function (b) {
          var d = H((b.crosshair || {}).snap, !0),
            h;
          d && ((h = l.hoverPoint) && h.series[b.coll] === b || (h = m(n, function (d) {
            return d.series && d.series[b.coll] === b;
          })));
          h || !d ? b.drawCrosshair(a, h) : b.hideCrosshair();
        });
      };
      c.prototype.scaleGroups = function (a, e) {
        var f = this.chart;
        f.series.forEach(function (l) {
          var d = a || l.getPlotBox();
          l.group && (l.xAxis && l.xAxis.zoomEnabled || f.mapView) && (l.group.attr(d), l.markerGroup && (l.markerGroup.attr(d), l.markerGroup.clip(e ? f.clipRect : null)), l.dataLabelsGroup && l.dataLabelsGroup.attr(d));
        });
        f.clipRect.attr(e || f.clipBox);
      };
      c.prototype.setDOMEvents = function () {
        var a = this,
          e = this.chart.container,
          f = e.ownerDocument;
        e.onmousedown = this.onContainerMouseDown.bind(this);
        e.onmousemove = this.onContainerMouseMove.bind(this);
        e.onclick = this.onContainerClick.bind(this);
        this.eventsToUnbind.push(x(e, "mouseenter", this.onContainerMouseEnter.bind(this)));
        this.eventsToUnbind.push(x(e, "mouseleave", this.onContainerMouseLeave.bind(this)));
        c.unbindDocumentMouseUp || (c.unbindDocumentMouseUp = x(f, "mouseup", this.onDocumentMouseUp.bind(this)));
        for (var l = this.chart.renderTo.parentElement; l && "BODY" !== l.tagName;) this.eventsToUnbind.push(x(l, "scroll", function () {
          delete a.chartPosition;
        })), l = l.parentElement;
        g.hasTouch && (this.eventsToUnbind.push(x(e, "touchstart", this.onContainerTouchStart.bind(this), {
          passive: !1
        })), this.eventsToUnbind.push(x(e, "touchmove", this.onContainerTouchMove.bind(this), {
          passive: !1
        })), c.unbindDocumentTouchEnd || (c.unbindDocumentTouchEnd = x(f, "touchend", this.onDocumentTouchEnd.bind(this), {
          passive: !1
        })));
      };
      c.prototype.setHoverChartIndex = function () {
        var a = this.chart,
          e = g.charts[H(c.hoverChartIndex, -1)];
        if (e && e !== a) e.pointer.onContainerMouseLeave({
          relatedTarget: a.container
        });
        e && e.mouseIsDown || (c.hoverChartIndex = a.index);
      };
      c.prototype.touch = function (a, e) {
        var f = this.chart,
          l;
        this.setHoverChartIndex();
        if (1 === a.touches.length) {
          if (a = this.normalize(a), (l = f.isInsidePlot(a.chartX - f.plotLeft, a.chartY - f.plotTop, {
            visiblePlotOnly: !0
          })) && !f.openMenu) {
            e && this.runPointActions(a);
            if ("touchmove" === a.type) {
              e = this.pinchDown;
              var d = e[0] ? 4 <= Math.sqrt(Math.pow(e[0].chartX - a.chartX, 2) + Math.pow(e[0].chartY - a.chartY, 2)) : !1;
            }
            H(d, !0) && this.pinch(a);
          } else e && this.reset();
        } else 2 === a.touches.length && this.pinch(a);
      };
      c.prototype.touchSelect = function (a) {
        return !(!this.chart.options.chart.zooming.singleTouch || !a.touches || 1 !== a.touches.length);
      };
      c.prototype.zoomOption = function (a) {
        var e = this.chart,
          f = e.options.chart;
        e = e.inverted;
        var l = f.zooming.type || "";
        /touch/.test(a.type) && (l = H(f.zooming.pinchType, l));
        this.zoomX = a = /x/.test(l);
        this.zoomY = f = /y/.test(l);
        this.zoomHor = a && !e || f && e;
        this.zoomVert = f && !e || a && e;
        this.hasZoom = a || f;
      };
      return c;
    }();
    "";
    return c;
  });
  I(g, "Core/MSPointer.js", [g["Core/Globals.js"], g["Core/Pointer.js"], g["Core/Utilities.js"]], function (c, g, y) {
    function A() {
      var a = [];
      a.item = function (a) {
        return this[a];
      };
      m(e, function (e) {
        a.push({
          pageX: e.pageX,
          pageY: e.pageY,
          target: e.target
        });
      });
      return a;
    }
    function B(a, e, k, c) {
      var n = C[g.hoverChartIndex || NaN];
      "touch" !== a.pointerType && a.pointerType !== a.MSPOINTER_TYPE_TOUCH || !n || (n = n.pointer, c(a), n[e]({
        type: k,
        target: a.currentTarget,
        preventDefault: t,
        touches: A()
      }));
    }
    var z = this && this.__extends || function () {
        var a = function (e, k) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, e) {
            a.__proto__ = e;
          } || function (a, e) {
            for (var k in e) e.hasOwnProperty(k) && (a[k] = e[k]);
          };
          return a(e, k);
        };
        return function (e, k) {
          function c() {
            this.constructor = e;
          }
          a(e, k);
          e.prototype = null === k ? Object.create(k) : (c.prototype = k.prototype, new c());
        };
      }(),
      C = c.charts,
      x = c.doc,
      t = c.noop,
      r = c.win,
      a = y.addEvent,
      q = y.css,
      m = y.objectEach,
      p = y.pick,
      k = y.removeEvent,
      e = {},
      D = !!r.PointerEvent;
    return function (m) {
      function g() {
        return null !== m && m.apply(this, arguments) || this;
      }
      z(g, m);
      g.isRequired = function () {
        return !(c.hasTouch || !r.PointerEvent && !r.MSPointerEvent);
      };
      g.prototype.batchMSEvents = function (a) {
        a(this.chart.container, D ? "pointerdown" : "MSPointerDown", this.onContainerPointerDown);
        a(this.chart.container, D ? "pointermove" : "MSPointerMove", this.onContainerPointerMove);
        a(x, D ? "pointerup" : "MSPointerUp", this.onDocumentPointerUp);
      };
      g.prototype.destroy = function () {
        this.batchMSEvents(k);
        m.prototype.destroy.call(this);
      };
      g.prototype.init = function (a, e) {
        m.prototype.init.call(this, a, e);
        this.hasZoom && q(a.container, {
          "-ms-touch-action": "none",
          "touch-action": "none"
        });
      };
      g.prototype.onContainerPointerDown = function (a) {
        B(a, "onContainerTouchStart", "touchstart", function (a) {
          e[a.pointerId] = {
            pageX: a.pageX,
            pageY: a.pageY,
            target: a.currentTarget
          };
        });
      };
      g.prototype.onContainerPointerMove = function (a) {
        B(a, "onContainerTouchMove", "touchmove", function (a) {
          e[a.pointerId] = {
            pageX: a.pageX,
            pageY: a.pageY
          };
          e[a.pointerId].target || (e[a.pointerId].target = a.currentTarget);
        });
      };
      g.prototype.onDocumentPointerUp = function (a) {
        B(a, "onDocumentTouchEnd", "touchend", function (a) {
          delete e[a.pointerId];
        });
      };
      g.prototype.setDOMEvents = function () {
        var e = this.chart.tooltip;
        m.prototype.setDOMEvents.call(this);
        (this.hasZoom || p(e && e.options.followTouchMove, !0)) && this.batchMSEvents(a);
      };
      return g;
    }(g);
  });
  I(g, "Core/Legend/Legend.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/FormatUtilities.js"], g["Core/Globals.js"], g["Core/Series/Point.js"], g["Core/Renderer/RendererUtilities.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z) {
    var C = c.animObject,
      x = c.setAnimation,
      t = g.format,
      r = y.marginNames,
      a = B.distribute,
      q = z.addEvent,
      m = z.createElement,
      p = z.css,
      k = z.defined,
      e = z.discardElement,
      D = z.find,
      G = z.fireEvent,
      H = z.isNumber,
      K = z.merge,
      u = z.pick,
      n = z.relativeLength,
      w = z.stableSort,
      f = z.syncTimeout;
    c = function () {
      function l(d, b) {
        this.allItems = [];
        this.contentGroup = this.box = void 0;
        this.display = !1;
        this.group = void 0;
        this.offsetWidth = this.maxLegendWidth = this.maxItemWidth = this.legendWidth = this.legendHeight = this.lastLineHeight = this.lastItemY = this.itemY = this.itemX = this.itemMarginTop = this.itemMarginBottom = this.itemHeight = this.initialItemY = 0;
        this.options = void 0;
        this.padding = 0;
        this.pages = [];
        this.proximate = !1;
        this.scrollGroup = void 0;
        this.widthOption = this.totalItemWidth = this.titleHeight = this.symbolWidth = this.symbolHeight = 0;
        this.chart = d;
        this.init(d, b);
      }
      l.prototype.init = function (d, b) {
        this.chart = d;
        this.setOptions(b);
        b.enabled && (this.render(), q(this.chart, "endResize", function () {
          this.legend.positionCheckboxes();
        }), this.proximate ? this.unchartrender = q(this.chart, "render", function () {
          this.legend.proximatePositions();
          this.legend.positionItems();
        }) : this.unchartrender && this.unchartrender());
      };
      l.prototype.setOptions = function (d) {
        var b = u(d.padding, 8);
        this.options = d;
        this.chart.styledMode || (this.itemStyle = d.itemStyle, this.itemHiddenStyle = K(this.itemStyle, d.itemHiddenStyle));
        this.itemMarginTop = d.itemMarginTop || 0;
        this.itemMarginBottom = d.itemMarginBottom || 0;
        this.padding = b;
        this.initialItemY = b - 5;
        this.symbolWidth = u(d.symbolWidth, 16);
        this.pages = [];
        this.proximate = "proximate" === d.layout && !this.chart.inverted;
        this.baseline = void 0;
      };
      l.prototype.update = function (d, b) {
        var a = this.chart;
        this.setOptions(K(!0, this.options, d));
        this.destroy();
        a.isDirtyLegend = a.isDirtyBox = !0;
        u(b, !0) && a.redraw();
        G(this, "afterUpdate");
      };
      l.prototype.colorizeItem = function (d, b) {
        var a = d.legendItem || {},
          f = a.group,
          l = a.label,
          e = a.line;
        a = a.symbol;
        if (f) f[b ? "removeClass" : "addClass"]("highcharts-legend-item-hidden");
        if (!this.chart.styledMode) {
          var k = this.options;
          f = this.itemHiddenStyle.color;
          k = b ? k.itemStyle.color : f;
          var c = b ? d.color || f : f,
            n = d.options && d.options.marker,
            m = {
              fill: c
            };
          l && l.css({
            fill: k,
            color: k
          });
          e && e.attr({
            stroke: c
          });
          a && (n && a.isMarker && (m = d.pointAttribs(), b || (m.stroke = m.fill = f)), a.attr(m));
        }
        G(this, "afterColorizeItem", {
          item: d,
          visible: b
        });
      };
      l.prototype.positionItems = function () {
        this.allItems.forEach(this.positionItem, this);
        this.chart.isResizing || this.positionCheckboxes();
      };
      l.prototype.positionItem = function (d) {
        var b = this,
          a = d.legendItem || {},
          f = a.group,
          l = a.x;
        l = void 0 === l ? 0 : l;
        a = a.y;
        a = void 0 === a ? 0 : a;
        var e = this.options,
          c = e.symbolPadding,
          n = !e.rtl;
        e = d.checkbox;
        f && f.element && (c = {
          translateX: n ? l : this.legendWidth - l - 2 * c - 4,
          translateY: a
        }, f[k(f.translateY) ? "animate" : "attr"](c, void 0, function () {
          G(b, "afterPositionItem", {
            item: d
          });
        }));
        e && (e.x = l, e.y = a);
      };
      l.prototype.destroyItem = function (d) {
        for (var b = d.checkbox, a = d.legendItem || {}, f = 0, l = ["group", "label", "line", "symbol"]; f < l.length; f++) {
          var k = l[f];
          a[k] && (a[k] = a[k].destroy());
        }
        b && e(b);
        d.legendItem = void 0;
      };
      l.prototype.destroy = function () {
        for (var d = 0, b = this.getAllItems(); d < b.length; d++) this.destroyItem(b[d]);
        d = 0;
        for (b = "clipRect up down pager nav box title group".split(" "); d < b.length; d++) {
          var a = b[d];
          this[a] && (this[a] = this[a].destroy());
        }
        this.display = null;
      };
      l.prototype.positionCheckboxes = function () {
        var d = this.group && this.group.alignAttr,
          b = this.clipHeight || this.legendHeight,
          a = this.titleHeight;
        if (d) {
          var f = d.translateY;
          this.allItems.forEach(function (h) {
            var l = h.checkbox;
            if (l) {
              var e = f + a + l.y + (this.scrollOffset || 0) + 3;
              p(l, {
                left: d.translateX + h.checkboxOffset + l.x - 20 + "px",
                top: e + "px",
                display: this.proximate || e > f - 6 && e < f + b - 6 ? "" : "none"
              });
            }
          }, this);
        }
      };
      l.prototype.renderTitle = function () {
        var d = this.options,
          b = this.padding,
          a = d.title,
          f = 0;
        a.text && (this.title || (this.title = this.chart.renderer.label(a.text, b - 3, b - 4, void 0, void 0, void 0, d.useHTML, void 0, "legend-title").attr({
          zIndex: 1
        }), this.chart.styledMode || this.title.css(a.style), this.title.add(this.group)), a.width || this.title.css({
          width: this.maxLegendWidth + "px"
        }), d = this.title.getBBox(), f = d.height, this.offsetWidth = d.width, this.contentGroup.attr({
          translateY: f
        }));
        this.titleHeight = f;
      };
      l.prototype.setText = function (d) {
        var b = this.options;
        d.legendItem.label.attr({
          text: b.labelFormat ? t(b.labelFormat, d, this.chart) : b.labelFormatter.call(d)
        });
      };
      l.prototype.renderItem = function (d) {
        var b = d.legendItem = d.legendItem || {},
          a = this.chart,
          f = a.renderer,
          l = this.options,
          e = this.symbolWidth,
          k = l.symbolPadding || 0,
          c = this.itemStyle,
          n = this.itemHiddenStyle,
          m = "horizontal" === l.layout ? u(l.itemDistance, 20) : 0,
          p = !l.rtl,
          q = !d.series,
          w = !q && d.series.drawLegendSymbol ? d.series : d,
          g = w.options,
          r = this.createCheckboxForItem && g && g.showCheckbox,
          t = l.useHTML,
          D = d.options.className,
          G = b.label;
        g = e + k + m + (r ? 20 : 0);
        G || (b.group = f.g("legend-item").addClass("highcharts-" + w.type + "-series highcharts-color-" + d.colorIndex + (D ? " " + D : "") + (q ? " highcharts-series-" + d.index : "")).attr({
          zIndex: 1
        }).add(this.scrollGroup), b.label = G = f.text("", p ? e + k : -k, this.baseline || 0, t), a.styledMode || G.css(K(d.visible ? c : n)), G.attr({
          align: p ? "left" : "right",
          zIndex: 2
        }).add(b.group), this.baseline || (this.fontMetrics = f.fontMetrics(a.styledMode ? 12 : c.fontSize, G), this.baseline = this.fontMetrics.f + 3 + this.itemMarginTop, G.attr("y", this.baseline), this.symbolHeight = l.symbolHeight || this.fontMetrics.f, l.squareSymbol && (this.symbolWidth = u(l.symbolWidth, Math.max(this.symbolHeight, 16)), g = this.symbolWidth + k + m + (r ? 20 : 0), p && G.attr("x", this.symbolWidth + k))), w.drawLegendSymbol(this, d), this.setItemEvents && this.setItemEvents(d, G, t));
        r && !d.checkbox && this.createCheckboxForItem && this.createCheckboxForItem(d);
        this.colorizeItem(d, d.visible);
        !a.styledMode && c.width || G.css({
          width: (l.itemWidth || this.widthOption || a.spacingBox.width) - g + "px"
        });
        this.setText(d);
        a = G.getBBox();
        f = this.fontMetrics && this.fontMetrics.h || 0;
        d.itemWidth = d.checkboxOffset = l.itemWidth || b.labelWidth || a.width + g;
        this.maxItemWidth = Math.max(this.maxItemWidth, d.itemWidth);
        this.totalItemWidth += d.itemWidth;
        this.itemHeight = d.itemHeight = Math.round(b.labelHeight || (a.height > 1.5 * f ? a.height : f));
      };
      l.prototype.layoutItem = function (d) {
        var b = this.options,
          a = this.padding,
          f = "horizontal" === b.layout,
          l = d.itemHeight,
          e = this.itemMarginBottom,
          k = this.itemMarginTop,
          c = f ? u(b.itemDistance, 20) : 0,
          n = this.maxLegendWidth;
        b = b.alignColumns && this.totalItemWidth > n ? this.maxItemWidth : d.itemWidth;
        var m = d.legendItem || {};
        f && this.itemX - a + b > n && (this.itemX = a, this.lastLineHeight && (this.itemY += k + this.lastLineHeight + e), this.lastLineHeight = 0);
        this.lastItemY = k + this.itemY + e;
        this.lastLineHeight = Math.max(l, this.lastLineHeight);
        m.x = this.itemX;
        m.y = this.itemY;
        f ? this.itemX += b : (this.itemY += k + l + e, this.lastLineHeight = l);
        this.offsetWidth = this.widthOption || Math.max((f ? this.itemX - a - (d.checkbox ? 0 : c) : b) + a, this.offsetWidth);
      };
      l.prototype.getAllItems = function () {
        var d = [];
        this.chart.series.forEach(function (b) {
          var a = b && b.options;
          b && u(a.showInLegend, k(a.linkedTo) ? !1 : void 0, !0) && (d = d.concat((b.legendItem || {}).labels || ("point" === a.legendType ? b.data : b)));
        });
        G(this, "afterGetAllItems", {
          allItems: d
        });
        return d;
      };
      l.prototype.getAlignment = function () {
        var d = this.options;
        return this.proximate ? d.align.charAt(0) + "tv" : d.floating ? "" : d.align.charAt(0) + d.verticalAlign.charAt(0) + d.layout.charAt(0);
      };
      l.prototype.adjustMargins = function (d, b) {
        var a = this.chart,
          f = this.options,
          l = this.getAlignment();
        l && [/(lth|ct|rth)/, /(rtv|rm|rbv)/, /(rbh|cb|lbh)/, /(lbv|lm|ltv)/].forEach(function (h, e) {
          h.test(l) && !k(d[e]) && (a[r[e]] = Math.max(a[r[e]], a.legend[(e + 1) % 2 ? "legendHeight" : "legendWidth"] + [1, -1, -1, 1][e] * f[e % 2 ? "x" : "y"] + u(f.margin, 12) + b[e] + (a.titleOffset[e] || 0)));
        });
      };
      l.prototype.proximatePositions = function () {
        var d = this.chart,
          b = [],
          h = "left" === this.options.align;
        this.allItems.forEach(function (a) {
          var f;
          var l = h;
          if (a.yAxis) {
            a.xAxis.options.reversed && (l = !l);
            a.points && (f = D(l ? a.points : a.points.slice(0).reverse(), function (b) {
              return H(b.plotY);
            }));
            l = this.itemMarginTop + a.legendItem.label.getBBox().height + this.itemMarginBottom;
            var e = a.yAxis.top - d.plotTop;
            a.visible ? (f = f ? f.plotY : a.yAxis.height, f += e - .3 * l) : f = e + a.yAxis.height;
            b.push({
              target: f,
              size: l,
              item: a
            });
          }
        }, this);
        for (var f, l = 0, e = a(b, d.plotHeight); l < e.length; l++) {
          var k = e[l];
          f = k.item.legendItem || {};
          H(k.pos) && (f.y = d.plotTop - d.spacing[0] + k.pos);
        }
      };
      l.prototype.render = function () {
        var d = this.chart,
          b = d.renderer,
          a = this.options,
          f = this.padding,
          l = this.getAllItems(),
          e = this.group,
          k = this.box;
        this.itemX = f;
        this.itemY = this.initialItemY;
        this.lastItemY = this.offsetWidth = 0;
        this.widthOption = n(a.width, d.spacingBox.width - f);
        var c = d.spacingBox.width - 2 * f - a.x;
        -1 < ["rm", "lm"].indexOf(this.getAlignment().substring(0, 2)) && (c /= 2);
        this.maxLegendWidth = this.widthOption || c;
        e || (this.group = e = b.g("legend").addClass(a.className || "").attr({
          zIndex: 7
        }).add(), this.contentGroup = b.g().attr({
          zIndex: 1
        }).add(e), this.scrollGroup = b.g().add(this.contentGroup));
        this.renderTitle();
        w(l, function (b, d) {
          return (b.options && b.options.legendIndex || 0) - (d.options && d.options.legendIndex || 0);
        });
        a.reversed && l.reverse();
        this.allItems = l;
        this.display = c = !!l.length;
        this.itemHeight = this.totalItemWidth = this.maxItemWidth = this.lastLineHeight = 0;
        l.forEach(this.renderItem, this);
        l.forEach(this.layoutItem, this);
        l = (this.widthOption || this.offsetWidth) + f;
        var m = this.lastItemY + this.lastLineHeight + this.titleHeight;
        m = this.handleOverflow(m);
        m += f;
        k || (this.box = k = b.rect().addClass("highcharts-legend-box").attr({
          r: a.borderRadius
        }).add(e));
        d.styledMode || k.attr({
          stroke: a.borderColor,
          "stroke-width": a.borderWidth || 0,
          fill: a.backgroundColor || "none"
        }).shadow(a.shadow);
        if (0 < l && 0 < m) k[k.placed ? "animate" : "attr"](k.crisp.call({}, {
          x: 0,
          y: 0,
          width: l,
          height: m
        }, k.strokeWidth()));
        e[c ? "show" : "hide"]();
        d.styledMode && "none" === e.getStyle("display") && (l = m = 0);
        this.legendWidth = l;
        this.legendHeight = m;
        c && this.align();
        this.proximate || this.positionItems();
        G(this, "afterRender");
      };
      l.prototype.align = function (d) {
        void 0 === d && (d = this.chart.spacingBox);
        var b = this.chart,
          a = this.options,
          f = d.y;
        /(lth|ct|rth)/.test(this.getAlignment()) && 0 < b.titleOffset[0] ? f += b.titleOffset[0] : /(lbh|cb|rbh)/.test(this.getAlignment()) && 0 < b.titleOffset[2] && (f -= b.titleOffset[2]);
        f !== d.y && (d = K(d, {
          y: f
        }));
        b.hasRendered || (this.group.placed = !1);
        this.group.align(K(a, {
          width: this.legendWidth,
          height: this.legendHeight,
          verticalAlign: this.proximate ? "top" : a.verticalAlign
        }), !0, d);
      };
      l.prototype.handleOverflow = function (d) {
        var b = this,
          a = this.chart,
          f = a.renderer,
          l = this.options,
          e = l.y,
          k = "top" === l.verticalAlign,
          c = this.padding,
          n = l.maxHeight,
          m = l.navigation,
          p = u(m.animation, !0),
          q = m.arrowSize || 12,
          w = this.pages,
          g = this.allItems,
          r = function (d) {
            "number" === typeof d ? H.attr({
              height: d
            }) : H && (b.clipRect = H.destroy(), b.contentGroup.clip());
            b.contentGroup.div && (b.contentGroup.div.style.clip = d ? "rect(" + c + "px,9999px," + (c + d) + "px,0)" : "auto");
          },
          t = function (d) {
            b[d] = f.circle(0, 0, 1.3 * q).translate(q / 2, q / 2).add(C);
            a.styledMode || b[d].attr("fill", "rgba(0,0,0,0.0001)");
            return b[d];
          },
          D,
          G,
          x;
        e = a.spacingBox.height + (k ? -e : e) - c;
        var C = this.nav,
          H = this.clipRect;
        "horizontal" !== l.layout || "middle" === l.verticalAlign || l.floating || (e /= 2);
        n && (e = Math.min(e, n));
        w.length = 0;
        d && 0 < e && d > e && !1 !== m.enabled ? (this.clipHeight = D = Math.max(e - 20 - this.titleHeight - c, 0), this.currentPage = u(this.currentPage, 1), this.fullHeight = d, g.forEach(function (b, d) {
          x = b.legendItem || {};
          b = x.y || 0;
          var a = Math.round(x.label.getBBox().height),
            h = w.length;
          if (!h || b - w[h - 1] > D && (G || b) !== w[h - 1]) w.push(G || b), h++;
          x.pageIx = h - 1;
          G && ((g[d - 1].legendItem || {}).pageIx = h - 1);
          d === g.length - 1 && b + a - w[h - 1] > D && a <= D && (w.push(b), x.pageIx = h);
          b !== G && (G = b);
        }), H || (H = b.clipRect = f.clipRect(0, c, 9999, 0), b.contentGroup.clip(H)), r(D), C || (this.nav = C = f.g().attr({
          zIndex: 1
        }).add(this.group), this.up = f.symbol("triangle", 0, 0, q, q).add(C), t("upTracker").on("click", function () {
          b.scroll(-1, p);
        }), this.pager = f.text("", 15, 10).addClass("highcharts-legend-navigation"), !a.styledMode && m.style && this.pager.css(m.style), this.pager.add(C), this.down = f.symbol("triangle-down", 0, 0, q, q).add(C), t("downTracker").on("click", function () {
          b.scroll(1, p);
        })), b.scroll(0), d = e) : C && (r(), this.nav = C.destroy(), this.scrollGroup.attr({
          translateY: 1
        }), this.clipHeight = 0);
        return d;
      };
      l.prototype.scroll = function (d, b) {
        var a = this,
          l = this.chart,
          e = this.pages,
          k = e.length,
          c = this.clipHeight,
          n = this.options.navigation,
          m = this.pager,
          p = this.padding,
          q = this.currentPage + d;
        q > k && (q = k);
        0 < q && ("undefined" !== typeof b && x(b, l), this.nav.attr({
          translateX: p,
          translateY: c + this.padding + 7 + this.titleHeight,
          visibility: "inherit"
        }), [this.up, this.upTracker].forEach(function (b) {
          b.attr({
            "class": 1 === q ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
          });
        }), m.attr({
          text: q + "/" + k
        }), [this.down, this.downTracker].forEach(function (b) {
          b.attr({
            x: 18 + this.pager.getBBox().width,
            "class": q === k ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
          });
        }, this), l.styledMode || (this.up.attr({
          fill: 1 === q ? n.inactiveColor : n.activeColor
        }), this.upTracker.css({
          cursor: 1 === q ? "default" : "pointer"
        }), this.down.attr({
          fill: q === k ? n.inactiveColor : n.activeColor
        }), this.downTracker.css({
          cursor: q === k ? "default" : "pointer"
        })), this.scrollOffset = -e[q - 1] + this.initialItemY, this.scrollGroup.animate({
          translateY: this.scrollOffset
        }), this.currentPage = q, this.positionCheckboxes(), d = C(u(b, l.renderer.globalAnimation, !0)), f(function () {
          G(a, "afterScroll", {
            currentPage: q
          });
        }, d.duration));
      };
      l.prototype.setItemEvents = function (d, b, a) {
        var h = this,
          f = d.legendItem || {},
          l = h.chart.renderer.boxWrapper,
          e = d instanceof A,
          k = "highcharts-legend-" + (e ? "point" : "series") + "-active",
          c = h.chart.styledMode,
          n = function (b) {
            h.allItems.forEach(function (a) {
              d !== a && [a].concat(a.linkedSeries || []).forEach(function (d) {
                d.setState(b, !e);
              });
            });
          },
          m = 0;
        for (a = a ? [b, f.symbol] : [f.group]; m < a.length; m++) if (f = a[m]) f.on("mouseover", function () {
          d.visible && n("inactive");
          d.setState("hover");
          d.visible && l.addClass(k);
          c || b.css(h.options.itemHoverStyle);
        }).on("mouseout", function () {
          h.chart.styledMode || b.css(K(d.visible ? h.itemStyle : h.itemHiddenStyle));
          n("");
          l.removeClass(k);
          d.setState();
        }).on("click", function (b) {
          var a = function () {
            d.setVisible && d.setVisible();
            n(d.visible ? "inactive" : "");
          };
          l.removeClass(k);
          b = {
            browserEvent: b
          };
          d.firePointEvent ? d.firePointEvent("legendItemClick", b, a) : G(d, "legendItemClick", b, a);
        });
      };
      l.prototype.createCheckboxForItem = function (d) {
        d.checkbox = m("input", {
          type: "checkbox",
          className: "highcharts-legend-checkbox",
          checked: d.selected,
          defaultChecked: d.selected
        }, this.options.itemCheckboxStyle, this.chart.container);
        q(d.checkbox, "click", function (b) {
          G(d.series || d, "checkboxClick", {
            checked: b.target.checked,
            item: d
          }, function () {
            d.select();
          });
        });
      };
      return l;
    }();
    "";
    return c;
  });
  I(g, "Core/Series/SeriesRegistry.js", [g["Core/Globals.js"], g["Core/Defaults.js"], g["Core/Series/Point.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var B = g.defaultOptions,
      z = A.extendClass,
      C = A.merge,
      x;
    (function (g) {
      function r(a, c) {
        var m = B.plotOptions || {},
          p = c.defaultOptions,
          k = c.prototype;
        k.type = a;
        k.pointClass || (k.pointClass = y);
        p && (m[a] = p);
        g.seriesTypes[a] = c;
      }
      g.seriesTypes = c.seriesTypes;
      g.registerSeriesType = r;
      g.seriesType = function (a, c, m, p, k) {
        var e = B.plotOptions || {};
        c = c || "";
        e[a] = C(e[c], m);
        r(a, z(g.seriesTypes[c] || function () {}, p));
        g.seriesTypes[a].prototype.type = a;
        k && (g.seriesTypes[a].prototype.pointClass = z(y, k));
        return g.seriesTypes[a];
      };
    })(x || (x = {}));
    return x;
  });
  I(g, "Core/Chart/Chart.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Axis/Axis.js"], g["Core/Defaults.js"], g["Core/FormatUtilities.js"], g["Core/Foundation.js"], g["Core/Globals.js"], g["Core/Legend/Legend.js"], g["Core/MSPointer.js"], g["Core/Pointer.js"], g["Core/Renderer/RendererRegistry.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Renderer/SVG/SVGRenderer.js"], g["Core/Time.js"], g["Core/Utilities.js"], g["Core/Renderer/HTML/AST.js"]], function (c, g, y, A, B, z, C, x, t, r, a, q, m, p, k) {
    var e = c.animate,
      D = c.animObject,
      G = c.setAnimation,
      H = y.defaultOptions,
      K = y.defaultTime,
      u = A.numberFormat,
      n = B.registerEventOptions,
      w = z.charts,
      f = z.doc,
      l = z.marginNames,
      d = z.svg,
      b = z.win,
      h = a.seriesTypes,
      v = p.addEvent,
      E = p.attr,
      J = p.cleanRecursively,
      M = p.createElement,
      O = p.css,
      P = p.defined,
      T = p.discardElement,
      F = p.erase,
      Q = p.error,
      aa = p.extend,
      L = p.find,
      R = p.fireEvent,
      N = p.getStyle,
      X = p.isArray,
      ba = p.isNumber,
      da = p.isObject,
      V = p.isString,
      U = p.merge,
      Y = p.objectEach,
      S = p.pick,
      ca = p.pInt,
      Z = p.relativeLength,
      I = p.removeEvent,
      ea = p.splat,
      fa = p.syncTimeout,
      ha = p.uniqueKey;
    c = function () {
      function a(b, d, a) {
        this.series = this.renderTo = this.renderer = this.pointer = this.pointCount = this.plotWidth = this.plotTop = this.plotLeft = this.plotHeight = this.plotBox = this.options = this.numberFormatter = this.margin = this.legend = this.labelCollectors = this.isResizing = this.index = this.eventOptions = this.container = this.colorCounter = this.clipBox = this.chartWidth = this.chartHeight = this.bounds = this.axisOffset = this.axes = void 0;
        this.sharedClips = {};
        this.yAxis = this.xAxis = this.userOptions = this.titleOffset = this.time = this.symbolCounter = this.spacingBox = this.spacing = void 0;
        this.getArgs(b, d, a);
      }
      a.chart = function (b, d, h) {
        return new a(b, d, h);
      };
      a.prototype.getArgs = function (b, d, a) {
        V(b) || b.nodeName ? (this.renderTo = b, this.init(d, a)) : this.init(b, d);
      };
      a.prototype.init = function (b, d) {
        var a = b.plotOptions || {};
        R(this, "init", {
          args: arguments
        }, function () {
          var h = U(H, b),
            f = h.chart;
          Y(h.plotOptions, function (b, d) {
            da(b) && (b.tooltip = a[d] && U(a[d].tooltip) || void 0);
          });
          h.tooltip.userOptions = b.chart && b.chart.forExport && b.tooltip.userOptions || b.tooltip;
          this.userOptions = b;
          this.margin = [];
          this.spacing = [];
          this.bounds = {
            h: {},
            v: {}
          };
          this.labelCollectors = [];
          this.callback = d;
          this.isResizing = 0;
          var l = f.zooming = f.zooming || {};
          b.chart && !b.chart.zooming && (l.resetButton = f.resetZoomButton);
          l.key = S(l.key, f.zoomKey);
          l.pinchType = S(l.pinchType, f.pinchType);
          l.singleTouch = S(l.singleTouch, f.zoomBySingleTouch);
          l.type = S(l.type, f.zoomType);
          this.options = h;
          this.axes = [];
          this.series = [];
          this.time = b.time && Object.keys(b.time).length ? new m(b.time) : z.time;
          this.numberFormatter = f.numberFormatter || u;
          this.styledMode = f.styledMode;
          this.hasCartesianSeries = f.showAxes;
          this.index = w.length;
          w.push(this);
          z.chartCount++;
          n(this, f);
          this.xAxis = [];
          this.yAxis = [];
          this.pointCount = this.colorCounter = this.symbolCounter = 0;
          R(this, "afterInit");
          this.firstRender();
        });
      };
      a.prototype.initSeries = function (b) {
        var d = this.options.chart;
        d = b.type || d.type || d.defaultSeriesType;
        var a = h[d];
        a || Q(17, !0, this, {
          missingModuleFor: d
        });
        d = new a();
        "function" === typeof d.init && d.init(this, b);
        return d;
      };
      a.prototype.setSeriesData = function () {
        this.getSeriesOrderByLinks().forEach(function (b) {
          b.points || b.data || !b.enabledDataSorting || b.setData(b.options.data, !1);
        });
      };
      a.prototype.getSeriesOrderByLinks = function () {
        return this.series.concat().sort(function (b, d) {
          return b.linkedSeries.length || d.linkedSeries.length ? d.linkedSeries.length - b.linkedSeries.length : 0;
        });
      };
      a.prototype.orderSeries = function (b) {
        var d = this.series;
        b = b || 0;
        for (var a = d.length; b < a; ++b) d[b] && (d[b].index = b, d[b].name = d[b].getName());
      };
      a.prototype.isInsidePlot = function (b, d, a) {
        void 0 === a && (a = {});
        var h = this.inverted,
          f = this.plotBox,
          l = this.plotLeft,
          e = this.plotTop,
          k = this.scrollablePlotBox,
          c = 0;
        var n = 0;
        a.visiblePlotOnly && this.scrollingContainer && (n = this.scrollingContainer, c = n.scrollLeft, n = n.scrollTop);
        var v = a.series;
        f = a.visiblePlotOnly && k || f;
        k = a.inverted ? d : b;
        d = a.inverted ? b : d;
        b = {
          x: k,
          y: d,
          isInsidePlot: !0,
          options: a
        };
        if (!a.ignoreX) {
          var m = v && (h && !this.polar ? v.yAxis : v.xAxis) || {
            pos: l,
            len: Infinity
          };
          k = a.paneCoordinates ? m.pos + k : l + k;
          k >= Math.max(c + l, m.pos) && k <= Math.min(c + l + f.width, m.pos + m.len) || (b.isInsidePlot = !1);
        }
        !a.ignoreY && b.isInsidePlot && (h = a.axis && !a.axis.isXAxis && a.axis || v && (h ? v.xAxis : v.yAxis) || {
          pos: e,
          len: Infinity
        }, a = a.paneCoordinates ? h.pos + d : e + d, a >= Math.max(n + e, h.pos) && a <= Math.min(n + e + f.height, h.pos + h.len) || (b.isInsidePlot = !1));
        R(this, "afterIsInsidePlot", b);
        return b.isInsidePlot;
      };
      a.prototype.redraw = function (b) {
        R(this, "beforeRedraw");
        var d = this.hasCartesianSeries ? this.axes : this.colorAxis || [],
          a = this.series,
          h = this.pointer,
          f = this.legend,
          l = this.userOptions.legend,
          e = this.renderer,
          k = e.isHidden(),
          c = [],
          n = this.isDirtyBox,
          v = this.isDirtyLegend;
        this.setResponsive && this.setResponsive(!1);
        G(this.hasRendered ? b : !1, this);
        k && this.temporaryDisplay();
        this.layOutTitles();
        for (b = a.length; b--;) {
          var m = a[b];
          if (m.options.stacking || m.options.centerInCategory) {
            var p = !0;
            if (m.isDirty) {
              var q = !0;
              break;
            }
          }
        }
        if (q) for (b = a.length; b--;) m = a[b], m.options.stacking && (m.isDirty = !0);
        a.forEach(function (b) {
          b.isDirty && ("point" === b.options.legendType ? ("function" === typeof b.updateTotals && b.updateTotals(), v = !0) : l && (l.labelFormatter || l.labelFormat) && (v = !0));
          b.isDirtyData && R(b, "updatedData");
        });
        v && f && f.options.enabled && (f.render(), this.isDirtyLegend = !1);
        p && this.getStacks();
        d.forEach(function (b) {
          b.updateNames();
          b.setScale();
        });
        this.getMargins();
        d.forEach(function (b) {
          b.isDirty && (n = !0);
        });
        d.forEach(function (b) {
          var d = b.min + "," + b.max;
          b.extKey !== d && (b.extKey = d, c.push(function () {
            R(b, "afterSetExtremes", aa(b.eventArgs, b.getExtremes()));
            delete b.eventArgs;
          }));
          (n || p) && b.redraw();
        });
        n && this.drawChartBox();
        R(this, "predraw");
        a.forEach(function (b) {
          (n || b.isDirty) && b.visible && b.redraw();
          b.isDirtyData = !1;
        });
        h && h.reset(!0);
        e.draw();
        R(this, "redraw");
        R(this, "render");
        k && this.temporaryDisplay(!0);
        c.forEach(function (b) {
          b.call();
        });
      };
      a.prototype.get = function (b) {
        function d(d) {
          return d.id === b || d.options && d.options.id === b;
        }
        for (var a = this.series, h = L(this.axes, d) || L(this.series, d), f = 0; !h && f < a.length; f++) h = L(a[f].points || [], d);
        return h;
      };
      a.prototype.getAxes = function () {
        var b = this,
          d = this.options,
          a = d.xAxis = ea(d.xAxis || {});
        d = d.yAxis = ea(d.yAxis || {});
        R(this, "getAxes");
        a.forEach(function (b, d) {
          b.index = d;
          b.isX = !0;
        });
        d.forEach(function (b, d) {
          b.index = d;
        });
        a.concat(d).forEach(function (d) {
          new g(b, d);
        });
        R(this, "afterGetAxes");
      };
      a.prototype.getSelectedPoints = function () {
        return this.series.reduce(function (b, d) {
          d.getPointsCollection().forEach(function (d) {
            S(d.selectedStaging, d.selected) && b.push(d);
          });
          return b;
        }, []);
      };
      a.prototype.getSelectedSeries = function () {
        return this.series.filter(function (b) {
          return b.selected;
        });
      };
      a.prototype.setTitle = function (b, d, a) {
        this.applyDescription("title", b);
        this.applyDescription("subtitle", d);
        this.applyDescription("caption", void 0);
        this.layOutTitles(a);
      };
      a.prototype.applyDescription = function (b, d) {
        var a = this,
          h = "title" === b ? {
            color: "#333333",
            fontSize: this.options.isStock ? "16px" : "18px"
          } : {
            color: "#666666"
          };
        h = this.options[b] = U(!this.styledMode && {
          style: h
        }, this.options[b], d);
        var f = this[b];
        f && d && (this[b] = f = f.destroy());
        h && !f && (f = this.renderer.text(h.text, 0, 0, h.useHTML).attr({
          align: h.align,
          "class": "highcharts-" + b,
          zIndex: h.zIndex || 4
        }).add(), f.update = function (d) {
          a[{
            title: "setTitle",
            subtitle: "setSubtitle",
            caption: "setCaption"
          }[b]](d);
        }, this.styledMode || f.css(h.style), this[b] = f);
      };
      a.prototype.layOutTitles = function (b) {
        var d = [0, 0, 0],
          a = this.renderer,
          h = this.spacingBox;
        ["title", "subtitle", "caption"].forEach(function (b) {
          var f = this[b],
            l = this.options[b],
            e = l.verticalAlign || "top";
          b = "title" === b ? "top" === e ? -3 : 0 : "top" === e ? d[0] + 2 : 0;
          var k;
          if (f) {
            this.styledMode || (k = l.style && l.style.fontSize);
            k = a.fontMetrics(k, f).b;
            f.css({
              width: (l.width || h.width + (l.widthAdjust || 0)) + "px"
            });
            var c = Math.round(f.getBBox(l.useHTML).height);
            f.align(aa({
              y: "bottom" === e ? k : b + k,
              height: c
            }, l), !1, "spacingBox");
            l.floating || ("top" === e ? d[0] = Math.ceil(d[0] + c) : "bottom" === e && (d[2] = Math.ceil(d[2] + c)));
          }
        }, this);
        d[0] && "top" === (this.options.title.verticalAlign || "top") && (d[0] += this.options.title.margin);
        d[2] && "bottom" === this.options.caption.verticalAlign && (d[2] += this.options.caption.margin);
        var f = !this.titleOffset || this.titleOffset.join(",") !== d.join(",");
        this.titleOffset = d;
        R(this, "afterLayOutTitles");
        !this.isDirtyBox && f && (this.isDirtyBox = this.isDirtyLegend = f, this.hasRendered && S(b, !0) && this.isDirtyBox && this.redraw());
      };
      a.prototype.getChartSize = function () {
        var b = this.options.chart,
          d = b.width;
        b = b.height;
        var a = this.renderTo;
        P(d) || (this.containerWidth = N(a, "width"));
        P(b) || (this.containerHeight = N(a, "height"));
        this.chartWidth = Math.max(0, d || this.containerWidth || 600);
        this.chartHeight = Math.max(0, Z(b, this.chartWidth) || (1 < this.containerHeight ? this.containerHeight : 400));
      };
      a.prototype.temporaryDisplay = function (b) {
        var d = this.renderTo;
        if (b) for (; d && d.style;) d.hcOrigStyle && (O(d, d.hcOrigStyle), delete d.hcOrigStyle), d.hcOrigDetached && (f.body.removeChild(d), d.hcOrigDetached = !1), d = d.parentNode;else for (; d && d.style;) {
          f.body.contains(d) || d.parentNode || (d.hcOrigDetached = !0, f.body.appendChild(d));
          if ("none" === N(d, "display", !1) || d.hcOricDetached) d.hcOrigStyle = {
            display: d.style.display,
            height: d.style.height,
            overflow: d.style.overflow
          }, b = {
            display: "block",
            overflow: "hidden"
          }, d !== this.renderTo && (b.height = 0), O(d, b), d.offsetWidth || d.style.setProperty("display", "block", "important");
          d = d.parentNode;
          if (d === f.body) break;
        }
      };
      a.prototype.setClassName = function (b) {
        this.container.className = "highcharts-container " + (b || "");
      };
      a.prototype.getContainer = function () {
        var b = this.options,
          a = b.chart,
          h = ha(),
          l,
          e = this.renderTo;
        e || (this.renderTo = e = a.renderTo);
        V(e) && (this.renderTo = e = f.getElementById(e));
        e || Q(13, !0, this);
        var c = ca(E(e, "data-highcharts-chart"));
        ba(c) && w[c] && w[c].hasRendered && w[c].destroy();
        E(e, "data-highcharts-chart", this.index);
        e.innerHTML = k.emptyHTML;
        a.skipClone || e.offsetWidth || this.temporaryDisplay();
        this.getChartSize();
        c = this.chartWidth;
        var n = this.chartHeight;
        O(e, {
          overflow: "hidden"
        });
        this.styledMode || (l = aa({
          position: "relative",
          overflow: "hidden",
          width: c + "px",
          height: n + "px",
          textAlign: "left",
          lineHeight: "normal",
          zIndex: 0,
          "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
          userSelect: "none",
          "touch-action": "manipulation",
          outline: "none"
        }, a.style || {}));
        this.container = h = M("div", {
          id: h
        }, l, e);
        this._cursor = h.style.cursor;
        this.renderer = new (a.renderer || !d ? r.getRendererType(a.renderer) : q)(h, c, n, void 0, a.forExport, b.exporting && b.exporting.allowHTML, this.styledMode);
        G(void 0, this);
        this.setClassName(a.className);
        if (this.styledMode) for (var v in b.defs) this.renderer.definition(b.defs[v]);else this.renderer.setStyle(a.style);
        this.renderer.chartIndex = this.index;
        R(this, "afterGetContainer");
      };
      a.prototype.getMargins = function (b) {
        var d = this.spacing,
          a = this.margin,
          h = this.titleOffset;
        this.resetMargins();
        h[0] && !P(a[0]) && (this.plotTop = Math.max(this.plotTop, h[0] + d[0]));
        h[2] && !P(a[2]) && (this.marginBottom = Math.max(this.marginBottom, h[2] + d[2]));
        this.legend && this.legend.display && this.legend.adjustMargins(a, d);
        R(this, "getMargins");
        b || this.getAxisMargins();
      };
      a.prototype.getAxisMargins = function () {
        var b = this,
          d = b.axisOffset = [0, 0, 0, 0],
          a = b.colorAxis,
          h = b.margin,
          f = function (b) {
            b.forEach(function (b) {
              b.visible && b.getOffset();
            });
          };
        b.hasCartesianSeries ? f(b.axes) : a && a.length && f(a);
        l.forEach(function (a, f) {
          P(h[f]) || (b[a] += d[f]);
        });
        b.setChartSize();
      };
      a.prototype.reflow = function (d) {
        var a = this,
          h = a.options.chart,
          l = a.renderTo,
          e = P(h.width) && P(h.height),
          k = h.width || N(l, "width");
        h = h.height || N(l, "height");
        l = d ? d.target : b;
        delete a.pointer.chartPosition;
        if (!e && !a.isPrinting && k && h && (l === b || l === f)) {
          if (k !== a.containerWidth || h !== a.containerHeight) p.clearTimeout(a.reflowTimeout), a.reflowTimeout = fa(function () {
            a.container && a.setSize(void 0, void 0, !1);
          }, d ? 100 : 0);
          a.containerWidth = k;
          a.containerHeight = h;
        }
      };
      a.prototype.setReflow = function (d) {
        var a = this;
        !1 === d || this.unbindReflow ? !1 === d && this.unbindReflow && (this.unbindReflow = this.unbindReflow()) : (this.unbindReflow = v(b, "resize", function (b) {
          a.options && a.reflow(b);
        }), v(this, "destroy", this.unbindReflow));
      };
      a.prototype.setSize = function (b, d, a) {
        var h = this,
          f = h.renderer;
        h.isResizing += 1;
        G(a, h);
        a = f.globalAnimation;
        h.oldChartHeight = h.chartHeight;
        h.oldChartWidth = h.chartWidth;
        "undefined" !== typeof b && (h.options.chart.width = b);
        "undefined" !== typeof d && (h.options.chart.height = d);
        h.getChartSize();
        h.styledMode || (a ? e : O)(h.container, {
          width: h.chartWidth + "px",
          height: h.chartHeight + "px"
        }, a);
        h.setChartSize(!0);
        f.setSize(h.chartWidth, h.chartHeight, a);
        h.axes.forEach(function (b) {
          b.isDirty = !0;
          b.setScale();
        });
        h.isDirtyLegend = !0;
        h.isDirtyBox = !0;
        h.layOutTitles();
        h.getMargins();
        h.redraw(a);
        h.oldChartHeight = null;
        R(h, "resize");
        fa(function () {
          h && R(h, "endResize", null, function () {
            --h.isResizing;
          });
        }, D(a).duration);
      };
      a.prototype.setChartSize = function (b) {
        var d = this.inverted,
          a = this.renderer,
          h = this.chartWidth,
          f = this.chartHeight,
          l = this.options.chart,
          e = this.spacing,
          k = this.clipOffset,
          c,
          n,
          v,
          m;
        this.plotLeft = c = Math.round(this.plotLeft);
        this.plotTop = n = Math.round(this.plotTop);
        this.plotWidth = v = Math.max(0, Math.round(h - c - this.marginRight));
        this.plotHeight = m = Math.max(0, Math.round(f - n - this.marginBottom));
        this.plotSizeX = d ? m : v;
        this.plotSizeY = d ? v : m;
        this.plotBorderWidth = l.plotBorderWidth || 0;
        this.spacingBox = a.spacingBox = {
          x: e[3],
          y: e[0],
          width: h - e[3] - e[1],
          height: f - e[0] - e[2]
        };
        this.plotBox = a.plotBox = {
          x: c,
          y: n,
          width: v,
          height: m
        };
        d = 2 * Math.floor(this.plotBorderWidth / 2);
        h = Math.ceil(Math.max(d, k[3]) / 2);
        f = Math.ceil(Math.max(d, k[0]) / 2);
        this.clipBox = {
          x: h,
          y: f,
          width: Math.floor(this.plotSizeX - Math.max(d, k[1]) / 2 - h),
          height: Math.max(0, Math.floor(this.plotSizeY - Math.max(d, k[2]) / 2 - f))
        };
        b || (this.axes.forEach(function (b) {
          b.setAxisSize();
          b.setAxisTranslation();
        }), a.alignElements());
        R(this, "afterSetChartSize", {
          skipAxes: b
        });
      };
      a.prototype.resetMargins = function () {
        R(this, "resetMargins");
        var b = this,
          d = b.options.chart;
        ["margin", "spacing"].forEach(function (a) {
          var h = d[a],
            f = da(h) ? h : [h, h, h, h];
          ["Top", "Right", "Bottom", "Left"].forEach(function (h, l) {
            b[a][l] = S(d[a + h], f[l]);
          });
        });
        l.forEach(function (d, a) {
          b[d] = S(b.margin[a], b.spacing[a]);
        });
        b.axisOffset = [0, 0, 0, 0];
        b.clipOffset = [0, 0, 0, 0];
      };
      a.prototype.drawChartBox = function () {
        var b = this.options.chart,
          d = this.renderer,
          a = this.chartWidth,
          h = this.chartHeight,
          f = this.styledMode,
          l = this.plotBGImage,
          e = b.backgroundColor,
          k = b.plotBackgroundColor,
          c = b.plotBackgroundImage,
          n = this.plotLeft,
          v = this.plotTop,
          m = this.plotWidth,
          p = this.plotHeight,
          q = this.plotBox,
          u = this.clipRect,
          g = this.clipBox,
          w = this.chartBackground,
          E = this.plotBackground,
          r = this.plotBorder,
          t,
          F = "animate";
        w || (this.chartBackground = w = d.rect().addClass("highcharts-background").add(), F = "attr");
        if (f) var N = t = w.strokeWidth();else {
          N = b.borderWidth || 0;
          t = N + (b.shadow ? 8 : 0);
          e = {
            fill: e || "none"
          };
          if (N || w["stroke-width"]) e.stroke = b.borderColor, e["stroke-width"] = N;
          w.attr(e).shadow(b.shadow);
        }
        w[F]({
          x: t / 2,
          y: t / 2,
          width: a - t - N % 2,
          height: h - t - N % 2,
          r: b.borderRadius
        });
        F = "animate";
        E || (F = "attr", this.plotBackground = E = d.rect().addClass("highcharts-plot-background").add());
        E[F](q);
        f || (E.attr({
          fill: k || "none"
        }).shadow(b.plotShadow), c && (l ? (c !== l.attr("href") && l.attr("href", c), l.animate(q)) : this.plotBGImage = d.image(c, n, v, m, p).add()));
        u ? u.animate({
          width: g.width,
          height: g.height
        }) : this.clipRect = d.clipRect(g);
        F = "animate";
        r || (F = "attr", this.plotBorder = r = d.rect().addClass("highcharts-plot-border").attr({
          zIndex: 1
        }).add());
        f || r.attr({
          stroke: b.plotBorderColor,
          "stroke-width": b.plotBorderWidth || 0,
          fill: "none"
        });
        r[F](r.crisp({
          x: n,
          y: v,
          width: m,
          height: p
        }, -r.strokeWidth()));
        this.isDirtyBox = !1;
        R(this, "afterDrawChartBox");
      };
      a.prototype.propFromSeries = function () {
        var b = this,
          d = b.options.chart,
          a = b.options.series,
          f,
          l,
          e;
        ["inverted", "angular", "polar"].forEach(function (k) {
          l = h[d.type || d.defaultSeriesType];
          e = d[k] || l && l.prototype[k];
          for (f = a && a.length; !e && f--;) (l = h[a[f].type]) && l.prototype[k] && (e = !0);
          b[k] = e;
        });
      };
      a.prototype.linkSeries = function () {
        var b = this,
          d = b.series;
        d.forEach(function (b) {
          b.linkedSeries.length = 0;
        });
        d.forEach(function (d) {
          var a = d.options.linkedTo;
          V(a) && (a = ":previous" === a ? b.series[d.index - 1] : b.get(a)) && a.linkedParent !== d && (a.linkedSeries.push(d), d.linkedParent = a, a.enabledDataSorting && d.setDataSortingOptions(), d.visible = S(d.options.visible, a.options.visible, d.visible));
        });
        R(this, "afterLinkSeries");
      };
      a.prototype.renderSeries = function () {
        this.series.forEach(function (b) {
          b.translate();
          b.render();
        });
      };
      a.prototype.renderLabels = function () {
        var b = this,
          d = b.options.labels;
        d.items && d.items.forEach(function (a) {
          var h = aa(d.style, a.style),
            f = ca(h.left) + b.plotLeft,
            l = ca(h.top) + b.plotTop + 12;
          delete h.left;
          delete h.top;
          b.renderer.text(a.html, f, l).attr({
            zIndex: 2
          }).css(h).add();
        });
      };
      a.prototype.render = function () {
        var b = this.axes,
          d = this.colorAxis,
          a = this.renderer,
          h = this.options,
          f = function (b) {
            b.forEach(function (b) {
              b.visible && b.render();
            });
          },
          l = 0;
        this.setTitle();
        this.legend = new C(this, h.legend);
        this.getStacks && this.getStacks();
        this.getMargins(!0);
        this.setChartSize();
        h = this.plotWidth;
        b.some(function (b) {
          if (b.horiz && b.visible && b.options.labels.enabled && b.series.length) return l = 21, !0;
        });
        var e = this.plotHeight = Math.max(this.plotHeight - l, 0);
        b.forEach(function (b) {
          b.setScale();
        });
        this.getAxisMargins();
        var k = 1.1 < h / this.plotWidth,
          c = 1.05 < e / this.plotHeight;
        if (k || c) b.forEach(function (b) {
          (b.horiz && k || !b.horiz && c) && b.setTickInterval(!0);
        }), this.getMargins();
        this.drawChartBox();
        this.hasCartesianSeries ? f(b) : d && d.length && f(d);
        this.seriesGroup || (this.seriesGroup = a.g("series-group").attr({
          zIndex: 3
        }).add());
        this.renderSeries();
        this.renderLabels();
        this.addCredits();
        this.setResponsive && this.setResponsive();
        this.hasRendered = !0;
      };
      a.prototype.addCredits = function (d) {
        var a = this,
          h = U(!0, this.options.credits, d);
        h.enabled && !this.credits && (this.credits = this.renderer.text(h.text + (this.mapCredits || ""), 0, 0).addClass("highcharts-credits").on("click", function () {
          h.href && (b.location.href = h.href);
        }).attr({
          align: h.position.align,
          zIndex: 8
        }), a.styledMode || this.credits.css(h.style), this.credits.add().align(h.position), this.credits.update = function (b) {
          a.credits = a.credits.destroy();
          a.addCredits(b);
        });
      };
      a.prototype.destroy = function () {
        var b = this,
          d = b.axes,
          a = b.series,
          h = b.container,
          f = h && h.parentNode,
          l;
        R(b, "destroy");
        b.renderer.forExport ? F(w, b) : w[b.index] = void 0;
        z.chartCount--;
        b.renderTo.removeAttribute("data-highcharts-chart");
        I(b);
        for (l = d.length; l--;) d[l] = d[l].destroy();
        this.scroller && this.scroller.destroy && this.scroller.destroy();
        for (l = a.length; l--;) a[l] = a[l].destroy();
        "title subtitle chartBackground plotBackground plotBGImage plotBorder seriesGroup clipRect credits pointer rangeSelector legend resetZoomButton tooltip renderer".split(" ").forEach(function (d) {
          var a = b[d];
          a && a.destroy && (b[d] = a.destroy());
        });
        h && (h.innerHTML = k.emptyHTML, I(h), f && T(h));
        Y(b, function (d, a) {
          delete b[a];
        });
      };
      a.prototype.firstRender = function () {
        var b = this,
          d = b.options;
        if (!b.isReadyToRender || b.isReadyToRender()) {
          b.getContainer();
          b.resetMargins();
          b.setChartSize();
          b.propFromSeries();
          b.getAxes();
          (X(d.series) ? d.series : []).forEach(function (d) {
            b.initSeries(d);
          });
          b.linkSeries();
          b.setSeriesData();
          R(b, "beforeRender");
          t && (x.isRequired() ? b.pointer = new x(b, d) : b.pointer = new t(b, d));
          b.render();
          b.pointer.getChartPosition();
          if (!b.renderer.imgCount && !b.hasLoaded) b.onload();
          b.temporaryDisplay(!0);
        }
      };
      a.prototype.onload = function () {
        this.callbacks.concat([this.callback]).forEach(function (b) {
          b && "undefined" !== typeof this.index && b.apply(this, [this]);
        }, this);
        R(this, "load");
        R(this, "render");
        P(this.index) && this.setReflow(this.options.chart.reflow);
        this.warnIfA11yModuleNotLoaded();
        this.hasLoaded = !0;
      };
      a.prototype.warnIfA11yModuleNotLoaded = function () {
        var b = this.options,
          d = this.title;
        b && !this.accessibility && (this.renderer.boxWrapper.attr({
          role: "img",
          "aria-label": (d && d.element.textContent || "").replace(/</g, "&lt;")
        }), b.accessibility && !1 === b.accessibility.enabled || Q('Highcharts warning: Consider including the "accessibility.js" module to make your chart more usable for people with disabilities. Set the "accessibility.enabled" option to false to remove this warning. See https://www.highcharts.com/docs/accessibility/accessibility-module.', !1, this));
      };
      a.prototype.addSeries = function (b, d, a) {
        var h = this,
          f;
        b && (d = S(d, !0), R(h, "addSeries", {
          options: b
        }, function () {
          f = h.initSeries(b);
          h.isDirtyLegend = !0;
          h.linkSeries();
          f.enabledDataSorting && f.setData(b.data, !1);
          R(h, "afterAddSeries", {
            series: f
          });
          d && h.redraw(a);
        }));
        return f;
      };
      a.prototype.addAxis = function (b, d, a, h) {
        return this.createAxis(d ? "xAxis" : "yAxis", {
          axis: b,
          redraw: a,
          animation: h
        });
      };
      a.prototype.addColorAxis = function (b, d, a) {
        return this.createAxis("colorAxis", {
          axis: b,
          redraw: d,
          animation: a
        });
      };
      a.prototype.createAxis = function (b, d) {
        b = new g(this, U(d.axis, {
          index: this[b].length,
          isX: "xAxis" === b
        }));
        S(d.redraw, !0) && this.redraw(d.animation);
        return b;
      };
      a.prototype.showLoading = function (b) {
        var d = this,
          a = d.options,
          h = a.loading,
          f = function () {
            l && O(l, {
              left: d.plotLeft + "px",
              top: d.plotTop + "px",
              width: d.plotWidth + "px",
              height: d.plotHeight + "px"
            });
          },
          l = d.loadingDiv,
          c = d.loadingSpan;
        l || (d.loadingDiv = l = M("div", {
          className: "highcharts-loading highcharts-loading-hidden"
        }, null, d.container));
        c || (d.loadingSpan = c = M("span", {
          className: "highcharts-loading-inner"
        }, null, l), v(d, "redraw", f));
        l.className = "highcharts-loading";
        k.setElementHTML(c, S(b, a.lang.loading, ""));
        d.styledMode || (O(l, aa(h.style, {
          zIndex: 10
        })), O(c, h.labelStyle), d.loadingShown || (O(l, {
          opacity: 0,
          display: ""
        }), e(l, {
          opacity: h.style.opacity || .5
        }, {
          duration: h.showDuration || 0
        })));
        d.loadingShown = !0;
        f();
      };
      a.prototype.hideLoading = function () {
        var b = this.options,
          d = this.loadingDiv;
        d && (d.className = "highcharts-loading highcharts-loading-hidden", this.styledMode || e(d, {
          opacity: 0
        }, {
          duration: b.loading.hideDuration || 100,
          complete: function () {
            O(d, {
              display: "none"
            });
          }
        }));
        this.loadingShown = !1;
      };
      a.prototype.update = function (b, d, a, h) {
        var f = this,
          l = {
            credits: "addCredits",
            title: "setTitle",
            subtitle: "setSubtitle",
            caption: "setCaption"
          },
          e = b.isResponsiveOptions,
          k = [],
          c,
          v;
        R(f, "update", {
          options: b
        });
        e || f.setResponsive(!1, !0);
        b = J(b, f.options);
        f.userOptions = U(f.userOptions, b);
        var p = b.chart;
        if (p) {
          U(!0, f.options.chart, p);
          "className" in p && f.setClassName(p.className);
          "reflow" in p && f.setReflow(p.reflow);
          if ("inverted" in p || "polar" in p || "type" in p) {
            f.propFromSeries();
            var q = !0;
          }
          "alignTicks" in p && (q = !0);
          "events" in p && n(this, p);
          Y(p, function (b, d) {
            -1 !== f.propsRequireUpdateSeries.indexOf("chart." + d) && (c = !0);
            -1 !== f.propsRequireDirtyBox.indexOf(d) && (f.isDirtyBox = !0);
            -1 !== f.propsRequireReflow.indexOf(d) && (e ? f.isDirtyBox = !0 : v = !0);
          });
          !f.styledMode && p.style && f.renderer.setStyle(f.options.chart.style || {});
        }
        !f.styledMode && b.colors && (this.options.colors = b.colors);
        b.time && (this.time === K && (this.time = new m(b.time)), U(!0, f.options.time, b.time));
        Y(b, function (d, a) {
          if (f[a] && "function" === typeof f[a].update) f[a].update(d, !1);else if ("function" === typeof f[l[a]]) f[l[a]](d);else "colors" !== a && -1 === f.collectionsWithUpdate.indexOf(a) && U(!0, f.options[a], b[a]);
          "chart" !== a && -1 !== f.propsRequireUpdateSeries.indexOf(a) && (c = !0);
        });
        this.collectionsWithUpdate.forEach(function (d) {
          if (b[d]) {
            var h = [];
            f[d].forEach(function (b, d) {
              b.options.isInternal || h.push(S(b.options.index, d));
            });
            ea(b[d]).forEach(function (b, l) {
              var e = P(b.id),
                k;
              e && (k = f.get(b.id));
              !k && f[d] && (k = f[d][h ? h[l] : l]) && e && P(k.options.id) && (k = void 0);
              k && k.coll === d && (k.update(b, !1), a && (k.touched = !0));
              !k && a && f.collectionsWithInit[d] && (f.collectionsWithInit[d][0].apply(f, [b].concat(f.collectionsWithInit[d][1] || []).concat([!1])).touched = !0);
            });
            a && f[d].forEach(function (b) {
              b.touched || b.options.isInternal ? delete b.touched : k.push(b);
            });
          }
        });
        k.forEach(function (b) {
          b.chart && b.remove && b.remove(!1);
        });
        q && f.axes.forEach(function (b) {
          b.update({}, !1);
        });
        c && f.getSeriesOrderByLinks().forEach(function (b) {
          b.chart && b.update({}, !1);
        }, this);
        q = p && p.width;
        p = p && (V(p.height) ? Z(p.height, q || f.chartWidth) : p.height);
        v || ba(q) && q !== f.chartWidth || ba(p) && p !== f.chartHeight ? f.setSize(q, p, h) : S(d, !0) && f.redraw(h);
        R(f, "afterUpdate", {
          options: b,
          redraw: d,
          animation: h
        });
      };
      a.prototype.setSubtitle = function (b, d) {
        this.applyDescription("subtitle", b);
        this.layOutTitles(d);
      };
      a.prototype.setCaption = function (b, d) {
        this.applyDescription("caption", b);
        this.layOutTitles(d);
      };
      a.prototype.showResetZoom = function () {
        function b() {
          d.zoomOut();
        }
        var d = this,
          a = H.lang,
          h = d.options.chart.zooming.resetButton,
          f = h.theme,
          l = "chart" === h.relativeTo || "spacingBox" === h.relativeTo ? null : "scrollablePlotBox";
        R(this, "beforeShowResetZoom", null, function () {
          d.resetZoomButton = d.renderer.button(a.resetZoom, null, null, b, f).attr({
            align: h.position.align,
            title: a.resetZoomTitle
          }).addClass("highcharts-reset-zoom").add().align(h.position, !1, l);
        });
        R(this, "afterShowResetZoom");
      };
      a.prototype.zoomOut = function () {
        R(this, "selection", {
          resetSelection: !0
        }, this.zoom);
      };
      a.prototype.zoom = function (b) {
        var d = this,
          a = d.pointer,
          h = !1,
          f;
        !b || b.resetSelection ? (d.axes.forEach(function (b) {
          f = b.zoom();
        }), a.initiated = !1) : b.xAxis.concat(b.yAxis).forEach(function (b) {
          var l = b.axis;
          if (a[l.isXAxis ? "zoomX" : "zoomY"] && P(a.mouseDownX) && P(a.mouseDownY) && d.isInsidePlot(a.mouseDownX - d.plotLeft, a.mouseDownY - d.plotTop, {
            axis: l
          }) || !P(d.inverted ? a.mouseDownX : a.mouseDownY)) f = l.zoom(b.min, b.max), l.displayBtn && (h = !0);
        });
        var l = d.resetZoomButton;
        h && !l ? d.showResetZoom() : !h && da(l) && (d.resetZoomButton = l.destroy());
        f && d.redraw(S(d.options.chart.animation, b && b.animation, 100 > d.pointCount));
      };
      a.prototype.pan = function (b, d) {
        var a = this,
          h = a.hoverPoints;
        d = "object" === typeof d ? d : {
          enabled: d,
          type: "x"
        };
        var f = a.options.chart;
        f && f.panning && (f.panning = d);
        var l = d.type,
          e;
        R(this, "pan", {
          originalEvent: b
        }, function () {
          h && h.forEach(function (b) {
            b.setState();
          });
          var d = a.xAxis;
          "xy" === l ? d = d.concat(a.yAxis) : "y" === l && (d = a.yAxis);
          var f = {};
          d.forEach(function (d) {
            if (d.options.panningEnabled && !d.options.isInternal) {
              var h = d.horiz,
                k = b[h ? "chartX" : "chartY"];
              h = h ? "mouseDownX" : "mouseDownY";
              var c = a[h],
                n = d.minPointOffset || 0,
                v = d.reversed && !a.inverted || !d.reversed && a.inverted ? -1 : 1,
                m = d.getExtremes(),
                p = d.toValue(c - k, !0) + n * v,
                q = d.toValue(c + d.len - k, !0) - (n * v || d.isXAxis && d.pointRangePadding || 0),
                u = q < p;
              v = d.hasVerticalPanning();
              c = u ? q : p;
              p = u ? p : q;
              var w = d.panningState;
              !v || d.isXAxis || w && !w.isDirty || d.series.forEach(function (b) {
                var d = b.getProcessedData(!0);
                d = b.getExtremes(d.yData, !0);
                w || (w = {
                  startMin: Number.MAX_VALUE,
                  startMax: -Number.MAX_VALUE
                });
                ba(d.dataMin) && ba(d.dataMax) && (w.startMin = Math.min(S(b.options.threshold, Infinity), d.dataMin, w.startMin), w.startMax = Math.max(S(b.options.threshold, -Infinity), d.dataMax, w.startMax));
              });
              v = Math.min(S(w && w.startMin, m.dataMin), n ? m.min : d.toValue(d.toPixels(m.min) - d.minPixelPadding));
              q = Math.max(S(w && w.startMax, m.dataMax), n ? m.max : d.toValue(d.toPixels(m.max) + d.minPixelPadding));
              d.panningState = w;
              d.isOrdinal || (n = v - c, 0 < n && (p += n, c = v), n = p - q, 0 < n && (p = q, c -= n), d.series.length && c !== m.min && p !== m.max && c >= v && p <= q && (d.setExtremes(c, p, !1, !1, {
                trigger: "pan"
              }), !a.resetZoomButton && c !== v && p !== q && l.match("y") && (a.showResetZoom(), d.displayBtn = !1), e = !0), f[h] = k);
            }
          });
          Y(f, function (b, d) {
            a[d] = b;
          });
          e && a.redraw(!1);
          O(a.container, {
            cursor: "move"
          });
        });
      };
      return a;
    }();
    aa(c.prototype, {
      callbacks: [],
      collectionsWithInit: {
        xAxis: [c.prototype.addAxis, [!0]],
        yAxis: [c.prototype.addAxis, [!1]],
        series: [c.prototype.addSeries]
      },
      collectionsWithUpdate: ["xAxis", "yAxis", "series"],
      propsRequireDirtyBox: "backgroundColor borderColor borderWidth borderRadius plotBackgroundColor plotBackgroundImage plotBorderColor plotBorderWidth plotShadow shadow".split(" "),
      propsRequireReflow: "margin marginTop marginRight marginBottom marginLeft spacing spacingTop spacingRight spacingBottom spacingLeft".split(" "),
      propsRequireUpdateSeries: "chart.inverted chart.polar chart.ignoreHiddenSeries chart.type colors plotOptions time tooltip".split(" ")
    });
    "";
    return c;
  });
  I(g, "Core/Legend/LegendSymbol.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.extend,
      y = c.merge,
      A = c.pick,
      B;
    (function (c) {
      c.drawLineMarker = function (c) {
        var x = this.legendItem = this.legendItem || {},
          t = this.options,
          r = c.symbolWidth,
          a = c.symbolHeight,
          q = a / 2,
          m = this.chart.renderer,
          p = x.group;
        c = c.baseline - Math.round(.3 * c.fontMetrics.b);
        var k = {},
          e = t.marker,
          D = 0;
        this.chart.styledMode || (k = {
          "stroke-width": Math.min(t.lineWidth || 0, 24)
        }, t.dashStyle ? k.dashstyle = t.dashStyle : "square" !== t.linecap && (k["stroke-linecap"] = "round"));
        x.line = m.path().addClass("highcharts-graph").attr(k).add(p);
        k["stroke-linecap"] && (D = Math.min(x.line.strokeWidth(), r) / 2);
        x.line.attr({
          d: [["M", D, c], ["L", r - D, c]]
        });
        e && !1 !== e.enabled && r && (t = Math.min(A(e.radius, q), q), 0 === this.symbol.indexOf("url") && (e = y(e, {
          width: a,
          height: a
        }), t = 0), x.symbol = x = m.symbol(this.symbol, r / 2 - t, c - t, 2 * t, 2 * t, g({
          context: "legend"
        }, e)).addClass("highcharts-point").add(p), x.isMarker = !0);
      };
      c.drawRectangle = function (c, g) {
        g = g.legendItem || {};
        var t = c.symbolHeight,
          r = c.options.squareSymbol;
        g.symbol = this.chart.renderer.rect(r ? (c.symbolWidth - t) / 2 : 0, c.baseline - t + 1, r ? t : c.symbolWidth, t, A(c.options.symbolRadius, t / 2)).addClass("highcharts-point").attr({
          zIndex: 3
        }).add(g.group);
      };
    })(B || (B = {}));
    return B;
  });
  I(g, "Core/Series/SeriesDefaults.js", [], function () {
    return {
      lineWidth: 2,
      allowPointSelect: !1,
      crisp: !0,
      showCheckbox: !1,
      animation: {
        duration: 1E3
      },
      events: {},
      marker: {
        enabledThreshold: 2,
        lineColor: "#ffffff",
        lineWidth: 0,
        radius: 4,
        states: {
          normal: {
            animation: !0
          },
          hover: {
            animation: {
              duration: 50
            },
            enabled: !0,
            radiusPlus: 2,
            lineWidthPlus: 1
          },
          select: {
            fillColor: "#cccccc",
            lineColor: "#000000",
            lineWidth: 2
          }
        }
      },
      point: {
        events: {}
      },
      dataLabels: {
        animation: {},
        align: "center",
        borderWidth: 0,
        defer: !0,
        formatter: function () {
          var c = this.series.chart.numberFormatter;
          return "number" !== typeof this.y ? "" : c(this.y, -1);
        },
        padding: 5,
        style: {
          fontSize: "11px",
          fontWeight: "bold",
          color: "contrast",
          textOutline: "1px contrast"
        },
        verticalAlign: "bottom",
        x: 0,
        y: 0
      },
      cropThreshold: 300,
      opacity: 1,
      pointRange: 0,
      softThreshold: !0,
      states: {
        normal: {
          animation: !0
        },
        hover: {
          animation: {
            duration: 50
          },
          lineWidthPlus: 1,
          marker: {},
          halo: {
            size: 10,
            opacity: .25
          }
        },
        select: {
          animation: {
            duration: 0
          }
        },
        inactive: {
          animation: {
            duration: 50
          },
          opacity: .2
        }
      },
      stickyTracking: !0,
      turboThreshold: 1E3,
      findNearestPointBy: "x"
    };
  });
  I(g, "Core/Series/Series.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Defaults.js"], g["Core/Foundation.js"], g["Core/Globals.js"], g["Core/Legend/LegendSymbol.js"], g["Core/Series/Point.js"], g["Core/Series/SeriesDefaults.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C, x, t, r) {
    var a = c.animObject,
      q = c.setAnimation,
      m = g.defaultOptions,
      p = y.registerEventOptions,
      k = A.hasTouch,
      e = A.svg,
      D = A.win,
      G = x.seriesTypes,
      H = r.arrayMax,
      K = r.arrayMin,
      u = r.clamp,
      n = r.cleanRecursively,
      w = r.correctFloat,
      f = r.defined,
      l = r.erase,
      d = r.error,
      b = r.extend,
      h = r.find,
      v = r.fireEvent,
      E = r.getNestedProperty,
      J = r.isArray,
      M = r.isNumber,
      O = r.isString,
      P = r.merge,
      T = r.objectEach,
      F = r.pick,
      Q = r.removeEvent,
      aa = r.splat,
      L = r.syncTimeout;
    c = function () {
      function c() {
        this.zones = this.yAxis = this.xAxis = this.userOptions = this.tooltipOptions = this.processedYData = this.processedXData = this.points = this.options = this.linkedSeries = this.index = this.eventsToUnbind = this.eventOptions = this.data = this.chart = this._i = void 0;
      }
      c.prototype.init = function (d, a) {
        v(this, "init", {
          options: a
        });
        var h = this,
          f = d.series;
        this.eventsToUnbind = [];
        h.chart = d;
        h.options = h.setOptions(a);
        a = h.options;
        h.linkedSeries = [];
        h.bindAxes();
        b(h, {
          name: a.name,
          state: "",
          visible: !1 !== a.visible,
          selected: !0 === a.selected
        });
        p(this, a);
        var l = a.events;
        if (l && l.click || a.point && a.point.events && a.point.events.click || a.allowPointSelect) d.runTrackerClick = !0;
        h.getColor();
        h.getSymbol();
        h.parallelArrays.forEach(function (b) {
          h[b + "Data"] || (h[b + "Data"] = []);
        });
        h.isCartesian && (d.hasCartesianSeries = !0);
        var e;
        f.length && (e = f[f.length - 1]);
        h._i = F(e && e._i, -1) + 1;
        h.opacity = h.options.opacity;
        d.orderSeries(this.insert(f));
        a.dataSorting && a.dataSorting.enabled ? h.setDataSortingOptions() : h.points || h.data || h.setData(a.data, !1);
        v(this, "afterInit");
      };
      c.prototype.is = function (b) {
        return G[b] && this instanceof G[b];
      };
      c.prototype.insert = function (b) {
        var d = this.options.index,
          a;
        if (M(d)) {
          for (a = b.length; a--;) if (d >= F(b[a].options.index, b[a]._i)) {
            b.splice(a + 1, 0, this);
            break;
          }
          -1 === a && b.unshift(this);
          a += 1;
        } else b.push(this);
        return F(a, b.length - 1);
      };
      c.prototype.bindAxes = function () {
        var b = this,
          a = b.options,
          h = b.chart,
          f;
        v(this, "bindAxes", null, function () {
          (b.axisTypes || []).forEach(function (l) {
            var e = 0;
            h[l].forEach(function (d) {
              f = d.options;
              if (a[l] === e && !f.isInternal || "undefined" !== typeof a[l] && a[l] === f.id || "undefined" === typeof a[l] && 0 === f.index) b.insert(d.series), b[l] = d, d.isDirty = !0;
              f.isInternal || e++;
            });
            b[l] || b.optionalAxis === l || d(18, !0, h);
          });
        });
        v(this, "afterBindAxes");
      };
      c.prototype.updateParallelArrays = function (b, d) {
        var a = b.series,
          h = arguments,
          f = M(d) ? function (h) {
            var f = "y" === h && a.toYData ? a.toYData(b) : b[h];
            a[h + "Data"][d] = f;
          } : function (b) {
            Array.prototype[d].apply(a[b + "Data"], Array.prototype.slice.call(h, 2));
          };
        a.parallelArrays.forEach(f);
      };
      c.prototype.hasData = function () {
        return this.visible && "undefined" !== typeof this.dataMax && "undefined" !== typeof this.dataMin || this.visible && this.yData && 0 < this.yData.length;
      };
      c.prototype.autoIncrement = function (b) {
        var d = this.options,
          a = d.pointIntervalUnit,
          h = d.relativeXValue,
          f = this.chart.time,
          l = this.xIncrement,
          e;
        l = F(l, d.pointStart, 0);
        this.pointInterval = e = F(this.pointInterval, d.pointInterval, 1);
        h && M(b) && (e *= b);
        a && (d = new f.Date(l), "day" === a ? f.set("Date", d, f.get("Date", d) + e) : "month" === a ? f.set("Month", d, f.get("Month", d) + e) : "year" === a && f.set("FullYear", d, f.get("FullYear", d) + e), e = d.getTime() - l);
        if (h && M(b)) return l + e;
        this.xIncrement = l + e;
        return l;
      };
      c.prototype.setDataSortingOptions = function () {
        var d = this.options;
        b(this, {
          requireSorting: !1,
          sorted: !1,
          enabledDataSorting: !0,
          allowDG: !1
        });
        f(d.pointRange) || (d.pointRange = 1);
      };
      c.prototype.setOptions = function (b) {
        var d = this.chart,
          a = d.options,
          h = a.plotOptions,
          l = d.userOptions || {};
        b = P(b);
        d = d.styledMode;
        var e = {
          plotOptions: h,
          userOptions: b
        };
        v(this, "setOptions", e);
        var c = e.plotOptions[this.type],
          k = l.plotOptions || {};
        this.userOptions = e.userOptions;
        l = P(c, h.series, l.plotOptions && l.plotOptions[this.type], b);
        this.tooltipOptions = P(m.tooltip, m.plotOptions.series && m.plotOptions.series.tooltip, m.plotOptions[this.type].tooltip, a.tooltip.userOptions, h.series && h.series.tooltip, h[this.type].tooltip, b.tooltip);
        this.stickyTracking = F(b.stickyTracking, k[this.type] && k[this.type].stickyTracking, k.series && k.series.stickyTracking, this.tooltipOptions.shared && !this.noSharedTooltip ? !0 : l.stickyTracking);
        null === c.marker && delete l.marker;
        this.zoneAxis = l.zoneAxis;
        h = this.zones = (l.zones || []).slice();
        !l.negativeColor && !l.negativeFillColor || l.zones || (a = {
          value: l[this.zoneAxis + "Threshold"] || l.threshold || 0,
          className: "highcharts-negative"
        }, d || (a.color = l.negativeColor, a.fillColor = l.negativeFillColor), h.push(a));
        h.length && f(h[h.length - 1].value) && h.push(d ? {} : {
          color: this.color,
          fillColor: this.fillColor
        });
        v(this, "afterSetOptions", {
          options: l
        });
        return l;
      };
      c.prototype.getName = function () {
        return F(this.options.name, "Series " + (this.index + 1));
      };
      c.prototype.getCyclic = function (b, d, a) {
        var h = this.chart,
          l = this.userOptions,
          e = b + "Index",
          c = b + "Counter",
          k = a ? a.length : F(h.options.chart[b + "Count"], h[b + "Count"]);
        if (!d) {
          var n = F(l[e], l["_" + e]);
          f(n) || (h.series.length || (h[c] = 0), l["_" + e] = n = h[c] % k, h[c] += 1);
          a && (d = a[n]);
        }
        "undefined" !== typeof n && (this[e] = n);
        this[b] = d;
      };
      c.prototype.getColor = function () {
        this.chart.styledMode ? this.getCyclic("color") : this.options.colorByPoint ? this.color = "#cccccc" : this.getCyclic("color", this.options.color || m.plotOptions[this.type].color, this.chart.options.colors);
      };
      c.prototype.getPointsCollection = function () {
        return (this.hasGroupedData ? this.points : this.data) || [];
      };
      c.prototype.getSymbol = function () {
        this.getCyclic("symbol", this.options.marker.symbol, this.chart.options.symbols);
      };
      c.prototype.findPointIndex = function (b, d) {
        var a = b.id,
          f = b.x,
          l = this.points,
          e = this.options.dataSorting,
          c,
          k;
        if (a) e = this.chart.get(a), e instanceof z && (c = e);else if (this.linkedParent || this.enabledDataSorting || this.options.relativeXValue) if (c = function (d) {
          return !d.touched && d.index === b.index;
        }, e && e.matchByName ? c = function (d) {
          return !d.touched && d.name === b.name;
        } : this.options.relativeXValue && (c = function (d) {
          return !d.touched && d.options.x === b.x;
        }), c = h(l, c), !c) return;
        if (c) {
          var n = c && c.index;
          "undefined" !== typeof n && (k = !0);
        }
        "undefined" === typeof n && M(f) && (n = this.xData.indexOf(f, d));
        -1 !== n && "undefined" !== typeof n && this.cropped && (n = n >= this.cropStart ? n - this.cropStart : n);
        !k && M(n) && l[n] && l[n].touched && (n = void 0);
        return n;
      };
      c.prototype.updateData = function (b, d) {
        var a = this.options,
          h = a.dataSorting,
          l = this.points,
          e = [],
          c = this.requireSorting,
          k = b.length === l.length,
          n,
          v,
          m,
          p = !0;
        this.xIncrement = null;
        b.forEach(function (b, d) {
          var v = f(b) && this.pointClass.prototype.optionsToObject.call({
              series: this
            }, b) || {},
            p = v.x;
          if (v.id || M(p)) {
            if (v = this.findPointIndex(v, m), -1 === v || "undefined" === typeof v ? e.push(b) : l[v] && b !== a.data[v] ? (l[v].update(b, !1, null, !1), l[v].touched = !0, c && (m = v + 1)) : l[v] && (l[v].touched = !0), !k || d !== v || h && h.enabled || this.hasDerivedData) n = !0;
          } else e.push(b);
        }, this);
        if (n) for (b = l.length; b--;) (v = l[b]) && !v.touched && v.remove && v.remove(!1, d);else !k || h && h.enabled ? p = !1 : (b.forEach(function (b, d) {
          b !== l[d].y && l[d].update && l[d].update(b, !1, null, !1);
        }), e.length = 0);
        l.forEach(function (b) {
          b && (b.touched = !1);
        });
        if (!p) return !1;
        e.forEach(function (b) {
          this.addPoint(b, !1, null, null, !1);
        }, this);
        null === this.xIncrement && this.xData && this.xData.length && (this.xIncrement = H(this.xData), this.autoIncrement());
        return !0;
      };
      c.prototype.setData = function (b, a, h, f) {
        void 0 === a && (a = !0);
        var l = this,
          e = l.points,
          c = e && e.length || 0,
          k = l.options,
          n = l.chart,
          v = k.dataSorting,
          m = l.xAxis,
          p = k.turboThreshold,
          q = this.xData,
          u = this.yData,
          w = l.pointArrayMap;
        w = w && w.length;
        var g = k.keys,
          E,
          r = 0,
          t = 1,
          F = null;
        if (!n.options.chart.allowMutatingData) {
          k.data && delete l.options.data;
          l.userOptions.data && delete l.userOptions.data;
          var D = P(!0, b);
        }
        b = D || b || [];
        D = b.length;
        v && v.enabled && (b = this.sortData(b));
        n.options.chart.allowMutatingData && !1 !== f && D && c && !l.cropped && !l.hasGroupedData && l.visible && !l.boosted && (E = this.updateData(b, h));
        if (!E) {
          l.xIncrement = null;
          l.colorCounter = 0;
          this.parallelArrays.forEach(function (b) {
            l[b + "Data"].length = 0;
          });
          if (p && D > p) {
            if (F = l.getFirstValidPoint(b), M(F)) for (h = 0; h < D; h++) q[h] = this.autoIncrement(), u[h] = b[h];else if (J(F)) {
              if (w) {
                if (F.length === w) for (h = 0; h < D; h++) q[h] = this.autoIncrement(), u[h] = b[h];else for (h = 0; h < D; h++) f = b[h], q[h] = f[0], u[h] = f.slice(1, w + 1);
              } else if (g && (r = g.indexOf("x"), t = g.indexOf("y"), r = 0 <= r ? r : 0, t = 0 <= t ? t : 1), 1 === F.length && (t = 0), r === t) for (h = 0; h < D; h++) q[h] = this.autoIncrement(), u[h] = b[h][t];else for (h = 0; h < D; h++) f = b[h], q[h] = f[r], u[h] = f[t];
            } else d(12, !1, n);
          } else for (h = 0; h < D; h++) "undefined" !== typeof b[h] && (f = {
            series: l
          }, l.pointClass.prototype.applyOptions.apply(f, [b[h]]), l.updateParallelArrays(f, h));
          u && O(u[0]) && d(14, !0, n);
          l.data = [];
          l.options.data = l.userOptions.data = b;
          for (h = c; h--;) e[h] && e[h].destroy && e[h].destroy();
          m && (m.minRange = m.userMinRange);
          l.isDirty = n.isDirtyBox = !0;
          l.isDirtyData = !!e;
          h = !1;
        }
        "point" === k.legendType && (this.processData(), this.generatePoints());
        a && n.redraw(h);
      };
      c.prototype.sortData = function (b) {
        var d = this,
          a = d.options.dataSorting.sortKey || "y",
          h = function (b, d) {
            return f(d) && b.pointClass.prototype.optionsToObject.call({
              series: b
            }, d) || {};
          };
        b.forEach(function (a, f) {
          b[f] = h(d, a);
          b[f].index = f;
        }, this);
        b.concat().sort(function (b, d) {
          b = E(a, b);
          d = E(a, d);
          return d < b ? -1 : d > b ? 1 : 0;
        }).forEach(function (b, d) {
          b.x = d;
        }, this);
        d.linkedSeries && d.linkedSeries.forEach(function (d) {
          var a = d.options,
            f = a.data;
          a.dataSorting && a.dataSorting.enabled || !f || (f.forEach(function (a, l) {
            f[l] = h(d, a);
            b[l] && (f[l].x = b[l].x, f[l].index = l);
          }), d.setData(f, !1));
        });
        return b;
      };
      c.prototype.getProcessedData = function (b) {
        var a = this.xAxis,
          h = this.options,
          f = h.cropThreshold,
          l = b || this.getExtremesFromAll || h.getExtremesFromAll,
          e = this.isCartesian;
        b = a && a.val2lin;
        h = !(!a || !a.logarithmic);
        var c = 0,
          k = this.xData,
          n = this.yData,
          v = this.requireSorting;
        var m = !1;
        var p = k.length;
        if (a) {
          m = a.getExtremes();
          var q = m.min;
          var u = m.max;
          m = !(!a.categories || a.names.length);
        }
        if (e && this.sorted && !l && (!f || p > f || this.forceCrop)) if (k[p - 1] < q || k[0] > u) k = [], n = [];else if (this.yData && (k[0] < q || k[p - 1] > u)) {
          var w = this.cropData(this.xData, this.yData, q, u);
          k = w.xData;
          n = w.yData;
          c = w.start;
          w = !0;
        }
        for (f = k.length || 1; --f;) if (a = h ? b(k[f]) - b(k[f - 1]) : k[f] - k[f - 1], 0 < a && ("undefined" === typeof g || a < g)) var g = a;else 0 > a && v && !m && (d(15, !1, this.chart), v = !1);
        return {
          xData: k,
          yData: n,
          cropped: w,
          cropStart: c,
          closestPointRange: g
        };
      };
      c.prototype.processData = function (b) {
        var d = this.xAxis;
        if (this.isCartesian && !this.isDirty && !d.isDirty && !this.yAxis.isDirty && !b) return !1;
        b = this.getProcessedData();
        this.cropped = b.cropped;
        this.cropStart = b.cropStart;
        this.processedXData = b.xData;
        this.processedYData = b.yData;
        this.closestPointRange = this.basePointRange = b.closestPointRange;
        v(this, "afterProcessData");
      };
      c.prototype.cropData = function (b, d, a, h, f) {
        var l = b.length,
          e,
          c = 0,
          k = l;
        f = F(f, this.cropShoulder);
        for (e = 0; e < l; e++) if (b[e] >= a) {
          c = Math.max(0, e - f);
          break;
        }
        for (a = e; a < l; a++) if (b[a] > h) {
          k = a + f;
          break;
        }
        return {
          xData: b.slice(c, k),
          yData: d.slice(c, k),
          start: c,
          end: k
        };
      };
      c.prototype.generatePoints = function () {
        var d = this.options,
          a = this.processedData || d.data,
          h = this.processedXData,
          f = this.processedYData,
          l = this.pointClass,
          e = h.length,
          c = this.cropStart || 0,
          k = this.hasGroupedData,
          n = d.keys,
          m = [];
        d = d.dataGrouping && d.dataGrouping.groupAll ? c : 0;
        var p,
          q,
          u = this.data;
        if (!u && !k) {
          var w = [];
          w.length = a.length;
          u = this.data = w;
        }
        n && k && (this.options.keys = !1);
        for (q = 0; q < e; q++) {
          w = c + q;
          if (k) {
            var g = new l().init(this, [h[q]].concat(aa(f[q])));
            g.dataGroup = this.groupMap[d + q];
            g.dataGroup.options && (g.options = g.dataGroup.options, b(g, g.dataGroup.options), delete g.dataLabels);
          } else (g = u[w]) || "undefined" === typeof a[w] || (u[w] = g = new l().init(this, a[w], h[q]));
          g && (g.index = k ? d + q : w, m[q] = g);
        }
        this.options.keys = n;
        if (u && (e !== (p = u.length) || k)) for (q = 0; q < p; q++) q !== c || k || (q += e), u[q] && (u[q].destroyElements(), u[q].plotX = void 0);
        this.data = u;
        this.points = m;
        v(this, "afterGeneratePoints");
      };
      c.prototype.getXExtremes = function (b) {
        return {
          min: K(b),
          max: H(b)
        };
      };
      c.prototype.getExtremes = function (b, d) {
        var a = this.xAxis,
          h = this.yAxis,
          f = this.processedXData || this.xData,
          l = [],
          e = this.requireSorting ? this.cropShoulder : 0;
        h = h ? h.positiveValuesOnly : !1;
        var c,
          k = 0,
          n = 0,
          m = 0;
        b = b || this.stackedYData || this.processedYData || [];
        var p = b.length;
        if (a) {
          var q = a.getExtremes();
          k = q.min;
          n = q.max;
        }
        for (c = 0; c < p; c++) {
          var u = f[c];
          q = b[c];
          var w = (M(q) || J(q)) && (q.length || 0 < q || !h);
          u = d || this.getExtremesFromAll || this.options.getExtremesFromAll || this.cropped || !a || (f[c + e] || u) >= k && (f[c - e] || u) <= n;
          if (w && u) if (w = q.length) for (; w--;) M(q[w]) && (l[m++] = q[w]);else l[m++] = q;
        }
        b = {
          activeYData: l,
          dataMin: K(l),
          dataMax: H(l)
        };
        v(this, "afterGetExtremes", {
          dataExtremes: b
        });
        return b;
      };
      c.prototype.applyExtremes = function () {
        var b = this.getExtremes();
        this.dataMin = b.dataMin;
        this.dataMax = b.dataMax;
        return b;
      };
      c.prototype.getFirstValidPoint = function (b) {
        for (var d = b.length, a = 0, h = null; null === h && a < d;) h = b[a], a++;
        return h;
      };
      c.prototype.translate = function () {
        this.processedXData || this.processData();
        this.generatePoints();
        var b = this.options,
          d = b.stacking,
          a = this.xAxis,
          h = a.categories,
          l = this.enabledDataSorting,
          e = this.yAxis,
          c = this.points,
          k = c.length,
          n = this.pointPlacementToXValue(),
          m = !!n,
          p = b.threshold,
          q = b.startFromThreshold ? p : 0,
          g = this.zoneAxis || "y",
          r,
          E,
          t = Number.MAX_VALUE;
        for (r = 0; r < k; r++) {
          var D = c[r],
            G = D.x,
            x = void 0,
            Q = void 0,
            O = D.y,
            H = D.low,
            C = d && e.stacking && e.stacking.stacks[(this.negStacks && O < (q ? 0 : p) ? "-" : "") + this.stackKey];
          if (e.positiveValuesOnly && !e.validatePositiveValue(O) || a.positiveValuesOnly && !a.validatePositiveValue(G)) D.isNull = !0;
          D.plotX = E = w(u(a.translate(G, 0, 0, 0, 1, n, "flags" === this.type), -1E5, 1E5));
          if (d && this.visible && C && C[G]) {
            var P = this.getStackIndicator(P, G, this.index);
            !D.isNull && P.key && (x = C[G], Q = x.points[P.key]);
            x && J(Q) && (H = Q[0], O = Q[1], H === q && P.key === C[G].base && (H = F(M(p) ? p : e.min)), e.positiveValuesOnly && f(H) && 0 >= H && (H = void 0), D.total = D.stackTotal = F(x.total), D.percentage = f(D.y) && x.total ? D.y / x.total * 100 : void 0, D.stackY = O, this.irregularWidths || x.setOffset(this.pointXOffset || 0, this.barW || 0, void 0, void 0, void 0, this.xAxis));
          }
          D.yBottom = f(H) ? u(e.translate(H, 0, 1, 0, 1), -1E5, 1E5) : void 0;
          this.dataModify && (O = this.dataModify.modifyValue(O, r));
          D.plotY = void 0;
          M(O) && (x = e.translate(O, !1, !0, !1, !0), "undefined" !== typeof x && (D.plotY = u(x, -1E5, 1E5)));
          D.isInside = this.isPointInside(D);
          D.clientX = m ? w(a.translate(G, 0, 0, 0, 1, n)) : E;
          D.negative = D[g] < (b[g + "Threshold"] || p || 0);
          D.category = F(h && h[D.x], D.x);
          if (!D.isNull && !1 !== D.visible) {
            "undefined" !== typeof z && (t = Math.min(t, Math.abs(E - z)));
            var z = E;
          }
          D.zone = this.zones.length ? D.getZone() : void 0;
          !D.graphic && this.group && l && (D.isNew = !0);
        }
        this.closestPointRangePx = t;
        v(this, "afterTranslate");
      };
      c.prototype.getValidPoints = function (b, d, a) {
        var h = this.chart;
        return (b || this.points || []).filter(function (b) {
          return d && !h.isInsidePlot(b.plotX, b.plotY, {
            inverted: h.inverted
          }) ? !1 : !1 !== b.visible && (a || !b.isNull);
        });
      };
      c.prototype.getClipBox = function () {
        var b = this.chart,
          d = this.xAxis,
          a = this.yAxis,
          h = P(b.clipBox);
        d && d.len !== b.plotSizeX && (h.width = d.len);
        a && a.len !== b.plotSizeY && (h.height = a.len);
        return h;
      };
      c.prototype.getSharedClipKey = function () {
        return this.sharedClipKey = (this.options.xAxis || 0) + "," + (this.options.yAxis || 0);
      };
      c.prototype.setClip = function () {
        var b = this.chart,
          d = this.group,
          a = this.markerGroup,
          h = b.sharedClips;
        b = b.renderer;
        var f = this.getClipBox(),
          l = this.getSharedClipKey(),
          e = h[l];
        e ? e.animate(f) : h[l] = e = b.clipRect(f);
        d && d.clip(!1 === this.options.clip ? void 0 : e);
        a && a.clip();
      };
      c.prototype.animate = function (b) {
        var d = this.chart,
          h = this.group,
          f = this.markerGroup,
          l = d.inverted,
          e = a(this.options.animation),
          c = [this.getSharedClipKey(), e.duration, e.easing, e.defer].join(),
          k = d.sharedClips[c],
          n = d.sharedClips[c + "m"];
        if (b && h) e = this.getClipBox(), k ? k.attr("height", e.height) : (e.width = 0, l && (e.x = d.plotHeight), k = d.renderer.clipRect(e), d.sharedClips[c] = k, n = d.renderer.clipRect({
          x: -99,
          y: -99,
          width: l ? d.plotWidth + 199 : 99,
          height: l ? 99 : d.plotHeight + 199
        }), d.sharedClips[c + "m"] = n), h.clip(k), f && f.clip(n);else if (k && !k.hasClass("highcharts-animating")) {
          d = this.getClipBox();
          var v = e.step;
          f && f.element.childNodes.length && (e.step = function (b, d) {
            v && v.apply(d, arguments);
            "width" === d.prop && n && n.element && n.attr(l ? "height" : "width", b + 99);
          });
          k.addClass("highcharts-animating").animate(d, e);
        }
      };
      c.prototype.afterAnimate = function () {
        var b = this;
        this.setClip();
        T(this.chart.sharedClips, function (d, a, h) {
          d && !b.chart.container.querySelector('[clip-path="url(#'.concat(d.id, ')"]')) && (d.destroy(), delete h[a]);
        });
        this.finishedAnimating = !0;
        v(this, "afterAnimate");
      };
      c.prototype.drawPoints = function (b) {
        void 0 === b && (b = this.points);
        var d = this.chart,
          a = d.styledMode,
          h = this.colorAxis,
          f = this.options.marker,
          l = this[this.specialGroup || "markerGroup"],
          e = this.xAxis,
          c = F(f.enabled, !e || e.isRadial ? !0 : null, this.closestPointRangePx >= f.enabledThreshold * f.radius),
          k,
          n;
        if (!1 !== f.enabled || this._hasPointMarkers) for (k = 0; k < b.length; k++) {
          var v = b[k];
          var m = (n = v.graphic) ? "animate" : "attr";
          var p = v.marker || {};
          var q = !!v.marker;
          if ((c && "undefined" === typeof p.enabled || p.enabled) && !v.isNull && !1 !== v.visible) {
            var u = F(p.symbol, this.symbol, "rect");
            var w = this.markerAttribs(v, v.selected && "select");
            this.enabledDataSorting && (v.startXPos = e.reversed ? -(w.width || 0) : e.width);
            var g = !1 !== v.isInside;
            !n && g && (0 < (w.width || 0) || v.hasImage) && (v.graphic = n = d.renderer.symbol(u, w.x, w.y, w.width, w.height, q ? p : f).add(l), this.enabledDataSorting && d.hasRendered && (n.attr({
              x: v.startXPos
            }), m = "animate"));
            n && "animate" === m && n[g ? "show" : "hide"](g).animate(w);
            if (n) if (p = this.pointAttribs(v, a || !v.selected ? void 0 : "select"), a) h && n.css({
              fill: p.fill
            });else n[m](p);
            n && n.addClass(v.getClassName(), !0);
          } else n && (v.graphic = n.destroy());
        }
      };
      c.prototype.markerAttribs = function (b, d) {
        var a = this.options,
          h = a.marker,
          f = b.marker || {},
          l = f.symbol || h.symbol,
          e = {},
          c = F(f.radius, h && h.radius);
        d && (h = h.states[d], d = f.states && f.states[d], c = F(d && d.radius, h && h.radius, c && c + (h && h.radiusPlus || 0)));
        b.hasImage = l && 0 === l.indexOf("url");
        b.hasImage && (c = 0);
        b = b.pos();
        M(c) && b && (e.x = b[0] - c, e.y = b[1] - c, a.crisp && (e.x = Math.floor(e.x)));
        c && (e.width = e.height = 2 * c);
        return e;
      };
      c.prototype.pointAttribs = function (b, d) {
        var a = this.options.marker,
          h = b && b.options,
          f = h && h.marker || {},
          l = h && h.color,
          e = b && b.color,
          c = b && b.zone && b.zone.color,
          k = this.color;
        b = F(f.lineWidth, a.lineWidth);
        h = 1;
        k = l || c || e || k;
        l = f.fillColor || a.fillColor || k;
        e = f.lineColor || a.lineColor || k;
        d = d || "normal";
        a = a.states[d] || {};
        d = f.states && f.states[d] || {};
        b = F(d.lineWidth, a.lineWidth, b + F(d.lineWidthPlus, a.lineWidthPlus, 0));
        l = d.fillColor || a.fillColor || l;
        e = d.lineColor || a.lineColor || e;
        h = F(d.opacity, a.opacity, h);
        return {
          stroke: e,
          "stroke-width": b,
          fill: l,
          opacity: h
        };
      };
      c.prototype.destroy = function (b) {
        var d = this,
          a = d.chart,
          h = /AppleWebKit\/533/.test(D.navigator.userAgent),
          f = d.data || [],
          e,
          c,
          k,
          n;
        v(d, "destroy", {
          keepEventsForUpdate: b
        });
        this.removeEvents(b);
        (d.axisTypes || []).forEach(function (b) {
          (n = d[b]) && n.series && (l(n.series, d), n.isDirty = n.forceRedraw = !0);
        });
        d.legendItem && d.chart.legend.destroyItem(d);
        for (c = f.length; c--;) (k = f[c]) && k.destroy && k.destroy();
        d.clips && d.clips.forEach(function (b) {
          return b.destroy();
        });
        r.clearTimeout(d.animationTimeout);
        T(d, function (b, d) {
          b instanceof t && !b.survive && (e = h && "group" === d ? "hide" : "destroy", b[e]());
        });
        a.hoverSeries === d && (a.hoverSeries = void 0);
        l(a.series, d);
        a.orderSeries();
        T(d, function (a, h) {
          b && "hcEvents" === h || delete d[h];
        });
      };
      c.prototype.applyZones = function () {
        var b = this,
          d = this.chart,
          a = d.renderer,
          h = this.zones,
          f = this.clips || [],
          l = this.graph,
          e = this.area,
          c = Math.max(d.plotWidth, d.plotHeight),
          k = this[(this.zoneAxis || "y") + "Axis"],
          n = d.inverted,
          v,
          m,
          p,
          q,
          w,
          g,
          r,
          E,
          t = !1;
        if (h.length && (l || e) && k && "undefined" !== typeof k.min) {
          var D = k.reversed;
          var J = k.horiz;
          l && !this.showLine && l.hide();
          e && e.hide();
          var G = k.getExtremes();
          h.forEach(function (h, M) {
            v = D ? J ? d.plotWidth : 0 : J ? 0 : k.toPixels(G.min) || 0;
            v = u(F(m, v), 0, c);
            m = u(Math.round(k.toPixels(F(h.value, G.max), !0) || 0), 0, c);
            t && (v = m = k.toPixels(G.max));
            q = Math.abs(v - m);
            w = Math.min(v, m);
            g = Math.max(v, m);
            k.isXAxis ? (p = {
              x: n ? g : w,
              y: 0,
              width: q,
              height: c
            }, J || (p.x = d.plotHeight - p.x)) : (p = {
              x: 0,
              y: n ? g : w,
              width: c,
              height: q
            }, J && (p.y = d.plotWidth - p.y));
            n && a.isVML && (p = k.isXAxis ? {
              x: 0,
              y: D ? w : g,
              height: p.width,
              width: d.chartWidth
            } : {
              x: p.y - d.plotLeft - d.spacingBox.x,
              y: 0,
              width: p.height,
              height: d.chartHeight
            });
            f[M] ? f[M].animate(p) : f[M] = a.clipRect(p);
            r = b["zone-area-" + M];
            E = b["zone-graph-" + M];
            l && E && E.clip(f[M]);
            e && r && r.clip(f[M]);
            t = h.value > G.max;
            b.resetZones && 0 === m && (m = void 0);
          });
          this.clips = f;
        } else b.visible && (l && l.show(), e && e.show());
      };
      c.prototype.plotGroup = function (b, d, a, h, l) {
        var e = this[b],
          c = !e;
        a = {
          visibility: a,
          zIndex: h || .1
        };
        "undefined" === typeof this.opacity || this.chart.styledMode || "inactive" === this.state || (a.opacity = this.opacity);
        c && (this[b] = e = this.chart.renderer.g().add(l));
        e.addClass("highcharts-" + d + " highcharts-series-" + this.index + " highcharts-" + this.type + "-series " + (f(this.colorIndex) ? "highcharts-color-" + this.colorIndex + " " : "") + (this.options.className || "") + (e.hasClass("highcharts-tracker") ? " highcharts-tracker" : ""), !0);
        e.attr(a)[c ? "attr" : "animate"](this.getPlotBox(d));
        return e;
      };
      c.prototype.getPlotBox = function (b) {
        var d = this.xAxis,
          a = this.yAxis,
          h = this.chart;
        b = h.inverted && !h.polar && d && !1 !== this.invertible && "series" === b;
        h.inverted && (d = a, a = this.xAxis);
        return {
          translateX: d ? d.left : h.plotLeft,
          translateY: a ? a.top : h.plotTop,
          rotation: b ? 90 : 0,
          rotationOriginX: b ? (d.len - a.len) / 2 : 0,
          rotationOriginY: b ? (d.len + a.len) / 2 : 0,
          scaleX: b ? -1 : 1,
          scaleY: 1
        };
      };
      c.prototype.removeEvents = function (b) {
        b || Q(this);
        this.eventsToUnbind.length && (this.eventsToUnbind.forEach(function (b) {
          b();
        }), this.eventsToUnbind.length = 0);
      };
      c.prototype.render = function () {
        var b = this,
          d = b.chart,
          h = b.options,
          f = a(h.animation),
          l = b.visible ? "inherit" : "hidden",
          e = h.zIndex,
          c = b.hasRendered,
          k = d.seriesGroup;
        d = !b.finishedAnimating && d.renderer.isSVG ? f.duration : 0;
        v(this, "render");
        b.plotGroup("group", "series", l, e, k);
        b.markerGroup = b.plotGroup("markerGroup", "markers", l, e, k);
        !1 !== h.clip && b.setClip();
        b.animate && d && b.animate(!0);
        b.drawGraph && (b.drawGraph(), b.applyZones());
        b.visible && b.drawPoints();
        b.drawDataLabels && b.drawDataLabels();
        b.redrawPoints && b.redrawPoints();
        b.drawTracker && !1 !== b.options.enableMouseTracking && b.drawTracker();
        b.animate && d && b.animate();
        c || (d && f.defer && (d += f.defer), b.animationTimeout = L(function () {
          b.afterAnimate();
        }, d || 0));
        b.isDirty = !1;
        b.hasRendered = !0;
        v(b, "afterRender");
      };
      c.prototype.redraw = function () {
        var b = this.isDirty || this.isDirtyData;
        this.translate();
        this.render();
        b && delete this.kdTree;
      };
      c.prototype.searchPoint = function (b, d) {
        var a = this.xAxis,
          h = this.yAxis,
          f = this.chart.inverted;
        return this.searchKDTree({
          clientX: f ? a.len - b.chartY + a.pos : b.chartX - a.pos,
          plotY: f ? h.len - b.chartX + h.pos : b.chartY - h.pos
        }, d, b);
      };
      c.prototype.buildKDTree = function (b) {
        function d(b, h, f) {
          var l = b && b.length;
          if (l) {
            var e = a.kdAxisArray[h % f];
            b.sort(function (b, d) {
              return b[e] - d[e];
            });
            l = Math.floor(l / 2);
            return {
              point: b[l],
              left: d(b.slice(0, l), h + 1, f),
              right: d(b.slice(l + 1), h + 1, f)
            };
          }
        }
        this.buildingKdTree = !0;
        var a = this,
          h = -1 < a.options.findNearestPointBy.indexOf("y") ? 2 : 1;
        delete a.kdTree;
        L(function () {
          a.kdTree = d(a.getValidPoints(null, !a.directTouch), h, h);
          a.buildingKdTree = !1;
        }, a.options.kdNow || b && "touchstart" === b.type ? 0 : 1);
      };
      c.prototype.searchKDTree = function (b, d, a) {
        function h(b, d, a, n) {
          var v = d.point,
            m = l.kdAxisArray[a % n],
            p = v,
            q = f(b[e]) && f(v[e]) ? Math.pow(b[e] - v[e], 2) : null;
          var w = f(b[c]) && f(v[c]) ? Math.pow(b[c] - v[c], 2) : null;
          w = (q || 0) + (w || 0);
          v.dist = f(w) ? Math.sqrt(w) : Number.MAX_VALUE;
          v.distX = f(q) ? Math.sqrt(q) : Number.MAX_VALUE;
          m = b[m] - v[m];
          w = 0 > m ? "left" : "right";
          q = 0 > m ? "right" : "left";
          d[w] && (w = h(b, d[w], a + 1, n), p = w[k] < p[k] ? w : v);
          d[q] && Math.sqrt(m * m) < p[k] && (b = h(b, d[q], a + 1, n), p = b[k] < p[k] ? b : p);
          return p;
        }
        var l = this,
          e = this.kdAxisArray[0],
          c = this.kdAxisArray[1],
          k = d ? "distX" : "dist";
        d = -1 < l.options.findNearestPointBy.indexOf("y") ? 2 : 1;
        this.kdTree || this.buildingKdTree || this.buildKDTree(a);
        if (this.kdTree) return h(b, this.kdTree, d, d);
      };
      c.prototype.pointPlacementToXValue = function () {
        var b = this.options,
          d = b.pointRange,
          a = this.xAxis;
        b = b.pointPlacement;
        "between" === b && (b = a.reversed ? -.5 : .5);
        return M(b) ? b * (d || a.pointRange) : 0;
      };
      c.prototype.isPointInside = function (b) {
        var d = this.chart,
          a = this.xAxis,
          h = this.yAxis;
        return "undefined" !== typeof b.plotY && "undefined" !== typeof b.plotX && 0 <= b.plotY && b.plotY <= (h ? h.len : d.plotHeight) && 0 <= b.plotX && b.plotX <= (a ? a.len : d.plotWidth);
      };
      c.prototype.drawTracker = function () {
        var b = this,
          d = b.options,
          a = d.trackByArea,
          h = [].concat(a ? b.areaPath : b.graphPath),
          f = b.chart,
          l = f.pointer,
          c = f.renderer,
          n = f.options.tooltip.snap,
          m = b.tracker,
          p = function (d) {
            if (f.hoverSeries !== b) b.onMouseOver();
          },
          q = "rgba(192,192,192," + (e ? .0001 : .002) + ")";
        m ? m.attr({
          d: h
        }) : b.graph && (b.tracker = c.path(h).attr({
          visibility: b.visible ? "inherit" : "hidden",
          zIndex: 2
        }).addClass(a ? "highcharts-tracker-area" : "highcharts-tracker-line").add(b.group), f.styledMode || b.tracker.attr({
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          stroke: q,
          fill: a ? q : "none",
          "stroke-width": b.graph.strokeWidth() + (a ? 0 : 2 * n)
        }), [b.tracker, b.markerGroup, b.dataLabelsGroup].forEach(function (b) {
          if (b && (b.addClass("highcharts-tracker").on("mouseover", p).on("mouseout", function (b) {
            l.onTrackerMouseOut(b);
          }), d.cursor && !f.styledMode && b.css({
            cursor: d.cursor
          }), k)) b.on("touchstart", p);
        }));
        v(this, "afterDrawTracker");
      };
      c.prototype.addPoint = function (b, d, a, h, f) {
        var l = this.options,
          e = this.data,
          c = this.chart,
          k = this.xAxis;
        k = k && k.hasNames && k.names;
        var n = l.data,
          m = this.xData,
          p;
        d = F(d, !0);
        var q = {
          series: this
        };
        this.pointClass.prototype.applyOptions.apply(q, [b]);
        var w = q.x;
        var u = m.length;
        if (this.requireSorting && w < m[u - 1]) for (p = !0; u && m[u - 1] > w;) u--;
        this.updateParallelArrays(q, "splice", u, 0, 0);
        this.updateParallelArrays(q, u);
        k && q.name && (k[w] = q.name);
        n.splice(u, 0, b);
        if (p || this.processedData) this.data.splice(u, 0, null), this.processData();
        "point" === l.legendType && this.generatePoints();
        a && (e[0] && e[0].remove ? e[0].remove(!1) : (e.shift(), this.updateParallelArrays(q, "shift"), n.shift()));
        !1 !== f && v(this, "addPoint", {
          point: q
        });
        this.isDirtyData = this.isDirty = !0;
        d && c.redraw(h);
      };
      c.prototype.removePoint = function (b, d, a) {
        var h = this,
          f = h.data,
          l = f[b],
          e = h.points,
          c = h.chart,
          k = function () {
            e && e.length === f.length && e.splice(b, 1);
            f.splice(b, 1);
            h.options.data.splice(b, 1);
            h.updateParallelArrays(l || {
              series: h
            }, "splice", b, 1);
            l && l.destroy();
            h.isDirty = !0;
            h.isDirtyData = !0;
            d && c.redraw();
          };
        q(a, c);
        d = F(d, !0);
        l ? l.firePointEvent("remove", null, k) : k();
      };
      c.prototype.remove = function (b, d, a, h) {
        function f() {
          l.destroy(h);
          e.isDirtyLegend = e.isDirtyBox = !0;
          e.linkSeries();
          F(b, !0) && e.redraw(d);
        }
        var l = this,
          e = l.chart;
        !1 !== a ? v(l, "remove", null, f) : f();
      };
      c.prototype.update = function (a, h) {
        a = n(a, this.userOptions);
        v(this, "update", {
          options: a
        });
        var f = this,
          l = f.chart,
          e = f.userOptions,
          c = f.initialType || f.type,
          k = l.options.plotOptions,
          m = G[c].prototype,
          p = f.finishedAnimating && {
            animation: !1
          },
          q = {},
          w = ["eventOptions", "navigatorSeries", "baseSeries"],
          u = a.type || e.type || l.options.chart.type,
          g = !(this.hasDerivedData || u && u !== this.type || "undefined" !== typeof a.pointStart || "undefined" !== typeof a.pointInterval || "undefined" !== typeof a.relativeXValue || a.joinBy || a.mapData || f.hasOptionChanged("dataGrouping") || f.hasOptionChanged("pointStart") || f.hasOptionChanged("pointInterval") || f.hasOptionChanged("pointIntervalUnit") || f.hasOptionChanged("keys"));
        u = u || c;
        g && (w.push("data", "isDirtyData", "points", "processedData", "processedXData", "processedYData", "xIncrement", "cropped", "_hasPointMarkers", "_hasPointLabels", "clips", "nodes", "layout", "level", "mapMap", "mapData", "minY", "maxY", "minX", "maxX"), !1 !== a.visible && w.push("area", "graph"), f.parallelArrays.forEach(function (b) {
          w.push(b + "Data");
        }), a.data && (a.dataSorting && b(f.options.dataSorting, a.dataSorting), this.setData(a.data, !1)));
        a = P(e, p, {
          index: "undefined" === typeof e.index ? f.index : e.index,
          pointStart: F(k && k.series && k.series.pointStart, e.pointStart, f.xData[0])
        }, !g && {
          data: f.options.data
        }, a);
        g && a.data && (a.data = f.options.data);
        w = ["group", "markerGroup", "dataLabelsGroup", "transformGroup", "shadowGroup"].concat(w);
        w.forEach(function (b) {
          w[b] = f[b];
          delete f[b];
        });
        k = !1;
        if (G[u]) {
          if (k = u !== f.type, f.remove(!1, !1, !1, !0), k) if (Object.setPrototypeOf) Object.setPrototypeOf(f, G[u].prototype);else {
            p = Object.hasOwnProperty.call(f, "hcEvents") && f.hcEvents;
            for (r in m) f[r] = void 0;
            b(f, G[u].prototype);
            p ? f.hcEvents = p : delete f.hcEvents;
          }
        } else d(17, !0, l, {
          missingModuleFor: u
        });
        w.forEach(function (b) {
          f[b] = w[b];
        });
        f.init(l, a);
        if (g && this.points) {
          a = f.options;
          if (!1 === a.visible) q.graphic = 1, q.dataLabel = 1;else if (!f._hasPointLabels) {
            m = a.marker;
            var r = a.dataLabels;
            e = e.marker || {};
            !m || !1 !== m.enabled && e.symbol === m.symbol && e.height === m.height && e.width === m.width || (q.graphic = 1);
            r && !1 === r.enabled && (q.dataLabel = 1);
          }
          e = 0;
          for (m = this.points; e < m.length; e++) (r = m[e]) && r.series && (r.resolveColor(), Object.keys(q).length && r.destroyElements(q), !1 === a.showInLegend && r.legendItem && l.legend.destroyItem(r));
        }
        f.initialType = c;
        l.linkSeries();
        k && f.linkedSeries.length && (f.isDirtyData = !0);
        v(this, "afterUpdate");
        F(h, !0) && l.redraw(g ? void 0 : !1);
      };
      c.prototype.setName = function (b) {
        this.name = this.options.name = this.userOptions.name = b;
        this.chart.isDirtyLegend = !0;
      };
      c.prototype.hasOptionChanged = function (b) {
        var d = this.options[b],
          a = this.chart.options.plotOptions,
          h = this.userOptions[b];
        return h ? d !== h : d !== F(a && a[this.type] && a[this.type][b], a && a.series && a.series[b], d);
      };
      c.prototype.onMouseOver = function () {
        var b = this.chart,
          d = b.hoverSeries;
        b.pointer.setHoverChartIndex();
        if (d && d !== this) d.onMouseOut();
        this.options.events.mouseOver && v(this, "mouseOver");
        this.setState("hover");
        b.hoverSeries = this;
      };
      c.prototype.onMouseOut = function () {
        var b = this.options,
          d = this.chart,
          a = d.tooltip,
          h = d.hoverPoint;
        d.hoverSeries = null;
        if (h) h.onMouseOut();
        this && b.events.mouseOut && v(this, "mouseOut");
        !a || this.stickyTracking || a.shared && !this.noSharedTooltip || a.hide();
        d.series.forEach(function (b) {
          b.setState("", !0);
        });
      };
      c.prototype.setState = function (b, d) {
        var a = this,
          h = a.options,
          f = a.graph,
          l = h.inactiveOtherPoints,
          e = h.states,
          c = F(e[b || "normal"] && e[b || "normal"].animation, a.chart.options.chart.animation),
          k = h.lineWidth,
          n = 0,
          v = h.opacity;
        b = b || "";
        if (a.state !== b && ([a.group, a.markerGroup, a.dataLabelsGroup].forEach(function (d) {
          d && (a.state && d.removeClass("highcharts-series-" + a.state), b && d.addClass("highcharts-series-" + b));
        }), a.state = b, !a.chart.styledMode)) {
          if (e[b] && !1 === e[b].enabled) return;
          b && (k = e[b].lineWidth || k + (e[b].lineWidthPlus || 0), v = F(e[b].opacity, v));
          if (f && !f.dashstyle && M(k)) for (h = {
            "stroke-width": k
          }, f.animate(h, c); a["zone-graph-" + n];) a["zone-graph-" + n].animate(h, c), n += 1;
          l || [a.group, a.markerGroup, a.dataLabelsGroup, a.labelBySeries].forEach(function (b) {
            b && b.animate({
              opacity: v
            }, c);
          });
        }
        d && l && a.points && a.setAllPointsToState(b || void 0);
      };
      c.prototype.setAllPointsToState = function (b) {
        this.points.forEach(function (d) {
          d.setState && d.setState(b);
        });
      };
      c.prototype.setVisible = function (b, d) {
        var a = this,
          h = a.chart,
          f = h.options.chart.ignoreHiddenSeries,
          l = a.visible,
          e = (a.visible = b = a.options.visible = a.userOptions.visible = "undefined" === typeof b ? !l : b) ? "show" : "hide";
        ["group", "dataLabelsGroup", "markerGroup", "tracker", "tt"].forEach(function (b) {
          if (a[b]) a[b][e]();
        });
        if (h.hoverSeries === a || (h.hoverPoint && h.hoverPoint.series) === a) a.onMouseOut();
        a.legendItem && h.legend.colorizeItem(a, b);
        a.isDirty = !0;
        a.options.stacking && h.series.forEach(function (b) {
          b.options.stacking && b.visible && (b.isDirty = !0);
        });
        a.linkedSeries.forEach(function (d) {
          d.setVisible(b, !1);
        });
        f && (h.isDirtyBox = !0);
        v(a, e);
        !1 !== d && h.redraw();
      };
      c.prototype.show = function () {
        this.setVisible(!0);
      };
      c.prototype.hide = function () {
        this.setVisible(!1);
      };
      c.prototype.select = function (b) {
        this.selected = b = this.options.selected = "undefined" === typeof b ? !this.selected : b;
        this.checkbox && (this.checkbox.checked = b);
        v(this, b ? "select" : "unselect");
      };
      c.prototype.shouldShowTooltip = function (b, d, a) {
        void 0 === a && (a = {});
        a.series = this;
        a.visiblePlotOnly = !0;
        return this.chart.isInsidePlot(b, d, a);
      };
      c.defaultOptions = C;
      c.types = x.seriesTypes;
      c.registerType = x.registerSeriesType;
      return c;
    }();
    b(c.prototype, {
      axisTypes: ["xAxis", "yAxis"],
      coll: "series",
      colorCounter: 0,
      cropShoulder: 1,
      directTouch: !1,
      drawLegendSymbol: B.drawLineMarker,
      isCartesian: !0,
      kdAxisArray: ["clientX", "plotY"],
      parallelArrays: ["x", "y"],
      pointClass: z,
      requireSorting: !0,
      sorted: !0
    });
    x.series = c;
    "";
    "";
    return c;
  });
  I(g, "Extensions/ScrollablePlotArea.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Axis/Axis.js"], g["Core/Chart/Chart.js"], g["Core/Series/Series.js"], g["Core/Renderer/RendererRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z) {
    var C = c.stop,
      x = z.addEvent,
      t = z.createElement,
      r = z.defined,
      a = z.merge,
      q = z.pick;
    x(y, "afterSetChartSize", function (c) {
      var m = this.options.chart.scrollablePlotArea,
        k = m && m.minWidth;
      m = m && m.minHeight;
      if (!this.renderer.forExport) {
        if (k) {
          if (this.scrollablePixelsX = k = Math.max(0, k - this.chartWidth)) {
            this.scrollablePlotBox = this.renderer.scrollablePlotBox = a(this.plotBox);
            this.plotBox.width = this.plotWidth += k;
            this.inverted ? this.clipBox.height += k : this.clipBox.width += k;
            var e = {
              1: {
                name: "right",
                value: k
              }
            };
          }
        } else m && (this.scrollablePixelsY = k = Math.max(0, m - this.chartHeight), r(k) && (this.scrollablePlotBox = this.renderer.scrollablePlotBox = a(this.plotBox), this.plotBox.height = this.plotHeight += k, this.inverted ? this.clipBox.width += k : this.clipBox.height += k, e = {
          2: {
            name: "bottom",
            value: k
          }
        }));
        e && !c.skipAxes && this.axes.forEach(function (a) {
          e[a.side] ? a.getPlotLinePath = function () {
            var c = e[a.side].name,
              k = this[c];
            this[c] = k - e[a.side].value;
            var m = g.prototype.getPlotLinePath.apply(this, arguments);
            this[c] = k;
            return m;
          } : (a.setAxisSize(), a.setAxisTranslation());
        });
      }
    });
    x(y, "render", function () {
      this.scrollablePixelsX || this.scrollablePixelsY ? (this.setUpScrolling && this.setUpScrolling(), this.applyFixed()) : this.fixedDiv && this.applyFixed();
    });
    y.prototype.setUpScrolling = function () {
      var a = this,
        c = {
          WebkitOverflowScrolling: "touch",
          overflowX: "hidden",
          overflowY: "hidden"
        };
      this.scrollablePixelsX && (c.overflowX = "auto");
      this.scrollablePixelsY && (c.overflowY = "auto");
      this.scrollingParent = t("div", {
        className: "highcharts-scrolling-parent"
      }, {
        position: "relative"
      }, this.renderTo);
      this.scrollingContainer = t("div", {
        className: "highcharts-scrolling"
      }, c, this.scrollingParent);
      var k;
      x(this.scrollingContainer, "scroll", function () {
        a.pointer && (delete a.pointer.chartPosition, a.hoverPoint && (k = a.hoverPoint), a.pointer.runPointActions(void 0, k, !0));
      });
      this.innerContainer = t("div", {
        className: "highcharts-inner-container"
      }, null, this.scrollingContainer);
      this.innerContainer.appendChild(this.container);
      this.setUpScrolling = null;
    };
    y.prototype.moveFixedElements = function () {
      var a = this.container,
        c = this.fixedRenderer,
        k = ".highcharts-breadcrumbs-group .highcharts-contextbutton .highcharts-credits .highcharts-legend .highcharts-legend-checkbox .highcharts-navigator-series .highcharts-navigator-xaxis .highcharts-navigator-yaxis .highcharts-navigator .highcharts-reset-zoom .highcharts-drillup-button .highcharts-scrollbar .highcharts-subtitle .highcharts-title".split(" "),
        e;
      this.scrollablePixelsX && !this.inverted ? e = ".highcharts-yaxis" : this.scrollablePixelsX && this.inverted ? e = ".highcharts-xaxis" : this.scrollablePixelsY && !this.inverted ? e = ".highcharts-xaxis" : this.scrollablePixelsY && this.inverted && (e = ".highcharts-yaxis");
      e && k.push("" + e + ":not(.highcharts-radial-axis)", "" + e + "-labels:not(.highcharts-radial-axis-labels)");
      k.forEach(function (e) {
        [].forEach.call(a.querySelectorAll(e), function (a) {
          (a.namespaceURI === c.SVG_NS ? c.box : c.box.parentNode).appendChild(a);
          a.style.pointerEvents = "auto";
        });
      });
    };
    y.prototype.applyFixed = function () {
      var a = !this.fixedDiv,
        c = this.options.chart,
        k = c.scrollablePlotArea,
        e = B.getRendererType();
      a ? (this.fixedDiv = t("div", {
        className: "highcharts-fixed"
      }, {
        position: "absolute",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: (c.style && c.style.zIndex || 0) + 2,
        top: 0
      }, null, !0), this.scrollingContainer && this.scrollingContainer.parentNode.insertBefore(this.fixedDiv, this.scrollingContainer), this.renderTo.style.overflow = "visible", this.fixedRenderer = c = new e(this.fixedDiv, this.chartWidth, this.chartHeight, this.options.chart.style), this.scrollableMask = c.path().attr({
        fill: this.options.chart.backgroundColor || "#fff",
        "fill-opacity": q(k.opacity, .85),
        zIndex: -1
      }).addClass("highcharts-scrollable-mask").add(), x(this, "afterShowResetZoom", this.moveFixedElements), x(this, "afterApplyDrilldown", this.moveFixedElements), x(this, "afterLayOutTitles", this.moveFixedElements)) : this.fixedRenderer.setSize(this.chartWidth, this.chartHeight);
      if (this.scrollableDirty || a) this.scrollableDirty = !1, this.moveFixedElements();
      c = this.chartWidth + (this.scrollablePixelsX || 0);
      e = this.chartHeight + (this.scrollablePixelsY || 0);
      C(this.container);
      this.container.style.width = c + "px";
      this.container.style.height = e + "px";
      this.renderer.boxWrapper.attr({
        width: c,
        height: e,
        viewBox: [0, 0, c, e].join(" ")
      });
      this.chartBackground.attr({
        width: c,
        height: e
      });
      this.scrollingContainer.style.height = this.chartHeight + "px";
      a && (k.scrollPositionX && (this.scrollingContainer.scrollLeft = this.scrollablePixelsX * k.scrollPositionX), k.scrollPositionY && (this.scrollingContainer.scrollTop = this.scrollablePixelsY * k.scrollPositionY));
      e = this.axisOffset;
      a = this.plotTop - e[0] - 1;
      k = this.plotLeft - e[3] - 1;
      c = this.plotTop + this.plotHeight + e[2] + 1;
      e = this.plotLeft + this.plotWidth + e[1] + 1;
      var g = this.plotLeft + this.plotWidth - (this.scrollablePixelsX || 0),
        r = this.plotTop + this.plotHeight - (this.scrollablePixelsY || 0);
      a = this.scrollablePixelsX ? [["M", 0, a], ["L", this.plotLeft - 1, a], ["L", this.plotLeft - 1, c], ["L", 0, c], ["Z"], ["M", g, a], ["L", this.chartWidth, a], ["L", this.chartWidth, c], ["L", g, c], ["Z"]] : this.scrollablePixelsY ? [["M", k, 0], ["L", k, this.plotTop - 1], ["L", e, this.plotTop - 1], ["L", e, 0], ["Z"], ["M", k, r], ["L", k, this.chartHeight], ["L", e, this.chartHeight], ["L", e, r], ["Z"]] : [["M", 0, 0]];
      "adjustHeight" !== this.redrawTrigger && this.scrollableMask.attr({
        d: a
      });
    };
    x(g, "afterInit", function () {
      this.chart.scrollableDirty = !0;
    });
    x(A, "show", function () {
      this.chart.scrollableDirty = !0;
    });
    "";
  });
  I(g, "Core/Axis/Stacking/StackItem.js", [g["Core/FormatUtilities.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = c.format,
      B = g.series,
      z = y.destroyObjectProperties,
      C = y.pick,
      x = y.isNumber;
    c = function () {
      function c(c, a, q, m, p) {
        var k = c.chart.inverted,
          e = c.reversed;
        this.axis = c;
        c = this.isNegative = !!q !== !!e;
        this.options = a = a || {};
        this.x = m;
        this.cumulative = this.total = null;
        this.points = {};
        this.hasValidPoints = !1;
        this.stack = p;
        this.rightCliff = this.leftCliff = 0;
        this.alignOptions = {
          align: a.align || (k ? c ? "left" : "right" : "center"),
          verticalAlign: a.verticalAlign || (k ? "middle" : c ? "bottom" : "top"),
          y: a.y,
          x: a.x
        };
        this.textAlign = a.textAlign || (k ? c ? "right" : "left" : "center");
      }
      c.prototype.destroy = function () {
        z(this, this.axis);
      };
      c.prototype.render = function (c) {
        var a = this.axis.chart,
          q = this.options,
          m = q.format;
        m = m ? A(m, this, a) : q.formatter.call(this);
        this.label ? this.label.attr({
          text: m,
          visibility: "hidden"
        }) : (this.label = a.renderer.label(m, null, void 0, q.shape, void 0, void 0, q.useHTML, !1, "stack-labels"), m = {
          r: q.borderRadius || 0,
          text: m,
          padding: C(q.padding, 5),
          visibility: "hidden"
        }, a.styledMode || (m.fill = q.backgroundColor, m.stroke = q.borderColor, m["stroke-width"] = q.borderWidth, this.label.css(q.style || {})), this.label.attr(m), this.label.added || this.label.add(c));
        this.label.labelrank = a.plotSizeY;
      };
      c.prototype.setOffset = function (c, a, q, m, p, k) {
        var e = this.alignOptions,
          g = this.axis,
          r = this.label,
          t = this.options,
          z = this.textAlign,
          u = g.chart;
        c = this.getStackBox({
          xOffset: c,
          width: a,
          boxBottom: q,
          boxTop: m,
          defaultX: p,
          xAxis: k
        });
        p = e.verticalAlign;
        r && c && (a = r.getBBox(), q = r.padding, m = "justify" === C(t.overflow, "justify"), e.x = t.x || 0, e.y = t.y || 0, p = this.adjustStackPosition({
          labelBox: a,
          verticalAlign: p,
          textAlign: z
        }), z = p.x, p = p.y, c.x -= z, c.y -= p, r.align(e, !1, c), (z = u.isInsidePlot(r.alignAttr.x + e.x + z, r.alignAttr.y + e.y + p)) || (m = !1), m && B.prototype.justifyDataLabel.call(g, r, e, r.alignAttr, a, c), r.attr({
          x: r.alignAttr.x,
          y: r.alignAttr.y,
          rotation: t.rotation,
          rotationOriginX: a.width / 2,
          rotationOriginY: a.height / 2
        }), C(!m && t.crop, !0) && (z = x(r.x) && x(r.y) && u.isInsidePlot(r.x - q + r.width, r.y) && u.isInsidePlot(r.x + q, r.y)), r[z ? "show" : "hide"]());
      };
      c.prototype.adjustStackPosition = function (c) {
        var a = c.labelBox,
          q = {
            bottom: 0,
            middle: 1,
            top: 2,
            right: 1,
            center: 0,
            left: -1
          };
        return {
          x: a.width / 2 + a.width / 2 * q[c.textAlign],
          y: a.height / 2 * q[c.verticalAlign]
        };
      };
      c.prototype.getStackBox = function (c) {
        var a = this.axis,
          q = a.chart,
          m = c.boxTop,
          p = c.defaultX,
          k = c.xOffset,
          e = c.width,
          g = c.boxBottom;
        m = a.stacking.usePercentage ? 100 : C(m, this.total, 0);
        m = a.toPixels(m);
        c = C(p, (c.xAxis || q.xAxis[0]).toPixels(this.x)) + k;
        a = a.toPixels(g ? g : 0);
        a = Math.abs(m - a);
        g = this.isNegative;
        return q.inverted ? {
          x: (g ? m : m - a) - q.plotLeft,
          y: c - q.plotTop,
          width: a,
          height: e
        } : {
          x: c - q.plotLeft,
          y: (g ? m - a : m) - q.plotTop,
          width: e,
          height: a
        };
      };
      return c;
    }();
    "";
    return c;
  });
  I(g, "Core/Axis/Stacking/StackingAxis.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Axis/Axis.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Axis/Stacking/StackItem.js"], g["Core/Utilities.js"]], function (c, g, y, A, B) {
    function z() {
      var b = this,
        d = b.inverted;
      b.yAxis.forEach(function (b) {
        b.stacking && b.stacking.stacks && b.hasVisibleSeries && (b.stacking.oldStacks = b.stacking.stacks);
      });
      b.series.forEach(function (a) {
        var h = a.xAxis && a.xAxis.options || {};
        !a.options.stacking || !0 !== a.visible && !1 !== b.options.chart.ignoreHiddenSeries || (a.stackKey = [a.type, f(a.options.stack, ""), d ? h.top : h.left, d ? h.height : h.width].join());
      });
    }
    function C() {
      var b = this.stacking;
      if (b) {
        var d = b.stacks;
        w(d, function (b, a) {
          H(b);
          d[a] = null;
        });
        b && b.stackTotalGroup && b.stackTotalGroup.destroy();
      }
    }
    function x() {
      "yAxis" !== this.coll || this.stacking || (this.stacking = new l(this));
    }
    function t(b, d, a, f) {
      !G(b) || b.x !== d || f && b.stackKey !== f ? b = {
        x: d,
        index: 0,
        key: f,
        stackKey: f
      } : b.index++;
      b.key = [a, d, b.index].join();
      return b;
    }
    function r() {
      var b = this,
        d = b.stackKey,
        a = b.yAxis.stacking.stacks,
        f = b.processedXData,
        l = b[b.options.stacking + "Stacker"],
        e;
      l && [d, "-" + d].forEach(function (d) {
        for (var h = f.length, c, k; h--;) c = f[h], e = b.getStackIndicator(e, c, b.index, d), (k = (c = a[d] && a[d][c]) && c.points[e.key]) && l.call(b, k, c, h);
      });
    }
    function a(b, d, a) {
      d = d.total ? 100 / d.total : 0;
      b[0] = D(b[0] * d);
      b[1] = D(b[1] * d);
      this.stackedYData[a] = b[1];
    }
    function q() {
      var b = this.yAxis.stacking;
      this.options.centerInCategory && (this.is("column") || this.is("columnrange")) && !this.options.stacking && 1 < this.chart.series.length ? k.setStackedPoints.call(this, "group") : b && w(b.stacks, function (d, a) {
        "group" === a.slice(-5) && (w(d, function (b) {
          return b.destroy();
        }), delete b.stacks[a]);
      });
    }
    function m(b) {
      var d = this.chart,
        a = b || this.options.stacking;
      if (a && (!0 === this.visible || !1 === d.options.chart.ignoreHiddenSeries)) {
        var l = this.processedXData,
          e = this.processedYData,
          c = [],
          k = e.length,
          n = this.options,
          m = n.threshold,
          p = f(n.startFromThreshold && m, 0);
        n = n.stack;
        b = b ? "" + this.type + ",".concat(a) : this.stackKey;
        var q = "-" + b,
          w = this.negStacks;
        d = "group" === a ? d.yAxis[0] : this.yAxis;
        var g = d.stacking.stacks,
          r = d.stacking.oldStacks,
          t,
          x;
        d.stacking.stacksTouched += 1;
        for (x = 0; x < k; x++) {
          var C = l[x];
          var H = e[x];
          var z = this.getStackIndicator(z, C, this.index);
          var B = z.key;
          var y = (t = w && H < (p ? 0 : m)) ? q : b;
          g[y] || (g[y] = {});
          g[y][C] || (r[y] && r[y][C] ? (g[y][C] = r[y][C], g[y][C].total = null) : g[y][C] = new A(d, d.options.stackLabels, !!t, C, n));
          y = g[y][C];
          null !== H ? (y.points[B] = y.points[this.index] = [f(y.cumulative, p)], G(y.cumulative) || (y.base = B), y.touched = d.stacking.stacksTouched, 0 < z.index && !1 === this.singleStacks && (y.points[B][0] = y.points[this.index + "," + C + ",0"][0])) : y.points[B] = y.points[this.index] = null;
          "percent" === a ? (t = t ? b : q, w && g[t] && g[t][C] ? (t = g[t][C], y.total = t.total = Math.max(t.total, y.total) + Math.abs(H) || 0) : y.total = D(y.total + (Math.abs(H) || 0))) : "group" === a ? (u(H) && (H = H[0]), null !== H && (y.total = (y.total || 0) + 1)) : y.total = D(y.total + (H || 0));
          y.cumulative = "group" === a ? (y.total || 1) - 1 : f(y.cumulative, p) + (H || 0);
          null !== H && (y.points[B].push(y.cumulative), c[x] = y.cumulative, y.hasValidPoints = !0);
        }
        "percent" === a && (d.stacking.usePercentage = !0);
        "group" !== a && (this.stackedYData = c);
        d.stacking.oldStacks = {};
      }
    }
    var p = c.getDeferredAnimation,
      k = y.series.prototype,
      e = B.addEvent,
      D = B.correctFloat,
      G = B.defined,
      H = B.destroyObjectProperties,
      K = B.fireEvent,
      u = B.isArray,
      n = B.isNumber,
      w = B.objectEach,
      f = B.pick,
      l = function () {
        function b(b) {
          this.oldStacks = {};
          this.stacks = {};
          this.stacksTouched = 0;
          this.axis = b;
        }
        b.prototype.buildStacks = function () {
          var b = this.axis,
            d = b.series,
            a = b.options.reversedStacks,
            f = d.length,
            l;
          this.usePercentage = !1;
          for (l = f; l--;) {
            var e = d[a ? l : f - l - 1];
            e.setStackedPoints();
            e.setGroupedPoints();
          }
          for (l = 0; l < f; l++) d[l].modifyStacks();
          K(b, "afterBuildStacks");
        };
        b.prototype.cleanStacks = function () {
          if (this.oldStacks) var b = this.stacks = this.oldStacks;
          w(b, function (b) {
            w(b, function (b) {
              b.cumulative = b.total;
            });
          });
        };
        b.prototype.resetStacks = function () {
          var b = this;
          w(this.stacks, function (d) {
            w(d, function (a, h) {
              n(a.touched) && a.touched < b.stacksTouched ? (a.destroy(), delete d[h]) : (a.total = null, a.cumulative = null);
            });
          });
        };
        b.prototype.renderStackTotals = function () {
          var b = this.axis,
            d = b.chart,
            a = d.renderer,
            f = this.stacks;
          b = p(d, b.options.stackLabels && b.options.stackLabels.animation || !1);
          var l = this.stackTotalGroup = this.stackTotalGroup || a.g("stack-labels").attr({
            zIndex: 6,
            opacity: 0
          }).add();
          l.translate(d.plotLeft, d.plotTop);
          w(f, function (b) {
            w(b, function (b) {
              b.render(l);
            });
          });
          l.animate({
            opacity: 1
          }, b);
        };
        return b;
      }(),
      d;
    (function (b) {
      var d = [];
      b.compose = function (b, h, f) {
        -1 === d.indexOf(b) && (d.push(b), e(b, "init", x), e(b, "destroy", C));
        -1 === d.indexOf(h) && (d.push(h), h.prototype.getStacks = z);
        -1 === d.indexOf(f) && (d.push(f), b = f.prototype, b.getStackIndicator = t, b.modifyStacks = r, b.percentStacker = a, b.setGroupedPoints = q, b.setStackedPoints = m);
      };
    })(d || (d = {}));
    return d;
  });
  I(g, "Series/Line/LineSeries.js", [g["Core/Series/Series.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = this && this.__extends || function () {
        var c = function (g, t) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, a) {
            c.__proto__ = a;
          } || function (c, a) {
            for (var q in a) a.hasOwnProperty(q) && (c[q] = a[q]);
          };
          return c(g, t);
        };
        return function (g, t) {
          function r() {
            this.constructor = g;
          }
          c(g, t);
          g.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
        };
      }(),
      B = y.defined,
      z = y.merge;
    y = function (g) {
      function x() {
        var c = null !== g && g.apply(this, arguments) || this;
        c.data = void 0;
        c.options = void 0;
        c.points = void 0;
        return c;
      }
      A(x, g);
      x.prototype.drawGraph = function () {
        var c = this,
          g = this.options,
          a = (this.gappedPath || this.getGraphPath).call(this),
          q = this.chart.styledMode,
          m = [["graph", "highcharts-graph"]];
        q || m[0].push(g.lineColor || this.color || "#cccccc", g.dashStyle);
        m = c.getZonesGraphs(m);
        m.forEach(function (m, k) {
          var e = m[0],
            p = c[e],
            r = p ? "animate" : "attr";
          p ? (p.endX = c.preventGraphAnimation ? null : a.xMap, p.animate({
            d: a
          })) : a.length && (c[e] = p = c.chart.renderer.path(a).addClass(m[1]).attr({
            zIndex: 1
          }).add(c.group));
          p && !q && (e = {
            stroke: m[2],
            "stroke-width": g.lineWidth || 0,
            fill: c.fillGraph && c.color || "none"
          }, m[3] ? e.dashstyle = m[3] : "square" !== g.linecap && (e["stroke-linecap"] = e["stroke-linejoin"] = "round"), p[r](e).shadow(2 > k && g.shadow));
          p && (p.startX = a.xMap, p.isArea = a.isArea);
        });
      };
      x.prototype.getGraphPath = function (c, g, a) {
        var q = this,
          m = q.options,
          p = [],
          k = [],
          e,
          r = m.step;
        c = c || q.points;
        var t = c.reversed;
        t && c.reverse();
        (r = {
          right: 1,
          center: 2
        }[r] || r && 3) && t && (r = 4 - r);
        c = this.getValidPoints(c, !1, !(m.connectNulls && !g && !a));
        c.forEach(function (t, D) {
          var u = t.plotX,
            n = t.plotY,
            w = c[D - 1];
          (t.leftCliff || w && w.rightCliff) && !a && (e = !0);
          t.isNull && !B(g) && 0 < D ? e = !m.connectNulls : t.isNull && !g ? e = !0 : (0 === D || e ? D = [["M", t.plotX, t.plotY]] : q.getPointSpline ? D = [q.getPointSpline(c, t, D)] : r ? (D = 1 === r ? [["L", w.plotX, n]] : 2 === r ? [["L", (w.plotX + u) / 2, w.plotY], ["L", (w.plotX + u) / 2, n]] : [["L", u, w.plotY]], D.push(["L", u, n])) : D = [["L", u, n]], k.push(t.x), r && (k.push(t.x), 2 === r && k.push(t.x)), p.push.apply(p, D), e = !1);
        });
        p.xMap = k;
        return q.graphPath = p;
      };
      x.prototype.getZonesGraphs = function (c) {
        this.zones.forEach(function (g, a) {
          a = ["zone-graph-" + a, "highcharts-graph highcharts-zone-graph-" + a + " " + (g.className || "")];
          this.chart.styledMode || a.push(g.color || this.color, g.dashStyle || this.options.dashStyle);
          c.push(a);
        }, this);
        return c;
      };
      x.defaultOptions = z(c.defaultOptions, {});
      return x;
    }(c);
    g.registerSeriesType("line", y);
    "";
    return y;
  });
  I(g, "Series/Area/AreaSeries.js", [g["Core/Color/Color.js"], g["Core/Legend/LegendSymbol.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var B = this && this.__extends || function () {
        var a = function (c, m) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var e in c) c.hasOwnProperty(e) && (a[e] = c[e]);
          };
          return a(c, m);
        };
        return function (c, m) {
          function p() {
            this.constructor = c;
          }
          a(c, m);
          c.prototype = null === m ? Object.create(m) : (p.prototype = m.prototype, new p());
        };
      }(),
      z = c.parse,
      C = y.seriesTypes.line;
    c = A.extend;
    var x = A.merge,
      t = A.objectEach,
      r = A.pick;
    A = function (a) {
      function c() {
        var c = null !== a && a.apply(this, arguments) || this;
        c.data = void 0;
        c.options = void 0;
        c.points = void 0;
        return c;
      }
      B(c, a);
      c.prototype.drawGraph = function () {
        this.areaPath = [];
        a.prototype.drawGraph.apply(this);
        var c = this,
          p = this.areaPath,
          k = this.options,
          e = [["area", "highcharts-area", this.color, k.fillColor]];
        this.zones.forEach(function (a, m) {
          e.push(["zone-area-" + m, "highcharts-area highcharts-zone-area-" + m + " " + a.className, a.color || c.color, a.fillColor || k.fillColor]);
        });
        e.forEach(function (a) {
          var e = a[0],
            m = {},
            q = c[e],
            g = q ? "animate" : "attr";
          q ? (q.endX = c.preventGraphAnimation ? null : p.xMap, q.animate({
            d: p
          })) : (m.zIndex = 0, q = c[e] = c.chart.renderer.path(p).addClass(a[1]).add(c.group), q.isArea = !0);
          c.chart.styledMode || (m.fill = r(a[3], z(a[2]).setOpacity(r(k.fillOpacity, .75)).get()));
          q[g](m);
          q.startX = p.xMap;
          q.shiftUnit = k.step ? 2 : 1;
        });
      };
      c.prototype.getGraphPath = function (a) {
        var c = C.prototype.getGraphPath,
          k = this.options,
          e = k.stacking,
          m = this.yAxis,
          q = [],
          g = [],
          t = this.index,
          u = m.stacking.stacks[this.stackKey],
          n = k.threshold,
          w = Math.round(m.getThreshold(k.threshold));
        k = r(k.connectNulls, "percent" === e);
        var f = function (b, d, f) {
          var l = a[b];
          b = e && u[l.x].points[t];
          var c = l[f + "Null"] || 0;
          f = l[f + "Cliff"] || 0;
          l = !0;
          if (f || c) {
            var k = (c ? b[0] : b[1]) + f;
            var v = b[0] + f;
            l = !!c;
          } else !e && a[d] && a[d].isNull && (k = v = n);
          "undefined" !== typeof k && (g.push({
            plotX: h,
            plotY: null === k ? w : m.getThreshold(k),
            isNull: l,
            isCliff: !0
          }), q.push({
            plotX: h,
            plotY: null === v ? w : m.getThreshold(v),
            doCurve: !1
          }));
        };
        a = a || this.points;
        e && (a = this.getStackPoints(a));
        for (var l = 0, d = a.length; l < d; ++l) {
          e || (a[l].leftCliff = a[l].rightCliff = a[l].leftNull = a[l].rightNull = void 0);
          var b = a[l].isNull;
          var h = r(a[l].rectPlotX, a[l].plotX);
          var v = e ? r(a[l].yBottom, w) : w;
          if (!b || k) k || f(l, l - 1, "left"), b && !e && k || (g.push(a[l]), q.push({
            x: l,
            plotX: h,
            plotY: v
          })), k || f(l, l + 1, "right");
        }
        f = c.call(this, g, !0, !0);
        q.reversed = !0;
        b = c.call(this, q, !0, !0);
        (v = b[0]) && "M" === v[0] && (b[0] = ["L", v[1], v[2]]);
        b = f.concat(b);
        b.length && b.push(["Z"]);
        c = c.call(this, g, !1, k);
        b.xMap = f.xMap;
        this.areaPath = b;
        return c;
      };
      c.prototype.getStackPoints = function (a) {
        var c = this,
          k = [],
          e = [],
          m = this.xAxis,
          q = this.yAxis,
          g = q.stacking.stacks[this.stackKey],
          x = {},
          u = q.series,
          n = u.length,
          w = q.options.reversedStacks ? 1 : -1,
          f = u.indexOf(c);
        a = a || this.points;
        if (this.options.stacking) {
          for (var l = 0; l < a.length; l++) a[l].leftNull = a[l].rightNull = void 0, x[a[l].x] = a[l];
          t(g, function (b, d) {
            null !== b.total && e.push(d);
          });
          e.sort(function (b, d) {
            return b - d;
          });
          var d = u.map(function (b) {
            return b.visible;
          });
          e.forEach(function (b, a) {
            var h = 0,
              l,
              p;
            if (x[b] && !x[b].isNull) k.push(x[b]), [-1, 1].forEach(function (h) {
              var k = 1 === h ? "rightNull" : "leftNull",
                m = g[e[a + h]],
                v = 0;
              if (m) for (var q = f; 0 <= q && q < n;) {
                var r = u[q].index;
                l = m.points[r];
                l || (r === c.index ? x[b][k] = !0 : d[q] && (p = g[b].points[r]) && (v -= p[1] - p[0]));
                q += w;
              }
              x[b][1 === h ? "rightCliff" : "leftCliff"] = v;
            });else {
              for (var t = f; 0 <= t && t < n;) {
                if (l = g[b].points[u[t].index]) {
                  h = l[1];
                  break;
                }
                t += w;
              }
              h = r(h, 0);
              h = q.translate(h, 0, 1, 0, 1);
              k.push({
                isNull: !0,
                plotX: m.translate(b, 0, 0, 0, 1),
                x: b,
                plotY: h,
                yBottom: h
              });
            }
          });
        }
        return k;
      };
      c.defaultOptions = x(C.defaultOptions, {
        threshold: 0
      });
      return c;
    }(C);
    c(A.prototype, {
      singleStacks: !1,
      drawLegendSymbol: g.drawRectangle
    });
    y.registerSeriesType("area", A);
    "";
    return A;
  });
  I(g, "Series/Spline/SplineSeries.js", [g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g) {
    var y = this && this.__extends || function () {
        var c = function (g, t) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, a) {
            c.__proto__ = a;
          } || function (c, a) {
            for (var q in a) a.hasOwnProperty(q) && (c[q] = a[q]);
          };
          return c(g, t);
        };
        return function (g, t) {
          function r() {
            this.constructor = g;
          }
          c(g, t);
          g.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
        };
      }(),
      A = c.seriesTypes.line,
      B = g.merge,
      z = g.pick;
    g = function (c) {
      function g() {
        var g = null !== c && c.apply(this, arguments) || this;
        g.data = void 0;
        g.options = void 0;
        g.points = void 0;
        return g;
      }
      y(g, c);
      g.prototype.getPointSpline = function (c, g, a) {
        var q = g.plotX || 0,
          m = g.plotY || 0,
          p = c[a - 1];
        a = c[a + 1];
        if (p && !p.isNull && !1 !== p.doCurve && !g.isCliff && a && !a.isNull && !1 !== a.doCurve && !g.isCliff) {
          c = p.plotY || 0;
          var k = a.plotX || 0;
          a = a.plotY || 0;
          var e = 0;
          var r = (1.5 * q + (p.plotX || 0)) / 2.5;
          var t = (1.5 * m + c) / 2.5;
          k = (1.5 * q + k) / 2.5;
          var x = (1.5 * m + a) / 2.5;
          k !== r && (e = (x - t) * (k - q) / (k - r) + m - x);
          t += e;
          x += e;
          t > c && t > m ? (t = Math.max(c, m), x = 2 * m - t) : t < c && t < m && (t = Math.min(c, m), x = 2 * m - t);
          x > a && x > m ? (x = Math.max(a, m), t = 2 * m - x) : x < a && x < m && (x = Math.min(a, m), t = 2 * m - x);
          g.rightContX = k;
          g.rightContY = x;
        }
        g = ["C", z(p.rightContX, p.plotX, 0), z(p.rightContY, p.plotY, 0), z(r, q, 0), z(t, m, 0), q, m];
        p.rightContX = p.rightContY = void 0;
        return g;
      };
      g.defaultOptions = B(A.defaultOptions);
      return g;
    }(A);
    c.registerSeriesType("spline", g);
    "";
    return g;
  });
  I(g, "Series/AreaSpline/AreaSplineSeries.js", [g["Series/Spline/SplineSeries.js"], g["Core/Legend/LegendSymbol.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    var B = this && this.__extends || function () {
        var c = function (a, q) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var k in c) c.hasOwnProperty(k) && (a[k] = c[k]);
          };
          return c(a, q);
        };
        return function (a, q) {
          function m() {
            this.constructor = a;
          }
          c(a, q);
          a.prototype = null === q ? Object.create(q) : (m.prototype = q.prototype, new m());
        };
      }(),
      z = y.seriesTypes,
      C = z.area;
    z = z.area.prototype;
    var x = A.extend,
      t = A.merge;
    A = function (g) {
      function a() {
        var a = null !== g && g.apply(this, arguments) || this;
        a.data = void 0;
        a.points = void 0;
        a.options = void 0;
        return a;
      }
      B(a, g);
      a.defaultOptions = t(c.defaultOptions, C.defaultOptions);
      return a;
    }(c);
    x(A.prototype, {
      getGraphPath: z.getGraphPath,
      getStackPoints: z.getStackPoints,
      drawGraph: z.drawGraph,
      drawLegendSymbol: g.drawRectangle
    });
    y.registerSeriesType("areaspline", A);
    "";
    return A;
  });
  I(g, "Series/Column/ColumnSeriesDefaults.js", [], function () {
    "";

    return {
      borderRadius: 0,
      centerInCategory: !1,
      groupPadding: .2,
      marker: null,
      pointPadding: .1,
      minPointLength: 0,
      cropThreshold: 50,
      pointRange: null,
      states: {
        hover: {
          halo: !1,
          brightness: .1
        },
        select: {
          color: "#cccccc",
          borderColor: "#000000"
        }
      },
      dataLabels: {
        align: void 0,
        verticalAlign: void 0,
        y: void 0
      },
      startFromThreshold: !0,
      stickyTracking: !1,
      tooltip: {
        distance: 6
      },
      threshold: 0,
      borderColor: "#ffffff"
    };
  });
  I(g, "Series/Column/ColumnSeries.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Color/Color.js"], g["Series/Column/ColumnSeriesDefaults.js"], g["Core/Globals.js"], g["Core/Legend/LegendSymbol.js"], g["Core/Series/Series.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C, x) {
    var t = this && this.__extends || function () {
        var a = function (e, f) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, d) {
            a.__proto__ = d;
          } || function (a, d) {
            for (var b in d) d.hasOwnProperty(b) && (a[b] = d[b]);
          };
          return a(e, f);
        };
        return function (e, f) {
          function l() {
            this.constructor = e;
          }
          a(e, f);
          e.prototype = null === f ? Object.create(f) : (l.prototype = f.prototype, new l());
        };
      }(),
      r = c.animObject,
      a = g.parse,
      q = A.hasTouch;
    c = A.noop;
    var m = x.clamp,
      p = x.defined,
      k = x.extend,
      e = x.fireEvent,
      D = x.isArray,
      G = x.isNumber,
      H = x.merge,
      K = x.pick,
      u = x.objectEach;
    x = function (c) {
      function n() {
        var a = null !== c && c.apply(this, arguments) || this;
        a.borderWidth = void 0;
        a.data = void 0;
        a.group = void 0;
        a.options = void 0;
        a.points = void 0;
        return a;
      }
      t(n, c);
      n.prototype.animate = function (a) {
        var f = this,
          d = this.yAxis,
          b = f.options,
          h = this.chart.inverted,
          e = {},
          c = h ? "translateX" : "translateY";
        if (a) e.scaleY = .001, a = m(d.toPixels(b.threshold), d.pos, d.pos + d.len), h ? e.translateX = a - d.len : e.translateY = a, f.clipBox && f.setClip(), f.group.attr(e);else {
          var n = Number(f.group.attr(c));
          f.group.animate({
            scaleY: 1
          }, k(r(f.options.animation), {
            step: function (b, a) {
              f.group && (e[c] = n + a.pos * (d.pos - n), f.group.attr(e));
            }
          }));
        }
      };
      n.prototype.init = function (a, l) {
        c.prototype.init.apply(this, arguments);
        var d = this;
        a = d.chart;
        a.hasRendered && a.series.forEach(function (b) {
          b.type === d.type && (b.isDirty = !0);
        });
      };
      n.prototype.getColumnMetrics = function () {
        var a = this,
          l = a.options,
          d = a.xAxis,
          b = a.yAxis,
          h = d.options.reversedStacks;
        h = d.reversed && !h || !d.reversed && h;
        var e = {},
          c,
          k = 0;
        !1 === l.grouping ? k = 1 : a.chart.series.forEach(function (d) {
          var h = d.yAxis,
            f = d.options;
          if (d.type === a.type && (d.visible || !a.chart.options.chart.ignoreHiddenSeries) && b.len === h.len && b.pos === h.pos) {
            if (f.stacking && "group" !== f.stacking) {
              c = d.stackKey;
              "undefined" === typeof e[c] && (e[c] = k++);
              var l = e[c];
            } else !1 !== f.grouping && (l = k++);
            d.columnIndex = l;
          }
        });
        var n = Math.min(Math.abs(d.transA) * (d.ordinal && d.ordinal.slope || l.pointRange || d.closestPointRange || d.tickInterval || 1), d.len),
          m = n * l.groupPadding,
          p = (n - 2 * m) / (k || 1);
        l = Math.min(l.maxPointWidth || d.len, K(l.pointWidth, p * (1 - 2 * l.pointPadding)));
        a.columnMetrics = {
          width: l,
          offset: (p - l) / 2 + (m + ((a.columnIndex || 0) + (h ? 1 : 0)) * p - n / 2) * (h ? -1 : 1),
          paddedWidth: p,
          columnCount: k
        };
        return a.columnMetrics;
      };
      n.prototype.crispCol = function (a, l, d, b) {
        var h = this.chart,
          f = this.borderWidth,
          e = -(f % 2 ? .5 : 0);
        f = f % 2 ? .5 : 1;
        h.inverted && h.renderer.isVML && (f += 1);
        this.options.crisp && (d = Math.round(a + d) + e, a = Math.round(a) + e, d -= a);
        b = Math.round(l + b) + f;
        e = .5 >= Math.abs(l) && .5 < b;
        l = Math.round(l) + f;
        b -= l;
        e && b && (--l, b += 1);
        return {
          x: a,
          y: l,
          width: d,
          height: b
        };
      };
      n.prototype.adjustForMissingColumns = function (a, l, d, b) {
        var h = this,
          f = this.options.stacking;
        if (!d.isNull && 1 < b.columnCount) {
          var e = this.yAxis.options.reversedStacks,
            c = 0,
            k = e ? 0 : -b.columnCount;
          u(this.yAxis.stacking && this.yAxis.stacking.stacks, function (b) {
            if ("number" === typeof d.x) {
              var a = b[d.x.toString()];
              a && (b = a.points[h.index], f ? (b && (c = k), a.hasValidPoints && (e ? k++ : k--)) : D(b) && (b = Object.keys(a.points).filter(function (b) {
                return !b.match(",") && a.points[b] && 1 < a.points[b].length;
              }).map(parseFloat).sort(function (b, d) {
                return d - b;
              }), c = b.indexOf(h.index), k = b.length));
            }
          });
          a = (d.plotX || 0) + ((k - 1) * b.paddedWidth + l) / 2 - l - c * b.paddedWidth;
        }
        return a;
      };
      n.prototype.translate = function () {
        var a = this,
          l = a.chart,
          d = a.options,
          b = a.dense = 2 > a.closestPointRange * a.xAxis.transA;
        b = a.borderWidth = K(d.borderWidth, b ? 0 : 1);
        var h = a.xAxis,
          e = a.yAxis,
          c = d.threshold,
          k = a.translatedThreshold = e.getThreshold(c),
          n = K(d.minPointLength, 5),
          g = a.getColumnMetrics(),
          q = g.width,
          u = a.pointXOffset = g.offset,
          w = a.dataMin,
          r = a.dataMax,
          t = a.barW = Math.max(q, 1 + 2 * b);
        l.inverted && (k -= .5);
        d.pointPadding && (t = Math.ceil(t));
        z.prototype.translate.apply(a);
        a.points.forEach(function (b) {
          var f = K(b.yBottom, k),
            v = 999 + Math.abs(f),
            E = b.plotX || 0;
          v = m(b.plotY, -v, e.len + v);
          var F = Math.min(v, f),
            D = Math.max(v, f) - F,
            J = q,
            x = E + u,
            M = t;
          n && Math.abs(D) < n && (D = n, E = !e.reversed && !b.negative || e.reversed && b.negative, G(c) && G(r) && b.y === c && r <= c && (e.min || 0) < c && (w !== r || (e.max || 0) <= c) && (E = !E), F = Math.abs(F - k) > n ? f - n : k - (E ? n : 0));
          p(b.options.pointWidth) && (J = M = Math.ceil(b.options.pointWidth), x -= Math.round((J - q) / 2));
          d.centerInCategory && (x = a.adjustForMissingColumns(x, J, b, g));
          b.barX = x;
          b.pointWidth = J;
          b.tooltipPos = l.inverted ? [m(e.len + e.pos - l.plotLeft - v, e.pos - l.plotLeft, e.len + e.pos - l.plotLeft), h.len + h.pos - l.plotTop - x - M / 2, D] : [h.left - l.plotLeft + x + M / 2, m(v + e.pos - l.plotTop, e.pos - l.plotTop, e.len + e.pos - l.plotTop), D];
          b.shapeType = a.pointClass.prototype.shapeType || "rect";
          b.shapeArgs = a.crispCol.apply(a, b.isNull ? [x, k, M, 0] : [x, F, M, D]);
        });
      };
      n.prototype.drawGraph = function () {
        this.group[this.dense ? "addClass" : "removeClass"]("highcharts-dense-data");
      };
      n.prototype.pointAttribs = function (f, l) {
        var d = this.options,
          b = this.pointAttrToOptions || {},
          h = b.stroke || "borderColor",
          e = b["stroke-width"] || "borderWidth",
          c = f && f.color || this.color,
          k = f && f[h] || d[h] || c;
        b = f && f.options.dashStyle || d.dashStyle;
        var n = f && f[e] || d[e] || this[e] || 0,
          m = K(f && f.opacity, d.opacity, 1);
        if (f && this.zones.length) {
          var p = f.getZone();
          c = f.options.color || p && (p.color || f.nonZonedColor) || this.color;
          p && (k = p.borderColor || k, b = p.dashStyle || b, n = p.borderWidth || n);
        }
        l && f && (f = H(d.states[l], f.options.states && f.options.states[l] || {}), l = f.brightness, c = f.color || "undefined" !== typeof l && a(c).brighten(f.brightness).get() || c, k = f[h] || k, n = f[e] || n, b = f.dashStyle || b, m = K(f.opacity, m));
        h = {
          fill: c,
          stroke: k,
          "stroke-width": n,
          opacity: m
        };
        b && (h.dashstyle = b);
        return h;
      };
      n.prototype.drawPoints = function (a) {
        void 0 === a && (a = this.points);
        var f = this,
          d = this.chart,
          b = f.options,
          h = d.renderer,
          e = b.animationLimit || 250,
          c;
        a.forEach(function (a) {
          var l = a.graphic,
            k = !!l,
            n = l && d.pointCount < e ? "animate" : "attr";
          if (G(a.plotY) && null !== a.y) {
            c = a.shapeArgs;
            l && a.hasNewShapeType() && (l = l.destroy());
            f.enabledDataSorting && (a.startXPos = f.xAxis.reversed ? -(c ? c.width || 0 : 0) : f.xAxis.width);
            l || (a.graphic = l = h[a.shapeType](c).add(a.group || f.group)) && f.enabledDataSorting && d.hasRendered && d.pointCount < e && (l.attr({
              x: a.startXPos
            }), k = !0, n = "animate");
            if (l && k) l[n](H(c));
            if (b.borderRadius) l[n]({
              r: b.borderRadius
            });
            d.styledMode || l[n](f.pointAttribs(a, a.selected && "select")).shadow(!1 !== a.allowShadow && b.shadow, null, b.stacking && !b.borderRadius);
            l && (l.addClass(a.getClassName(), !0), l.attr({
              visibility: a.visible ? "inherit" : "hidden"
            }));
          } else l && (a.graphic = l.destroy());
        });
      };
      n.prototype.drawTracker = function (a) {
        void 0 === a && (a = this.points);
        var f = this,
          d = f.chart,
          b = d.pointer,
          h = function (d) {
            var a = b.getPointFromEvent(d);
            "undefined" !== typeof a && (b.isDirectTouch = !0, a.onMouseOver(d));
          },
          c;
        a.forEach(function (b) {
          c = D(b.dataLabels) ? b.dataLabels : b.dataLabel ? [b.dataLabel] : [];
          b.graphic && (b.graphic.element.point = b);
          c.forEach(function (d) {
            d.div ? d.div.point = b : d.element.point = b;
          });
        });
        f._hasTracking || (f.trackerGroups.forEach(function (a) {
          if (f[a]) {
            f[a].addClass("highcharts-tracker").on("mouseover", h).on("mouseout", function (d) {
              b.onTrackerMouseOut(d);
            });
            if (q) f[a].on("touchstart", h);
            !d.styledMode && f.options.cursor && f[a].css({
              cursor: f.options.cursor
            });
          }
        }), f._hasTracking = !0);
        e(this, "afterDrawTracker");
      };
      n.prototype.remove = function () {
        var a = this,
          l = a.chart;
        l.hasRendered && l.series.forEach(function (d) {
          d.type === a.type && (d.isDirty = !0);
        });
        z.prototype.remove.apply(a, arguments);
      };
      n.defaultOptions = H(z.defaultOptions, y);
      return n;
    }(z);
    k(x.prototype, {
      cropShoulder: 0,
      directTouch: !0,
      drawLegendSymbol: B.drawRectangle,
      getSymbol: c,
      negStacks: !0,
      trackerGroups: ["group", "dataLabelsGroup"]
    });
    C.registerSeriesType("column", x);
    "";
    return x;
  });
  I(g, "Core/Series/DataLabel.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/FormatUtilities.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = c.getDeferredAnimation,
      B = g.format,
      z = y.defined,
      C = y.extend,
      x = y.fireEvent,
      t = y.isArray,
      r = y.isString,
      a = y.merge,
      q = y.objectEach,
      m = y.pick,
      p = y.splat,
      k;
    (function (e) {
      function c(a, l, d, b, h) {
        var f = this,
          e = this.chart,
          c = this.isCartesian && e.inverted,
          k = this.enabledDataSorting,
          n = a.plotX,
          p = a.plotY,
          g = d.rotation,
          q = d.align,
          u = z(n) && z(p) && e.isInsidePlot(n, Math.round(p), {
            inverted: c,
            paneCoordinates: !0,
            series: f
          });
        p = function (b) {
          k && f.xAxis && !w && f.setDataLabelStartPos(a, l, h, u, b);
        };
        var w = "justify" === m(d.overflow, k ? "none" : "justify");
        n = this.visible && !1 !== a.visible && z(n) && (a.series.forceDL || k && !w || u || m(d.inside, !!this.options.stacking) && b && e.isInsidePlot(n, c ? b.x + 1 : b.y + b.height - 1, {
          inverted: c,
          paneCoordinates: !0,
          series: f
        }));
        c = a.pos();
        if (n && c) {
          g && l.attr({
            align: q
          });
          q = l.getBBox(!0);
          var r = [0, 0];
          var t = e.renderer.fontMetrics(e.styledMode ? void 0 : d.style.fontSize, l).b;
          b = C({
            x: c[0],
            y: Math.round(c[1]),
            width: 0,
            height: 0
          }, b);
          C(d, {
            width: q.width,
            height: q.height
          });
          g ? (w = !1, r = e.renderer.rotCorr(t, g), t = {
            x: b.x + (d.x || 0) + b.width / 2 + r.x,
            y: b.y + (d.y || 0) + {
              top: 0,
              middle: .5,
              bottom: 1
            }[d.verticalAlign] * b.height
          }, r = [q.x - Number(l.attr("x")), q.y - Number(l.attr("y"))], p(t), l[h ? "attr" : "animate"](t)) : (p(b), l.align(d, void 0, b), t = l.alignAttr);
          w && 0 <= b.height ? this.justifyDataLabel(l, d, t, q, b, h) : m(d.crop, !0) && (b = t.x, p = t.y, b += r[0], p += r[1], n = e.isInsidePlot(b, p, {
            paneCoordinates: !0,
            series: f
          }) && e.isInsidePlot(b + q.width, p + q.height, {
            paneCoordinates: !0,
            series: f
          }));
          if (d.shape && !g) l[h ? "attr" : "animate"]({
            anchorX: c[0],
            anchorY: c[1]
          });
        }
        h && k && (l.placed = !1);
        n || k && !w ? l.show() : (l.hide(), l.placed = !1);
      }
      function k(a, l) {
        var d = l.filter;
        return d ? (l = d.operator, a = a[d.property], d = d.value, ">" === l && a > d || "<" === l && a < d || ">=" === l && a >= d || "<=" === l && a <= d || "==" === l && a == d || "===" === l && a === d ? !0 : !1) : !0;
      }
      function g(a) {
        void 0 === a && (a = this.points);
        var f = this,
          d = f.chart,
          b = f.options,
          h = f.hasRendered || 0,
          e = d.renderer,
          c = d.options.chart,
          n = c.backgroundColor;
        c = c.plotBackgroundColor;
        var g = e.getContrast(r(c) && c || r(n) && n || "#000000"),
          w = b.dataLabels,
          D;
        n = w.animation;
        n = w.defer ? A(d, n, f) : {
          defer: 0,
          duration: 0
        };
        w = u(u(d.options.plotOptions && d.options.plotOptions.series && d.options.plotOptions.series.dataLabels, d.options.plotOptions && d.options.plotOptions[f.type] && d.options.plotOptions[f.type].dataLabels), w);
        x(this, "drawDataLabels");
        if (t(w) || w.enabled || f._hasPointLabels) {
          var G = f.plotGroup("dataLabelsGroup", "data-labels", h ? "inherit" : "hidden", w.zIndex || 6);
          G.attr({
            opacity: +h
          });
          !h && (h = f.dataLabelsGroup) && (f.visible && G.show(), h[b.animation ? "animate" : "attr"]({
            opacity: 1
          }, n));
          a.forEach(function (a) {
            D = p(u(w, a.dlOptions || a.options && a.options.dataLabels));
            D.forEach(function (h, l) {
              var c = h.enabled && (!a.isNull || a.dataLabelOnNull) && k(a, h),
                n = a.connectors ? a.connectors[l] : a.connector,
                p = a.dataLabels ? a.dataLabels[l] : a.dataLabel,
                v = !p,
                w = m(h.distance, a.labelDistance);
              if (c) {
                var u = a.getLabelConfig();
                var r = m(h[a.formatPrefix + "Format"], h.format);
                u = z(r) ? B(r, u, d) : (h[a.formatPrefix + "Formatter"] || h.formatter).call(u, h);
                r = h.style;
                var t = h.rotation;
                d.styledMode || (r.color = m(h.color, r.color, f.color, "#000000"), "contrast" === r.color ? (a.contrastColor = e.getContrast(a.color || f.color), r.color = !z(w) && h.inside || 0 > w || b.stacking ? a.contrastColor : g) : delete a.contrastColor, b.cursor && (r.cursor = b.cursor));
                var E = {
                  r: h.borderRadius || 0,
                  rotation: t,
                  padding: h.padding,
                  zIndex: 1
                };
                if (!d.styledMode) {
                  w = h.backgroundColor;
                  var D = h.borderColor;
                  E.fill = "auto" === w ? a.color : w;
                  E.stroke = "auto" === D ? a.color : D;
                  E["stroke-width"] = h.borderWidth;
                }
                q(E, function (b, a) {
                  "undefined" === typeof b && delete E[a];
                });
              }
              !p || c && z(u) && !!p.div === !!h.useHTML && (p.rotation && h.rotation || p.rotation === h.rotation) || (v = !0, a.dataLabel = p = a.dataLabel && a.dataLabel.destroy(), a.dataLabels && (1 === a.dataLabels.length ? delete a.dataLabels : delete a.dataLabels[l]), l || delete a.dataLabel, n && (a.connector = a.connector.destroy(), a.connectors && (1 === a.connectors.length ? delete a.connectors : delete a.connectors[l])));
              c && z(u) ? (p ? E.text = u : (a.dataLabels = a.dataLabels || [], p = a.dataLabels[l] = t ? e.text(u, 0, 0, h.useHTML).addClass("highcharts-data-label") : e.label(u, 0, 0, h.shape, null, null, h.useHTML, null, "data-label"), l || (a.dataLabel = p), p.addClass(" highcharts-data-label-color-" + a.colorIndex + " " + (h.className || "") + (h.useHTML ? " highcharts-tracker" : ""))), p.options = h, p.attr(E), d.styledMode || p.css(r).shadow(h.shadow), (l = h[a.formatPrefix + "TextPath"] || h.textPath) && !h.useHTML && (p.setTextPath(a.getDataLabelPath && a.getDataLabelPath(p) || a.graphic, l), a.dataLabelPath && !l.enabled && (a.dataLabelPath = a.dataLabelPath.destroy())), p.added || p.add(G), f.alignDataLabel(a, p, h, null, v)) : p && p.hide();
            });
          });
        }
        x(this, "afterDrawDataLabels");
      }
      function y(a, e, d, b, h, c) {
        var f = this.chart,
          l = e.align,
          k = e.verticalAlign,
          n = a.box ? 0 : a.padding || 0,
          m = e.x;
        m = void 0 === m ? 0 : m;
        var p = e.y;
        p = void 0 === p ? 0 : p;
        var v = (d.x || 0) + n;
        if (0 > v) {
          "right" === l && 0 <= m ? (e.align = "left", e.inside = !0) : m -= v;
          var g = !0;
        }
        v = (d.x || 0) + b.width - n;
        v > f.plotWidth && ("left" === l && 0 >= m ? (e.align = "right", e.inside = !0) : m += f.plotWidth - v, g = !0);
        v = d.y + n;
        0 > v && ("bottom" === k && 0 <= p ? (e.verticalAlign = "top", e.inside = !0) : p -= v, g = !0);
        v = (d.y || 0) + b.height - n;
        v > f.plotHeight && ("top" === k && 0 >= p ? (e.verticalAlign = "bottom", e.inside = !0) : p += f.plotHeight - v, g = !0);
        g && (e.x = m, e.y = p, a.placed = !c, a.align(e, void 0, h));
        return g;
      }
      function u(f, e) {
        var d = [],
          b;
        if (t(f) && !t(e)) d = f.map(function (b) {
          return a(b, e);
        });else if (t(e) && !t(f)) d = e.map(function (b) {
          return a(f, b);
        });else if (t(f) || t(e)) for (b = Math.max(f.length, e.length); b--;) d[b] = a(f[b], e[b]);else d = a(f, e);
        return d;
      }
      function n(a, e, d, b, h) {
        var f = this.chart,
          l = f.inverted,
          c = this.xAxis,
          k = c.reversed,
          n = l ? e.height / 2 : e.width / 2;
        a = (a = a.pointWidth) ? a / 2 : 0;
        e.startXPos = l ? h.x : k ? -n - a : c.width - n + a;
        e.startYPos = l ? k ? this.yAxis.height - n + a : -n - a : h.y;
        b ? "hidden" === e.visibility && (e.show(), e.attr({
          opacity: 0
        }).animate({
          opacity: 1
        })) : e.attr({
          opacity: 1
        }).animate({
          opacity: 0
        }, void 0, e.hide);
        f.hasRendered && (d && e.attr({
          x: e.startXPos,
          y: e.startYPos
        }), e.placed = !0);
      }
      var w = [];
      e.compose = function (a) {
        if (-1 === w.indexOf(a)) {
          var f = a.prototype;
          w.push(a);
          f.alignDataLabel = c;
          f.drawDataLabels = g;
          f.justifyDataLabel = y;
          f.setDataLabelStartPos = n;
        }
      };
    })(k || (k = {}));
    "";
    return k;
  });
  I(g, "Series/Column/ColumnDataLabel.js", [g["Core/Series/DataLabel.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = g.series,
      B = y.merge,
      z = y.pick,
      C;
    (function (g) {
      function t(a, c, m, p, k) {
        var e = this.chart.inverted,
          g = a.series,
          q = (g.xAxis ? g.xAxis.len : this.chart.plotSizeX) || 0;
        g = (g.yAxis ? g.yAxis.len : this.chart.plotSizeY) || 0;
        var r = a.dlBox || a.shapeArgs,
          t = z(a.below, a.plotY > z(this.translatedThreshold, g)),
          u = z(m.inside, !!this.options.stacking);
        r && (p = B(r), 0 > p.y && (p.height += p.y, p.y = 0), r = p.y + p.height - g, 0 < r && r < p.height && (p.height -= r), e && (p = {
          x: g - p.y - p.height,
          y: q - p.x - p.width,
          width: p.height,
          height: p.width
        }), u || (e ? (p.x += t ? 0 : p.width, p.width = 0) : (p.y += t ? p.height : 0, p.height = 0)));
        m.align = z(m.align, !e || u ? "center" : t ? "right" : "left");
        m.verticalAlign = z(m.verticalAlign, e || u ? "middle" : t ? "top" : "bottom");
        A.prototype.alignDataLabel.call(this, a, c, m, p, k);
        m.inside && a.contrastColor && c.css({
          color: a.contrastColor
        });
      }
      var r = [];
      g.compose = function (a) {
        c.compose(A);
        -1 === r.indexOf(a) && (r.push(a), a.prototype.alignDataLabel = t);
      };
    })(C || (C = {}));
    return C;
  });
  I(g, "Series/Bar/BarSeries.js", [g["Series/Column/ColumnSeries.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = this && this.__extends || function () {
        var c = function (g, t) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, a) {
            c.__proto__ = a;
          } || function (c, a) {
            for (var g in a) a.hasOwnProperty(g) && (c[g] = a[g]);
          };
          return c(g, t);
        };
        return function (g, t) {
          function r() {
            this.constructor = g;
          }
          c(g, t);
          g.prototype = null === t ? Object.create(t) : (r.prototype = t.prototype, new r());
        };
      }(),
      B = y.extend,
      z = y.merge;
    y = function (g) {
      function x() {
        var c = null !== g && g.apply(this, arguments) || this;
        c.data = void 0;
        c.options = void 0;
        c.points = void 0;
        return c;
      }
      A(x, g);
      x.defaultOptions = z(c.defaultOptions, {});
      return x;
    }(c);
    B(y.prototype, {
      inverted: !0
    });
    g.registerSeriesType("bar", y);
    "";
    return y;
  });
  I(g, "Series/Scatter/ScatterSeriesDefaults.js", [], function () {
    "";

    return {
      lineWidth: 0,
      findNearestPointBy: "xy",
      jitter: {
        x: 0,
        y: 0
      },
      marker: {
        enabled: !0
      },
      tooltip: {
        headerFormat: '<span style="color:{point.color}">\u25cf</span> <span style="font-size: 10px"> {series.name}</span><br/>',
        pointFormat: "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
      }
    };
  });
  I(g, "Series/Scatter/ScatterSeries.js", [g["Series/Scatter/ScatterSeriesDefaults.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = this && this.__extends || function () {
        var c = function (a, g) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var k in c) c.hasOwnProperty(k) && (a[k] = c[k]);
          };
          return c(a, g);
        };
        return function (a, g) {
          function m() {
            this.constructor = a;
          }
          c(a, g);
          a.prototype = null === g ? Object.create(g) : (m.prototype = g.prototype, new m());
        };
      }(),
      B = g.seriesTypes,
      z = B.column,
      C = B.line;
    B = y.addEvent;
    var x = y.extend,
      t = y.merge;
    y = function (g) {
      function a() {
        var a = null !== g && g.apply(this, arguments) || this;
        a.data = void 0;
        a.options = void 0;
        a.points = void 0;
        return a;
      }
      A(a, g);
      a.prototype.applyJitter = function () {
        var a = this,
          c = this.options.jitter,
          g = this.points.length;
        c && this.points.forEach(function (k, e) {
          ["x", "y"].forEach(function (m, p) {
            var q = "plot" + m.toUpperCase();
            if (c[m] && !k.isNull) {
              var r = a[m + "Axis"];
              var u = c[m] * r.transA;
              if (r && !r.isLog) {
                var n = Math.max(0, k[q] - u);
                r = Math.min(r.len, k[q] + u);
                p = 1E4 * Math.sin(e + p * g);
                k[q] = n + (r - n) * (p - Math.floor(p));
                "x" === m && (k.clientX = k.plotX);
              }
            }
          });
        });
      };
      a.prototype.drawGraph = function () {
        this.options.lineWidth ? g.prototype.drawGraph.call(this) : this.graph && (this.graph = this.graph.destroy());
      };
      a.defaultOptions = t(C.defaultOptions, c);
      return a;
    }(C);
    x(y.prototype, {
      drawTracker: z.prototype.drawTracker,
      sorted: !1,
      requireSorting: !1,
      noSharedTooltip: !0,
      trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
      takeOrdinalPosition: !1
    });
    B(y, "afterTranslate", function () {
      this.applyJitter();
    });
    g.registerSeriesType("scatter", y);
    return y;
  });
  I(g, "Series/CenteredUtilities.js", [g["Core/Globals.js"], g["Core/Series/Series.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = c.deg2rad,
      B = y.fireEvent,
      z = y.isNumber,
      C = y.pick,
      x = y.relativeLength,
      t;
    (function (c) {
      c.getCenter = function () {
        var a = this.options,
          c = this.chart,
          m = 2 * (a.slicedOffset || 0),
          p = c.plotWidth - 2 * m,
          k = c.plotHeight - 2 * m,
          e = a.center,
          r = Math.min(p, k),
          t = a.thickness,
          y = a.size,
          A = a.innerSize || 0;
        "string" === typeof y && (y = parseFloat(y));
        "string" === typeof A && (A = parseFloat(A));
        a = [C(e[0], "50%"), C(e[1], "50%"), C(y && 0 > y ? void 0 : a.size, "100%"), C(A && 0 > A ? void 0 : a.innerSize || 0, "0%")];
        !c.angular || this instanceof g || (a[3] = 0);
        for (e = 0; 4 > e; ++e) y = a[e], c = 2 > e || 2 === e && /%$/.test(y), a[e] = x(y, [p, k, r, a[2]][e]) + (c ? m : 0);
        a[3] > a[2] && (a[3] = a[2]);
        z(t) && 2 * t < a[2] && 0 < t && (a[3] = a[2] - 2 * t);
        B(this, "afterGetCenter", {
          positions: a
        });
        return a;
      };
      c.getStartAndEndRadians = function (a, c) {
        a = z(a) ? a : 0;
        c = z(c) && c > a && 360 > c - a ? c : a + 360;
        return {
          start: A * (a + -90),
          end: A * (c + -90)
        };
      };
    })(t || (t = {}));
    "";
    return t;
  });
  I(g, "Series/Pie/PiePoint.js", [g["Core/Animation/AnimationUtilities.js"], g["Core/Series/Point.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = this && this.__extends || function () {
        var a = function (c, m) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var e in c) c.hasOwnProperty(e) && (a[e] = c[e]);
          };
          return a(c, m);
        };
        return function (c, m) {
          function g() {
            this.constructor = c;
          }
          a(c, m);
          c.prototype = null === m ? Object.create(m) : (g.prototype = m.prototype, new g());
        };
      }(),
      B = c.setAnimation,
      z = y.addEvent,
      C = y.defined;
    c = y.extend;
    var x = y.isNumber,
      t = y.pick,
      r = y.relativeLength;
    g = function (a) {
      function c() {
        var c = null !== a && a.apply(this, arguments) || this;
        c.labelDistance = void 0;
        c.options = void 0;
        c.series = void 0;
        return c;
      }
      A(c, a);
      c.prototype.getConnectorPath = function () {
        var a = this.labelPosition,
          c = this.series.options.dataLabels,
          k = this.connectorShapes,
          e = c.connectorShape;
        k[e] && (e = k[e]);
        return e.call(this, {
          x: a.final.x,
          y: a.final.y,
          alignment: a.alignment
        }, a.connectorPosition, c);
      };
      c.prototype.getTranslate = function () {
        return this.sliced ? this.slicedTranslation : {
          translateX: 0,
          translateY: 0
        };
      };
      c.prototype.haloPath = function (a) {
        var c = this.shapeArgs;
        return this.sliced || !this.visible ? [] : this.series.chart.renderer.symbols.arc(c.x, c.y, c.r + a, c.r + a, {
          innerR: c.r - 1,
          start: c.start,
          end: c.end
        });
      };
      c.prototype.init = function () {
        var c = this;
        a.prototype.init.apply(this, arguments);
        this.name = t(this.name, "Slice");
        var g = function (a) {
          c.slice("select" === a.type);
        };
        z(this, "select", g);
        z(this, "unselect", g);
        return this;
      };
      c.prototype.isValid = function () {
        return x(this.y) && 0 <= this.y;
      };
      c.prototype.setVisible = function (a, c) {
        var k = this,
          e = this.series,
          g = e.chart,
          m = e.options.ignoreHiddenPoint;
        c = t(c, m);
        a !== this.visible && (this.visible = this.options.visible = a = "undefined" === typeof a ? !this.visible : a, e.options.data[e.data.indexOf(this)] = this.options, ["graphic", "dataLabel", "connector", "shadowGroup"].forEach(function (e) {
          if (k[e]) k[e][a ? "show" : "hide"](a);
        }), this.legendItem && g.legend.colorizeItem(this, a), a || "hover" !== this.state || this.setState(""), m && (e.isDirty = !0), c && g.redraw());
      };
      c.prototype.slice = function (a, c, k) {
        var e = this.series;
        B(k, e.chart);
        t(c, !0);
        this.sliced = this.options.sliced = C(a) ? a : !this.sliced;
        e.options.data[e.data.indexOf(this)] = this.options;
        this.graphic && this.graphic.animate(this.getTranslate());
        this.shadowGroup && this.shadowGroup.animate(this.getTranslate());
      };
      return c;
    }(g);
    c(g.prototype, {
      connectorShapes: {
        fixedOffset: function (a, c, g) {
          var m = c.breakAt;
          c = c.touchingSliceAt;
          return [["M", a.x, a.y], g.softConnector ? ["C", a.x + ("left" === a.alignment ? -5 : 5), a.y, 2 * m.x - c.x, 2 * m.y - c.y, m.x, m.y] : ["L", m.x, m.y], ["L", c.x, c.y]];
        },
        straight: function (a, c) {
          c = c.touchingSliceAt;
          return [["M", a.x, a.y], ["L", c.x, c.y]];
        },
        crookedLine: function (a, c, g) {
          c = c.touchingSliceAt;
          var m = this.series,
            k = m.center[0],
            e = m.chart.plotWidth,
            q = m.chart.plotLeft;
          m = a.alignment;
          var t = this.shapeArgs.r;
          g = r(g.crookDistance, 1);
          e = "left" === m ? k + t + (e + q - k - t) * (1 - g) : q + (k - t) * g;
          g = ["L", e, a.y];
          k = !0;
          if ("left" === m ? e > a.x || e < c.x : e < a.x || e > c.x) k = !1;
          a = [["M", a.x, a.y]];
          k && a.push(g);
          a.push(["L", c.x, c.y]);
          return a;
        }
      }
    });
    return g;
  });
  I(g, "Series/Pie/PieSeriesDefaults.js", [], function () {
    "";

    return {
      center: [null, null],
      clip: !1,
      colorByPoint: !0,
      dataLabels: {
        allowOverlap: !0,
        connectorPadding: 5,
        connectorShape: "fixedOffset",
        crookDistance: "70%",
        distance: 30,
        enabled: !0,
        formatter: function () {
          return this.point.isNull ? void 0 : this.point.name;
        },
        softConnector: !0,
        x: 0
      },
      fillColor: void 0,
      ignoreHiddenPoint: !0,
      inactiveOtherPoints: !0,
      legendType: "point",
      marker: null,
      size: null,
      showInLegend: !1,
      slicedOffset: 10,
      stickyTracking: !1,
      tooltip: {
        followPointer: !0
      },
      borderColor: "#ffffff",
      borderWidth: 1,
      lineWidth: void 0,
      states: {
        hover: {
          brightness: .1
        }
      }
    };
  });
  I(g, "Series/Pie/PieSeries.js", [g["Series/CenteredUtilities.js"], g["Series/Column/ColumnSeries.js"], g["Core/Globals.js"], g["Core/Legend/LegendSymbol.js"], g["Series/Pie/PiePoint.js"], g["Series/Pie/PieSeriesDefaults.js"], g["Core/Series/Series.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Renderer/SVG/Symbols.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C, x, t, r) {
    var a = this && this.__extends || function () {
        var a = function (e, c) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, e) {
            a.__proto__ = e;
          } || function (a, e) {
            for (var f in e) e.hasOwnProperty(f) && (a[f] = e[f]);
          };
          return a(e, c);
        };
        return function (e, c) {
          function k() {
            this.constructor = e;
          }
          a(e, c);
          e.prototype = null === c ? Object.create(c) : (k.prototype = c.prototype, new k());
        };
      }(),
      q = c.getStartAndEndRadians;
    y = y.noop;
    var m = r.clamp,
      p = r.extend,
      k = r.fireEvent,
      e = r.merge,
      D = r.pick,
      G = r.relativeLength;
    r = function (c) {
      function g() {
        var a = null !== c && c.apply(this, arguments) || this;
        a.center = void 0;
        a.data = void 0;
        a.maxLabelDistance = void 0;
        a.options = void 0;
        a.points = void 0;
        return a;
      }
      a(g, c);
      g.prototype.animate = function (a) {
        var e = this,
          c = e.points,
          f = e.startAngleRad;
        a || c.forEach(function (a) {
          var d = a.graphic,
            b = a.shapeArgs;
          d && b && (d.attr({
            r: D(a.startR, e.center && e.center[3] / 2),
            start: f,
            end: f
          }), d.animate({
            r: b.r,
            start: b.start,
            end: b.end
          }, e.options.animation));
        });
      };
      g.prototype.drawEmpty = function () {
        var a = this.startAngleRad,
          e = this.endAngleRad,
          c = this.options;
        if (0 === this.total && this.center) {
          var f = this.center[0];
          var l = this.center[1];
          this.graph || (this.graph = this.chart.renderer.arc(f, l, this.center[1] / 2, 0, a, e).addClass("highcharts-empty-series").add(this.group));
          this.graph.attr({
            d: t.arc(f, l, this.center[2] / 2, 0, {
              start: a,
              end: e,
              innerR: this.center[3] / 2
            })
          });
          this.chart.styledMode || this.graph.attr({
            "stroke-width": c.borderWidth,
            fill: c.fillColor || "none",
            stroke: c.color || "#cccccc"
          });
        } else this.graph && (this.graph = this.graph.destroy());
      };
      g.prototype.drawPoints = function () {
        var a = this.chart.renderer;
        this.points.forEach(function (e) {
          e.graphic && e.hasNewShapeType() && (e.graphic = e.graphic.destroy());
          e.graphic || (e.graphic = a[e.shapeType](e.shapeArgs).add(e.series.group), e.delayedRendering = !0);
        });
      };
      g.prototype.generatePoints = function () {
        c.prototype.generatePoints.call(this);
        this.updateTotals();
      };
      g.prototype.getX = function (a, e, c) {
        var f = this.center,
          l = this.radii ? this.radii[c.index] || 0 : f[2] / 2;
        a = Math.asin(m((a - f[1]) / (l + c.labelDistance), -1, 1));
        return f[0] + (e ? -1 : 1) * Math.cos(a) * (l + c.labelDistance) + (0 < c.labelDistance ? (e ? -1 : 1) * this.options.dataLabels.padding : 0);
      };
      g.prototype.hasData = function () {
        return !!this.processedXData.length;
      };
      g.prototype.redrawPoints = function () {
        var a = this,
          c = a.chart,
          k = c.renderer,
          f = a.options.shadow,
          l,
          d,
          b,
          h;
        this.drawEmpty();
        !f || a.shadowGroup || c.styledMode || (a.shadowGroup = k.g("shadow").attr({
          zIndex: -1
        }).add(a.group));
        a.points.forEach(function (n) {
          var g = {};
          d = n.graphic;
          if (!n.isNull && d) {
            var m = void 0;
            h = n.shapeArgs;
            l = n.getTranslate();
            c.styledMode || (m = n.shadowGroup, f && !m && (m = n.shadowGroup = k.g("shadow").add(a.shadowGroup)), m && m.attr(l), b = a.pointAttribs(n, n.selected && "select"));
            n.delayedRendering ? (d.setRadialReference(a.center).attr(h).attr(l), c.styledMode || d.attr(b).attr({
              "stroke-linejoin": "round"
            }).shadow(f, m), n.delayedRendering = !1) : (d.setRadialReference(a.center), c.styledMode || e(!0, g, b), e(!0, g, h, l), d.animate(g));
            d.attr({
              visibility: n.visible ? "inherit" : "hidden"
            });
            d.addClass(n.getClassName(), !0);
          } else d && (n.graphic = d.destroy());
        });
      };
      g.prototype.sortByAngle = function (a, e) {
        a.sort(function (a, f) {
          return "undefined" !== typeof a.angle && (f.angle - a.angle) * e;
        });
      };
      g.prototype.translate = function (a) {
        k(this, "translate");
        this.generatePoints();
        var e = this.options,
          c = e.slicedOffset,
          f = c + (e.borderWidth || 0),
          l = q(e.startAngle, e.endAngle),
          d = this.startAngleRad = l.start;
        l = (this.endAngleRad = l.end) - d;
        var b = this.points,
          h = e.dataLabels.distance;
        e = e.ignoreHiddenPoint;
        var g = b.length,
          m,
          p = 0;
        a || (this.center = a = this.getCenter());
        for (m = 0; m < g; m++) {
          var u = b[m];
          var r = d + p * l;
          !u.isValid() || e && !u.visible || (p += u.percentage / 100);
          var t = d + p * l;
          var x = {
            x: a[0],
            y: a[1],
            r: a[2] / 2,
            innerR: a[3] / 2,
            start: Math.round(1E3 * r) / 1E3,
            end: Math.round(1E3 * t) / 1E3
          };
          u.shapeType = "arc";
          u.shapeArgs = x;
          u.labelDistance = D(u.options.dataLabels && u.options.dataLabels.distance, h);
          u.labelDistance = G(u.labelDistance, x.r);
          this.maxLabelDistance = Math.max(this.maxLabelDistance || 0, u.labelDistance);
          t = (t + r) / 2;
          t > 1.5 * Math.PI ? t -= 2 * Math.PI : t < -Math.PI / 2 && (t += 2 * Math.PI);
          u.slicedTranslation = {
            translateX: Math.round(Math.cos(t) * c),
            translateY: Math.round(Math.sin(t) * c)
          };
          x = Math.cos(t) * a[2] / 2;
          var F = Math.sin(t) * a[2] / 2;
          u.tooltipPos = [a[0] + .7 * x, a[1] + .7 * F];
          u.half = t < -Math.PI / 2 || t > Math.PI / 2 ? 1 : 0;
          u.angle = t;
          r = Math.min(f, u.labelDistance / 5);
          u.labelPosition = {
            natural: {
              x: a[0] + x + Math.cos(t) * u.labelDistance,
              y: a[1] + F + Math.sin(t) * u.labelDistance
            },
            "final": {},
            alignment: 0 > u.labelDistance ? "center" : u.half ? "right" : "left",
            connectorPosition: {
              breakAt: {
                x: a[0] + x + Math.cos(t) * r,
                y: a[1] + F + Math.sin(t) * r
              },
              touchingSliceAt: {
                x: a[0] + x,
                y: a[1] + F
              }
            }
          };
        }
        k(this, "afterTranslate");
      };
      g.prototype.updateTotals = function () {
        var a = this.points,
          e = a.length,
          c = this.options.ignoreHiddenPoint,
          f,
          l = 0;
        for (f = 0; f < e; f++) {
          var d = a[f];
          !d.isValid() || c && !d.visible || (l += d.y);
        }
        this.total = l;
        for (f = 0; f < e; f++) d = a[f], d.percentage = 0 < l && (d.visible || !c) ? d.y / l * 100 : 0, d.total = l;
      };
      g.defaultOptions = e(C.defaultOptions, z);
      return g;
    }(C);
    p(r.prototype, {
      axisTypes: [],
      directTouch: !0,
      drawGraph: void 0,
      drawLegendSymbol: A.drawRectangle,
      drawTracker: g.prototype.drawTracker,
      getCenter: c.getCenter,
      getSymbol: y,
      isCartesian: !1,
      noSharedTooltip: !0,
      pointAttribs: g.prototype.pointAttribs,
      pointClass: B,
      requireSorting: !1,
      searchPoint: y,
      trackerGroups: ["group", "dataLabelsGroup"]
    });
    x.registerSeriesType("pie", r);
    return r;
  });
  I(g, "Series/Pie/PieDataLabel.js", [g["Core/Series/DataLabel.js"], g["Core/Globals.js"], g["Core/Renderer/RendererUtilities.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A, B) {
    var z = g.noop,
      C = y.distribute,
      x = A.series,
      t = B.arrayMax,
      r = B.clamp,
      a = B.defined,
      q = B.merge,
      m = B.pick,
      p = B.relativeLength,
      k;
    (function (e) {
      function k() {
        var e = this,
          c = e.data,
          f = e.chart,
          l = e.options.dataLabels || {},
          d = l.connectorPadding,
          b = f.plotWidth,
          h = f.plotHeight,
          k = f.plotLeft,
          g = Math.round(f.chartWidth / 3),
          p = e.center,
          u = p[2] / 2,
          r = p[1],
          D = [[], []],
          G = [0, 0, 0, 0],
          F = e.dataLabelPositioners,
          y,
          z,
          B,
          H,
          A,
          L,
          K,
          I,
          V,
          U,
          Y,
          S;
        e.visible && (l.enabled || e._hasPointLabels) && (c.forEach(function (b) {
          b.dataLabel && b.visible && b.dataLabel.shortened && (b.dataLabel.attr({
            width: "auto"
          }).css({
            width: "auto",
            textOverflow: "clip"
          }), b.dataLabel.shortened = !1);
        }), x.prototype.drawDataLabels.apply(e), c.forEach(function (b) {
          b.dataLabel && (b.visible ? (D[b.half].push(b), b.dataLabel._pos = null, !a(l.style.width) && !a(b.options.dataLabels && b.options.dataLabels.style && b.options.dataLabels.style.width) && b.dataLabel.getBBox().width > g && (b.dataLabel.css({
            width: Math.round(.7 * g) + "px"
          }), b.dataLabel.shortened = !0)) : (b.dataLabel = b.dataLabel.destroy(), b.dataLabels && 1 === b.dataLabels.length && delete b.dataLabels));
        }), D.forEach(function (c, n) {
          var g = c.length,
            v = [],
            q;
          if (g) {
            e.sortByAngle(c, n - .5);
            if (0 < e.maxLabelDistance) {
              var w = Math.max(0, r - u - e.maxLabelDistance);
              var t = Math.min(r + u + e.maxLabelDistance, f.plotHeight);
              c.forEach(function (b) {
                0 < b.labelDistance && b.dataLabel && (b.top = Math.max(0, r - u - b.labelDistance), b.bottom = Math.min(r + u + b.labelDistance, f.plotHeight), q = b.dataLabel.getBBox().height || 21, b.distributeBox = {
                  target: b.labelPosition.natural.y - b.top + q / 2,
                  size: q,
                  rank: b.y
                }, v.push(b.distributeBox));
              });
              w = t + q - w;
              C(v, w, w / 5);
            }
            for (Y = 0; Y < g; Y++) {
              y = c[Y];
              L = y.labelPosition;
              H = y.dataLabel;
              U = !1 === y.visible ? "hidden" : "inherit";
              V = w = L.natural.y;
              v && a(y.distributeBox) && ("undefined" === typeof y.distributeBox.pos ? U = "hidden" : (K = y.distributeBox.size, V = F.radialDistributionY(y)));
              delete y.positionIndex;
              if (l.justify) I = F.justify(y, u, p);else switch (l.alignTo) {
                case "connectors":
                  I = F.alignToConnectors(c, n, b, k);
                  break;
                case "plotEdges":
                  I = F.alignToPlotEdges(H, n, b, k);
                  break;
                default:
                  I = F.radialDistributionX(e, y, V, w);
              }
              H._attr = {
                visibility: U,
                align: L.alignment
              };
              S = y.options.dataLabels || {};
              H._pos = {
                x: I + m(S.x, l.x) + ({
                  left: d,
                  right: -d
                }[L.alignment] || 0),
                y: V + m(S.y, l.y) - 10
              };
              L.final.x = I;
              L.final.y = V;
              m(l.crop, !0) && (A = H.getBBox().width, w = null, I - A < d && 1 === n ? (w = Math.round(A - I + d), G[3] = Math.max(w, G[3])) : I + A > b - d && 0 === n && (w = Math.round(I + A - b + d), G[1] = Math.max(w, G[1])), 0 > V - K / 2 ? G[0] = Math.max(Math.round(-V + K / 2), G[0]) : V + K / 2 > h && (G[2] = Math.max(Math.round(V + K / 2 - h), G[2])), H.sideOverflow = w);
            }
          }
        }), 0 === t(G) || this.verifyDataLabelOverflow(G)) && (this.placeDataLabels(), this.points.forEach(function (b) {
          S = q(l, b.options.dataLabels);
          if (z = m(S.connectorWidth, 1)) {
            var a;
            B = b.connector;
            if ((H = b.dataLabel) && H._pos && b.visible && 0 < b.labelDistance) {
              U = H._attr.visibility;
              if (a = !B) b.connector = B = f.renderer.path().addClass("highcharts-data-label-connector  highcharts-color-" + b.colorIndex + (b.className ? " " + b.className : "")).add(e.dataLabelsGroup), f.styledMode || B.attr({
                "stroke-width": z,
                stroke: S.connectorColor || b.color || "#666666"
              });
              B[a ? "attr" : "animate"]({
                d: b.getConnectorPath()
              });
              B.attr("visibility", U);
            } else B && (b.connector = B.destroy());
          }
        }));
      }
      function g() {
        this.points.forEach(function (a) {
          var e = a.dataLabel,
            f;
          e && a.visible && ((f = e._pos) ? (e.sideOverflow && (e._attr.width = Math.max(e.getBBox().width - e.sideOverflow, 0), e.css({
            width: e._attr.width + "px",
            textOverflow: (this.options.dataLabels.style || {}).textOverflow || "ellipsis"
          }), e.shortened = !0), e.attr(e._attr), e[e.moved ? "animate" : "attr"](f), e.moved = !0) : e && e.attr({
            y: -9999
          }));
          delete a.distributeBox;
        }, this);
      }
      function y(a) {
        var e = this.center,
          f = this.options,
          c = f.center,
          d = f.minSize || 80,
          b = null !== f.size;
        if (!b) {
          if (null !== c[0]) var h = Math.max(e[2] - Math.max(a[1], a[3]), d);else h = Math.max(e[2] - a[1] - a[3], d), e[0] += (a[3] - a[1]) / 2;
          null !== c[1] ? h = r(h, d, e[2] - Math.max(a[0], a[2])) : (h = r(h, d, e[2] - a[0] - a[2]), e[1] += (a[0] - a[2]) / 2);
          h < e[2] ? (e[2] = h, e[3] = Math.min(f.thickness ? Math.max(0, h - 2 * f.thickness) : Math.max(0, p(f.innerSize || 0, h)), h), this.translate(e), this.drawDataLabels && this.drawDataLabels()) : b = !0;
        }
        return b;
      }
      var B = [],
        u = {
          radialDistributionY: function (a) {
            return a.top + a.distributeBox.pos;
          },
          radialDistributionX: function (a, e, f, c) {
            return a.getX(f < e.top + 2 || f > e.bottom - 2 ? c : f, e.half, e);
          },
          justify: function (a, e, f) {
            return f[0] + (a.half ? -1 : 1) * (e + a.labelDistance);
          },
          alignToPlotEdges: function (a, e, f, c) {
            a = a.getBBox().width;
            return e ? a + c : f - a - c;
          },
          alignToConnectors: function (a, e, f, c) {
            var d = 0,
              b;
            a.forEach(function (a) {
              b = a.dataLabel.getBBox().width;
              b > d && (d = b);
            });
            return e ? d + c : f - d - c;
          }
        };
      e.compose = function (a) {
        c.compose(x);
        -1 === B.indexOf(a) && (B.push(a), a = a.prototype, a.dataLabelPositioners = u, a.alignDataLabel = z, a.drawDataLabels = k, a.placeDataLabels = g, a.verifyDataLabelOverflow = y);
      };
    })(k || (k = {}));
    return k;
  });
  I(g, "Extensions/OverlappingDataLabels.js", [g["Core/Chart/Chart.js"], g["Core/Utilities.js"]], function (c, g) {
    function y(c, a) {
      var g = !1;
      if (c) {
        var m = c.newOpacity;
        c.oldOpacity !== m && (c.alignAttr && c.placed ? (c[m ? "removeClass" : "addClass"]("highcharts-data-label-hidden"), g = !0, c.alignAttr.opacity = m, c[c.isOld ? "animate" : "attr"](c.alignAttr, null, function () {
          a.styledMode || c.css({
            pointerEvents: m ? "auto" : "none"
          });
        }), B(a, "afterHideOverlappingLabel")) : c.attr({
          opacity: m
        }));
        c.isOld = !0;
      }
      return g;
    }
    var A = g.addEvent,
      B = g.fireEvent,
      z = g.isArray,
      C = g.isNumber,
      x = g.objectEach,
      t = g.pick;
    A(c, "render", function () {
      var c = this,
        a = [];
      (this.labelCollectors || []).forEach(function (c) {
        a = a.concat(c());
      });
      (this.yAxis || []).forEach(function (c) {
        c.stacking && c.options.stackLabels && !c.options.stackLabels.allowOverlap && x(c.stacking.stacks, function (c) {
          x(c, function (c) {
            c.label && a.push(c.label);
          });
        });
      });
      (this.series || []).forEach(function (g) {
        var m = g.options.dataLabels;
        g.visible && (!1 !== m.enabled || g._hasPointLabels) && (m = function (g) {
          return g.forEach(function (k) {
            k.visible && (z(k.dataLabels) ? k.dataLabels : k.dataLabel ? [k.dataLabel] : []).forEach(function (e) {
              var g = e.options;
              e.labelrank = t(g.labelrank, k.labelrank, k.shapeArgs && k.shapeArgs.height);
              g.allowOverlap ? (e.oldOpacity = e.opacity, e.newOpacity = 1, y(e, c)) : a.push(e);
            });
          });
        }, m(g.nodes || []), m(g.points));
      });
      this.hideOverlappingLabels(a);
    });
    c.prototype.hideOverlappingLabels = function (c) {
      var a = this,
        g = c.length,
        m = a.renderer,
        p,
        k,
        e,
        t = !1;
      var r = function (a) {
        var e,
          c = a.box ? 0 : a.padding || 0,
          f = e = 0,
          l;
        if (a && (!a.alignAttr || a.placed)) {
          var d = a.alignAttr || {
            x: a.attr("x"),
            y: a.attr("y")
          };
          var b = a.parentGroup;
          a.width || (e = a.getBBox(), a.width = e.width, a.height = e.height, e = m.fontMetrics(null, a.element).h);
          var h = a.width - 2 * c;
          (l = {
            left: "0",
            center: "0.5",
            right: "1"
          }[a.alignValue]) ? f = +l * h : C(a.x) && Math.round(a.x) !== a.translateX && (f = a.x - a.translateX);
          return {
            x: d.x + (b.translateX || 0) + c - (f || 0),
            y: d.y + (b.translateY || 0) + c - e,
            width: a.width - 2 * c,
            height: a.height - 2 * c
          };
        }
      };
      for (k = 0; k < g; k++) if (p = c[k]) p.oldOpacity = p.opacity, p.newOpacity = 1, p.absoluteBox = r(p);
      c.sort(function (a, e) {
        return (e.labelrank || 0) - (a.labelrank || 0);
      });
      for (k = 0; k < g; k++) {
        var x = (r = c[k]) && r.absoluteBox;
        for (p = k + 1; p < g; ++p) {
          var z = (e = c[p]) && e.absoluteBox;
          !x || !z || r === e || 0 === r.newOpacity || 0 === e.newOpacity || "hidden" === r.visibility || "hidden" === e.visibility || z.x >= x.x + x.width || z.x + z.width <= x.x || z.y >= x.y + x.height || z.y + z.height <= x.y || ((r.labelrank < e.labelrank ? r : e).newOpacity = 0);
        }
      }
      c.forEach(function (e) {
        y(e, a) && (t = !0);
      });
      t && B(a, "afterHideAllOverlappingLabels");
    };
  });
  I(g, "Core/Responsive.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.extend,
      y = c.find,
      A = c.isArray,
      B = c.isObject,
      z = c.merge,
      C = c.objectEach,
      x = c.pick,
      t = c.splat,
      r = c.uniqueKey,
      a;
    (function (a) {
      var c = [];
      a.compose = function (a) {
        -1 === c.indexOf(a) && (c.push(a), g(a.prototype, p.prototype));
        return a;
      };
      var p = function () {
        function a() {}
        a.prototype.currentOptions = function (a) {
          function e(a, k, n, g) {
            var f;
            C(a, function (a, d) {
              if (!g && -1 < c.collectionsWithUpdate.indexOf(d) && k[d]) for (a = t(a), n[d] = [], f = 0; f < Math.max(a.length, k[d].length); f++) k[d][f] && (void 0 === a[f] ? n[d][f] = k[d][f] : (n[d][f] = {}, e(a[f], k[d][f], n[d][f], g + 1)));else B(a) ? (n[d] = A(a) ? [] : {}, e(a, k[d] || {}, n[d], g + 1)) : n[d] = "undefined" === typeof k[d] ? null : k[d];
            });
          }
          var c = this,
            k = {};
          e(a, this.options, k, 0);
          return k;
        };
        a.prototype.matchResponsiveRule = function (a, c) {
          var e = a.condition;
          (e.callback || function () {
            return this.chartWidth <= x(e.maxWidth, Number.MAX_VALUE) && this.chartHeight <= x(e.maxHeight, Number.MAX_VALUE) && this.chartWidth >= x(e.minWidth, 0) && this.chartHeight >= x(e.minHeight, 0);
          }).call(this) && c.push(a._id);
        };
        a.prototype.setResponsive = function (a, c) {
          var e = this,
            k = this.options.responsive,
            g = this.currentResponsive,
            m = [];
          !c && k && k.rules && k.rules.forEach(function (a) {
            "undefined" === typeof a._id && (a._id = r());
            e.matchResponsiveRule(a, m);
          }, this);
          c = z.apply(void 0, m.map(function (a) {
            return y((k || {}).rules || [], function (e) {
              return e._id === a;
            });
          }).map(function (a) {
            return a && a.chartOptions;
          }));
          c.isResponsiveOptions = !0;
          m = m.toString() || void 0;
          m !== (g && g.ruleIds) && (g && this.update(g.undoOptions, a, !0), m ? (g = this.currentOptions(c), g.isResponsiveOptions = !0, this.currentResponsive = {
            ruleIds: m,
            mergedOptions: c,
            undoOptions: g
          }, this.update(c, a, !0)) : this.currentResponsive = void 0);
        };
        return a;
      }();
    })(a || (a = {}));
    "";
    "";
    return a;
  });
  I(g, "masters/highcharts.src.js", [g["Core/Globals.js"], g["Core/Utilities.js"], g["Core/Defaults.js"], g["Core/Animation/Fx.js"], g["Core/Animation/AnimationUtilities.js"], g["Core/Renderer/HTML/AST.js"], g["Core/FormatUtilities.js"], g["Core/Renderer/RendererUtilities.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Renderer/SVG/SVGRenderer.js"], g["Core/Renderer/HTML/HTMLElement.js"], g["Core/Renderer/HTML/HTMLRenderer.js"], g["Core/Axis/Axis.js"], g["Core/Axis/DateTimeAxis.js"], g["Core/Axis/LogarithmicAxis.js"], g["Core/Axis/PlotLineOrBand/PlotLineOrBand.js"], g["Core/Axis/Tick.js"], g["Core/Tooltip.js"], g["Core/Series/Point.js"], g["Core/Pointer.js"], g["Core/MSPointer.js"], g["Core/Legend/Legend.js"], g["Core/Chart/Chart.js"], g["Core/Axis/Stacking/StackingAxis.js"], g["Core/Axis/Stacking/StackItem.js"], g["Core/Series/Series.js"], g["Core/Series/SeriesRegistry.js"], g["Series/Column/ColumnSeries.js"], g["Series/Column/ColumnDataLabel.js"], g["Series/Pie/PieSeries.js"], g["Series/Pie/PieDataLabel.js"], g["Core/Series/DataLabel.js"], g["Core/Responsive.js"], g["Core/Color/Color.js"], g["Core/Time.js"]], function (c, g, y, A, B, z, C, x, t, r, a, q, m, p, k, e, D, G, H, K, u, n, w, f, l, d, b, h, v, E, J, M, O, P, T) {
    c.animate = B.animate;
    c.animObject = B.animObject;
    c.getDeferredAnimation = B.getDeferredAnimation;
    c.setAnimation = B.setAnimation;
    c.stop = B.stop;
    c.timers = A.timers;
    c.AST = z;
    c.Axis = m;
    c.Chart = w;
    c.chart = w.chart;
    c.Fx = A;
    c.Legend = n;
    c.PlotLineOrBand = e;
    c.Point = H;
    c.Pointer = u.isRequired() ? u : K;
    c.Series = d;
    c.StackItem = l;
    c.SVGElement = t;
    c.SVGRenderer = r;
    c.Tick = D;
    c.Time = T;
    c.Tooltip = G;
    c.Color = P;
    c.color = P.parse;
    q.compose(r);
    a.compose(t);
    c.defaultOptions = y.defaultOptions;
    c.getOptions = y.getOptions;
    c.time = y.defaultTime;
    c.setOptions = y.setOptions;
    c.dateFormat = C.dateFormat;
    c.format = C.format;
    c.numberFormat = C.numberFormat;
    c.addEvent = g.addEvent;
    c.arrayMax = g.arrayMax;
    c.arrayMin = g.arrayMin;
    c.attr = g.attr;
    c.clearTimeout = g.clearTimeout;
    c.correctFloat = g.correctFloat;
    c.createElement = g.createElement;
    c.css = g.css;
    c.defined = g.defined;
    c.destroyObjectProperties = g.destroyObjectProperties;
    c.discardElement = g.discardElement;
    c.distribute = x.distribute;
    c.erase = g.erase;
    c.error = g.error;
    c.extend = g.extend;
    c.extendClass = g.extendClass;
    c.find = g.find;
    c.fireEvent = g.fireEvent;
    c.getMagnitude = g.getMagnitude;
    c.getStyle = g.getStyle;
    c.inArray = g.inArray;
    c.isArray = g.isArray;
    c.isClass = g.isClass;
    c.isDOMElement = g.isDOMElement;
    c.isFunction = g.isFunction;
    c.isNumber = g.isNumber;
    c.isObject = g.isObject;
    c.isString = g.isString;
    c.keys = g.keys;
    c.merge = g.merge;
    c.normalizeTickInterval = g.normalizeTickInterval;
    c.objectEach = g.objectEach;
    c.offset = g.offset;
    c.pad = g.pad;
    c.pick = g.pick;
    c.pInt = g.pInt;
    c.relativeLength = g.relativeLength;
    c.removeEvent = g.removeEvent;
    c.seriesType = b.seriesType;
    c.splat = g.splat;
    c.stableSort = g.stableSort;
    c.syncTimeout = g.syncTimeout;
    c.timeUnits = g.timeUnits;
    c.uniqueKey = g.uniqueKey;
    c.useSerialIds = g.useSerialIds;
    c.wrap = g.wrap;
    v.compose(h);
    M.compose(d);
    p.compose(m);
    k.compose(m);
    J.compose(E);
    e.compose(m);
    O.compose(w);
    f.compose(m, w, d);
    return c;
  });
  I(g, "Core/Axis/NavigatorAxisComposition.js", [g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g) {
    function y() {
      this.navigatorAxis || (this.navigatorAxis = new q(this));
    }
    function A(a) {
      var c = this.chart.options,
        k = c.navigator,
        e = this.navigatorAxis,
        g = c.chart.zooming.pinchType,
        m = c.rangeSelector;
      c = c.chart.zooming.type;
      this.isXAxis && (k && k.enabled || m && m.enabled) && ("y" === c ? a.zoomed = !1 : (!B && "xy" === c || B && "xy" === g) && this.options.range && (k = e.previousZoom, x(a.newMin) ? e.previousZoom = [this.min, this.max] : k && (a.newMin = k[0], a.newMax = k[1], e.previousZoom = void 0)));
      "undefined" !== typeof a.zoomed && a.preventDefault();
    }
    var B = c.isTouchDevice,
      z = g.addEvent,
      C = g.correctFloat,
      x = g.defined,
      t = g.isNumber,
      r = g.pick,
      a = [],
      q = function () {
        function c(a) {
          this.axis = a;
        }
        c.compose = function (c) {
          -1 === a.indexOf(c) && (a.push(c), c.keepProps.push("navigatorAxis"), z(c, "init", y), z(c, "zoom", A));
        };
        c.prototype.destroy = function () {
          this.axis = void 0;
        };
        c.prototype.toFixedRange = function (a, c, e, g) {
          var k = this.axis,
            m = k.chart;
          a = r(e, k.translate(a, !0, !k.horiz));
          c = r(g, k.translate(c, !0, !k.horiz));
          m = m && m.fixedRange;
          k = (k.pointRange || 0) / 2;
          var p = m && (c - a) / m;
          x(e) || (a = C(a + k));
          x(g) || (c = C(c - k));
          .7 < p && 1.3 > p && (g ? a = c - m : c = a + m);
          t(a) && t(c) || (a = c = void 0);
          return {
            min: a,
            max: c
          };
        };
        return c;
      }();
    return q;
  });
  I(g, "Stock/Navigator/NavigatorDefaults.js", [g["Core/Color/Color.js"], g["Core/Series/SeriesRegistry.js"]], function (c, g) {
    c = c.parse;
    g = g.seriesTypes;
    g = {
      height: 40,
      margin: 25,
      maskInside: !0,
      handles: {
        width: 7,
        height: 15,
        symbols: ["navigator-handle", "navigator-handle"],
        enabled: !0,
        lineWidth: 1,
        backgroundColor: "#f2f2f2",
        borderColor: "#999999"
      },
      maskFill: c("#6685c2").setOpacity(.3).get(),
      outlineColor: "#cccccc",
      outlineWidth: 1,
      series: {
        type: "undefined" === typeof g.areaspline ? "line" : "areaspline",
        fillOpacity: .05,
        lineWidth: 1,
        compare: null,
        dataGrouping: {
          approximation: "average",
          enabled: !0,
          groupPixelWidth: 2,
          firstAnchor: "firstPoint",
          anchor: "middle",
          lastAnchor: "lastPoint",
          units: [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2, 3, 4]], ["week", [1, 2, 3]], ["month", [1, 3, 6]], ["year", null]]
        },
        dataLabels: {
          enabled: !1,
          zIndex: 2
        },
        id: "highcharts-navigator-series",
        className: "highcharts-navigator-series",
        lineColor: null,
        marker: {
          enabled: !1
        },
        threshold: null
      },
      xAxis: {
        overscroll: 0,
        className: "highcharts-navigator-xaxis",
        tickLength: 0,
        lineWidth: 0,
        gridLineColor: "#e6e6e6",
        gridLineWidth: 1,
        tickPixelInterval: 200,
        labels: {
          align: "left",
          style: {
            color: "#999999"
          },
          x: 3,
          y: -4
        },
        crosshair: !1
      },
      yAxis: {
        className: "highcharts-navigator-yaxis",
        gridLineWidth: 0,
        startOnTick: !1,
        endOnTick: !1,
        minPadding: .1,
        maxPadding: .1,
        labels: {
          enabled: !1
        },
        crosshair: !1,
        title: {
          text: null
        },
        tickLength: 0,
        tickWidth: 0
      }
    };
    "";
    return g;
  });
  I(g, "Stock/Navigator/NavigatorSymbols.js", [], function () {
    return {
      "navigator-handle": function (c, g, y, A, B) {
        void 0 === B && (B = {});
        c = B.width ? B.width / 2 : y;
        g = Math.round(c / 3) + .5;
        A = B.height || A;
        return [["M", -c - 1, .5], ["L", c, .5], ["L", c, A + .5], ["L", -c - 1, A + .5], ["L", -c - 1, .5], ["M", -g, 4], ["L", -g, A - 3], ["M", g - 1, 4], ["L", g - 1, A - 3]];
      }
    };
  });
  I(g, "Stock/Navigator/NavigatorComposition.js", [g["Core/Defaults.js"], g["Core/Globals.js"], g["Core/Axis/NavigatorAxisComposition.js"], g["Stock/Navigator/NavigatorDefaults.js"], g["Stock/Navigator/NavigatorSymbols.js"], g["Core/Renderer/RendererRegistry.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C) {
    function x() {
      this.navigator && this.navigator.setBaseSeries(null, !1);
    }
    function t() {
      var a = this.legend,
        b = this.navigator;
      if (b) {
        var h = a && a.options;
        var e = b.xAxis;
        var c = b.yAxis;
        var f = b.scrollbarHeight;
        this.inverted ? (b.left = b.opposite ? this.chartWidth - f - b.height : this.spacing[3] + f, b.top = this.plotTop + f) : (b.left = w(e.left, this.plotLeft + f), b.top = b.navigatorOptions.top || this.chartHeight - b.height - f - this.spacing[2] - (this.rangeSelector && this.extraBottomMargin ? this.rangeSelector.getHeight() : 0) - (h && "bottom" === h.verticalAlign && "proximate" !== h.layout && h.enabled && !h.floating ? a.legendHeight + w(h.margin, 10) : 0) - (this.titleOffset ? this.titleOffset[2] : 0));
        e && c && (this.inverted ? e.options.left = c.options.left = b.left : e.options.top = c.options.top = b.top, e.setAxisSize(), c.setAxisSize());
      }
    }
    function r(a) {
      this.navigator || this.scroller || !this.options.navigator.enabled && !this.options.scrollbar.enabled || (this.scroller = this.navigator = new l(this), w(a.redraw, !0) && this.redraw(a.animation));
    }
    function a() {
      var a = this.options;
      if (a.navigator.enabled || a.scrollbar.enabled) this.scroller = this.navigator = new l(this);
    }
    function q() {
      var a = this.options,
        b = a.navigator,
        h = a.rangeSelector;
      if ((b && b.enabled || h && h.enabled) && (!G && "x" === a.chart.zooming.type || G && "x" === a.chart.zooming.pinchType)) return !1;
    }
    function m(a) {
      var b = a.navigator;
      b && a.xAxis[0] && (a = a.xAxis[0].getExtremes(), b.render(a.min, a.max));
    }
    function p(a) {
      var b = a.options.navigator || {},
        d = a.options.scrollbar || {};
      this.navigator || this.scroller || !b.enabled && !d.enabled || (n(!0, this.options.navigator, b), n(!0, this.options.scrollbar, d), delete a.options.navigator, delete a.options.scrollbar);
    }
    function k() {
      this.chart.navigator && !this.options.isInternal && this.chart.navigator.setBaseSeries(null, !1);
    }
    var e = c.defaultOptions,
      D = c.setOptions,
      G = g.isTouchDevice,
      H = z.getRendererType,
      K = C.addEvent,
      u = C.extend,
      n = C.merge,
      w = C.pick,
      f = [],
      l;
    return {
      compose: function (d, b, h, c) {
        y.compose(d);
        l = h;
        -1 === f.indexOf(b) && (f.push(b), b.prototype.callbacks.push(m), K(b, "afterAddSeries", x), K(b, "afterSetChartSize", t), K(b, "afterUpdate", r), K(b, "beforeRender", a), K(b, "beforeShowResetZoom", q), K(b, "update", p));
        -1 === f.indexOf(c) && (f.push(c), K(c, "afterUpdate", k));
        -1 === f.indexOf(H) && (f.push(H), u(H().prototype.symbols, B));
        -1 === f.indexOf(D) && (f.push(D), u(e, {
          navigator: A
        }));
      }
    };
  });
  I(g, "Core/Axis/ScrollbarAxis.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.addEvent,
      y = c.defined,
      A = c.pick;
    return function () {
      function c() {}
      c.compose = function (z, C) {
        if (-1 === c.composed.indexOf(z)) c.composed.push(z);else return z;
        var x = function (c) {
          var g = A(c.options && c.options.min, c.min),
            a = A(c.options && c.options.max, c.max);
          return {
            axisMin: g,
            axisMax: a,
            scrollMin: y(c.dataMin) ? Math.min(g, c.min, c.dataMin, A(c.threshold, Infinity)) : g,
            scrollMax: y(c.dataMax) ? Math.max(a, c.max, c.dataMax, A(c.threshold, -Infinity)) : a
          };
        };
        g(z, "afterInit", function () {
          var c = this;
          c.options && c.options.scrollbar && c.options.scrollbar.enabled && (c.options.scrollbar.vertical = !c.horiz, c.options.startOnTick = c.options.endOnTick = !1, c.scrollbar = new C(c.chart.renderer, c.options.scrollbar, c.chart), g(c.scrollbar, "changed", function (g) {
            var a = x(c),
              q = a.axisMax,
              m = a.scrollMin,
              p = a.scrollMax - m;
            y(a.axisMin) && y(q) && (c.horiz && !c.reversed || !c.horiz && c.reversed ? (a = m + p * this.to, m += p * this.from) : (a = m + p * (1 - this.from), m += p * (1 - this.to)), this.shouldUpdateExtremes(g.DOMType) ? c.setExtremes(m, a, !0, "mousemove" !== g.DOMType && "touchmove" !== g.DOMType, g) : this.setRange(this.from, this.to));
          }));
        });
        g(z, "afterRender", function () {
          var c = x(this),
            g = c.scrollMin,
            a = c.scrollMax;
          c = this.scrollbar;
          var q = this.axisTitleMargin + (this.titleOffset || 0),
            m = this.chart.scrollbarsOffsets,
            p = this.options.margin || 0;
          c && (this.horiz ? (this.opposite || (m[1] += q), c.position(this.left, this.top + this.height + 2 + m[1] - (this.opposite ? p : 0), this.width, this.height), this.opposite || (m[1] += p), q = 1) : (this.opposite && (m[0] += q), c.position(c.options.opposite ? this.left + this.width + 2 + m[0] - (this.opposite ? 0 : p) : this.opposite ? 0 : p, this.top, this.width, this.height), this.opposite && (m[0] += p), q = 0), m[q] += c.size + c.options.margin, isNaN(g) || isNaN(a) || !y(this.min) || !y(this.max) || this.min === this.max ? c.setRange(0, 1) : (m = (this.min - g) / (a - g), g = (this.max - g) / (a - g), this.horiz && !this.reversed || !this.horiz && this.reversed ? c.setRange(m, g) : c.setRange(1 - g, 1 - m)));
        });
        g(z, "afterGetOffset", function () {
          var c = this.scrollbar && !this.scrollbar.options.opposite;
          c = this.horiz ? 2 : c ? 3 : 1;
          var g = this.scrollbar;
          g && (this.chart.scrollbarsOffsets = [0, 0], this.chart.axisOffset[c] += g.size + g.options.margin);
        });
        return z;
      };
      c.composed = [];
      return c;
    }();
  });
  I(g, "Stock/Scrollbar/ScrollbarDefaults.js", [g["Core/Globals.js"]], function (c) {
    return {
      height: c.isTouchDevice ? 20 : 14,
      barBorderRadius: 0,
      buttonBorderRadius: 0,
      liveRedraw: void 0,
      margin: 10,
      minWidth: 6,
      opposite: !0,
      step: .2,
      zIndex: 3,
      barBackgroundColor: "#cccccc",
      barBorderWidth: 1,
      barBorderColor: "#cccccc",
      buttonArrowColor: "#333333",
      buttonBackgroundColor: "#e6e6e6",
      buttonBorderColor: "#cccccc",
      buttonBorderWidth: 1,
      rifleColor: "#333333",
      trackBackgroundColor: "#f2f2f2",
      trackBorderColor: "#f2f2f2",
      trackBorderWidth: 1
    };
  });
  I(g, "Stock/Scrollbar/Scrollbar.js", [g["Core/Defaults.js"], g["Core/Globals.js"], g["Core/Axis/ScrollbarAxis.js"], g["Stock/Scrollbar/ScrollbarDefaults.js"], g["Core/Utilities.js"]], function (c, g, y, A, B) {
    var z = c.defaultOptions,
      C = B.addEvent,
      x = B.correctFloat,
      t = B.defined,
      r = B.destroyObjectProperties,
      a = B.fireEvent,
      q = B.merge,
      m = B.pick,
      p = B.removeEvent;
    c = function () {
      function c(a, c, k) {
        this._events = [];
        this.chart = void 0;
        this.from = this.chartY = this.chartX = 0;
        this.scrollbar = this.renderer = this.options = this.group = void 0;
        this.scrollbarButtons = [];
        this.scrollbarGroup = void 0;
        this.scrollbarLeft = 0;
        this.scrollbarRifles = void 0;
        this.scrollbarStrokeWidth = 1;
        this.to = this.size = this.scrollbarTop = 0;
        this.track = void 0;
        this.trackBorderWidth = 1;
        this.userOptions = void 0;
        this.y = this.x = 0;
        this.init(a, c, k);
      }
      c.compose = function (a) {
        y.compose(a, c);
      };
      c.swapXY = function (a, c) {
        c && a.forEach(function (a) {
          for (var c = a.length, e, k = 0; k < c; k += 2) e = a[k + 1], "number" === typeof e && (a[k + 1] = a[k + 2], a[k + 2] = e);
        });
        return a;
      };
      c.prototype.addEvents = function () {
        var a = this.options.inverted ? [1, 0] : [0, 1],
          c = this.scrollbarButtons,
          k = this.scrollbarGroup.element,
          m = this.track.element,
          p = this.mouseDownHandler.bind(this),
          q = this.mouseMoveHandler.bind(this),
          n = this.mouseUpHandler.bind(this);
        a = [[c[a[0]].element, "click", this.buttonToMinClick.bind(this)], [c[a[1]].element, "click", this.buttonToMaxClick.bind(this)], [m, "click", this.trackClick.bind(this)], [k, "mousedown", p], [k.ownerDocument, "mousemove", q], [k.ownerDocument, "mouseup", n]];
        g.hasTouch && a.push([k, "touchstart", p], [k.ownerDocument, "touchmove", q], [k.ownerDocument, "touchend", n]);
        a.forEach(function (a) {
          C.apply(null, a);
        });
        this._events = a;
      };
      c.prototype.buttonToMaxClick = function (c) {
        var e = (this.to - this.from) * m(this.options.step, .2);
        this.updatePosition(this.from + e, this.to + e);
        a(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: c
        });
      };
      c.prototype.buttonToMinClick = function (c) {
        var e = x(this.to - this.from) * m(this.options.step, .2);
        this.updatePosition(x(this.from - e), x(this.to - e));
        a(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: c
        });
      };
      c.prototype.cursorToScrollbarPosition = function (a) {
        var c = this.options;
        c = c.minWidth > this.calculatedWidth ? c.minWidth : 0;
        return {
          chartX: (a.chartX - this.x - this.xOffset) / (this.barWidth - c),
          chartY: (a.chartY - this.y - this.yOffset) / (this.barWidth - c)
        };
      };
      c.prototype.destroy = function () {
        var a = this,
          c = a.chart.scroller;
        a.removeEvents();
        ["track", "scrollbarRifles", "scrollbar", "scrollbarGroup", "group"].forEach(function (c) {
          a[c] && a[c].destroy && (a[c] = a[c].destroy());
        });
        c && a === c.scrollbar && (c.scrollbar = null, r(c.scrollbarButtons));
      };
      c.prototype.drawScrollbarButton = function (a) {
        var e = this.renderer,
          k = this.scrollbarButtons,
          g = this.options,
          m = this.size,
          p = e.g().add(this.group);
        k.push(p);
        p = e.rect().addClass("highcharts-scrollbar-button").add(p);
        this.chart.styledMode || p.attr({
          stroke: g.buttonBorderColor,
          "stroke-width": g.buttonBorderWidth,
          fill: g.buttonBackgroundColor
        });
        p.attr(p.crisp({
          x: -.5,
          y: -.5,
          width: m + 1,
          height: m + 1,
          r: g.buttonBorderRadius
        }, p.strokeWidth()));
        p = e.path(c.swapXY([["M", m / 2 + (a ? -1 : 1), m / 2 - 3], ["L", m / 2 + (a ? -1 : 1), m / 2 + 3], ["L", m / 2 + (a ? 2 : -2), m / 2]], g.vertical)).addClass("highcharts-scrollbar-arrow").add(k[a]);
        this.chart.styledMode || p.attr({
          fill: g.buttonArrowColor
        });
      };
      c.prototype.init = function (a, c, k) {
        this.scrollbarButtons = [];
        this.renderer = a;
        this.userOptions = c;
        this.options = q(A, z.scrollbar, c);
        this.chart = k;
        this.size = m(this.options.size, this.options.height);
        c.enabled && (this.render(), this.addEvents());
      };
      c.prototype.mouseDownHandler = function (a) {
        a = this.chart.pointer.normalize(a);
        a = this.cursorToScrollbarPosition(a);
        this.chartX = a.chartX;
        this.chartY = a.chartY;
        this.initPositions = [this.from, this.to];
        this.grabbedCenter = !0;
      };
      c.prototype.mouseMoveHandler = function (c) {
        var e = this.chart.pointer.normalize(c),
          k = this.options.vertical ? "chartY" : "chartX",
          g = this.initPositions || [];
        !this.grabbedCenter || c.touches && 0 === c.touches[0][k] || (e = this.cursorToScrollbarPosition(e)[k], k = this[k], k = e - k, this.hasDragged = !0, this.updatePosition(g[0] + k, g[1] + k), this.hasDragged && a(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMType: c.type,
          DOMEvent: c
        }));
      };
      c.prototype.mouseUpHandler = function (c) {
        this.hasDragged && a(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMType: c.type,
          DOMEvent: c
        });
        this.grabbedCenter = this.hasDragged = this.chartX = this.chartY = null;
      };
      c.prototype.position = function (a, c, k, g) {
        var e = this.options.vertical,
          m = this.rendered ? "animate" : "attr",
          n = g,
          p = 0;
        this.group.show();
        this.x = a;
        this.y = c + this.trackBorderWidth;
        this.width = k;
        this.height = g;
        this.xOffset = n;
        this.yOffset = p;
        e ? (this.width = this.yOffset = k = p = this.size, this.xOffset = n = 0, this.barWidth = g - 2 * k, this.x = a += this.options.margin) : (this.height = this.xOffset = g = n = this.size, this.barWidth = k - 2 * g, this.y += this.options.margin);
        this.group[m]({
          translateX: a,
          translateY: this.y
        });
        this.track[m]({
          width: k,
          height: g
        });
        this.scrollbarButtons[1][m]({
          translateX: e ? 0 : k - n,
          translateY: e ? g - p : 0
        });
      };
      c.prototype.removeEvents = function () {
        this._events.forEach(function (a) {
          p.apply(null, a);
        });
        this._events.length = 0;
      };
      c.prototype.render = function () {
        var a = this.renderer,
          k = this.options,
          g = this.size,
          m = this.chart.styledMode,
          p = a.g("scrollbar").attr({
            zIndex: k.zIndex
          }).hide().add();
        this.group = p;
        this.track = a.rect().addClass("highcharts-scrollbar-track").attr({
          x: 0,
          r: k.trackBorderRadius || 0,
          height: g,
          width: g
        }).add(p);
        m || this.track.attr({
          fill: k.trackBackgroundColor,
          stroke: k.trackBorderColor,
          "stroke-width": k.trackBorderWidth
        });
        this.trackBorderWidth = this.track.strokeWidth();
        this.track.attr({
          y: -this.trackBorderWidth % 2 / 2
        });
        this.scrollbarGroup = a.g().add(p);
        this.scrollbar = a.rect().addClass("highcharts-scrollbar-thumb").attr({
          height: g,
          width: g,
          r: k.barBorderRadius || 0
        }).add(this.scrollbarGroup);
        this.scrollbarRifles = a.path(c.swapXY([["M", -3, g / 4], ["L", -3, 2 * g / 3], ["M", 0, g / 4], ["L", 0, 2 * g / 3], ["M", 3, g / 4], ["L", 3, 2 * g / 3]], k.vertical)).addClass("highcharts-scrollbar-rifles").add(this.scrollbarGroup);
        m || (this.scrollbar.attr({
          fill: k.barBackgroundColor,
          stroke: k.barBorderColor,
          "stroke-width": k.barBorderWidth
        }), this.scrollbarRifles.attr({
          stroke: k.rifleColor,
          "stroke-width": 1
        }));
        this.scrollbarStrokeWidth = this.scrollbar.strokeWidth();
        this.scrollbarGroup.translate(-this.scrollbarStrokeWidth % 2 / 2, -this.scrollbarStrokeWidth % 2 / 2);
        this.drawScrollbarButton(0);
        this.drawScrollbarButton(1);
      };
      c.prototype.setRange = function (a, c) {
        var e = this.options,
          k = e.vertical,
          g = e.minWidth,
          m = this.barWidth,
          n = !this.rendered || this.hasDragged || this.chart.navigator && this.chart.navigator.hasDragged ? "attr" : "animate";
        if (t(m)) {
          var p = m * Math.min(c, 1);
          a = Math.max(a, 0);
          var f = Math.ceil(m * a);
          this.calculatedWidth = p = x(p - f);
          p < g && (f = (m - g + p) * a, p = g);
          g = Math.floor(f + this.xOffset + this.yOffset);
          m = p / 2 - .5;
          this.from = a;
          this.to = c;
          k ? (this.scrollbarGroup[n]({
            translateY: g
          }), this.scrollbar[n]({
            height: p
          }), this.scrollbarRifles[n]({
            translateY: m
          }), this.scrollbarTop = g, this.scrollbarLeft = 0) : (this.scrollbarGroup[n]({
            translateX: g
          }), this.scrollbar[n]({
            width: p
          }), this.scrollbarRifles[n]({
            translateX: m
          }), this.scrollbarLeft = g, this.scrollbarTop = 0);
          12 >= p ? this.scrollbarRifles.hide() : this.scrollbarRifles.show();
          !1 === e.showFull && (0 >= a && 1 <= c ? this.group.hide() : this.group.show());
          this.rendered = !0;
        }
      };
      c.prototype.shouldUpdateExtremes = function (a) {
        return m(this.options.liveRedraw, g.svg && !g.isTouchDevice && !this.chart.boosted) || "mouseup" === a || "touchend" === a || !t(a);
      };
      c.prototype.trackClick = function (c) {
        var e = this.chart.pointer.normalize(c),
          k = this.to - this.from,
          g = this.y + this.scrollbarTop,
          m = this.x + this.scrollbarLeft;
        this.options.vertical && e.chartY > g || !this.options.vertical && e.chartX > m ? this.updatePosition(this.from + k, this.to + k) : this.updatePosition(this.from - k, this.to - k);
        a(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: c
        });
      };
      c.prototype.update = function (a) {
        this.destroy();
        this.init(this.chart.renderer, q(!0, this.options, a), this.chart);
      };
      c.prototype.updatePosition = function (a, c) {
        1 < c && (a = x(1 - x(c - a)), c = 1);
        0 > a && (c = x(c - a), a = 0);
        this.from = a;
        this.to = c;
      };
      c.defaultOptions = A;
      return c;
    }();
    z.scrollbar = q(!0, c.defaultOptions, z.scrollbar);
    return c;
  });
  I(g, "Stock/Navigator/Navigator.js", [g["Core/Axis/Axis.js"], g["Core/Defaults.js"], g["Core/Globals.js"], g["Core/Axis/NavigatorAxisComposition.js"], g["Stock/Navigator/NavigatorComposition.js"], g["Stock/Scrollbar/Scrollbar.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z, C) {
    function x(a) {
      for (var b = [], d = 1; d < arguments.length; d++) b[d - 1] = arguments[d];
      b = [].filter.call(b, u);
      if (b.length) return Math[a].apply(0, b);
    }
    var t = g.defaultOptions,
      r = y.hasTouch,
      a = y.isTouchDevice,
      q = C.addEvent,
      m = C.clamp,
      p = C.correctFloat,
      k = C.defined,
      e = C.destroyObjectProperties,
      D = C.erase,
      G = C.extend,
      H = C.find,
      K = C.isArray,
      u = C.isNumber,
      n = C.merge,
      w = C.pick,
      f = C.removeEvent,
      l = C.splat;
    return function () {
      function d(b) {
        this.zoomedMin = this.zoomedMax = this.yAxis = this.xAxis = this.top = this.size = this.shades = this.rendered = this.range = this.outlineHeight = this.outline = this.opposite = this.navigatorSize = this.navigatorSeries = this.navigatorOptions = this.navigatorGroup = this.navigatorEnabled = this.left = this.height = this.handles = this.chart = this.baseSeries = void 0;
        this.init(b);
      }
      d.compose = function (b, a, c) {
        B.compose(b, a, d, c);
      };
      d.prototype.drawHandle = function (b, a, d, c) {
        var h = this.navigatorOptions.handles.height;
        this.handles[a][c](d ? {
          translateX: Math.round(this.left + this.height / 2),
          translateY: Math.round(this.top + parseInt(b, 10) + .5 - h)
        } : {
          translateX: Math.round(this.left + parseInt(b, 10)),
          translateY: Math.round(this.top + this.height / 2 - h / 2 - 1)
        });
      };
      d.prototype.drawOutline = function (b, a, d, c) {
        var h = this.navigatorOptions.maskInside,
          e = this.outline.strokeWidth(),
          f = e / 2,
          l = e % 2 / 2;
        e = this.outlineHeight;
        var k = this.scrollbarHeight || 0,
          g = this.size,
          n = this.left - k,
          m = this.top;
        d ? (n -= f, d = m + a + l, a = m + b + l, l = [["M", n + e, m - k - l], ["L", n + e, d], ["L", n, d], ["L", n, a], ["L", n + e, a], ["L", n + e, m + g + k]], h && l.push(["M", n + e, d - f], ["L", n + e, a + f])) : (b += n + k - l, a += n + k - l, m += f, l = [["M", n, m], ["L", b, m], ["L", b, m + e], ["L", a, m + e], ["L", a, m], ["L", n + g + 2 * k, m]], h && l.push(["M", b - f, m], ["L", a + f, m]));
        this.outline[c]({
          d: l
        });
      };
      d.prototype.drawMasks = function (b, a, d, c) {
        var h = this.left,
          e = this.top,
          f = this.height;
        if (d) {
          var l = [h, h, h];
          var k = [e, e + b, e + a];
          var g = [f, f, f];
          var n = [b, a - b, this.size - a];
        } else l = [h, h + b, h + a], k = [e, e, e], g = [b, a - b, this.size - a], n = [f, f, f];
        this.shades.forEach(function (b, a) {
          b[c]({
            x: l[a],
            y: k[a],
            width: g[a],
            height: n[a]
          });
        });
      };
      d.prototype.renderElements = function () {
        var b = this,
          a = b.navigatorOptions,
          d = a.maskInside,
          c = b.chart,
          e = c.renderer,
          f = {
            cursor: c.inverted ? "ns-resize" : "ew-resize"
          },
          l = b.navigatorGroup = e.g("navigator").attr({
            zIndex: 8,
            visibility: "hidden"
          }).add();
        [!d, d, !d].forEach(function (d, h) {
          var k = e.rect().addClass("highcharts-navigator-mask" + (1 === h ? "-inside" : "-outside")).add(l);
          c.styledMode || (k.attr({
            fill: d ? a.maskFill : "rgba(0,0,0,0)"
          }), 1 === h && k.css(f));
          b.shades[h] = k;
        });
        b.outline = e.path().addClass("highcharts-navigator-outline").add(l);
        c.styledMode || b.outline.attr({
          "stroke-width": a.outlineWidth,
          stroke: a.outlineColor
        });
        if (a.handles && a.handles.enabled) {
          var k = a.handles,
            g = k.height,
            n = k.width;
          [0, 1].forEach(function (a) {
            b.handles[a] = e.symbol(k.symbols[a], -n / 2 - 1, 0, n, g, k);
            c.inverted && b.handles[a].attr({
              rotation: 90,
              rotationOriginX: Math.floor(-n / 2),
              rotationOriginY: (g + n) / 2
            });
            b.handles[a].attr({
              zIndex: 7 - a
            }).addClass("highcharts-navigator-handle highcharts-navigator-handle-" + ["left", "right"][a]).add(l);
            c.styledMode || b.handles[a].attr({
              fill: k.backgroundColor,
              stroke: k.borderColor,
              "stroke-width": k.lineWidth
            }).css(f);
          });
        }
      };
      d.prototype.update = function (b) {
        (this.series || []).forEach(function (b) {
          b.baseSeries && delete b.baseSeries.navigatorSeries;
        });
        this.destroy();
        n(!0, this.chart.options.navigator, b);
        this.init(this.chart);
      };
      d.prototype.render = function (b, a, d, c) {
        var h = this.chart,
          e = this.xAxis,
          f = e.pointRange || 0,
          l = e.navigatorAxis.fake ? h.xAxis[0] : e,
          g = this.navigatorEnabled,
          n = this.rendered,
          v = h.inverted,
          q = h.xAxis[0].minRange,
          r = h.xAxis[0].options.maxRange,
          t = this.scrollbarHeight,
          E;
        if (!this.hasDragged || k(d)) {
          b = p(b - f / 2);
          a = p(a + f / 2);
          if (!u(b) || !u(a)) if (n) d = 0, c = w(e.width, l.width);else return;
          this.left = w(e.left, h.plotLeft + t + (v ? h.plotWidth : 0));
          var x = this.size = E = w(e.len, (v ? h.plotHeight : h.plotWidth) - 2 * t);
          h = v ? t : E + 2 * t;
          d = w(d, e.toPixels(b, !0));
          c = w(c, e.toPixels(a, !0));
          u(d) && Infinity !== Math.abs(d) || (d = 0, c = h);
          b = e.toValue(d, !0);
          a = e.toValue(c, !0);
          var D = Math.abs(p(a - b));
          D < q ? this.grabbedLeft ? d = e.toPixels(a - q - f, !0) : this.grabbedRight && (c = e.toPixels(b + q + f, !0)) : k(r) && p(D - f) > r && (this.grabbedLeft ? d = e.toPixels(a - r - f, !0) : this.grabbedRight && (c = e.toPixels(b + r + f, !0)));
          this.zoomedMax = m(Math.max(d, c), 0, x);
          this.zoomedMin = m(this.fixedWidth ? this.zoomedMax - this.fixedWidth : Math.min(d, c), 0, x);
          this.range = this.zoomedMax - this.zoomedMin;
          x = Math.round(this.zoomedMax);
          d = Math.round(this.zoomedMin);
          g && (this.navigatorGroup.attr({
            visibility: "inherit"
          }), n = n && !this.hasDragged ? "animate" : "attr", this.drawMasks(d, x, v, n), this.drawOutline(d, x, v, n), this.navigatorOptions.handles.enabled && (this.drawHandle(d, 0, v, n), this.drawHandle(x, 1, v, n)));
          this.scrollbar && (v ? (v = this.top - t, l = this.left - t + (g || !l.opposite ? 0 : (l.titleOffset || 0) + l.axisTitleMargin), t = E + 2 * t) : (v = this.top + (g ? this.height : -t), l = this.left - t), this.scrollbar.position(l, v, h, t), this.scrollbar.setRange(this.zoomedMin / (E || 1), this.zoomedMax / (E || 1)));
          this.rendered = !0;
        }
      };
      d.prototype.addMouseEvents = function () {
        var b = this,
          a = b.chart,
          d = a.container,
          c = [],
          e,
          f;
        b.mouseMoveHandler = e = function (a) {
          b.onMouseMove(a);
        };
        b.mouseUpHandler = f = function (a) {
          b.onMouseUp(a);
        };
        c = b.getPartsEvents("mousedown");
        c.push(q(a.renderTo, "mousemove", e), q(d.ownerDocument, "mouseup", f));
        r && (c.push(q(a.renderTo, "touchmove", e), q(d.ownerDocument, "touchend", f)), c.concat(b.getPartsEvents("touchstart")));
        b.eventsToUnbind = c;
        b.series && b.series[0] && c.push(q(b.series[0].xAxis, "foundExtremes", function () {
          a.navigator.modifyNavigatorAxisExtremes();
        }));
      };
      d.prototype.getPartsEvents = function (b) {
        var a = this,
          d = [];
        ["shades", "handles"].forEach(function (c) {
          a[c].forEach(function (h, e) {
            d.push(q(h.element, b, function (b) {
              a[c + "Mousedown"](b, e);
            }));
          });
        });
        return d;
      };
      d.prototype.shadesMousedown = function (b, a) {
        b = this.chart.pointer.normalize(b);
        var d = this.chart,
          c = this.xAxis,
          h = this.zoomedMin,
          e = this.size,
          f = this.range,
          l = this.left,
          g = b.chartX;
        d.inverted && (g = b.chartY, l = this.top);
        if (1 === a) this.grabbedCenter = g, this.fixedWidth = f, this.dragOffset = g - h;else {
          b = g - l - f / 2;
          if (0 === a) b = Math.max(0, b);else if (2 === a && b + f >= e) if (b = e - f, this.reversedExtremes) {
            b -= f;
            var n = this.getUnionExtremes().dataMin;
          } else var m = this.getUnionExtremes().dataMax;
          b !== h && (this.fixedWidth = f, a = c.navigatorAxis.toFixedRange(b, b + f, n, m), k(a.min) && d.xAxis[0].setExtremes(Math.min(a.min, a.max), Math.max(a.min, a.max), !0, null, {
            trigger: "navigator"
          }));
        }
      };
      d.prototype.handlesMousedown = function (b, a) {
        this.chart.pointer.normalize(b);
        b = this.chart;
        var d = b.xAxis[0],
          c = this.reversedExtremes;
        0 === a ? (this.grabbedLeft = !0, this.otherHandlePos = this.zoomedMax, this.fixedExtreme = c ? d.min : d.max) : (this.grabbedRight = !0, this.otherHandlePos = this.zoomedMin, this.fixedExtreme = c ? d.max : d.min);
        b.fixedRange = null;
      };
      d.prototype.onMouseMove = function (b) {
        var d = this,
          c = d.chart,
          e = d.navigatorSize,
          f = d.range,
          l = d.dragOffset,
          k = c.inverted,
          g = d.left;
        b.touches && 0 === b.touches[0].pageX || (b = c.pointer.normalize(b), c = b.chartX, k && (g = d.top, c = b.chartY), d.grabbedLeft ? (d.hasDragged = !0, d.render(0, 0, c - g, d.otherHandlePos)) : d.grabbedRight ? (d.hasDragged = !0, d.render(0, 0, d.otherHandlePos, c - g)) : d.grabbedCenter && (d.hasDragged = !0, c < l ? c = l : c > e + l - f && (c = e + l - f), d.render(0, 0, c - l, c - l + f)), d.hasDragged && d.scrollbar && w(d.scrollbar.options.liveRedraw, y.svg && !a && !this.chart.boosted) && (b.DOMType = b.type, setTimeout(function () {
          d.onMouseUp(b);
        }, 0)));
      };
      d.prototype.onMouseUp = function (b) {
        var a = this.chart,
          d = this.xAxis,
          c = this.scrollbar,
          e = b.DOMEvent || b,
          f = a.inverted,
          l = this.rendered && !this.hasDragged ? "animate" : "attr";
        if (this.hasDragged && (!c || !c.hasDragged) || "scrollbar" === b.trigger) {
          c = this.getUnionExtremes();
          if (this.zoomedMin === this.otherHandlePos) var g = this.fixedExtreme;else if (this.zoomedMax === this.otherHandlePos) var n = this.fixedExtreme;
          this.zoomedMax === this.size && (n = this.reversedExtremes ? c.dataMin : c.dataMax);
          0 === this.zoomedMin && (g = this.reversedExtremes ? c.dataMax : c.dataMin);
          d = d.navigatorAxis.toFixedRange(this.zoomedMin, this.zoomedMax, g, n);
          k(d.min) && a.xAxis[0].setExtremes(Math.min(d.min, d.max), Math.max(d.min, d.max), !0, this.hasDragged ? !1 : null, {
            trigger: "navigator",
            triggerOp: "navigator-drag",
            DOMEvent: e
          });
        }
        "mousemove" !== b.DOMType && "touchmove" !== b.DOMType && (this.grabbedLeft = this.grabbedRight = this.grabbedCenter = this.fixedWidth = this.fixedExtreme = this.otherHandlePos = this.hasDragged = this.dragOffset = null);
        this.navigatorEnabled && u(this.zoomedMin) && u(this.zoomedMax) && (a = Math.round(this.zoomedMin), b = Math.round(this.zoomedMax), this.shades && this.drawMasks(a, b, f, l), this.outline && this.drawOutline(a, b, f, l), this.navigatorOptions.handles.enabled && Object.keys(this.handles).length === this.handles.length && (this.drawHandle(a, 0, f, l), this.drawHandle(b, 1, f, l)));
      };
      d.prototype.removeEvents = function () {
        this.eventsToUnbind && (this.eventsToUnbind.forEach(function (b) {
          b();
        }), this.eventsToUnbind = void 0);
        this.removeBaseSeriesEvents();
      };
      d.prototype.removeBaseSeriesEvents = function () {
        var b = this.baseSeries || [];
        this.navigatorEnabled && b[0] && (!1 !== this.navigatorOptions.adaptToUpdatedData && b.forEach(function (b) {
          f(b, "updatedData", this.updatedDataHandler);
        }, this), b[0].xAxis && f(b[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes));
      };
      d.prototype.init = function (b) {
        var a = b.options,
          d = a.navigator || {},
          e = d.enabled,
          f = a.scrollbar || {},
          l = f.enabled;
        a = e && d.height || 0;
        var k = l && f.height || 0;
        this.handles = [];
        this.shades = [];
        this.chart = b;
        this.setBaseSeries();
        this.height = a;
        this.scrollbarHeight = k;
        this.scrollbarEnabled = l;
        this.navigatorEnabled = e;
        this.navigatorOptions = d;
        this.scrollbarOptions = f;
        this.outlineHeight = a + k;
        this.opposite = w(d.opposite, !(e || !b.inverted));
        var g = this;
        e = g.baseSeries;
        f = b.xAxis.length;
        l = b.yAxis.length;
        var m = e && e[0] && e[0].xAxis || b.xAxis[0] || {
          options: {}
        };
        b.isDirtyBox = !0;
        g.navigatorEnabled ? (g.xAxis = new c(b, n({
          breaks: m.options.breaks,
          ordinal: m.options.ordinal
        }, d.xAxis, {
          id: "navigator-x-axis",
          yAxis: "navigator-y-axis",
          isX: !0,
          type: "datetime",
          index: f,
          isInternal: !0,
          offset: 0,
          keepOrdinalPadding: !0,
          startOnTick: !1,
          endOnTick: !1,
          minPadding: 0,
          maxPadding: 0,
          zoomEnabled: !1
        }, b.inverted ? {
          offsets: [k, 0, -k, 0],
          width: a
        } : {
          offsets: [0, -k, 0, k],
          height: a
        })), g.yAxis = new c(b, n(d.yAxis, {
          id: "navigator-y-axis",
          alignTicks: !1,
          offset: 0,
          index: l,
          isInternal: !0,
          reversed: w(d.yAxis && d.yAxis.reversed, b.yAxis[0] && b.yAxis[0].reversed, !1),
          zoomEnabled: !1
        }, b.inverted ? {
          width: a
        } : {
          height: a
        })), e || d.series.data ? g.updateNavigatorSeries(!1) : 0 === b.series.length && (g.unbindRedraw = q(b, "beforeRedraw", function () {
          0 < b.series.length && !g.series && (g.setBaseSeries(), g.unbindRedraw());
        })), g.reversedExtremes = b.inverted && !g.xAxis.reversed || !b.inverted && g.xAxis.reversed, g.renderElements(), g.addMouseEvents()) : (g.xAxis = {
          chart: b,
          navigatorAxis: {
            fake: !0
          },
          translate: function (a, d) {
            var c = b.xAxis[0],
              e = c.getExtremes(),
              h = c.len - 2 * k,
              f = x("min", c.options.min, e.dataMin);
            c = x("max", c.options.max, e.dataMax) - f;
            return d ? a * c / h + f : h * (a - f) / c;
          },
          toPixels: function (b) {
            return this.translate(b);
          },
          toValue: function (b) {
            return this.translate(b, !0);
          }
        }, g.xAxis.navigatorAxis.axis = g.xAxis, g.xAxis.navigatorAxis.toFixedRange = A.prototype.toFixedRange.bind(g.xAxis.navigatorAxis));
        b.options.scrollbar.enabled && (b.scrollbar = g.scrollbar = new z(b.renderer, n(b.options.scrollbar, {
          margin: g.navigatorEnabled ? 0 : 10,
          vertical: b.inverted
        }), b), q(g.scrollbar, "changed", function (b) {
          var a = g.size,
            d = a * this.to;
          a *= this.from;
          g.hasDragged = g.scrollbar.hasDragged;
          g.render(0, 0, a, d);
          this.shouldUpdateExtremes(b.DOMType) && setTimeout(function () {
            g.onMouseUp(b);
          });
        }));
        g.addBaseSeriesEvents();
        g.addChartEvents();
      };
      d.prototype.getUnionExtremes = function (b) {
        var a = this.chart.xAxis[0],
          d = this.xAxis,
          c = d.options,
          e = a.options,
          f;
        b && null === a.dataMin || (f = {
          dataMin: w(c && c.min, x("min", e.min, a.dataMin, d.dataMin, d.min)),
          dataMax: w(c && c.max, x("max", e.max, a.dataMax, d.dataMax, d.max))
        });
        return f;
      };
      d.prototype.setBaseSeries = function (b, a) {
        var d = this.chart,
          c = this.baseSeries = [];
        b = b || d.options && d.options.navigator.baseSeries || (d.series.length ? H(d.series, function (b) {
          return !b.options.isInternal;
        }).index : 0);
        (d.series || []).forEach(function (a, d) {
          a.options.isInternal || !a.options.showInNavigator && (d !== b && a.options.id !== b || !1 === a.options.showInNavigator) || c.push(a);
        });
        this.xAxis && !this.xAxis.navigatorAxis.fake && this.updateNavigatorSeries(!0, a);
      };
      d.prototype.updateNavigatorSeries = function (b, a) {
        var d = this,
          c = d.chart,
          e = d.baseSeries,
          h = {
            enableMouseTracking: !1,
            index: null,
            linkedTo: null,
            group: "nav",
            padXAxis: !1,
            xAxis: "navigator-x-axis",
            yAxis: "navigator-y-axis",
            showInLegend: !1,
            stacking: void 0,
            isInternal: !0,
            states: {
              inactive: {
                opacity: 1
              }
            }
          },
          k = d.series = (d.series || []).filter(function (b) {
            var a = b.baseSeries;
            return 0 > e.indexOf(a) ? (a && (f(a, "updatedData", d.updatedDataHandler), delete a.navigatorSeries), b.chart && b.destroy(), !1) : !0;
          }),
          g,
          m,
          p = d.navigatorOptions.series,
          q;
        e && e.length && e.forEach(function (b) {
          var f = b.navigatorSeries,
            v = G({
              color: b.color,
              visible: b.visible
            }, K(p) ? t.navigator.series : p);
          f && !1 === d.navigatorOptions.adaptToUpdatedData || (h.name = "Navigator " + e.length, g = b.options || {}, q = g.navigatorOptions || {}, v.dataLabels = l(v.dataLabels), m = n(g, h, v, q), m.pointRange = w(v.pointRange, q.pointRange, t.plotOptions[m.type || "line"].pointRange), v = q.data || v.data, d.hasNavigatorData = d.hasNavigatorData || !!v, m.data = v || g.data && g.data.slice(0), f && f.options ? f.update(m, a) : (b.navigatorSeries = c.initSeries(m), b.navigatorSeries.baseSeries = b, k.push(b.navigatorSeries)));
        });
        if (p.data && (!e || !e.length) || K(p)) d.hasNavigatorData = !1, p = l(p), p.forEach(function (b, a) {
          h.name = "Navigator " + (k.length + 1);
          m = n(t.navigator.series, {
            color: c.series[a] && !c.series[a].options.isInternal && c.series[a].color || c.options.colors[a] || c.options.colors[0]
          }, h, b);
          m.data = b.data;
          m.data && (d.hasNavigatorData = !0, k.push(c.initSeries(m)));
        });
        b && this.addBaseSeriesEvents();
      };
      d.prototype.addBaseSeriesEvents = function () {
        var b = this,
          a = this,
          d = a.baseSeries || [];
        d[0] && d[0].xAxis && d[0].eventsToUnbind.push(q(d[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes));
        d.forEach(function (d) {
          d.eventsToUnbind.push(q(d, "show", function () {
            this.navigatorSeries && this.navigatorSeries.setVisible(!0, !1);
          }));
          d.eventsToUnbind.push(q(d, "hide", function () {
            this.navigatorSeries && this.navigatorSeries.setVisible(!1, !1);
          }));
          !1 !== b.navigatorOptions.adaptToUpdatedData && d.xAxis && d.eventsToUnbind.push(q(d, "updatedData", b.updatedDataHandler));
          d.eventsToUnbind.push(q(d, "remove", function () {
            this.navigatorSeries && (D(a.series, this.navigatorSeries), k(this.navigatorSeries.options) && this.navigatorSeries.remove(!1), delete this.navigatorSeries);
          }));
        });
      };
      d.prototype.getBaseSeriesMin = function (b) {
        return this.baseSeries.reduce(function (b, a) {
          return Math.min(b, a.xData && a.xData.length ? a.xData[0] : b);
        }, b);
      };
      d.prototype.modifyNavigatorAxisExtremes = function () {
        var b = this.xAxis;
        if ("undefined" !== typeof b.getExtremes) {
          var a = this.getUnionExtremes(!0);
          !a || a.dataMin === b.min && a.dataMax === b.max || (b.min = a.dataMin, b.max = a.dataMax);
        }
      };
      d.prototype.modifyBaseAxisExtremes = function () {
        var b = this.chart.navigator,
          a = this.getExtremes(),
          d = a.dataMin,
          c = a.dataMax;
        a = a.max - a.min;
        var e = b.stickToMin,
          f = b.stickToMax,
          l = w(this.options.overscroll, 0),
          k = b.series && b.series[0],
          g = !!this.setExtremes;
        if (!this.eventArgs || "rangeSelectorButton" !== this.eventArgs.trigger) {
          if (e) {
            var n = d;
            var m = n + a;
          }
          f && (m = c + l, e || (n = Math.max(d, m - a, b.getBaseSeriesMin(k && k.xData ? k.xData[0] : -Number.MAX_VALUE))));
          g && (e || f) && u(n) && (this.min = this.userMin = n, this.max = this.userMax = m);
        }
        b.stickToMin = b.stickToMax = null;
      };
      d.prototype.updatedDataHandler = function () {
        var b = this.chart.navigator,
          a = this.navigatorSeries;
        b.stickToMax = w(this.chart.options.navigator && this.chart.options.navigator.stickToMax, b.reversedExtremes ? 0 === Math.round(b.zoomedMin) : Math.round(b.zoomedMax) >= Math.round(b.size));
        b.stickToMin = b.shouldStickToMin(this, b);
        a && !b.hasNavigatorData && (a.options.pointStart = this.xData[0], a.setData(this.options.data, !1, null, !1));
      };
      d.prototype.shouldStickToMin = function (b, a) {
        a = a.getBaseSeriesMin(b.xData[0]);
        var d = b.xAxis;
        b = d.max;
        var c = d.min;
        d = d.options.range;
        return u(b) && u(c) ? d && 0 < b - a ? b - a < d : c <= a : !1;
      };
      d.prototype.addChartEvents = function () {
        this.eventsToUnbind || (this.eventsToUnbind = []);
        this.eventsToUnbind.push(q(this.chart, "redraw", function () {
          var b = this.navigator,
            a = b && (b.baseSeries && b.baseSeries[0] && b.baseSeries[0].xAxis || this.xAxis[0]);
          a && b.render(a.min, a.max);
        }), q(this.chart, "getMargins", function () {
          var b = this.navigator,
            a = b.opposite ? "plotTop" : "marginBottom";
          this.inverted && (a = b.opposite ? "marginRight" : "plotLeft");
          this[a] = (this[a] || 0) + (b.navigatorEnabled || !this.inverted ? b.outlineHeight : 0) + b.navigatorOptions.margin;
        }));
      };
      d.prototype.destroy = function () {
        var b = this;
        this.removeEvents();
        this.xAxis && (D(this.chart.xAxis, this.xAxis), D(this.chart.axes, this.xAxis));
        this.yAxis && (D(this.chart.yAxis, this.yAxis), D(this.chart.axes, this.yAxis));
        (this.series || []).forEach(function (b) {
          b.destroy && b.destroy();
        });
        "series xAxis yAxis shades outline scrollbarTrack scrollbarRifles scrollbarGroup scrollbar navigatorGroup rendered".split(" ").forEach(function (a) {
          b[a] && b[a].destroy && b[a].destroy();
          b[a] = null;
        });
        [this.handles].forEach(function (b) {
          e(b);
        });
      };
      return d;
    }();
  });
  I(g, "Stock/RangeSelector/RangeSelectorDefaults.js", [], function () {
    return {
      lang: {
        rangeSelectorZoom: "Zoom",
        rangeSelectorFrom: "",
        rangeSelectorTo: "\u2192"
      },
      rangeSelector: {
        allButtonsEnabled: !1,
        buttons: void 0,
        buttonSpacing: 5,
        dropdown: "responsive",
        enabled: void 0,
        verticalAlign: "top",
        buttonTheme: {
          width: 28,
          height: 18,
          padding: 2,
          zIndex: 7
        },
        floating: !1,
        x: 0,
        y: 0,
        height: void 0,
        inputBoxBorderColor: "none",
        inputBoxHeight: 17,
        inputBoxWidth: void 0,
        inputDateFormat: "%b %e, %Y",
        inputDateParser: void 0,
        inputEditDateFormat: "%Y-%m-%d",
        inputEnabled: !0,
        inputPosition: {
          align: "right",
          x: 0,
          y: 0
        },
        inputSpacing: 5,
        selected: void 0,
        buttonPosition: {
          align: "left",
          x: 0,
          y: 0
        },
        inputStyle: {
          color: "#335cad",
          cursor: "pointer"
        },
        labelStyle: {
          color: "#666666"
        }
      }
    };
  });
  I(g, "Stock/RangeSelector/RangeSelectorComposition.js", [g["Core/Defaults.js"], g["Stock/RangeSelector/RangeSelectorDefaults.js"], g["Core/Utilities.js"]], function (c, g, y) {
    function A() {
      var a = this.range,
        c = a.type,
        d = this.max,
        b = this.chart.time,
        e = function (a, d) {
          var e = "year" === c ? "FullYear" : "Month",
            f = new b.Date(a),
            h = b.get(e, f);
          b.set(e, f, h + d);
          h === b.get(e, f) && b.set("Date", f, 0);
          return f.getTime() - a;
        };
      if (G(a)) {
        var k = d - a;
        var g = a;
      } else a && (k = d + e(d, -(a.count || 1)), this.chart && (this.chart.fixedRange = d - k));
      var n = K(this.dataMin, Number.MIN_VALUE);
      G(k) || (k = n);
      k <= n && (k = n, "undefined" === typeof g && (g = e(k, a.count)), this.newMax = Math.min(k + g, K(this.dataMax, Number.MAX_VALUE)));
      G(d) ? !G(a) && a && a._offsetMin && (k += a._offsetMin) : k = void 0;
      return k;
    }
    function B() {
      this.options.rangeSelector && this.options.rangeSelector.enabled && (this.rangeSelector = new w(this));
    }
    function z() {
      var a = this.axes,
        c = this.rangeSelector;
      c && (G(c.deferredYTDClick) && (c.clickButton(c.deferredYTDClick), delete c.deferredYTDClick), a.forEach(function (a) {
        a.updateNames();
        a.setScale();
      }), this.getAxisMargins(), c.render(), a = c.options.verticalAlign, c.options.floating || ("bottom" === a ? this.extraBottomMargin = !0 : "middle" !== a && (this.extraTopMargin = !0)));
    }
    function C(a) {
      var c,
        d,
        b,
        e,
        f = a.rangeSelector,
        k = function () {
          f && (c = a.xAxis[0].getExtremes(), d = a.legend, e = f && f.options.verticalAlign, G(c.min) && f.render(c.min, c.max), d.display && "top" === e && e === d.options.verticalAlign && (b = H(a.spacingBox), b.y = "vertical" === d.options.layout ? a.plotTop : b.y + f.getHeight(), d.group.placed = !1, d.align(b)));
        };
      f && (D(u, function (b) {
        return b[0] === a;
      }) || u.push([a, [p(a.xAxis[0], "afterSetExtremes", function (b) {
        f && f.render(b.min, b.max);
      }), p(a, "redraw", k)]]), k());
    }
    function x() {
      for (var a = 0, c = u.length; a < c; ++a) {
        var d = u[a];
        if (d[0] === this) {
          d[1].forEach(function (b) {
            return b();
          });
          u.splice(a, 1);
          break;
        }
      }
    }
    function t() {
      var a = this.rangeSelector;
      a && (a = a.getHeight(), this.extraTopMargin && (this.plotTop += a), this.extraBottomMargin && (this.marginBottom += a));
    }
    function r() {
      var a = this.rangeSelector;
      a && !a.options.floating && (a.render(), a = a.options.verticalAlign, "bottom" === a ? this.extraBottomMargin = !0 : "middle" !== a && (this.extraTopMargin = !0));
    }
    function a(a) {
      var c = a.options.rangeSelector;
      a = this.extraBottomMargin;
      var d = this.extraTopMargin,
        b = this.rangeSelector;
      c && c.enabled && !k(b) && this.options.rangeSelector && (this.options.rangeSelector.enabled = !0, this.rangeSelector = b = new w(this));
      this.extraTopMargin = this.extraBottomMargin = !1;
      b && (C(this), c = c && c.verticalAlign || b.options && b.options.verticalAlign, b.options.floating || ("bottom" === c ? this.extraBottomMargin = !0 : "middle" !== c && (this.extraTopMargin = !0)), this.extraBottomMargin !== a || this.extraTopMargin !== d) && (this.isDirtyBox = !0);
    }
    var q = c.defaultOptions,
      m = c.setOptions,
      p = y.addEvent,
      k = y.defined,
      e = y.extend,
      D = y.find,
      G = y.isNumber,
      H = y.merge,
      K = y.pick,
      u = [],
      n = [],
      w;
    return {
      compose: function (c, l, d) {
        w = d;
        -1 === n.indexOf(c) && (n.push(c), c.prototype.minFromRange = A);
        -1 === n.indexOf(l) && (n.push(l), p(l, "afterGetContainer", B), p(l, "beforeRender", z), p(l, "destroy", x), p(l, "getMargins", t), p(l, "render", r), p(l, "update", a), l.prototype.callbacks.push(C));
        -1 === n.indexOf(m) && (e(q, {
          rangeSelector: g.rangeSelector
        }), e(q.lang, g.lang));
      }
    };
  });
  I(g, "Stock/RangeSelector/RangeSelector.js", [g["Core/Axis/Axis.js"], g["Core/Defaults.js"], g["Core/Globals.js"], g["Stock/RangeSelector/RangeSelectorComposition.js"], g["Core/Renderer/SVG/SVGElement.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z) {
    function C(a) {
      if (-1 !== a.indexOf("%L")) return "text";
      var c = "aAdewbBmoyY".split("").some(function (b) {
          return -1 !== a.indexOf("%" + b);
        }),
        d = "HkIlMS".split("").some(function (b) {
          return -1 !== a.indexOf("%" + b);
        });
      return c && d ? "datetime-local" : c ? "date" : d ? "time" : "text";
    }
    var x = g.defaultOptions,
      t = z.addEvent,
      r = z.createElement,
      a = z.css,
      q = z.defined,
      m = z.destroyObjectProperties,
      p = z.discardElement,
      k = z.extend,
      e = z.fireEvent,
      D = z.isNumber,
      G = z.merge,
      H = z.objectEach,
      K = z.pad,
      u = z.pick,
      n = z.pInt,
      w = z.splat;
    g = function () {
      function f(a) {
        this.buttons = void 0;
        this.buttonOptions = f.prototype.defaultButtons;
        this.initialButtonGroupWidth = 0;
        this.options = void 0;
        this.chart = a;
        this.init(a);
      }
      f.compose = function (a, d) {
        A.compose(a, d, f);
      };
      f.prototype.clickButton = function (a, d) {
        var b = this.chart,
          h = this.buttonOptions[a],
          f = b.xAxis[0],
          l = b.scroller && b.scroller.getUnionExtremes() || f || {},
          k = h.type,
          g = h.dataGrouping,
          n = l.dataMin,
          m = l.dataMax,
          p = f && Math.round(Math.min(f.max, u(m, f.max)));
        l = h._range;
        var r,
          x = !0;
        if (null !== n && null !== m) {
          b.fixedRange = l;
          this.setSelected(a);
          g && (this.forcedDataGrouping = !0, c.prototype.setDataGrouping.call(f || {
            chart: this.chart
          }, g, !1), this.frozenStates = h.preserveDataGrouping);
          if ("month" === k || "year" === k) {
            if (f) {
              k = {
                range: h,
                max: p,
                chart: b,
                dataMin: n,
                dataMax: m
              };
              var y = f.minFromRange.call(k);
              D(k.newMax) && (p = k.newMax);
              x = !1;
            } else l = h;
          } else if (l) y = Math.max(p - l, n), p = Math.min(y + l, m), x = !1;else if ("ytd" === k) {
            if (f) {
              if ("undefined" === typeof m || "undefined" === typeof n) n = Number.MAX_VALUE, m = Number.MIN_VALUE, b.series.forEach(function (b) {
                if (b = b.xData) n = Math.min(b[0], n), m = Math.max(b[b.length - 1], m);
              }), d = !1;
              k = this.getYTDExtremes(m, n, b.time.useUTC);
              y = r = k.min;
              p = k.max;
            } else {
              this.deferredYTDClick = a;
              return;
            }
          } else "all" === k && f && (b.navigator && b.navigator.baseSeries[0] && (b.navigator.baseSeries[0].xAxis.options.range = void 0), y = n, p = m);
          x && h._offsetMin && q(y) && (y += h._offsetMin);
          h._offsetMax && q(p) && (p += h._offsetMax);
          this.dropdown && (this.dropdown.selectedIndex = a + 1);
          if (f) f.setExtremes(y, p, u(d, !0), void 0, {
            trigger: "rangeSelectorButton",
            rangeSelectorButton: h
          });else {
            var C = w(b.options.xAxis)[0];
            var z = C.range;
            C.range = l;
            var G = C.min;
            C.min = r;
            t(b, "load", function () {
              C.range = z;
              C.min = G;
            });
          }
          e(this, "afterBtnClick");
        }
      };
      f.prototype.setSelected = function (a) {
        this.selected = this.options.selected = a;
      };
      f.prototype.init = function (a) {
        var d = this,
          b = a.options.rangeSelector,
          c = b.buttons || d.defaultButtons.slice(),
          f = b.selected,
          l = function () {
            var b = d.minInput,
              a = d.maxInput;
            b && b.blur && e(b, "blur");
            a && a.blur && e(a, "blur");
          };
        d.chart = a;
        d.options = b;
        d.buttons = [];
        d.buttonOptions = c;
        this.eventsToUnbind = [];
        this.eventsToUnbind.push(t(a.container, "mousedown", l));
        this.eventsToUnbind.push(t(a, "resize", l));
        c.forEach(d.computeButtonRange);
        "undefined" !== typeof f && c[f] && this.clickButton(f, !1);
        this.eventsToUnbind.push(t(a, "load", function () {
          a.xAxis && a.xAxis[0] && t(a.xAxis[0], "setExtremes", function (b) {
            this.max - this.min !== a.fixedRange && "rangeSelectorButton" !== b.trigger && "updatedData" !== b.trigger && d.forcedDataGrouping && !d.frozenStates && this.setDataGrouping(!1, !1);
          });
        }));
      };
      f.prototype.updateButtonStates = function () {
        var a = this,
          d = this.chart,
          b = this.dropdown,
          c = d.xAxis[0],
          e = Math.round(c.max - c.min),
          f = !c.hasVisibleSeries,
          k = d.scroller && d.scroller.getUnionExtremes() || c,
          g = k.dataMin,
          n = k.dataMax;
        d = a.getYTDExtremes(n, g, d.time.useUTC);
        var m = d.min,
          p = d.max,
          q = a.selected,
          w = a.options.allButtonsEnabled,
          u = a.buttons,
          r = D(q);
        a.buttonOptions.forEach(function (d, h) {
          var l = d._range,
            k = d.type,
            v = d.count || 1,
            t = u[h],
            x = d._offsetMax - d._offsetMin,
            F = h === q,
            E = l > n - g,
            D = l < c.minRange;
          d = 0;
          var y = !1,
            C = !1;
          l = l === e;
          ("month" === k || "year" === k) && e + 36E5 >= 864E5 * {
            month: 28,
            year: 365
          }[k] * v - x && e - 36E5 <= 864E5 * {
            month: 31,
            year: 366
          }[k] * v + x ? l = !0 : "ytd" === k ? (l = p - m + x === e, y = !F) : "all" === k && (l = c.max - c.min >= n - g, C = !F && r && l);
          k = !w && (E || D || C || f);
          v = F && l || l && !r && !y || F && a.frozenStates;
          k ? d = 3 : v && (r = !0, d = 2);
          t.state !== d && (t.setState(d), b && (b.options[h + 1].disabled = k, 2 === d && (b.selectedIndex = h + 1)), 0 === d && q === h && a.setSelected());
        });
      };
      f.prototype.computeButtonRange = function (a) {
        var d = a.type,
          b = a.count || 1,
          c = {
            millisecond: 1,
            second: 1E3,
            minute: 6E4,
            hour: 36E5,
            day: 864E5,
            week: 6048E5
          };
        if (c[d]) a._range = c[d] * b;else if ("month" === d || "year" === d) a._range = 864E5 * {
          month: 30,
          year: 365
        }[d] * b;
        a._offsetMin = u(a.offsetMin, 0);
        a._offsetMax = u(a.offsetMax, 0);
        a._range += a._offsetMax - a._offsetMin;
      };
      f.prototype.getInputValue = function (a) {
        a = "min" === a ? this.minInput : this.maxInput;
        var d = this.chart.options.rangeSelector,
          b = this.chart.time;
        return a ? ("text" === a.type && d.inputDateParser || this.defaultInputDateParser)(a.value, b.useUTC, b) : 0;
      };
      f.prototype.setInputValue = function (a, d) {
        var b = this.options,
          c = this.chart.time,
          e = "min" === a ? this.minInput : this.maxInput;
        a = "min" === a ? this.minDateBox : this.maxDateBox;
        if (e) {
          var f = e.getAttribute("data-hc-time");
          f = q(f) ? Number(f) : void 0;
          q(d) && (q(f) && e.setAttribute("data-hc-time-previous", f), e.setAttribute("data-hc-time", d), f = d);
          e.value = c.dateFormat(this.inputTypeFormats[e.type] || b.inputEditDateFormat, f);
          a && a.attr({
            text: c.dateFormat(b.inputDateFormat, f)
          });
        }
      };
      f.prototype.setInputExtremes = function (a, d, b) {
        if (a = "min" === a ? this.minInput : this.maxInput) {
          var c = this.inputTypeFormats[a.type],
            e = this.chart.time;
          c && (d = e.dateFormat(c, d), a.min !== d && (a.min = d), b = e.dateFormat(c, b), a.max !== b && (a.max = b));
        }
      };
      f.prototype.showInput = function (c) {
        var d = "min" === c ? this.minDateBox : this.maxDateBox;
        if ((c = "min" === c ? this.minInput : this.maxInput) && d && this.inputGroup) {
          var b = "text" === c.type,
            e = this.inputGroup,
            f = e.translateX;
          e = e.translateY;
          var l = this.options.inputBoxWidth;
          a(c, {
            width: b ? d.width + (l ? -2 : 20) + "px" : "auto",
            height: b ? d.height - 2 + "px" : "auto",
            border: "2px solid silver"
          });
          b && l ? a(c, {
            left: f + d.x + "px",
            top: e + "px"
          }) : a(c, {
            left: Math.min(Math.round(d.x + f - (c.offsetWidth - d.width) / 2), this.chart.chartWidth - c.offsetWidth) + "px",
            top: e - (c.offsetHeight - d.height) / 2 + "px"
          });
        }
      };
      f.prototype.hideInput = function (c) {
        (c = "min" === c ? this.minInput : this.maxInput) && a(c, {
          top: "-9999em",
          border: 0,
          width: "1px",
          height: "1px"
        });
      };
      f.prototype.defaultInputDateParser = function (a, d, b) {
        var c = a.split("/").join("-").split(" ").join("T");
        -1 === c.indexOf("T") && (c += "T00:00");
        if (d) c += "Z";else {
          var e;
          if (e = y.isSafari) e = c, e = !(6 < e.length && (e.lastIndexOf("-") === e.length - 6 || e.lastIndexOf("+") === e.length - 6));
          e && (e = new Date(c).getTimezoneOffset() / 60, c += 0 >= e ? "+".concat(K(-e), ":00") : "-".concat(K(e), ":00"));
        }
        c = Date.parse(c);
        D(c) || (a = a.split("-"), c = Date.UTC(n(a[0]), n(a[1]) - 1, n(a[2])));
        b && d && D(c) && (c += b.getTimezoneOffset(c));
        return c;
      };
      f.prototype.drawInput = function (c) {
        function d() {
          var a = l.maxInput,
            d = l.minInput,
            e = b.xAxis[0],
            f = b.scroller && b.scroller.xAxis ? b.scroller.xAxis : e,
            h = f.dataMin;
          f = f.dataMax;
          var k = l.getInputValue(c);
          k !== Number(w.getAttribute("data-hc-time-previous")) && D(k) && (w.setAttribute("data-hc-time-previous", k), p && a && D(h) ? k > Number(a.getAttribute("data-hc-time")) ? k = void 0 : k < h && (k = h) : d && D(f) && (k < Number(d.getAttribute("data-hc-time")) ? k = void 0 : k > f && (k = f)), "undefined" !== typeof k && e.setExtremes(p ? k : e.min, p ? e.max : k, void 0, void 0, {
            trigger: "rangeSelectorInput"
          }));
        }
        var b = this.chart,
          e = this.div,
          f = this.inputGroup,
          l = this,
          g = b.renderer.style || {},
          n = b.renderer,
          m = b.options.rangeSelector,
          p = "min" === c,
          q = x.lang[p ? "rangeSelectorFrom" : "rangeSelectorTo"] || "";
        q = n.label(q, 0).addClass("highcharts-range-label").attr({
          padding: q ? 2 : 0,
          height: q ? m.inputBoxHeight : 0
        }).add(f);
        n = n.label("", 0).addClass("highcharts-range-input").attr({
          padding: 2,
          width: m.inputBoxWidth,
          height: m.inputBoxHeight,
          "text-align": "center"
        }).on("click", function () {
          l.showInput(c);
          l[c + "Input"].focus();
        });
        b.styledMode || n.attr({
          stroke: m.inputBoxBorderColor,
          "stroke-width": 1
        });
        n.add(f);
        var w = r("input", {
          name: c,
          className: "highcharts-range-selector"
        }, void 0, e);
        w.setAttribute("type", C(m.inputDateFormat || "%b %e, %Y"));
        b.styledMode || (q.css(G(g, m.labelStyle)), n.css(G({
          color: "#333333"
        }, g, m.inputStyle)), a(w, k({
          position: "absolute",
          border: 0,
          boxShadow: "0 0 15px rgba(0,0,0,0.3)",
          width: "1px",
          height: "1px",
          padding: 0,
          textAlign: "center",
          fontSize: g.fontSize,
          fontFamily: g.fontFamily,
          top: "-9999em"
        }, m.inputStyle)));
        w.onfocus = function () {
          l.showInput(c);
        };
        w.onblur = function () {
          w === y.doc.activeElement && d();
          l.hideInput(c);
          l.setInputValue(c);
          w.blur();
        };
        var u = !1;
        w.onchange = function () {
          u || (d(), l.hideInput(c), w.blur());
        };
        w.onkeypress = function (b) {
          13 === b.keyCode && d();
        };
        w.onkeydown = function (b) {
          u = !0;
          38 !== b.keyCode && 40 !== b.keyCode || d();
        };
        w.onkeyup = function () {
          u = !1;
        };
        return {
          dateBox: n,
          input: w,
          label: q
        };
      };
      f.prototype.getPosition = function () {
        var a = this.chart,
          d = a.options.rangeSelector;
        a = "top" === d.verticalAlign ? a.plotTop - a.axisOffset[0] : 0;
        return {
          buttonTop: a + d.buttonPosition.y,
          inputTop: a + d.inputPosition.y - 10
        };
      };
      f.prototype.getYTDExtremes = function (a, d, b) {
        var c = this.chart.time,
          e = new c.Date(a),
          f = c.get("FullYear", e);
        b = b ? c.Date.UTC(f, 0, 1) : +new c.Date(f, 0, 1);
        d = Math.max(d, b);
        e = e.getTime();
        return {
          max: Math.min(a || e, e),
          min: d
        };
      };
      f.prototype.render = function (a, d) {
        var b = this.chart,
          c = b.renderer,
          e = b.container,
          f = b.options,
          l = f.rangeSelector,
          k = u(f.chart.style && f.chart.style.zIndex, 0) + 1;
        f = l.inputEnabled;
        if (!1 !== l.enabled) {
          this.rendered || (this.group = c.g("range-selector-group").attr({
            zIndex: 7
          }).add(), this.div = r("div", void 0, {
            position: "relative",
            height: 0,
            zIndex: k
          }), this.buttonOptions.length && this.renderButtons(), e.parentNode && e.parentNode.insertBefore(this.div, e), f && (this.inputGroup = c.g("input-group").add(this.group), c = this.drawInput("min"), this.minDateBox = c.dateBox, this.minLabel = c.label, this.minInput = c.input, c = this.drawInput("max"), this.maxDateBox = c.dateBox, this.maxLabel = c.label, this.maxInput = c.input));
          if (f && (this.setInputValue("min", a), this.setInputValue("max", d), a = b.scroller && b.scroller.getUnionExtremes() || b.xAxis[0] || {}, q(a.dataMin) && q(a.dataMax) && (b = b.xAxis[0].minRange || 0, this.setInputExtremes("min", a.dataMin, Math.min(a.dataMax, this.getInputValue("max")) - b), this.setInputExtremes("max", Math.max(a.dataMin, this.getInputValue("min")) + b, a.dataMax)), this.inputGroup)) {
            var g = 0;
            [this.minLabel, this.minDateBox, this.maxLabel, this.maxDateBox].forEach(function (b) {
              if (b) {
                var a = b.getBBox().width;
                a && (b.attr({
                  x: g
                }), g += a + l.inputSpacing);
              }
            });
          }
          this.alignElements();
          this.rendered = !0;
        }
      };
      f.prototype.renderButtons = function () {
        var a = this,
          d = this.buttons,
          b = this.options,
          c = x.lang,
          f = this.chart.renderer,
          k = G(b.buttonTheme),
          g = k && k.states,
          n = k.width || 28;
        delete k.width;
        delete k.states;
        this.buttonGroup = f.g("range-selector-buttons").add(this.group);
        var m = this.dropdown = r("select", void 0, {
          position: "absolute",
          width: "1px",
          height: "1px",
          padding: 0,
          border: 0,
          top: "-9999em",
          cursor: "pointer",
          opacity: .0001
        }, this.div);
        t(m, "touchstart", function () {
          m.style.fontSize = "16px";
        });
        [[y.isMS ? "mouseover" : "mouseenter"], [y.isMS ? "mouseout" : "mouseleave"], ["change", "click"]].forEach(function (b) {
          var c = b[0],
            f = b[1];
          t(m, c, function () {
            var b = d[a.currentButtonIndex()];
            b && e(b.element, f || c);
          });
        });
        this.zoomText = f.label(c && c.rangeSelectorZoom || "", 0).attr({
          padding: b.buttonTheme.padding,
          height: b.buttonTheme.height,
          paddingLeft: 0,
          paddingRight: 0
        }).add(this.buttonGroup);
        this.chart.styledMode || (this.zoomText.css(b.labelStyle), k["stroke-width"] = u(k["stroke-width"], 0));
        r("option", {
          textContent: this.zoomText.textStr,
          disabled: !0
        }, void 0, m);
        this.buttonOptions.forEach(function (b, c) {
          r("option", {
            textContent: b.title || b.text
          }, void 0, m);
          d[c] = f.button(b.text, 0, 0, function (d) {
            var e = b.events && b.events.click,
              f;
            e && (f = e.call(b, d));
            !1 !== f && a.clickButton(c);
            a.isActive = !0;
          }, k, g && g.hover, g && g.select, g && g.disabled).attr({
            "text-align": "center",
            width: n
          }).add(a.buttonGroup);
          b.title && d[c].attr("title", b.title);
        });
      };
      f.prototype.alignElements = function () {
        var a = this,
          d = this.buttonGroup,
          b = this.buttons,
          c = this.chart,
          e = this.group,
          f = this.inputGroup,
          k = this.options,
          g = this.zoomText,
          n = c.options,
          m = n.exporting && !1 !== n.exporting.enabled && n.navigation && n.navigation.buttonOptions;
        n = k.buttonPosition;
        var p = k.inputPosition,
          q = k.verticalAlign,
          w = function (b, d) {
            return m && a.titleCollision(c) && "top" === q && "right" === d.align && d.y - b.getBBox().height - 12 < (m.y || 0) + (m.height || 0) + c.spacing[0] ? -40 : 0;
          },
          r = c.plotLeft;
        if (e && n && p) {
          var t = n.x - c.spacing[3];
          if (d) {
            this.positionButtons();
            if (!this.initialButtonGroupWidth) {
              var x = 0;
              g && (x += g.getBBox().width + 5);
              b.forEach(function (a, d) {
                x += a.width;
                d !== b.length - 1 && (x += k.buttonSpacing);
              });
              this.initialButtonGroupWidth = x;
            }
            r -= c.spacing[3];
            this.updateButtonStates();
            g = w(d, n);
            this.alignButtonGroup(g);
            e.placed = d.placed = c.hasLoaded;
          }
          d = 0;
          f && (d = w(f, p), "left" === p.align ? t = r : "right" === p.align && (t = -Math.max(c.axisOffset[1], -d)), f.align({
            y: p.y,
            width: f.getBBox().width,
            align: p.align,
            x: p.x + t - 2
          }, !0, c.spacingBox), f.placed = c.hasLoaded);
          this.handleCollision(d);
          e.align({
            verticalAlign: q
          }, !0, c.spacingBox);
          f = e.alignAttr.translateY;
          d = e.getBBox().height + 20;
          w = 0;
          "bottom" === q && (w = (w = c.legend && c.legend.options) && "bottom" === w.verticalAlign && w.enabled && !w.floating ? c.legend.legendHeight + u(w.margin, 10) : 0, d = d + w - 20, w = f - d - (k.floating ? 0 : k.y) - (c.titleOffset ? c.titleOffset[2] : 0) - 10);
          if ("top" === q) k.floating && (w = 0), c.titleOffset && c.titleOffset[0] && (w = c.titleOffset[0]), w += c.margin[0] - c.spacing[0] || 0;else if ("middle" === q) if (p.y === n.y) w = f;else if (p.y || n.y) w = 0 > p.y || 0 > n.y ? w - Math.min(p.y, n.y) : f - d;
          e.translate(k.x, k.y + Math.floor(w));
          n = this.minInput;
          p = this.maxInput;
          f = this.dropdown;
          k.inputEnabled && n && p && (n.style.marginTop = e.translateY + "px", p.style.marginTop = e.translateY + "px");
          f && (f.style.marginTop = e.translateY + "px");
        }
      };
      f.prototype.alignButtonGroup = function (a, d) {
        var b = this.chart,
          c = this.buttonGroup,
          e = this.options.buttonPosition,
          f = b.plotLeft - b.spacing[3],
          l = e.x - b.spacing[3];
        "right" === e.align ? l += a - f : "center" === e.align && (l -= f / 2);
        c && c.align({
          y: e.y,
          width: u(d, this.initialButtonGroupWidth),
          align: e.align,
          x: l
        }, !0, b.spacingBox);
      };
      f.prototype.positionButtons = function () {
        var a = this.buttons,
          d = this.chart,
          b = this.options,
          c = this.zoomText,
          e = d.hasLoaded ? "animate" : "attr",
          f = b.buttonPosition,
          k = d = d.plotLeft;
        c && "hidden" !== c.visibility && (c[e]({
          x: u(d + f.x, d)
        }), k += f.x + c.getBBox().width + 5);
        c = 0;
        for (f = this.buttonOptions.length; c < f; ++c) if ("hidden" !== a[c].visibility) a[c][e]({
          x: k
        }), k += a[c].width + b.buttonSpacing;else a[c][e]({
          x: d
        });
      };
      f.prototype.handleCollision = function (a) {
        var d = this,
          b = this.chart,
          c = this.buttonGroup,
          e = this.inputGroup,
          f = this.options,
          l = f.buttonPosition,
          k = f.dropdown,
          g = f.inputPosition;
        f = function () {
          var b = 0;
          d.buttons.forEach(function (a) {
            a = a.getBBox();
            a.width > b && (b = a.width);
          });
          return b;
        };
        var n = function (b) {
            if (e && c) {
              var d = e.alignAttr.translateX + e.alignOptions.x - a + e.getBBox().x + 2,
                f = e.alignOptions.width,
                h = c.alignAttr.translateX + c.getBBox().x;
              return h + b > d && d + f > h && l.y < g.y + e.getBBox().height;
            }
            return !1;
          },
          m = function () {
            e && c && e.attr({
              translateX: e.alignAttr.translateX + (b.axisOffset[1] >= -a ? 0 : -a),
              translateY: e.alignAttr.translateY + c.getBBox().height + 10
            });
          };
        if (c) {
          if ("always" === k) {
            this.collapseButtons(a);
            n(f()) && m();
            return;
          }
          "never" === k && this.expandButtons();
        }
        e && c ? g.align === l.align || n(this.initialButtonGroupWidth + 20) ? "responsive" === k ? (this.collapseButtons(a), n(f()) && m()) : m() : "responsive" === k && this.expandButtons() : c && "responsive" === k && (this.initialButtonGroupWidth > b.plotWidth ? this.collapseButtons(a) : this.expandButtons());
      };
      f.prototype.collapseButtons = function (a) {
        var d = this.buttons,
          b = this.buttonOptions,
          c = this.chart,
          e = this.dropdown,
          f = this.options,
          l = this.zoomText,
          k = c.userOptions.rangeSelector && c.userOptions.rangeSelector.buttonTheme || {},
          g = function (b) {
            return {
              text: b ? "" + b + " \u25be" : "\u25be",
              width: "auto",
              paddingLeft: u(f.buttonTheme.paddingLeft, k.padding, 8),
              paddingRight: u(f.buttonTheme.paddingRight, k.padding, 8)
            };
          };
        l && l.hide();
        var n = !1;
        b.forEach(function (b, a) {
          a = d[a];
          2 !== a.state ? a.hide() : (a.show(), a.attr(g(b.text)), n = !0);
        });
        n || (e && (e.selectedIndex = 0), d[0].show(), d[0].attr(g(this.zoomText && this.zoomText.textStr)));
        b = f.buttonPosition.align;
        this.positionButtons();
        "right" !== b && "center" !== b || this.alignButtonGroup(a, d[this.currentButtonIndex()].getBBox().width);
        this.showDropdown();
      };
      f.prototype.expandButtons = function () {
        var a = this.buttons,
          d = this.buttonOptions,
          b = this.options,
          c = this.zoomText;
        this.hideDropdown();
        c && c.show();
        d.forEach(function (d, c) {
          c = a[c];
          c.show();
          c.attr({
            text: d.text,
            width: b.buttonTheme.width || 28,
            paddingLeft: u(b.buttonTheme.paddingLeft, "unset"),
            paddingRight: u(b.buttonTheme.paddingRight, "unset")
          });
          2 > c.state && c.setState(0);
        });
        this.positionButtons();
      };
      f.prototype.currentButtonIndex = function () {
        var a = this.dropdown;
        return a && 0 < a.selectedIndex ? a.selectedIndex - 1 : 0;
      };
      f.prototype.showDropdown = function () {
        var c = this.buttonGroup,
          d = this.buttons,
          b = this.chart,
          e = this.dropdown;
        if (c && e) {
          var f = c.translateX;
          c = c.translateY;
          d = d[this.currentButtonIndex()].getBBox();
          a(e, {
            left: b.plotLeft + f + "px",
            top: c + .5 + "px",
            width: d.width + "px",
            height: d.height + "px"
          });
          this.hasVisibleDropdown = !0;
        }
      };
      f.prototype.hideDropdown = function () {
        var c = this.dropdown;
        c && (a(c, {
          top: "-9999em",
          width: "1px",
          height: "1px"
        }), this.hasVisibleDropdown = !1);
      };
      f.prototype.getHeight = function () {
        var a = this.options,
          d = this.group,
          b = a.y,
          c = a.buttonPosition.y,
          e = a.inputPosition.y;
        if (a.height) return a.height;
        this.alignElements();
        a = d ? d.getBBox(!0).height + 13 + b : 0;
        d = Math.min(e, c);
        if (0 > e && 0 > c || 0 < e && 0 < c) a += Math.abs(d);
        return a;
      };
      f.prototype.titleCollision = function (a) {
        return !(a.options.title.text || a.options.subtitle.text);
      };
      f.prototype.update = function (a) {
        var d = this.chart;
        G(!0, d.options.rangeSelector, a);
        this.destroy();
        this.init(d);
        this.render();
      };
      f.prototype.destroy = function () {
        var a = this,
          d = a.minInput,
          b = a.maxInput;
        a.eventsToUnbind && (a.eventsToUnbind.forEach(function (b) {
          return b();
        }), a.eventsToUnbind = void 0);
        m(a.buttons);
        d && (d.onfocus = d.onblur = d.onchange = null);
        b && (b.onfocus = b.onblur = b.onchange = null);
        H(a, function (b, d) {
          b && "chart" !== d && (b instanceof B ? b.destroy() : b instanceof W.HTMLElement && p(b));
          b !== f.prototype[d] && (a[d] = null);
        }, this);
      };
      return f;
    }();
    k(g.prototype, {
      defaultButtons: [{
        type: "month",
        count: 1,
        text: "1m",
        title: "View 1 month"
      }, {
        type: "month",
        count: 3,
        text: "3m",
        title: "View 3 months"
      }, {
        type: "month",
        count: 6,
        text: "6m",
        title: "View 6 months"
      }, {
        type: "ytd",
        text: "YTD",
        title: "View year to date"
      }, {
        type: "year",
        count: 1,
        text: "1y",
        title: "View 1 year"
      }, {
        type: "all",
        text: "All",
        title: "View all"
      }],
      inputTypeFormats: {
        "datetime-local": "%Y-%m-%dT%H:%M:%S",
        date: "%Y-%m-%d",
        time: "%H:%M:%S"
      }
    });
    "";
    return g;
  });
  I(g, "Series/XRange/XRangeSeriesDefaults.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.correctFloat,
      y = c.isNumber,
      A = c.isObject;
    "";
    return {
      colorByPoint: !0,
      dataLabels: {
        formatter: function () {
          var c = this.point.partialFill;
          A(c) && (c = c.amount);
          if (y(c) && 0 < c) return g(100 * c) + "%";
        },
        inside: !0,
        verticalAlign: "middle"
      },
      tooltip: {
        headerFormat: '<span style="font-size: 10px">{point.x} - {point.x2}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25cf</span> {series.name}: <b>{point.yCategory}</b><br/>'
      },
      borderRadius: 3,
      pointRange: 0
    };
  });
  I(g, "Series/XRange/XRangePoint.js", [g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g) {
    var y = this && this.__extends || function () {
        var c = function (g, y) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, g) {
            c.__proto__ = g;
          } || function (c, g) {
            for (var r in g) g.hasOwnProperty(r) && (c[r] = g[r]);
          };
          return c(g, y);
        };
        return function (g, y) {
          function x() {
            this.constructor = g;
          }
          c(g, y);
          g.prototype = null === y ? Object.create(y) : (x.prototype = y.prototype, new x());
        };
      }(),
      A = c.series.prototype.pointClass.prototype;
    g = g.extend;
    c = function (c) {
      function g() {
        var g = null !== c && c.apply(this, arguments) || this;
        g.options = void 0;
        g.series = void 0;
        return g;
      }
      y(g, c);
      g.getColorByCategory = function (c, g) {
        var t = c.options.colors || c.chart.options.colors;
        c = g.y % (t ? t.length : c.chart.options.chart.colorCount);
        return {
          colorIndex: c,
          color: t && t[c]
        };
      };
      g.prototype.resolveColor = function () {
        var c = this.series;
        if (c.options.colorByPoint && !this.options.color) {
          var x = g.getColorByCategory(c, this);
          c.chart.styledMode || (this.color = x.color);
          this.options.colorIndex || (this.colorIndex = x.colorIndex);
        } else this.color || (this.color = c.color);
      };
      g.prototype.init = function () {
        A.init.apply(this, arguments);
        this.y || (this.y = 0);
        return this;
      };
      g.prototype.setState = function () {
        A.setState.apply(this, arguments);
        this.series.drawPoint(this, this.series.getAnimationVerb());
      };
      g.prototype.getLabelConfig = function () {
        var c = A.getLabelConfig.call(this),
          g = this.series.yAxis.categories;
        c.x2 = this.x2;
        c.yCategory = this.yCategory = g && g[this.y];
        return c;
      };
      g.prototype.isValid = function () {
        return "number" === typeof this.x && "number" === typeof this.x2;
      };
      return g;
    }(c.seriesTypes.column.prototype.pointClass);
    g(c.prototype, {
      ttBelow: !1,
      tooltipDateKeys: ["x", "x2"]
    });
    "";
    return c;
  });
  I(g, "Series/XRange/XRangeSeries.js", [g["Core/Globals.js"], g["Core/Color/Color.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"], g["Series/XRange/XRangeSeriesDefaults.js"], g["Series/XRange/XRangePoint.js"]], function (c, g, y, A, B, z) {
    function C() {
      if (this.isXAxis) {
        var a = H(this.dataMax, -Number.MAX_VALUE);
        for (var c = 0, e = this.series; c < e.length; c++) {
          var f = e[c];
          if (f.x2Data) {
            var l = 0;
            for (f = f.x2Data; l < f.length; l++) {
              var d = f[l];
              if (d && d > a) {
                a = d;
                var b = !0;
              }
            }
          }
        }
        b && (this.dataMax = a);
      }
    }
    var x = this && this.__extends || function () {
      var a = function (c, e) {
        a = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (a, c) {
          a.__proto__ = c;
        } || function (a, c) {
          for (var d in c) c.hasOwnProperty(d) && (a[d] = c[d]);
        };
        return a(c, e);
      };
      return function (c, e) {
        function f() {
          this.constructor = c;
        }
        a(c, e);
        c.prototype = null === e ? Object.create(e) : (f.prototype = e.prototype, new f());
      };
    }();
    c = c.noop;
    var t = g.parse,
      r = y.series.prototype,
      a = y.seriesTypes.column,
      q = A.addEvent,
      m = A.clamp,
      p = A.defined;
    g = A.extend;
    var k = A.find,
      e = A.isNumber,
      D = A.isObject,
      G = A.merge,
      H = A.pick,
      K = [];
    A = function (c) {
      function g() {
        var a = null !== c && c.apply(this, arguments) || this;
        a.data = void 0;
        a.options = void 0;
        a.points = void 0;
        return a;
      }
      x(g, c);
      g.compose = function (a) {
        -1 === K.indexOf(a) && (K.push(a), q(a, "afterGetSeriesExtremes", C));
      };
      g.prototype.init = function () {
        c.prototype.init.apply(this, arguments);
        this.options.stacking = void 0;
      };
      g.prototype.getColumnMetrics = function () {
        var a = this,
          e = function () {
            for (var d = 0, b = a.chart.series; d < b.length; d++) {
              var c = b[d],
                e = c.xAxis;
              c.xAxis = c.yAxis;
              c.yAxis = e;
            }
          };
        e();
        var l = c.prototype.getColumnMetrics.call(this);
        e();
        return l;
      };
      g.prototype.cropData = function (a, c, e, d) {
        c = r.cropData.call(this, this.x2Data, c, e, d);
        c.xData = a.slice(c.start, c.end);
        return c;
      };
      g.prototype.findPointIndex = function (a) {
        var c = this.cropStart,
          l = this.points,
          d = a.id;
        if (d) var b = (b = k(l, function (b) {
          return b.id === d;
        })) ? b.index : void 0;
        "undefined" === typeof b && (b = (b = k(l, function (b) {
          return b.x === a.x && b.x2 === a.x2 && !b.touched;
        })) ? b.index : void 0);
        this.cropped && e(b) && e(c) && b >= c && (b -= c);
        return b;
      };
      g.prototype.alignDataLabel = function (a) {
        var e = a.plotX;
        a.plotX = H(a.dlBox && a.dlBox.centerX, a.plotX);
        c.prototype.alignDataLabel.apply(this, arguments);
        a.plotX = e;
      };
      g.prototype.translatePoint = function (a) {
        var c = this.xAxis,
          l = this.yAxis,
          d = this.columnMetrics,
          b = this.options,
          h = b.minPointLength || 0,
          k = (a.shapeArgs && a.shapeArgs.width || 0) / 2,
          g = this.pointXOffset = d.offset,
          n = H(a.x2, a.x + (a.len || 0)),
          q = a.plotX,
          w = c.translate(n, 0, 0, 0, 1);
        n = Math.abs(w - q);
        var u = this.chart.inverted,
          r = H(b.borderWidth, 1) % 2 / 2,
          t = d.offset,
          x = Math.round(d.width);
        h && (h -= n, 0 > h && (h = 0), q -= h / 2, w += h / 2);
        q = Math.max(q, -10);
        w = m(w, -10, c.len + 10);
        p(a.options.pointWidth) && (t -= (Math.ceil(a.options.pointWidth) - x) / 2, x = Math.ceil(a.options.pointWidth));
        b.pointPlacement && e(a.plotY) && l.categories && (a.plotY = l.translate(a.y, 0, 1, 0, 1, b.pointPlacement));
        b = Math.floor(Math.min(q, w)) + r;
        b = {
          x: b,
          y: Math.floor(a.plotY + t) + r,
          width: Math.floor(Math.max(q, w)) + r - b,
          height: x,
          r: this.options.borderRadius
        };
        a.shapeArgs = b;
        u ? a.tooltipPos[1] += g + k : a.tooltipPos[0] -= k + g - b.width / 2;
        k = b.x;
        g = k + b.width;
        0 > k || g > c.len ? (k = m(k, 0, c.len), g = m(g, 0, c.len), w = g - k, a.dlBox = G(b, {
          x: k,
          width: g - k,
          centerX: w ? w / 2 : null
        })) : a.dlBox = null;
        k = a.tooltipPos;
        g = u ? 1 : 0;
        w = u ? 0 : 1;
        d = this.columnMetrics ? this.columnMetrics.offset : -d.width / 2;
        k[g] = u ? k[g] + b.width / 2 : k[g] + (c.reversed ? -1 : 0) * b.width;
        k[w] = m(k[w] + (u ? -1 : 1) * d, 0, l.len - 1);
        if (l = a.partialFill) D(l) && (l = l.amount), e(l) || (l = 0), a.partShapeArgs = G(b, {
          r: this.options.borderRadius
        }), q = Math.max(Math.round(n * l + a.plotX - q), 0), a.clipRectArgs = {
          x: c.reversed ? b.x + n - q : b.x,
          y: b.y,
          width: q,
          height: b.height
        };
      };
      g.prototype.translate = function () {
        c.prototype.translate.apply(this, arguments);
        for (var a = 0, e = this.points; a < e.length; a++) this.translatePoint(e[a]);
      };
      g.prototype.drawPoint = function (a, c) {
        var e = this.options,
          d = this.chart.renderer,
          b = a.shapeType,
          f = a.shapeArgs,
          k = a.partShapeArgs,
          g = a.clipRectArgs,
          n = e.stacking && !e.borderRadius,
          m = a.state,
          p = e.states[m || "normal"] || {},
          q = "undefined" === typeof m ? "attr" : c;
        m = this.pointAttribs(a, m);
        p = H(this.chart.options.chart.animation, p.animation);
        var u = a.graphic,
          r = a.partialFill;
        if (a.isNull || !1 === a.visible) u && (a.graphic = u.destroy());else {
          if (u) u.rect[c](f);else a.graphic = u = d.g("point").addClass(a.getClassName()).add(a.group || this.group), u.rect = d[b](G(f)).addClass(a.getClassName()).addClass("highcharts-partfill-original").add(u);
          k && (u.partRect ? (u.partRect[c](G(k)), u.partialClipRect[c](G(g))) : (u.partialClipRect = d.clipRect(g.x, g.y, g.width, g.height), u.partRect = d[b](k).addClass("highcharts-partfill-overlay").add(u).clip(u.partialClipRect)));
          this.chart.styledMode || (u.rect[c](m, p).shadow(e.shadow, null, n), k && (D(r) || (r = {}), D(e.partialFill) && (r = G(e.partialFill, r)), a = r.fill || t(m.fill).brighten(-.3).get() || t(a.color || this.color).brighten(-.3).get(), m.fill = a, u.partRect[q](m, p).shadow(e.shadow, null, n)));
        }
      };
      g.prototype.drawPoints = function () {
        for (var a = this.getAnimationVerb(), c = 0, e = this.points; c < e.length; c++) this.drawPoint(e[c], a);
      };
      g.prototype.getAnimationVerb = function () {
        return this.chart.pointCount < (this.options.animationLimit || 250) ? "animate" : "attr";
      };
      g.prototype.isPointInside = function (a) {
        var e = a.shapeArgs,
          l = a.plotX,
          d = a.plotY;
        return e ? "undefined" !== typeof l && "undefined" !== typeof d && 0 <= d && d <= this.yAxis.len && 0 <= (e.x || 0) + (e.width || 0) && l <= this.xAxis.len : c.prototype.isPointInside.apply(this, arguments);
      };
      g.defaultOptions = G(a.defaultOptions, B);
      return g;
    }(a);
    g(A.prototype, {
      pointClass: z,
      cropShoulder: 1,
      getExtremesFromAll: !0,
      parallelArrays: ["x", "x2", "y"],
      requireSorting: !1,
      type: "xrange",
      animate: r.animate,
      autoIncrement: c,
      buildKDTree: c
    });
    y.registerSeriesType("xrange", A);
    return A;
  });
  I(g, "Series/Gantt/GanttPoint.js", [g["Core/Series/SeriesRegistry.js"], g["Core/Utilities.js"]], function (c, g) {
    var y = this && this.__extends || function () {
        var c = function (g, y) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, g) {
            c.__proto__ = g;
          } || function (c, g) {
            for (var r in g) g.hasOwnProperty(r) && (c[r] = g[r]);
          };
          return c(g, y);
        };
        return function (g, y) {
          function x() {
            this.constructor = g;
          }
          c(g, y);
          g.prototype = null === y ? Object.create(y) : (x.prototype = y.prototype, new x());
        };
      }(),
      A = g.pick;
    return function (c) {
      function g() {
        var g = null !== c && c.apply(this, arguments) || this;
        g.options = void 0;
        g.series = void 0;
        return g;
      }
      y(g, c);
      g.setGanttPointAliases = function (c) {
        function g(g, r) {
          "undefined" !== typeof r && (c[g] = r);
        }
        g("x", A(c.start, c.x));
        g("x2", A(c.end, c.x2));
        g("partialFill", A(c.completed, c.partialFill));
      };
      g.prototype.applyOptions = function (y, x) {
        y = c.prototype.applyOptions.call(this, y, x);
        g.setGanttPointAliases(y);
        return y;
      };
      g.prototype.isValid = function () {
        return ("number" === typeof this.start || "number" === typeof this.x) && ("number" === typeof this.end || "number" === typeof this.x2 || this.milestone);
      };
      return g;
    }(c.seriesTypes.xrange.prototype.pointClass);
  });
  I(g, "Core/Axis/BrokenAxis.js", [g["Core/Axis/Stacking/StackItem.js"], g["Core/Utilities.js"]], function (c, g) {
    var y = g.addEvent,
      A = g.find,
      B = g.fireEvent,
      z = g.isArray,
      C = g.isNumber,
      x = g.pick,
      t;
    (function (g) {
      function a() {
        "undefined" !== typeof this.brokenAxis && this.brokenAxis.setBreaks(this.options.breaks, !1);
      }
      function q() {
        this.brokenAxis && this.brokenAxis.hasBreaks && (this.options.ordinal = !1);
      }
      function m() {
        var a = this.brokenAxis;
        if (a && a.hasBreaks) {
          for (var c = this.tickPositions, e = this.tickPositions.info, f = [], l = 0; l < c.length; l++) a.isInAnyBreak(c[l]) || f.push(c[l]);
          this.tickPositions = f;
          this.tickPositions.info = e;
        }
      }
      function p() {
        this.brokenAxis || (this.brokenAxis = new K(this));
      }
      function k() {
        var a = this.options.connectNulls,
          c = this.points,
          e = this.xAxis,
          f = this.yAxis;
        if (this.isDirty) for (var l = c.length; l--;) {
          var d = c[l],
            b = !(null === d.y && !1 === a) && (e && e.brokenAxis && e.brokenAxis.isInAnyBreak(d.x, !0) || f && f.brokenAxis && f.brokenAxis.isInAnyBreak(d.y, !0));
          d.visible = b ? !1 : !1 !== d.options.visible;
        }
      }
      function e() {
        this.drawBreaks(this.xAxis, ["x"]);
        this.drawBreaks(this.yAxis, x(this.pointArrayMap, ["y"]));
      }
      function r(a, c) {
        var e = this,
          f = e.points,
          l,
          d,
          b,
          h;
        if (a && a.brokenAxis && a.brokenAxis.hasBreaks) {
          var k = a.brokenAxis;
          c.forEach(function (c) {
            l = k && k.breakArray || [];
            d = a.isXAxis ? a.min : x(e.options.threshold, a.min);
            f.forEach(function (e) {
              h = x(e["stack" + c.toUpperCase()], e[c]);
              l.forEach(function (c) {
                if (C(d) && C(h)) {
                  b = !1;
                  if (d < c.from && h > c.to || d > c.from && h < c.from) b = "pointBreak";else if (d < c.from && h > c.from && h < c.to || d > c.from && h > c.to && h < c.from) b = "pointInBreak";
                  b && B(a, b, {
                    point: e,
                    brk: c
                  });
                }
              });
            });
          });
        }
      }
      function t() {
        var a = this.currentDataGrouping,
          e = a && a.gapSize;
        a = this.points.slice();
        var k = this.yAxis,
          f = this.options.gapSize,
          l = a.length - 1,
          d;
        if (f && 0 < l) for ("value" !== this.options.gapUnit && (f *= this.basePointRange), e && e > f && e >= this.basePointRange && (f = e), d = void 0; l--;) d && !1 !== d.visible || (d = a[l + 1]), e = a[l], !1 !== d.visible && !1 !== e.visible && (d.x - e.x > f && (d = (e.x + d.x) / 2, a.splice(l + 1, 0, {
          isNull: !0,
          x: d
        }), k.stacking && this.options.stacking && (d = k.stacking.stacks[this.stackKey][d] = new c(k, k.options.stackLabels, !1, d, this.stack), d.total = 0)), d = e);
        return this.getGraphPath(a);
      }
      var H = [];
      g.compose = function (c, g) {
        -1 === H.indexOf(c) && (H.push(c), c.keepProps.push("brokenAxis"), y(c, "init", p), y(c, "afterInit", a), y(c, "afterSetTickPositions", m), y(c, "afterSetOptions", q));
        if (-1 === H.indexOf(g)) {
          H.push(g);
          var n = g.prototype;
          n.drawBreaks = r;
          n.gappedPath = t;
          y(g, "afterGeneratePoints", k);
          y(g, "afterRender", e);
        }
        return c;
      };
      var K = function () {
        function a(a) {
          this.hasBreaks = !1;
          this.axis = a;
        }
        a.isInBreak = function (a, c) {
          var e = a.repeat || Infinity,
            k = a.from,
            d = a.to - a.from;
          c = c >= k ? (c - k) % e : e - (k - c) % e;
          return a.inclusive ? c <= d : c < d && 0 !== c;
        };
        a.lin2Val = function (c) {
          var e = this.brokenAxis;
          e = e && e.breakArray;
          if (!e || !C(c)) return c;
          var f;
          for (f = 0; f < e.length; f++) {
            var k = e[f];
            if (k.from >= c) break;else k.to < c ? c += k.len : a.isInBreak(k, c) && (c += k.len);
          }
          return c;
        };
        a.val2Lin = function (c) {
          var e = this.brokenAxis;
          e = e && e.breakArray;
          if (!e || !C(c)) return c;
          var f = c,
            k;
          for (k = 0; k < e.length; k++) {
            var d = e[k];
            if (d.to <= c) f -= d.len;else if (d.from >= c) break;else if (a.isInBreak(d, c)) {
              f -= c - d.from;
              break;
            }
          }
          return f;
        };
        a.prototype.findBreakAt = function (a, c) {
          return A(c, function (c) {
            return c.from < a && a < c.to;
          });
        };
        a.prototype.isInAnyBreak = function (c, e) {
          var f = this.axis,
            k = f.options.breaks || [],
            d = k.length,
            b;
          if (d && C(c)) {
            for (; d--;) if (a.isInBreak(k[d], c)) {
              var h = !0;
              b || (b = x(k[d].showPoints, !f.isXAxis));
            }
            var g = h && e ? h && !b : h;
          }
          return g;
        };
        a.prototype.setBreaks = function (c, e) {
          var f = this,
            k = f.axis,
            d = z(c) && !!c.length;
          k.isDirty = f.hasBreaks !== d;
          f.hasBreaks = d;
          k.options.breaks = k.userOptions.breaks = c;
          k.forceRedraw = !0;
          k.series.forEach(function (b) {
            b.isDirty = !0;
          });
          d || k.val2lin !== a.val2Lin || (delete k.val2lin, delete k.lin2val);
          d && (k.userOptions.ordinal = !1, k.lin2val = a.lin2Val, k.val2lin = a.val2Lin, k.setExtremes = function (b, a, d, c, e) {
            if (f.hasBreaks) {
              for (var h = this.options.breaks || [], l; l = f.findBreakAt(b, h);) b = l.to;
              for (; l = f.findBreakAt(a, h);) a = l.from;
              a < b && (a = b);
            }
            k.constructor.prototype.setExtremes.call(this, b, a, d, c, e);
          }, k.setAxisTranslation = function () {
            k.constructor.prototype.setAxisTranslation.call(this);
            f.unitLength = void 0;
            if (f.hasBreaks) {
              var b = k.options.breaks || [],
                d = [],
                c = [],
                e = x(k.pointRangePadding, 0),
                l = 0,
                g,
                m = k.userMin || k.min,
                n = k.userMax || k.max,
                p;
              b.forEach(function (b) {
                g = b.repeat || Infinity;
                C(m) && C(n) && (a.isInBreak(b, m) && (m += b.to % g - m % g), a.isInBreak(b, n) && (n -= n % g - b.from % g));
              });
              b.forEach(function (b) {
                r = b.from;
                g = b.repeat || Infinity;
                if (C(m) && C(n)) {
                  for (; r - g > m;) r -= g;
                  for (; r < m;) r += g;
                  for (p = r; p < n; p += g) d.push({
                    value: p,
                    move: "in"
                  }), d.push({
                    value: p + b.to - b.from,
                    move: "out",
                    size: b.breakSize
                  });
                }
              });
              d.sort(function (b, a) {
                return b.value === a.value ? ("in" === b.move ? 0 : 1) - ("in" === a.move ? 0 : 1) : b.value - a.value;
              });
              var q = 0;
              var r = m;
              d.forEach(function (b) {
                q += "in" === b.move ? 1 : -1;
                1 === q && "in" === b.move && (r = b.value);
                0 === q && C(r) && (c.push({
                  from: r,
                  to: b.value,
                  len: b.value - r - (b.size || 0)
                }), l += b.value - r - (b.size || 0));
              });
              f.breakArray = c;
              C(m) && C(n) && C(k.min) && (f.unitLength = n - m - l + e, B(k, "afterBreaks"), k.staticScale ? k.transA = k.staticScale : f.unitLength && (k.transA *= (n - k.min + e) / f.unitLength), e && (k.minPixelPadding = k.transA * (k.minPointOffset || 0)), k.min = m, k.max = n);
            }
          });
          x(e, !0) && k.chart.redraw();
        };
        return a;
      }();
      g.Additions = K;
    })(t || (t = {}));
    return t;
  });
  I(g, "Core/Axis/GridAxis.js", [g["Core/Axis/Axis.js"], g["Core/Axis/AxisDefaults.js"], g["Core/Globals.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    function B(b, a) {
      var d = {
        width: 0,
        height: 0
      };
      a.forEach(function (a) {
        a = b[a];
        if (A.isObject(a, !0)) {
          var c = A.isObject(a.label, !0) ? a.label : {};
          a = c.getBBox ? c.getBBox().height : 0;
          c.textStr && !h(c.textPxLength) && (c.textPxLength = c.getBBox().width);
          var e = h(c.textPxLength) ? Math.round(c.textPxLength) : 0;
          c.textStr && (e = Math.round(c.getBBox().width));
          d.height = Math.max(a, d.height);
          d.width = Math.max(e, d.width);
        }
      });
      "treegrid" === this.options.type && this.treeGrid && this.treeGrid.mapOfPosToGridNode && (d.width += this.options.labels.indentation * ((this.treeGrid.mapOfPosToGridNode[-1].height || 0) - 1));
      return d;
    }
    function z() {
      var b = this.grid;
      (b && b.columns || []).forEach(function (b) {
        b.getOffset();
      });
    }
    function C(b) {
      if (!0 === (this.options.grid || {}).enabled) {
        var a = this.axisTitle,
          d = this.height,
          c = this.horiz,
          e = this.left,
          f = this.offset,
          h = this.opposite,
          k = this.options,
          l = this.top,
          g = this.width,
          m = this.tickSize(),
          n = a && a.getBBox().width,
          p = k.title.x,
          q = k.title.y,
          r = E(k.title.margin, c ? 5 : 10);
        a = this.chart.renderer.fontMetrics(k.title.style.fontSize, a).f;
        m = (c ? l + d : e) + (c ? 1 : -1) * (h ? -1 : 1) * (m ? m[0] / 2 : 0) + (this.side === O.bottom ? a : 0);
        b.titlePosition.x = c ? e - (n || 0) / 2 - r + p : m + (h ? g : 0) + f + p;
        b.titlePosition.y = c ? m - (h ? d : 0) + (h ? a : -a) / 2 + f + q : l - r + q;
      }
    }
    function x() {
      var b = this.chart,
        a = this.options.grid;
      a = void 0 === a ? {} : a;
      var d = this.userOptions;
      if (a.enabled) {
        var e = this.options;
        e.labels.align = E(e.labels.align, "center");
        this.categories || (e.showLastLabel = !1);
        this.labelRotation = 0;
        e.labels.rotation = 0;
      }
      if (a.columns) {
        e = this.grid.columns = [];
        for (var f = this.grid.columnIndex = 0; ++f < a.columns.length;) {
          var h = v(d, a.columns[a.columns.length - f - 1], {
            linkedTo: 0,
            type: "category",
            scrollbar: {
              enabled: !1
            }
          });
          delete h.grid.columns;
          h = new c(this.chart, h);
          h.grid.isColumn = !0;
          h.grid.columnIndex = f;
          l(b.axes, h);
          l(b[this.coll], h);
          e.push(h);
        }
      }
    }
    function t() {
      var b = this.grid,
        a = this.options;
      if (!0 === (a.grid || {}).enabled) {
        var d = this.min || 0,
          c = this.max || 0;
        this.maxLabelDimensions = this.getMaxLabelDimensions(this.ticks, this.tickPositions);
        this.rightWall && this.rightWall.destroy();
        if (this.grid && this.grid.isOuterAxis() && this.axisLine) {
          var e = a.lineWidth;
          if (e) {
            e = this.getLinePath(e);
            var f = e[0],
              h = e[1],
              k = ((this.tickSize("tick") || [1])[0] - 1) * (this.side === O.top || this.side === O.left ? -1 : 1);
            "M" === f[0] && "L" === h[0] && (this.horiz ? (f[2] += k, h[2] += k) : (f[1] += k, h[1] += k));
            !this.horiz && this.chart.marginRight && (f = [f, ["L", this.left, f[2] || 0]], k = ["L", this.chart.chartWidth - this.chart.marginRight, this.toPixels(c + this.tickmarkOffset)], h = [["M", h[1] || 0, this.toPixels(c + this.tickmarkOffset)], k], this.grid.upperBorder || 0 === d % 1 || (this.grid.upperBorder = this.grid.renderBorder(f)), this.grid.upperBorder && (this.grid.upperBorder.attr({
              stroke: a.lineColor,
              "stroke-width": a.lineWidth
            }), this.grid.upperBorder.animate({
              d: f
            })), this.grid.lowerBorder || 0 === c % 1 || (this.grid.lowerBorder = this.grid.renderBorder(h)), this.grid.lowerBorder && (this.grid.lowerBorder.attr({
              stroke: a.lineColor,
              "stroke-width": a.lineWidth
            }), this.grid.lowerBorder.animate({
              d: h
            })));
            this.grid.axisLineExtra ? (this.grid.axisLineExtra.attr({
              stroke: a.lineColor,
              "stroke-width": a.lineWidth
            }), this.grid.axisLineExtra.animate({
              d: e
            })) : this.grid.axisLineExtra = this.grid.renderBorder(e);
            this.axisLine[this.showAxis ? "show" : "hide"]();
          }
        }
        (b && b.columns || []).forEach(function (b) {
          return b.render();
        });
        if (!this.horiz && this.chart.hasRendered && (this.scrollbar || this.linkedParent && this.linkedParent.scrollbar)) {
          b = this.tickmarkOffset;
          a = this.tickPositions[this.tickPositions.length - 1];
          e = this.tickPositions[0];
          for (f = h = void 0; (h = this.hiddenLabels.pop()) && h.element;) h.show();
          for (; (f = this.hiddenMarks.pop()) && f.element;) f.show();
          (h = this.ticks[e].label) && (d - e > b ? this.hiddenLabels.push(h.hide()) : h.show());
          (h = this.ticks[a].label) && (a - c > b ? this.hiddenLabels.push(h.hide()) : h.show());
          (d = this.ticks[a].mark) && a - c < b && 0 < a - c && this.ticks[a].isLast && this.hiddenMarks.push(d.hide());
        }
      }
    }
    function r() {
      var b = this.tickPositions && this.tickPositions.info,
        a = this.options,
        d = this.userOptions.labels || {};
      (a.grid || {}).enabled && (this.horiz ? (this.series.forEach(function (b) {
        b.options.pointRange = 0;
      }), b && a.dateTimeLabelFormats && a.labels && !f(d.align) && (!1 === a.dateTimeLabelFormats[b.unitName].range || 1 < b.count) && (a.labels.align = "left", f(d.x) || (a.labels.x = 3))) : "treegrid" !== this.options.type && this.grid && this.grid.columns && (this.minPointOffset = this.tickInterval));
    }
    function a(b) {
      var a = this.options;
      b = b.userOptions;
      var d = a && A.isObject(a.grid, !0) ? a.grid : {};
      if (!0 === d.enabled) {
        var c = v(!0, {
          className: "highcharts-grid-axis " + (b.className || ""),
          dateTimeLabelFormats: {
            hour: {
              list: ["%H:%M", "%H"]
            },
            day: {
              list: ["%A, %e. %B", "%a, %e. %b", "%E"]
            },
            week: {
              list: ["Week %W", "W%W"]
            },
            month: {
              list: ["%B", "%b", "%o"]
            }
          },
          grid: {
            borderWidth: 1
          },
          labels: {
            padding: 2,
            style: {
              fontSize: "13px"
            }
          },
          margin: 0,
          title: {
            text: null,
            reserveSpace: !1,
            rotation: 0
          },
          units: [["millisecond", [1, 10, 100]], ["second", [1, 10]], ["minute", [1, 5, 15]], ["hour", [1, 6]], ["day", [1]], ["week", [1]], ["month", [1]], ["year", null]]
        }, b);
        "xAxis" === this.coll && (f(b.linkedTo) && !f(b.tickPixelInterval) && (c.tickPixelInterval = 350), f(b.tickPixelInterval) || !f(b.linkedTo) || f(b.tickPositioner) || f(b.tickInterval) || (c.tickPositioner = function (b, a) {
          var d = this.linkedParent && this.linkedParent.tickPositions && this.linkedParent.tickPositions.info;
          if (d) {
            for (var e = c.units || [], f = void 0, k = 1, l = "year", g = 0; g < e.length; g++) {
              var m = e[g];
              if (m && m[0] === d.unitName) {
                f = g;
                break;
              }
            }
            (e = h(f) && e[f + 1]) ? (l = e[0] || "year", k = (k = e[1]) && k[0] || 1) : "year" === d.unitName && (k = 10 * d.count);
            d = J[l];
            this.tickInterval = d * k;
            return this.chart.time.getTimeTicks({
              unitRange: d,
              count: k,
              unitName: l
            }, b, a, this.options.startOfWeek);
          }
        }));
        v(!0, this.options, c);
        this.horiz && (a.minPadding = E(b.minPadding, 0), a.maxPadding = E(b.maxPadding, 0));
        h(a.grid.borderWidth) && (a.tickWidth = a.lineWidth = d.borderWidth);
      }
    }
    function q(b) {
      b = (b = b.userOptions) && b.grid || {};
      var a = b.columns;
      b.enabled && a && v(!0, this.options, a[a.length - 1]);
    }
    function m() {
      (this.grid.columns || []).forEach(function (b) {
        return b.setScale();
      });
    }
    function p(a) {
      var d = g.defaultLeftAxisOptions,
        c = this.horiz,
        e = this.maxLabelDimensions,
        f = this.options.grid;
      f = void 0 === f ? {} : f;
      f.enabled && e && (d = 2 * Math.abs(d.labels.x), c = c ? f.cellHeight || d + e.height : d + e.width, b(a.tickSize) ? a.tickSize[0] = c : a.tickSize = [c, 0]);
    }
    function k() {
      this.axes.forEach(function (b) {
        (b.grid && b.grid.columns || []).forEach(function (b) {
          b.setAxisSize();
          b.setAxisTranslation();
        });
      });
    }
    function e(b) {
      var a = this.grid;
      (a.columns || []).forEach(function (a) {
        return a.destroy(b.keepEvents);
      });
      a.columns = void 0;
    }
    function D(b) {
      b = b.userOptions || {};
      var a = b.grid || {};
      a.enabled && f(a.borderColor) && (b.tickColor = b.lineColor = a.borderColor);
      this.grid || (this.grid = new L(this));
      this.hiddenLabels = [];
      this.hiddenMarks = [];
    }
    function G(b) {
      var a = this.label,
        d = this.axis,
        c = d.reversed,
        e = d.chart,
        f = d.options.grid || {},
        k = d.options.labels,
        l = k.align,
        g = O[d.side],
        m = b.tickmarkOffset,
        n = d.tickPositions,
        p = this.pos - m;
      n = h(n[b.index + 1]) ? n[b.index + 1] - m : (d.max || 0) + m;
      var q = d.tickSize("tick");
      m = q ? q[0] : 0;
      q = q ? q[1] / 2 : 0;
      if (!0 === f.enabled) {
        if ("top" === g) {
          f = d.top + d.offset;
          var r = f - m;
        } else "bottom" === g ? (r = e.chartHeight - d.bottom + d.offset, f = r + m) : (f = d.top + d.len - (d.translate(c ? n : p) || 0), r = d.top + d.len - (d.translate(c ? p : n) || 0));
        "right" === g ? (g = e.chartWidth - d.right + d.offset, c = g + m) : "left" === g ? (c = d.left + d.offset, g = c - m) : (g = Math.round(d.left + (d.translate(c ? n : p) || 0)) - q, c = Math.min(Math.round(d.left + (d.translate(c ? p : n) || 0)) - q, d.left + d.len));
        this.slotWidth = c - g;
        b.pos.x = "left" === l ? g : "right" === l ? c : g + (c - g) / 2;
        b.pos.y = r + (f - r) / 2;
        e = e.renderer.fontMetrics(k.style.fontSize, a && a.element);
        a = a ? a.getBBox().height : 0;
        k.useHTML ? b.pos.y += e.b + -(a / 2) : (a = Math.round(a / e.h), b.pos.y += (e.b - (e.h - e.f)) / 2 + -((a - 1) * e.h / 2));
        b.pos.x += d.horiz && k.x || 0;
      }
    }
    function H(b) {
      var a = b.axis,
        c = b.value;
      if (a.options.grid && a.options.grid.enabled) {
        var e = a.tickPositions,
          f = (a.linkedParent || a).series[0],
          h = c === e[0];
        e = c === e[e.length - 1];
        var k = f && d(f.options.data, function (b) {
            return b[a.isXAxis ? "x" : "y"] === c;
          }),
          l = void 0;
        k && f.is("gantt") && (l = v(k), y.seriesTypes.gantt.prototype.pointClass.setGanttPointAliases(l));
        b.isFirst = h;
        b.isLast = e;
        b.point = l;
      }
    }
    function K() {
      var b = this.options,
        a = this.categories,
        d = this.tickPositions,
        c = d[0],
        e = d[d.length - 1],
        f = this.linkedParent && this.linkedParent.min || this.min,
        h = this.linkedParent && this.linkedParent.max || this.max,
        k = this.tickInterval;
      !0 !== (b.grid || {}).enabled || a || !this.horiz && !this.isLinked || (c < f && c + k > f && !b.startOnTick && (d[0] = f), e > h && e - k < h && !b.endOnTick && (d[d.length - 1] = h));
    }
    function u(b) {
      var a = this.options.grid;
      return !0 === (void 0 === a ? {} : a).enabled && this.categories ? this.tickInterval : b.apply(this, Array.prototype.slice.call(arguments, 1));
    }
    var n = y.dateFormats,
      w = A.addEvent,
      f = A.defined,
      l = A.erase,
      d = A.find,
      b = A.isArray,
      h = A.isNumber,
      v = A.merge,
      E = A.pick,
      J = A.timeUnits,
      M = A.wrap,
      O;
    (function (b) {
      b[b.top = 0] = "top";
      b[b.right = 1] = "right";
      b[b.bottom = 2] = "bottom";
      b[b.left = 3] = "left";
    })(O || (O = {}));
    var I = [],
      L = function () {
        function b(b) {
          this.axis = b;
        }
        b.prototype.isOuterAxis = function () {
          var b = this.axis,
            a = b.grid.columnIndex,
            d = b.linkedParent && b.linkedParent.grid.columns || b.grid.columns,
            c = a ? b.linkedParent : b,
            e = -1,
            f = 0;
          (b.chart[b.coll] || []).forEach(function (a, d) {
            a.side !== b.side || a.options.isInternal || (f = d, a === c && (e = d));
          });
          return f === e && (h(a) ? d.length === a : !0);
        };
        b.prototype.renderBorder = function (b) {
          var a = this.axis,
            d = a.chart.renderer,
            c = a.options;
          b = d.path(b).addClass("highcharts-axis-line").add(a.axisBorder);
          d.styledMode || b.attr({
            stroke: c.lineColor,
            "stroke-width": c.lineWidth,
            zIndex: 7
          });
          return b;
        };
        return b;
      }();
    n.E = function (b) {
      return this.dateFormat("%a", b, !0).charAt(0);
    };
    n.W = function (b) {
      var a = this,
        d = new this.Date(b);
      ["Hours", "Milliseconds", "Minutes", "Seconds"].forEach(function (b) {
        a.set(b, d, 0);
      });
      var c = (this.get("Day", d) + 6) % 7;
      b = new this.Date(d.valueOf());
      this.set("Date", b, this.get("Date", d) - c + 3);
      c = new this.Date(this.get("FullYear", b), 0, 1);
      4 !== this.get("Day", c) && (this.set("Month", d, 0), this.set("Date", d, 1 + (11 - this.get("Day", c)) % 7));
      return (1 + Math.floor((b.valueOf() - c.valueOf()) / 6048E5)).toString();
    };
    "";
    return {
      compose: function (b, d, c) {
        -1 === I.indexOf(b) && (I.push(b), b.keepProps.push("grid"), b.prototype.getMaxLabelDimensions = B, M(b.prototype, "unsquish", u), w(b, "init", D), w(b, "afterGetOffset", z), w(b, "afterGetTitlePosition", C), w(b, "afterInit", x), w(b, "afterRender", t), w(b, "afterSetAxisTranslation", r), w(b, "afterSetOptions", a), w(b, "afterSetOptions", q), w(b, "afterSetScale", m), w(b, "afterTickSize", p), w(b, "trimTicks", K), w(b, "destroy", e));
        -1 === I.indexOf(d) && w(d, "afterSetChartSize", k);
        -1 === I.indexOf(c) && (w(c, "afterGetLabelPosition", G), w(c, "labelFormat", H));
        return b;
      }
    };
  });
  I(g, "Gantt/Tree.js", [g["Core/Utilities.js"]], function (c) {
    var g = c.extend,
      y = c.isNumber,
      A = c.pick,
      B = function (c, g) {
        var t = c.reduce(function (c, a) {
          var g = A(a.parent, "");
          "undefined" === typeof c[g] && (c[g] = []);
          c[g].push(a);
          return c;
        }, {});
        Object.keys(t).forEach(function (c, a) {
          var q = t[c];
          "" !== c && -1 === g.indexOf(c) && (q.forEach(function (c) {
            a[""].push(c);
          }), delete a[c]);
        });
        return t;
      },
      z = function (c, x, t, r, a, q) {
        var m = 0,
          p = 0,
          k = q && q.after,
          e = q && q.before;
        x = {
          data: r,
          depth: t - 1,
          id: c,
          level: t,
          parent: x
        };
        var D, G;
        "function" === typeof e && e(x, q);
        e = (a[c] || []).map(function (e) {
          var k = z(e.id, c, t + 1, e, a, q),
            g = e.start;
          e = !0 === e.milestone ? g : e.end;
          D = !y(D) || g < D ? g : D;
          G = !y(G) || e > G ? e : G;
          m = m + 1 + k.descendants;
          p = Math.max(k.height + 1, p);
          return k;
        });
        r && (r.start = A(r.start, D), r.end = A(r.end, G));
        g(x, {
          children: e,
          descendants: m,
          height: p
        });
        "function" === typeof k && k(x, q);
        return x;
      };
    return {
      getListOfParents: B,
      getNode: z,
      getTree: function (c, g) {
        var t = c.map(function (c) {
          return c.id;
        });
        c = B(c, t);
        return z("", null, 1, null, c, g);
      }
    };
  });
  I(g, "Core/Axis/TreeGrid/TreeGridTick.js", [g["Core/Utilities.js"]], function (c) {
    function g() {
      this.treeGrid || (this.treeGrid = new q(this));
    }
    function y(a, c) {
      a = a.treeGrid;
      var k = !a.labelIcon,
        e = c.renderer,
        g = c.xy,
        m = c.options,
        p = m.width || 0,
        q = m.height || 0,
        r = g.x - p / 2 - (m.padding || 0);
      g = g.y - q / 2;
      var n = c.collapsed ? 90 : 180,
        w = c.show && x(g),
        f = a.labelIcon;
      f || (a.labelIcon = f = e.path(e.symbols[m.type](m.x || 0, m.y || 0, p, q)).addClass("highcharts-label-icon").add(c.group));
      f[w ? "show" : "hide"]();
      e.styledMode || f.attr({
        cursor: "pointer",
        fill: t(c.color, "#666666"),
        "stroke-width": 1,
        stroke: m.lineColor,
        strokeWidth: m.lineWidth || 0
      });
      f[k ? "attr" : "animate"]({
        translateX: r,
        translateY: g,
        rotation: n
      });
    }
    function A(a, c, k, e, g, q, r, y, u) {
      var m = t(this.options && this.options.labels, q);
      q = this.pos;
      var p = this.axis,
        f = "treegrid" === p.options.type;
      a = a.apply(this, [c, k, e, g, m, r, y, u]);
      f && (c = m && C(m.symbol, !0) ? m.symbol : {}, m = m && x(m.indentation) ? m.indentation : 0, q = (q = (p = p.treeGrid.mapOfPosToGridNode) && p[q]) && q.depth || 1, a.x += (c.width || 0) + 2 * (c.padding || 0) + (q - 1) * m);
      return a;
    }
    function B(a) {
      var c = this,
        k = c.pos,
        e = c.axis,
        g = c.label,
        m = e.treeGrid.mapOfPosToGridNode,
        q = e.options,
        r = t(c.options && c.options.labels, q && q.labels),
        u = r && C(r.symbol, !0) ? r.symbol : {},
        n = (m = m && m[k]) && m.depth;
      q = "treegrid" === q.type;
      var w = -1 < e.tickPositions.indexOf(k);
      k = e.chart.styledMode;
      q && m && g && g.element && g.addClass("highcharts-treegrid-node-level-" + n);
      a.apply(c, Array.prototype.slice.call(arguments, 1));
      q && g && g.element && m && m.descendants && 0 < m.descendants && (e = e.treeGrid.isCollapsed(m), y(c, {
        color: !k && g.styles && g.styles.color || "",
        collapsed: e,
        group: g.parentGroup,
        options: u,
        renderer: g.renderer,
        show: w,
        xy: g.xy
      }), u = "highcharts-treegrid-node-" + (e ? "expanded" : "collapsed"), g.addClass("highcharts-treegrid-node-" + (e ? "collapsed" : "expanded")).removeClass(u), k || g.css({
        cursor: "pointer"
      }), [g, c.treeGrid.labelIcon].forEach(function (a) {
        a && !a.attachedTreeGridEvents && (z(a.element, "mouseover", function () {
          g.addClass("highcharts-treegrid-node-active");
          g.renderer.styledMode || g.css({
            textDecoration: "underline"
          });
        }), z(a.element, "mouseout", function () {
          var a = C(r.style) ? r.style : {};
          g.removeClass("highcharts-treegrid-node-active");
          g.renderer.styledMode || g.css({
            textDecoration: a.textDecoration
          });
        }), z(a.element, "click", function () {
          c.treeGrid.toggleCollapse();
        }), a.attachedTreeGridEvents = !0);
      }));
    }
    var z = c.addEvent,
      C = c.isObject,
      x = c.isNumber,
      t = c.pick,
      r = c.wrap,
      a = [],
      q = function () {
        function c(a) {
          this.tick = a;
        }
        c.compose = function (c) {
          -1 === a.indexOf(c) && (a.push(c), z(c, "init", g), r(c.prototype, "getLabelPosition", A), r(c.prototype, "renderLabel", B), c.prototype.collapse = function (a) {
            this.treeGrid.collapse(a);
          }, c.prototype.expand = function (a) {
            this.treeGrid.expand(a);
          }, c.prototype.toggleCollapse = function (a) {
            this.treeGrid.toggleCollapse(a);
          });
        };
        c.prototype.collapse = function (a) {
          var c = this.tick,
            e = c.axis,
            g = e.brokenAxis;
          g && e.treeGrid.mapOfPosToGridNode && (c = e.treeGrid.collapse(e.treeGrid.mapOfPosToGridNode[c.pos]), g.setBreaks(c, t(a, !0)));
        };
        c.prototype.destroy = function () {
          this.labelIcon && this.labelIcon.destroy();
        };
        c.prototype.expand = function (a) {
          var c = this.tick,
            e = c.axis,
            g = e.brokenAxis;
          g && e.treeGrid.mapOfPosToGridNode && (c = e.treeGrid.expand(e.treeGrid.mapOfPosToGridNode[c.pos]), g.setBreaks(c, t(a, !0)));
        };
        c.prototype.toggleCollapse = function (a) {
          var c = this.tick,
            e = c.axis,
            g = e.brokenAxis;
          g && e.treeGrid.mapOfPosToGridNode && (c = e.treeGrid.toggleCollapse(e.treeGrid.mapOfPosToGridNode[c.pos]), g.setBreaks(c, t(a, !0)));
        };
        return c;
      }();
    return q;
  });
  I(g, "Series/TreeUtilities.js", [g["Core/Color/Color.js"], g["Core/Utilities.js"]], function (c, g) {
    function y(c, a) {
      var g = a.before,
        m = a.idRoot,
        p = a.mapIdToNode[m],
        k = a.points[c.i],
        e = k && k.options || {},
        r = [],
        x = 0;
      c.levelDynamic = c.level - (!1 !== a.levelIsConstant ? 0 : p.level);
      c.name = t(k && k.name, "");
      c.visible = m === c.id || !0 === a.visible;
      "function" === typeof g && (c = g(c, a));
      c.children.forEach(function (e, k) {
        var g = A({}, a);
        A(g, {
          index: k,
          siblings: c.children.length,
          visible: c.visible
        });
        e = y(e, g);
        r.push(e);
        e.visible && (x += e.val);
      });
      g = t(e.value, x);
      c.visible = 0 <= g && (0 < x || c.visible);
      c.children = r;
      c.childrenTotal = x;
      c.isLeaf = c.visible && !x;
      c.val = g;
      return c;
    }
    var A = g.extend,
      B = g.isArray,
      z = g.isNumber,
      C = g.isObject,
      x = g.merge,
      t = g.pick;
    return {
      getColor: function (g, a) {
        var q = a.index,
          m = a.mapOptionsToLevel,
          p = a.parentColor,
          k = a.parentColorIndex,
          e = a.series,
          r = a.colors,
          x = a.siblings,
          y = e.points,
          z = e.chart.options.chart,
          u;
        if (g) {
          y = y[g.i];
          g = m[g.level] || {};
          if (m = y && g.colorByPoint) {
            var n = y.index % (r ? r.length : z.colorCount);
            var w = r && r[n];
          }
          if (!e.chart.styledMode) {
            r = y && y.options.color;
            z = g && g.color;
            if (u = p) u = (u = g && g.colorVariation) && "brightness" === u.key && q && x ? c.parse(p).brighten(q / x * u.to).get() : p;
            u = t(r, z, w, u, e.color);
          }
          var f = t(y && y.options.colorIndex, g && g.colorIndex, n, k, a.colorIndex);
        }
        return {
          color: u,
          colorIndex: f
        };
      },
      getLevelOptions: function (c) {
        var a = {};
        if (C(c)) {
          var g = z(c.from) ? c.from : 1;
          var m = c.levels;
          var p = {};
          var k = C(c.defaults) ? c.defaults : {};
          B(m) && (p = m.reduce(function (a, c) {
            if (C(c) && z(c.level)) {
              var e = x({}, c);
              var m = t(e.levelIsConstant, k.levelIsConstant);
              delete e.levelIsConstant;
              delete e.level;
              c = c.level + (m ? 0 : g - 1);
              C(a[c]) ? x(!0, a[c], e) : a[c] = e;
            }
            return a;
          }, {}));
          m = z(c.to) ? c.to : 1;
          for (c = 0; c <= m; c++) a[c] = x({}, k, C(p[c]) ? p[c] : {});
        }
        return a;
      },
      setTreeValues: y,
      updateRootId: function (c) {
        if (C(c)) {
          var a = C(c.options) ? c.options : {};
          a = t(c.rootNode, a.rootId, "");
          C(c.userOptions) && (c.userOptions.rootId = a);
          c.rootNode = a;
        }
        return a;
      }
    };
  });
  I(g, "Core/Axis/TreeGrid/TreeGridAxis.js", [g["Core/Axis/BrokenAxis.js"], g["Core/Axis/GridAxis.js"], g["Gantt/Tree.js"], g["Core/Axis/TreeGrid/TreeGridTick.js"], g["Series/TreeUtilities.js"], g["Core/Utilities.js"]], function (c, g, y, A, B, z) {
    function C(a, b) {
      var d = a.collapseEnd || 0;
      a = a.collapseStart || 0;
      d >= b && (a -= .5);
      return {
        from: a,
        to: d,
        showPoints: !1
      };
    }
    function x(a, b, c) {
      var d = [],
        e = [],
        f = {},
        h = "boolean" === typeof b ? b : !1,
        g = {},
        l = -1;
      a = y.getTree(a, {
        after: function (b) {
          b = g[b.pos];
          var a = 0,
            d = 0;
          b.children.forEach(function (b) {
            d += (b.descendants || 0) + 1;
            a = Math.max((b.height || 0) + 1, a);
          });
          b.descendants = d;
          b.height = a;
          b.collapsed && e.push(b);
        },
        before: function (b) {
          var a = G(b.data, !0) ? b.data : {},
            c = H(a.name) ? a.name : "",
            e = f[b.parent];
          e = G(e, !0) ? g[e.pos] : null;
          var m = function (b) {
              return b.name === c;
            },
            n;
          h && G(e, !0) && (n = k(e.children, m)) ? (m = n.pos, n.nodes.push(b)) : m = l++;
          g[m] || (g[m] = n = {
            depth: e ? e.depth + 1 : 0,
            name: c,
            id: a.id,
            nodes: [b],
            children: [],
            pos: m
          }, -1 !== m && d.push(c), G(e, !0) && e.children.push(n));
          H(b.id) && (f[b.id] = b);
          n && !0 === a.collapsed && (n.collapsed = !0);
          b.pos = m;
        }
      });
      g = function (b, a) {
        var d = function (b, c, e) {
          var f = c + (-1 === c ? 0 : a - 1),
            h = (f - c) / 2,
            k = c + h;
          b.nodes.forEach(function (b) {
            var a = b.data;
            G(a, !0) && (a.y = c + (a.seriesIndex || 0), delete a.seriesIndex);
            b.pos = k;
          });
          e[k] = b;
          b.pos = k;
          b.tickmarkOffset = h + .5;
          b.collapseStart = f + .5;
          b.children.forEach(function (b) {
            d(b, f + 1, e);
            f = (b.collapseEnd || 0) - .5;
          });
          b.collapseEnd = f + .5;
          return e;
        };
        return d(b["-1"], -1, {});
      }(g, c);
      return {
        categories: d,
        mapOfIdToNode: f,
        mapOfPosToGridNode: g,
        collapsedNodes: e,
        tree: a
      };
    }
    function t(a) {
      a.target.axes.filter(function (b) {
        return "treegrid" === b.options.type;
      }).forEach(function (b) {
        var d = b.options || {},
          c = d.labels,
          e = d.uniqueNames;
        d = d.max;
        var f = 0;
        if (!b.treeGrid.mapOfPosToGridNode || b.series.some(function (b) {
          return !b.hasRendered || b.isDirtyData || b.isDirty;
        })) {
          var k = b.series.reduce(function (b, a) {
            a.visible && ((a.options.data || []).forEach(function (d) {
              a.options.keys && a.options.keys.length && (d = a.pointClass.prototype.optionsToObject.call({
                series: a
              }, d), a.pointClass.setGanttPointAliases(d));
              G(d, !0) && (d.seriesIndex = f, b.push(d));
            }), !0 === e && f++);
            return b;
          }, []);
          if (d && k.length < d) for (var g = k.length; g <= d; g++) k.push({
            name: g + "\u200b"
          });
          d = x(k, e || !1, !0 === e ? f : 1);
          b.categories = d.categories;
          b.treeGrid.mapOfPosToGridNode = d.mapOfPosToGridNode;
          b.hasNames = !0;
          b.treeGrid.tree = d.tree;
          b.series.forEach(function (b) {
            var a = (b.options.data || []).map(function (a) {
              D(a) && b.options.keys && b.options.keys.length && k.forEach(function (b) {
                0 <= a.indexOf(b.x) && 0 <= a.indexOf(b.x2) && (a = b);
              });
              return G(a, !0) ? K(a) : a;
            });
            b.visible && b.setData(a, !1);
          });
          b.treeGrid.mapOptionsToLevel = m({
            defaults: c,
            from: 1,
            levels: c && c.levels,
            to: b.treeGrid.tree && b.treeGrid.tree.height
          });
          "beforeRender" === a.type && (b.treeGrid.collapsedNodes = d.collapsedNodes);
        }
      });
    }
    function r(a, b) {
      var d = this.treeGrid.mapOptionsToLevel || {},
        c = this.ticks,
        e = c[b],
        k;
      if ("treegrid" === this.options.type && this.treeGrid.mapOfPosToGridNode) {
        var g = this.treeGrid.mapOfPosToGridNode[b];
        (d = d[g.depth]) && (k = {
          labels: d
        });
        !e && f ? c[b] = new f(this, b, void 0, void 0, {
          category: g.name,
          tickmarkOffset: g.tickmarkOffset,
          options: k
        }) : (e.parameters.category = g.name, e.options = k, e.addLabel());
      } else a.apply(this, Array.prototype.slice.call(arguments, 1));
    }
    function a(a, b, c) {
      var d = this,
        e = "treegrid" === c.type;
      d.treeGrid || (d.treeGrid = new l(d));
      e && (p(b, "beforeRender", t), p(b, "beforeRedraw", t), p(b, "addSeries", function (b) {
        b.options.data && (b = x(b.options.data, c.uniqueNames || !1, 1), d.treeGrid.collapsedNodes = (d.treeGrid.collapsedNodes || []).concat(b.collapsedNodes));
      }), p(d, "foundExtremes", function () {
        d.treeGrid.collapsedNodes && d.treeGrid.collapsedNodes.forEach(function (b) {
          var a = d.treeGrid.collapse(b);
          d.brokenAxis && (d.brokenAxis.setBreaks(a, !1), d.treeGrid.collapsedNodes && (d.treeGrid.collapsedNodes = d.treeGrid.collapsedNodes.filter(function (a) {
            return b.collapseStart !== a.collapseStart || b.collapseEnd !== a.collapseEnd;
          })));
        });
      }), p(d, "afterBreaks", function () {
        "yAxis" === d.coll && !d.staticScale && d.chart.options.chart.height && (d.isDirty = !0);
      }), c = K({
        grid: {
          enabled: !0
        },
        labels: {
          align: "left",
          levels: [{
            level: void 0
          }, {
            level: 1,
            style: {
              fontWeight: "bold"
            }
          }],
          symbol: {
            type: "triangle",
            x: -5,
            y: -5,
            height: 10,
            width: 10,
            padding: 5
          }
        },
        uniqueNames: !1
      }, c, {
        reversed: !0,
        grid: {
          columns: void 0
        }
      }));
      a.apply(d, [b, c]);
      e && (d.hasNames = !0, d.options.showLastLabel = !0);
    }
    function q(a) {
      var b = this.options;
      "treegrid" === b.type ? (this.min = u(this.userMin, b.min, this.dataMin), this.max = u(this.userMax, b.max, this.dataMax), e(this, "foundExtremes"), this.setAxisTranslation(), this.tickmarkOffset = .5, this.tickInterval = 1, this.tickPositions = this.treeGrid.mapOfPosToGridNode ? this.treeGrid.getTickPositions() : []) : a.apply(this, Array.prototype.slice.call(arguments, 1));
    }
    var m = B.getLevelOptions,
      p = z.addEvent,
      k = z.find,
      e = z.fireEvent,
      D = z.isArray,
      G = z.isObject,
      H = z.isString,
      K = z.merge,
      u = z.pick,
      n = z.wrap,
      w = [],
      f,
      l = function () {
        function d(b) {
          this.axis = b;
        }
        d.compose = function (b, d, e, k) {
          if (-1 === w.indexOf(b)) {
            w.push(b);
            -1 === b.keepProps.indexOf("treeGrid") && b.keepProps.push("treeGrid");
            var h = b.prototype;
            n(h, "generateTick", r);
            n(h, "init", a);
            n(h, "setTickInterval", q);
            h.utils = {
              getNode: y.getNode
            };
          }
          -1 === w.indexOf(k) && (w.push(k), f || (f = k));
          g.compose(b, d, k);
          c.compose(b, e);
          A.compose(k);
          return b;
        };
        d.prototype.setCollapsedStatus = function (b) {
          var a = this.axis,
            d = a.chart;
          a.series.forEach(function (a) {
            var c = a.options.data;
            if (b.id && c) {
              var e = d.get(b.id);
              a = c[a.data.indexOf(e)];
              e && a && (e.collapsed = b.collapsed, a.collapsed = b.collapsed);
            }
          });
        };
        d.prototype.collapse = function (b) {
          var a = this.axis,
            d = a.options.breaks || [],
            c = C(b, a.max);
          d.push(c);
          b.collapsed = !0;
          a.treeGrid.setCollapsedStatus(b);
          return d;
        };
        d.prototype.expand = function (b) {
          var a = this.axis,
            d = a.options.breaks || [],
            c = C(b, a.max);
          b.collapsed = !1;
          a.treeGrid.setCollapsedStatus(b);
          return d.reduce(function (b, a) {
            a.to === c.to && a.from === c.from || b.push(a);
            return b;
          }, []);
        };
        d.prototype.getTickPositions = function () {
          var b = this.axis,
            a = Math.floor(b.min / b.tickInterval) * b.tickInterval,
            d = Math.ceil(b.max / b.tickInterval) * b.tickInterval;
          return Object.keys(b.treeGrid.mapOfPosToGridNode || {}).reduce(function (c, e) {
            e = +e;
            !(e >= a && e <= d) || b.brokenAxis && b.brokenAxis.isInAnyBreak(e) || c.push(e);
            return c;
          }, []);
        };
        d.prototype.isCollapsed = function (b) {
          var a = this.axis,
            d = a.options.breaks || [],
            c = C(b, a.max);
          return d.some(function (b) {
            return b.from === c.from && b.to === c.to;
          });
        };
        d.prototype.toggleCollapse = function (b) {
          return this.isCollapsed(b) ? this.expand(b) : this.collapse(b);
        };
        return d;
      }();
    return l;
  });
  I(g, "Extensions/CurrentDateIndication.js", [g["Core/Axis/Axis.js"], g["Core/Axis/PlotLineOrBand/PlotLineOrBand.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = y.addEvent,
      B = y.merge;
    y = y.wrap;
    var z = {
      color: "#ccd6eb",
      width: 2,
      label: {
        format: "%a, %b %d %Y, %H:%M",
        formatter: function (c, g) {
          return this.axis.chart.time.dateFormat(g || "", c);
        },
        rotation: 0,
        style: {
          fontSize: "10px"
        }
      }
    };
    A(c, "afterSetOptions", function () {
      var c = this.options,
        g = c.currentDateIndicator;
      g && (g = "object" === typeof g ? B(z, g) : B(z), g.value = Date.now(), g.className = "highcharts-current-date-indicator", c.plotLines || (c.plotLines = []), c.plotLines.push(g));
    });
    A(g, "render", function () {
      this.label && this.label.attr({
        text: this.getLabelText(this.options.label)
      });
    });
    y(g.prototype, "getLabelText", function (c, g) {
      var t = this.options;
      return t && t.className && -1 !== t.className.indexOf("highcharts-current-date-indicator") && t.label && "function" === typeof t.label.formatter ? (t.value = Date.now(), t.label.formatter.call(this, t.value, t.label.format)) : c.call(this, g);
    });
  });
  I(g, "Extensions/StaticScale.js", [g["Core/Axis/Axis.js"], g["Core/Chart/Chart.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = y.addEvent,
      B = y.defined,
      z = y.isNumber,
      C = y.pick;
    A(c, "afterSetOptions", function () {
      var c = this.chart.options.chart;
      !this.horiz && z(this.options.staticScale) && (!c.height || c.scrollablePlotArea && c.scrollablePlotArea.minHeight) && (this.staticScale = this.options.staticScale);
    });
    g.prototype.adjustHeight = function () {
      "adjustHeight" !== this.redrawTrigger && ((this.axes || []).forEach(function (c) {
        var g = c.chart,
          r = !!g.initiatedScale && g.options.animation,
          a = c.options.staticScale;
        if (c.staticScale && B(c.min)) {
          var q = C(c.brokenAxis && c.brokenAxis.unitLength, c.max + c.tickInterval - c.min) * a;
          q = Math.max(q, a);
          a = q - g.plotHeight;
          !g.scrollablePixelsY && 1 <= Math.abs(a) && (g.plotHeight = q, g.redrawTrigger = "adjustHeight", g.setSize(void 0, g.chartHeight + a, r));
          c.series.forEach(function (a) {
            (a = a.sharedClipKey && g.sharedClips[a.sharedClipKey]) && a.attr(g.inverted ? {
              width: g.plotHeight
            } : {
              height: g.plotHeight
            });
          });
        }
      }), this.initiatedScale = !0);
      this.redrawTrigger = null;
    };
    A(g, "render", g.prototype.adjustHeight);
  });
  I(g, "Extensions/ArrowSymbols.js", [g["Core/Renderer/SVG/SVGRenderer.js"]], function (c) {
    function g(c, g, y, x) {
      return [["M", c, g + x / 2], ["L", c + y, g], ["L", c, g + x / 2], ["L", c + y, g + x]];
    }
    function y(c, g, y, x) {
      return [["M", c + y, g], ["L", c, g + x / 2], ["L", c + y, g + x], ["Z"]];
    }
    function A(c, g, A, x) {
      return y(c, g, A / 2, x);
    }
    c = c.prototype.symbols;
    c.arrow = g;
    c["arrow-filled"] = y;
    c["arrow-filled-half"] = A;
    c["arrow-half"] = function (c, y, A, x) {
      return g(c, y, A / 2, x);
    };
    c["triangle-left"] = y;
    c["triangle-left-half"] = A;
    return c;
  });
  I(g, "Gantt/Connection.js", [g["Core/Defaults.js"], g["Core/Globals.js"], g["Core/Series/Point.js"], g["Core/Utilities.js"]], function (c, g, y, A) {
    function B(a) {
      var c = a.shapeArgs;
      return c ? {
        xMin: c.x || 0,
        xMax: (c.x || 0) + (c.width || 0),
        yMin: c.y || 0,
        yMax: (c.y || 0) + (c.height || 0)
      } : (c = a.graphic && a.graphic.getBBox()) ? {
        xMin: a.plotX - c.width / 2,
        xMax: a.plotX + c.width / 2,
        yMin: a.plotY - c.height / 2,
        yMax: a.plotY + c.height / 2
      } : null;
    }
    var z = c.defaultOptions,
      C = A.defined,
      x = A.error;
    c = A.extend;
    var t = A.merge,
      r = A.objectEach;
    "";
    var a = g.deg2rad,
      q = Math.max,
      m = Math.min;
    c(z, {
      connectors: {
        type: "straight",
        lineWidth: 1,
        marker: {
          enabled: !1,
          align: "center",
          verticalAlign: "middle",
          inside: !1,
          lineWidth: 1
        },
        startMarker: {
          symbol: "diamond"
        },
        endMarker: {
          symbol: "arrow-filled"
        }
      }
    });
    A = function () {
      function c(a, c, g) {
        this.toPoint = this.pathfinder = this.graphics = this.fromPoint = this.chart = void 0;
        this.init(a, c, g);
      }
      c.prototype.init = function (a, c, g) {
        this.fromPoint = a;
        this.toPoint = c;
        this.options = g;
        this.chart = a.series.chart;
        this.pathfinder = this.chart.pathfinder;
      };
      c.prototype.renderPath = function (a, c, g) {
        var e = this.chart,
          k = e.styledMode,
          m = e.pathfinder,
          p = !e.options.chart.forExport && !1 !== g,
          n = this.graphics && this.graphics.path;
        m.group || (m.group = e.renderer.g().addClass("highcharts-pathfinder-group").attr({
          zIndex: -1
        }).add(e.seriesGroup));
        m.group.translate(e.plotLeft, e.plotTop);
        n && n.renderer || (n = e.renderer.path().add(m.group), k || n.attr({
          opacity: 0
        }));
        n.attr(c);
        a = {
          d: a
        };
        k || (a.opacity = 1);
        n[p ? "animate" : "attr"](a, g);
        this.graphics = this.graphics || {};
        this.graphics.path = n;
      };
      c.prototype.addMarker = function (c, e, g) {
        var k = this.fromPoint.series.chart,
          m = k.pathfinder;
        k = k.renderer;
        var p = "start" === c ? this.fromPoint : this.toPoint,
          q = p.getPathfinderAnchorPoint(e);
        if (e.enabled && ((g = "start" === c ? g[1] : g[g.length - 2]) && "M" === g[0] || "L" === g[0])) {
          g = {
            x: g[1],
            y: g[2]
          };
          g = p.getRadiansToVector(g, q);
          q = p.getMarkerVector(g, e.radius, q);
          g = -g / a;
          if (e.width && e.height) {
            var n = e.width;
            var r = e.height;
          } else n = r = 2 * e.radius;
          this.graphics = this.graphics || {};
          q = {
            x: q.x - n / 2,
            y: q.y - r / 2,
            width: n,
            height: r,
            rotation: g,
            rotationOriginX: q.x,
            rotationOriginY: q.y
          };
          this.graphics[c] ? this.graphics[c].animate(q) : (this.graphics[c] = k.symbol(e.symbol).addClass("highcharts-point-connecting-path-" + c + "-marker").attr(q).add(m.group), k.styledMode || this.graphics[c].attr({
            fill: e.color || this.fromPoint.color,
            stroke: e.lineColor,
            "stroke-width": e.lineWidth,
            opacity: 0
          }).animate({
            opacity: 1
          }, p.series.options.animation));
        }
      };
      c.prototype.getPath = function (a) {
        var c = this.pathfinder,
          g = this.chart,
          k = c.algorithms[a.type],
          m = c.chartObstacles;
        if ("function" !== typeof k) return x('"' + a.type + '" is not a Pathfinder algorithm.'), {
          path: [],
          obstacles: []
        };
        k.requiresObstacles && !m && (m = c.chartObstacles = c.getChartObstacles(a), g.options.connectors.algorithmMargin = a.algorithmMargin, c.chartObstacleMetrics = c.getObstacleMetrics(m));
        return k(this.fromPoint.getPathfinderAnchorPoint(a.startMarker), this.toPoint.getPathfinderAnchorPoint(a.endMarker), t({
          chartObstacles: m,
          lineObstacles: c.lineObstacles || [],
          obstacleMetrics: c.chartObstacleMetrics,
          hardBounds: {
            xMin: 0,
            xMax: g.plotWidth,
            yMin: 0,
            yMax: g.plotHeight
          },
          obstacleOptions: {
            margin: a.algorithmMargin
          },
          startDirectionX: c.getAlgorithmStartDirection(a.startMarker)
        }, a));
      };
      c.prototype.render = function () {
        var a = this.fromPoint,
          c = a.series,
          g = c.chart,
          p = g.pathfinder,
          r = t(g.options.connectors, c.options.connectors, a.options.connectors, this.options),
          x = {};
        g.styledMode || (x.stroke = r.lineColor || a.color, x["stroke-width"] = r.lineWidth, r.dashStyle && (x.dashstyle = r.dashStyle));
        x["class"] = "highcharts-point-connecting-path highcharts-color-" + a.colorIndex;
        r = t(x, r);
        C(r.marker.radius) || (r.marker.radius = m(q(Math.ceil((r.algorithmMargin || 8) / 2) - 1, 1), 5));
        a = this.getPath(r);
        g = a.path;
        a.obstacles && (p.lineObstacles = p.lineObstacles || [], p.lineObstacles = p.lineObstacles.concat(a.obstacles));
        this.renderPath(g, x, c.options.animation);
        this.addMarker("start", t(r.marker, r.startMarker), g);
        this.addMarker("end", t(r.marker, r.endMarker), g);
      };
      c.prototype.destroy = function () {
        this.graphics && (r(this.graphics, function (a) {
          a.destroy();
        }), delete this.graphics);
      };
      return c;
    }();
    g.Connection = A;
    c(y.prototype, {
      getPathfinderAnchorPoint: function (a) {
        var c = B(this);
        switch (a.align) {
          case "right":
            var e = "xMax";
            break;
          case "left":
            e = "xMin";
        }
        switch (a.verticalAlign) {
          case "top":
            var g = "yMin";
            break;
          case "bottom":
            g = "yMax";
        }
        return {
          x: e ? c[e] : (c.xMin + c.xMax) / 2,
          y: g ? c[g] : (c.yMin + c.yMax) / 2
        };
      },
      getRadiansToVector: function (a, c) {
        var e;
        C(c) || (e = B(this)) && (c = {
          x: (e.xMin + e.xMax) / 2,
          y: (e.yMin + e.yMax) / 2
        });
        return Math.atan2(c.y - a.y, a.x - c.x);
      },
      getMarkerVector: function (a, c, e) {
        var g = 2 * Math.PI,
          k = B(this),
          m = k.xMax - k.xMin,
          p = k.yMax - k.yMin,
          q = Math.atan2(p, m),
          n = !1;
        m /= 2;
        var r = p / 2,
          f = k.xMin + m;
        k = k.yMin + r;
        for (var l = f, d = k, b = 1, h = 1; a < -Math.PI;) a += g;
        for (; a > Math.PI;) a -= g;
        g = Math.tan(a);
        a > -q && a <= q ? (h = -1, n = !0) : a > q && a <= Math.PI - q ? h = -1 : a > Math.PI - q || a <= -(Math.PI - q) ? (b = -1, n = !0) : b = -1;
        n ? (l += b * m, d += h * m * g) : (l += p / (2 * g) * b, d += h * r);
        e.x !== f && (l = e.x);
        e.y !== k && (d = e.y);
        return {
          x: l + c * Math.cos(a),
          y: d - c * Math.sin(a)
        };
      }
    });
    return A;
  });
  I(g, "Gantt/PathfinderAlgorithms.js", [g["Core/Utilities.js"]], function (c) {
    function g(a, c, g) {
      g = g || 0;
      var m = a.length - 1;
      c -= 1e-7;
      for (var k, e; g <= m;) if (k = m + g >> 1, e = c - a[k].xMin, 0 < e) g = k + 1;else if (0 > e) m = k - 1;else return k;
      return 0 < g ? g - 1 : 0;
    }
    function y(a, c) {
      for (var m = g(a, c.x + 1) + 1; m--;) {
        var p;
        if (p = a[m].xMax >= c.x) p = a[m], p = c.x <= p.xMax && c.x >= p.xMin && c.y <= p.yMax && c.y >= p.yMin;
        if (p) return m;
      }
      return -1;
    }
    function A(a) {
      var c = [];
      if (a.length) {
        c.push(["M", a[0].start.x, a[0].start.y]);
        for (var g = 0; g < a.length; ++g) c.push(["L", a[g].end.x, a[g].end.y]);
      }
      return c;
    }
    function B(a, c) {
      a.yMin = x(a.yMin, c.yMin);
      a.yMax = C(a.yMax, c.yMax);
      a.xMin = x(a.xMin, c.xMin);
      a.xMax = C(a.xMax, c.xMax);
    }
    var z = c.pick,
      C = Math.min,
      x = Math.max,
      t = Math.abs;
    c = function (a, c, g) {
      function m(a, c, e, g, d) {
        a = {
          x: a.x,
          y: a.y
        };
        a[c] = e[g || c] + (d || 0);
        return a;
      }
      function k(a, c, e) {
        var f = t(c[e] - a[e + "Min"]) > t(c[e] - a[e + "Max"]);
        return m(c, e, a, e + (f ? "Max" : "Min"), f ? 1 : -1);
      }
      var e = [],
        q = z(g.startDirectionX, t(c.x - a.x) > t(c.y - a.y)) ? "x" : "y",
        r = g.chartObstacles,
        x = y(r, a);
      g = y(r, c);
      if (-1 < g) {
        var B = r[g];
        g = k(B, c, q);
        B = {
          start: g,
          end: c
        };
        var u = g;
      } else u = c;
      -1 < x && (r = r[x], g = k(r, a, q), e.push({
        start: a,
        end: g
      }), g[q] >= a[q] === g[q] >= u[q] && (q = "y" === q ? "x" : "y", c = a[q] < c[q], e.push({
        start: g,
        end: m(g, q, r, q + (c ? "Max" : "Min"), c ? 1 : -1)
      }), q = "y" === q ? "x" : "y"));
      a = e.length ? e[e.length - 1].end : a;
      g = m(a, q, u);
      e.push({
        start: a,
        end: g
      });
      q = m(g, "y" === q ? "x" : "y", u);
      e.push({
        start: g,
        end: q
      });
      e.push(B);
      return {
        path: A(e),
        obstacles: e
      };
    };
    c.requiresObstacles = !0;
    var r = function (a, c, m) {
      function p(a, d, c) {
        var e,
          f = a.x < d.x ? 1 : -1;
        if (a.x < d.x) {
          var h = a;
          var k = d;
        } else h = d, k = a;
        if (a.y < d.y) {
          var l = a;
          var m = d;
        } else l = d, m = a;
        for (e = 0 > f ? C(g(b, k.x), b.length - 1) : 0; b[e] && (0 < f && b[e].xMin <= k.x || 0 > f && b[e].xMax >= h.x);) {
          if (b[e].xMin <= k.x && b[e].xMax >= h.x && b[e].yMin <= m.y && b[e].yMax >= l.y) return c ? {
            y: a.y,
            x: a.x < d.x ? b[e].xMin - 1 : b[e].xMax + 1,
            obstacle: b[e]
          } : {
            x: a.x,
            y: a.y < d.y ? b[e].yMin - 1 : b[e].yMax + 1,
            obstacle: b[e]
          };
          e += f;
        }
        return d;
      }
      function k(a, b, d, c, e) {
        var f = e.soft,
          h = e.hard,
          g = c ? "x" : "y",
          k = {
            x: b.x,
            y: b.y
          },
          l = {
            x: b.x,
            y: b.y
          };
        e = a[g + "Max"] >= f[g + "Max"];
        f = a[g + "Min"] <= f[g + "Min"];
        var m = a[g + "Max"] >= h[g + "Max"];
        h = a[g + "Min"] <= h[g + "Min"];
        var n = t(a[g + "Min"] - b[g]),
          q = t(a[g + "Max"] - b[g]);
        d = 10 > t(n - q) ? b[g] < d[g] : q < n;
        l[g] = a[g + "Min"];
        k[g] = a[g + "Max"];
        a = p(b, l, c)[g] !== l[g];
        b = p(b, k, c)[g] !== k[g];
        d = a ? b ? d : !0 : b ? !1 : d;
        d = f ? e ? d : !0 : e ? !1 : d;
        return h ? m ? d : !0 : m ? !1 : d;
      }
      function e(a, c, h) {
        if (a.x === c.x && a.y === c.y) return [];
        var g = h ? "x" : "y",
          n = m.obstacleOptions.margin;
        var q = {
          soft: {
            xMin: w,
            xMax: f,
            yMin: l,
            yMax: d
          },
          hard: m.hardBounds
        };
        var r = y(b, a);
        if (-1 < r) {
          r = b[r];
          q = k(r, a, c, h, q);
          B(r, m.hardBounds);
          var t = h ? {
            y: a.y,
            x: r[q ? "xMax" : "xMin"] + (q ? 1 : -1)
          } : {
            x: a.x,
            y: r[q ? "yMax" : "yMin"] + (q ? 1 : -1)
          };
          var v = y(b, t);
          -1 < v && (v = b[v], B(v, m.hardBounds), t[g] = q ? x(r[g + "Max"] - n + 1, (v[g + "Min"] + r[g + "Max"]) / 2) : C(r[g + "Min"] + n - 1, (v[g + "Max"] + r[g + "Min"]) / 2), a.x === t.x && a.y === t.y ? (u && (t[g] = q ? x(r[g + "Max"], v[g + "Max"]) + 1 : C(r[g + "Min"], v[g + "Min"]) - 1), u = !u) : u = !1);
          a = [{
            start: a,
            end: t
          }];
        } else g = p(a, {
          x: h ? c.x : a.x,
          y: h ? a.y : c.y
        }, h), a = [{
          start: a,
          end: {
            x: g.x,
            y: g.y
          }
        }], g[h ? "x" : "y"] !== c[h ? "x" : "y"] && (q = k(g.obstacle, g, c, !h, q), B(g.obstacle, m.hardBounds), q = {
          x: h ? g.x : g.obstacle[q ? "xMax" : "xMin"] + (q ? 1 : -1),
          y: h ? g.obstacle[q ? "yMax" : "yMin"] + (q ? 1 : -1) : g.y
        }, h = !h, a = a.concat(e({
          x: g.x,
          y: g.y
        }, q, h)));
        return a = a.concat(e(a[a.length - 1].end, c, !h));
      }
      function q(a, b, d) {
        var c = C(a.xMax - b.x, b.x - a.xMin) < C(a.yMax - b.y, b.y - a.yMin);
        d = k(a, b, d, c, {
          soft: m.hardBounds,
          hard: m.hardBounds
        });
        return c ? {
          y: b.y,
          x: a[d ? "xMax" : "xMin"] + (d ? 1 : -1)
        } : {
          x: b.x,
          y: a[d ? "yMax" : "yMin"] + (d ? 1 : -1)
        };
      }
      var r = z(m.startDirectionX, t(c.x - a.x) > t(c.y - a.y)),
        H = r ? "x" : "y",
        I = [],
        u = !1,
        n = m.obstacleMetrics,
        w = C(a.x, c.x) - n.maxWidth - 10,
        f = x(a.x, c.x) + n.maxWidth + 10,
        l = C(a.y, c.y) - n.maxHeight - 10,
        d = x(a.y, c.y) + n.maxHeight + 10,
        b = m.chartObstacles;
      var h = g(b, w);
      n = g(b, f);
      b = b.slice(h, n + 1);
      if (-1 < (n = y(b, c))) {
        var v = q(b[n], c, a);
        I.push({
          end: c,
          start: v
        });
        c = v;
      }
      for (; -1 < (n = y(b, c));) h = 0 > c[H] - a[H], v = {
        x: c.x,
        y: c.y
      }, v[H] = b[n][h ? H + "Max" : H + "Min"] + (h ? 1 : -1), I.push({
        end: c,
        start: v
      }), c = v;
      a = e(a, c, r);
      a = a.concat(I.reverse());
      return {
        path: A(a),
        obstacles: a
      };
    };
    r.requiresObstacles = !0;
    return {
      fastAvoid: r,
      straight: function (a, c) {
        return {
          path: [["M", a.x, a.y], ["L", c.x, c.y]],
          obstacles: [{
            start: a,
            end: c
          }]
        };
      },
      simpleConnect: c
    };
  });
  I(g, "Gantt/Pathfinder.js", [g["Gantt/Connection.js"], g["Core/Chart/Chart.js"], g["Core/Defaults.js"], g["Core/Globals.js"], g["Core/Series/Point.js"], g["Core/Utilities.js"], g["Gantt/PathfinderAlgorithms.js"]], function (c, g, y, A, B, z, C) {
    function x(a) {
      var c = a.shapeArgs;
      return c ? {
        xMin: c.x || 0,
        xMax: (c.x || 0) + (c.width || 0),
        yMin: c.y || 0,
        yMax: (c.y || 0) + (c.height || 0)
      } : (c = a.graphic && a.graphic.getBBox()) ? {
        xMin: a.plotX - c.width / 2,
        xMax: a.plotX + c.width / 2,
        yMin: a.plotY - c.height / 2,
        yMax: a.plotY + c.height / 2
      } : null;
    }
    function t(a) {
      for (var c = a.length, g = 0, f, k, d = [], b = function (a, d, c) {
          c = e(c, 10);
          var f = a.yMax + c > d.yMin - c && a.yMin - c < d.yMax + c,
            g = a.xMax + c > d.xMin - c && a.xMin - c < d.xMax + c,
            h = f ? a.xMin > d.xMax ? a.xMin - d.xMax : d.xMin - a.xMax : Infinity,
            k = g ? a.yMin > d.yMax ? a.yMin - d.yMax : d.yMin - a.yMax : Infinity;
          return g && f ? c ? b(a, d, Math.floor(c / 2)) : Infinity : H(h, k);
        }; g < c; ++g) for (f = g + 1; f < c; ++f) k = b(a[g], a[f]), 80 > k && d.push(k);
      d.push(80);
      return G(Math.floor(d.sort(function (a, b) {
        return a - b;
      })[Math.floor(d.length / 10)] / 2 - 1), 1);
    }
    function r(a) {
      if (a.options.pathfinder || a.series.reduce(function (a, c) {
        c.options && k(!0, c.options.connectors = c.options.connectors || {}, c.options.pathfinder);
        return a || c.options && c.options.pathfinder;
      }, !1)) k(!0, a.options.connectors = a.options.connectors || {}, a.options.pathfinder), m('WARNING: Pathfinder options have been renamed. Use "chart.connectors" or "series.connectors" instead.');
    }
    y = y.defaultOptions;
    var a = z.addEvent,
      q = z.defined,
      m = z.error,
      p = z.extend,
      k = z.merge,
      e = z.pick,
      D = z.splat;
    "";
    var G = Math.max,
      H = Math.min;
    p(y, {
      connectors: {
        type: "straight",
        lineWidth: 1,
        marker: {
          enabled: !1,
          align: "center",
          verticalAlign: "middle",
          inside: !1,
          lineWidth: 1
        },
        startMarker: {
          symbol: "diamond"
        },
        endMarker: {
          symbol: "arrow-filled"
        }
      }
    });
    var I = function () {
      function g(a) {
        this.lineObstacles = this.group = this.connections = this.chartObstacleMetrics = this.chartObstacles = this.chart = void 0;
        this.init(a);
      }
      g.prototype.init = function (c) {
        this.chart = c;
        this.connections = [];
        a(c, "redraw", function () {
          this.pathfinder.update();
        });
      };
      g.prototype.update = function (a) {
        var e = this.chart,
          f = this,
          g = f.connections;
        f.connections = [];
        e.series.forEach(function (a) {
          a.visible && !a.options.isInternal && a.points.forEach(function (a) {
            var b = a.options;
            b && b.dependency && (b.connect = b.dependency);
            var d;
            b = a.options && a.options.connect && D(a.options.connect);
            a.visible && !1 !== a.isInside && b && b.forEach(function (b) {
              d = e.get("string" === typeof b ? b : b.to);
              d instanceof B && d.series.visible && d.visible && !1 !== d.isInside && f.connections.push(new c(a, d, "string" === typeof b ? {} : b));
            });
          });
        });
        for (var d = 0, b = void 0, h = void 0, k = g.length, m = f.connections.length; d < k; ++d) {
          h = !1;
          var n = g[d];
          for (b = 0; b < m; ++b) {
            var p = f.connections[b];
            if ((n.options && n.options.type) === (p.options && p.options.type) && n.fromPoint === p.fromPoint && n.toPoint === p.toPoint) {
              p.graphics = n.graphics;
              h = !0;
              break;
            }
          }
          h || n.destroy();
        }
        delete this.chartObstacles;
        delete this.lineObstacles;
        f.renderConnections(a);
      };
      g.prototype.renderConnections = function (c) {
        c ? this.chart.series.forEach(function (c) {
          var e = function () {
            var a = c.chart.pathfinder;
            (a && a.connections || []).forEach(function (a) {
              a.fromPoint && a.fromPoint.series === c && a.render();
            });
            c.pathfinderRemoveRenderEvent && (c.pathfinderRemoveRenderEvent(), delete c.pathfinderRemoveRenderEvent);
          };
          !1 === c.options.animation ? e() : c.pathfinderRemoveRenderEvent = a(c, "afterAnimate", e);
        }) : this.connections.forEach(function (a) {
          a.render();
        });
      };
      g.prototype.getChartObstacles = function (a) {
        for (var c = [], f = this.chart.series, g = e(a.algorithmMargin, 0), d, b = 0, h = f.length; b < h; ++b) if (f[b].visible && !f[b].options.isInternal) {
          var k = 0,
            m = f[b].points.length,
            n = void 0;
          for (n = void 0; k < m; ++k) n = f[b].points[k], n.visible && (n = x(n)) && c.push({
            xMin: n.xMin - g,
            xMax: n.xMax + g,
            yMin: n.yMin - g,
            yMax: n.yMax + g
          });
        }
        c = c.sort(function (a, b) {
          return a.xMin - b.xMin;
        });
        q(a.algorithmMargin) || (d = a.algorithmMargin = t(c), c.forEach(function (a) {
          a.xMin -= d;
          a.xMax += d;
          a.yMin -= d;
          a.yMax += d;
        }));
        return c;
      };
      g.prototype.getObstacleMetrics = function (a) {
        for (var c = 0, e = 0, g, d, b = a.length; b--;) g = a[b].xMax - a[b].xMin, d = a[b].yMax - a[b].yMin, c < g && (c = g), e < d && (e = d);
        return {
          maxHeight: e,
          maxWidth: c
        };
      };
      g.prototype.getAlgorithmStartDirection = function (a) {
        var c = "top" !== a.verticalAlign && "bottom" !== a.verticalAlign;
        return "left" !== a.align && "right" !== a.align ? c ? void 0 : !1 : c ? !0 : void 0;
      };
      return g;
    }();
    I.prototype.algorithms = C;
    A.Pathfinder = I;
    p(B.prototype, {
      getPathfinderAnchorPoint: function (a) {
        var c = x(this);
        switch (a.align) {
          case "right":
            var e = "xMax";
            break;
          case "left":
            e = "xMin";
        }
        switch (a.verticalAlign) {
          case "top":
            var f = "yMin";
            break;
          case "bottom":
            f = "yMax";
        }
        return {
          x: e ? c[e] : (c.xMin + c.xMax) / 2,
          y: f ? c[f] : (c.yMin + c.yMax) / 2
        };
      },
      getRadiansToVector: function (a, c) {
        var e;
        q(c) || (e = x(this)) && (c = {
          x: (e.xMin + e.xMax) / 2,
          y: (e.yMin + e.yMax) / 2
        });
        return Math.atan2(c.y - a.y, a.x - c.x);
      },
      getMarkerVector: function (a, c, e) {
        var f = 2 * Math.PI,
          g = x(this),
          d = g.xMax - g.xMin,
          b = g.yMax - g.yMin,
          h = Math.atan2(b, d),
          k = !1;
        d /= 2;
        var m = b / 2,
          n = g.xMin + d;
        g = g.yMin + m;
        for (var p = n, q = g, r = 1, t = 1; a < -Math.PI;) a += f;
        for (; a > Math.PI;) a -= f;
        f = Math.tan(a);
        a > -h && a <= h ? (t = -1, k = !0) : a > h && a <= Math.PI - h ? t = -1 : a > Math.PI - h || a <= -(Math.PI - h) ? (r = -1, k = !0) : r = -1;
        k ? (p += r * d, q += t * d * f) : (p += b / (2 * f) * r, q += t * m);
        e.x !== n && (p = e.x);
        e.y !== g && (q = e.y);
        return {
          x: p + c * Math.cos(a),
          y: q - c * Math.sin(a)
        };
      }
    });
    g.prototype.callbacks.push(function (a) {
      !1 !== a.options.connectors.enabled && (r(a), this.pathfinder = new I(this), this.pathfinder.update(!0));
    });
    return I;
  });
  I(g, "Series/Gantt/GanttSeries.js", [g["Core/Axis/Axis.js"], g["Core/Chart/Chart.js"], g["Series/Gantt/GanttPoint.js"], g["Core/Series/SeriesRegistry.js"], g["Core/Axis/Tick.js"], g["Core/Utilities.js"], g["Core/Axis/TreeGrid/TreeGridAxis.js"]], function (c, g, y, A, B, z, C) {
    var x = this && this.__extends || function () {
        var a = function (c, e) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var e in c) c.hasOwnProperty(e) && (a[e] = c[e]);
          };
          return a(c, e);
        };
        return function (c, e) {
          function g() {
            this.constructor = c;
          }
          a(c, e);
          c.prototype = null === e ? Object.create(e) : (g.prototype = e.prototype, new g());
        };
      }(),
      t = A.series,
      r = A.seriesTypes.xrange,
      a = z.extend,
      q = z.isNumber,
      m = z.merge;
    C.compose(c, g, t, B);
    c = function (a) {
      function c() {
        var c = null !== a && a.apply(this, arguments) || this;
        c.data = void 0;
        c.options = void 0;
        c.points = void 0;
        return c;
      }
      x(c, a);
      c.prototype.drawPoint = function (a, c) {
        var e = this.options,
          g = this.chart.renderer,
          k = a.shapeArgs,
          m = a.plotY,
          n = a.graphic,
          p = a.selected && "select",
          f = e.stacking && !e.borderRadius;
        if (a.options.milestone) {
          if (q(m) && null !== a.y && !1 !== a.visible) {
            k = g.symbols.diamond(k.x || 0, k.y || 0, k.width || 0, k.height || 0);
            if (n) n[c]({
              d: k
            });else a.graphic = g.path(k).addClass(a.getClassName(), !0).add(a.group || this.group);
            this.chart.styledMode || a.graphic.attr(this.pointAttribs(a, p)).shadow(e.shadow, null, f);
          } else n && (a.graphic = n.destroy());
        } else r.prototype.drawPoint.call(this, a, c);
      };
      c.prototype.translatePoint = function (a) {
        r.prototype.translatePoint.call(this, a);
        if (a.options.milestone) {
          var c = a.shapeArgs;
          var e = c.height || 0;
          a.shapeArgs = {
            x: (c.x || 0) - e / 2,
            y: c.y,
            width: e,
            height: e
          };
        }
      };
      c.defaultOptions = m(r.defaultOptions, {
        grouping: !1,
        dataLabels: {
          enabled: !0
        },
        tooltip: {
          headerFormat: '<span style="font-size: 10px">{series.name}</span><br/>',
          pointFormat: null,
          pointFormatter: function () {
            var a = this.series,
              c = a.xAxis,
              g = a.tooltipOptions.dateTimeLabelFormats,
              k = c.options.startOfWeek,
              m = a.tooltipOptions,
              p = m.xDateFormat,
              n = this.options.milestone,
              r = "<b>" + (this.name || this.yCategory) + "</b>";
            if (m.pointFormat) return this.tooltipFormatter(m.pointFormat);
            !p && q(this.start) && (p = a.chart.time.getDateFormat(c.closestPointRange, this.start, k, g || {}));
            c = a.chart.time.dateFormat(p, this.start);
            a = a.chart.time.dateFormat(p, this.end);
            r += "<br/>";
            return n ? r + (c + "<br/>") : r + ("Start: " + c + "<br/>End: ") + (a + "<br/>");
          }
        },
        connectors: {
          type: "simpleConnect",
          animation: {
            reversed: !0
          },
          startMarker: {
            enabled: !0,
            symbol: "arrow-filled",
            radius: 4,
            fill: "#fa0",
            align: "left"
          },
          endMarker: {
            enabled: !1,
            align: "right"
          }
        }
      });
      return c;
    }(r);
    a(c.prototype, {
      pointArrayMap: ["start", "end", "y"],
      pointClass: y,
      setData: t.prototype.setData
    });
    A.registerSeriesType("gantt", c);
    "";
    return c;
  });
  I(g, "Core/Chart/GanttChart.js", [g["Core/Chart/Chart.js"], g["Core/Defaults.js"], g["Core/Utilities.js"]], function (c, g, y) {
    var A = this && this.__extends || function () {
        var c = function (g, a) {
          c = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var g in c) c.hasOwnProperty(g) && (a[g] = c[g]);
          };
          return c(g, a);
        };
        return function (g, a) {
          function q() {
            this.constructor = g;
          }
          c(g, a);
          g.prototype = null === a ? Object.create(a) : (q.prototype = a.prototype, new q());
        };
      }(),
      B = g.getOptions,
      z = y.isArray,
      C = y.merge,
      x = y.splat;
    c = function (c) {
      function g() {
        return null !== c && c.apply(this, arguments) || this;
      }
      A(g, c);
      g.prototype.init = function (a, g) {
        var m = B(),
          p = a.xAxis,
          k = a.yAxis,
          e;
        a.xAxis = a.yAxis = void 0;
        var q = C(!0, {
          chart: {
            type: "gantt"
          },
          title: {
            text: null
          },
          legend: {
            enabled: !1
          },
          navigator: {
            series: {
              type: "gantt"
            },
            yAxis: {
              type: "category"
            }
          }
        }, a, {
          isGantt: !0
        });
        a.xAxis = p;
        a.yAxis = k;
        q.xAxis = (z(a.xAxis) ? a.xAxis : [a.xAxis || {}, {}]).map(function (a, c) {
          1 === c && (e = 0);
          return C(m.xAxis, {
            grid: {
              enabled: !0
            },
            opposite: !0,
            linkedTo: e
          }, a, {
            type: "datetime"
          });
        });
        q.yAxis = x(a.yAxis || {}).map(function (a) {
          return C(m.yAxis, {
            grid: {
              enabled: !0
            },
            staticScale: 50,
            reversed: !0,
            type: a.categories ? a.type : "treegrid"
          }, a);
        });
        c.prototype.init.call(this, q, g);
      };
      return g;
    }(c);
    (function (c) {
      c.ganttChart = function (g, a, q) {
        return new c(g, a, q);
      };
    })(c || (c = {}));
    return c;
  });
  I(g, "masters/modules/gantt.src.js", [g["Core/Globals.js"], g["Stock/Navigator/Navigator.js"], g["Stock/Scrollbar/Scrollbar.js"], g["Stock/RangeSelector/RangeSelector.js"], g["Series/XRange/XRangeSeries.js"], g["Core/Chart/GanttChart.js"]], function (c, g, y, A, B, z) {
    c.GanttChart = z;
    c.ganttChart = z.ganttChart;
    c.Navigator = g;
    c.RangeSelector = A;
    c.Scrollbar = y;
    g.compose(c.Axis, c.Chart, c.Series);
    A.compose(c.Axis, c.Chart);
    y.compose(c.Axis);
    B.compose(c.Axis);
  });
  I(g, "masters/highcharts-gantt.src.js", [g["masters/highcharts.src.js"]], function (c) {
    c.product = "Highcharts Gantt";
    return c;
  });
  g["masters/highcharts-gantt.src.js"]._modules = g;
  return g["masters/highcharts-gantt.src.js"];
});
