import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../../services/doctor.service';
import moment from 'moment';
import jsPDF from 'jspdf'
import * as XLSX from 'xlsx';
import 'jspdf-autotable'
@Component({
  selector: 'app-vaccine-projections',
  templateUrl: './vaccine-projections.component.html',
  styleUrls: ['./vaccine-projections.component.scss']
})
export class VaccineProjectionsComponent implements OnInit {
  fileName:any;

  monthArray: any = [{ name: 'Next 30 Days'},{ name: 'Next 60 Days'}]
  futureReport:any=[]
  futureReportList:any[] =[] 
  showList:boolean=false
  monthSelected="Next 30 Days"
  displayedColumnsForVaccDue: string[] = ['vaccName','totalVaccinesForDuration','brandName'];

  constructor(private doctorService:DoctorService) { }

  ngOnInit(): void {
    this.getInventoryPrediction();

    var followupDateNew = new Date(new Date().setDate(new Date().getDate() + 30));
    var todayDate= new Date();
    console.log("Date",todayDate)

console.log("dateAug9",followupDateNew)

  }
  onDropdownSelectMonth(event:any){
    console.log("evnt",event)
    this.monthSelected=event
    this.getInventoryPrediction();
  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  getInventoryPrediction(): void{
    var allVaccine:any=[]
    var allSortList:any=[]
    var fromDate=null
    var toDate=null
    if(this.monthSelected=="Next 60 Days"){
      var todayDate= new Date();
      fromDate= this.reverseDate(todayDate);
      fromDate = moment(new Date(this.parseDate(fromDate))).format("YYYY-MM-DD");
      var followupDateNew = new Date(new Date().setDate(new Date().getDate() + 60));
      toDate= this.reverseDate(followupDateNew);
      toDate = moment(new Date(this.parseDate(toDate))).format("YYYY-MM-DD");

    }
    else{
      var todayDate= new Date();
      fromDate= this.reverseDate(todayDate);
      fromDate = moment(new Date(this.parseDate(fromDate))).format("YYYY-MM-DD");
      var followupDateNew = new Date(new Date().setDate(new Date().getDate() + 30));
      toDate= this.reverseDate(followupDateNew);
      toDate = moment(new Date(this.parseDate(toDate))).format("YYYY-MM-DD");

 
    }
   let postData={
    fromDate:fromDate,
    toDate:toDate
   }
   this.doctorService.getInventoryPrediction(postData).subscribe(response=>{

    if(response){
      console.log("Aug8",response)
      this.futureReport=response.report.futureReport
      console.log("Aug8", this.futureReport)
       this.futureReport.map((ins:any)=>{
        
        if(ins.brandsForVaccines){

       
        ins.brandsForVaccines.map((ele:any)=>{
          if(ele.quantity < ins.totalVaccinesForDuration){
            ins['color']='red'
            allSortList.push(ins)
            this.showList=true
          }
          else{
            ins['color']=''
            allVaccine.push(ins)
            this.showList=false
          }
        })
      }
      else{
        ins['color']=''
        allVaccine.push(ins)
        this.showList=false
      }

       })
       allVaccine = allVaccine.reduce((unique:any, o:any) => {
        if (!unique.some((obj:any) => obj.MasterVaccineId== o.MasterVaccineId
          )) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.futureReportList=allSortList.concat(allVaccine)
       console.log("testAug14",allVaccine)
       console.log("Aug14",this.futureReportList)

    }
   })

  }



  DownloadExcel(filename:any, elementId:any) {
    this.fileName = filename;
    this.exportexcel(elementId);
  }
  exportexcel(elementId:any): void {
    /* table id is passed over here */
    let element = document.getElementById(elementId);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }



  openPDF() {
    if (this.futureReportList.length < 1) {
     // this.toastr.warning("No data found");
    } else {
      var arr = [];
   
      for (let index = 0; index < this.futureReportList.length; index++) {
        const element = this.futureReportList[index];
       
        var eachArr = []
        var  ele:any=[]
        if(element.brandsForVaccines){

       
      for(let i=0;i<element.brandsForVaccines.length;i++){
       
       var ele1=element.brandsForVaccines[i]
       var item=ele1.brandName+" "+"("+ele1.quantity+")";
       ele.push(item)

      
      }
        eachArr.push(element.name);
        eachArr.push(element.totalVaccinesForDuration);
        eachArr.push(ele.join(','));
        arr.push(eachArr);
    
    }
    }
      var doc:any = new jsPDF();
      doc.autoTable({
        head: [['Vaccine','	Required','Brand Name(In Stock)']],
        body: arr,
        theme: 'grid',
        columnStyles: {
          2: {cellWidth: 70},                           
      }   
      })
      doc.output('dataurlnewwindow');
    }
  }

}
