import { Component, OnInit, ViewChild, EventEmitter, Output, TemplateRef, Input, ElementRef, SimpleChanges } from '@angular/core';
import { openClose } from '../../../animations'
import $ from 'jquery';
import { AuthService } from '../../../services/auth.service'
import { PatientService } from '../../../services/patient.service'
import { refreshService } from '../../../services/refersh.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageService } from '../../../services/storage.service'
import { NgbModal, ModalDismissReasons, NgbDatepicker, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { debounce, cloneDeep } from 'lodash'
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { Constants } from "../../../../config/constants";
import moment from 'moment';
import { UtilService } from '../../../util/utilService'
// import { AppointmentsComponent } from '../../appointments/appointments.component';
import { patientServiceReload } from './patient.service'
import { GlobalService } from "../../../services/global.service";
import { ToastrService } from "ngx-toastr";
import { treatmentComponent } from '../../patient/treatment/treatment.component';
import { DoctorService } from '../../../services/doctor.service';
import { MatDialog } from '@angular/material/dialog';

let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }];
let createPerscription = [{ name: "Blank", selected: true, value: "" }, { name: "Copy", selected: false, value: "" }]
let selectSlotEven = [{ name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }, { name: "10.00 am", selected: false, value: "" }, { name: "10.30 am", selected: false, value: "" }, { name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }, { name: "10.00 am", selected: false, value: "" }, { name: "10.30 am", selected: false, value: "" }, { name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }]
let selectSlot = [{ name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }, { name: "10.00 am", selected: false, value: "" }, { name: "10.30 am", selected: false, value: "" }, { name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }, { name: "10.00 am", selected: false, value: "" }, { name: "10.30 am", selected: false, value: "" }, { name: "9.00 am", selected: false, value: "" }, { name: "9.15 am", selected: false, value: "" }, { name: "9.30 am", selected: false, value: "" }]
@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  animations: [openClose],
  providers: [MessageService],
})
export class patientComponent implements OnInit {
  @ViewChild('addAppointmentModal') addAppointmentModal!: ElementRef;

  @ViewChild("addQueueSuccessModal") addQueueSuccessModal!: ElementRef;
  @ViewChild("addQueueModal") addQueueModal!: ElementRef;
  @ViewChild("share_Link_App") share_Link_App!: ElementRef;
  @ViewChild("patient_Link") patient_Link!: ElementRef;


  @ViewChild("showPrescription_modal") showPrescription_modal!: TemplateRef<any>;
  @ViewChild("addSelectSlotModal") addSelectSlotModal!: TemplateRef<any>;
  @Input() callBack!: (args: any) => void;

  @Input() searchPatientData:any[] = [];
  @Input() searchPatientDataOrg:any[] = [];

  @Output() messageEvent = new EventEmitter<string>();
  @Output() homePage = new EventEmitter<string>();
  templateName: any = "Custom Prescription";
  selectedTemplate: any = [];
  resendOtpFlag!: boolean
  docUserId:any = null;
  isEdit = false;
  time: any = 30;
  refreshComponent = false;
  isTeleCall:any = null
  patientId:any = null;
  appointment: any;
  appointment_id:any = null;
  User_id:any = null;
  userId:any = null;
  selectedDate: any;
  paitentName: any;
  first_name = '';
  date: any;
  mobileNumber: any
  selectFieldsArray: any = selectFields;
  visitId: any
  prescriptionPreviewArray: any = []
  selectedComplaints: any
  selectedDiagnosis: any
  selectedGeneralInstructions: any
  selectedDietaryAdvices: any
  nextFollowUp: any
  patientMoreInfo: any
  customPerscription = false
  gender = false;
  isPriorityTimeSelected = false
  comment: any;
  selectedSlotStartType: any = ''
  selectedSlotPriorityType: any = ''
  selectedSlotEndType: string = ''
  selectedSlotType: string = '';
  slotTypeArray: any = [];
  selectedSlotEvenType: string = '';
  slotEvenTypeArray: any[] = [];
  unselectedSloatType = false;
  recentlyAuthorizedPatients: any = []
  createPescription: any = [];
  originalCopy: any = []
  userID = ""
  currentFavMedObj: any = {}
  limit = 10
  allMedicines: any = []
  selectedPer: any;
  //moment: any = moment;
  totalPatients: number
  offset: any = 0
  nextOffset = 0
  pageSize: number
  totalPages: number[]
  currentPage: number
  @ViewChild('ViewAllCases') ViewAllCases!:ElementRef;
  cases: any
  patientId_new: any
  closeResult!: string;
  cloneCases: any
  caseIdheader: any;
  doSomething: any;
  appointmentData: any;
  allVisits: any
  otherCases: Boolean = false
  openCaseId: any
  showLoading = false
  searchString: any = ""
  user: any = {};
  patient: any = {};
  errors: any = {};
  bloodGroups:any[] = [];
  stdCode:any= '91';
  patientData: any = []
  // searchPatientDataOrg: any = []
  opdAppointmentTime = 10
  morningAppointmentCount = 0
  eveningAppointmentCount = 0
  priorityAppointmentCount = 0
  morningStarthr: any = [];
  morningEndhr = 0;
  eveningStarthr = 0;
  eveningEndhr = 0;
  favArray: any = []
  favArraySelected: any = []
  selectedFavMed: any = []
  selectedPreviousFavMe: any
  morningEndhrToShow: any = 0;
  morningStarthrToShow: any = 0;
  morningSlots:any[] = []
  isAppointmentEnabled = 0;
  eveningSlots: any[] = []
  priorityTimeSlots:any[] = [];
  selectedPreviousFavMed: any = [];
  minDateForAppointment: any;
  maxDateForAppointment:any;
  isFromEdit = false;
  rxUi:any=""
  appointmentLength = 0;
  deletePatientId: any
  queueFlag: any;
  searchForPatientData: any = []
  // searchPatientData: any = []
  cameFromOnboardingForQueue = false;

  validationOtp = '';
  consentOtp = '';
  selectedPatientData: any = {};
  bookNextAppointmentFlag:boolean = false;
  selectedAppinDate:any;
  profileImagePath = Constants.APICONFIG.patientProfileImagePath;
  constructor(
    private util: UtilService,
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private patientService: PatientService,
    private patientServiceReload: patientServiceReload,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private confirmationService: ConfirmationService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    // private appointmentCompt: AppointmentsComponent,
    private refreshservice: refreshService
  ) {
    this.storageService.removeData()
    this.storageService.removeStage()
    this.userID = this.authService.getUser();
    this.totalPatients = this.offset = 0
    this.pageSize = 100000
    this.currentPage = 1
    this.totalPages = [1]
    this.slotTypeArray = selectSlot;
    this.slotEvenTypeArray = selectSlotEven;
    this.createPescription = createPerscription
    this.date = this.reverseDate(new Date());
   
  }
  
  ngOnInit() {
    const today = new Date();
    // Set maxDate to 3 months ahead
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 3);
   
    this.selectedDate = this.reverseDate(new Date());
    this.minDateForAppointment = this.reverseDate(new Date());
    this.maxDateForAppointment = this.reverseDate(maxDate);

   setTimeout(() => {
    this.route.queryParams?.subscribe(queryParams => {
      this.queueFlag = queryParams.queueFlag
      if(queryParams.patientId || this.queueFlag){
        this.getPatients().then(returnval => {
          this.route.queryParams
            .subscribe((params:any) => {
              var action = params.action;
              var date = params.date
    
              if (params.patientId) {
                var selectePatientforAppt = null;
                this.recentlyAuthorizedPatients.forEach((eachPat:any) => {
                  if (eachPat.PatientId == params.patientId) {
                    selectePatientforAppt = eachPat;
    
                  }
    
                });
    
                if (selectePatientforAppt) {
                  this.openDialog(this.addAppointmentModal, "addAppointmentModal");
                  this.addAppointment(selectePatientforAppt, null);
                }
    
              }
              if (action) {
                var date: any = localStorage.getItem("appDate")
                //this.selectedDate=(date)
                this.selectedDate = this.reverseDate(new Date(date));
                console.log("mydate", this.selectedDate)
              }
              if (params.patientidfornewQueue) {
                // var selectePatientforAppt = null;
                // this.recentlyAuthorizedPatients.forEach(eachPat => {
                //   if (eachPat.PatientId == params.patientidfornewQueue) {
                //     selectePatientforAppt = eachPat;
    
                //   }
    
                // });
    
                for (let i = 0; i < this.recentlyAuthorizedPatients.length; i++) {
                  const element = this.recentlyAuthorizedPatients[i];
                  if (element.PatientId == params.patientidfornewQueue) {
                    selectePatientforAppt = element;
                    break;
                  }
                }
    
                if (selectePatientforAppt) {
                  this.addAppointment(selectePatientforAppt, null);
                  this.cameFromOnboardingForQueue = true;
    
                  let appointment_id:any = null;
                  //show popup if appt alreay exists for this patient
    
                  setTimeout(() => {
                    const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
                    this.router.navigateByUrl(urlWithoutQueryParams)
                      .then(() => {
                        params = null;
                        this.bookAppointmentForQueue()
                      });
                  }, 500);
    
                }
    
              }
            });
        })
      }
      if(queryParams.bookNextAppointment){
        this.bookNextAppointmentFlag = true;
        // this.calendarIcon.nativeElement.click();

      }
      if (queryParams['nextAppointmentDate']) {
        const dateStr = queryParams['nextAppointmentDate']; // Format: dd-mm-yyyy
        const formattedDate = this.convertToNgbDate(dateStr);

        this.selectedDate = formattedDate; // Set to 
      }
    })
   }, 500);
    this.getlocalstoredPatients();

    this.getDoctorDetails();
    this.getFavApptsOfDoctor()
    this.stdCode = "91";
    //this.getVersion();
    this.rxUi= (localStorage.getItem('RxUi'))

    this.patientServiceReload.reload.subscribe(isReload => {
      setTimeout(() => {
        this.getPatients();
      }, 1000);
    });
    date: this.reverseDate(new Date())

    this.refreshservice.PatientRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      //  this.getAppointments();
      this.getPatients();
    });


  }
 // Convert dd-mm-yyyy string to NgbDatepicker format {year, month, day}
 convertToNgbDate(dateStr: string): { year: number; month: number; day: number } {
  const [day, month, year] = dateStr.split('-').map(Number);
  return { year, month, day };
}

  openDialog(content:any, index:any) {

    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }
  selectPrescriptionValue(index:any) {
    let array = this.createPescription;
    array.map((ins:any, ind:any) => {
      if (ind == index) {
        array[index].selected = true;


        this.selectedPer = ins.name;
        if (this.selectedPer == "Blank") {
          this.customPerscription = false
        }
        else {
          this.customPerscription = true
        }
        // this.daysWeeks='Weeks'
      } else {
        ins.selected = false;
        //this.daysWeeks='weeks'
        // this.daysWeeks='Weeks'
      }

    });

    console.log("selectePer", this.createPescription)
  }

  getVersion() {
    this.authService.getVersion().subscribe((response:any) => {
      console.log(response, localStorage.getItem("version"));
      let currentwebapp:any = "";
      if (localStorage.getItem("version")) {
        currentwebapp = localStorage.getItem("version");
      }
      if (response.webapp != currentwebapp) {
        console.log("Need to be refreshed");
        localStorage.setItem("version", response.webapp);
        window.location.reload();
      }
    });
  }

  validateNumber(e:any) {
    return (
      (e.charCode > 47 && e.charCode < 58) ||
      e.charCode == 46 ||
      e.which == 8 ||
      e.which == 13 ||
      e.keyCode == 9
    );
  }
  shareLinkApp() {
    if (this.mobileNumber.length >= 10) {
      const postData = {
        mobileNumber: this.mobileNumber,
      }
      this.patientService.sendSmsOfAppInvite(postData).subscribe((response:any) => {
        this.closeDialog();
        this.messageService.add({ severity: 'custom', detail: 'Successfully shared Health App Link with patient' });


      })
    }
    else {
      this.messageService.add({ severity: 'custom', detail: 'Please enter Valid mobile number' });
      this.openDialog(this.share_Link_App, 0);

    }



  }


  getFavApptsOfDoctor = () => {
    // this.showRequisitionLoading = true;
    this.patientService.getFavApptsOfDoctor().subscribe((response:any) => {
      if (response) {
        // this.currentFavMedObj=response;
        this.favArray = [];
        this.selectedFavMed = [];

        //let myid=this.currentFavMedObj.id;
        // this.currentFavMedObj=response;
        // console.log("favappt1", this.currentFavMedObj);
        console.log("favappt", response)
        this.favArray = response

        // this.showRequisitionLoading = false;

        console.log("favArrar", this.favArray);
        if (response.visit) {
          this.selectedComplaints = response.visit.chief_complaints ? response.visit.chief_complaints : '';
          console.log("complaints", this.selectedComplaints)
        }

      }
    })
  }
  onDropdownSelectFavMed = (item:any, key:any) => {
    var data = []
    console.log("favvv", item)
    item.map((ins:any) => {
      if (key.favApptName == ins.favApptName) {
        this.visitId = ins.VisitId
        console.log("visitId", this.visitId)

        this.selectedComplaints = ins.Visit.chief_complaints ? ins.Visit.chief_complaints : '';
        this.selectedDiagnosis = ins.Visit.complaint_diagnosis ? ins.Visit.complaint_diagnosis : '';
        this.selectedGeneralInstructions = ins.Visit.general_instruction ? ins.Visit.general_instruction : '';
        this.selectedDietaryAdvices = ins.Visit.nutrition_diet_advice ? ins.Visit.nutrition_diet_advice : '';
        this.nextFollowUp = ins.Visit.next_vaccination;
        let data = ins.Visit.Medicines;
        console.log("dataa", data)
        data.map((ins:any) => {

          let obj: any = {}
          obj.name = ins.Drug.product_name;
          obj.Drug = ins.Drug;
          obj.dosagePreviewMed = ins.dosage2;
          obj.dosage2 = ins.dosage2;
          obj.duration2 = ins.duration2
          obj.duration3 = ''//this.days;
          obj.duration4 = ins.frequency2;
          obj.finalPreview = ins.dosage2;
          obj.frequency2 = ins.frequency2;
          obj.instructions = ins.instructions;
          // obj.id = ins.id;

          let element = {
            // drugType: this.selectedDrugType,
            // dose: this.selectedDosageUnit,
            // time: timeArr.join(', '),
            whenToTake: ins.duration2,
            // days: this.days,
            medicineName: ins.Drug.product_name,
            // name: ins.Drug.product_name,
            dosage2: ins.dosage2,
            instructions: ins.instructions,
            id: ins.id,
            //  favApptName:ins.favApptName
          }

          // element.dosage2 = element.whenToTake ? element.dosage2.replace('--', `- ${element.whenToTake} -`) : element.dosage2

          // ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
          delete element['id'];
          //here
          this.allMedicines.push(obj);
          this.prescriptionPreviewArray.push(element);
          // this.favArry.push(element);
        });

      }
    })

    console.log("data", this.favArraySelected)
  }


  previewPrescriptionPDF = () => {

    console.log("next", this.nextFollowUp)


    var chief_complaints = this.selectedComplaints
    //  var on_examination = this.selectedOnExamination
    var complaint_diagnosis = this.selectedDiagnosis
    var general_instruction = this.selectedGeneralInstructions;
    var nutrition_diet_advice = this.selectedDietaryAdvices

    let obj: any = {};
    var patient: any = {};
    var Drug: any = {}

    patient.first_name = this.patientData.User.first_name;
    patient.last_name = this.patientData.User.last_name;
    patient.gender = this.patientData.User.gender;
    patient.phone_number = this.patientData.User.phone_number;
    patient.age = '';
    patient.weight = 'N/A';
    patient.height = 'N/A';
    console.log("patiMru", patient)
    obj = {
      "visit_id": null,
      "clinic_id": null,
      "read_only": false,
      "date": '',
      "appointmentId": null,
      "action": "preview",
      "patientId": this.patientData.id,
      "patient": patient,
      "medicines": this.allMedicines, //pending
      "general_instruction": general_instruction,
      "covidQues": "",
      "suggested_investigation": '',
      "chief_complaints": chief_complaints,
      "next_vaccination": this.nextFollowUp,

      "expirationDate": '',
      "on_examination": '',
      "complaint_diagnosis": complaint_diagnosis,
      "savePrescriptionParams": {},
      //  "printingFields":this.printingFields,
      "deletedMedicines": '',//pending
      'newVersion': true,
      "medicinePreview": '',//pending
      "language": '',
      "nutrition_diet_advice": nutrition_diet_advice,
      "templateName": '',
    }
    this.allMedicines.map((ins:any) => {
      ins.dosage2 = ins.dosage2.split(",").join(" - ");

    })
    let savePrescriptionParams = {
      visit: {
        complaint_diagnosis: obj.complaint_diagnosis,
        general_instruction: obj.general_instruction,
        chief_complaints: obj.chief_complaints,
        next_vaccination: obj.next_vaccination,
        suggested_investigation: obj.suggested_investigation,
       covidQues: null as string | null,
        expirationDate: obj.expirationDate,
        nutrition_diet_advice: obj.nutrition_diet_advice,
      },
      patient_id: obj.patientId,
      visit_id: obj.visit_id,
      medicine_list: this.allMedicines,
      deleted_medicines: obj.deletedMedicines,
    }
    obj.savePrescriptionParams = savePrescriptionParams;
    var arr = this.selectFieldsArray.filter((field:any) => { if (field.selected) { return field } });
    arr = arr.map((field:any) => { return field.value });
    obj.printingFields = arr;
    console.log("RETRO>>>>>>", JSON.stringify(obj));




    this.patientService.printPrescriptionVersion2(obj).subscribe((response:any) => {
      if (response) {

        console.log('PRESCRIPTION PDF Version 2', response)
        let url = response.url;
        //window.open(url);
        sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));
        this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { prescrip_url: url, patientId: this.patientData.id } });
      } else {

      }
    });


  }

  getPatients() {
    return new Promise((resolve, reject) => {

      //this.showLoading = true
      let params:any = {
        user_id: this.userID,
        offset: this.offset,
        limit: this.pageSize
      }
      this.patientService.getAuthorizedPatients(params).subscribe((response:any) => {
        // this.showLoading = false
        if (response.success) {
          if (response.next_offset != 0) {

            this.recentlyAuthorizedPatients = response.patients;
            console.log("patientJan28", this.recentlyAuthorizedPatients)

            var d1 = new Date().toISOString().slice(0, 10);

            var arrTest = [];

            this.recentlyAuthorizedPatients.map((ins:any) => {
              if (ins.Patient && ins.Patient.UserId != null && ins.Patient.User && ins.Patient.User.Customer && ins.Patient.User.Customer.spousecontactno !== null) {
                if (ins.Patient.User.Customer.phone_number.includes('91')) {
                  ins.Patient.User.Customer.phone_number = ins.Patient.User.Customer.phone_number.replace('91', '+91');
                  arrTest.push(ins);
                }
                else {
                  arrTest.push(ins);
                }
              }

            })

            var arr: any = [];


            for (let index = 0; index < this.recentlyAuthorizedPatients.length; index++) {
              const element = this.recentlyAuthorizedPatients[index]

              if (element.Patient && element.Patient.UserId != null && element.Patient.User && element.Patient.User.Customer && element.Patient.User.Customer.spousecontactno !== null) {

                if (element.Patient.User.Customer.spousecontactno.includes('+')) {

                  element.Patient.User.Customer.spousecontactno = element.Patient.User.Customer.spousecontactno.replace('+', '')
                  arr.push(element)

                }
                else {
                  arr.push(element)
                }
              }

            }
            var arrTestSecondNo = [];
            arr.map((ins:any) => {
              if (ins.Patient && ins.Patient.UserId != null && ins.Patient.User && ins.Patient.User.Customer && ins.Patient.User.Customer.spousecontactno !== null) {
                if (ins.Patient.User.Customer.spousecontactno.includes('91')) {
                  ins.Patient.User.Customer.spousecontactno = ins.Patient.User.Customer.spousecontactno.replace('91', '+91');
                  ins.Patient.User.Customer.spousecontactno = ins.Patient.User.Customer.spousecontactno.replace(/\s/g, "");
                  arrTestSecondNo.push(ins);
                }
                else {
                  arrTestSecondNo.push(ins);
                }
              }

            })

            this.recentlyAuthorizedPatients = arr
            this.originalCopy = this.recentlyAuthorizedPatients;

            this.recentlyAuthorizedPatients = this.originalCopy

            resolve(null);

          }
          this.nextOffset = response.next_offset

        }
      })

    })
  }



  loadPatients(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;



    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.offset !== 0) {
        this.getPatients();
      }
    }
  }
  searchPatient() {
    if (this.searchString.length > 3) {
      var arr = [];
      for (let index = 0; index < this.recentlyAuthorizedPatients.length; index++) {
        const element = this.recentlyAuthorizedPatients[index];
        if (element.Patient && element.Patient.UserId != null && element.Patient.User && element.Patient.User.Customer && element.Patient.User.Customer.spousecontactno != null) {
          if (element.Patient.User.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.Customer.phone_number.includes(this.searchString) || element.Patient.User.Customer.spousecontactno.includes(this.searchString)) {

            arr.push(element)
          }
        }

      }
      this.recentlyAuthorizedPatients = arr;

    }
    else if (this.searchString.length < 1) {
      this.recentlyAuthorizedPatients = this.originalCopy;
    }

  }
  closeSearch() {
    this.searchString = ''
    this.searchPatientData = this.searchPatientDataOrg;
  }

  setPatient(patient:any, d:any) {
    this.patientId_new = patient.Patient.id
    var req_param = { patient_id: patient.Patient.id, doctor_id: this.userID }
    this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.cases = response.patientCase
        this.cloneCases = cloneDeep(this.cases)
        this.otherCases = false
        this.open(this.ViewAllCases, 'viewAllCasesPopup')

      }
    })

  }
  open(content: any, customClass: any = '') {
    this.modalService.open(content, { backdrop: false, size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): any {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // return 'by clicking on a backdrop dasdasdasdasdad';
    } else {
      // return  `with: ${reason}`;
    }
  }

  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  changePage = (page:any) => {
    this.offset = this.pageSize * (page - 1)
    this.getPatients()
  }
  closeAddAppoinmentModal() {
    this.selectedDate = this.reverseDate(new Date());
    this.selectedSlotStartType = "";
    this.selectedSlotEndType = "";

  }
  
  bookAppointmentForQueue = () => {
    var appointment_id=null
    if(this.appointment){
      this.appointment.map((ins:any)=>{
        if(ins.PatientId==this.patientId){
        appointment_id=ins.id
        }
      })
    }
    
    var arr:any = [];
    for (let index = 0; index < this.priorityTimeSlots.length; index++) {
      const element:any = this.priorityTimeSlots[index];
      if (element.count > 0) {
      }
      else {
        arr.push(element);
      }
    }
    var postData:any={}
    if (arr.length) {
      let startTime = moment(arr[0].start, "hh:mm a");
      startTime = moment(startTime).set({ second: 0, millisecond: 0 });
      let endTime = moment(arr[0].end, "hh:mm a");
      endTime = moment(endTime).set({ second: 0, millisecond: 0 });
   
       postData= {
        doctor_id: this.userID,
        patientId: this.patientId,
        from: moment(new Date()).format("YYYY-MM-DD") + startTime.format('THH:mm:ssZ'),
        appointment_date: moment(new Date()).format("YYYY-MM-DD") + startTime.format('THH:mm:ssZ'),
        to: moment(new Date()).format("YYYY-MM-DD") + endTime.format('THH:mm:ssZ'),
      }
     if (appointment_id!=null){      
  
      postData.appointment_id=appointment_id;}

      this.doctorService.addNewAppointment(postData).subscribe((response:any) => {
        if (response) {
          console.log("Nov444",response)

          this.openDialog(this.addQueueSuccessModal, 0);
          this.refreshservice.appointrefreshed();
          this.getlocalstoredPatients();
          this.cameFromOnboardingForQueue = false;
          this.getAllClinicsTiming();
          this.getAllAppointments();
        }
      });

    }
  }

  // --------************getAllClinicsTiming---------------------

  bookAppointment = () => {
    console.log("message fired");
    
    this.refreshservice.setFlag('vitalsAtAppoinmentFlagvalue')

    var patientId=null
    // if(this.appointment){

   
    //  this.appointment.map(ins=>{
    //   if(ins.PatientId==this.patientId){
    //     patientId=ins.PatientId
    //   }
    //  })
    // }
    if (this.selectedSlotStartType === '') {

      this.messageService.add({ severity: 'custom', detail: 'No slot selected' });
      this.openDialog(this.addAppointmentModal, 'addAppointmentModal')

    }
    // if(patientId){
    //   this.messageService.add({ severity: 'custom', detail: 'already book Appointment' });
    // }

    else {
      let startTime = moment(this.selectedSlotStartType, "hh:mm a");
      startTime = moment(startTime).set({ second: 0, millisecond: 0 });
      let endTime = moment(this.selectedSlotEndType, "hh:mm a");
      endTime = moment(endTime).set({ second: 0, millisecond: 0 });

      var postData: any = {
        doctor_id: this.userID,
        appointment_date: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD"),     //"2020-04-05T19:46:12.491Z",
        from: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD") + startTime.format('THH:mm:ssZ'),//"2020-04-05T19:46:12.898Z",
        to: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD") + endTime.format('THH:mm:ssZ'),//"2020-04-05T19:56:12.898Z",
        clinic_id: null,
        isTeleCall: this.isTeleCall ? "1" : "0",
        patientId: this.patientId,

        comment: this.comment,
      }

      console.log("datadata", postData);
      this.selectedAppinDate = postData.appointment_date
      this.doctorService.addNewAppointment(postData).subscribe((response:any) => {

        //  this.getAllAppointments();
        if (response) {

          console.log("bookap", response );
          console.log("bookappppp", this.appointment);
          if (this.appointment) {

            this.messageService.add({ severity: 'custom', detail: 'Appointment Created' });
            this.refreshComponent = true;
            this.getlocalstoredPatients()
            console.log("newComponent", this.refreshComponent)

          }
          else {

            this.messageService.add({ severity: 'custom', detail: 'Appointment Booked' });

            this.refreshComponent = true;
            this.getlocalstoredPatients()
            console.log("newComponent", this.refreshComponent)

          }
if(!this.bookNextAppointmentFlag){
  setTimeout(() => {
            this.getAllAppointments();
            this.refreshservice.appointrefreshed();
          }, 1000);
}
if(this.bookNextAppointmentFlag){
  this.bookNextAppointment();
 

}      
        }

      })
    }

  } 


  getDoctorDetails = () => {
    let params = {
      user_id: this.userID
    }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      this.opdAppointmentTime = response.doctor && response.doctor.opdAppointmentTime ? response.doctor.opdAppointmentTime : 10;
      this.isAppointmentEnabled = response.doctor && response.doctor.isAppointmentEnabled ? response.doctor.isAppointmentEnabled : 0;
      this.getAllClinicsTiming();
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);

          if (services.template_names) {

            this.templateName = 'Custom Prescription';
            this.selectedTemplate = services.template_names;
            // here
            let temp = localStorage.getItem("doctorsTemplate");

            if (temp) {
              this.templateName = temp
            } else {
              this.templateName = services.template_names[0];
            }
          }
          else {
            this.selectedTemplate.push('default');
          }
        }
        else {
          this.templateName = 'default';
          this.selectedTemplate.push('default');
        }
      }
    })
  }

  getAllAppointments = () => {

    const postData = {
      doctor_id: this.userID,
      searchby_name_number: "",
      searchby_from_date: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD"),
      searchby_to_date: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD"),
      order:[] as string[],
    };
    this.doctorService.getAppointments(postData).subscribe((response:any) => {
      this.appointment = response.appointments
      console.log("March24",this.appointment)
      let morningAppointmentCount = 0
      let eveningAppointmentCount = 0
      var existingPatientCount = 0;
     var latestPatient = null;

      // let  priorityAppointmentCount=0
      if (response) {

        var appointmentList = response.appointments;
        var arr = [];
        let appointmentLength = 0;
        var latestToken = null; // To store the token as the array length for the latest patient with prescriptionPDFURL
        var latestIndex = -1;
        if (appointmentList && appointmentList.length > 0) {
          for (let i = 0; i < appointmentList.length; i++) {
            if (moment(appointmentList[i].visit_date).format('DD') == moment(new Date(this.parseDate(this.selectedDate))).format('DD') && appointmentList[i].Patient.User) {
              var obj = { start: moment(appointmentList[i].from).local().format("hh:mm a"), end: moment(appointmentList[i].to).local().format("hh:mm a"), title: appointmentList[i].Patient.User.first_name + ' ' + appointmentList[i].Patient.User.last_name, summary: appointmentList[i].id,
                isLatest: !!appointmentList[i].prescriptionPDFURL
               };
              
               arr.push(obj);

               if (appointmentList[i].arrived !=='done' && appointmentList[i].prescriptionPDFURL ) {
                latestToken = arr.length; // Set token to current length
                latestIndex = arr.length -1; // Save index for the latest prescription
              }
             
              
            }
          }
          const filteredArr:any = arr.filter((_, index) => !appointmentList[index].prescriptionPDFURL && appointmentList[index].arrived !== 'done');

  // Update token if necessary on the last valid patient
  if (latestToken && latestIndex !== -1 && filteredArr[latestIndex - 1]) {
    filteredArr[latestIndex - 1].token = latestToken;
  }
          this.appointmentLength = filteredArr.length;
         // this.appointmentData=arr
          console.log("Final appointment data:", this.appointmentLength);
        }
     
       

        var priorityTimeSlots: any = this.priorityTimeSlots
        for (let index = 0; index < priorityTimeSlots.length; index++) {
          const element = priorityTimeSlots[index];
          var count = 0;
          for (let j = 0; j < arr.length; j++) {
            const newElement = arr[j];
            if (newElement.start === element.start) {
              count++;
              priorityTimeSlots[index].count = count;
            }
          }
        }
        this.priorityTimeSlots = priorityTimeSlots

        var morningSlots:any = this.morningSlots
        for (let index = 0; index < morningSlots.length; index++) {
          const element:any = morningSlots[index];
          var count = 0;
          for (let j = 0; j < arr.length; j++) {
            const newElement = arr[j];
            if (newElement.start === element.start) {
              count++;
              morningSlots[index].count = count;
            }
          }
        }
        var eveningSlots = this.eveningSlots
        for (let index = 0; index < eveningSlots.length; index++) {
          const element = eveningSlots[index];
          var count = 0;
          for (let j = 0; j < arr.length; j++) {
            const newElement = arr[j];
            if (newElement.start === element.start) {
              count++;
              eveningSlots[index].count = count;
            }
          }
        }
        this.eveningSlots = eveningSlots
        this.morningSlots = morningSlots
        // }
        var morningSlots:any = this.morningSlots
        for (let index = 0; index < morningSlots.length; index++) {
          const element = morningSlots[index];
          if (element.count > 0) {
            morningAppointmentCount++;
          }
        }
        var eveningSlots = this.eveningSlots
        for (let index = 0; index < eveningSlots.length; index++) {
          const element = eveningSlots[index];
          if (element.count > 0) {
            eveningAppointmentCount++;
          }
        }
        this.morningAppointmentCount = morningAppointmentCount
        this.eveningAppointmentCount = eveningAppointmentCount

      }
      else {
        this.morningAppointmentCount = morningAppointmentCount
        this.eveningAppointmentCount = eveningAppointmentCount

      }
      console.log("countMoring", this.morningAppointmentCount);
    })

    if (this.cameFromOnboardingForQueue) {
      
    }
  }
  
  getAllClinicsTiming = () => {

    this.morningAppointmentCount = 0
    this.eveningAppointmentCount = 0
    this.priorityAppointmentCount = 0

    this.morningStarthr = 0;
    this.morningEndhr = 0;
    this.eveningStarthr = 0;
    this.eveningEndhr = 0;
    this.morningSlots = []
    this.eveningSlots = []
    this.priorityTimeSlots = []

    const postData = {
      date: moment(this.parseDate(this.selectedDate)).format("YYYY-MM-DD"),
    };
    this.doctorService.getClinicSchedule(postData).subscribe((response:any) => {

      console.log('Clinic Schedule', response)

      var morningStartArr = [];
      var morningEndArr = [];
      var eveningStartArr: any = [];
      var eveningEndArr: any = [];
      if (response.success) {
        for (let index = 0; index < response.clinicSchedule.length; index++) {
          const element = response.clinicSchedule[index];
          var morning_from = moment(element.ClinicSchedules[0].morning_from, "hh:mm a").valueOf();
          morningStartArr.push(morning_from);
          var morning_to = moment(element.ClinicSchedules[0].morning_to, "hh:mm a").valueOf();
          morningEndArr.push(morning_to);
          var evening_from = moment(element.ClinicSchedules[0].evening_from, "hh:mm a").valueOf();
          eveningStartArr.push(evening_from);
          var evening_to = moment(element.ClinicSchedules[0].evening_to, "hh:mm a").valueOf();
          eveningEndArr.push(evening_to);
        }
        const morningStarthr: any = Math.min(...(morningStartArr));
        const morningEndhr: any = Math.max(...(morningEndArr));
        const eveningStarthr: any = Math.min(...(eveningStartArr));
        const eveningEndhr: any = Math.max(...(eveningEndArr));
        var date1 = new Date(morningStarthr).setDate(new Date(this.parseDate(this.selectedDate)).getDate());
        date1 = new Date(date1).setMonth(new Date(this.parseDate(this.selectedDate)).getMonth());
        date1 = new Date(date1).setFullYear(new Date(this.parseDate(this.selectedDate)).getFullYear());
        var date2: any = new Date(morningEndhr).setDate(new Date(this.parseDate(this.selectedDate)).getDate());
        date2 = new Date(date2).setMonth(new Date(this.parseDate(this.selectedDate)).getMonth());
        date2 = new Date(date2).setFullYear(new Date(this.parseDate(this.selectedDate)).getFullYear());
        var date3: any = new Date(eveningStarthr).setDate(new Date(this.parseDate(this.selectedDate)).getDate());
        date3 = new Date(date3).setMonth(new Date(this.parseDate(this.selectedDate)).getMonth());
        date3 = new Date(date3).setFullYear(new Date(this.parseDate(this.selectedDate)).getFullYear());
        var date4: any = new Date(eveningEndhr).setDate(new Date(this.parseDate(this.selectedDate)).getDate());
        date4 = new Date(date4).setMonth(new Date(this.parseDate(this.selectedDate)).getMonth());
        date4 = new Date(date4).setFullYear(new Date(this.parseDate(this.selectedDate)).getFullYear());
        this.morningStarthr = date1
        this.morningEndhr = date2
        this.eveningStarthr = date3
        this.eveningEndhr = date4
        this.fillEmptyMorningSlots();       

      }

    })

  }
  


  fillEmptyMorningSlots = () => {
    var offset = this.opdAppointmentTime;
    var arr: any = [];
    var morningAppointmentCount = this.morningAppointmentCount;
    for (let index = new Date(this.morningStarthr).getHours(); index <= new Date(this.morningEndhr).getHours(); index++) {
      var startmin = 0;
      var endmin: any = offset;

      for (let j = 0; j < 60 / offset; j++) {

        var startTime: any = moment(this.parseDate(this.selectedDate)).format("YYYY-MM-DD");
        startTime = moment(startTime).set({ hour: index, minute: startmin, second: 0, millisecond: 0 });
        if (index === this.morningStarthr && startmin === 0) {

          this.morningStarthrToShow = moment(startTime).local().format("hh:mm a")

        }
        startmin = startmin + offset;
        var endTime: any = moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD");
        endTime = moment(startTime).set({ hour: index, minute: endmin, second: 0, millisecond: 0 });
        if (j === 60 / offset) {
          endTime = moment(startTime).set({ hour: index + 1, minute: 0, second: 0, millisecond: 0 });
        }
        endmin = endmin + offset;
        var obj = { start: moment(startTime).local().format("hh:mm a"), end: moment(endTime).local().format("hh:mm a"), title: null as string | null, summary: null as string | null, showPlusButton: false }
        if (moment(startTime).isAfter(moment())) {
          obj.showPlusButton = true;



          var date2 = this.morningStarthr;
          date2 = new Date(date2).setDate(new Date(startTime).getDate());
          var date3: any = this.morningEndhr;
          date3 = new Date(date3).setDate(new Date(endTime).getDate());
          if (new Date(startTime).getTime() >= date2 && new Date(endTime).getTime() <= date3) {
            arr.push(obj);
          }
        }
      }
      if (index === this.morningEndhr) {

        this.morningEndhrToShow = moment(endTime).local().format("hh:mm a");

      }
    }
    this.morningSlots = arr
    this.fillEmptyEveningSlots();

  }



  fillEmptyEveningSlots = () => {
    var offset = this.opdAppointmentTime;
    var eveningAppointmentCount = this.eveningAppointmentCount;
    var arr = [];
    for (let index = new Date(this.eveningStarthr).getHours(); index <= new Date(this.eveningEndhr).getHours(); index++) {
      var startmin = 0;
      var endmin: any = offset;

      for (let j = 0; j < 60 / offset; j++) {
        var startTime: any = moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD");
        startTime = moment(startTime).set({ hour: index, minute: startmin, second: 0, millisecond: 0 });
        startmin = startmin + offset;
        var endTime: any = moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD");
        endTime = moment(startTime).set({ hour: index, minute: endmin, second: 0, millisecond: 0 });
        if (j === 60 / offset) {
          endTime = moment(startTime).set({ hour: index + 1, minute: 0, second: 0, millisecond: 0 });
        }
        endmin = endmin + offset;
        var obj = { start: moment(startTime).local().format("hh:mm a"), end: moment(endTime).local().format("hh:mm a"), title: null as string | null, summary: null as string | null, showPlusButton: false }
        if (moment(startTime).isAfter(moment())) {
          obj.showPlusButton = true;

          var date2: any = this.eveningStarthr;
          date2 = new Date(date2).setDate(new Date(startTime).getDate());
          var date3: any = this.eveningEndhr;
          date3 = new Date(date3).setDate(new Date(endTime).getDate());
          if (new Date(startTime).getTime() >= date2 && new Date(endTime).getTime() <= date3) {
            arr.push(obj);
          }
        }
      }
    }
    this.eveningSlots = arr;
    this.fillEmptyPrioritySlots();
    this.getAllAppointments();


  }

  checkcode() {
    this.fillEmptyPrioritySlots();
  }

  fillEmptyPrioritySlots = () => {
    var offset = this.opdAppointmentTime;
    var arr = [];
    var priorityAppointmentCount = this.priorityAppointmentCount;

    for (let index = 7; index < 22; index++) {
      var startmin = 0;
      var endmin = offset;

      for (let j = 0; j < 60 / offset; j++) {
        var startTime: any = moment(new Date((this.parseDate(this.selectedDate)))).format("YYYY-MM-DD");
        startTime = moment(startTime).set({ hour: index, minute: startmin, second: 0, millisecond: 0 });
        startmin = startmin + offset;
        var endTime: any = moment(new Date((this.parseDate(this.selectedDate)))).format("YYYY-MM-DD");
        endTime = moment(startTime).set({ hour: index, minute: endmin, second: 0, millisecond: 0 });
        if (j === 60 / offset) {
          endTime = moment(startTime).set({ hour: index + 1, minute: 0, second: 0, millisecond: 0 });
        }
        endmin = endmin + offset;
        var obj = { start: moment(startTime).local().format("hh:mm a"), end: moment(endTime).local().format("hh:mm a"), title: null as string | null, summary: null as string | null, showPlusButton: false }
        if (moment(startTime).isAfter(moment())) {
          obj.showPlusButton = true
          arr.push(obj);
        }

      }
    }
    this.priorityTimeSlots = arr;
    console.log("prioritytime", this.priorityTimeSlots);
  }

  switchSlotEvenType(index:any) {

    let array = this.eveningSlots
    array.map((ins, ind) => {
      if (ind == index) {
        array[index].selected = true;
        this.selectedSlotStartType = ins.start;
        this.selectedSlotEndType = ins.end;

      } else {
        ins.selected = false;
      }

    });

  }


  switchSlotType(index:any) {

    let array:any = this.morningSlots
    console.log("vitalsVisitId array ", array);
    
    array.map((ins:any, ind:any) => {
      if (ind == index) {
        array[index].selected = true;
        this.selectedSlotStartType = ins.start;
        this.selectedSlotEndType = ins.end;
        console.log("----", ins.name);
        // this.modalService.dismiss();
      } else {
        ins.selected = false;
      }

    });

  }
  switchSlotPriorityType(index:any) {
    let array = this.priorityTimeSlots
    array.map((ins, ind) => {
      if (ind == index) {
        array[index].selected = true;
        this.isPriorityTimeSelected = ins.start;
        this.selectedSlotStartType = ins.start;
        this.selectedSlotEndType = ins.end;
        console.log("----", ins.name);
        // this.modalService.dismiss();
      } else {
        ins.selected = false;
      }

    });
  }

  clearAllSelection = () => {
    this.comment = "";
    this.isTeleCall = "";
    this.selectedSlotStartType = "";
    this.selectedSlotEndType = "";

    this.eveningSlots.map(ins => ins.selected = false);
    this.morningSlots.map(ins => ins.selected = false);
    this.slotEvenTypeArray.map(ins => ins.selected = false);


  }
  addSelectSlotModalPress = () => {
    this.closeDialog();
    this.openDialog(this.addAppointmentModal, "addAppointmentModal");
  }


  openPrescriptions(patientData:any) {

    if (patientData.hasOwnProperty('isLinked') && !patientData.isLinked) {
      this.selectedPatientData = patientData;
      this.sendOtpToPatient(patientData.Patient.User.phone_number);
      this.consentOtp = "";
      this.openDialog(this.patient_Link, 0);
    } else {

      if (this.selectedPer == "Copy") {

        this.previewPrescriptionPDF()
      }
      else {
        this.savetoLocalListOfPatients(patientData);
        if(this.rxUi=='NewRxUI'){
          localStorage.setItem('patientData', JSON.stringify(patientData.Patient));
          this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId: patientData.Patient.id } });
        }
        else{
          var appointment_id:any =null
          var appoinmentFlag:any
          if(this.appointment){
            this.appointment.map((ins:any)=>{
              if(ins.PatientId ==patientData.Patient.id){
               appointment_id=ins.id; //patient already in queue
             // appointment_id=false
              }
              
            })

          }

         
          console.log("this.appointment",appoinmentFlag)
          localStorage.setItem('patientData', JSON.stringify(patientData.Patient));
          sessionStorage.removeItem('appointmentCreated');
          this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: patientData.Patient.id,appointment_id:appointment_id } });
        }
       
      }
    }
  }
  bookNextAppointment(){
    this.closeDialog();
    
    var appointment_id:any =null
    var appoinmentFlag:any
    if(this.appointment){
      this.appointment.map((ins:any)=>{
        if(ins.PatientId == this.patientId){
         appointment_id=ins.id; //patient already in queue
       // appointment_id=false
        }
        
      })

    }
    console.log("selectedSlot",this.selectedSlotStartType, this.selectedSlotEndType);
   
    console.log("this.selectedAppinDate",this.selectedAppinDate)
    const formattedDate = moment(this.selectedAppinDate).format("DD-MM-YYYY");
console.log("Formatted Date:", formattedDate);
    this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientId,appointment_id:appointment_id , selectedNextAppinDate:formattedDate, slotStart:this.selectedSlotStartType} });

  }

  clickModal($event:any) {

    $event.stopPropagation();
    $event.preventDefault();
    //this.router.navigate([`/doctor/homepage`])

  }

  
  QueueAddingPopupMessage = "";
  checkifPatientAlreadyinQueue(patient:any){
    var appointment_id = null;
    if(this.appointment){
      this.appointment.map((ins:any)=>{
        if(ins.PatientId == patient.Patient.id){
          appointment_id=ins.id; //patient already in queue
        }
      })
    }
    
    if(appointment_id){
      //in the q already
      this.QueueAddingPopupMessage = "Patient <b>" + patient.Patient.User.first_name + " " + patient.Patient.User.last_name + "</b> is already in Queue. Do you want to move Patient to end of Queue?"
    } else {
      //not in queue
      this.QueueAddingPopupMessage = "You are joining <b>" + patient.Patient.User.first_name + " " + patient.Patient.User.last_name + "</b> to the Queue" ;
    }

    console.log(this.QueueAddingPopupMessage);
  }

  addAppointment = (patientData:any, $event:any) => {
    this.getAllAppointments();

    if (patientData.hasOwnProperty('isLinked') && !patientData.isLinked) {

      this.selectedPatientData = patientData;
      this.sendOtpToPatient(patientData.Patient.User.phone_number);
      this.consentOtp = "";
      this.openDialog(this.patient_Link, 0);
    } else {
      this.savetoLocalListOfPatients(patientData)
      var middle = patientData.Patient.User.middle_name ? patientData.Patient.User.middle_name : ''

      this.paitentName = patientData.Patient.User.first_name + " " + middle + " " + patientData.Patient.User.last_name
      this.patientId = patientData.Patient.id;
      this.userId = patientData.Patient.User.id;

      if (this.isAppointmentEnabled == 1) {
        this.openDialog(this.addAppointmentModal, 0);
      }
      if ($event) {
        this.clickModal($event);
      }
      this.clearAllSelection()
    }

  }

  sendOtpToPatient(contactNo: string) {

    const data = { contactNo: contactNo };

    this.patientService.sendOtpToPatient({ data }).subscribe((response:any) => {
      if (response) {
        this.time = 30;
        console.log("Dec23",response)

        this.validationOtp = response.otp;

      }
    })
  }


  validateOTP() {
    if (this.validationOtp == this.consentOtp) {
      this.linkDoctorPatient();
      this.closeDialog();
    } else {
      alert('Please enter a valid OTP.')
    }
  }

  timeout = setInterval(() => {

    // this.time=0;
    if (this.time > 0) {
      this.time -= 1;
    } else {
      clearInterval(0);
    }
  }, 1000);
  linkDoctorPatient() {
    this.patientService.linkDoctorPatient(this.selectedPatientData.Patient.User.id).subscribe((response:any) => {
      console.log('Response --- link doctor', response);

      if (response) {
        this.selectedPatientData.isLinked = true;
        this.savetoLocalListOfPatients(this.selectedPatientData);
        localStorage.setItem('patientData', JSON.stringify(this.selectedPatientData.Patient));
        this.router.navigate([`/doctor/patients/profileSummary/view`], { state: { data: this.selectedPatientData.Patient } });
      }
    })
  }
  profileSummaryModal(patient:any) {
    this.selectedPatientData = patient;
  }
  resendOtp() {
    //var patientData=this.selectedPatientData
    this.sendOtpToPatient(this.selectedPatientData.Patient.User.phone_number);

  }

  openProfileSummary(patientData:any) {
    console.log("patientData5Jan", patientData);
    if (patientData.hasOwnProperty('isLinked') && !patientData.isLinked) {

      this.selectedPatientData = patientData;
      this.sendOtpToPatient(patientData.Patient.User.phone_number);
      this.consentOtp = "";
      this.openDialog(this.patient_Link, 0);
    }
    else {
      var appointment_id:any =null
        
          if(this.appointment){
            this.appointment.map((ins:any)=>{
              if(ins.PatientId ==patientData.Patient.id){
               appointment_id=ins.id; //patient already in queue
             // appointment_id=false
              }
              
            })

          }

         
      this.savetoLocalListOfPatients(patientData);
      localStorage.setItem('patientData', JSON.stringify(patientData.Patient));
  this.router.navigate(
  ['/doctor/patients/profileSummary/view'],
  {
    queryParams: { patientId: patientData.Patient.id,appointment_id:appointment_id },
    state: { data: patientData.Patient }
  }
);  
      console.log("patientData.Patient.id",patientData.Patient.id);
      console.log("patientData.Patient",patientData.Patient);
      
    }
  }

  getlocalstoredPatients() {


    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    // Retrieve the data from localStorage
let storedData = localStorage.getItem("patientAllData");

// Check if storedData is not null before parsing
let data = storedData ? JSON.parse(storedData) : null;

// Now you can safely use 'data'
if (data) {
    console.log("Patient Data:", data);
} else {
    console.log("No patient data found.");
}

    let dataOrg = data
    console.log("newDataForPatient", dataOrg)

    let localacessedpatients: any = [];
    var test: any = []
    var arr: any = []
    if (localacessedpatientsstr) {
      localacessedpatients = JSON.parse(localacessedpatientsstr);
      console.log("dataLOad", localacessedpatients)

      let finalunique:any = [];
      if (localacessedpatients && localacessedpatients.length > 0) {

        localacessedpatients.map((o:any) => {
          let alreadyadded = false;
          for (let j = 0; j < finalunique.length; j++) {
            if (finalunique[j].Patient.User.id == o.Patient.User.id) {
              alreadyadded = true;
            }
          }

          if (!alreadyadded) {
            finalunique.push(o);
          }

        })

      }


      this.searchPatientData = finalunique;
      this.searchPatientDataOrg = finalunique;


    }


  }

  savetoLocalListOfPatients(patientData:any) {

    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    let localacessedpatients = localacessedpatientsstr ? JSON.parse(localacessedpatientsstr) : [];
  
    console.log("Existing patients in local storage before adding:", localacessedpatients);
  
    // Check if the patient already exists in the list
    let existingIndex = localacessedpatients.findIndex(
      (patient:any) => patient.Patient.User.id === patientData.Patient.User.id
    );
  
    if (existingIndex !== -1) {
      console.log("Matching patient found at index:", existingIndex);
      
      // Log mismatched fields for debugging
      let existingPatient = localacessedpatients[existingIndex];
      if (
        existingPatient.Patient.User.first_name !== patientData.Patient.User.first_name ||
        existingPatient.Patient.User.last_name !== patientData.Patient.User.last_name
      ) {
        console.log("Mismatch detected for patient ID:", patientData.Patient.User.id);
        console.log("Existing First Name:", existingPatient.Patient.User.first_name, "New First Name:", patientData.Patient.User.first_name);
        console.log("Existing Last Name:", existingPatient.Patient.User.last_name, "New Last Name:", patientData.Patient.User.last_name);
      }
      
      // Remove the existing patient
      localacessedpatients.splice(existingIndex, 1);
    }
  
    // Add the latest patient to the top of the list
    localacessedpatients.unshift(patientData);
  
    // Keep the list length within 50 entries
    if (localacessedpatients.length > 50) {
      localacessedpatients = localacessedpatients.slice(0, 50);
    }
  
    // Save updated data back to localStorage
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
    console.log("Updated patients saved to localStorage:", localacessedpatients);
  }
  
  

  openSearchPatient(patientData:any) {
    localStorage.setItem('patientData', JSON.stringify(patientData));
    // this.appointmentCompt.onClickPatientName("", {});
  }
  createNewCase(d:any) {
    localStorage.removeItem('viewCaseId')
    localStorage.removeItem('viewVisitId')
    this.confirmationService.confirm({
      message: 'Creating a new case for your existing patient will automatically ‘Close’ the previously open case (if any). Please click ‘Proceed’ to create new case.',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Proceed',
      rejectLabel: 'Cancel',
      accept: () => {
        let req_vars = { user_id: this.userID, patient_id: this.patientId_new }
        this.patientService.createCase(req_vars).subscribe((response:any) => {
          if (response.success == true) {
            localStorage.setItem('newCaseId', response.newCase.id)
            localStorage.setItem('newVisitId', response.newVisit.id)
            this.caseIdheader = response.newCase.id
            localStorage.setItem('isFromLanding', 'yes')
            localStorage.setItem('patientId', this.patientId_new)
            this.router.navigate([`/doctor/patients/vitals/edit`])
            d('close modal')
          }
        })
      },
      reject: () => {

      }
    })

  }

  createNewVisit(case_id:any, d:any) {
    localStorage.removeItem('viewCaseId')
    localStorage.removeItem('viewVisitId')
    let req_vars = { caseId: case_id, patient_id: this.patientId_new, doctor_id: this.userID }
    this.patientService.createVisit(req_vars).subscribe((response:any) => {
      if (response.success == true) {
        localStorage.setItem('patientId', this.patientId_new)
        this.router.navigate([`/doctor/patients/vitals/edit`])
        localStorage.setItem('newCaseId', response.patientCase.id)
        localStorage.setItem('newVisitId', response.visit.id)
        localStorage.setItem('isFromLanding', 'yes')
        this.caseIdheader = response.patientCase.id
        if (d) {
          d('close modal')
        }
      }
    })
  }

  viewVisitDetails(case_id:any, visit_id:any, d:any) {
    localStorage.removeItem('newCaseId')
    localStorage.removeItem('newVisitId')
    localStorage.setItem('viewCaseId', case_id)
    localStorage.setItem('viewVisitId', visit_id)
    if (d) {
      d('close modal')
    }
    this.getVisits(case_id, this.patientId_new)
    this.caseIdheader = case_id
    localStorage.setItem('isFromLanding', 'yes')

    localStorage.setItem('patientId', this.patientId_new)
    this.router.navigate([`/doctor/patients/vitals/view`])
  }

  getVisits(case_id:any, patient_Id:any) {
    let req_params = { caseId: case_id, patient_id: patient_Id }
    this.patientService.getVisits(req_params).subscribe((response:any) => {
      if (response.success == true) {
        this.allVisits = response.visits
      }
    })
  }

  showOtherCases(otherCases:any) {
    if (otherCases) {
      var req_param = { patient_id: this.patientId_new }
      this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
        if (response.success == true) {
          this.cases = response.patientCase
        }
      })
    } else {
      this.cases = this.cloneCases
    }
  }

  //function to toggle open case id
  toggleIsOpen(caseId:any) {
    this.openCaseId = (this.openCaseId == caseId ? "0" : caseId)
  }
  getConstants() {
    this.globalService.getConstants().subscribe((response:any) => {
      this.bloodGroups = response.blood_groups;
    });
  }

  getAge() {
    this.user["age"] = {};
    const { day, month, year } = this.user["dob"];
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    // 0
    bdate.setFullYear(year);
    let age = this.calculateAge(new Date(), bdate);
    this.user["age"]["years"] = age[0];
    this.user["age"]["months"] = age[1];
    this.user["age"]["weeks"] = Math.floor(age[2] / 7);
  }
  closeCalender(e:any, d:any) {
    if (e.target.offsetParent == null) d.close();
    else if (
      e.target.offsetParent &&
      e.target.offsetParent.nodeName != "NGB-DATEPICKER" &&
      e.target.offsetParent.className.indexOf("calender") == -1 &&
      e.target.offsetParent.className.indexOf("custom-select") == -1
    )
      d.close();
  }

  updatePatient(c:any) {
    this.errors = {};
    if (!this.user["first_name"]) {
      this.errors["first_name"] = "Please enter patient's first name";
    } else if (!this.user["middle_name"]) {
      this.errors["middle_name"] = "Please enter patient's last name";
    } else if (!this.user["last_name"]) {
      this.errors["last_name"] = "Please enter patient's last name";
    } else if (!this.user["dob"]) {
      this.errors["dob"] = "Please select patient's date of birth";
    } else if (!this.user["gender"]) {
      this.errors["gender"] = "Please select patient's gender";
    }
    // else if(this.user['blood_group'] == null){
    //   this.errors['blood_group'] = "Please select patient's blood group"
    // }
    else if (this.stdCode == null) {
      this.errors["stdCode"] = "Please enter valid country code.";
    } else {
      this.user["first_name"].trim();
      this.user["middle_name"].trim();
      this.user["last_name"].trim();
      // this.user.phone_number = `${this.stdCode}${this.user.phone_number}`;
      this.user.dob = this.parseDate(this.user.dob);
      this.patient.Patient.User = this.user;
      this.showLoading = true;
      this.patientService.updateBasicInfo(this.patient.Patient).subscribe((response:any) => {
        if (response.success) {
          //this.router.navigate['doctor/patients']
          c("close modal");
          this.showLoading = false;
          this.getPatients();
          this.toastr.success(`Record of ${this.user["first_name"]} ${this.user["middle_name"]} ${this.user["last_name"]} has been updated successfully`);
        } else {
          this.toastr.error(response.error);
          this.showLoading = false;

        }
      });
    }
  }

  parseDate(dateObject:any) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  }
  calculateAge(endingDate:any, startingDate:any) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    return [yearDiff, monthDiff, dayDiff];
  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  genderToggle(current:any) {
    if (current == "yes") {
      this.user.gender_type = "non_specified";
      this.user.gender = null;
    } else {
      this.user.gender_type = null;
    }
  }
}
