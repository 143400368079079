<div style="background-color:#fff; display:flex; height:60px; width:100%; margin-bottom: 0px; justify-content: center; align-items: center; position: relative;
  z-index: 0;">

  <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1;">
    <div (click)="backToEdit()" class="btn-type-border">Back To Edit</div>
    <div *ngIf="hideBackButton" (click)="backToEdit()" class="btn-type-border" style="display: none;">Back To Edit</div>
    <div style="display: flex; flex-direction: row;">


      <!-- <div *ngIf="appointmentBook" (click)="bookAppointment()" class="btn-type-filled">Book appointment for followup</div> -->
      <div (click)="openDialog(sharePrescription); openPDF()" class="btn-type-filled" style="margin: 0px 64px 0px 8px;">
        Done</div>
    </div>

  </div>
</div>
<ng-template #previous_medicines_modal let-modal>
<div style="padding: 20px;">
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Add Favourite Prescription</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Set favourite Prescription Name</label>
        <input type="text" class="form-control"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
          [(ngModel)]="favApptName">

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="saveFavPer(); modal.dismiss()" class="btn-type-filled">Save</div>
  </div>
</div>
</ng-template>


<ng-template #confrim_modal let-modal>
<div style="padding: 20px;">
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Add Favourite Prescription</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Set favourite Prescription Name</label>
        <input type="text" class="form-control"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
          [(ngModel)]="favApptName">

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="saveFavPer(); modal.dismiss()" class="btn-type-filled">Save</div>
  </div>
</div>
</ng-template>
<div style="display: flex;  height: 100vh; margin-top: 10px;">
  <iframe *ngIf='showPDF' [src]="pdfURL()" frameborder="0" width="100%" height="107%"></iframe>
</div>

<ng-template #sharePrescription let-modal>
<div style="padding: 20px;">
  <div style="font-size:20px;font-weight: 600;">Share Prescription</div>

  <div *ngIf="!hidePrescriptionFlag" style="color: gray; font-size: 16px; width: 30vw;"> We successfully shared the prescription
    with the Patient via SMS.if you want to share it any further use the following.</div>
    
    <div *ngIf="hidePrescriptionFlag" style="color: gray; font-size: 16px; width: 30vw;"> Share prescription further with the following options.</div>

  <div style="display: flex;flex-direction: column;">
    <div style="font-size: 16px; font-weight: 600; padding: 5px 0px;"> Share with Pharmacy</div>
    <mat-form-field>
      <mat-label>Choose Pharmacy</mat-label>
      <mat-select (selectionChange)="onDropdownSelectFollowUpTypes('item',$event.value)">
        <mat-option *ngFor="let item of dropDownPharmacy" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf='!selectedPharmacy' style="font-size: 12px; display: flex;flex-direction: row; ">
      <div class="iconStyle" style="font-size: 12px;width: 152px; margin: 8px 9px;cursor: pointer;">
        <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/whatsapp_icon_grey.svg"
          alt="" /><span style="margin-left: 5px;">WhatsApp</span>
      </div>
      <div class="iconStyle" style="font-size: 12px;width: 152px;">
        <img style="height: 25px; margin-left: 0x; width: 20px; cursor: pointer;"
          src="assets/images/gmail_icon_grey.svg" alt="" /><span style="margin-left: 5px;">Email</span>
      </div>
    </div>
    <div *ngIf='selectedPharmacy' style="font-size: 12px; display: flex;flex-direction: row; ">
      <div (click)="sharePrescriptionWithPharmacyViaWhatsApp()" class="iconActiveStyle"
        style="font-size: 12px;width: 152px; margin: 8px 9px; cursor: pointer;">
        <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/whatsapp_icon_color.svg"
          alt="" /><span style="margin-left: 5px;">WhatsApp</span>
      </div>
      <div *ngIf="!emailFlag" (click)="sharePrescriptionWithPharmacyViaEmail()" class="emailIconActiveStyle"
        style="font-size: 12px;width: 152px;">
        <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/gmail_icon_color.svg" alt="" /><span
          style="margin-left: 5px; cursor: pointer;">Email</span>
      </div>
      <div *ngIf="emailFlag" class="emailIconActiveStyle"
        style="font-size: 12px;width: 152px; display: flex; justify-content: space-between;">
        <span style="margin-left: 5px; cursor: pointer; align-self: center;">Email Sent</span>
        <img style="height: 30px; margin-left: 0x; width: 30px;" src="assets/images/tick_maroon.svg" alt="" />
      </div>


    </div>

    <div style="font-size: 16px; font-weight: 600; margin: 9px 4px;"> Share with Other</div>
    <div style="font-size: 12px; display: flex;flex-direction: row;">
      <div (click)="shareWithOther()" class="iconActiveStyle" style="font-size: 12px;width: 152px; cursor: pointer;">
        <img style="height: 25px; margin-left: 0x; width: 20px; align-self: center;"
          src="assets/images/whatsapp_icon_color.svg" alt="" /><span style="margin-left: 5px;">WhatsApp</span>
      </div>
      <!-- <div (click)="shareWithOther()" class="iconActiveStyle" style="font-size: 12px;width: 152px; cursor: pointer;">
        <mat-icon>receipt</mat-icon><span style="margin-left: 5px;">Receipt</span>
      </div> -->
    </div>
    <div style="font-size: 16px; font-weight: 600; margin: 9px 4px;"> Share Receipt</div>

  

    <button (click)="openReceipt();closeDialog();"  style=" background-color: #5D86CC;
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 0px;
    outline: none;
    margin: 4px;border: 1px solid #5D86CC;
  color: #5D86CC;
  background-color: #fff;
  outline: none; margin: 6px 10px 0px 10px;
  display: flex;
 width: 35%;
  align-items: center;" >

      <mat-icon >
       receipt
      </mat-icon>

      <span style="margin-left: 4px;">Receipt</span>
  </button>


    <div *ngIf="hidePrescriptionFlag">
      <div style="font-size: 16px; font-weight: 600; margin: 9px 4px;"> Share with Patient (Health App)</div>
      <div style="font-size: 12px; display: flex;flex-direction: row;">
        <div (click)="shareWithPatientApp()" class="iconActiveStyleBlue"
          style="font-size: 12px; cursor: pointer; border: 1px dashed #4687BF;">
          <img style="height: 30px; margin-left: 0x; width: 30px; align-self: center;" src="assets/images/new_logo.svg"
            alt="" /><span style="margin-left: 5px;">Share prescription with patient in Health App</span>
        </div>
      </div>
    </div>

    <div style="font-size: 12px; display: flex;flex-direction: row;margin-top: 7px;"
      (click)="closeDialog();shareReciept()">
    </div>

  </div>

  <div style="display: flex;flex-direction: row;justify-content: space-between;">
    <div style=" padding-top: 28px; color: #6975d7; font-size: 16px;font-weight: 600; text-decoration: underline;">
      <span style="cursor: pointer;" *ngIf="appointmentBook" (click)="bookAppointment()"> Book Appointment</span>
    </div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()"
        style="background-color: #eee; padding: 8px 16px; border-radius: 6px; color: #fff; font-weight: bold; border: 0px; margin: 8px; color: #444; outline: none;">Cancel</button>

      <button (click)="closeDialog();donePrescription()" style="background-color: #5D86CC;
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    border: 0px;
    font-size: 18px;
    margin: 8px 0px;
    outline: none;">Go Home</button>

    </div>
  </div>
</div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
        <!-- <img (click)="goBackpage()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                  src="assets/images/exclamation-circle-blue.svg" alt="" /> -->
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>