import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { DoctorService } from '../../../services/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../services/global.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BrowserAnimationsModule, } from '@angular/platform-browser/animations';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-clinicians-profile',
  templateUrl: './clinicians-profile.component.html',
  styleUrls: ['./clinicians-profile.component.scss']
})
export class CliniciansProfileComponent implements OnInit {
  [key: string]: any;
  currentStage: string = '1'
  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  registrationMask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  userId: any = null
  user: any = { doctor: {} }
  specialization: any = null
  degreeArray: any = []
  councilsArray: any = []
  specializations: any = []
  institutes: any = []
  specialitiesArray: any = []
  originalSpecialitiesArray: any = []
  subSpecialitiesArray: any = []
  originalSubSpecialitiesArray: any = []
  educationArray:any[] = []
  awardArray:any[] = []
  yearsArray:any[] = []
  clinicArray:any[] = []
  editMode = false
  experienceArray:any[] = []
  date: any
  minDate: any
  errors: any = {}
  educationErrors:any[] = []
  awardsErrors:any[] = []
deletedAwards:any[] = []
  deletedEducation:any[] = []
  path = ""
  updatedPersonalDetails = false
  updatedEducationDetails = false


  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private authService: AuthService, private doctorService: DoctorService, private globalService: GlobalService, private toastr: ToastrService, private router: Router) {

    this.userId = this.authService.getUser()

    let params = {
      user_id: this.userId
    }
    const urlArray = location.href.split('/')
    this.path = urlArray[urlArray.length - 2].toLowerCase()
    let d = new Date()
    this.date = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.minDate = { year: 1900, month: 1, day: 1 }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      if (response.success) {
        this.user = response.user
        this.user.doctor = response.doctor
        this.awardArray = response.awards
        this.educationArray = response.educations

        if (this.awardArray.length == 0) {
          this.addField('awardArray')
        }
        if (this.educationArray.length == 0) {
          this.addField('educationArray')
        }

      } else {
        this.toastr.error(response.error)
      }
    })
    this.globalService.getConstants().subscribe((response:any) => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
      this.originalSpecialitiesArray = response.specialities
      this.specializations = response.specializations
      this.specialitiesArray = response.specialities
      this.institutes = response.institutes
      let currentYear = (new Date).getFullYear()
      let startYear = 1960
      for (let i = 0; i <= currentYear - startYear; i++) {
        this.yearsArray.push(startYear + i)
      }
      for (let i = 1; i <= 50; i++) {
        this.experienceArray.push(`${i}`)
      }
    })

  }

  ngOnInit() {
    $('textarea').keyup(function (event) {

      let re = /(^|[.!?]\s+)([a-z])/g;
      var textBox: HTMLInputElement = <HTMLInputElement>event.target;
      //textBox.value = textBox.value.charAt(0).toUpperCase() + textBox.value.slice(1).toLowerCase();
      textBox.value = textBox.value.replace(re, (m, $1, $2) => $1 + $2.toUpperCase());
    });

    // this.setStage(this.currentStage)

  }

  setValue(field:any, value:any) {
    this[field] = value
  }

  getAwardTitle(index:any, awardArray:any) {
    return awardArray[index].title;
  }
  setAssociations(association:any, index:any, field:any, value:any) {
    this[association][index][field] = value
  }

  searchSpecialities(e:any) {
    this.specialitiesArray = this.filterSpecialities(e.query, this.originalSpecialitiesArray)
  }

  filterSpecialities(query:any, list:any) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let speciality = list[i];
      if (speciality.speciality.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(speciality);
      }
    }
    return filtered
  }

  clearSubSpeciality(e:any) {
    this.user.doctor.controls['subSpeciality'].setValue(null)
  }

  getSubSpecialityList() {
    this.subSpecialitiesArray = this.user.doctor.speciality.sub_speciality
    this.originalSubSpecialitiesArray = this.user.doctor.speciality.sub_speciality
  }

  searchSubspecialities(e:any) {
    this.subSpecialitiesArray = this.filterSubSpecialities(e.query, this.originalSubSpecialitiesArray)
  }

  filterSubSpecialities(query:any, list:any) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let speciality = list[i];
      if (speciality.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(speciality);
      }
    }
    return filtered
  }

  addField(field:any) {
    let obj = {}
    switch (field) {
      case "educationArray":
        obj = { speciality: null, institute: null, year: null }
        break;
      case "awardArray":
        obj = { title: null, year: null }
        break;
      default:
        obj = {}
        break;
    }
    this[field].push(obj)
  }

  removeField(field:any, i:any) {
    if (field == 'awardArray' && this[field][i].id) {
      this.deletedAwards.push(this[field][i].id)
      this.awardsErrors.splice(i, 1)
    }
    if (field == 'educationArray' && this[field][i].id) {
      this.deletedEducation.push(this[field][i].id)
      this.educationErrors.splice(i, 1)
    }
    this[field].splice(i, 1)
  }


  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  addLabelAutoComplete(e:any) {
    $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelAutoComplete(e:any) {

    if (e.target.value == '') {
      $(e.target.parentElement.parentElement.parentElement.children[1]).removeClass('animatedLabel')
    }

  }

  checkIfAwardValid(awards:any) {

  }

  editUser() {
    let d = new Date(this.user.dob)
    this.user.dob = { year: Number(d.getFullYear()), month: Number(d.getMonth() + 1), day: Number(d.getDate()) }
    this.user.doctor.speciality = { speciality: this.user.doctor.speciality }
    this.editMode = true
  }

  checkEducationErrors() {
    let error = false
    this.educationArray.forEach(education => {
      if (education.speciality == null && education.year == null && education.institute == null) {
        return false
      }
      else if (education.speciality != null && education.year != null && education.institute != null) {
        return false
      } else {
        return true
      }
    })
    // return error
  }

  saveStepOne(f:any) {
    this.user.doctor.registered_name = this.user.doctor.registered_name ? this.user.doctor.registered_name.trim() : null
    if (!this.user.first_name) {
      this.errors = {}
      this.errors.fname = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.last_name) {
      this.errors = {}
      this.errors.lname = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.dob) {
      this.errors = {}
      this.errors.dob = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.doctor.degree) {
      this.errors = {}
      this.errors.degree = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.doctor.registration_number) {
      this.errors = {}
      this.errors.registration_number = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.doctor.registered_name) {
      this.errors = {}
      this.errors.registration_name = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else if (!this.user.doctor.state_medical_council) {
      this.errors = {}
      this.errors.state_medical_council = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    }
    else if (!this.user.doctor.speciality) {
      this.errors = {}
      this.errors.speciality = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    }
    else if (!this.user.doctor.sub_speciality) {
      this.errors = {}
      this.errors.sub_speciality = true
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    } else {
      this.educationErrors = []
      this.educationArray.map((education) => {
        if (education.speciality == null && education.year == null && education.institute == null) {
          this.educationErrors.push("")
        } else if (education.speciality != null && education.year != null && education.institute != null) {
          this.educationErrors.push("")
        } else if (!(education.speciality != null && education.year != null && education.institute != null)) {
          this.educationErrors.push("error")
        }
      })
      this.awardsErrors = []
      this.awardArray.map((award) => {

        if (award.year == null && (award.title == null || award.title.trim() == "")) {
          this.awardsErrors.push("")
        } else if (award.year != null && (award.title != null && award.title.trim() != "")) {
          this.awardsErrors.push("")
        } else if (!(award.year != null && (award.title != null && award.title.trim() != ""))) {
          this.awardsErrors.push("error")
        }
      })

      if (this.educationErrors.indexOf('error') == -1 && this.awardsErrors.indexOf('error') == -1) {
        this.saveDetails()
      } else {
        if (!(/Edge\/\d./i.test(navigator.userAgent))) {
          document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
        }
        this.toastr.error("Please fill in the required fields")
      }

    }
  }

  parseDate(dateObject: any) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
  }


  saveDetails() {
    this.user.doctor.speciality = this.user.doctor.speciality.speciality ? this.user.doctor.speciality.speciality : this.user.doctor.speciality
    this.user.doctor.awards = this.awardArray
    this.user.doctor.educations = this.educationArray
    this.user.deleted_awards = this.deletedAwards
    this.user.deleted_educations = this.deletedEducation
    this.user.dob = typeof (this.user.dob) == 'object' ? this.parseDate(this.user.dob) : this.user.dob
    this.user.updated_personal_details = this.updatedPersonalDetails
    this.user.updated_educational_details = this.updatedEducationDetails
    this.doctorService.updateProfile(this.user).subscribe((response:any) => {
      if (response.success) {
        if (this.path == "editprofile") {
          this.toastr.success("Details have been updated successfully")
          this.router.navigateByUrl('/doctor/profileView')
        } else {
          this.router.navigateByUrl('/doctor/profileSetup/stepTwo')
        }
      } else {
        this.toastr.error(response.error)
      }
    })
  }

  isInvalid(f:any) {
    //console.log(f)
  }

  setStage(stageNo:any) {
    this.currentStage = stageNo
    let headerText = ""
    switch (this.currentStage) {
      case "3":
        headerText = "DETAILS OF OFFICE PAPERS"
        break;
      case "5":
        headerText = "LET'S GET STARTED"
        break;
      default:
        // headerText = "CLINICIAN'S PROFILE"
        headerText = "";

        break;
    }
    let topHeading = document.getElementsByClassName('topHeading')[0]
    topHeading.innerHTML = headerText
    topHeading.scrollIntoView({ behavior: "smooth" })
  }

  resetForm() {
    //this.deletedAwards = this.awardArray
    //this.deletedEducation = this.educationArray
    this.awardArray.splice(1, 2)
    this.educationArray.splice(1, 2)
  }

  capitalize(e:any) {
    let re = /(^|[.!?]\s+)([a-z])/g;
    var textBox: HTMLInputElement = <HTMLInputElement>e.target;
    //textBox.value = textBox.value.charAt(0).toUpperCase() + textBox.value.slice(1).toLowerCase();
    textBox.value = textBox.value.replace(re, (m, $1, $2) => $1 + $2.toUpperCase());
  }

  closeCalender(e:any, d:any  ) {
    console.log(e.target.offsetParent.nodeName)
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  trimInput(awardArray:any, i:any, title:any) {
    awardArray[i][title] = awardArray[i][title].trim()
  }

  trimInput2(patient:any, name:any) {
    patient[name] = patient[name].trim()
  }

}
