/*
 Highcharts JS v10.3.3 (2023-01-20)

 Highcharts Drilldown module

 Author: Torstein Honsi
 License: www.highcharts.com/license

*/
(function (a) {
  "object" === typeof module && module.exports ? (a["default"] = a, module.exports = a) : "function" === typeof define && define.amd ? define("highcharts/modules/drilldown", ["highcharts"], function (v) {
    a(v);
    a.Highcharts = v;
    return a;
  }) : a("undefined" !== typeof Highcharts ? Highcharts : void 0);
})(function (a) {
  function v(a, q, f, p) {
    a.hasOwnProperty(q) || (a[q] = p.apply(null, f), "function" === typeof CustomEvent && window.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: q,
        module: a[q]
      }
    })));
  }
  a = a ? a._modules : {};
  v(a, "Extensions/Breadcrumbs.js", [a["Core/Chart/Chart.js"], a["Core/Defaults.js"], a["Core/Globals.js"], a["Core/Utilities.js"], a["Core/FormatUtilities.js"]], function (a, q, f, p, l) {
    var v = l.format;
    l = p.addEvent;
    var C = p.objectEach,
      y = p.extend,
      D = p.fireEvent,
      w = p.merge,
      A = p.pick,
      E = p.defined,
      r = p.isString;
    y(q.defaultOptions.lang, {
      mainBreadcrumb: "Main"
    });
    q = function () {
      function a(b, d) {
        this.group = void 0;
        this.list = [];
        this.elementList = {};
        this.isDirty = !0;
        this.level = 0;
        this.options = void 0;
        d = w(b.options.drilldown && b.options.drilldown.drillUpButton, a.defaultBreadcrumbsOptions, b.options.navigation && b.options.navigation.breadcrumbs, d);
        this.chart = b;
        this.options = d || {};
      }
      a.prototype.updateProperties = function (b) {
        this.setList(b);
        this.setLevel();
        this.isDirty = !0;
      };
      a.prototype.setList = function (b) {
        this.list = b;
      };
      a.prototype.setLevel = function () {
        this.level = this.list.length && this.list.length - 1;
      };
      a.prototype.getLevel = function () {
        return this.level;
      };
      a.prototype.getButtonText = function (b) {
        var a = this.chart,
          h = this.options,
          e = a.options.lang,
          f = A(h.format, h.showFullPath ? "{level.name}" : "\u2190 {level.name}");
        e = e && A(e.drillUpText, e.mainBreadcrumb);
        b = h.formatter && h.formatter(b) || v(f, {
          level: b.levelOptions
        }, a) || "";
        (r(b) && !b.length || "\u2190 " === b) && E(e) && (b = h.showFullPath ? e : "\u2190 " + e);
        return b;
      };
      a.prototype.redraw = function () {
        this.isDirty && this.render();
        this.group && this.group.align();
        this.isDirty = !1;
      };
      a.prototype.render = function () {
        var a = this.chart,
          d = this.options;
        !this.group && d && (this.group = a.renderer.g("breadcrumbs-group").addClass("highcharts-no-tooltip highcharts-breadcrumbs").attr({
          zIndex: d.zIndex
        }).add());
        d.showFullPath ? this.renderFullPathButtons() : this.renderSingleButton();
        this.alignBreadcrumbsGroup();
      };
      a.prototype.renderFullPathButtons = function () {
        this.destroySingleButton();
        this.resetElementListState();
        this.updateListElements();
        this.destroyListElements();
      };
      a.prototype.renderSingleButton = function () {
        var a = this.chart,
          d = this.list,
          h = this.options.buttonSpacing;
        this.destroyListElements();
        var e = this.group ? this.group.getBBox().width : h;
        d = d[d.length - 2];
        !a.drillUpButton && 0 < this.level ? a.drillUpButton = this.renderButton(d, e, h) : a.drillUpButton && (0 < this.level ? this.updateSingleButton() : this.destroySingleButton());
      };
      a.prototype.alignBreadcrumbsGroup = function (a) {
        if (this.group) {
          var b = this.options,
            h = b.buttonTheme,
            e = b.position,
            f = "chart" === b.relativeTo || "spacingBox" === b.relativeTo ? void 0 : "scrollablePlotBox",
            k = this.group.getBBox();
          b = 2 * (h.padding || 0) + b.buttonSpacing;
          e.width = k.width + b;
          e.height = k.height + b;
          k = w(e);
          a && (k.x += a);
          this.options.rtl && (k.x += e.width);
          k.y = A(k.y, this.yOffset, 0);
          this.group.align(k, !0, f);
        }
      };
      a.prototype.renderButton = function (a, d, h) {
        var b = this,
          f = this.chart,
          k = b.options,
          x = w(k.buttonTheme);
        d = f.renderer.button(b.getButtonText(a), d, h, function (d) {
          var e = k.events && k.events.click,
            h;
          e && (h = e.call(b, d, a));
          !1 !== h && (d.newLevel = k.showFullPath ? a.level : b.level - 1, D(b, "up", d));
        }, x).addClass("highcharts-breadcrumbs-button").add(b.group);
        f.styledMode || d.attr(k.style);
        return d;
      };
      a.prototype.renderSeparator = function (a, d) {
        var b = this.chart,
          e = this.options.separator;
        a = b.renderer.label(e.text, a, d, void 0, void 0, void 0, !1).addClass("highcharts-breadcrumbs-separator").add(this.group);
        b.styledMode || a.css(e.style);
        return a;
      };
      a.prototype.update = function (a) {
        w(!0, this.options, a);
        this.destroy();
        this.isDirty = !0;
      };
      a.prototype.updateSingleButton = function () {
        var a = this.chart,
          d = this.list[this.level - 1];
        a.drillUpButton && a.drillUpButton.attr({
          text: this.getButtonText(d)
        });
      };
      a.prototype.destroy = function () {
        this.destroySingleButton();
        this.destroyListElements(!0);
        this.group && this.group.destroy();
        this.group = void 0;
      };
      a.prototype.destroyListElements = function (a) {
        var b = this.elementList;
        C(b, function (d, e) {
          if (a || !b[e].updated) d = b[e], d.button && d.button.destroy(), d.separator && d.separator.destroy(), delete d.button, delete d.separator, delete b[e];
        });
        a && (this.elementList = {});
      };
      a.prototype.destroySingleButton = function () {
        this.chart.drillUpButton && (this.chart.drillUpButton.destroy(), this.chart.drillUpButton = void 0);
      };
      a.prototype.resetElementListState = function () {
        C(this.elementList, function (a) {
          a.updated = !1;
        });
      };
      a.prototype.updateListElements = function () {
        var a = this,
          d = a.elementList,
          h = a.options.buttonSpacing,
          e = a.list,
          f = a.options.rtl,
          k = f ? -1 : 1,
          x = function (a, b) {
            return k * a.getBBox().width + k * b;
          },
          t = a.group ? x(a.group, h) : h,
          l;
        e.forEach(function (b, q) {
          q = q === e.length - 1;
          if (d[b.level]) {
            l = d[b.level];
            var p = l.button;
            if (l.separator || q) l.separator && q && (l.separator.destroy(), delete l.separator);else {
              t += k * h;
              l.separator = a.renderSeparator(t, h);
              if (f) {
                var r = l.separator;
                r.translate(t - r.getBBox().width, h);
              }
              t += x(l.separator, h);
            }
            d[b.level].updated = !0;
          } else p = a.renderButton(b, t, h), f && p.translate(t - p.getBBox().width, h), t += x(p, h), q || (r = a.renderSeparator(t, h), f && r.translate(t - r.getBBox().width, h), t += x(r, h)), d[b.level] = {
            button: p,
            separator: r,
            updated: !0
          };
          p && p.setState(q ? 2 : 0);
        });
      };
      a.defaultBreadcrumbsOptions = {
        buttonTheme: {
          fill: "none",
          height: 18,
          padding: 2,
          "stroke-width": 0,
          zIndex: 7,
          states: {
            select: {
              fill: "none"
            }
          },
          style: {
            color: "#335cad"
          }
        },
        buttonSpacing: 5,
        floating: !1,
        format: void 0,
        relativeTo: "plotBox",
        rtl: !1,
        position: {
          align: "left",
          verticalAlign: "top",
          x: 0,
          y: void 0
        },
        separator: {
          text: "/",
          style: {
            color: "#666666"
          }
        },
        showFullPath: !0,
        style: {},
        useHTML: !1,
        zIndex: 7
      };
      return a;
    }();
    f.Breadcrumbs || (f.Breadcrumbs = q, l(a, "getMargins", function () {
      var a = this.breadcrumbs;
      if (a && !a.options.floating && a.level) {
        var b = a.options,
          d = b.buttonTheme;
        d = (d.height || 0) + 2 * (d.padding || 0) + b.buttonSpacing;
        b = b.position.verticalAlign;
        "bottom" === b ? (this.marginBottom = (this.marginBottom || 0) + d, a.yOffset = d) : "middle" !== b ? (this.plotTop += d, a.yOffset = -d) : a.yOffset = void 0;
      }
    }), l(a, "redraw", function () {
      this.breadcrumbs && this.breadcrumbs.redraw();
    }), l(a, "destroy", function () {
      this.breadcrumbs && (this.breadcrumbs.destroy(), this.breadcrumbs = void 0);
    }), l(a, "afterShowResetZoom", function () {
      if (this.breadcrumbs) {
        var a = this.resetZoomButton && this.resetZoomButton.getBBox(),
          b = this.breadcrumbs.options;
        a && "right" === b.position.align && "plotBox" === b.relativeTo && this.breadcrumbs.alignBreadcrumbsGroup(-a.width - b.buttonSpacing);
      }
    }), l(a, "selection", function (a) {
      !0 === a.resetSelection && this.breadcrumbs && this.breadcrumbs.alignBreadcrumbsGroup();
    }));
    "";
    return q;
  });
  v(a, "Extensions/Drilldown.js", [a["Core/Animation/AnimationUtilities.js"], a["Core/Axis/Axis.js"], a["Core/Chart/Chart.js"], a["Core/Color/Color.js"], a["Series/Column/ColumnSeries.js"], a["Core/Globals.js"], a["Core/Defaults.js"], a["Core/Series/Point.js"], a["Core/Series/Series.js"], a["Core/Series/SeriesRegistry.js"], a["Core/Renderer/SVG/SVGRenderer.js"], a["Core/Axis/Tick.js"], a["Core/Utilities.js"], a["Extensions/Breadcrumbs.js"]], function (a, q, f, p, l, v, K, y, D, w, A, E, r, x) {
    function b(a) {
      var g = d(this.chart.options.drilldown.animation);
      a && (a.hide(), G(function () {
        a && a.added && a.fadeIn();
      }, Math.max(g.duration - 50, 0)));
    }
    var d = a.animObject,
      h = v.noop;
    a = K.defaultOptions;
    var e = r.addEvent,
      C = r.removeEvent,
      k = r.extend,
      z = r.fireEvent,
      t = r.merge,
      L = r.objectEach,
      B = r.pick,
      G = r.syncTimeout;
    w = w.seriesTypes.pie;
    var H = 1;
    k(a.lang, {});
    a.drilldown = {
      activeAxisLabelStyle: {
        cursor: "pointer",
        color: "#003399",
        fontWeight: "bold",
        textDecoration: "underline"
      },
      activeDataLabelStyle: {
        cursor: "pointer",
        color: "#003399",
        fontWeight: "bold",
        textDecoration: "underline"
      },
      animation: {
        duration: 500
      },
      drillUpButton: {
        position: {
          align: "right",
          x: -10,
          y: 10
        }
      }
    };
    A.prototype.Element.prototype.fadeIn = function (a) {
      this.attr({
        opacity: .1,
        visibility: "inherit"
      }).animate({
        opacity: B(this.newOpacity, 1)
      }, a || {
        duration: 250
      });
    };
    f.prototype.addSeriesAsDrilldown = function (a, c) {
      this.addSingleSeriesAsDrilldown(a, c);
      this.applyDrilldown();
    };
    f.prototype.addSingleSeriesAsDrilldown = function (a, c) {
      var g = a.series,
        m = g.xAxis,
        b = g.yAxis,
        u = [],
        d = [],
        n;
      var e = this.styledMode ? {
        colorIndex: B(a.colorIndex, g.colorIndex)
      } : {
        color: a.color || g.color
      };
      this.drilldownLevels || (this.drilldownLevels = []);
      var f = g.options._levelNumber || 0;
      (n = this.drilldownLevels[this.drilldownLevels.length - 1]) && n.levelNumber !== f && (n = void 0);
      c = k(k({
        _ddSeriesId: H++
      }, e), c);
      var l = g.points.indexOf(a);
      g.chart.series.forEach(function (a) {
        a.xAxis !== m || a.isDrilling || (a.options._ddSeriesId = a.options._ddSeriesId || H++, a.options._colorIndex = a.userOptions._colorIndex, a.options._levelNumber = a.options._levelNumber || f, n ? (u = n.levelSeries, d = n.levelSeriesOptions) : (u.push(a), a.purgedOptions = t({
          _ddSeriesId: a.options._ddSeriesId,
          _levelNumber: a.options._levelNumber,
          selected: a.options.selected
        }, a.userOptions), d.push(a.purgedOptions)));
      });
      a = k({
        levelNumber: f,
        seriesOptions: g.options,
        seriesPurgedOptions: g.purgedOptions,
        levelSeriesOptions: d,
        levelSeries: u,
        shapeArgs: a.shapeArgs,
        bBox: a.graphic ? a.graphic.getBBox() : {},
        color: a.isNull ? p.parse(e.color).setOpacity(0).get() : e.color,
        lowerSeriesOptions: c,
        pointOptions: g.options.data[l],
        pointIndex: l,
        oldExtremes: {
          xMin: m && m.userMin,
          xMax: m && m.userMax,
          yMin: b && b.userMin,
          yMax: b && b.userMax
        },
        resetZoomButton: n && n.levelNumber === f ? void 0 : this.resetZoomButton
      }, e);
      this.drilldownLevels.push(a);
      m && m.names && (m.names.length = 0);
      c = a.lowerSeries = this.addSeries(c, !1);
      c.options._levelNumber = f + 1;
      m && (m.oldPos = m.pos, m.userMin = m.userMax = null, b.userMin = b.userMax = null);
      g.type === c.type && (c.animate = c.animateDrilldown || h, c.options.animation = !0);
    };
    f.prototype.applyDrilldown = function () {
      var a = this.drilldownLevels;
      if (a && 0 < a.length) {
        var c = a[a.length - 1].levelNumber;
        this.drilldownLevels.forEach(function (a) {
          a.levelNumber === c && a.levelSeries.forEach(function (a) {
            a.options && a.options._levelNumber === c && a.remove(!1);
          });
        });
      }
      this.resetZoomButton && (this.resetZoomButton.hide(), delete this.resetZoomButton);
      this.pointer.reset();
      z(this, "afterDrilldown");
      this.redraw();
      z(this, "afterApplyDrilldown");
    };
    var I = function (a) {
      var c = [];
      (a = a.drilldownLevels) && a.length && (c[0] || c.push({
        level: 0,
        levelOptions: a[0].seriesOptions
      }), a.forEach(function (a, g) {
        a.levelNumber + 1 > c[c.length - 1].level && c.push({
          level: a.levelNumber + 1,
          levelOptions: t({
            name: a.lowerSeries.name
          }, a.pointOptions)
        });
      }));
      return c;
    };
    f.prototype.drillUp = function () {
      if (this.drilldownLevels && 0 !== this.drilldownLevels.length) {
        z(this, "beforeDrillUp");
        for (var a = this, c = a.drilldownLevels, b = c[c.length - 1].levelNumber, m = c.length, d = a.series, u, e, n, f, h = function (c) {
            d.forEach(function (a) {
              a.options._ddSeriesId === c._ddSeriesId && (g = a);
            });
            var g = g || a.addSeries(c, !1);
            g.type === n.type && g.animateDrillupTo && (g.animate = g.animateDrillupTo);
            c === e.seriesPurgedOptions && (f = g);
          }, k = a.drilldownLevels.length; m--;) if (e = c[m], e.levelNumber === b) {
          c.pop();
          n = e.lowerSeries;
          if (!n.chart) for (u = d.length; u--;) if (d[u].options.id === e.lowerSeriesOptions.id && d[u].options._levelNumber === b + 1) {
            n = d[u];
            break;
          }
          n.xData = [];
          n.xAxis && n.xAxis.names && (0 === k || m === k) && (n.xAxis.names.length = 0);
          e.levelSeriesOptions.forEach(h);
          z(a, "drillup", {
            seriesOptions: e.seriesPurgedOptions || e.seriesOptions
          });
          f.type === n.type && (f.drilldownLevel = e, f.options.animation = a.options.drilldown.animation, n.animateDrillupFrom && n.chart && n.animateDrillupFrom(e));
          f.options._levelNumber = b;
          n.remove(!1);
          f.xAxis && (u = e.oldExtremes, f.xAxis.setExtremes(u.xMin, u.xMax, !1), f.yAxis.setExtremes(u.yMin, u.yMax, !1));
          e.resetZoomButton && (a.resetZoomButton = e.resetZoomButton);
        }
        z(a, "afterDrillUp");
        this.redraw();
        this.ddDupes && (this.ddDupes.length = 0);
        z(a, "drillupall");
      }
    };
    e(f, "afterInit", function () {
      var a = this;
      a.drilldown = {
        chart: a,
        fadeInGroup: b,
        update: function (c, g) {
          t(!0, a.options.drilldown, c);
          B(g, !0) && a.redraw();
        }
      };
    });
    e(f, "render", function () {
      (this.xAxis || []).forEach(function (a) {
        a.ddPoints = {};
        a.series.forEach(function (c) {
          var g,
            b = c.xData || [],
            e = c.points;
          for (g = 0; g < b.length; g++) {
            var d = c.options.data[g];
            "number" !== typeof d && (d = c.pointClass.prototype.optionsToObject.call({
              series: c
            }, d), d.drilldown && (a.ddPoints[b[g]] || (a.ddPoints[b[g]] = []), d = g - (c.cropStart || 0), a.ddPoints[b[g]].push(e && 0 <= d && d < e.length ? e[d] : !0)));
          }
        });
        L(a.ticks, E.prototype.drillable);
      });
    });
    e(v.Breadcrumbs, "up", function (a) {
      var c = this.chart;
      a = this.getLevel() - a.newLevel;
      for (var g = 0; g < a; g++) c.drillUp();
    });
    e(f, "afterDrilldown", function () {
      var a = this.options.drilldown;
      a = a && a.breadcrumbs;
      this.breadcrumbs || (this.breadcrumbs = new x(this, a));
      this.breadcrumbs.updateProperties(I(this));
    });
    e(f, "afterDrillUp", function () {
      this.breadcrumbs && this.breadcrumbs.updateProperties(I(this));
    });
    e(f, "update", function (a) {
      var c = this.breadcrumbs,
        b = a.options.drilldown && a.options.drilldown.breadcrumbs;
      c && b && c.update(a.options.drilldown.breadcrumbs);
    });
    l.prototype.animateDrillupTo = function (a) {
      if (!a) {
        var c = this,
          b = c.drilldownLevel;
        this.points.forEach(function (a) {
          var c = a.dataLabel;
          a.graphic && a.graphic.hide();
          c && (c.hidden = "hidden" === c.attr("visibility"), c.hidden || (c.hide(), a.connector && a.connector.hide()));
        });
        G(function () {
          if (c.points) {
            var a = [];
            c.data.forEach(function (c) {
              a.push(c);
            });
            c.nodes && (a = a.concat(c.nodes));
            a.forEach(function (a, c) {
              c = c === (b && b.pointIndex) ? "show" : "fadeIn";
              var g = "show" === c ? !0 : void 0,
                d = a.dataLabel;
              if (a.graphic) a.graphic[c](g);
              d && !d.hidden && (d.fadeIn(), a.connector && a.connector.fadeIn());
            });
          }
        }, Math.max(this.chart.options.drilldown.animation.duration - 50, 0));
        delete this.animate;
      }
    };
    l.prototype.animateDrilldown = function (a) {
      var c = this,
        b = this.chart,
        g = b.drilldownLevels,
        e,
        f = d(b.options.drilldown.animation),
        h = this.xAxis,
        n = b.styledMode;
      a || (g.forEach(function (a) {
        c.options._ddSeriesId === a.lowerSeriesOptions._ddSeriesId && (e = a.shapeArgs, n || (e.fill = a.color));
      }), e.x += B(h.oldPos, h.pos) - h.pos, this.points.forEach(function (a) {
        var b = a.shapeArgs;
        n || (b.fill = a.color);
        a.graphic && a.graphic.attr(e).animate(k(a.shapeArgs, {
          fill: a.color || c.color
        }), f);
      }), b.drilldown && b.drilldown.fadeInGroup(this.dataLabelsGroup), delete this.animate);
    };
    l.prototype.animateDrillupFrom = function (a) {
      var c = d(this.chart.options.drilldown.animation),
        b = this.group,
        e = b !== this.chart.columnGroup,
        g = this;
      g.trackerGroups.forEach(function (a) {
        if (g[a]) g[a].on("mouseover");
      });
      e && delete this.group;
      this.points.forEach(function (d) {
        var f = d.graphic,
          m = a.shapeArgs,
          h = function () {
            f.destroy();
            b && e && (b = b.destroy());
          };
        f && m && (delete d.graphic, g.chart.styledMode || (m.fill = a.color), c.duration ? f.animate(m, t(c, {
          complete: h
        })) : (f.attr(m), h()));
      });
    };
    w && k(w.prototype, {
      animateDrillupTo: l.prototype.animateDrillupTo,
      animateDrillupFrom: l.prototype.animateDrillupFrom,
      animateDrilldown: function (a) {
        var c = this.chart.drilldownLevels[this.chart.drilldownLevels.length - 1],
          b = this.chart.options.drilldown.animation;
        this.is("item") && (b.duration = 0);
        if (this.center) {
          var d = c.shapeArgs,
            e = d.start,
            g = (d.end - e) / this.points.length,
            f = this.chart.styledMode;
          a || (this.points.forEach(function (a, m) {
            var h = a.shapeArgs;
            f || (d.fill = c.color, h.fill = a.color);
            if (a.graphic) a.graphic.attr(t(d, {
              start: e + m * g,
              end: e + (m + 1) * g
            }))[b ? "animate" : "attr"](h, b);
          }), this.chart.drilldown && this.chart.drilldown.fadeInGroup(this.dataLabelsGroup), delete this.animate);
        }
      }
    });
    y.prototype.doDrilldown = function () {
      this.runDrilldown();
    };
    y.prototype.runDrilldown = function (a, c, b) {
      var d = this.series.chart,
        e = d.options.drilldown,
        g = (e.series || []).length;
      d.ddDupes || (d.ddDupes = []);
      for (; g-- && !f;) if (e.series[g].id === this.drilldown && -1 === d.ddDupes.indexOf(this.drilldown)) {
        var f = e.series[g];
        d.ddDupes.push(this.drilldown);
      }
      z(d, "drilldown", {
        point: this,
        seriesOptions: f,
        category: c,
        originalEvent: b,
        points: "undefined" !== typeof c && this.series.xAxis.getDDPoints(c).slice(0)
      }, function (c) {
        var b = c.point.series && c.point.series.chart,
          d = c.seriesOptions;
        b && d && (a ? b.addSingleSeriesAsDrilldown(c.point, d) : b.addSeriesAsDrilldown(c.point, d));
      });
    };
    q.prototype.drilldownCategory = function (a, c) {
      this.getDDPoints(a).forEach(function (b) {
        b && b.series && b.series.visible && b.runDrilldown && b.runDrilldown(!0, a, c);
      });
      this.chart.applyDrilldown();
    };
    q.prototype.getDDPoints = function (a) {
      return this.ddPoints && this.ddPoints[a] || [];
    };
    E.prototype.drillable = function () {
      var a = this.pos,
        c = this.label,
        b = this.axis,
        d = "xAxis" === b.coll && b.getDDPoints,
        f = d && b.getDDPoints(a),
        h = b.chart.styledMode;
      d && (c && f && f.length ? (c.drillable = !0, c.basicStyles || h || (c.basicStyles = t(c.styles)), c.addClass("highcharts-drilldown-axis-label"), c.removeOnDrillableClick && C(c.element, "click"), c.removeOnDrillableClick = e(c.element, "click", function (c) {
        c.preventDefault();
        b.drilldownCategory(a, c);
      }), h || c.css(b.chart.options.drilldown.activeAxisLabelStyle)) : c && c.drillable && c.removeOnDrillableClick && (h || (c.styles = {}, c.element.removeAttribute("style"), c.css(c.basicStyles)), c.removeOnDrillableClick(), c.removeClass("highcharts-drilldown-axis-label")));
    };
    e(y, "afterInit", function () {
      this.drilldown && !this.unbindDrilldownClick && (this.unbindDrilldownClick = e(this, "click", J));
      return this;
    });
    e(y, "update", function (a) {
      a = a.options || {};
      a.drilldown && !this.unbindDrilldownClick ? this.unbindDrilldownClick = e(this, "click", J) : !a.drilldown && void 0 !== a.drilldown && this.unbindDrilldownClick && (this.unbindDrilldownClick = this.unbindDrilldownClick());
    });
    var J = function (a) {
      var c = this.series;
      c.xAxis && !1 === c.chart.options.drilldown.allowPointDrilldown ? c.xAxis.drilldownCategory(this.x, a) : this.runDrilldown(void 0, void 0, a);
    };
    e(D, "afterDrawDataLabels", function () {
      var a = this.chart.options.drilldown.activeDataLabelStyle,
        c = this.chart.renderer,
        b = this.chart.styledMode;
      this.points.forEach(function (d) {
        var e = d.options.dataLabels,
          g = B(d.dlOptions, e && e.style, {});
        d.drilldown && d.dataLabel && ("contrast" !== a.color || b || (g.color = c.getContrast(d.color || this.color)), e && e.color && (g.color = e.color), d.dataLabel.addClass("highcharts-drilldown-data-label"), b || d.dataLabel.css(a).css(g));
      }, this);
    });
    var F = function (a, b, d, e) {
      a[d ? "addClass" : "removeClass"]("highcharts-drilldown-point");
      e || a.css({
        cursor: b
      });
    };
    e(D, "afterDrawTracker", function () {
      var a = this.chart.styledMode;
      this.points.forEach(function (b) {
        b.drilldown && b.graphic && F(b.graphic, "pointer", !0, a);
      });
    });
    e(y, "afterSetState", function () {
      var a = this.series.chart.styledMode;
      this.drilldown && this.series.halo && "hover" === this.state ? F(this.series.halo, "pointer", !0, a) : this.series.halo && F(this.series.halo, "auto", !1, a);
    });
    e(f, "drillup", function () {
      this.resetZoomButton && (this.resetZoomButton = this.resetZoomButton.destroy());
    });
    e(f, "drillupall", function () {
      this.resetZoomButton && this.showResetZoom();
    });
  });
  v(a, "masters/modules/drilldown.src.js", [], function () {});
});
