import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from '../../../services/patient.service';
import { refreshService } from '../../../services/refersh.service';
import { UtilService } from '../../../util/utilService';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
interface patientInterface {
  _id: number;
  id: number;
  formattedDOB: string;
  vaccinationStartDate: string;
  createdAt: string;

  User: {
    dob: string,
    gender: string
  }
}

function checkForNull(value:any) {
  if (value && value != '' && value != '0' && value != 0) {
    return false
  } else {
    return true;
  }
}

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-add-vitals',
  templateUrl: './add-vitals.component.html',
  styleUrls: ['./add-vitals.component.css'],
 
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  
})



export class AddVitalsComponent implements OnInit {
  @Input() width: string = '80%';
  
  @Input() containerHeight!: string;
  @ViewChild('tooltipRef') tooltip!: MatTooltip;
  showHcAndCC:boolean=false
  tooltipDisabled:boolean=false
  previousVitals = {
   weight:{value:'',date:''},
   height:{value:'',date:''},
   hc:{value:'',date:''},
   cc:{value:'',date:''},
    Temp: {value:'',date:''},
    spo2:{value:'',date:''},
    Bloodhaemoglobin: {value:'',date:''},
    bpSys: {value:'',date:''},
    bpDia: {value:'',date:''},
    Heartrate: {value:'',date:''},
    Pulse: {value:'',date:''},
    BloodSugarRandom: {value:'',date:''},
    Bloodsugar_F: {value:'',date:''},
    Bloodsugar_PP: {value:'',date:''},
    Blood_Hba1c: {value:'',date:''},
    Chol_HDL: {value:'',date:''},
    Chol_LDL: {value:'',date:''},
    TotalCholesterol: {value:'',date:''},
    Triglycerides_LFT: {value:'',date:''},
    lmp: {value:'',date:''},
    
    
  }
   @Input() patientResponse:any;
   @ViewChild("openVitals") openVitals!:ElementRef;
   @Input () isPediatric:any
   @Input() appionmentMessage:any
   @Input () isEdit:any
   @Input () addVisit:any
   @Input () PassedVisit:any
   @Input() isEditableVisit:any
  @Input() visitFlag:any
  @Input() todayvisitDate:any
  showLMP: boolean = false
  isVisitEdit: boolean = false
  isEditFlag: boolean = false
  visits: any
    minMaxPercentile: any = { weight: { min: "", max: "" }, height: { min: "", max: "" }, hc: { min: "", max: "" }, cc: { min: "", max: "" } }
  visitDateDiff: any = null;
  selectedVisit: any = {}
  visitsForVisitScreen:any;
  latestVisitWithValues: any = ""
  patientData: any
  patientId: any
  patientInfo: any
  selectedVisitdate: any;
  birthVisit:any;
  todayDate
  //isEditableVisit:boolean=false;
  visitDeleteFlag: boolean = false
  allVisits:any;
  showSaveButton = true;
  lmpDate: any
  vitalsSaveFlag: boolean = false;
  paramPatientId: any;
  paramPatientId1: any;
  paramPatientId2: any;
  firstVisitId: any;
  patientDataFromLocal:any;
  visitIdByAppoinment:any;
  //event to emit current values in boxes (wt, ht)
  public VitalsDataChangeWtHtEvent: EventEmitter<any> = new EventEmitter<any>();
  getflag: any;
  latestVisitForOlxQuickRx: any;
  dateOfBirth: any;
  dateOfBirthFromLocal: any;

  constructor(public dialog: MatDialog, private util: UtilService, private patientService: PatientService, private refreshservice: refreshService, private route: ActivatedRoute) {

    this.selectedVisitdate = new Date();
    //this.lmpDate=new Date();
    this.todayDate = new Date();
  }

  ngOnInit(): void {
    
    this.route.queryParams.subscribe((params:any) => {
      console.log("params ", params);
      this.patientId = params.patientId;
      

    })
    console.log("paramPatientId123", this.paramPatientId);

    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData")??'null');
    console.log("May1222222", this.patientResponse)


    // if(this.patientResponse.visits && this.patientResponse.visits.length>0){
    //   this.firstVisitId = this.patientResponse.visits[0]._id;
    // }
//     console.log("firstVisitId in addVitals", this.firstVisitId);
    this.patientData = this.patientResponse;

    this.patientData = this.patientResponse;
    console.log("patientDatasep",this.patientData);
    this.dateOfBirthFromLocal = this.patientDataFromLocal.User.dob ;
    this.dateOfBirth = this.patientResponse?.DOB?this.patientResponse.DOB:this.dateOfBirthFromLocal;
    console.log("this.dateOfBirth",this.dateOfBirth);
    this.selectedVisit = {
      weight: '',
      height: '',
      Temp: '',
      spo2: '',
      // lmp:null,
      Bloodhaemoglobin: '',
      bpSys: '',
      bpDia: '',
      Heartrate: '',
      Pulse: '',
      BloodSugarRandom: '',
      Bloodsugar_F: '',
      Bloodsugar_PP: '',
      Blood_Hba1c: '',
      Chol_HDL: '',
      Chol_LDL: '',
      TotalCholesterol: '',
      Triglycerides_LFT: '',
      addedBy: 'doctor',
      date: this.reverseDate(new Date())

    };
    console.log("this.selectedVisit LMP Bug", this.selectedVisit);
    
    if (this.patientData) {
      setTimeout(() => {
        this.getPatientInfo(this.patientData._id);
      }, 500)
    }
   
    
  }

  //emit event on change of Ht and wt
  emitHtWt() {
    let data: any = {};
    data.wt = this.selectedVisit.weight ? this.selectedVisit.weight : '';
    data.ht = this.selectedVisit.height ? this.selectedVisit.height : '';
    this.VitalsDataChangeWtHtEvent.emit(data);
  }
  //called from out side when patient is selected and id is availabel
  loadPatientData(patientId:any) {
    var patientID = this.paramPatientId
    this.patientId = patientId ? patientId : patientID;
    setTimeout(() => {
      var patientID = this.paramPatientId
      this.patientId = patientId ? patientId : patientID;
      this.getPatientInfo(patientId);
      console.log("this.patientId  in vitals ", this.patientId);

    }, 500)
  }
  hideTooltip() {
    this.tooltip.hide();
  }
  closeTooltip(tooltip: MatTooltip) {
    tooltip.hide();  // Hide the tooltip when clicked
  }
 
  hideSaveButton() {
    this.showSaveButton = false;
  }

  opensDialog(content:any) {
    // this.selectedIndex = index;
    this.dialog.open(content);
  }

  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  getMinMaxPercentile(date:any) {
    let momentVd = moment(date);
    let momentDob = moment(this.patientData.DOB ? this.patientData.DOB : '', "DD-MMM-YYYY");
    console.log("vitalsVisitId ", momentDob);

    let diff = moment.duration(momentVd.diff(momentDob));
    this.visitDateDiff = diff ? diff : {};
    
    this.calculateMinMax(moment(date).format("YYYY-MM-DD HH:mm:ss"), diff.years());
  }

  calculateMinMax(vDate:any, ageYears:any) {
    var postData = {
      patientId: this.patientData.id ? this.patientData.id : this.patientId,
      visitDate: vDate
    }
    console.log("calculateMinMax", postData);

    let gender = this.patientData.gender === "Male" ? "Boys" : "Girls";
    this.patientService.calculateMinMax(postData, this.patientData._id).subscribe((response:any) => {
      if (response) {
        var _minMaxPercentile:any = {};
        _minMaxPercentile["weight"] = response["weight" + gender];
        _minMaxPercentile["height"] = response["height" + gender];
        if (ageYears < 3) {
          _minMaxPercentile["hc"] = response["hc" + gender];
        } else {
          _minMaxPercentile["hc"] = { min: "", max: "" };
        }
        this.minMaxPercentile = _minMaxPercentile;

      }
      console.log("minMax ---", this.minMaxPercentile)
    });
  }

  addNewVitalsLink() {
    // if(this.patientAge >= 3){
    //   this.ageFlagForHCAndCC=true
    // }
    this.isEditFlag = false

    this.isVisitEdit = false;
    if (this.selectedVisit) {
      delete this.selectedVisit["_id"];
      // this.errors = {};
      var date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      // this.selectedVisitdate = this.reverseDate(date);
      if (this.isPediatric) {

        this.getMinMaxPercentile(date);
      }
      // this.selectedVisit={}
      this.selectedVisit.weight = "";
      this.selectedVisit.height = "";
      this.selectedVisit.hc = ""
      this.selectedVisit.cc = "";
      this.selectedVisit.BloodSugarRandom = ""
      this.selectedVisit.Blood_Hba1c = ""
      this.selectedVisit.Bloodhaemoglobin = ""
      this.selectedVisit.Bloodsugar_F = ""
      this.selectedVisit.Bloodsugar_PP = ""
      this.selectedVisit.Chol_HDL = ""
      this.selectedVisit.Chol_LDL = ""
      this.selectedVisit.Heartrate = ""
      this.selectedVisit.Pulse = ""
      this.selectedVisit.Temp = ""
      this.selectedVisit.TotalCholesterol = ""
      this.selectedVisit.Triglycerides_LFT = ""
      this.selectedVisit.bmi = ""
      this.selectedVisit.bpDia = ""
      this.selectedVisit.bpSys = ""
      this.selectedVisit.spo2 = ""
      this.selectedVisit.lmp = null;

    }
  }

  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  onSelectVisit(visit:any, type:any) {
 
     var obj = visit ? visit : '';
     this.selectedVisit = obj;
   
    // this.selectedVisitdate = this.reverseDate(new Date(visit.date));
     if (new Date(visit.date).getDate() === new Date().getDate() && new Date(visit.date).getMonth() === new Date().getMonth() && new Date(visit.date).getFullYear() === new Date().getFullYear()) {
       this.isEditableVisit = true
     }
     else{
       this.isEditableVisit = false
     
     }
     if (this.isPediatric) {
       this.getMinMaxPercentile(visit.date);
     }
 
     if (type == 1) {
       setTimeout(() => {
         if(this.selectedVisit.addedBy=="birth"){
           this.visitDeleteFlag=true
         }
         else{
           this.visitDeleteFlag=false
         }
         console.log('HAME', this.selectedVisit)
         if(!visit.weight || visit.weight == 'null' || visit.weight == '0' || visit.weight == 'undefined'){visit.weight = ""}
         if(!visit.height || visit.height == 'null' || visit.height == '0' || visit.height == 'undefined'){visit.height = ""}
         if(!visit.hc || visit.hc == 'null' || visit.hc == '0' || visit.hc == 'undefined'){visit.hc = ""}
         if(!visit.cc || visit.cc == 'null' || visit.cc == '0' || visit.cc == '0' || visit.cc == 'undefined'){visit.cc = ""}
         if(!visit.Temp || visit.Temp == 'null' || visit.Temp == 'undefined'){visit.Temp = ""}
         if(!visit.spo2 || visit.spo2 == 'null' || visit.spo2=='0' || visit.spo2=='undefined'){visit.spo2 = ""}
         if(!visit.bpSys || visit.bpSys == 'null' || visit.bpSys == '0' || visit.bpSys == 'undefined'){visit.bpSys = ""}
         if(!visit.bpDia || visit.bpDia == 'null' || visit.bpDia == '0' || visit.bpDia == 'undefined'){visit.bpDia = ""}
         if(!visit.Bloodhaemoglobin || visit.Bloodhaemoglobin == 'null' || visit.Bloodhaemoglobin == '0' || visit.Bloodhaemoglobin == 'undefined'){visit.Bloodhaemoglobin = ""}
         if(!visit.Blood_Hba1c || visit.Blood_Hba1c == 'null' || visit.Blood_Hba1c == '0' || visit.Blood_Hba1c == 'undefined'){visit.Blood_Hba1c = ""}
         if(!visit.BloodSugarRandom || visit.BloodSugarRandom == 'null' || visit.BloodSugarRandom == '0' || visit.BloodSugarRandom == 'undefined'){visit.BloodSugarRandom = ""}
         if(!visit.Bloodsugar_F || visit.Bloodsugar_F == 'null' || visit.Bloodsugar_F == '0' || visit.Bloodsugar_F == 'undefined'){visit.Bloodsugar_F = ""}
         if(!visit.Bloodsugar_PP || visit.Bloodsugar_PP == 'null' || visit.Bloodsugar_PP == '0' || visit.Bloodsugar_PP == 'undefined'){visit.Bloodsugar_PP = ""}
         if(!visit.Chol_HDL || visit.Chol_HDL == 'null' || visit.Chol_HDL == '0' || visit.Chol_HDL == 'undefined'){visit.Chol_HDL = ""}
         if(!visit.Chol_LDL || visit.Chol_LDL == 'null' || visit.Chol_LDL == '0' || visit.Chol_LDL == 'undefined'){visit.Chol_LDL = ""}
         if(!visit.Heartrate || visit.Heartrate == 'null' || visit.Heartrate == '0' || visit.Heartrate == 'undefined'){visit.Heartrate = ""}
         if(!visit.Pulse || visit.Pulse == 'null' || visit.Pulse == '0' || visit.Pulse == 'undefined'){visit.Pulse = ""}
         if(!visit.TotalCholesterol || visit.TotalCholesterol == 'null' || visit.TotalCholesterol == '0' || visit.TotalCholesterol == 'undefined'){visit.TotalCholesterol = ""}
         if(!visit.Triglycerides_LFT || visit.Triglycerides_LFT == 'null' || visit.Triglycerides_LFT == '0' || visit.Triglycerides_LFT == 'undefined'){visit.Triglycerides_LFT = ""}
         this.selectedVisit = visit
         this.selectedVisitdate = new Date(visit.date)
         this.lmpDate = visit.lmp ? new Date(visit.lmp) : null
        //  this.selectedVisitdate = new Date(visit.date)
        //  this.lmpDate=new Date(visit.lmp)
        // this.opensDialog(this.editVitalsModal);
       }, 500);
     }
   }
  tabClick(tab:any) {
    console.log("tab", tab);
    // if(tab.index==1){
    
    // }
    // else if(tab.index==2){
   
    // }
  }
  validateNumber(e:any) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110) || (e.keyCode == 46)
  }



  getPatientInfo = (patientId:any) => {
    console.log(" patientId in add vitals get PatientInfo", patientId);

    this.patientService.getPatientInfo(patientId).subscribe((response:any) => {
      if (response) {
        console.log("Response Patients111111111111", response);
        this.patientData = response
      if(response.visits && response.visits.length){
        this.latestVisitForOlxQuickRx = response.visits[response.visits.length-1]._id;
      }
        console.log("latestVisitForOlxQuickRx",this.latestVisitForOlxQuickRx);
        
        // this.visitIdByAppoinment = response.visits[response.visits.length-2]._id;
        // console.log("visitIdByAppoinment",this.visitIdByAppoinment);

        console.log('Patient2', this.patientData);
        this.isPediatric = this.patientData.patientTypes.length === 2 ? true : false;
        var ageObj = this.patientData.age;
        //this.age = moment().diff(this.dob, 'years');

        //this.patient = response;
        if (ageObj.years < 5) {
          this.showHcAndCC = true
          console.log("MMMMMMMMM????", this.showHcAndCC)
        }
        if (ageObj.years >= 8 && this.patientData.gender == "Female") {
          this.showLMP = true
          console.log("showLMP", this.showLMP);
          
        }
        this.visits = response.visits && response.visits.length ? response.visits : [];
        console.log("allVisit", this.visits)
        this.visits.map((element:any, index:any) => {
          if (element._id === "synthetic_record") {
            console.log("element._id appoinment adult", element._id);
            
            console.log("log in synthetic_record add vitals");

            this.visits.splice(index, 1);
          }


        });
        this.visits.map((visit:any) => {
          if (new Date(visit.date).getDate() == new Date().getDate() && new Date(visit.date).getMonth() == new Date().getMonth() && new Date(visit.date).getFullYear() == new Date().getFullYear()) {
            
          }
          else {



            if (visit.weight && visit.weight != '0' && visit.weight != 'undefined' && visit.weight != 'null' && visit.weight != null) {
console.log("visit1234",visit.lmp);

              //if(this.previousVitals.weight){
              this.previousVitals["weight"].value = visit.weight
              this.previousVitals["weight"].date = visit.date

            }
            if (visit.height && visit.height != '0' && visit.height != 'undefined' && visit.height != 'null' && visit.height != null) {
              this.previousVitals["height"].value = visit.height
              this.previousVitals["height"].date = visit.date
            }
            if (visit.Temp && visit.Temp != '0' && visit.Temp != 'undefined' && visit.Temp != 'null' && visit.Temp != null) {
              this.previousVitals["Temp"].value = visit.Temp
              this.previousVitals["Temp"].date = visit.date
            }
            if (visit.hc && visit.hc != '0' && visit.hc != 'undefined' && visit.hc != 'null' && visit.hc != null) {
              this.previousVitals["hc"].value = visit.hc
              this.previousVitals["hc"].date = visit.date
            }
            if (visit.cc && visit.cc != '0' && visit.cc != 'undefined' && visit.cc != 'null' && visit.cc != null) {
              this.previousVitals["cc"].value = visit.cc
              this.previousVitals["cc"].date = visit.date
            }
            if (visit.bpSys && visit.bpSys != '0' && visit.bpSys != 'undefined' && visit.bpSys != 'null' && visit.bpSys != undefined && visit.bpSys != null) {
              this.previousVitals["bpSys"].value = visit.bpSys
              this.previousVitals["bpSys"].date = visit.date
            }
            if (visit.bpDia && visit.bpDia != '0' && visit.bpDia != 'undefined' && visit.bpDia != 'null' && visit.bpDia != null) {
              this.previousVitals["bpDia"].value = visit.bpDia
              this.previousVitals["bpDia"].date = visit.date
            }
            if (visit.Pulse && visit.Pulse != '0' && visit.Pulse != 'undefined' && visit.Pulse != 'null' && visit.Pulse != null) {
              this.previousVitals["Pulse"].value = visit.Pulse
              this.previousVitals["Pulse"].date = visit.date
            }
            if (visit.Heartrate && visit.Heartrate != '0' && visit.Heartrate != 'undefined' && visit.Heartrate != 'null' && visit.Heartrate != null) {
              this.previousVitals["Heartrate"].value = visit.Heartrate
              this.previousVitals["Heartrate"].date = visit.date
            }
            if (visit.spo2 && visit.spo2 != '0' && visit.spo2 != 'undefined' && visit.spo2 != null && visit.spo2 != 'null') {
              this.previousVitals["spo2"].value = visit.spo2
              this.previousVitals["spo2"].date = visit.date
            }
            if (visit.lmp && visit.lmp != '0' && visit.lmp != 'undefined' && visit.lmp != null && visit.lmp != 'null') {
              console.log("previewVitals visit.lmp",visit.lmp);
              console.log("previewVitals visit.date",visit.date);

             var formatedLMP= moment(visit.lmp).format("DD/MM/YYYY");
             console.log("previewVitals formatedLMP",formatedLMP);
             
              this.previousVitals["lmp"].value = formatedLMP;
              this.previousVitals["lmp"].date = visit.date;
            }
            if (visit.Bloodsugar_F && visit.Bloodsugar_F != '0' && visit.Bloodsugar_F != 'undefined' && visit.Bloodsugar_F != null && visit.Bloodsugar_F != 'null') {
              this.previousVitals["Bloodsugar_F"].value = visit.Bloodsugar_F
              this.previousVitals["Bloodsugar_F"].date = visit.date
            }
            if (visit.BloodSugarRandom && visit.BloodSugarRandom != '0' && visit.BloodSugarRandom != 'undefined' && visit.BloodSugarRandom != null && visit.BloodSugarRandom != 'null') {
              this.previousVitals["BloodSugarRandom"].value = visit.BloodSugarRandom
              this.previousVitals["BloodSugarRandom"].date = visit.date
            }
            if (visit.Bloodsugar_PP && visit.Bloodsugar_PP != '0' && visit.Bloodsugar_PP != 'undefined' && visit.Bloodsugar_PP != null && visit.Bloodsugar_PP != 'null') {
              this.previousVitals["Bloodsugar_PP"].value = visit.Bloodsugar_PP
              this.previousVitals["Bloodsugar_PP"].date = visit.date
            }
            if (visit.Bloodhaemoglobin && visit.Bloodhaemoglobin != '0' && visit.Bloodhaemoglobin != 'undefined' && visit.Bloodhaemoglobin != null && visit.Bloodhaemoglobin != 'null') {
              this.previousVitals["Bloodhaemoglobin"].value = visit.Bloodhaemoglobin
              this.previousVitals["Bloodhaemoglobin"].date = visit.date
            }
            if (visit.Chol_HDL && visit.Chol_HDL != '0' && visit.Chol_HDL != 'undefined' && visit.Chol_HDL != null && visit.Chol_HDL != 'null') {
              this.previousVitals["Chol_HDL"].value = visit.Chol_HDL
              this.previousVitals["Chol_HDL"].date = visit.date
            }
            if (visit.Chol_LDL && visit.Chol_LDL != '0' && visit.Chol_LDL != 'undefined' && visit.Chol_LDL != null && visit.Chol_LDL != 'null') {
              this.previousVitals["Chol_LDL"].value = visit.Chol_LDL
              this.previousVitals["Chol_LDL"].date = visit.date
            }
            if (visit.Triglycerides_LFT && visit.Triglycerides_LFT != '0' && visit.Triglycerides_LFT != 'undefined' && visit.Triglycerides_LFT != null && visit.Triglycerides_LFT != 'null') {
              this.previousVitals["Triglycerides_LFT"].value = visit.Triglycerides_LFT
              this.previousVitals["Triglycerides_LFT"].date = visit.date
            }
            
            if (visit.TotalCholesterol && visit.TotalCholesterol != '0' && visit.TotalCholesterol != 'undefined' &&  visit.TotalCholesterol != 'null') {
              this.previousVitals["TotalCholesterol"].value = visit.TotalCholesterol
              this.previousVitals["TotalCholesterol"].date = visit.date
            }
            if (visit.Blood_Hba1c && visit.Blood_Hba1c != '0' && visit.Blood_Hba1c != 'undefined' && visit.Blood_Hba1c != null && visit.Blood_Hba1c != 'null') {
              
              this.previousVitals["Blood_Hba1c"].value = visit.Blood_Hba1c
              this.previousVitals["Blood_Hba1c"].date = visit.date
            }
          }
        })
        console.log("previewVitals", this.previousVitals.lmp)

        
        

        //if new visit
        if (this.PassedVisit == undefined) {
          this.isEditFlag = false
          this.addNewVitalsLink()
        } else {
         


          this.visits.map((element:any, index:any) => {
            if (element._id === "synthetic_record") {
              console.log("log in synthetic_record1");

              this.visits.splice(index, 1);
            }
            console.log("ele get patientInfo", element)
            if (element.lmp === "0000-00-00" || isNaN(Date.parse(element.lmp))) {
              element.lmp = null;
            }
            console.log("ele get patientInfo after filter", element)

            if (!this.PassedVisit && new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {

              console.log("ele get patientInfo", element)
             

            }

            if (this.PassedVisit._id == element._id) {
              this.isEditFlag = true
              console.log("call from addvital get patient");
              this.onSelectVisit(element, 1)
            }
          });
        }


        console.log("")

        // this.getPatientAgeCategory();
      } else {
        alert('Something went wrong')
      }
    });
    setTimeout(() => {
      // this.getPatientsVisitInfoFN();
    }, 500)


    // setTimeout(()=>{
    //   this.addNewVitalsLink(0)
    // },500)

  }




//   }
hasValidData = (element: any): boolean => {
  // Define keys to check (you can expand this as needed)
  const keysToCheck = [
    "BloodSugarRandom",
    "Blood_Hba1c",
    "Bloodhaemoglobin",
    "Bloodsugar_F",
    "Bloodsugar_PP",
    "Chol_HDL",
    "Chol_LDL",
    "Heartrate",
    "Pulse",
    "Temp",
    "TotalCholesterol",
    "Triglycerides_LFT",
    'weight',
    'height',
    
  ];

  // Return true if any key has valid data
  return keysToCheck.some((key) => {
    const value = element[key];
    return value !== null && value !== undefined && value !== "null" && value !== "";
  });
};

saveVisit() {
  if(this.selectedVisit.lmp && this.selectedVisit.lmp.includes("GMT")){
    var lmpDate: any = this.reverseDate(this.selectedVisit.lmp);
    lmpDate = moment(new Date(this.parseDate(lmpDate))).format("YYYY-MM-DD");
    this.selectedVisit.lmp=lmpDate
   }
  if (!this.selectedVisitdate) {

  }

console.log("ASwwin SaveVisit() -==== !", this.selectedVisit)
console.log("abcdefg",this.patientData, Number(this.patientId) );

  
    let recordData = {
      patientId: this.patientData?.id ? this.patientData?.id : Number(this.patientId),
      dob: this.patientData?.DOB,
      visit: this.selectedVisit,

    };
    console.log("Jan23 record Data",recordData)
    var selectedVisitdate=this.reverseDate(this.selectedVisitdate)
    recordData.visit.date = moment(new Date(this.parseDate(selectedVisitdate))).format("YYYY-MM-DD HH:mm:ss")
    this.patientService.savePatientVisit(recordData, this.patientData?._id).subscribe((response:any) => {
      if (response) {
       console.log("Jan23 saveVisit on Save visit",response)
      }
      this.refreshservice.vitalsRefreshed();
   
    // this.getPatientsVisitInfoFN();
    
   
      
    })


  
}

  getPatientsVisitInfoFN() {

    //clear all past data of this comp[oenent for fresh reload]
    this.birthVisit = null;
    this.latestVisitWithValues = "";
    this.allVisits = [];
    /// this.selectedVisit = null;

    //now call api for ffresh data
    var patientID = this.patientId;
    console.log("KKKKKKKKK", this.patientData, patientID)
    this.patientService.getPatientsVisitInfo(patientID).subscribe((response:any) => {
      //  this.showLoading = false;
      let flattenedVisitList:any[] = []
      if (response) {

        console.log('TRUE', response)
        const visits = response ? response : {};
        const lastVisit = visits[visits.length - 1] ? visits[visits.length - 1] : '';
        if (this.isPediatric) {


          this.birthVisit = lastVisit.data[lastVisit.data.length - 1] ? lastVisit.data[lastVisit.data.length - 1] : '';
          // flattenedVisitList.push(lastVisit.data)
          //  this.visitsForVisitScreen = lastVisit;
          console.log('hmm', this.birthVisit)
        }
        if (lastVisit && lastVisit.data.length === 1) {
          visits.pop();

        }

        var arr = [];
        for (let index = 0; index < visits.length; index++) {
          const element = visits[index];

          for (let j = 0; j < element.data.length; j++) {
            const elementForMonth = element.data[j];

            if (new Date().getTime() < new Date(elementForMonth.date).getTime()) {
              //commenting here - condition unknown
              // element.data.splice(j, 1);
            }

          }

        }
        if (visits.length) {
          this.latestVisitWithValues = '';
          var visit = visits[0].data[0];
          var keys = Object.keys(visit);
          var strToShowNonNullValues = '';
          for (let m = 0; m < keys.length; m++) {
            const elementKey = keys[m];

            if (visit[elementKey] && visit[elementKey] !== '0' && visit[elementKey] !== 0 && visit[elementKey] !== null && visit[elementKey] !== 'null' && visit[elementKey] !== ''
              && elementKey !== '_id' && elementKey !== 'chief_complaints' && elementKey !== 'date'
              && elementKey !== 'addedBy' && elementKey !== 'updatedByName' && elementKey !== 'DoctorId' && elementKey !== 'patientid' && elementKey !== 'formattedDate' && elementKey !== 'newDate' && elementKey !== 'strToShowNonNullValues') {
              strToShowNonNullValues = strToShowNonNullValues + " " + elementKey + ' : ' + visit[elementKey];
            }
          }
          this.latestVisitWithValues = strToShowNonNullValues ? strToShowNonNullValues : '';
        }
        console.log("latesVisist", this.latestVisitWithValues)
        //this.visitsForVisitScreen = visits;


        // this.visitsForVisitScreen.map((monthwiseVisits) => {
        //   flattenedVisitList.push(...monthwiseVisits);
        // });

        response.map((monthwiseVisits:any) => {
          flattenedVisitList.push(...monthwiseVisits.data);
        });

        this.formatVisitsArray(flattenedVisitList)

        console.log('Flattened List', flattenedVisitList)

        this.visitsForVisitScreen = visits;


        //this.addVitals()
      }
    });
  }

  getTooltipText() {

  }



  formatVisitsArray(visits:any) {

    let formattedVisitsArray: any = [];

    visits.map((visit:any) => {


      console.log('VISITI', visit);

      let formattedvisitObject = {
        weight: this.validateField(visit.weight) ? visit.weight : '-',
        height: this.validateField(visit.height) ? visit.height : '-',
        bmi: this.validateField(visit.weight) && this.validateField(visit.height) ? (visit.weight / ((visit.height * visit.height) / 10000)).toFixed(2) : '-',
        cc: this.validateField(visit.cc) ? visit.cc : '-',
        hc: this.validateField(visit.hc) ? visit.hc : '-',
        Temp: this.validateField(visit.Temp) ? visit.Temp : '-',
        spo2: this.validateField(visit.spo2) ? visit.spo2 : '-',
        lmp: new Date(visit.lmp),
        Bloodhaemoglobin: this.validateField(visit.Bloodhaemoglobin) ? visit.Bloodhaemoglobin : '-',
        bpSys: this.validateField(visit.bpSys) ? visit.bpSys : '-',
        bpDia: this.validateField(visit.bpDia) ? visit.bpDia : '-',
        Heartrate: this.validateField(visit.Heartrate) ? visit.Heartrate : '-',
        Pulse: this.validateField(visit.Pulse) ? visit.Pulse : '-',
        BloodSugarRandom: this.validateField(visit.BloodSugarRandom) ? visit.BloodSugarRandom : '-',
        Bloodsugar_F: this.validateField(visit.Bloodsugar_F) ? visit.Bloodsugar_F : '-',
        Bloodsugar_PP: this.validateField(visit.Bloodsugar_PP) ? visit.Bloodsugar_PP : '-',
        Blood_Hba1c: this.validateField(visit.Blood_Hba1c) ? visit.Blood_Hba1c : '-',
        Chol_HDL: this.validateField(visit.Chol_HDL) ? visit.Chol_HDL : '-',
        Chol_LDL: this.validateField(visit.Chol_LDL) ? visit.Chol_LDL : '-',
        TotalCholesterol: this.validateField(visit.TotalCholesterol) ? visit.TotalCholesterol : '-',
        Triglycerides_LFT: this.validateField(visit.Triglycerides_LFT) ? visit.Triglycerides_LFT : '-',
        addedBy: 'doctor',
        date: visit.date,
        time: visit.date,
        _id: visit._id,
        //  ageAtVisit: this.getAge(this.birthVisit?.date ? this.birthVisit.date : this.patientData.DOB, visit.date),
        visitBy: visit.updatedByName ? visit.updatedByName.split(' ')[0] : '',

      };

      formattedVisitsArray.push(formattedvisitObject);


    })
    this.allVisits = formattedVisitsArray;
    console.log("may10", this.allVisits)
    // this.selectedVisit=this.allVisits
    //  this.allVisitsBackup =  formattedVisitsArray;
    this.FilterVisitsEmpty()
  }


  FilterVisitsEmpty = () => {

    let visits:any = this.allVisits;
    let formattedVisits:any[] = [];

    // if (this.toggleFormat) {
    // this.allVisits = this.allVisitsBackup;
    //} else {
    visits.map((visit:any, index:any) => {
      if (this.validateField(visit.weight) || this.validateField(visit.height) ||
        this.validateField(visit.cc) || this.validateField(visit.hc) || this.validateField(visit.Temp) ||
        this.validateField(visit.spo2) || this.validateField(visit.bpSys) || this.validateField(visit.bpDia) ||
        this.validateField(visit.Heartrate) || this.validateField(visit.Pulse) || this.validateField(visit.Bloodhaemoglobin) ||
        this.validateField(visit.BloodSugarRandom) ||
        this.validateField(visit.Bloodsugar_F) || this.validateField(visit.Bloodsugar_PP) ||
        this.validateField(visit.Blood_Hba1c) || this.validateField(visit.Chol_LDL) || this.validateField(visit.Chol_HDL) ||
        this.validateField(visit.TotalCholestrol) || this.validateField(visit.Triglycerides_LFT)
      ) {
        formattedVisits.push(visit)
      }
    });

    this.allVisits = formattedVisits;

    //}


  }
  validateField = (field:any) => {
    if (field && field != '0' && field != '' && field != 'undefined' && field != 'null' && field != '-') {
      return true;
    } else {
      return false;
    }
  }


  getAge(birthDate:any, visitDate:any) {
    birthDate = new Date(birthDate)
    let age = this.util.calculateAgeForVisit(birthDate, visitDate)
    let ageStr = null
    ageStr = age['years'] ? `${age['years']}Y` : null
    // ageStr = ageStr ? age['months'] ? `${ageStr}, ${age['months']}M` : `${ageStr}` : 0;
    ageStr = `${age['years']}y ${age['months']}m ${age['days']}d`


    console.log('AGE STRING:::::', ageStr)




    return ageStr
  }

  closeDialog() {
    this.dialog.closeAll();
  }
  onClickDeleteVisit() {
    let postData: any = {
      visitId: this.selectedVisit._id,
      //  patientId:this.selectedVisit.patientid
    }
    console.log("onClickDeleteVisit", this.selectedVisit.patientid, this.selectedVisit._id);
    
    this.patientService.deleteVisit(this.selectedVisit.patientid, this.selectedVisit._id).subscribe((response:any) => {
      if (response) {
        console.log("onClickDeleteVisit res", response)
      }
    })
    this.refreshservice.vitalsRefreshed();
    this.closeDialog()
    // to load the graph again 
    this.triggerOpenVitalGraphFunction()
    // this.getPatientInfo('Visit');


  }
  triggerOpenVitalGraphFunction() {
    this.refreshservice.triggerOpenVitalGraphFunction();
  }


  onDateChange(event:any) {
    let lmpDate: any = event.value;
    if(lmpDate){
      console.log("lmpDatelmpDate",lmpDate);

      lmpDate = moment(lmpDate).format("YYYY-MM-DD");
      this.selectedVisit.lmp = lmpDate
      console.log("lmpDatelmpDate",lmpDate);

    }else {
      this.selectedVisit.lmp = null
      console.log("lmpDatelmpDate",lmpDate);
      
    }

  }
}
