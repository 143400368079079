import { EditPrescriptionComponent } from './patient/edit-prescription/edit-prescription.component';
import { Routes } from '@angular/router';
import { BasicInformationComponent } from './profile/basic-information/basic-information.component';
import { treatmentComponent } from './patient/treatment/treatment.component';
import { prescriptionsComponent } from './patient/prescriptions/prescriptions.component'
import { CliniciansProfileComponent } from './profile/clinicians-profile/clinicians-profile.component';
import { CliniciansProfileViewComponent } from './profile/clinicians-profile-view/clinicians-profile-view.component';
import { ChangeEmailComponent } from './settings/change-email/change-email.component';
import { VaccinationReportComponent } from './reports/vaccinationreport/vaccinationreport.component';
import { AppDownloadsComponent } from './reports/appdownloads/appdownloads.component';
import { VaccinationsComponent } from './settings/vaccinations/vaccinations.component';
import { ClinicDetailsComponent } from './profile/clinic-details/clinic-details.component'
import { LetterheadDetailsComponent } from './profile/letterhead-details/letterhead-details.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { ChangePhoneComponent } from './settings/change-phone/change-phone.component';
import { AuthGuard } from '../../auth/auth.guard';
import { patientComponent } from './patient/patient/patient.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PatientAuthGuard } from '../../auth/patientAuth.guard';
import { AppointmentsComponent } from './appointments/appointments.component';
import { CanDeactivateGuard } from '../can-deactivate.guard';
import { ViewPrescriptionsComponent } from './viewPrescriptions/viewPrescriptions.component';
import { HomePageComponent } from './homepage/homepage.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { ProfileSummary } from './patient/profile-summary/profile-summary.component';
import { PatientOnboard } from './patient/patient-onboard/patient-onboard.component';
import { PrescriptionpreviewComponent } from './patient/prescriptionpreview/prescriptionpreview.component';
import { DoctorReportsComponentComponent } from './reports/doctor-reports-component/doctor-reports-component.component';
import { QuickPrescriptionComponentComponent } from './patient/quickprescription/quick.prescription.component/quick.prescription.component.component';
import { GraphContainerComponent } from './patient/graph-container/graph-container.component';
import { FavouriteManagementComponent } from './favourite-management/favourite-management.component';
import { NewProfileComponent } from './patient/new-profile/new-profile.component';
import { VaccineInventoryComponent } from './vaccine-inventory/vaccine-inventory.component';
import { VaccineProjectionsComponent } from './vaccine-projections/vaccine-projections.component';
import { DocBillReportComponent } from '../admin-panel/doc-bill-report/doc-bill-report.component';
import { DocSelfReportComponent } from './reports/doc-self-report/doc-self-report.component';





export const profileRoutes: Routes = [
  {
    path: 'profile',
    children: [{
      path: 'BasicInformation',
      component: BasicInformationComponent
    },
    {
      path: 'CliniciansProfile',
      component: CliniciansProfileComponent,
      canActivate: [AuthGuard]
    }]
  }, {
    path: 'signup',
    component: BasicInformationComponent,
  },
  {
    path: 'thankyou',
    component: ThankYouComponent
  },
  {
    path: 'profileSetup/stepOne',
    component: CliniciansProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editProfile/stepOne',
    component: CliniciansProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profileSetup/stepTwo',
    component: ClinicDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editProfile/stepTwo',
    component: ClinicDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profileSetup/stepThree',
    component: LetterheadDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editProfile/stepThree',
    component: LetterheadDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    children: [
      {
        path: 'changeEmail',
        component: ChangeEmailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'changePhone',
        component: ChangePhoneComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vaccinations',
        component: VaccinationsComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'reports',
    children: [
      {
        path: 'appdownloads',
        component: AppDownloadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'billView',
        component: DocSelfReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vaccinationreport',
        component: VaccinationReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vaccineProjections',
        component: VaccineProjectionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'doctorreport',
        component: DoctorReportsComponentComponent,
        canActivate: [AuthGuard]
      },

    ]
  },

  {
    path: 'appointments',
    component: AppointmentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'viewPrescriptions',
    component: ViewPrescriptionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billView',
    component: DocSelfReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'homepage',
    // component: HomePageComponent,
    component: HomeDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profileView',
    component: CliniciansProfileViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'favouriteManagement',
    component: FavouriteManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vaccineInventory',
    component: VaccineInventoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients',
    //
    children: [

      {
        path: 'treatment/view',
        component: treatmentComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'prescriptions/view',
        component: prescriptionsComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'quickprescription/view',
        component: QuickPrescriptionComponentComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'editprescription/view',
        component: EditPrescriptionComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'prescriptions/preview',
        component: PrescriptionpreviewComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: '',
        component: patientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profileSummary/view',
        component: NewProfileComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'graphs',
        component: GraphContainerComponent,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'patientOnboard',
        component: PatientOnboard,
        canActivate: [AuthGuard, PatientAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
];
