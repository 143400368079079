<br>


<div
    style="margin-left: 20px; display: flex; flex: 1; justify-content: space-between; position: relative; padding-right: 16px;">
    <div
        style="height: 70%; width: 30%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin-right: 16px;">
        <div style="width: 90%; height: 100%;">
            <input type="text" placeholder="Search by Brand or Company Name" class="form-control" #searchInput
                [(ngModel)]="searchVaccine" (keyup)="onSearchVaccine($event)"
                style="height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
        </div>

        <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center;">
            <button style="border: 0px; background-color: transparent; outline: none;"><img
                    src="assets/images/search_icon_blue.svg" alt="" /></button>
        </div>
    </div>
    <button (click)="openDialog(addNewBrandInventory,'addNewBrandInventory');removeBrandDetails();"
        class="btn btn-secondary" style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

        <mat-icon [ngClass]="'blue-icon'">
            vaccines
        </mat-icon>

        <span style="margin-left: 4px;">Add New Brand</span>
    </button>
</div>


<div style=" width: 100%; ">
    <div style=" width: 100%; height: 100vh; ">
        <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:allVaccineInventory}">
        </ng-container>
    </div>
</div>


<ng-template #renderImmunization let-value="value">
    <table mat-table [dataSource]="value" style="width: 97%;  margin: 8px 0px 0px 23px;">
        <ng-container>
            <!-- VISIT -->
            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef style="text-align:start !important; font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Brand </th>
                <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 10% !important;"
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">
                    {{element.brandName}}

                </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef style="text-align:start !important ;font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Company
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: start !important; width:20%; "
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">
                    {{element.company}}

                </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Quantity </th>
                <td mat-cell *matCellDef="let element" style="width: 10% !important; text-align: center;"
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">
                    {{element.quantity}}
                </td>
            </ng-container>
            <ng-container matColumnDef="update">
                <th mat-header-cell *matHeaderCellDef style="text-align: center ; font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Update </th>
                <td mat-cell *matCellDef="let element" style="width: 20% !important; text-align: center;"
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">
                    <mat-icon (click)="updateBrand(element)" [ngClass]="'editBarnd'">edit</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef style="text-align: start;font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Delete </th>
                <td mat-cell *matCellDef="let element" style="width: 20% !important; text-align: center;"
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">
                    <div (click)="getDeleteBrandData(element)"
                        style="flex-direction: row;display:flex; width: 15%;  justify-content: center; align-items: center; ">
                        <img style="height: 20px;cursor: pointer;" src="assets/images/delete_red.svg" />
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="text-align: start;font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;"> Action </th>
                <td mat-cell *matCellDef="let element" style="width: 25% !important; text-align: center;"
                    [ngClass]="(element.quantity <=5) ?  'lessQuantity' :'quantityList'">



                    <!-- (click)="getDeleteBrandData(element)" -->
                    <div
                        style="flex-direction: row;display:flex; width: 40%;  justify-content: center; align-items: center; ">

                        <button class="btn btn-secondary viewStock-Info-btn"
                            (click)="getStockInventory(element); openDialog(viewBrandInventory, 'viewBrandInventory')"
                            *ngIf="element && element.brandId"
                            style="margin-top: 6px;  display: flex; justify-content: center; align-items: center; border:none">
                            <span style="margin-left: 4px;">View Stock</span>

                            <mat-icon class="custom-info-icon ml-2" color="primary" aria-hidden="false">info</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsForVaccDue; sticky:true" style="background-color: #eee;">
        </tr>
        <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"
            *matRowDef="let row; columns: displayedColumnsForVaccDue;"></tr>
    </table>
</ng-template>









<ng-template #updateBrandInventory let-modal>

<div style="width: 100%; padding: 20px;">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div style="font-size: 20px;font-weight: 600;">{{updateBrandName}}<br>
            <span style="font-size: 18px;color: #555;">{{updateCompanyName}}</span>
        </div>
        <button type="button" class="close" style="outline: none; border-width: 0px; margin-top: 17px;"
            aria-label="Close" (click)="closeDialog('Cross click')">
            <span aria-hidden="true" style="font-size: 40px;">&times;</span>
        </button>
    </div>
    <div>
        
    <div style="display: flex; justify-content: center;align-items: center;">
        <div style="display: flex;flex-direction: row; width: 30%; justify-content: space-evenly; align-items: center; ">

            <div>
               <button  (click)="decrement();"  color="primary" class="addRmemoveBtn">
                  
                  <!-- <div style="  position: relative;
                  top: 3px;
                  right: 1px;"> -->
                   <mat-icon class="removeIcon" >remove</mat-icon>
                  <!-- </div> -->
           
               </button>
            </div>

               <div class="quantityUi">
                   <div>
                       <input type="text" maxlength="3" class="form-control" #searchInput [(ngModel)]="quantity"
                           (keypress)="validateNumber($event)"
                           style="text-align: center; height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
                   </div>

               </div>

             <!-- <div style="  position: relative;
             top: 3px;
             right: 1px;"> -->
               <button  (click)="increment();" class="addRmemoveBtn" color="primary">
             <div>
               <mat-icon class="removeIcon"  >add</mat-icon>
             </div>
                   </button>
             <!-- </div> -->
           </div>
    </div>

        <div style="margin-top: 20px;">
            <button (click)="BrandUpdate()" class="btn btn-secondary"
                style="width: 30vw; background-color: #5D86CC; color: #fff; margin-top: 6px; display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">

                <span style="margin-left: 4px;">Update Quantity</span>
            </button>
        </div>
    </div>
</div>

</ng-template>



<ng-template #addNewBrandInventory let-modal>

   <div style="width: 100%; padding: 20px;">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h4 style="color:#4687BF ;">Add New Brand to Inventory</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click')">
            <span aria-hidden="true" style="font-size: 30px;">&times;</span>
        </button>
        <!-- <button (click)="closeDialog()" class="cancelUibtn">Close</button> -->

    </div>
    <br>
    <div style=" width: 35vw;">
        <div
            style=" margin-top: 0px; display: flex; flex-direction: row; width: 35vw; border: 1px solid #ccc; border-radius: 6px;">

            <input #searchFavourites placeholder="Start typing brand or company name" id="favinputtextbox" type="text"
                aria-label="Number" matInput [matAutocomplete]="auto" [formControl]="myControlFav"
                [(ngModel)]="selectedNewBrand" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 100%;
                font-size: 16px; z-index: 0;     font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
">
            <mat-autocomplete (optionSelected)='onDropdownSelectNewBrand($event.option.value)' autoActiveFirstOption
                #auto="matAutocomplete">
                <mat-option *ngFor="let option of allBrandName | async" [value]="option">
                    <span>
                        <span style="font-weight: bold; color: #333;">{{option.searchstr}} </span>
                    </span>

                </mat-option>
            </mat-autocomplete>

            <!-- <i class="fa fa-close" (click)="clearFavouriteText()" aria-hidden="true"
                style="color: #bfbebf;padding: 10px; font-size: 18px; cursor: pointer;"></i> -->
        </div>


        <div *ngIf="selectedNewBrand"
            style="display: flex;flex-direction: row; justify-content: space-between; width: 100%; margin-top: 40px;">
            
            <div style="margin-left: 10px;width: 70%; font-size: 20px; color: #333;font-weight: 600;">{{brandName}}<br>
                <span style="font-size: 18px;color: #ccc;"> {{brandCompany}}</span>
            </div>

            <div style="display: flex;flex-direction: row; width: 30%; justify-content: space-evenly; align-items: center; ">

             <div>
                <button  (click)="decrement();"  color="primary" class="addRmemoveBtn">
                   
                   <!-- <div style="  position: relative;
                   top: 3px;
                   right: 1px;"> -->
                    <mat-icon class="removeIcon" >remove</mat-icon>
                   <!-- </div> -->
            
                </button>
             </div>

                <div class="quantityUi">
                    <div>
                        <input type="text" maxlength="3" class="form-control" #searchInput [(ngModel)]="quantity"
                            (keypress)="validateNumber($event)"
                            style="text-align: center; height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
                    </div>

                </div>

              <!-- <div style="  position: relative;
              top: 3px;
              right: 1px;"> -->
                <button  (click)="increment();" class="addRmemoveBtn" color="primary">
              <div>
                <mat-icon class="removeIcon"  >add</mat-icon>
              </div>
                    </button>
              <!-- </div> -->
            </div>

        </div>
        <div style="display: flex;flex-direction: row;;">

            <button *ngIf="selectedNewBrand" (click)="saveBrand()" class="btn btn-secondary"
                style="background-color: #5D86CC; color: #fff; width: 35vw; margin-top: 6px; display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">

                <span style="margin-left: 4px;">Save</span>
            </button>
        </div>
    </div>

   </div>
</ng-template>




<!-- viewBrandInventory modal  -->
<ng-template #viewBrandInventory let-modal>
    <!-- loader for lading data  -->

    
    <div *ngIf="loadingVaccineStock || !dataLoaded; else content"class="data-loader">
        <mat-spinner mode="indeterminate" diameter="70"></mat-spinner>
    </div>
    
    <div>
        <!-- content to render after loading  -->
        
    <ng-template #content>

        <div style="display: flex; flex-direction: row; padding: 16px; justify-content: space-between;">
            <div style="display: flex; flex-direction: row; align-items: center; padding: 10px 10px 10px 10px;">
                <div *ngIf="allVaccineStock && allVaccineStock.length > 0">
                    <h2 class="text-align-left">Stock History Of    {{ allVaccineStock[0]?.brandName }} ({{
                        allVaccineStock[0]?.company }})</h2>
                </div>
            </div>
            <div style="display: flex; flex-direction: row; padding: 16px 0px;">
                <div style="background-color: #eff7ff; display: flex; flex: 1; flex-direction: row; border: 1px dashed blue; border-radius: 8px; padding: 6px 16px; align-items: center; margin-right: 16px;"
                    *ngIf="allVaccineStock && allVaccineStock.length > 0">
                    <div style="font-size: 16px;">Available in Stock: </div>
                    <div style="font-size: 28px; font-weight: bold; color: #4687BF; margin-left: 7.5px;">{{ allVaccineStock[0]?.quantity
                        }}
                    </div>
                </div>
                <div>
                    <button type="button" class="close" style="outline: none; border-width: 0px;margin-top: 10px; "
                        aria-label="Close" (click)="closeDialog('Cross click')">
                        <span aria-hidden="true" style="font-size: 30px;">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div style=" width: 70vw; height: 80vh; overflow-y: auto;padding: 10px 10px 10px 10px;">
            <ng-container *ngIf="allVaccineStock?.length; else noLogsTemplate">
                <!-- Table of Stock -->
                <div style="width: 100%; height: 100%;">
                    <ng-container [ngTemplateOutlet]="renderBrandInfoImmunization"
                        [ngTemplateOutletContext]="{value: allVaccineStock}">
                    </ng-container>
                </div>
            </ng-container>
            <!-- No logs found template -->
            <ng-template #noLogsTemplate>
                <div class="No-logs-found">
                    <h2 style="margin-bottom: 10px;">No Logs Found</h2>
                    <p style="text-align: center; font-size: 18px;">Previous logs were not found for this inventory,
                        please
                        update inventory to maintain logs from now onwards.</p>
                </div>
            </ng-template>
        </div>
        <!-- table of Vaccine Inventory Log  -->
        <ng-template #renderBrandInfoImmunization let-value="value">
            <table mat-table [dataSource]="value">
                <ng-container class="mt-2 table-Containers">
                    <!-- VISIT -->
                    <ng-container matColumnDef="logDate">
                        <th mat-header-cell *matHeaderCellDef style="text-align:start !important;"> Date </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align: start !important; width: 7.5% !important;" [ngClass]="{
                        'lessQuantity': element.quantity <= 5,
                        'quantityList': element.quantity > 5,
                        'lightGreenRow': element.patientName === null
                    }">
                            {{element.updatedAt | date: 'dd/MM/yy'}}
                        </td>
                        u
                        u
                    </ng-container>
                    <ng-container matColumnDef="patient">
                        <th mat-header-cell *matHeaderCellDef style="text-align:start !important ;font-size: 18px;">
                            Patient
                            Name </th>
                        <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 17.5%;"
                            [ngClass]="{
    'text-center': true,
    'lessQuantity': element.quantity <= 5,
    'quantityList': element.quantity > 5,
    'lightGreenRow': element.patientName === null
  }">
                            {{ element.patientName || '--' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Comment </th>
                        <td mat-cell *matCellDef="let element" style="width: 15% !important; text-align: left;"
                            [ngClass]="{
        'lessQuantity': element.quantity <= 5,
        'quantityList': element.quantity > 5,
        'lightGreenRow': element.patientName === null
    }">
                            {{element.comment}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Quantity </th>
                        <td mat-cell *matCellDef="let element" style="width: 15% !important; text-align: center;"
                            [ngClass]="{
                        'lessQuantity': element.quantity <= 5,
                        'quantityList': element.quantity > 5,
                        'lightGreenRow': element.patientName === null
                    }">
                            {{element.quantity}}
                        </td>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForStock; sticky:true"
                    style="background-color: #eee;">
                </tr>
                <tr mat-row [ngClass]="{
                'highlight': selectedRowIndex == element.id,
                'lightGreenRow': element?.patientName === null
            }" *matRowDef="let element; columns: displayedColumnsForStock;">
                </tr>
            </table>
        </ng-template>
    </ng-template>

</div>

</ng-template>

<ng-template #delete_modal >
    <div style="padding: 10px 10px 10px 10px;">
    <h4>Delete {{deleteObj.brandName}} ({{deleteObj.company}})</h4>
    <div style="color: #555; font-size: 16px;">Are you sure you want to delete this vaccine from inventory?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" style="background-color: #eee;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                      color: #444;
                      outline: none;">Cancel</button>

        <button (click)="onClickDeleteBrand()" style="background-color: #f44336;visibility: 
        ;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                outline: none;">Yes, Delete</button>
    </div>
</div>
</ng-template>



<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                    font-weight: bold;
                    padding: 12px 0px 0px 12px;
                    
                    color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>