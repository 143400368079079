import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PatientService } from '../../../services/patient.service'
import moment from 'moment';
import { SeriesOptionsType } from 'highcharts';

@Component({
  selector: 'app-weight-height-graph',
  templateUrl: './weight-height-graph.component.html',
  styleUrls: ['./weight-height-graph.component.scss']
})
export class WeightHeightGraphComponent  implements OnInit {

  @Input() isForPopup!: false;
  chart!: Chart;
  graphSeries:any[] = [];
  xAxisPlotLines:any = null;
  xAxisTitle = '';
  seriesX:any[] = [];
  totalRealWeeks:any[] = [];
  categories:any = null;
  birthWeek:any = 40;
  patient: any = { gender: 'Male' }
  isPreTerm:any = 0;
  height:any = 400;
  width:any = 550;


  constructor(private patientService: PatientService,) { }

  ngOnInit(): void {
    // this.init();

    // return;

    this.patient = JSON.parse(localStorage.getItem('patientData')??'null');

    console.log('Patient Object', this.patient)

    // var printchart = this.chart.ref
    if (this.isForPopup) {
      this.width = 1000;
      this.height = window.innerHeight - 180;
    }

    const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
    const actualDate = moment(this.patient.DOB, PATIENT_DOB_FORMAT); // sept 18 2018
    const expectedDate = moment(this.patient.eDOB, PATIENT_DOB_FORMAT);// oct 15 2018
    const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
    const diffWeeks = actualDate.diff(refDate, "days"); // 253
    const gestationalAge = {
      w: Math.round(Number(diffWeeks / 7)), // 36
      d: diffWeeks % 7 // 1
    };
    this.birthWeek = gestationalAge.w;
    var weeks = this.calculateWeek(new Date(this.patient.DOB).getTime(), new Date(this.patient.eDOB).getTime());
    if (weeks < 17 && weeks >= 13) {
      this.isPreTerm = 2;
    } else if (weeks < 13 && weeks > 2) {
      this.isPreTerm = 1;
    }
    else {
      this.isPreTerm = 0;
    }

    this.getGraphs(null);

  }

  calculateWeek(d1:any, d2:any) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }



  getGraphs(weeks:any) {
    const data = {
      graphType: this.patient.gender == 'Male' ? 'weightHeightBoys' : 'weightHeightGirls',
      patientId: this.patient._id ? this.patient._id : this.patient.id,
      visitId: this.patient.visits[0]._id,
      weeksToShow: weeks,
    };
    this.patientService.getPatientsHeightToWeightGraph(this.patient._id, this.patient.visits[0]._id, data).subscribe(response => {

      this.graphSeries = response.graphData,
        this.xAxisPlotLines = response.xAxisPlotLines,
        this.xAxisTitle = "Gestational Age / Weeks",
        this.seriesX = response.seriesX
      this.getRealAge();


      this.graphSeries.map(ins => {
        ins.type = 'line';
      })

    })
  }


  getRealAge() {
    var realWeeksArray = [];
    var uniqueArray = [];
    var initial = 0;
    var dataArray = [];
    var allGweeksArray = [];
    var labelArray = [];
    if (this.graphSeries.length) {
      dataArray = this.graphSeries[0].data

      for (var i = 0; i < dataArray.length; i++) {
        allGweeksArray.push(dataArray[i][0]);
      }
    }

    for (var j = 0; j <= allGweeksArray[allGweeksArray.length - 1]; j++) {

      realWeeksArray.push(initial++);
    }

    for (let i = 0; i < realWeeksArray.length; i++) {
      if (this.isPreTerm != 0) {
        if (realWeeksArray[i] < (41 - this.birthWeek)) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);
        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }
      else {
        if (realWeeksArray[i] < 5) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);

        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }

    }

    this.totalRealWeeks = realWeeksArray,
      this.categories = labelArray
    this.init();
  }

  getYearAndMonthFromDays(numberOfDays:any) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var yearsDisplay = years > 0 ? years + (years == 1 ? "y" : "y") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    return yearsDisplay + monthsDisplay;
  }
  getWeekAndDaysFromDays(numberOfDays:any) {
    var weeks = Math.floor(numberOfDays / 7);
    var days = numberOfDays % 7;
    var weekDisplay = weeks > 0 ? weeks + (weeks == 1 ? "w" : "w") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";
    return weekDisplay + daysDisplay;
  }
  getMonthAndDaysFromDays(numberOfDays:any) {
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);


    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";

    return monthsDisplay + daysDisplay;
  }


  getConf() {

    let patientInformation = "";
    try {
      if (this.patient && this.isForPopup) {
        patientInformation = `${this.patient.firstName} (${this.patient.gender.split('')[0]} - ${this.patient.age.years}y ${this.patient.age.months}m ${this.patient.age.days}d)`
      }
    } catch (exception) {
      console.log(exception)
    }

    console.log("?>>?>?>?>?>?>?>?>?>?>?>?>?>?>?>?>?>?", JSON.stringify(this.patient), this.birthWeek)
    // let graphColor = this.patient ? (this.patient.gender === 'Male' ? '#CCFFFF' : '#FFCCFF') : '#fff';
    let graphColor = '#fff';
    let tmpXAxis = [];

    tmpXAxis.push({
      plotLines: this.xAxisPlotLines,
      gridLineWidth: 1,
      gridLineColor: 'lightgray',
      tickInterval: 1,
      minorTickInterval: 0.142,
      min: this.isPreTerm == 1 ? 27 : this.isPreTerm == 2 ? 24 : this.birthWeek,
      "opposite": true,
      title: {
        text: patientInformation + '<br/><br/>' + this.xAxisTitle,
        style: {
          fontSize: '15px',
          fontWeight: 'bold'
        }
      },
      labels: {
        style:
        {
          color: "#555555",
          fontSize: "15px",
          enabled: true
        }
      }
    });
    if (this.seriesX.length > 0) {
      tmpXAxis.push({
        "title": {
          "text": 'Age',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold'
          }
        },
        "opposite": false,
        categories: this.categories,
        "min": 0,
        "max": this.seriesX[this.seriesX.length - 1] - this.birthWeek,
      });
    } else {
      tmpXAxis.push({
        "title": {
          "text": ' ',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold',
          },
          "offset": 25
        },
        "opposite": true,
        "labels": {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1
        }
      });
    }
    const conf = {
      chart: {
        type: "line",
        marginRight: 10,
        backgroundColor: graphColor,
        spacingLeft: 0,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        plotBorderColor: '#C0C0C0',
        plotBorderWidth: 1,
        checkboxOffset: 0,
      },
      tooltip: {

        //[AL-GR]
        crosshairs: {
          color: 'red',
          dashStyle: 'solid',

        },
        formatter: function (this: any): string  {
          if (this.series.name == 'Current') {
            return 'Age: <b>' + this.point.extra.age + '</b>' + '<br/>Percentile: <b>' + this.point.extra.percentile + '</b>' + '<br/>' + this.point.extra.value[0].toUpperCase() + this.point.extra.value.slice(1) + ': <b>' + this.y + '</b>' + '<br/>Date: <b>' + this.point.extra.date + '</b>';
          }
          else {
            return "";
          }
        },
        positioner: function () {
          return { x: 80, y: 100 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: tmpXAxis,
      plotOptions: {
        series: {
          states: {
            hover: { enabled: true }, ////[AL-GR] was true
            inactive: {
              opacity: 1,
            }
          },
          lineWidth: 1,
          point: {
            events: {
              click: function () {

              },
              mouseOver: function () {
              //  console.log("LLL:L:L:L:L:L:L:L:LL:LL:", this.series)
              },
              mouseOut: function () {
               // console.log("LLL:L:L:L:L:L:L:L:LL:LL:122122121", this.series)

              }
            }
          }
        },
      },
      yAxis: {
        min: 0,
        gridLineWidth: 1,
        minorTickInterval: 'auto',
        gridLineColor: '#C5EEFA',
        title: {
          text: 'Weight (Kg.)',
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        labels: {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1,
          formatter: function (this: any): string  {
            return this.value;
          }
        }
      },
      legend: {
        enabled: true,
        margin: 0,
        // itemMarginTop: 6,
        // itemMarginBottom: 8,
        itemStyle: {
          fontSize: '12px'
        }
      },
      "navigation": {
        "buttonOptions": {
          "enabled": false
        }
      },
      series: this.graphSeries as SeriesOptionsType[]
    };
    return conf;
  }

  addPoint() {
    if (this.chart) {
      this.chart.addPoint(Math.floor(Math.random() * 10));
    } else {
      alert('init chart, first!');
    }
  }

  init() {
    setTimeout(() => {
      let chart = new Chart(this.getConf());
      // chart.addPoint(4);
      // chart.addPoint(5);
      this.chart = chart;
    }, 600);
  }

}
