import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { openClose } from '../../../animations'
import $ from 'jquery';
import { AuthService } from '../../../services/auth.service'
import { DoctorService } from '../../../services/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { TouchSequence } from 'selenium-webdriver';
// import { NullTemplateVisitor } from '@angular/compiler';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, SharedModule} from 'primeng/api';

@Component({
  selector: 'app-change-phone',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss']
})
export class VaccinationsComponent implements OnInit {
  vaccines: any = []
  show: boolean = false;
  isSave: Boolean = false;
  isEdit: Boolean = true;
  userId: any
  nextOffset:any = null
  offset: any = 0
  limit: any = 20
  type = 'normal'
  updatedVaccines:any[] = []
  editVaccineId:any = null
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
  ) {
    this.userId = this.authService.getUser()
    if (this.userId) {
      this.getVaccines()
    }
  }

  getVaccines() {
    let params = {
      user_id: this.userId,
      type: this.type,
      offset: this.offset,
      limit: this.limit
    }
    this.doctorService.getDoctorVaccines(params).subscribe((response:any) => {
      if (response.success == true) {
        if (response.next_offset != 0) {
          this.vaccines = this.vaccines.concat(response.vaccines)
        }
        this.nextOffset = response.next_offset
      } else {
        this.toastr.error(response.msg)
      }
    })
  }

  loadVaccines(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.nextOffset !== 0) {
        this.getVaccines()
      }
    }
  }

  addToUpdateList(vaccine:any) {
    this.updatedVaccines.push(vaccine)
  }

  updateVaccine(vaccine:any) {
    let params = {
      vaccine: vaccine,
      type: 'normal'
    }
    this.doctorService.updateDoctorVaccines(params).subscribe((response:any) => {
      if (response.success == true) {
        this.editVaccineId = null
        this.toastr.success("Vaccines have been updated successfully")
        this.limit = 5
        this.offset = 0
        this.nextOffset = null
        this.vaccines = []
        this.getVaccines()

      } else {
        this.toastr.error(response.msg)
      }
    })
    this.isSave = false; this.isEdit = true; this.show = !this.show;
  }

  reset() {
    this.confirmationService.confirm({
      message: 'Restoring the default vaccination schedule will replace the existing schedule. The updated information of the schedule (if any) will be lost. Note that, the previous version of the schedule if assigned to any patient will NOT get affected by this update. Please confirm to proceed',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        let params = {
          type: this.type,
          user_id: this.userId
        }
        this.doctorService.resetVaccinationSchedule(params).subscribe((response:any) => {
          if (response.success == true) {

            this.limit = 20
            this.offset = 0
            this.nextOffset = null
            this.vaccines = []
            this.getVaccines()
            // this.updatedVaccines = []
            this.toastr.success("Vaccination schedule has been reset successfully")
          } else {
            this.toastr.error(response.msg)
          }
        })
      },
      reject: () => {

      }
    })



  }

  changeTab(type:any) {
    this.type = type
    this.limit = 20
    this.offset = 0
    this.nextOffset = null
    this.vaccines = []
    this.getVaccines()
  }

  validateNumber(e:any) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.keyCode == 9)
  }

  ngOnInit() {
  }

}
