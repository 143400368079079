import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service'
import { AdminService } from '../../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../services/global.service'
import { UtilService } from '../../../util/utilService'

import { debounce } from 'lodash';

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import moment from 'moment';


@Component({
  selector: 'app-vaccinationreport',
  templateUrl: './vaccinationreport.component.html',
  styleUrls: ['./vaccinationreport.component.scss']
})
export class VaccinationReportComponent implements OnInit {
  @ViewChild('htmlData') htmlData!: ElementRef;

  vaccList: any = []
  search: any
  totalUsers: number
  offset:any
  limit: number
  nextOffset:any = null
  totalPages: number[]
  currentPage: number
  isLoading: Boolean = false
  degreeArray: any = []
  councilsArray: any = []
  totalRecords:any = null
  searchString:any = null
  searchByGender:any = null
  fromDate:any = null
  toDate:any = null
  order:any[] = []
  searchByType:any = null;
  showLoading = false;

  constructor(private userService: UserService, private util: UtilService, private adminService: AdminService, private router: Router, private toastr: ToastrService, private globalService: GlobalService) {
    this.search = {}
    this.totalUsers = this.offset = 0
    this.limit = 10
    this.currentPage = 1
    this.totalPages = [1]
  }

  ngOnInit() {
    this.setDefaultDates();
  }

  setDefaultDates() {
    let today = new Date()
    this.fromDate = this.util.reverseDate(today)
    let pastDate = new Date(today.setMonth(today.getMonth() + 1))
    this.toDate = this.util.reverseDate(pastDate);
    this.getVaccReports();
  }

  loadActivities(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.nextOffset !== 0) {
        this.getVaccReports()
      }
    }
  }

  searchActivities = debounce(() => {
    this.vaccList = []
    this.offset = null
    this.nextOffset = null
    if (this.searchString) {
      this.search.search_string = this.searchString
    } else {
      delete this.search['search_string']
    }
    if (this.searchByType) {
      if (this.searchByType == 'all') {
        delete this.search['search_by_type']
      } else {
        this.search.search_by_type = this.searchByType
      }

    }
    this.search.from_date = this.fromDate ? this.util.parseDate(this.fromDate) : null
    this.search.to_date = this.toDate ? this.util.parseDate(this.toDate) : null



    this.getVaccReports()
  }, 300)

  sort = debounce((model, param, order) => {
    if (model)
      this.order = [[model, param, order]]
    else
      this.order = [[param, order]]

    this.vaccList = []
    this.offset = null
    this.nextOffset = null
    this.getVaccReports()
  }, 300)


  clearFilters() {
    this.search = {}
    this.searchString = null
    this.searchByGender = null
    this.fromDate = null
    this.toDate = null
    this.searchByType = null
    this.setDefaultDates()
    this.getVaccReports()
  }


  closeCalender(e:any, d:any) {

    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  getVaccReports = () => {
    var fromDate:any = this.fromDate ? this.util.parseDate(this.fromDate) : null;
    var toDate:any = this.toDate ? this.util.parseDate(this.toDate) : null;
    if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
      this.toastr.error("Invalid date range selected, please try again with valid date range");
    } else {
      this.showLoading = true;
      let req_vars = {
        fromDate: fromDate,
        toDate: toDate
      }
      this.globalService.showVaccinationReportForDoctor(req_vars).subscribe((response:any) => {
        if (response) {
          this.showLoading = false;
          this.vaccList = response;
        }
      }, (err:any) => {
        this.showLoading = false;
        console.error(err)
      })

    }
  }

  getAge(dob:any) {
    dob = new Date(dob)
    let age = this.util.calculateAge(dob)
    let ageStr = null
    ageStr = age['years'] ? `${age['years']}Y` : null
    ageStr = ageStr ? age['months'] ? `${ageStr}, ${age['months']}M` : `${ageStr}` : 0
    return ageStr
  }

  openPDF() {
    if (this.vaccList.length < 1) {
      this.toastr.warning("No data found");
    } else {
      var arr = [];
      for (let index = 0; index < this.vaccList.length; index++) {
        const element = this.vaccList[index];
        var eachArr = []
        eachArr.push(element.DueDate);
        eachArr.push(element.TakenDate);
        eachArr.push(element.ChildName);
        eachArr.push(element.VaccineName);
        eachArr.push(element.ChildAge);
        eachArr.push(element.PhoneNumber);
        arr.push(eachArr);
      }
      var doc:any = new jsPDF();
      doc.autoTable({
        head: [['Due Date', 'Taken Date', 'Child Name', 'Vaccine Name', 'Child Age', 'Phone Number']],
        body: arr,
        theme: 'grid'
      })
      doc.output('dataurlnewwindow');
    }
  }


  public downloadPDF() {
    if (this.vaccList.length < 1) {
      this.toastr.warning("No data found");
    } else {
      var arr = [];
      for (let index = 0; index < this.vaccList.length; index++) {
        const element = this.vaccList[index];
        var eachArr = []
        eachArr.push(element.DueDate);
        eachArr.push(element.TakenDate);
        eachArr.push(element.ChildName);
        eachArr.push(element.VaccineName);
        eachArr.push(element.ChildAge);
        eachArr.push(element.PhoneNumber);
        arr.push(eachArr);
      }
      let doc:any = new jsPDF();
      doc.autoTable({
        head: [['Due Date', 'Taken Date', 'Child Name', 'Vaccine Name', 'Child Age', 'Phone Number']],
        body: arr,
        theme: 'grid'
      })
      var date = moment().format('YYYY-MM-DD HH:mm:ss');
      var name = 'VaccinationReport' + date;
      doc.save(name + '.pdf');
    }
  }


  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }




}
