import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DoctorService } from '../../services/doctor.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, map, filter, pairwise, startWith } from 'rxjs/operators';
import { debounce } from 'lodash';

@Component({
  selector: 'app-vaccine-inventory',
  templateUrl: './vaccine-inventory.component.html',
  styleUrls: ['./vaccine-inventory.component.scss'],
  providers: [MessageService,]
})
export class VaccineInventoryComponent implements OnInit {

  allVaccineInventory: any = [];
  allVaccineStock: any = [];
  loadingVaccineStock: boolean = false;
  dataLoaded: boolean = false;

  searchVaccine = ""
  selectedNewBrand = ""
  allBrands: any = []
  brandName = "";
  brandCompany = ""
  quantity:any = null
  brandId:any = null
  allBrandName!: Observable<string[]>
  myControlFav = new FormControl();
  updateQuantity:any = null
  updateBrandName = "";
  updateCompanyName = ""
  updateId:any = null
  deleteObj: any = {}
  @ViewChild("updateBrandInventory") updateBrandInventory!:ElementRef;
  @ViewChild("delete_modal") delete_modal!:ElementRef;

  displayedColumnsForVaccDue: string[] = ['brand', 'company', 'quantity', 'update', 'delete', 'view'];
  displayedColumnsForStock: string[] = ['logDate', 'patient', 'comment', 'quantity'];

  constructor(private messageService: MessageService, private doctorService: DoctorService, public dialog: MatDialog) {

  }
  calculateUniqueBrandNames(): any[] {
    const uniqueBrandNamesSet = new Set<string>();

    for (const item of this.allVaccineStock) {
      if (item.brandName) {
        uniqueBrandNamesSet.add(item.brandName);
      }
    }

    // Convert the Set back to an array
    return Array.from(uniqueBrandNamesSet);
  }

  ngOnInit(): void {
    // this.loadingVaccineStock = true;
    this.getVaccineInventory()
    this.getMasterVaccineBrandCombos()
  }
  ngAfterViewInit() {
    this.loadingVaccineStock = false; //this is related to modal data loader 
    this.dataLoaded = true; // Set dataLoaded to true after receiving the response
  }
  removeBrandDetails() {
    this.quantity = "";
    this.selectedNewBrand = "";
    this.brandCompany = "";
    this.brandName = ""

  }
  getMasterVaccineBrandCombos() {
    this.doctorService.getUniqueBrandsForInventory().subscribe((response:any) => {
      if (response) {
        console.log("JUl10", response)
        this.allBrands = response.masterVaccineBrandCombo
        this.allBrands.map((ins: any) => {
          ins['quantity'] = 0
          ins['searchstr'] = ins.name + " " + "(" + ins.company + ")"

        })

      }

      this.allBrandName = this.allBrands.map((ins:any) => { return ins.searchstr });
      console.log("favMarch1", this.allBrandName)

      this.allBrandName = this.myControlFav.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFavourites(value))
      );

      console.log("jul11", this.allBrands)
    })
  }
  private _filterFavourites(value: any): string[] {
    console.log('Value', value)

    let filterValue: any = ''

    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.searchstr.toLowerCase();
    }


    return this.allBrands.filter((option:any) => option.searchstr.toLowerCase().includes(filterValue));
  }

  onDropdownSelectNewBrand = (item:any) => {

    this.selectedNewBrand = item.searchstr
    this.brandName = item.name
    this.brandCompany = item.company
    this.quantity = item.quantity
    this.brandId = item.id
  }

  validateNumber(e:any) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 46) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110)



  }

  getVaccineInventory() {
    this.doctorService.getVaccineInventory().subscribe((response:any) => {
      if (response) {
        this.allVaccineInventory = new MatTableDataSource(response.inventory);
      }
    });
  }


  // code for getting brand details 
  // getStockInventory(data:any){
  //   this.doctorService.getVaccinStockInventory(data).subscribe(response=>{
  //     if(response){
  //      console.log("brand detail modal",response)
  //       this.allVaccineStock = new MatTableDataSource(response.inventory);
  //     }
  //   })
  //   }
  getStockInventory(data: any) {
    this.loadingVaccineStock = true; // Set loading to false in case of an error

    if (!data || !data.brandId) {
      console.error('Data or brandId is undefined');
      return;
    }
    console.log('Received data:', data);
    const requestData = {
      brandId: data.brandId
    };
    this.doctorService.getVaccinStockInventory(requestData).subscribe(
      (response:any) => {
        console.log("Full response from backend:", response);

        this.allVaccineStock = response?.report;

        console.log("checking undefind or not from backend ", this.allVaccineStock);

        this.loadingVaccineStock = false; // Set loading to false after receiving the response
      },
      (error:any) => {
        console.error('Error in HTTP request:', error);
        this.loadingVaccineStock = false;
      }
    );
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

    // Repeatitive work
    this.allVaccineInventory.filter = filterValue;
  }
  onSearchVaccine = (event: Event) => {
    this.applyFilter(this.searchVaccine);
  }
  openDialog(content:any, index:any) {
    //console.log('proper', this.selectedMilestone);
    this.dialog.open(content, { disableClose: true });
  }


  openStockDialog(content:any, index:any) {
    //console.log('proper', this.selectedMilestone);
    this.dialog.open(content, { disableClose: true });
  }
  closeDialog() {
    this.dialog.closeAll()
  }
  increment(quantity:any) {
    this.quantity = this.quantity + 1
  }
  decrement() {
    if (this.quantity == 0) {
      this.quantity = 0

    }
    else {
      this.quantity = this.quantity - 1
    }

  }

  saveBrand = debounce(() => {
    let postData = {
      company: this.brandCompany,
      brandName: this.brandName,
      brandId: this.brandId,
      quantity: this.quantity
    }
    this.doctorService.createOrUpdateVaccineInventory(postData).subscribe((response:any) => {
      if (response) {

        if (response.error) {
          this.messageService.add({ severity: 'custom', detail: "Inventory already exist for this Brand,Please Update if you wish" });
        }


        this.getVaccineInventory();
        this.closeDialog();
      }
    })
  }, 400)

  updateBrand(brand:any) {
    console.log("brand", brand)
    this.updateBrandName = brand.brandName
    this.updateCompanyName = brand.company
    this.updateId = brand.id
    this.quantity = brand.quantity
    this.openDialog(this.updateBrandInventory, 'updateBrandInventory')
  }
  BrandUpdate() {
    let postData = {
      id: this.updateId,
      quantity: this.quantity
    }
    this.doctorService.createOrUpdateVaccineInventory(postData).subscribe((response:any) => {

      if (response) {
        this.getVaccineInventory();
        this.closeDialog();
      }
    })
    this.searchVaccine = ""
  }
  getDeleteBrandData(ele:any) {
    this.deleteObj = ele
    this.openDialog(this.delete_modal, "delete_modal")
  }

  onClickDeleteBrand() {
    let postData = {
      id: this.deleteObj.id
    }
    this.doctorService.deleteVaccineFromInventory(postData).subscribe((response:any) => {
      if (response) {
        this.getVaccineInventory();
        this.closeDialog();
      }
    })
  }
}
