import { AppointmentsComponent } from './../appointments/appointments.component';
import { Component, ElementRef, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { openClose } from '../../animations'
import $ from 'jquery';
import { cloneDeep } from 'lodash'
import { UserService } from '../../services/user.service';
import { DoctorService } from '../../services/doctor.service';
import { PatientService } from "../../services/patient.service";
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../util/utilService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { debounce } from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';
import { AdminService } from '../../services/admin.service';
import { NewOnboardPatientComponent } from '../patient/new-onboard-patient/new-onboard-patient.component';
let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }];
let createPerscription = [{ name: "Blank", selected: true, value: "" }, { name: "Copy", selected: false, value: "" }]


@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss'],
  animations: [openClose],
  providers: [MessageService],
})

export class HomeDashboardComponent implements OnInit, OnDestroy {

  @ViewChild("searchIcon") searchIcon!: ElementRef
  @ViewChild("searchInput") searchInput!: ElementRef;
  @ViewChild('appmtPopUp') appmtPopUp!: ElementRef;
  @ViewChild("todaysBday") todaysBday!:ElementRef;
  @ViewChild("showPrescription_modal") showPrescription_modal!:ElementRef;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild("StoperPop") StoperPop!:ElementRef;
  
  @ViewChild(AppointmentsComponent) appointmentComponentReference!: AppointmentsComponent;
   @Input() docUserId!:any;

  //new variables
  analyticsInfo:any[] = []
  searchPatientData: any = [];
  searchPatientDataOrg: any = [];

   doctorFullName:any=""

  createPescription: any = [];
  searchString: any = ""
  modalRef: any = null
  status: boolean = false;
  user: any = {}
  errors: any = {}
  userId: any
  clinics: any = []
  searchby_name_number: string = ""
  searchby_from_date: any = ""
  searchby_to_date: any = ""
  editMode = false
  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  selectedAppointment:any = null
  appointmentCollection: any = [];
  originalCopy: any = [];
  closeResult!: string;
  selectFieldsArray: any = selectFields;
  users:any[] = []
  usersArray:any[] = []
  number:any = {}
  firstName:any = {}
  lastName:any = {}
  minDate = { year: 1900, month: 1, day: 1 }
  today:any = null
  showLoading = false
  order:any[] = []
  isUserSelectedFromList = false
  isTeleAppointMent = '1'
  patientData: any
  appointmentDataForPresc: any
  billAmount: string = ""
  patientEmail: string = ""
  pathArray: any;
  cases: any;
  selectedIndex:any = null;
  selectedPer: any
  customPerscription = false
  interval: any
  favArray: any[] = []
  selectedFavMed: any[] = []
  selectedComplaints: any
  selectedDiagnosis: any
  selectedGeneralInstructions: any
  selectedDietaryAdvices: any
  nextFollowUp: any
  allMedicines: any = []
  visitId: any
  favArraySelected: any
  prescriptionPreviewArray: any = [];

  doctorId = '';
  doctorStatsCount: object = {}

  mobileNumber: string = ""

  constructor(private modalService: NgbModal,
    private userService: UserService,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private util: UtilService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private patientService: PatientService,
    private messageService: MessageService,
    private adminService: AdminService,
    public dialog: MatDialog,

  ) {
    this.pathArray = [
      {
        id: 1,
        name: "Basic Information",
      },
      {
        id: 2,
        name: "Past History",
      },
      {
        id: 3,
        name: "Vitals",
      },
      {
        id: 4,
        name: "Complaints",
      },
      {
        id: 5,
        name: "Treatment",
      },
      {
        id: 6,
        name: "Investigations",
      },
      {
        id: 7,
        name: "Growth & Milestone",
      },
      {
        id: 8,
        name: "Next Appointment",
      }
    ]
    this.createPescription = createPerscription
  }

  ngOnInit() {
    console.log("Aug28",this.docUserId)
    if(this.docUserId){
      this.userId=this.docUserId
    }
    else{
      this.userId = this.authService.getUser()
    }
    
    this.today = this.util.reverseDate(new Date());
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };


    this.displayCounter(0);
    this.getFavApptsOfDoctor();
    this.getDoctorDetails();
  }

  refreshAnalytics() {
    this.getDoctorsAnalytics();
  }

  shareLinkApp() {
    const postData = {
      mobileNumber: this.mobileNumber,
    }
    this.patientService.sendSmsOfAppInvite(postData).subscribe((response:any) => {
      if (response) {

        console.log(response)
        this.modalService.dismissAll();
      }
    })

  }
  validateNumber(e:any) {
    return (
      (e.charCode > 47 && e.charCode < 58) ||
      e.charCode == 46 ||
      e.which == 8 ||
      e.which == 13 ||
      e.keyCode == 9
    );
  }

  getDoctorsAnalytics() {

    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var startDate: string = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
    var endDate: string = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

    let params = {
      doctorids: this.doctorId,
      startDate: [startDate],
      endDate: [endDate],
    }

    this.adminService.admingetDoctorsStats(params).subscribe((response:any) => {
      if (response) {
        let doctorStatsCountObj:any = {
          "Patients Today": response.totalpatients[0].patcount,
          Appointments: response.totalAppointments[0].totalAppointments,
          Prescriptions: response.totalPrescriptions[0].totalPrescriptions,
          Requisitions: response.totalRequisitions[0].totalRequisitions,
          Reports: response.totalReports.totalReports,
          // Customers: response.totalHealthCustomers[0].totalHealthCustomers,
          // totalPatTakenVaccine: response.totalTakenVaccine[0].totalTakenVaccine,
          // totalPatPendingVaccine: response.totalPendingVaccine[0].totalPendingVaccine,
          // healthAppNotInstalledCustomers: response.totalHealthNotInstalledCustomers[0].totalHealthNotInstalledCustomers,
          // totalSendNotifications: response.totalSendNotifications[0].totalSendNotifications
        }

        this.analyticsInfo = [];

        Object.keys(doctorStatsCountObj).map(key => {
          this.analyticsInfo.push({ key: key, value: doctorStatsCountObj[key] })
        })

      } else {
        console.log(response.error);
      }
    });
  }



  routerOnDeactivate() {
    clearInterval(this.interval);
  }
  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.close()
    }
  }

  initPatientView() {
    var req_param = { patient_id: localStorage.getItem('patientId'), doctor_id: this.userId };
    this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.cases = response.patientCase;
        this.open(this.todaysBday, 'todaysBdayPopups')
      }
    });
  }

  displayCounter(refreshComponent:any) {
    console.log("????", refreshComponent);

    this.getAppointments();

  }
  openDialog(content:any, index:any) {

    this.selectedIndex = index;
    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }

  getFavApptsOfDoctor = () => {
    this.patientService.getFavApptsOfDoctor().subscribe((response:any) => {
      if (response) {
        this.favArray = [];
        this.selectedFavMed = [];

        this.favArray = response


        console.log("favArrar", this.favArray);
        if (response.visit) {
          this.selectedComplaints = response.visit.chief_complaints ? response.visit.chief_complaints : '';
          console.log("complaints", this.selectedComplaints)
        }
      }
    })
  }
  onDropdownSelectFavMed = (item:any, key:any) => {
    var data = []
    console.log("favvv", item)
    item.map((ins:any) => {
      if (key.favApptName == ins.favApptName) {
        this.visitId = ins.VisitId
        console.log("visitId", this.visitId)

        this.selectedComplaints = ins.Visit.chief_complaints ? ins.Visit.chief_complaints : '';
        this.selectedDiagnosis = ins.Visit.complaint_diagnosis ? ins.Visit.complaint_diagnosis : '';
        this.selectedGeneralInstructions = ins.Visit.general_instruction ? ins.Visit.general_instruction : '';
        this.selectedDietaryAdvices = ins.Visit.nutrition_diet_advice ? ins.Visit.nutrition_diet_advice : '';
        this.nextFollowUp = ins.Visit.next_vaccination;
        let data = ins.Visit.Medicines;
        console.log("dataa", data)
        data.map((ins:any) => {

          let obj: any = {}
          obj.name = ins.Drug.product_name;
          obj.Drug = ins.Drug;
          obj.dosagePreviewMed = ins.dosage2;
          obj.dosage2 = ins.dosage2;
          obj.duration2 = ins.duration2
          obj.duration3 = ''//this.days;
          obj.duration4 = ins.frequency2;
          obj.finalPreview = ins.dosage2;
          obj.frequency2 = ins.frequency2;
          obj.instructions = ins.instructions;

          let element = {
            whenToTake: ins.duration2,
            medicineName: ins.Drug.product_name,
            dosage2: ins.dosage2,
            instructions: ins.instructions,
            id: ins.id,
          }

          // element.dosage2 = element.whenToTake ? element.dosage2.replace('--', `- ${element.whenToTake} -`) : element.dosage2

          delete element['id'];
          //here
          this.allMedicines.push(obj);
          this.prescriptionPreviewArray.push(element);
        });

      }
    })
  }

  receiveMessage($event:any) {
    this.patientData = $event
    this.getPrescription(this.patientData);
    console.log("patientdDta", this.patientData)
  }

  openPrescriptions() {
    if (this.selectedPer == "Copy") {

      this.previewPrescriptionPDF()
    }
    else {
      localStorage.setItem('patientData', JSON.stringify(this.patientData));
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientData.id } });
    }
  }

  getPrescription(patientData:any) {
    this.showLoading = true;

    var obj = {
      "patientId": patientData.id,
      "selectfield": [] as string[],
    }

    this.patientService.getPrescriptions(obj).subscribe((response:any) => {

      if (response) {
        console.log("Prescription response", response);
        if (response.appointment && response.appointment.prescriptionPDFURL != null) {
          this.showLoading = false;

          //  CHANGE HAS PRESCRIPTION HERE !!!!!
          this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { patientId: this.patientData.id } });
          //has prescription.
        } else {

          //no prescription

          this.showLoading = false;
          this.openDialog(this.showPrescription_modal, 0)

        }
      }
    });
  }
  previewPrescriptionPDF = () => {

    console.log("next", this.nextFollowUp)


    var chief_complaints = this.selectedComplaints
    //  var on_examination = this.selectedOnExamination
    var complaint_diagnosis = this.selectedDiagnosis
    var general_instruction = this.selectedGeneralInstructions;
    var nutrition_diet_advice = this.selectedDietaryAdvices

    let obj: any = {};
    var patient: any = {};
    var Drug: any = {}

    patient.first_name = this.patientData.User.first_name;
    patient.last_name = this.patientData.User.last_name;
    patient.gender = this.patientData.User.gender;
    patient.phone_number = this.patientData.User.phone_number;
    patient.age = '';
    patient.weight = 'N/A';
    patient.height = 'N/A';
    console.log("patiMru", patient)
    obj = {
      "visit_id": null,
      "clinic_id": null,
      "read_only": false,
      "date": '',
      "appointmentId": null,
      "action": "preview",
      "patientId": this.patientData.id,
      "patient": patient,
      "medicines": this.allMedicines, //pending
      "general_instruction": general_instruction,
      "covidQues": "",
      "suggested_investigation": '',
      "chief_complaints": chief_complaints,
      "next_vaccination": this.nextFollowUp,

      "expirationDate": '',
      "on_examination": '',
      "complaint_diagnosis": complaint_diagnosis,
      "savePrescriptionParams": {},
      //  "printingFields":this.printingFields,
      "deletedMedicines": '',//pending
      'newVersion': true,
      "medicinePreview": '',//pending
      "language": '',
      "nutrition_diet_advice": nutrition_diet_advice,
      "templateName": '',
    }
    this.allMedicines.map((ins:any) => {
      // ins.dosage2 = ins.dosage2.replaceAll(',', ' - ')
      ins.dosage2 = ins.dosage2.split(",").join(" - ");

    })
    let savePrescriptionParams = {
      visit: {
        complaint_diagnosis: obj.complaint_diagnosis,
        general_instruction: obj.general_instruction,
        chief_complaints: obj.chief_complaints,
        next_vaccination: obj.next_vaccination,
        suggested_investigation: obj.suggested_investigation,
       covidQues: null as string | null,
        expirationDate: obj.expirationDate,
        nutrition_diet_advice: obj.nutrition_diet_advice,
      },
      patient_id: obj.patientId,
      visit_id: obj.visit_id,
      medicine_list: this.allMedicines,
      deleted_medicines: obj.deletedMedicines,
    }
    obj.savePrescriptionParams = savePrescriptionParams;
    var arr = this.selectFieldsArray.filter((field:any) => { if (field.selected) { return field } });
    arr = arr.map((field:any) => { return field.value });
    obj.printingFields = arr;
    console.log("RETRO>>>>>>", JSON.stringify(obj));




    this.patientService.printPrescriptionVersion2(obj).subscribe((response:any) => {
      if (response) {

        console.log('PRESCRIPTION PDF Version 2', response)
        let url = response.url;
        //window.open(url);
        sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));
        this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { prescrip_url: url, patientId: this.patientData.id } });
      } else {

      }
    });


  }
  onChange(index:any) {
    var caseObj = this.cases[index]
    localStorage.setItem("viewCaseId", caseObj.id);
    localStorage.setItem("viewVisitId", caseObj.Visits[0].id);

    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
    // this.getVisits(caseObj.id, localStorage.getItem('patientId'));
  }
  goToLink(path:any, d:any) {
    console.log(path);

    if (path.id === 1) {
      this.router.navigate([`/doctor/patients/patientBasicInfo/view`])
    } else if (path.id === 2) {
      this.router.navigate([`/doctor/patients/pastHistory/view`])
    } else if (path.id === 3) {
      this.router.navigate([`/doctor/patients/vitals/view`]);
    } else if (path.id === 4) {
      this.router.navigate([`/doctor/patients/complaints/view`])
    } else if (path.id === 5) {
      this.router.navigate([`/doctor/patients/treatment/view`])
    } else if (path.id === 6) {
      this.router.navigate([`/doctor/patients/investigations/view`])
    } else if (path.id === 7) {
      this.router.navigate([`/doctor/patients/growthnutrition/view`])
    } else if (path.id === 8) {
      this.router.navigate([`/doctor/patients/nextAppointment/view`])
    }

    // if (d) {
    //   d("close modal");
    // }
  }
  selectPrescriptionValue(index:any) {
    let array = this.createPescription;
    array.map((ins:any, ind:any) => {
      if (ind == index) {
        array[index].selected = true;


        this.selectedPer = ins.name;
        if (this.selectedPer == "Blank") {
          this.customPerscription = false
        }
        else {
          this.customPerscription = true
        }
        // this.daysWeeks='Weeks'
      } else {
        ins.selected = false;
        //this.daysWeeks='weeks'
        // this.daysWeeks='Weeks'
      }

    });

    console.log("selectePer", this.createPescription)
  }

  initAppointment() {
    var from = new Date();
    from.setMinutes(from.getMinutes() + 30);
    var to = new Date();
    to.setMinutes(to.getMinutes() + 40);
    this.user = {}
    this.number = {}
    this.editMode = false
    this.appmtPopUp.nativeElement.click();
    var retrievedPatientObject:any = localStorage.getItem('patientData');
    this.patientData = JSON.parse(retrievedPatientObject);
    this.user.mobile_number = `+${this.patientData.User.phone_number.substring(0, 2)} ${this.patientData.User.phone_number.substring(2)} ${this.patientData.User.first_name} ${this.patientData.User.last_name}`
    this.user.dob = this.patientData.User.dob ? this.util.reverseDate(this.patientData.User.dob) : null
    this.user.gender = this.patientData.User.gender
    this.user.patientId = this.patientData.id
    this.user.appointment_from = from;
    this.user.appointment_to = to;
    this.user.appointment_date = "";
  }


  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }
  clickEvent() {
    this.status = !this.status;
    if (this.status) {
      this.searchInput.nativeElement.focus();
    }
  }

  getUsers(number:any) {
    if (number.length > 3) {
      this.errors['mobile_number'] = ""
      this.userService.searchUsers(number).subscribe((response:any) => {
        if (response.length) {
          response.forEach((user:any) => {
            user.label = `+${user.contactNo.substring(0, 2)} ${user.contactNo.substring(2)} ${user.firstName} ${user.lastName}`
          });
          this.users = response;
          this.usersArray = this.users
        } else {
          this.users = []
          this.usersArray = this.users
        }
      })
    }
    else {
      this.errors['mobile_number'] = "Please enter atleast 4 digits"
    }
  }

  searchUsers(e:any, type:any) {
    this.users = this.filterUsers(e.query, this.usersArray, type)
  }

  filterUsers(query:any, list:any, type:any) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let user = list[i];
      if (user[type].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }
    return filtered
  }

  openQues(appointmentData:any) {
    this.billAmount = ""
    this.patientEmail = ""
    this.appointmentDataForPresc = appointmentData;
  }

  submitform() {
    let form = document.forms.namedItem("form1") as HTMLFormElement;
    let answers = "[COVID] Age of patient: " + (form.elements.namedItem("age") as HTMLInputElement).value;
    answers += " || Symptoms: ";

    for (let i = 1; i <= 9; i++) {
      let symptomInput = form.elements.namedItem(`symptoms${i}`) as HTMLInputElement;
      if (symptomInput && symptomInput.checked) {
        answers += (i === 1 ? "" : ", ") + symptomInput.value;
      }
    }

    answers += " || Conditions: ";

    for (let i = 1; i <= 10; i++) {
      let conditionInput = form.elements.namedItem(`conditions${i}`) as HTMLInputElement;
      if (conditionInput && conditionInput.checked) {
        answers += (i === 1 ? "" : ", ") + conditionInput.value;
      }
    }

    answers += " || International Travel: " + (form.elements.namedItem("international") as HTMLInputElement).value;
    answers += " || Infected area visited: " + (form.elements.namedItem("area") as HTMLInputElement).value;
    answers += " || Exposure to COVID-19: " + (form.elements.namedItem("exposure") as HTMLInputElement).value;
    answers += " || Worked in hospital: " + (form.elements.namedItem("works") as HTMLInputElement).value;
    answers += " || Conclusion for COVID-19: " + (form.elements.namedItem("conclusion") as HTMLInputElement).value;
    answers += "[COVID]";

    localStorage.setItem("covidques", answers);
    this.onClickPrescription(this.appointmentDataForPresc, 'covid');
  }

  symptomnoneselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;

    const symptomIds = ["symptoms2", "symptoms3", "symptoms4", "symptoms5", "symptoms6", "symptoms7", "symptoms8", "symptoms9"];

    symptomIds.forEach((id) => {
      const symptomElement = form.elements.namedItem(id) as HTMLInputElement;
      if (symptomElement) {
        symptomElement.checked = false;
      }
    });
  }

  conditionnoneselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;

    const conditionIds = [
      "conditions1",
      "conditions2",
      "conditions3",
      "conditions4",
      "conditions5",
      "conditions6",
      "conditions7",
      "conditions8",
      "conditions9"
    ];

    conditionIds.forEach((id) => {
      const conditionElement = form.elements.namedItem(id) as HTMLInputElement;
      if (conditionElement) {
        conditionElement.checked = false;
      }
    });
  }

  conditionitemselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;
    const conditionElement = form.elements.namedItem("conditions10") as HTMLInputElement;

    if (conditionElement) {
      conditionElement.checked = false;
    }
  }

  symptomitemselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;
    const symptomElement = form.elements.namedItem("symptoms1") as HTMLInputElement;

    if (symptomElement) {
      symptomElement.checked = false;
    }
  }


  selectUser(user:any) {
    this.firstName = user
    this.lastName = user
    this.user.mobile_number = user.contactNo
    this.user.dob = user.DOB ? this.util.reverseDate(user.DOB) : null
    this.user.gender = user.gender
    this.user.patientId = user._id
    this.isUserSelectedFromList = true
  }

  changeTime() {
    let minutes = this.user['appointment_from'].getMinutes() + 10
    let toTime = new Date(this.user['appointment_from'])
    toTime = new Date(toTime.setMinutes(minutes))
    this.user['appointment_to'] = toTime
  }

  onClickPrescription(appointmentData:any, isFrom:any) {
    localStorage.setItem('appointmentData', JSON.stringify(appointmentData.comepleteData));
    if (isFrom != 'covid') {
      localStorage.setItem("covidques", '');
    }
    this.router.navigate(['/doctor/patients/treatment/view'])
  }

  onClickPatientName(isFrom:any, data:any) {
    if (isFrom == 'appointment') {
      localStorage.setItem('patientId', data.comepleteData.PatientId);
      this.searchString = data.fullName;
      this.searchby_from_date = "";
      this.searchby_to_date = "";
      this.searchAppointmentByNameNumber();
    }
    else {
      var retrievedPatientObject:any = localStorage.getItem('patientData');
      this.patientData = JSON.parse(retrievedPatientObject);
      localStorage.setItem('patientId', this.patientData.id);
      this.searchString = this.patientData.User.first_name + " " + this.patientData.User.last_name;
      this.searchby_from_date = "";
      this.searchby_to_date = "";
      localStorage.removeItem('patientData');
      this.searchAppointmentByNameNumber();
    }
  }

  onClickStartCall(appointmentData:any, string:any) {
    this.showLoading = true;
    this.doctorService.startTeleMeeting(appointmentData.comepleteData).subscribe((response:any) => {
      this.showLoading = false;
      if (string != 'fromInvite') {
        window.open(response.startUrl);
      } else {
        this.toastr.success("Invite sent to patient successfully")
      }
    });
  }

  onClickPrescriptionAfterDay(appointmentData:any) {
    if (appointmentData.comepleteData.prescriptionPDFURL && appointmentData.comepleteData.prescriptionPDFURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPDFURL);
    } else if (appointmentData.comepleteData.prescriptionPhotoURL && appointmentData.comepleteData.prescriptionPhotoURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPhotoURL);
    }
    else {
      this.toastr.warning("No prescription was created for this appointment")
    }
  }

  searchAppointmentByNameNumber() {
    if (this.searchString.length > 0) {
      var arr = [];
      for (let index = 0; index < this.originalCopy.length; index++) {
        const element = this.originalCopy[index].comepleteData;
        var appointmentObj:any;
        let d = new Date()
        if (element.Patient.UserId != null && element.Patient.User) {
          if (this.originalCopy[index].fullName.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.phone_number.includes(this.searchString)) {
            var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentObj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
            };
            var visited = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(visited).getTime() > d.getTime()) {
              appointmentObj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentObj.showStartCall = true
            }
            if (element.VisitId == null && new Date(appointmentObj.visitedOn).getTime() > d.getTime()) {
              appointmentObj.showCancel = true;
            }
            if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
              appointmentObj.cancelled = true

            }
            arr.push(appointmentObj)
          }
        }

      }
      this.appointmentCollection = arr;
    } else {
      this.appointmentCollection = this.originalCopy;
    }
  }

  searchAppointmentByDates() {
    var arr = [];
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    var from = this.util.parseDate(this.searchby_from_date);
    var to = this.util.parseDate(this.searchby_to_date);
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);
    for (let index = 0; index < this.originalCopy.length; index++) {
      const element = this.originalCopy[index].comepleteData;
      var appointmentObj:any;
      let d = new Date()
      if (element.Patient.UserId != null && element.Patient.User) {
        if (new Date(element.visit_date).getTime() > new Date(from).getTime() && new Date(element.visit_date).getTime() < new Date(to).getTime()) {
          var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
          var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

          appointmentObj = {
            id: element.id,
            visitedOn: element.visit_date,
            from: element.from,
            to: element.to,
            mobile: element.Patient.User.phone_number,
            name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
            clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
            deleted: element.deleted,
            comepleteData: element,
            clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
            comment: element.comment,
            fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
            status: element.status,
            userId: element.Patient.User.id,
            showStartCall: false,
            zoomStartURL: element.zoomStartURL,
            PatientId: element.PatientId,
            showCancel: false,
            showCovid: false,
          };
          var visited = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
          if (new Date(visited).getTime() > d.getTime()) {
            appointmentObj.showCovid = true;
          }
          if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
            appointmentObj.showStartCall = true
          }
          if (element.VisitId == null && new Date(appointmentObj.visitedOn).getTime() > d.getTime()) {
            appointmentObj.showCancel = true;
          }
          if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
            appointmentObj.cancelled = true

          }
          arr.push(appointmentObj)
        }
      }

    }
    this.appointmentCollection = arr;
  }


  onClick(event:any) {
    if (!this.searchIcon.nativeElement.contains(event.target)) {
      this.status = false
    }
  }
  closeSearch() {
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.searchby_to_date = null;
    this.searchString = '';
    this.getAppointments();
  }
  open(content:any, customClass: any = '') {
    this.user = {}
    this.errors = {}
    this.editMode = false
    this.modalRef = this.modalService.open(content, { size: customClass })
    this.modalRef.result.then((result:any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      this.closeResult = `Dismissed ${reason}`;
    });

  }



  saveAppointment(c:any) {
    if (!this.editMode) {
    
    }
    this.errors = {};
    let date:any;
    let from
    let to
    let h
    let m
    if (this.user['appointment_date']) {

      date = this.util.parseDate(this.user['appointment_date'])
      from = this.user['appointment_from'] ? this.user['appointment_from'] : null
      to = this.user['appointment_to'] ? this.user['appointment_to'] : null
      h = from ? from.getHours() : null
      m = from ? from.getMinutes() : null
      if (from) {
        date.setHours(h)
        date.setMinutes(m)
      }

    }
    console.log(date)
    if (!this.user['appointment_date']) {
      this.errors['appointment_date'] = "Please select appointment date"
    } else if (!this.user['appointment_from']) {
      this.errors['appointment_from'] = "Please select appointment time"
    } else if (date < new Date()) {
      this.errors['appointment_from'] = "Invalid time"
    } else if (!this.user['appointment_to']) {
      this.errors['appointment_to'] = "Please select appointment time"
    } else if (this.user['appointment_to'] <= this.user['appointment_from']) {
      this.errors['appointment_to'] = "Invalid time"
    }
    
    else if (!['isTeleAppointMent']) {
      this.errors['isTeleAppointMent'] = "Please select if its a TeleAppointment"
    } else {
    

      let reqVars = {
        appointment_id: this.user.appointment_id,
        doctor_id: this.userId,
        from: from,
        to: to,
        appointment_date: date,
        clinic_id: this.user['clinic_id'],
        comment: this.user['comment'],
        patientId: this.patientData.id,
        isTeleCall: this.isTeleAppointMent,
      }
      this.showLoading = true
      c('close modal')

      this.doctorService.addNewAppointment(reqVars).subscribe((response:any) => {
        this.showLoading = false
        localStorage.removeItem('patientData')
        this.user = {}
        this.firstName = {}
        this.lastName = {}
        this.number = {}
        this.editMode = false
        // this.sort(null, 'id', 'desc')
        this.getAppointments()
        this.toastr.success(response.successMessage)
        this.editMode = false
      })
    }
  }

  submitBillGenerateForm(c:any) {
    if (this.billAmount == "") {
      this.toastr.error('Please enter valid bill amount')
    } else {
      let reqVars = {}
      reqVars =
      {
        patientId: this.appointmentDataForPresc.comepleteData.PatientId,
        appointmentId: this.appointmentDataForPresc.comepleteData.id,
        patientemail: this.patientEmail,
        amount: this.billAmount
      }
      this.showLoading = true
      this.doctorService.generatePaymentInvoice(reqVars).subscribe((response:any) => {
        this.showLoading = false
        c('close modal')
        if (response.success) {
          this.getAppointments()
          this.toastr.success('Generated and sent bill to patient successfully')
        } else {
          this.toastr.error('Something went wrong,please try again')
        }

      })
    }
  }


  updateStatus(event:any, appointmentObj:any) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: 'Are you sure you want to update the status?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        appointmentObj.status = 1
        appointmentObj.user_id = this.userId
        this.doctorService.updateAppointment(appointmentObj).subscribe((response:any) => { })
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
    });

  }

  addLabelAutoComplete(e:any) {
    $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelAutoComplete(e:any) {

    if (e.target.value == "") {
      $(e.target.parentElement.parentElement.parentElement.children[1]).removeClass('animatedLabel')
    } else {
      $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
    }

  }

  selectAppointment(appointmentData:any) {
    this.selectedAppointment = appointmentData
    this.editMode = true
    this.user.dob = appointmentData.comepleteData.Patient.User.dob ? this.util.reverseDate(appointmentData.comepleteData.Patient.User.dob) : null
    this.user.gender = appointmentData.comepleteData.Patient.User.gender
    this.user.appointment_date = this.util.reverseDate(appointmentData.comepleteData.visit_date)
    this.user.appointment_from = new Date(appointmentData.comepleteData.from)
    this.user.appointment_to = new Date(appointmentData.comepleteData.to)
    this.user.appointment_id = appointmentData.id
    this.user.mobile_number = appointmentData.comepleteData.Patient.User.phone_number
    this.user.first_name = appointmentData.comepleteData.Patient.User.first_name
    this.user.last_name = appointmentData.comepleteData.Patient.User.last_name
    this.user.clinic_id = appointmentData.clinicId
    this.user.comment = appointmentData.comment
    this.user.userId = appointmentData.userId
  }

  onClickLeftArrow() {
    var lessOneDate = this.util.parseDate(this.searchby_from_date);
    lessOneDate.setDate(lessOneDate.getDate() - 1);
    this.searchby_from_date = this.searchby_from_date = { year: new Date(lessOneDate).getFullYear(), month: new Date(lessOneDate).getMonth() + 1, day: new Date(lessOneDate).getDate() };;
    this.getAppointments();
  }

  onClickRightArrow() {
    var moreOneDate = this.util.parseDate(this.searchby_from_date);
    moreOneDate.setDate(moreOneDate.getDate() + 1);
    this.searchby_from_date = this.searchby_from_date = { year: new Date(moreOneDate).getFullYear(), month: new Date(moreOneDate).getMonth() + 1, day: new Date(moreOneDate).getDate() };;
    this.getAppointments();
  }

  getAppointments() {
    //this.showLoading = true

    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    let that = this
    let d = new Date()
    that.appointmentCollection = []
    this.showLoading = true

    this.doctorService.getAppointments(reqVars).subscribe((response:any) => {
      var appointmentboj: any = {}
      this.showLoading = false
      if (response.appointments) {
        response.appointments.forEach(function (element:any) {
          if (element.Patient && element.Patient.User && element.prescriptionPDFURL && element.prescriptionPDFURL != null) {
            var age = element.Patient.User.dob ? that.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentboj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
            };
            var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(visited).getTime() > d.getTime()) {
              appointmentboj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentboj.showStartCall = true
            }
            if (element.VisitId == null && new Date(appointmentboj.visitedOn).getTime() > d.getTime()) {
              appointmentboj.showCancel = true;
            }
            if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
              appointmentboj.cancelled = true

            }
            that.appointmentCollection.push(appointmentboj)
          }
        })

        this.originalCopy = this.appointmentCollection;
        localStorage.removeItem("covidques");
        // this.searchAppointmentByDates();
      }
    })

  }

  closeCalender(e:any, d:any) {
    if (this.user['appointment_date']) {
      this.errors['appointment_date'] = "";
    }
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }


  delete(c:any) {
    this.selectedAppointment.comepleteData['deleted'] = 1
    this.doctorService.updateAppointment(this.selectedAppointment.comepleteData).subscribe((response:any) => {
      this.editMode = false
      c('close modal')
      this.getAppointments()
      this.toastr.success("Appointment has been cancelled successfully")
    })

  }

  getDoctorDetails() {
   let req_vars:any={}
    if(this.docUserId){
      req_vars = { userId: this.docUserId }
    }
    else{
      req_vars = { userId: this.userId }
    }
   
    this.doctorService.getDoctorDetails(req_vars).subscribe((response:any) => {
      console.log("KKKKKKKK",response)
      this.doctorFullName=response.doctor.User.first_name+" "+response.doctor.User.last_name

      if(response.doctor.isEnabled==0){
        //event.stopPropagation();
        this.openDialog(this.StoperPop, { disableClose: true,hasBackdrop: false, });
      }
      else{
        
      this.clinics = response.clinics
      this.user.clinic_id = this.clinics[0].id;
      this.doctorId = response.doctor.id;
      this.getDoctorsAnalytics();

    }
    })
  }
  parseDate(dateObject:any) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
  }

  editAppointment() {
    console.log("hello..")
  }

  trimInput(user:any, name:any) {
    user[name] = user[name].trim()
  }


  // new functions 


  keyPressForSearch(event:any) {
    if (event.keyCode === 13) {
      this.searchPatientFromDb();
    }
    else if (this.searchString == "") {
      // this.searchPatientData = this.searchPatientDataOrg
      this.closeSearchTopBar();
    }
  }

  searchPatientFromDb() {
    
    this.appointmentComponentReference.searchInAppointments(this.searchString)

    let patientData = null;
    var data={}

    if(this.searchString.length == 10 && !isNaN(this.searchString)){
      //full number search
      // getPatientsByFullNumberSearch
      this.patientService.getPatientsByFullNumberSearch(this.searchString).subscribe((response:any) => {
        this.formatPatientRecords(response);
        console.log("Full mobile number search response", response);

      });
    } else if (this.searchString.length >= 3 ) {
      //general search
      this.patientService.searchPatientPostall({"searchStr":this.searchString}).subscribe((response:any) => {
        if (response) {
          this.formatPatientRecords(response)
          console.log("Search Patient Response", response);
         
      
        }
      })
    } else if (this.searchString.length < 1) {
      this.searchPatientData = this.searchPatientDataOrg;
    }
  }



  formatPatientRecords(patientData:any) {
    //formatting patient data as per our requirements
    var arr = [];
    for (let index = 0; index < patientData.length; index++) {
      const element = patientData[index];
      var userobj: any = {}
      var objData: any = {}
      var patient: any = {}
      userobj.id = element.userId;
      userobj.phone_number = element.contactNo
      userobj.spousecontactno = element.spousecontactno
      userobj.dob = element.DOB
      var gender: any = ""
      if (element.gender == 'Male') {
        gender = "male"
      }
      else {
        gender = "female"
      }
      userobj.gender = gender
      userobj.first_name = element.firstName
      userobj.last_name = element.lastName
      userobj.middle_name = element.middleName
      userobj.locality=element.locality ? element.locality:""
      if(element.profilePic && element.profilePic.trim() != ''){
        userobj.profile_photo = element.profilePic
      }
      if(element.fcmtoken && element.fcmtoken.length){
        objData['User'] = { ...userobj, Customer: { phone_number: element.contactNo, spousecontactno: element.spousecontactno, fcmtoken: element.fcmtoken } };
      }
      else{
        objData['User'] = { ...userobj, Customer: { phone_number: element.contactNo, spousecontactno: element.spousecontactno} };
      }

     
      objData['id'] = element._id;
      patient['Patient'] = objData;

      if (element.hasOwnProperty('isLinked')) {
        patient['isLinked'] = element.isLinked;
      } else {
        // patient['isLinked'] = false
      }

      arr.push(patient);
    }
    this.searchPatientData = arr;

    console.log("Search Patient Formatted", arr);
  }

  closeSearchTopBar() {
    this.appointmentComponentReference.searchInAppointments('')
    this.searchString = ''
    this.searchPatientData = this.searchPatientDataOrg;
    this.getlocalstoredPatients();
  }

  getlocalstoredPatients() {


    let localacessedpatientsstr:any = localStorage.getItem("localacessedpatients");

    let localacessedpatients: any = [];
    var test: any = []
    var arr: any = []
    if (localacessedpatientsstr) {
      localacessedpatients = JSON.parse(localacessedpatientsstr);
      console.log("dataLOad", localacessedpatients)

      let finalunique:any[] = [];
      if (localacessedpatients && localacessedpatients.length > 0) {

        localacessedpatients.map((o:any) => {
          let alreadyadded = false;
          for (let j = 0; j < finalunique.length; j++) {
            if (finalunique[j].Patient.User.id == o.Patient.User.id) {
              alreadyadded = true;
            }
          }

          if (!alreadyadded) {
            finalunique.push(o);
          }

        })

      }
      this.searchPatientData = finalunique;
      this.searchPatientDataOrg = finalunique;
    }
  }

  createNewPatient() {
    const dialogRef = this.dialog.open(NewOnboardPatientComponent,{
     
      height: 'fit-content',
      width: '75%',
     
    });
  }


  mouseEnter = (index:any) => {
    this.trigger.openMenu()
  }
 

  mouseLeave = (index:any) => {
    this.trigger.closeMenu()
  }

  logout() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  }
}


