<div class="container-ms">
    <div class="headerSection">
        <div style="flex: 1.5; display: flex; flex-direction: column;">
            <div class="heading"> Patient History </div>
            <div class="subheading"> This feature is used show all history for patients.
            </div>
        </div>
    
        <div
            style="display: flex; flex: 1; align-items: center; justify-content: flex-end; position: relative; padding-right: 0px;">
            <div
                style="height: 70%; width: 50%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin-right: 8px;">
                <div style="width: 90%; height: 100%;">
                    <input type="text" placeholder="Search Patient History" class="form-control" #searchInput
                        [(ngModel)]="searchPatientHistory" (keyup)="onSearchPatientHistory($event)"
                        style="height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
                </div>
                <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center;">
                    <button  style="border: 0px; background-color: transparent; outline: none;"><img
                            src="assets/images/search_icon_blue.svg" alt="" /></button>
                </div>
            </div>
        </div>

        <button (click)="getAllDoctorPrescriptions()" ngbTooltip="Refresh Documents" class="refershButton">
            <span style="font-weight: bolder;
              font-size: 24px;">⟲</span></button>




   
    </div>

    <!-- Tabs Section -->
    <mat-tab-group (selectedTabChange)="tabClick($event)" animationDuration="0ms" class="remove-border-bottom">
        <mat-tab>
            <ng-template mat-tab-label>
                All
            </ng-template>

            <div style="height: 88vh; width: 100%; display: flex;flex-direction: row;justify-content: center; ">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; width: 100%;">
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:allDocuments}">
                    </ng-container>
                    <!-- <div *ngIf="!allDocuments.length">
                        <app-empty-state-placeholder [title]="'All Documents'"
                          [subtitle]="'No Documents found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div> -->
                </div>
                <!-- MilestoneByType : All -->
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
               Prescription
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; ">
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:allPrescription}">
                    </ng-container>
                    <div *ngIf="!allPrescription.length">
                        <app-empty-state-placeholder [title]="'Prescription'"
                          [subtitle]="'No Prescription found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div>
                </div>
                <!-- MilestoneByType : All -->
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Reports
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- allReports : All -->
               
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:allReports}">
                    </ng-container>

                    <div *ngIf="!allReports">
                        <app-empty-state-placeholder [title]="' Reports'"
                          [subtitle]="'No reports found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div>
            
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Covid Vaccine Status	
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; width: 100%;
                ">
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:covidAllReports}">
                    </ng-container>
                    <div *ngIf="!covidAllReports.length">
                        <app-empty-state-placeholder [title]="' Covid Reports'"
                          [subtitle]="'No covid Reports found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div>
                </div>
                <!-- MilestoneByType : All -->
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
               Certificate	
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
               
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:allPatientCertificate}">
                    </ng-container>
                    <!-- <div *ngIf="!allPatientCertificate.length">
                        <app-empty-state-placeholder [title]="'  Certificate'"
                          [subtitle]="'No  Certificate found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div> -->
            
                <!-- MilestoneByType : All -->
            </div>
        </mat-tab>


      
        <mat-tab>
            <ng-template mat-tab-label>
                Requisition	
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
               
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:allRequisition}">
                    </ng-container>
                    <!-- <div *ngIf="!allPatientCertificate.length">
                        <app-empty-state-placeholder [title]="'  Certificate'"
                          [subtitle]="'No  Certificate found for selected Patient'"
                          [icon]="'assets/images/placeholder.svg'"></app-empty-state-placeholder>
                      </div> -->
            
                <!-- MilestoneByType : All -->
            </div>
        </mat-tab>

    </mat-tab-group>

    <!-- RESUSABLE TABLE -->
    <ng-template #renderMilestones let-value="value" >
        <table mat-table [dataSource]="value" style="width: 100%;">
            <ng-container>

                <!-- AGE -->
                <ng-container matColumnDef="sr no" style="width:10%">
                    <th class="tableHeading" mat-header-cell *matHeaderCellDef > Sr No</th>
                    <td class="tableData" mat-cell *matCellDef="let element let j = index"  style="text-align: center !important; width:10%"> 
                        {{j+1}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date" style="width:10%">
                    <th class="tableHeading" mat-header-cell *matHeaderCellDef > Date </th>
                    <td class="tableData" mat-cell *matCellDef="let element" style="text-align: start !important; width:10%"> 
                        {{moment(element.documentDate).format('DD/MM/YYYY')}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="document type">
                    <th class="tableHeading" mat-header-cell *matHeaderCellDef > Document Types </th>
                    <td class="tableData"  mat-cell *matCellDef="let element" style="text-align: start !important; width:10%"> 
                        {{element.documentType}}
                    </td>
                </ng-container>
                <!-- ACTION -->
                <ng-container matColumnDef="preview">
                    <th class="tableHeading" mat-header-cell *matHeaderCellDef >View </th>
                    <td class="tableData" mat-cell *matCellDef="let element" style="text-align: start !important;width: 10% !important">
                
                        <div class="actionBtn" 
                        (click)="onClickViewDocuments(element);"> View
                    </div>  
                       
                    </td>
                </ng-container>

                <!-- STATUS -->
                <ng-container matColumnDef="uploaded by" style="width:10%">
                    <th class="tableHeading"  mat-header-cell *matHeaderCellDef > Uploaded By </th>
                    <td class="tableData" mat-cell *matCellDef="let element" style="text-align: start  !important; width: 10% !important;">
                     <span> {{drString}}{{element.uploadedBy}} </span> 
                   
                     </td>
                    
                </ng-container>
             
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-template>

    <ng-template #viewDocumentsModal let-modal>
        <div style="width: 80vw;">
            <div class="modal-header" style="border-bottom: 0px; padding: 16px; width: 76vw;">
                <h4 class="modal-title" id="modal-basic-title">View Document</h4>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="display: flex;  height: 80vh;">
                    <embed [attr.src]="documentPdfURL" type="application/pdf" width="100%" height="100%">
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #viewDischargeModal let-modal>
        <div style="width: 80vw;">
            <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
                <h4 class="modal-title" id="modal-basic-title">View Discharge Summary</h4>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="display: flex;  height: 80vh;">
                    <embed [attr.src]="documentPdfURL" type="application/pdf" width="100%" height="100%">
                </div>
            </div>
        </div>
    </ng-template>



    <ng-template #dischargeModal let-modal>
        <div style="width: 80vw;" >
            <div class="modal-header" style="border-bottom: 0px; padding: 0px 16px 16px 0px; ">
                <h4 class="modal-title" id="modal-basic-title" style="margin-left: 17px;">Discharge Summary</h4>
    
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
                padding-right: 0px;
                color: #333;
                background-color: #fff;
                margin-right: 4px;
                " ngbDropdownToggle>{{templateName}} </button>
    
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                        <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
                            *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
                    </div>
                </div>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
    
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 0px 0px 0px 12px; width: 100%;">
                <div style="display: flex;flex-direction: row; ">
                    <mat-form-field appearance="outline" style="width: 48%;">
                        <mat-label>Ref. By Doctor :</mat-label>
                        <input matInput placeholder="Placeholder" [(ngModel)]="refbyDr">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Address</mat-label>
                        <input matInput placeholder="Placeholder" [(ngModel)]="address">
                     
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Date of Admission</mat-label>
                        <input matInput [matDatepicker]="picker" [readonly]="true"
                            [(ngModel)]="dateOfAdmission">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
    
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Date of Discharge</mat-label>
                        <input matInput [matDatepicker]="picker1" [readonly]="true"
                            [(ngModel)]="dateOfDischarge">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
    
                    </mat-form-field>
                   
                </div>
              
                <div style="display: flex;flex-direction: row; ">
                    <mat-form-field appearance="outline" style="width: 48%;">
                        <mat-label>Wt.OA</mat-label>
                        <input matInput placeholder="Placeholder" [(ngModel)]="wtoa">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Wt.OD</mat-label>
                        <input matInput placeholder="Placeholder"[(ngModel)]=wtod>
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput placeholder="Placeholder"[(ngModel)]=diagnosis>
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>C/O</mat-label>
                        <input matInput placeholder="Placeholder" [(ngModel)]="co">
                     
                      </mat-form-field>
                   
                </div>
                
    
                <div style="display: flex;flex-direction: row; ">
                    <mat-form-field appearance="outline" style="width: 48%;">
                        <mat-label>HOPD</mat-label>
                        <input matInput placeholder="Placeholder"[(ngModel)]="hopd">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Condition on Discharge</mat-label>
                        <input matInput placeholder="Placeholder" [(ngModel)]="dischargeCondition">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>On Discharge Rx </mat-label>
                        <input matInput placeholder="Placeholder"[(ngModel)]="onDischargeRx">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Advise</mat-label>
                        <input matInput placeholder="Placeholder"[(ngModel)]="advise">
                     
                      </mat-form-field>
                   
                </div>
               
                 
              
                <h4 style="font-size: 18px;" > On Examination: </h4>
                <div style="display: flex;flex-direction: row; ">
                  
                    <mat-form-field appearance="outline" style="width: 48%; ">
                        <mat-label>HR</mat-label>
                        <input matInput placeholder="" [(ngModel)]="hr">
                     
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>RR</mat-label>
                        <input matInput placeholder=""[(ngModel)]=rr>
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>BP</mat-label>
                        <input matInput placeholder=""[(ngModel)]="bp">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>SPO2.</mat-label>
                        <input matInput placeholder="" [(ngModel)]="spo2">
                     
                      </mat-form-field>
                </div>
    
    
                <h4 style="font-size: 18px;" >Systemic Ex: </h4>
                <div style="display: flex;flex-direction: row; ">
                  
                    <mat-form-field appearance="outline" style="width: 48%; ">
                        <mat-label>RS.</mat-label>
                        <input matInput placeholder=""[(ngModel)]="rs">
                     
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>CVS</mat-label>
                        <input matInput placeholder=""[(ngModel)]=cvs>
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>CNS</mat-label>
                        <input matInput placeholder=""[(ngModel)]="cns">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>PA</mat-label>
                        <input matInput placeholder="" [(ngModel)]="pa">
                     
                      </mat-form-field>
                </div>
    
                <div style="display: flex;flex-direction: row; ">
                  
                    <mat-form-field appearance="outline" style="width: 48%; ">
                        <mat-label>X-ray Findings</mat-label>
                        <input matInput placeholder="" [(ngModel)]="xrayFindings">
                     
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>CT SCAN</mat-label>
                        <input matInput placeholder="" [(ngModel)]="ctScan">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>MRI</mat-label>
                        <input matInput placeholder="" [(ngModel)]="mri">
                     
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="width: 48%; margin-left: 14px;">
                        <mat-label>Other</mat-label>
                        <input matInput placeholder="" [(ngModel)]="other">
                     
                      </mat-form-field>
                </div>
    
                <div style="display: flex;flex-direction: row; ">
    
                    <mat-form-field appearance="outline" style="width: 50%; ">
                        <mat-label>Lab Invistigation</mat-label>
                        <input matInput placeholder="" [(ngModel)]="labInvistigation">
                    </mat-form-field>
                        <mat-form-field appearance="outline" style="width: 50%; margin-left: 14px; ">
                            <mat-label>Rx Given</mat-label>
                            <input matInput placeholder=""[(ngModel)]="rxGiven">
                        </mat-form-field>
                    </div>
    
                  
                <div style="display: flex;flex-direction: row;justify-content:end;">
                   
                         
                        <button (click)="createDischargeSummary(); closeDialog();"  class="new_btn" style="margin-top: 10px;">Preview</button>
                   
                     
                    
                </div>
            </div>
        </div>
    </ng-template>
   
</div>