import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { DoctorService } from '../../../services/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../services/global.service'
import { AuthService } from '../../../services/auth.service'
import { filter, cloneDeep } from 'lodash'
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'
import { refreshService } from '../../../services/refersh.service';
@Component({
  selector: 'app-clinicians-profile',
  templateUrl: './clinicians-profile-view.component.html',
  styleUrls: ['./clinicians-profile-view.component.scss']
})
export class CliniciansProfileViewComponent implements OnInit {
  rxUiArray = [{ name: "OldRxUI", value:"Old UI",selected: false,  }, { name: "NewRxUI", value:"New UI", selected: false }]
  currentStage: string = '1'
  userId: any = null
  user: any = { doctor: {} }
  specialization: any = null
  degreeArray: any = []
  councilsArray: any = []
  specializations: any = []
  institutes: any = []
  specialitiesArray: any = []
  clinics: any = []
  showLetterhead = false
  primaryClinic = {}
  letterheadClinics:any[] = []
  showLoading = false;
  hideShowFlags: {
    rxTabs: { [key: string]: boolean };
    topNavTabs: { [key: string]: boolean };
} = {
    rxTabs: {},
    topNavTabs: {}
};

rxTabsArray = [
    { name: 'Complaints', value: 'complaints', selected: true },
    { name: 'Vitals', value: 'vitals', selected: true },
    { name: 'On Examinations', value: 'onExaminations', selected: true },
    { name: 'Diagnosis', value: 'diagnosis', selected: true },
    { name: 'Medicines', value: 'medicines', selected: true },
    { name: 'Dietary Advice', value: 'dietaryAdvice', selected: true },
    { name: 'Instruction', value: 'instruction', selected: true }
];

topNavTabsArray = [
  { name: 'Investigation', value: 'investigation', selected: true },
  { name: 'Health Profile', value: 'healthProfile', selected: true },
  { name: 'Documents', value: 'documents', selected: true },
];
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private authService: AuthService, private doctorService: DoctorService, private globalService: GlobalService, private toastr: ToastrService, private router: Router,private refreshservice: refreshService) {
    this.userId = this.authService.getUser()

    let params = {
      user_id: this.userId
    }
    this.showLoading = true
    this.doctorService.getDetails(params).subscribe((response:any) => {
      this.showLoading = false
      if (response.success) {

        this.user = response.user
        let currentYear = new Date().getFullYear()
        let birthYear = new Date(this.user.dob).getFullYear()
        this.user.age = currentYear - birthYear
        this.user.doctor = response.doctor
        this.user.doctor.awards = response.awards
        this.user.doctor.educations = response.educations
        let doc_params = {
          doctor_id: this.user.doctor.id
        }
        this.doctorService.getClinics(doc_params).subscribe((response:any) => {
          if (response.success) {
            this.clinics = response.clinics
            this.clinics.forEach((clinic:any) => {
              clinic.groupedSessions = this.groupSessions(clinic)
              clinic.groupedOnCalls = this.groupOnCalls(clinic)
            });
            this.primaryClinic = this.clinics.filter(function (obj:any) {
              return obj.primary
            })[0]
            if (!this.primaryClinic) {
              this.primaryClinic = this.clinics[0]
            }
            this.letterheadClinics = this.clinics.filter(function (obj:any) {
              return (obj.include_on_letterhead && !obj.primary)
            })
          }
          else {
            this.toastr.error(response.error)
          }
        })
      } else {
        this.toastr.error(response.error)
      }
    })
    this.globalService.getConstants().subscribe((response:any) => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
      this.specializations = response.specializations
      this.specialitiesArray = response.specialities
      this.institutes = response.institutes

    })

  }

  ngOnInit() {
    this.getVersion();
    // this.setStage(this.currentStage)
    var rxUi=localStorage.getItem("RxUi")

    if(rxUi==null){
      this.rxUiArray.map(ins=>{
        if(ins.name=="OldRxUI"){
          ins.selected=true
        }
        else{
          ins.selected=false
        }
      })
    }
  else{
    this.rxUiArray.map(ins=>{
      if(ins.name==rxUi){
        ins.selected=true
      }
      else{
        ins.selected=false
      }
    })
  }
  this.loadFromLocalStorage();
  }
  toggleHideShowRxTab(index: number, checked: boolean) {
    const tabKey = this.rxTabsArray[index].value;
  
    // Allow toggling only for 'On Examinations', 'Dietary Advice', and 'Instruction'
    if (['onExaminations', 'dietaryAdvice', 'instruction'].includes(tabKey)) {
      this.rxTabsArray[index].selected = checked;
      this.hideShowFlags.rxTabs[tabKey] = checked;
      this.saveToLocalStorage();
    }
  }
  
toggleHideShowNavTab(index: number, checked: boolean) {
  this.topNavTabsArray[index].selected = checked;
  const tabKey = this.topNavTabsArray[index].value;
  this.hideShowFlags.topNavTabs[tabKey] = checked;
  this.saveToLocalStorage();
}



saveToLocalStorage() {
  const dataToSave = {
      rxTabs: this.rxTabsArray,
      topNavTabs: this.topNavTabsArray,
  };
  localStorage.setItem('hideShowTabs', JSON.stringify(dataToSave));
}



loadFromLocalStorage() {
  const savedData = localStorage.getItem('hideShowTabs');
  if (savedData) {
      const parsedData = JSON.parse(savedData);
      this.rxTabsArray = parsedData.rxTabs || this.getDefaultRxTabs();
      this.topNavTabsArray = parsedData.topNavTabs || this.getDefaultTopNavTabs();
      
      // Ensure we restore hideShowFlags correctly
      this.hideShowFlags = parsedData.hideShowFlags || {
          rxTabs: this.rxTabsArray.reduce((acc: any, tab: any) => {
              acc[tab.value] = tab.selected;
              return acc;
          }, {}),
          topNavTabs: this.topNavTabsArray.reduce((acc: any, tab: any) => {
              acc[tab.value] = tab.selected;
              return acc;
          }, {})
      };
  } else {
    this.saveToLocalStorage();
      this.rxTabsArray = this.getDefaultRxTabs();
      this.topNavTabsArray = this.getDefaultTopNavTabs();
      this.hideShowFlags = {
          rxTabs: this.rxTabsArray.reduce((acc: any, tab: any) => {
              acc[tab.value] = tab.selected;
              return acc;
          }, {}),
          topNavTabs: this.topNavTabsArray.reduce((acc: any, tab: any) => {
              acc[tab.value] = tab.selected;
              return acc;
          }, {})
      };
  }
}


getDefaultRxTabs() {
    return [
        { name: 'Complaints', value: 'complaints', selected: true },
        { name: 'Vitals', value: 'vitals', selected: true },
        { name: 'On Examinations', value: 'onExaminations', selected: true },
        { name: 'Diagnosis', value: 'diagnosis', selected: true },
        { name: 'Medicines', value: 'medicines', selected: true },
        { name: 'Dietary Advice', value: 'dietaryAdvice', selected: true },
        { name: 'Instruction', value: 'instruction', selected: true }
    ];
}

getDefaultTopNavTabs() {
    return [
      { name: 'Investigation', value: 'investigation', selected: true },
      { name: 'Health Profile', value: 'healthProfile', selected: true },
      { name: 'Documents', value: 'documents', selected: true },
  ];
}
  switchRXUi(e:any,i:any){
    console.log("Feb122222",e)
    let array = this.rxUiArray;
    array.map((ins, ind) => {
      if (ind == i) {
        array[i].selected = true;
       // this.refreshservice.switchRxUI(ins.name)
       localStorage.setItem('RxUi', (ins.name));
        console.log("itemjhdjshdjshd",ins.name)
      }
      else{
        ins.selected = false;
      }
    //  array[ind].selected=false
      })
  }
  getVersion() {
    this.authService.getVersion().subscribe((response:any) => {
      console.log(response, localStorage.getItem("version"));
      let currentwebapp:any = "";
      if (localStorage.getItem("version")) {
        currentwebapp = localStorage.getItem("version");
      }
      if (response.webapp != currentwebapp) {
        console.log("Need to be refreshed");
        localStorage.setItem("version", response.webapp);
        window.location.reload();
      }
    });
  }

  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  setStage(stageNo:any) {
    this.currentStage = stageNo
    let headerText = ""
    switch (this.currentStage) {
      case "3":
        headerText = "DETAILS OF OFFICE PAPERS"
        break;
      case "5":
        headerText = "LET'S GET STARTED"
        break;
      default:
        headerText = ""
        break;
    }
    let topHeading = document.getElementsByClassName('topHeading')[0]
    topHeading.innerHTML = headerText
  }

  groupOnCalls(clinic:any) {
    let forGroupingSession = cloneDeep(clinic.ClinicSchedules)
    let groupedSessions:any = []
    forGroupingSession.map((day:any) => {
      if (day.day != null && day.on_call) {
        day.day = day.day.substring(0, 3)
        groupedSessions.push(day.day)
      }
    })
    return groupedSessions
  }

  groupSessions(clinic:any) {
    let forGroupingSession = cloneDeep(clinic.ClinicSchedules)
    let groupedSessions:any = {}
    forGroupingSession.map((day:any) => {
      if (day.day != null) {
        day.day = day.day.substring(0, 3)
        let key = ""
        if (day.morning_from != null && day.morning_to != null) {
          day.morning_from = day.morning_from.split(':')[0] == '0' ? '12:00 AM' : day.morning_from
          day.morning_to = day.morning_to.split(':')[0] == '0' ? '12:00 AM' : day.morning_to
          key = key + day.morning_from + ' to ' + day.morning_to
        }
        if (day.evening_from != null && day.evening_to != null) {
          if (key != "") {
            key = key + ', '
          }
          day.evening_from = day.evening_from.split(':')[0] == '0' ? '12:00 AM' : day.evening_from
          day.evening_to = day.evening_to.split(':')[0] == '0' ? '12:00 AM' : day.evening_to
          key = key + day.evening_from + ' to ' + day.evening_to
        }
        if (groupedSessions[key] && groupedSessions[key]['days'].length > 0) {
          groupedSessions[key]['days'].push(day)
        } else {
          groupedSessions[key] = {}
          groupedSessions[key]['days'] = []
          groupedSessions[key]['days'].push(day)
        }
      }
    })

    let sessions = Object.keys(groupedSessions).map(key => ({ time: key, value: groupedSessions[key] }))
    return sessions
  }

  previewLetterhead() {
    /*this.primaryClinic['groupedSessions'] = this.groupSessions(this.primaryClinic)
    this.letterheadClinics.forEach(clinic => {
      clinic.groupedSessions = this.groupSessions(clinic)
    });*/

    this.showLetterhead = true
  }
  logout() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  } 
  // Compiled script is not shown while source map is being loaded!

  vaccinationReset(){
    this.doctorService.resetVaccinationSchedule("").subscribe((response:any) => {
      if (response.success == true) {
        alert("Vaccination schedule has been reset successfully")
      }
      else {
        this.toastr.error(response.msg)
      }
    })
  }
}
