import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PatientService } from '../../../services/patient.service';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { refreshService } from '../../../services/refersh.service';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from '../../../services/auth.service';
import { DoctorService } from '../../../services/doctor.service';

@Component({
  selector: 'app-patient-requisition',
  templateUrl: './patient-requisition.component.html',
  styleUrls: ['./patient-requisition.component.css'],
  encapsulation: ViewEncapsulation.None, 
})
export class PatientRequisitionComponent implements OnInit {
  searchTest: any = ""
  requstionTab: any = null
  requisitionPlaceholder = "Start typing to search";
  objectKeys = Object.keys;
  jsonObj: any = {

  }

  packageWiseTests: any = {

  }
  showRequisitionLoading = true;
  emailFlag!: boolean;
  showEditRequitionButton = false;
  @ViewChild("viewRequisitionModal") viewRequisitionModal!:ElementRef;
  @ViewChild("addRequisitionModal") addRequisitionModal!:ElementRef;
  @Input() originalSectionWiseRequisitionTests: any;
  @Input() allRequistionTest: any
  @Input() doctorObj: any
  @Input() patientResponse: any
  favLabName: any = "Choose favourite Lab";
  templateName: any = "Speciality";
  selectedTemplate: any = [];
  requisitionForEdit: any = {};
  currentRequisitionObj: any = {};
  allRequisition:any = [];
  selectedLab:any = null;
  comments = ""
  requisitiontPdfURL: any = '';
  requisitiontPhotoURL:any;
  requisitionURL: any = '';
  selectedTest:any[] = []
  favLabsIds: any = [];
  favLabs: any[] = [];
  waNumber: any
  patientName: any
  userId:any = null
  speciality: any = ""
  specialityValue: any = ""
  searchBox!: string;
  finalTestArray: any = {
    "Package1": [
      {
        "package": "Package1",
        "test": "RT-PCR",
        "selected": false
      },
      {
        "package": "Package1",
        "test": "Free or total triiodothyronine (free T3 or total T3) ",
        "selected": false
      }
    ],
    "Package2": [
      {
        "package": "Package2",
        "test": "RT-PCR",
        "selected": false
      }
    ],
    "Package3": [
      {
        "package": "Package3",
        "test": "Hemoglobin (Hb%)",
        "selected": false
      }
    ]
  }

  sectionWiseRequisitionTests:any = [];
  constructor(private doctorService: DoctorService, private authService: AuthService, public dialog: MatDialog, private modalService: NgbModal, private refreshservice: refreshService, private sanitizer: DomSanitizer, private messageService: MessageService, private patientService: PatientService) {
    this.userId = this.authService.getUser();

  }

  ngOnInit(): void {
    this.getDoctorDetails()
    console.log("allTest", this.allRequistionTest)
    var services = JSON.parse(this.doctorObj.services);
    console.log("docServices", services)
    if (services.template_names) {
      this.templateName = services.template_names[0];
      this.selectedTemplate = services.template_names;
    }
    else {
      this.selectedTemplate.push('default');
    }
    if (services.favLabs) {
      this.favLabsIds = services.favLabs;
      this.getFavLabs();
    }
    this.patientName = this.patientResponse.firstName + "" + (this.patientResponse.middleName ? this.patientResponse.middleName : " ") + " " + this.patientResponse.lastName;
    this.openRequisitionModal();
    this.getAllRequisitions();
    setTimeout(() => {
      this.getAllRequisitionsPackages();
    }, 200);


  }
  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      console.log('Doctor Response Nov17', response.doctor)
      this.speciality = response.doctor.speciality
      console.log("speciality", this.speciality)
      switch (this.speciality) {

        case 'Pediatrics':
          this.specialityValue = "Ped";
          break;

        case 'GP':
          this.specialityValue = "GP";
          break;
      }

    })
  }

  getAllRequisitionsPackages() {
    var finalTestArray: any = []
    this.allRequistionTest.map((testObject:any) => {
      if (testObject.packages) {
        let packageArray:any = testObject.packages.split(",")
        packageArray.map((itr:any) => {
          if (packageArray.includes(itr)) {
            finalTestArray.push({ "package": itr, "test": testObject.testname, selected: false, });
          }
        })
      }
    });
    var data: any[] = []
    var allPackages: any = []
    var finalTest: any = []

    console.log('Mrunalini Test Array', finalTestArray);
    finalTestArray.map((ins:any) => {
      if (this.specialityValue == "" && !ins.package.includes("_")) {
        finalTestArray.splice(ins, 1)
        allPackages.push(ins)
      }
      else {
        if (ins.package.includes(this.specialityValue) || ins.package.includes('Com_')) {
          data.push(ins)

        }
      }

    })
    console.log(allPackages)
    if (this.specialityValue == "") {
      finalTest = allPackages
    }
    else {


      data.map(ins => {

        if (ins.package.includes('_')) {
          ins.package = ins.package.split('_');
          ins.package = ins.package[1]
          finalTest.push(ins)
        }

      })
    }
    console.log("finalTest", finalTest)
    this.finalTestArray = finalTest.reduce(function (prevElement:any, nextElement:any) {

      prevElement[nextElement.package] = prevElement[nextElement.package] || []
      prevElement[nextElement.package].push(nextElement);

      return prevElement;
    }, Object.create(null));
    this.jsonObj = this.finalTestArray
    this.packageWiseTests = this.finalTestArray

    console.log("test", this.jsonObj)


  }

  getSelctedTest(element:any, event:any) {
    console.log("Nov15", event)
    var selectedTest: any[] = []
    if (event.checked == true) {

      for (var key in this.packageWiseTests) {
        var arr = this.packageWiseTests[key];
        for (var i = 0; i < arr.length; i++) {
          var obj:any = arr[i];
          if (element == obj.package) {
            obj.selected = true
            this.selectedTest.push(obj.test)
            // this.selectedTest=selectedTest
          }





        }

      }

    }
    else {
      this.selectedTest = []
      //  this.selectedTest=selectedTest
    }

    console.log("Nov14", this.jsonObj)
  }
  openDialog(content:any) {
    this.dialog.open(content);
  }
  closeDialog() {

    this.dialog.closeAll();
  }
  shareRequisitionModalOpen(content:any) {
    this.dialog.open(content);

  }
  onDropdownSelectLab = (itemstr:any, item:any) => {
    this.favLabName = item.name;
    this.selectedLab = item;
  }
  share() {

    const url = this.requisitionURL;
    let encoded = encodeURI(url);
    let text = "Requisition for" + " " + this.patientName
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)

    var waNo = this.waNumber

    // Opening URL

    window.open(
      "https://web.whatsapp.com/send?phone=" + waNo + "&text=" + encoded,

      '_blank'
    );
  }
  shareWithOther() {

    const url = this.requisitionURL;
    let encoded = encodeURI(url);
    let text = "Requisition for" + " " + this.patientName
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)
    var waNo = this.waNumber

    console.log('Pull Request', "changed the logic")
    window.open(
      `https://web.whatsapp.com/send?text=${text} ${encoded} `,
      '_blank'
    );
  }

  onClickViewRequisition(requisition:any) {

    if (requisition.url) {
      this.requisitiontPhotoURL = ""
      this.requisitiontPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(requisition.url);
    }
    else {
      if (requisition.photoURL && requisition.photoURL != '') {


        this.requisitiontPdfURL = ""
        this.requisitiontPhotoURL = this.sanitizer.bypassSecurityTrustResourceUrl(requisition.photoURL)
      }
    }

    this.requisitionURL = requisition.url;
    this.openDialog(this.viewRequisitionModal);
    // this.isViewRecipt = true;
  }

  tabClick(tab:any) {
    this.searchBox = "";
    this.onSearchChange("", "");
    console.log("tabTEST", tab);
    this.requstionTab = tab.index
    if (this.requstionTab == 1) {
      this.searchTest = ""
      this.requisitionPlaceholder = "Start typing to search Packages"
      let newTestArr = JSON.parse(JSON.stringify(this.originalSectionWiseRequisitionTests));
      this.sectionWiseRequisitionTests = newTestArr;

    }
    else {
      this.requisitionPlaceholder = "Start typing to search "
      this.searchTest = ""
    }
  }
  onSearchChange(searchValue: string, type: string): void {
    var finalTestArray = []
    //logic for search test name inside packages 
    //gypsy
    if (this.requstionTab == 1) {
      if (searchValue == "") {
        this.packageWiseTests = this.jsonObj;

      } else {
        var testArr = [];
        var packageName = ''
        for (var key in this.packageWiseTests) {
          var arr = this.packageWiseTests[key];
          for (var i = 0; i < arr.length; i++) {
            var obj = arr[i];
            if (obj.package.toLowerCase().includes(searchValue.toLowerCase())) {
              packageName = obj.package;
              testArr.push(obj);
            }
            var newArr = []
            for (let j = 0; j < obj.length; j++) {
              var data = obj[j];
              if (data.test.toLowerCase().includes(searchValue.toLowerCase())) {
                newArr.push(data);
              }
            }
            if (newArr.length) {
              data = newArr
              testArr.push(data);
            }
          }
          testArr = testArr.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          });



        }

        let matchedObj:any = {};
        matchedObj[packageName] = testArr;


        this.packageWiseTests = matchedObj;

      }
      // this.sectionWiseRequisitionTests = testArr;


    } else {


      let newTestArr = JSON.parse(JSON.stringify(this.originalSectionWiseRequisitionTests));
      if (searchValue == '') {
        this.sectionWiseRequisitionTests = newTestArr;
      } else {
        var testArr = [];
        for (let index = 0; index < newTestArr.length; index++) {
          var element = newTestArr[index];
          if (element.section.toLowerCase().includes(searchValue.toLowerCase())) {
            testArr.push(element);
          }
          var newArr = []
          for (let j = 0; j < element.data.length; j++) {
            const test = element.data[j];
            if (test.name.toLowerCase().includes(searchValue.toLowerCase())) {
              newArr.push(test);
            }
          }
          if (newArr.length) {
            element.data = newArr
            testArr.push(element);
          }
        }
        testArr = testArr.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });

        this.sectionWiseRequisitionTests = testArr;

      }

    }
  }
  openRequisitionModal = () => {
    // alert('Open')
    this.comments = '';
    this.selectedTest = []
    this.sectionWiseRequisitionTests = JSON.parse(JSON.stringify(this.originalSectionWiseRequisitionTests))

    console.log("sectionwiseTEST", this.sectionWiseRequisitionTests)


  }
  selectPackageTestValue = (element:any) => {
    for (var key in this.packageWiseTests) {
      var arr = this.packageWiseTests[key];
      for (var i = 0; i < arr.length; i++) {
        var obj = arr[i];
        if (element.package == obj.package && element.test == obj.test) {
          //   obj.selected=true
          this.selectedTest.push(element.test)
          // this.selectedTest=selectedTest
        }





      }

    }


  }
  selectModalTestValue = (index1:any, index2:any) => {

    var obj = this.sectionWiseRequisitionTests[index1].data[index2];


    if (!this.selectedTest.includes(obj.name)) {
      this.selectedTest.push(obj.name);
    }

  }
  editRequition() {
    this.currentRequisitionObj = this.requisitionForEdit;
    this.sectionWiseRequisitionTests = JSON.parse(JSON.stringify(this.originalSectionWiseRequisitionTests));
    this.onClickRequisitionDelete();
  }
  onClickDeleteRequisition(reqObj:any) {
    //alert(JSON.stringify(reqObj));
    this.currentRequisitionObj = reqObj;
  }
  onClickRequisitionDelete() {
    let postData = {
      id: this.currentRequisitionObj.id
    }
  
    this.patientService.deleteRequisition(postData).subscribe((response:any) => {
      console.log("delete", response);
      
      // Remove the deleted requisition from the allRequisition array
      this.allRequisition = this.allRequisition.filter((req:any) => req.id !== this.currentRequisitionObj.id);
      // Refresh the requisitions list to ensure the UI is updated
      this.getAllRequisitions();
     });
  }
  removeSelectedTest(item:any) {
    var itemList = this.selectedTest.indexOf(item);
    this.selectedTest.splice(itemList, 1);

    for (let index = 0; index < this.sectionWiseRequisitionTests.length; index++) {
      const element = this.sectionWiseRequisitionTests[index];
      for (let j = 0; j < element.data.length; j++) {
        const test = element.data[j];
        if (item == test.name) {
          test.selected = false;
        }
      }
    }

  }
  getFavLabs() {
    var postData = {
      ids: this.favLabsIds,
    }
    this.patientService.getFavLabs(postData).subscribe((response:any) => {
      if (response) {
        var testArray: any = [];
        this.favLabs = response;

        console.log("FavLab", this.favLabs)
      }
      for (let index = 0; index < this.favLabs.length; index++) {
        const element = this.favLabs[index]
        if (element.waNo.includes('+91')) {
          testArray.push(element)
        }
        else {
          element.waNo = "+91" + element.waNo
          testArray.push(element)
          console.log("waNo", testArray)
        }
      }
      this.favLabs = testArray;
      this.favLabs.map(ins => {
        if (ins.waNo) {
          let wNumbers = ins.waNo;
          this.waNumber = wNumbers
          console.log("wNumbers", this.waNumber)
        }
      })

      console.log("newfavLabs", this.favLabs)
    });
  }
  getAllRequisitions() {

    this.showRequisitionLoading = true;
    var postData = {
      patient_id: this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id,

    }
  
    this.patientService.getAllRequisitions(postData).subscribe((response:any) => {

      if (response) {
        console.log("Feb12",response)

        this.showRequisitionLoading = false;
        for (let index = 0; index < response.requisition.length; index++) {
          const element = response.requisition[index];


          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }
 // Old Code which is reflecting table data on click of refresh Button
        //  var allRequisition = response.requisition;
        //  allRequisition.map(ins=>{
        //   if(ins.tests!='Patient Discharge Summery'){
        //     this.allRequisition.push(ins)
        //   }
        //  })  

 // New Code which is NotReflecting table data on click of refresh Button

        var allRequisition:any = response.requisition;
        // Create a set to keep track of unique entries
        var uniqueEntries:any = new Set(this.allRequisition.map((ins:any) => ins.tests));
        allRequisition.forEach((ins:any) => {
          if (ins.tests !== 'Patient Discharge Summery' && !uniqueEntries.has(ins.tests)) {
            this.allRequisition.push(ins);
            // Add the test to the set to mark it as seen
            uniqueEntries.add(ins.tests);
          }
        });
  
        this.allRequisition.map((ins:any) => {
          if (ins.sharedtolab != null) {
            ins.sharedtolab = typeof ins.sharedtolab === 'string' ? JSON.parse(ins.sharedtolab) : ins.sharedtolab;
            // ins.sharedtolab =ins.sharedtolab ? JSON.parse(ins.sharedtolab):ins.sharedtolab
          }
        })
  
        console.log("Newdata", this.allRequisition);
      }

    })


  }
  onClickRequisitionShare(requisitionURL:any) {

    var postData = {
      patient_id: this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id,
      url: requisitionURL
    }
    this.patientService.shareRequisition(postData).subscribe((response:any) => {
      if (response) {
        // this.modalService.dismissAll();
        console.log("true")
        this.messageService.add({ severity: 'custom', detail: 'Requisition shared to patient successfully ' });

      }
      // },
      //   (error:any) => {
      //     console.log('Requisition share error', error)
      //   }

    });

  }
  sharerequisitionWithFavLab() {
    this.emailFlag = false;
    this.favLabName == "Choose Favourite Lab"

  }

  onClickRequisitionShareToFavLab() {
    this.emailFlag = true;
    if (this.favLabName == "Choose Favourite Lab" || this.selectedLab == null) {
      this.messageService.add({ severity: 'custom', detail: 'Please choose lab before proceeding' });
      // this.share();
    }
    else {
      var postData = {
        lab: this.selectedLab,
        url: this.requisitionURL
      }
      this.patientService.shareRequisitionWithLab(postData).subscribe((response:any) => {
        if (response) {
          this.emailFlag = true;
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'custom', detail: 'Requisition emailed to Lab successfully' });

        }

      },
        (error:any) => {
          console.log('Requisition share error', error)
        }
      );

    }
  }
  closeSelectedData() {
    this.selectedLab = "";
    this.favLabName = "Choose Favourite Lab"
  }
  onDropdownSelectTemplate = (item:any) => {
    this.templateName = item
  }

  printRequisitions(action:any) {
    if (this.templateName == "Speciality") {

      this.messageService.add({ severity: 'custom', detail: 'Please specify speciality before proceeding' });
      //  this.openDialog(this.addRequisitionModal)

    }
    else {
      let testArr = this.selectedTest;
      console.log("Jan6", testArr)
      testArr = testArr.reduce((unique, o) => {
        if (!unique.some((obj:any) => obj == o)) {
          unique.push(o);
        }
        return unique;
      }, []);

      var postData = {
        patient_id: this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id,
        action: action,
        type: 'Requisition',
        tests: testArr.join("| "),
        comments: this.comments,
        "templateName": this.templateName

      }

      console.log("TEST", JSON.stringify(postData));



      if (testArr.length) {
        this.patientService.printRequisitions(postData).subscribe((response:any) => {

          if (response) {
            //  this.modalService.dismissAll();
            this.requisitiontPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
            this.requisitionURL = response.url;
            this.requisitionForEdit = response.requisition;
            if (action === 'preview') {
              this.openDialog(this.viewRequisitionModal);
              this.showEditRequitionButton = true;
            }
            this.getAllRequisitions();
          }
        })
      }
    }
  }

}
