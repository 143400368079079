<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>

<!-- <div style="width: 21%;">
    <app-patient></app-patient>
     <div class="heading" style="margin-left:0px">Appointments<span style="color: #ccc; font-size: 14px;">( 03/15 )</span></div>
  </div> -->


<div style="height: 100%; width: 100%; background-color: #fff; padding: 8px;">

  <!-- Header Section -->
  <div style="display: flex; flex-direction: row; align-items: center;">
    <div *ngIf="isAppointmentEnabled == '1'" class="heading" style="margin-left:0px">Appointments<span
        style="color: grey; font-size: 16px;"> (
        {{appointmentCollection.length}} / {{morningSlots.length+eveningSlots.length}} )</span></div>

    <div *ngIf="isAppointmentEnabled == '2'" class="heading" style="margin-left:0px">Queue<span
        style="color: grey; font-size: 16px;"> (
        {{appointmentCollection.length}} / {{morningSlots.length+eveningSlots.length}} )</span></div>


    <div class="scheduleandAddNew-outer">
      <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender" style="margin-right: 16px;">
        <div class="appointment float-right searchIcon active"
          style="width:150px; border-radius: 7px !important; border-color: #5D86CC;">
          <input id="changeDateForAppoint" [(ngModel)]="searchby_from_date" ngbDatepicker #dfrom="ngbDatepicker"
          (ngModelChange)="onDateSelect($event)" (focus)="dfrom.open()" type="text" class="form-control"
          [attr.placeholder]="searchby_from_date || 'DD/MM/YYYY'" name=""
          (document:click)="closeCalendar($event, dfrom)"
          style="font-size: 18px; font-weight: bold; padding: 8px !important; border-right: 0px solid #ccc !important; border-radius: 7px;"     readonly>
          <img (click)="dfrom.toggle()" style="padding-right: 10px;" class="calendarbg"
            src="assets/images/calendarIcon.svg" alt="" />
        </div>
      </div>
    </div>

    <button (click)="getAppointments(); getCancelledAppointments()" [placement]="'top'" ngbTooltip="Refresh Appointments" style="
                
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 1px solid #5D86CC;
                font-size: 18px;
                margin-top: 8px;
                margin-bottom: 8px;
                margin-right: 36px;
                color: #5D86CC;
                background-color: #fff;
                
              outline: none;">
      <span style="font-weight: bolder;
              font-size: 24px;">⟲</span></button>

  </div>

  <!-- Tabs Section -->
  <mat-tab-group animationDuration="0ms" class="remove-border-bottom">
    <mat-tab>
      <ng-template mat-tab-label>
        Allotted
        <span class="appointmentCountIndicator">{{appointmentCollection.length}}</span>
      </ng-template>
      <div style="height: 75vh; width: 100%;">

        <div *ngFor="let appointmentData of appointmentCollection; let i = index" [attr.data-index]="i">
          <app-appointment-progress-card  [appointmentData]="appointmentData" [i]="i" [isPdfUrl]="appointmentData.comepleteData.prescriptionPhotoURL"
            [isAppointmentEnabled]="isAppointmentEnabled" (openBillReceipt)="openBillReceipt(appointmentData)"
            (onClickAppointmentStatus)="onClickAppointmentStatus(appointmentData)" 
            (onClickDoneAppointment)="onClickDoneAppointment(appointmentData)"
            (onClickPatientInfo)="onClickPatientInfo(appointmentData)"
            (onCancelAppointment)="selectAppointment(appointmentData);openDialog(cancelAppointmentModal, 'cancelAppointmentModal')">
          </app-appointment-progress-card>
        </div>

        <div *ngIf="!appointmentCollection.length">
          <app-empty-state-placeholder [title]="'No Appointments'"
            [subtitle]="'No appointment found for selected date, keyword or category'"
            [icon]="'assets/images/no_appointments.svg'"></app-empty-state-placeholder>
        </div>

      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Arrived
        <span class="appointmentCountIndicator">{{appointmentCollectionArrived.length}}</span>
      </ng-template>

      <div style="height: 75vh; width: 100%;">
        <div *ngFor="let appointmentData of appointmentCollectionArrived; let i = index" [attr.data-index]="i">
          <app-appointment-progress-card [appointmentData]="appointmentData" [i]="i"
            [isAppointmentEnabled]="isAppointmentEnabled" (openBillReceipt)="openBillReceipt(appointmentData)"
            (onClickAppointmentStatus)="onClickAppointmentStatus(appointmentData)"
            (onClickPatientInfo)="onClickPatientInfo(appointmentData)"
            (onCancelAppointment)="selectAppointment(appointmentData);openDialog(cancelAppointmentModal, 'cancelAppointmentModal')">
          </app-appointment-progress-card>
        </div>

        <div *ngIf="!appointmentCollectionArrived.length">
          <app-empty-state-placeholder [title]="'No Appointments'"
            [subtitle]="'No appointment found for selected date, keyword or category'"
            [icon]="'assets/images/no_appointments.svg'"></app-empty-state-placeholder>
        </div>
      </div>


    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Attending
        <span class="appointmentCountIndicator">{{appointmentCollectionAttending.length}}</span>
      </ng-template>

      <div style="height: 75vh; width: 100%;">
        <div *ngFor="let appointmentData of appointmentCollectionAttending; let i = index" [attr.data-index]="i">
          <app-appointment-progress-card [appointmentData]="appointmentData" [i]="i"
            [isAppointmentEnabled]="isAppointmentEnabled" (openBillReceipt)="openBillReceipt(appointmentData)"
            (onClickAppointmentStatus)="onClickAppointmentStatus(appointmentData)"
            (onClickPatientInfo)="onClickPatientInfo(appointmentData)"
            (onCancelAppointment)="selectAppointment(appointmentData);openDialog(cancelAppointmentModal, 'cancelAppointmentModal')">
          </app-appointment-progress-card>
        </div>

        <div *ngIf="!appointmentCollectionAttending.length">
          <app-empty-state-placeholder [title]="'No Appointments'"
            [subtitle]="'No appointment found for selected date, keyword or category'"
            [icon]="'assets/images/no_appointments.svg'"></app-empty-state-placeholder>
        </div>
      </div>


    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Completed
        <span class="appointmentCountIndicator">{{appointmentCollectionDone.length}}</span>
      </ng-template>

      <div style="height: 75vh; width: 100%;">
        <div *ngFor="let appointmentData of appointmentCollectionDone; let i = index" [attr.data-index]="i">
          <app-appointment-progress-card [appointmentData]="appointmentData" [i]="i"
            (openBillReceipt)="openBillReceipt(appointmentData)"></app-appointment-progress-card>
        </div>

        <div *ngIf="!appointmentCollectionDone.length">
          <app-empty-state-placeholder [title]="'No Appointments'"
            [subtitle]="'No appointment found for selected date, keyword or category'"
            [icon]="'assets/images/no_appointments.svg'"></app-empty-state-placeholder>
        </div>
      </div>


    </mat-tab>

    <!-- COMMENTING CANCELLED APPOINTMENTS FOR RELEASE -->
    <mat-tab>
      <ng-template mat-tab-label>
        Cancelled
        <span class="appointmentCountIndicator"
          style="background-color:#FFEDED; border: 1px solid #AC3A3B; color: #AC3A3B;">{{this.cancelledAppointments.length}}</span>
      </ng-template>

      <div style="height: 75vh; width: 100%;">
        <div *ngFor="let appointmentData of cancelledAppointments; let i = index" [attr.data-index]="i">
          <app-appointment-progress-card [appointmentData]="appointmentData" [i]="i"
            (openBillReceipt)="openBillReceipt(appointmentData)"></app-appointment-progress-card>
        </div>

        <div *ngIf="!cancelledAppointments.length">
          <app-empty-state-placeholder [title]="'No Appointments'"
            [subtitle]="'No cancelled appointment found for selected date, keyword or category'"
            [icon]="'assets/images/no_appointments.svg'"></app-empty-state-placeholder>
        </div>
      </div>

    </mat-tab>

  </mat-tab-group>

</div>


<!-- OLD CODE FOR APPOINTMENT -->
<!-- <div style="width: 100%;">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="row pr-3 ml-0" style="height: 40px;">
              <div style="margin-top: 7px;margin-right: 15px;">
                <div class="card card-body card-widget inventory-outer fs-18">
                  <div class=" headingUpprcase18 newInputStyleLabel"
                    style="text-transform: capitalize;margin-right: 7px; font-family: 'Mukta', sans-serif;  ">
                    Appointments<a ngbTooltip="Refresh Appointments" style="margin-left: 14px;"
                      (click)="getAppointments();pageRefersh()" container="body"><i class="fa fa-refresh"
                        aria-hidden="true"></i></a>
                  </div>
                </div>
              </div>
              <div>
                <div class="todaysAppointments pl-2"
                  style="margin-top: 8px !important; font-size: 16px !important; font-weight: bold;font-family: 'Mukta', sans-serif;">
                  Search </div>
              </div>
              <div class="scheduleandAddNew-outer">
                <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                  <div class="appointment float-right searchIcon active"
                    style="width:110px; border-radius: 4px !important; border-color: #cccc;">
                    <input [(ngModel)]="searchby_from_date" ngbDatepicker #dfrom="ngbDatepicker"
                      (ngModelChange)="getAppointments()" (focus)="dfrom.open()" type="text" class="form-control"
                      placeholder="DD/MM/YYYY" name="" (document:click)="closeCalendar($event,dfrom)"
                      style="font-size: 15px; padding: 8px !important; border-right: 0px solid #ccc !important"
                      readonly>
                    <img (click)="dfrom.toggle()" style="padding-right: 10px;" class="calendarbg"
                      src="assets/images/calendarIcon.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="form-group searchField pull-left" style="width: 50%; background-color: #F3F6FA;">
                <input type="text" placeholder="Search Patients by Name or Phone number" class="form-control"
                  #searchInput [(ngModel)]="searchString" (keyup)="searchPatient();"
                  style="font-size: 15px; border-radius: 4px !important; border-right: 0px solid #000 !important; padding: 8px !important; background-color: #F3F6FA !important;" />
                <a type="button" (click)="closeSearchApp()" style="padding-left: 6px;
              padding-right: 6px;
              font-size: 34px; top: -4px;">×</a>
              </div>
            </div>
            <div class="row appointmentHead">
              <div class="col-xl-12 col-lg-12">
                <div class="row d-flex ">

                  <div class="d-flex justify-content-end" style="padding-left: 5px;">

                    <div style="font-weight: 800;padding-bottom: 5px;">

                      <div style="padding: 5px;">
                        <button type="button" class="appointmentCountName" (click)="openDialog(alloted)">Alloted <span
                            class="appointmentCount">{{totalAppointment}}</span></button>

                        <button type="button" class="appointmentCountName" (click)="openDialog(arrivedPatient)">Arrived
                          <span class="appointmentCount">{{arrivedCount}}</span></button>
                        <button type="button" class="appointmentCountName"
                          (click)="openDialog(attendingAppointmentsModal);">Attending <span
                            class="appointmentCount">{{inCount}}</span></button>
                        <button type="button" class="appointmentCountName"
                          (click)="openDialog(doneAppointmentsModal)">Done <span
                            class="appointmentCount">{{doneCount}}</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-0" style="cursor: pointer;">
          <div class="card-header no-shadow inventory-list mt-0">
            <div class="appointments inventory pendingBilling">
              <ul>
                <li class="appointmentstableHead">
                  <ul class="head clearfix" style="display: flex;flex-direction: row; ">
                    <li>
                      <div class="d-flex justify-content-start align-items-center">
                        <div class="" style="font-family: 'Mukta', sans-serif;">No.</div>
                        <div class="topDown">

                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex justify-content-start align-items-center">
                        <div class="" style="font-family: 'Mukta', sans-serif;">Date</div>
                        <div class="topDown">

                        </div>
                      </div>
                    </li>

                    <li style="font-family: 'Mukta', sans-serif;"> Name (Gender/Age in years)</li>
                    <li style="text-align: center;">Status</li>
                    <li *ngIf="isBillEnabled == '1'" style="font-weight:bold;">Receipt</li>
                    <li *ngIf="isAppointmentEnabled == '1'" style="font-weight:bold;text-align: left !important;">Cancel
                    </li>
                    <li *ngIf="isAppointmentEnabled == '2'" style="font-weight:bold;text-align: left !important;">Absent
                    </li>


                  </ul>
                </li>
                <li class="morningSession-outer">
                  <ul class="morningSessionDetails contentBody clearfix clickable forHover"
                    style="display: flex;flex-direction: row;"
                    *ngFor="let appointmentData of appointmentCollection; let i = index" [attr.data-index]="i">
                    <div *ngIf="!appointmentData.deleted" style="width: 54vw;">
                      <li
                        style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;background-color: #3498FE;color:#fff;border-bottom-right-radius: 25px;"
                        *ngIf="appointmentData.in && !appointmentData.done">
                        <strong>{{i+1}}</strong>
                      </li>

                      <li style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;"
                        *ngIf="!appointmentData.in">
                        {{i+1}}
                      </li>
                      <li style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;"
                        *ngIf="appointmentData.in && appointmentData.done">
                        {{i+1}}
                      </li>
                      <li style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;"
                        *ngIf="appointmentData.in && !appointmentData.done">
                        <strong>{{appointmentData.visitedOn | date: 'dd/MM/yyyy'}}</strong><br>
                        <strong>{{appointmentData.visitedOn | date: 'EEEE'}} </strong><br>
                        <strong>{{appointmentData.from | date: 'shortTime'}} - {{appointmentData.to | date:
                          'shortTime'}}</strong>
                      </li>
                      <li style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;"
                        *ngIf="!appointmentData.in">
                        {{appointmentData.visitedOn | date: 'dd/MM/yyyy'}}<br>
                        {{appointmentData.visitedOn | date: 'EEEE'}} <br>
                        {{appointmentData.from | date: 'shortTime'}} - {{appointmentData.to | date: 'shortTime'}}
                      </li>
                      <li style="text-transform: capitalize; font-size: 16px; font-family: 'Mukta', sans-serif;"
                        *ngIf="appointmentData.in && appointmentData.done">
                        {{appointmentData.visitedOn | date: 'dd/MM/yyyy'}}<br>
                        {{appointmentData.visitedOn | date: 'EEEE'}} <br>
                        {{appointmentData.from | date: 'shortTime'}} - {{appointmentData.to | date: 'shortTime'}}
                      </li>

                      <li style="padding-top: 25px;" *ngIf="appointmentData.in && !appointmentData.done"><span
                          (click)="onClickPatientName('appointment',appointmentData)" class="pName"
                          style="font-size: 16px;font-family: 'Mukta', sans-serif;"><strong>{{appointmentData.name}}<br>{{appointmentData.mobile
                            | slice: 2}}</strong></span>
                      </li>
                      <li style="padding-top: 25px;" *ngIf="!appointmentData.in"><span
                          (click)="onClickPatientName('appointment',appointmentData)" class="pName"
                          style="font-size: 16px;font-family: 'Mukta', sans-serif;">{{appointmentData.name}}<br>{{appointmentData.mobile
                          | slice: 2}}</span>
                      </li>
                      <li style="padding-top: 25px;" *ngIf="appointmentData.in && appointmentData.done"><span
                          (click)="onClickPatientName('appointment',appointmentData)" class="pName"
                          style="font-size: 16px;font-family: 'Mukta', sans-serif;">{{appointmentData.name}}<br>{{appointmentData.mobile
                          | slice: 2}}</span>
                      </li>

                      <li style="padding: 10px 0px 0px 51px !important"
                        (click)="onClickAppointmentStatus(appointmentData)">
                        <div *ngIf="appointmentData.isTeleCall === '1' "
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                          class="pName" (click)="onClickStartCall(appointmentData)">
                          <circle-progress [percent]="0" [radius]="25" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [space]="-5" [outerStrokeColor]="'#cccccc'" [innerStrokeColor]="'#cccccc'"
                            [showTitle]="false" [showSubtitle]="false" [animation]="true" [showUnits]="false"
                            [imageSrc]="'../../../assets/images/start_call.png'" [imageHeight]="35" [imageWidth]="35"
                            [animationDuration]="300" [startFromZero]="false" [showImage]="true">
                          </circle-progress>

                          <div style="align-self: center;margin-left: 10px;"> Start Call</div>
                        </div>

                        <div *ngIf="!appointmentData.arrived &&appointmentData.isTeleCall != '1' "
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                          class="pName">
                          <circle-progress [percent]="0" [radius]="25" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [space]="-5" [outerStrokeColor]="'#cccccc'" [innerStrokeColor]="'#cccccc'"
                            [showTitle]="false" [showSubtitle]="false" [animation]="true" [showUnits]="false"
                            [imageSrc]="'../../../assets/images/not_arrived.png'" [imageHeight]="40" [imageWidth]="20"
                            [animationDuration]="300" [startFromZero]="false" [showImage]="true">
                          </circle-progress>

                          <div *ngIf="appointmentData.bookedFrom === 'Health'" style="align-self: center;">Booked Online
                          </div>
                        </div>

                        <div *ngIf="appointmentData.arrived && !appointmentData.in && !appointmentData.done"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                          class="pName">
                          <circle-progress [percent]="33" [radius]="25" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [space]="-5" [outerStrokeColor]="'#FF9E1F'" [innerStrokeColor]="'#cccccc'"
                            [showTitle]="false" [showSubtitle]="false" [animation]="true" [showUnits]="false"
                            [imageSrc]="'../../../assets/images/arrived_icon.png'" [imageHeight]="40" [imageWidth]="20"
                            [animationDuration]="300" [startFromZero]="false" [showImage]="true">
                          </circle-progress>
                          <div style="align-self: center;">Arrived</div>
                        </div>
                        <div *ngIf="appointmentData.arrived && appointmentData.in && !appointmentData.done"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                          class="pName">
                          <circle-progress [percent]="66" [radius]="25" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [space]="-5" [outerStrokeColor]="'#3499FF'" [innerStrokeColor]="'#cccccc'"
                            [showTitle]="false" [showSubtitle]="false" [animation]="true" [showUnits]="false"
                            [imageSrc]="'../../../assets/images/attending_icon.png'" [imageHeight]="30"
                            [imageWidth]="25" [animationDuration]="300" [startFromZero]="false" [showImage]="true">
                          </circle-progress>
                          <br />
                          <div style="align-self: center; ">Attending</div>
                        </div>
                        <div *ngIf="appointmentData.arrived && appointmentData.in && appointmentData.done"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                          class="pName">
                          <circle-progress [percent]="100" [radius]="25" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [space]="-5" [outerStrokeColor]="'#3DDC96'" [innerStrokeColor]="'#cccccc'"
                            [showTitle]="false" [showSubtitle]="false" [animation]="true" [showUnits]="false"
                            [imageSrc]="'../../../assets/images/tick_mark.png'" [imageHeight]="35" [imageWidth]="20"
                            [animationDuration]="300" [startFromZero]="false" [showImage]="true">
                          </circle-progress>
                          <div style="align-self: center;">Done</div>
                        </div>

                      </li>
                      <li *ngIf="isBillEnabled == '1'">



                        <a ngbTooltip="Add New Receipt" type=" button" (click)="openBillReceipt(appointmentData);"><img
                            style=" margin: 6px; width: 35px;height: 33px;margin-top: 15px;;margin-left: 0px; "
                            src="assets/images/receipt_icon.svg" alt=""></a>

                      </li>
                      <li *ngIf="isAppointmentEnabled == '1'"
                        style="width: 25px; padding-top: 25px;font-size: 16px;font-family: 'Mukta', sans-serif;">
                        <a *ngIf="appointmentData.showCancel" ngbTooltip="Cancel Appointment" tooltipClass="ngtooltip"
                          (click)="selectAppointment(appointmentData);openDialog(cancelAppointmentModal, 'cancelAppointmentModal')"><img
                            style="height: 25px;margin-top: 7px;" src="assets/images/cancel.png" alt=""></a>
                      </li>
                      <li *ngIf="isAppointmentEnabled == '2'"
                        style="width: 25px;padding-top: 25px;font-size: 16px;font-family: 'Mukta', sans-serif;">
                        <a *ngIf="appointmentData.showNoShow" ngbTooltip="Mark Absent" tooltipClass="ngtooltip"
                          (click)="selectAppointment(appointmentData);openDialog(cancelAppointmentModal, 'cancelAppointmentModal')">
                          <img style="height: 25px;margin-top: 7px;" src="assets/images/noentry.png" alt=""></a>
                        <div *ngIf="appointmentData.status == 'noshow'"
                          style="width: 25px; overflow: hidden; display: flex;flex-direction: row; align-self: center;text-transform:none;color: crimson;">
                          Absent</div>
                      </li>


                    </div>
                  </ul>
                  <ul class="morningSessionDetails contentBody clearfix" *ngIf="appointmentCollection.length == 0">
                    <li class="noAppointment">
                      No appointments available
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->


<p-confirmDialog appendTo="body"></p-confirmDialog>
<ng-template #scheduleandAddNewAppointment let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title" style="text-transform: capitalize;" *ngIf="!editMode">Add New Appointment
      </h6>
      <h6 class="modal-title" style="text-transform: capitalize;" *ngIf="editMode">Appointment Details
      </h6>
      <button type="button" class="close" aria-label="Close" (click)="c()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-12">
            <div class="form-group onFocus" #mobileNumberEl>
              <label [ngClass]="{'animatedLabel':user.mobile_number}" class="newInputStyleLabel">Mobile Number <span
                  class="required">*</span></label>
              <input [(ngModel)]="user.mobile_number" type="text" class="form-control  newInputBox" [readonly]="true"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">


              <p class="errors" *ngIf="errors.mobile_number">{{errors.mobile_number}}</p>
            </div>
          </div>
        </div>

        <div class="row GforHeight">
          <div class="col-6 calender">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">Date of Birth </label>
              <!-- <input placeholder="DD/MM/YYYY" type="text" [placement]="'top-left'" [minDate]="minDate" [maxDate]="today"
                [ngClass]="{'error-field':error}" id="dobInput" class="form-control animateInput" [(ngModel)]="user.dob"
                ngbDatepicker #d="ngbDatepicker" readonly="true" class="newInputBox"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"> -->

              <input placeholder="DD/MM/YYYY" name="dp" [(ngModel)]="user.dob" ngbDatepicker #d="ngbDatepicker"
                style="font-size: 16px; color: #000; border: 0px; width: 100px; outline: none;" [minDate]="minDate"
                [maxDate]="today" [ngClass]="{'error-field':error}" id="dobInput" class="form-control animateInput"
                readonly="true" class="newInputBox"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
              <img class="calendarbg top25" src="assets/images/calendarIcon.svg" alt=""
                style="margin-right: 4px; margin-top: 2px;" />
              <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p>
            </div>
          </div>
          <div class="col-6 onFocus">
            <label class="ifnoLabel twoColRadioLabel newInputStyleLabel">Gender </label>
            <div class="form-group twoColRadio clearfix d-flex">


              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #868e95; padding-top: 2px; font-size: 16px;">Male
                <input type="radio" [(ngModel)]="user.gender" disabled name="gender" value="male">
                <span class="checkmark-radio"></span>
              </label>


              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #868e95; padding-top: 2px; font-size: 16px;">Female
                <input type="radio" [(ngModel)]="user.gender" disabled name="gender" value="female">
                <span class="checkmark-radio"></span>
              </label>

              <p class="errors clearfix" *ngIf="errors.gender">{{errors.gender}}</p>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-6 calender">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">Appointment Date <span class="required">*</span></label>
              <input placeholder="DD/MM/YYYY" type="text" [minDate]="today" [ngClass]="{'error-field':error}"
                id="appointmentDateInput" class="form-control animateInput" [(ngModel)]="user.appointment_date"
                ngbDatepicker #dd="ngbDatepicker" (focus)="dd.open()" (document:click)="closeCalendar($event,dd)"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;" readonly>

              <img (click)="dd.toggle()" class="calendarbg top25" src="assets/images/calendarIcon.svg" alt=""
                style="margin-right: 4px; margin-top: 2px;" />
              <p class="errors" *ngIf="errors.appointment_date">{{errors.appointment_date}}</p>
            </div>
          </div>
          <div class="col-6 onFocus">
            <label class="ifnoLabel twoColRadioLabel newInputStyleLabel">Is Video Appointment<span
                class="required">*</span></label>
            <div class="form-group twoColRadio clearfix d-flex">


              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px">Yes
                <input type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode" name="tele" value="1">
                <span class="checkmark-radio"></span>
              </label>


              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px;">No
                <input type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode" name="tele" value="0">
                <span class="checkmark-radio"></span>
              </label>

              <p class="errors clearfix" *ngIf="errors.isTeleAppointMent">{{errors.isTeleAppointMent}}</p>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-6">

            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">From<span class="required">*</span></label>

              <p-calendar [(ngModel)]="user.appointment_from" (ngModelChange)="changeTime()" hourFormat="12"
                dateFormat="hh:ii AA" timeOnly="true" readonlyInput="true" class="newInputBox"></p-calendar>


              <p class="errors" *ngIf="errors.appointment_from">{{errors.appointment_from}}</p>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">To<span class="required">*</span></label>
              <p-calendar [(ngModel)]="user.appointment_to" hourFormat="12" dateFormat="hh:ii AA" timeOnly="true"
                readonlyInput="true"></p-calendar>

              <p class="errors" *ngIf="errors.appointment_to">{{errors.appointment_to}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group selectDropDown bloodGroup onFocus"
              style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
              <label class="newInputStyleLabel">Name of Clinic <span class="required">*</span></label>
              <select [(ngModel)]="user.clinic_id" class="infoLabel ng-pristine ng-valid ng-touched"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; height: calc(2.25rem + 2px);">
                <option value="" disabled selected>Select Clinic</option>
                <option *ngFor="let clinic of clinics" value="{{clinic.id}}">{{clinic.name}}</option>
              </select>
              <p class="errors" *ngIf="errors.clinic_id">{{errors.clinic_id}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group onFocus">
              <label class="newInputStyleLabel" for="comments">Comments</label>
              <input class="form-control newInputBox" name="comments" placeholder="Enter Comments" maxlength="500"
                [(ngModel)]="user.comment" onfocus="if(placeholder=='Enter Comments'){placeholder=''}"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                onblur="if(placeholder==''){placeholder='Enter Comments'}" [readonly]="editMode">
            </div>
          </div>
        </div>
        <div class="row" style="display:flex; justify-content:flex-end">
          <div class="col-12 mt-3 mb-3" style="display:flex; justify-content:flex-end">
            <button *ngIf="!editMode" class="newBtnStyle" style="text-transform: capitalize; padding:6px 10px"
              type="button" (click)="saveAppointment(c)">Save</button>
            <button *ngIf="editMode" class="newBtnStyle" style="text-transform: capitalize;" type="button"
              (click)="delete(c)">Cancel</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Questionnaire let-c="close" let-d="dismiss">
  <div class="popUps" style="width: 600px !important;">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">COVID-19 Screening Questionnaire</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
        </span>
      </button>
    </div>
    <div class="modal-body" style="background-color: #fff; width: 600px !important;">
      <div class="model-content" style=" padding: 0 10px;">
        <div class="selectionTable" style="height: 400px; align-self: center; overflow-y:scroll;">
          <form name="form1" id="form1" style="margin-left: 5px;">
            <table>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">How old is the Patient?</b></td>
              </tr>
              <tr>


                <label class="container-radio">Under 18
                  <input type="radio" name="age" value="Under 18" checked>
                  <span class="checkmark-radio"></span>
                </label>

              </tr>
              <tr>

                <label class="container-radio">Between 18 and 65
                  <input type="radio" name="age" value="Between 18 and 65">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">Above 65
                  <input type="radio" name="age" value="Above 65">
                  <span class="checkmark-radio"></span>
                </label>

              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Symptoms shown by Patient (Multiple selection)</b>
                </td>
              </tr>
              <tr>


                <label class="container">Fevers chills or sweating
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms2"
                    value="Fevers chills or sweating">
                  <span class="checkmark"></span>
                </label>

              </tr>
              <tr>

                <label class="container">Difficulty in breathing [not severe]
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms3"
                    value="Difficulty in breathing [not severe]">
                  <span class="checkmark"></span>
                </label>

              </tr>
              <tr>

                <label class="container">New or heavy cough
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms4" value="New or heavy cough">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Sore throat
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms5" value="Sore throat">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Body ache
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms6" value="Body ache">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Vomitting or Diarrhea
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms7" value="Vomitting or Diarrhea">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Feels like Influenza
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms8" value="Feels like Influenza">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Runny nose
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms9" value="Runny nose">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">None
                  <input type="checkbox" (click)="symptomnoneselected()" name="symptoms1" value="None" checked>
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Medical Conditions (Multiple selection)</b></td>
              </tr>
              <tr>

                <label class="container">Asthama or Lung disease
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions1"
                    value="Asthama or Lung disease">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Pregnancy
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions2" value="Pregnancy">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Diabetes with complications
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions3"
                    value="Diabetes with complications">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Diseases that make it Hard to cough
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions4"
                    value="Diseases that make it Hard to cough">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Kidney failure or Dialysis
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions5"
                    value="Kidney failure or Dialysis">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Liver Cirrhosis
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions6" value="Liver Cirrhosis">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Weak immune system
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions7"
                    value="Weak immune system">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Heart disease
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions8" value="Heart disease">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">Obesity
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions9" value="Obesity">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>

                <label class="container">None
                  <input type="checkbox" name="conditions10" value="None" (click)="conditionnoneselected()" checked>
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, has patient travelled outside
                    India?</b></td>
              </tr>
              <tr>

                <label class="container-radio">Yes, travelled out of India
                  <input type="radio" name="international" value="(Yes, travelled outside India)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">No
                  <input type="radio" name="international" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, has patient travelled to any
                    area infected with
                    COVID-19?</b></td>
              </tr>
              <tr>

                <label class="container-radio">Lives in area infected by COVID-19
                  <input type="radio" name="area" value="(Lives in infected area)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">Travelled to area infected by COVID-19
                  <input type="radio" name="area" value="(Travelled to infected area)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <label class="container-radio">No
                  <input type="radio" name="area" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, Patient's exposure to
                    COVID-19?</b></td>
              </tr>
              <tr>

                <label class="container-radio">Lives with someone who has COVID-19
                  <input type="radio" name="exposure" value="(Lives with someone who has COVID-19)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">Met someone who has COVID-19
                  <input type="radio" name="exposure" value="(Met someone who has COVID-19)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">None
                  <input type="radio" name="exposure" value="None" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Patient works in a Hospital/Nursing Home/Old-age
                    home? (as Nurse,
                    Staff or Doctor)</b></td>
              </tr>
              <tr>

                <label class="container-radio">Lives in Hospital/Nursing Home/Old age home
                  <input type="radio" name="works" value="(Lives in Hospital/Nursing Home)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio">Works at Hospital/Nursing home/Old age home
                  <input type="radio" name="works" value="(Works at Hospital/Nursing home)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <label class="container-radio">None
                  <input type="radio" name="works" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Doctor's conclusion</b></td>
              </tr>
              <tr>

                <label class="container-radio">No, patient doesn't need to go to Hospital for COVID-19 testing
                  <input type="radio" name="conclusion"
                    value="As per information provided by Patient, NO test is needed as of Prescription Date mentioned above"
                    checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>

                <label class="container-radio"><b class="optionText">Yes, Patient needs to go to Hospital for COVID-19
                    testing</b>
                  <input type="radio" name="conclusion"
                    value="(As per information provided by Patient, Patient needs to be tested for COVID-19 by any authorized Hospital)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </form>
        </div>
        <div class="row d-flex pt-3" style="display: flex; justify-content: flex-end;">
          <button class="newBtnStyle" (click)="submitform()">Save & Move
            to Prescription</button>

        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #Billgenerate let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">Create Invoice</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="selectionTable" style="width: 90%; align-self: center; overflow-x:hidden;">

          <div class="row">
            <div class="col-12">
              <label for="" style="font-size: 18px;color: #454A51;" class="newInputStyleLabel">Patient
                Name : {{appointmentDataForPresc.comepleteData.Patient.User.first_name}}
                {{ appointmentDataForPresc.comepleteData.Patient.User.last_name}}</label>
              <div class="form-group  onFocus">
                <label [ngClass]="{'animatedLabel':firstName['first_name'] || editMode}" class="newInputStyleLabel">Bill
                  Amount (Rs.) <span class="required">*</span></label>
                <input [(ngModel)]="billAmount" type="text" class="form-control newInputBox"
                  (focus)="addLabelClass($event)" [textMask]="{mask: mask,guide:false}" placeholder="Enter bill amount"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                  (blur)="removeLabelClass($event)">

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group  onFocus">
                <label class="newInputStyleLabel"
                  [ngClass]="{'animatedLabel':lastName['last_name'] || editMode}">Patient Email (Optional)</label>
                <input [(ngModel)]="patientEmail" type="text" class="form-control newInputBox"
                  placeholder="Enter patient email"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex pt-3" style="display: flex; justify-content: flex-end;">
          <button class="newBtnStyle" (click)="submitBillGenerateForm(c)">Save & Send to Patient
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #todaysBday let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase" style="text-transform: capitalize;">Patient Info</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content ">
        <div *ngIf="cases.length > 0" style="height: 400px; align-self: center; overflow-y:scroll;">
          <div class="row">
            <div class="row">
              <div class="col-12">
                <div class="form-group selectDropDown bloodGroup onFocus" style="margin-left: 10px;">
                  <label class="">Choose Visit<span class="required">*</span></label>
                  <select (change)="onChange($event.target.value)" class="infoLabel ng-pristine ng-valid ng-touched">
                    <option *ngFor="let caseVisit of cases; let i = index" [value]="i">
                      {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date: 'dd/MM/yyyy'}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="certificates topBottomBorder birthdaylist">
              <ul class="pl-0" *ngFor="let path of pathArray; let i = index">
                <li class="clearfix">
                  <div class="row" style="margin: 15px;">
                    <div class="form-group checkbox withLabel" style="width: 60%; margin-left: 3px;">
                      <label style="color: #333;padding:3px">
                        {{path.name}}
                      </label>
                    </div>
                    <div class="row d-flex" style="width: 36%;margin: 3px;">
                      <a style="font-size: 14px;height: 30px;color: #FFF;background-color: #FF8E14;padding: 5px; border-radius: 4px; margin-left: 10px;margin-right: 10px;text-transform: capitalize;"
                        (click)="goToLink(path,d)">Go</a>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>

        </div>
        <div *ngIf="!cases || cases.length == 0">
          <div class="row">
            <div class="form-group checkbox withLabel mb-2 selectAll">
              <label>
                <span>No patient selected</span>
              </label>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #cancelAppointmentModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 *ngIf="isAppointmentEnabled == '1'" class="modal-title" id="modal-basic-title"
        style="font-family: 'Mukta', sans-serif;">Are you sure to cancel
        appointment for {{selectedAppointment.comepleteData.Patient.User.first_name}}
        {{selectedAppointment.comepleteData.Patient.User.last_name}}?</h4>
      <h4 *ngIf="isAppointmentEnabled == '2'" class="modal-title" id="modal-basic-title"
        style="font-family: 'Mukta', sans-serif;">Are you sure to mark
        {{selectedAppointment.comepleteData.Patient.User.first_name}}
        {{selectedAppointment.comepleteData.Patient.User.last_name}} as Absent?</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold; 
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">No</button>

      <button *ngIf="isAppointmentEnabled == '1'" (click)="delete();closeDialog();" class="buttonForStyle">Yes</button>
      <button *ngIf="isAppointmentEnabled == '2'" (click)="markNoShow();closeDialog();"
        class="buttonForStyle">Yes</button>
    </div>
  </div>
</ng-template>


<ng-template #addVitalsModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">Record vitals for
        {{selectedAppointment.comepleteData.Patient.User.first_name}}
        {{selectedAppointment.comepleteData.Patient.User.last_name}}?</h4>

      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>


    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 0px;
                    margin: 4px;
                    color: #444;
                    outline: none;">No</button>

      <button (click)="goToVital();" class="buttonForStyle">Yes</button>
    </div>
  </div>
</ng-template>


<ng-template #confirmationModal let-modal>
<div style="padding: 20px;">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">
        Are you sure, you want to mark
        {{ selectedAppointment.comepleteData.Patient.User.first_name }} 
        {{ selectedAppointment.comepleteData.Patient.User.last_name }} patient as done?
      </h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 0px;
                    margin: 4px;
                    color: #444;
                    outline: none;">No</button>
      <button (click)="confirmDone()" class="buttonForStyle">Yes</button>
    </div>
  </div>
</div>
</ng-template>


<!-- Alloted Appointment-->
<ng-template #alloted>
  <div style="padding:10px;">
    <h5>Alloted Appointments</h5>
  </div>
  <mat-dialog-content class="mat-typography">
    <div style="padding:10px;">
      <table class="table table-hover">
        <thead>
          <tr style="padding:20px;">
            <th>S.No.</th>
            <th>Patient Name</th>
            <th>Phone</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let alloted of appointmentCollection; let i = index;">
            <td>{{i+1}}</td>
            <td>{{ alloted.fullName }}</td>
            <td>{{ alloted.mobile }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <div style="padding-left: 10px;float:right;">
    <button
      style="background-color:#4687BF;font-size: 18px;padding:6px;color: #fff; border-radius: 5px;border-color:#4687BF;padding-left: 20px;padding-right: 20px;"
      (click)="closeDialog()">Close</button>
  </div>
</ng-template>

<!-- Arrived Patient-->
<ng-template #arrivedPatient>
  <div style="padding:10px;">
    <h5>Arrived Patients</h5>
  </div>
  <div style="padding:10px;">
    <table class="table table-hover">
      <thead>
        <tr style="padding:20px;">
          <th>S.No.</th>
          <th>Patient Name</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let arrived of arrivedPatients let i = index;">
          <td>{{i+1}}</td>
          <td>{{arrived.fullName}}</td>
          <td>{{arrived.mobile}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="padding-left: 10px;float:right;">
    <button
      style="background-color:#4687BF;font-size: 18px;padding:6px;color: #fff; border-radius: 5px;border-color:#4687BF;padding-left: 20px;padding-right: 20px;"
      (click)="closeDialog()">Close</button>
  </div>
</ng-template>

<ng-template #attendingAppointmentsModal>
  <div style="padding:10px;">
    <h5>Attending Appointments</h5>
  </div>
  <div style="padding:10px;">
    <table class="table table-hover">
      <thead>
        <tr style="padding:20px;">
          <th>S.No.</th>
          <th>Patient Name</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attend of attendingAppointments let i = index;">
          <td>{{i+1}}</td>
          <td>{{ attend.fullName }}</td>
          <td>{{ attend.mobile }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="padding-left: 10px;float:right;">
    <button
      style="background-color:#4687BF;font-size: 18px;padding:6px;color: #fff; border-radius: 5px;border-color:#4687BF;padding-left: 20px;padding-right: 20px;"
      (click)="closeDialog()">Close</button>
  </div>
</ng-template>

<ng-template #doneAppointmentsModal>
  <div style="padding:10px;">
    <h5>Done Appointments</h5>
  </div>
  <div style="padding:10px;">
    <table class="table table-hover">
      <thead>
        <tr style="padding:20px;">
          <th>S.No.</th>
          <th>Patient Name</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let done of doneAppointments let i = index;">
          <td>{{i+1}}</td>
          <td>{{ done.fullName }}</td>
          <td>{{ done.mobile }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="padding-left: 10px;float:right;">
    <button
      style="background-color:#4687BF;font-size: 18px;padding:6px;color: #fff; border-radius: 5px;border-color:#4687BF;padding-left: 20px;padding-right: 20px;"
      (click)="closeDialog()">Close</button>
  </div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>

        <div (click)="refreshAppointment()"
          style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px; font-weight: bold; cursor: pointer;">
          REFRESH NOW ⟲</div>
      </div>
    </div>
  </ng-template>
</p-toast>