import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { Router, ActivatedRoute, } from "@angular/router";
import moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { DoctorService } from '../../../services/doctor.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { map, startWith } from 'rxjs/operators';
import { PrescriptionLivePreviewComponent } from '../prescription-live-preview/prescription-live-preview.component';
import { LanguageSelectTextboxComponent } from '../../../components/language-select-textbox/language-select-textbox.component';


export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

let followupArray = [{ name: "Call", selected: false }, { name: "Appointment", selected: false }]
let followupArrayForQ = [{ name: "Call", selected: false }, { name: "Visit", selected: false }]
let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "OnExamination", selected: true, value: "showOnExamination" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }];
let printingFieldsArray = ["showVitals", "showComplaints", "showOnExamination", "showDiagnosis", "showPrescription", "showGeneralIntructions", "showDiet", "showNextFollowup"]
@Component({
  selector: 'app-edit-prescription',
  templateUrl: './edit-prescription.component.html',
  styleUrls: ['./edit-prescription.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],


})



export class EditPrescriptionComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  isAllSelected:boolean=true
  selectedVaccination:any=[]
  @ViewChild('pendingVaccinationModal', { static: true }) pendingVaccinationModal!: TemplateRef<any>;

  followUpMessageForLanguage: any = [];
  
  @ViewChild("pendingVaccination_modal") pendingVaccination_modal!: TemplateRef<any>;
  @ViewChild('allSelected') private allSelected!: MatOption;
  @ViewChild("favInput") favInput!: ElementRef;
  @ViewChild('prescritionpreviewcomponent') editViewPrescriptioncomponent!: PrescriptionLivePreviewComponent;
  @ViewChild('confirm_favourite_modal') confirm_favourite_modal!: TemplateRef<any>;

  @ViewChild("showPrescription_modal") showPrescription_modal!: TemplateRef<any>;
  @ViewChild("langFollowupMessage") langFollowupMessage!: LanguageSelectTextboxComponent;
  customFavourite: any = new FormControl('', [Validators.required]);

  // followupDateNew=new FormControl('',[Validators.required]);
  vacDate:any
  followupDateNew: any
  pendingVaccination:any=[]
  selectedDate: any
 
  followMessageArray: any = []
  showOtherId: any = []
  followupDateField: any = new FormControl('', [Validators.required]);
  followUpArray: any = []
  followupArray: any = followupArray;
  followupArrayForQ: any = followupArrayForQ
  step = 0;
  languageArray: any = [{ name: 'English', language: 'en' }, { name: 'Marathi', language: 'mr' }, { name: 'Hindi', language: 'hi' }, { name: 'Kannada', language: 'kn' }];
  selectedLanguage: any = { name: "English", language: 'en' };
  templateName: any = "Custom Prescription";
  selectedTemplate: any = [];
  followupType: any = ""
  followupTypeForQ: any = ""
  favMed = false
  folloupMessage: any = ""
  favaddFavPrescriptionTextBox: any = false
  addFollowUpFlag: any =true
  titleOfSelectFav = "Add Favourite Prescription"
  dateForFollowup: any
  selectedComplaints: any = '';
  selectedDiagnosis: any = '';
  selectedOnExamination: any = '';
  selectedDietaryAdvices: any = '';
  selectedGeneralInstructions: any = '';
  allMedicines: any = [];
  deletedMedicineIds: any = [];
  appointmentBook: any = false;
  deletedMedicinesArray: any = [];
  showLoading = false
  favApptName = "";
  presc_url: string = "";
  hideBackButton = false;
  url: string = "";
  isSaved = false
  drugMolecularFlag: any
  date: any
  patientId:any = null;
  selectFieldsArray: any = [{name:"All Fields",value:""}];
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate'];
  isEditPrescription = true
  appointmentData: any = {}
  appointmentId:any = null;
  medicineArry:any = [];
  nextFollowUp = '';
  prescriptionPreviewArray:any[] = [];

  patientData: any = {};
  fullName = "";
  gender = "";
  dob = "";
  ageObj:any = { years: null };
  contactNo = "";
  showAge = false;
  age: any = "";
  patient = {};
  visits:any[] = [];
  printingFields = printingFieldsArray;
  userId: any;
  minDateForAppointment: any
  followupDate: any
  minDate: any;
  language = "";
  maxDate: any;
  currentDoctorObj:any
  nextAppointmentDays: any
  followUpData: any
  appointmentFlag: any = "";
  arrOrg: string[] = [];
  vaccineList:any=[]
  upcomingVaccineList:any=[]
   backToeditFlag:any;
   selectFieldsValue:any="All Fields"
   selectedFielfFlag:boolean=false
  //  myselectedFoods = ['pasta-3', 'steak-0'];
 // foodForm: FormControl = new FormControl(this.selectFieldsArray);
  constructor(private patientService: PatientService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private doctorService: DoctorService, private authService: AuthService,) {
    this.date = new Date();
    // this.vacDate=new Date();
    this.minDate = new Date();
    this.userId = this.authService.getUser();
    this.minDateForAppointment = this.reverseDate(new Date());
      this.minDate = new Date();
      this.maxDate = new Date(2022, 12, 31);

  }

  ngOnInit(): void {
    if (this.nextAppointmentDays) {
      console.log("app", this.nextAppointmentDays)
      this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + this.nextAppointmentDays));
      localStorage.setItem("followupDate",this.followupDateNew)
    }
    else {
      this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + 5));
      localStorage.setItem("followupDate",this.followupDateNew)
    }
    this.followupDateField.setErrors(null)
    this.templateName = 'default';
     this.getDoctorDetails()
   
    // this.selectFieldsArray.map(ins=>{
    //   if(ins.selected==true){
    //     this.selectedFielfFlag=false
    //   }
    //   else{
    //    // ins.selected=false
    //   }
    // })
    this.route.queryParams
      .subscribe((params:any) => {
        this.patientId = params.patientId;
       this. backToeditFlag=params.edit
        this.getPatientInfo()
        setTimeout(() => {
          this.getPrescription();

        }, 200)

      }
      
      )
      if(this.backToeditFlag){

        var followupMessage:any =localStorage.getItem("followUpMessage")?localStorage.getItem("followUpMessage"):""
        setTimeout(()=>{
          this.langFollowupMessage.appendTexttoBox(followupMessage)
        },200)
   
        this.templateName=localStorage.getItem("selectDoctorsTemplate")?localStorage.getItem("selectDoctorsTemplate"):this.templateName

        this.followupType=localStorage.getItem("selectApptfollowupType")?localStorage.getItem("selectApptfollowupType"):""
    
        const storedLanguage = localStorage.getItem("doctorsLanguage");
        this.selectedLanguage = storedLanguage ? JSON.parse(storedLanguage) : this.selectedLanguage;
        
        this.date=localStorage.getItem("followupDate") ? localStorage.getItem("followupDate"):this.followupDateNew
        console.log("dsdd", this.date)
        this.followupDateNew =new Date(this.date)
      
        //this.getDoctorDetails();
      }
      

    this.patientService.getAllRequiredConstants('').subscribe((response:any) => {
      if (response) {
        this.followUpMessageForLanguage = response.data.nextFollowupMessages;
      }
    })
   
    //calculate the followup date
    // setTimeout(() => {
    //   this.checkFollowUpData();
    // }, 500)

 this.getPatientsVaccination();
  }

  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }



  openPanel(isOpen:any){
  if(isOpen){
    this.selectFieldsArray=selectFields
    this.selectFieldsValue=""
  
 
  
   console.log("??",this.isAllSelected)
    console.log("March23",this.selectFieldsArray)
  }

  else{
    let isAllSelected=true
     this.selectFieldsArray.map((ins:any)=>{
    if(!ins.selected){
      isAllSelected=false
      
    }
    
  
   })
    console.log("isOpenFlag",this.isAllSelected)
    if(isAllSelected){
      this.selectFieldsArray=[{name:"All Fields",value:""}];
    }
    
   
  }
  }
  selectedFollowupMessage(messageForFollowup:any) {

    this.folloupMessage = ""

    if (messageForFollowup.length > 0) {
      this.folloupMessage = messageForFollowup.join('')
    }


  }

  s1(sel: MatSelect) {
    sel.placeholder = 'sdfdsf';
  }

  toggleSelection(evnt:any, item:any) {
    var selectedmessageForFollowup: any = []

    this.folloupMessage = ""

    this.followUpMessageForLanguage.map((ins:any) => {
      if (ins.value == item) {
        selectedmessageForFollowup.push(ins.value)
      }
    })

    console.log("message", this.arrOrg);
    setTimeout(() => {
      this.selectedFollowupMessage(selectedmessageForFollowup)
    },500)


    //this.folloupMessage=selectedmessageForFollowup

  }

  getPatientsVaccination = () => {
    this.patientService.getPatientsVaccination(this.patientId).subscribe((response:any) => {
      if (response) {
        this.vaccineList = response;
        for (let i = 0; i < this.vaccineList.length; i++) {
          if (this.isJson(this.vaccineList[i].name)) {
            let scheduleNames = JSON.parse(this.vaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach((langpart:any) => {
                if (langpart.lang == 'en') {
                  this.vaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray:any[] = [];
        let newArray:any[] = [];
        let tempArr:any[] = [];
        let administeredVaccines:any[] = [];


        this.vaccineList.map((ins:any) => {
          ins.data.map((item:any, idx:any) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines
            if (item.administeredStatus) {
              administeredVaccines.push(item);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });

        });
      //  this.unformattedVaccineList = this.vaccineList;
        this.vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.vaccineList = vaccineArray;
       // this.allAdministeredVaccines = administeredVaccines;
        console.log('Vaccine List1', this.vaccineList)
      }
    });
  }

  isJson(str:any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      console.log('Doctor Response', response.doctor)

       this.currentDoctorObj = response.doctor
      this.appointmentFlag = response.doctor.isAppointmentEnabled
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);
          console.log("Service", services)
          this.drugMolecularFlag = services && services.drugMoleculeFlag ? services.drugMoleculeFlag : false
          this.nextAppointmentDays = services && services.nextApptDays ? services.nextApptDays : ''
          this.showOtherId = services && services.showAnotherId ? services.showAnotherId : ''

          var language = services && services.prescriptionLang ? services.prescriptionLang : 'en'
     // Safely retrieve and parse the value from localStorage
const localLanguageString = localStorage.getItem("doctorsLanguage");

// Check if the retrieved value is not null before parsing
const localLanguage: any = localLanguageString ? JSON.parse(localLanguageString) : null;

          var selectedLanguage={}
          this.languageArray.map((ins:any) => {
            if (ins.language == language) {
              selectedLanguage = { name: ins.name, language: ins.language }
            }
          })
          if(this.backToeditFlag){
            this.selectedLanguage=localLanguage?localLanguage:selectedLanguage
          }
          else{
            this.selectedLanguage=selectedLanguage
          }
          


          // this.selectedTemplate=JSON.parse(response.doctor.ser)

          if (services.template_names) {
            // this.tabs.push('Receipts');
            this.templateName = 'Custom Prescription';
            this.selectedTemplate = services.template_names;
            // here
            let temp = localStorage.getItem("selectDoctorsTemplate");

            if (temp) {
              this.templateName = temp
            } else {
              this.templateName = services.template_names[0];
            }
          }
          else {
            this.selectedTemplate.push('default');
          }
        }
        else {
          this.templateName = 'default';
          this.selectedTemplate.push('default');
        }
      }
    })
  }


  closeDialog() {
    this.dialog.closeAll()
  }

  getPatientInfo = () => {

    // let patientObj = JSON.parse(localStorage.getItem('patientData'));

    // this.fullName = patientObj.User.first_name + " " + patientObj.User.last_name;
    // this.gender = patientObj.User.gender == 'male' ? 'M' : 'F';
    // this.dob = patientObj.User.dob
    // this.age = moment().diff(this.dob, 'years');

    this.patientService.getPatientInfo(this.patientId).subscribe((response:any) => {
      if (response) {
        console.log("Response Patients", response);
        this.patientData = response
        console.log('Patient2', this.patientData);

        this.fullName = response.firstName + " " + response.lastName;
        this.gender = response.gender.toLowerCase() == 'male' ? 'M' : 'F';
        this.dob = response.DOB
        this.ageObj = response.age;
        this.contactNo = response.contactNo;
        if (this.ageObj.years < 1) {
          // this.ageObj.years="Under 1 Year";
          this.showAge = true;
        }
        else {
          this.ageObj.years;
          this.showAge = false;
        }
        this.age = moment().diff(this.dob, 'years');

        this.patient = response;
        this.visits = response.visits && response.visits.length ? response.visits : [];

        this.visits.map((element:any, index) => {
          if (element._id === "synthetic_record") {
            this.visits.splice(index, 1);
          }
        });


        // this.getPatientAgeCategory();
      } else {
        alert('Something went wrong')
      }
    });

  }



  getPrescription() {

    var obj = {
      "patientId": this.patientId,
      "selectfield": this.selectFieldsArray
    }

    this.patientService.getPrescriptions(obj).subscribe((response:any) => {

      if (response) {
        this.followUpData = response.visit
        console.log("Prescription response", this.followUpData);
        // alert('Response --- ' + JSON.stringify(response));
        if (response.appointment) {
          this.isEditPrescription = true;
          this.appointmentData = response.appointment;
          this.appointmentId = response.appointment.id;
          this.medicineArry = response.visit.Medicines;
          console.log("appiis", this.appointmentId)

        }

        if (response.visit) {

          this.selectedComplaints = response.visit.chief_complaints ? response.visit.chief_complaints : '';
          this.selectedDiagnosis = response.visit.complaint_diagnosis ? response.visit.complaint_diagnosis : '';
          this.selectedGeneralInstructions = response.visit.general_instruction ? response.visit.general_instruction : '';
          this.selectedDietaryAdvices = response.visit.nutrition_diet_advice ? response.visit.nutrition_diet_advice : '';
          this.selectedOnExamination = response.visit.complaint_examination ? response.visit.complaint_examination : ''
          // this.investigations = response.visit.suggested_investigation;
          // this.medicine_instructions = '';
          // this.followupType = response.visit.next_vaccination;

          let data = response.visit.Medicines;

          data.map((ins:any) => {

            let obj: any = {}
            if (!this.drugMolecularFlag) {
              obj.name = ins.Drug.product_name ? ins.Drug.product_name : ins.Drug.product_name;
            }
            else {
              obj.name = ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name;
            }

            obj.Drug = ins.Drug;
            obj.dosagePreviewMed = ins.dosage2;
            obj.dosage2 = ins.dosage2;
            obj.duration2 = ins.duration2
            obj.duration3 = ''//this.days;
            obj.duration4 = ins.frequency2;
            obj.finalPreview = ins.dosage2;
            obj.frequency2 = ins.frequency2;
            obj.instructions = ins.instructions;
            obj.id = ins.id;

            let element = {
              // drugType: this.selectedDrugType,
              // dose: this.selectedDosageUnit,
              // time: timeArr.join(', '),
              whenToTake: ins.duration2,
              // days: this.days,

              medicineName: ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name,
              // name: ins.Drug.product_name,
              dosage2: ins.dosage2,
              instructions: ins.instructions,
              id: ins.id,
              //  favApptName:ins.favApptName
            }

            element.dosage2 = element.whenToTake ? element.dosage2.replace('--', `- ${element.whenToTake} -`) : element.dosage2

            // ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;

            //here

            this.allMedicines.push(obj);
            this.allMedicines = this.allMedicines.reduce((unique:any, o:any) => {
              if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
                unique.push(o);
              }
              return unique;
            }, []);

            this.allMedicines.map((ins:any) => {
              if (this.drugMolecularFlag == 1) {
                ins.Drug.generic_name = "";
              }
            })

            this.prescriptionPreviewArray.push(element);
            // this.favArry.push(element);
          });

          sessionStorage.setItem("qrx_allMedicines", JSON.stringify(this.allMedicines))

        }


      }
    });
  }
  selectModalValue(index:any){
    if (this.pendingVaccination[index].selected) {
      this.pendingVaccination[index].selected = false;
    }
    else{
      this.pendingVaccination[index].selected = true;
    }
  }
  savePendingVaccination() {
    var data=this.langFollowupMessage.getValue();
    console.log("apr4",data)
    if(this.vacDate!=undefined){
     var vaccDate: any = this.reverseDate(this.vacDate);
    vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
    this.langFollowupMessage.appendTexttoBox(vaccDate +" "+"-"+" ")
   this.selectedVaccination.map((ins:any)=>{
     if(ins.name){
      this.langFollowupMessage.appendTexttoBox(' '+" "+ins.name +"");
     }
   })
   this.closeDialog()
  }
  else{

  }
  
    
  }

  selectFollowUpMessage(event:any) {
    var followupmessage=this.langFollowupMessage.getValue()
    if(followupmessage==event.value){

    }
    else{
      this.langFollowupMessage.appendTexttoBox(event.value)
     
    }
    
  
    this.vaccineList.map((ins:any)=>{
      if(ins.isOverdue ){
        this.pendingVaccination.push(ins)
      }
      else if(!ins.administeredStatus){
        this.upcomingVaccineList.push(ins)
      }
    })
    this.pendingVaccination = this.pendingVaccination.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj.name == o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.upcomingVaccineList = this.upcomingVaccineList.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj.name == o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);

    if(event.value=="Next Vaccination due:"){
   
      this.dialog.open(this.pendingVaccination_modal)
    }
  
   
  }

  selectedRowIndex = -1;
  selectedRows(item:any){
    this.selectedRowIndex = item.id;
  
  }

  getVaccData(element:any){
    this.selectedVaccination.push(element)
  
  }
  addFavPrescription(e:any) {
    if (e.target.checked) {
      this.favaddFavPrescriptionTextBox = true
    }
    else {
      this.favaddFavPrescriptionTextBox = false
    }
  }
  addFollowUp(e:any) {
    if (e.target.checked) {
      this.addFollowUpFlag = true
    }
    else {
      localStorage.removeItem("followupDate")
      this.addFollowUpFlag = false
    }

  }

  previewPrescriptionPDF = (flag:any) => {

    let editedValues = this.editViewPrescriptioncomponent.geteditedValues();

    // flag comes 0 for continue without favourite
    if (this.favaddFavPrescriptionTextBox && this.favApptName == "" && flag) {
      // alert("You must specify a name for your favourite or else it will not get saved")
      this.dialog.open(this.confirm_favourite_modal)
    } else {
      this.closeDialog()
      this.showLoading = true;
      if (this.followupDateNew != null) {
         var newFollowUpDate: any = new Date(this.followupDateNew);
         newFollowUpDate = newFollowUpDate ? newFollowUpDate : ''
         var nextFollowUpDate: any = newFollowUpDate.toDateString();
         }
      var nextFollowUp = "";
      var folloupMessage:any=this.langFollowupMessage.getValue()
      
      localStorage.setItem("followUpMessage",folloupMessage)
      folloupMessage= folloupMessage.split(',  ')
       folloupMessage= folloupMessage.join(':')
   
      if(this.addFollowUpFlag==true){
        nextFollowUp = (nextFollowUpDate ? nextFollowUpDate : "") + ":" + this.followupType +  "</br>:" + folloupMessage
      } 
   

      // var followupMessage = this.langFollowupMessage.getValue()
     
      // if (followupMessage) {
      //   this.folloupMessage = followupMessage.split(', ')
      //   this.folloupMessage = this.folloupMessage.join(':')
      // }



      // console.log('RSVP02', this.folloupMessage)

      // var followupType = this.followupType ? this.followupType : '';

      // localStorage.setItem("followupType", followupType)

      // console.log("followUpTypes", followupType)


      // if (this.followupDateNew != null) {
      //   var newFollowUpDate: any = new Date(this.followupDateNew);
      //   newFollowUpDate = newFollowUpDate ? newFollowUpDate : ''
      //   var nextFollowUpDate: any = newFollowUpDate.toDateString();
      // }

      // if (nextFollowUpDate != undefined && followupType != " " || this.folloupMessage != " ") {

      //   if (nextFollowUpDate == undefined) {
      //     nextFollowUp = followupType + ":" + this.folloupMessage;
      //     nextFollowUp = nextFollowUp.replace(/ +(?= )/g, '')

      //     localStorage.setItem("bookAppointment", nextFollowUp)

      //   }
      //   else {
      //     nextFollowUp = nextFollowUpDate + ":" + followupType + ":" + this.folloupMessage;
      //     nextFollowUp = nextFollowUp.replace(/ +(?= )/g, '')

      //     localStorage.setItem("bookAppointment", nextFollowUp)

      //   }

      // }
      localStorage.setItem("bookAppointment", nextFollowUp)
      console.log("nextVac", nextFollowUp)
      // else if(nextFollowUpDate || followupType!=" " || this.folloupMessage!=" "){
      //   nextFollowUp=nextFollowUpDate+":"+followupType+"-"+this.folloupMessage
      //   localStorage.setItem("bookAppointment",nextFollowUp)
      // }

      this.allMedicines.map((ins:any) => {
        if (!this.drugMolecularFlag) {
          ins.Drug.generic_name = "";
        }
      })

      var chief_complaints = editedValues.selectedComplaints
      var on_examination = editedValues.selectedOnExamination
      var complaint_diagnosis = editedValues.selectedDiagnosis
      var general_instruction = editedValues.selectedGeneralInstructions;
      var nutrition_diet_advice = editedValues.selectedDietaryAdvices

      let obj: any = {};
      var patient: any = {};
      var Drug: any = {}

      patient.first_name = this.patientData.firstName;
      patient.last_name = this.patientData.lastName;
      patient.gender = this.patientData.gender;
      patient.phone_number = this.patientData.contactNo;
      if (this.showOtherId == 1) {
        patient.otherIdNo = this.patientData.otherIdNo
      }

      patient.age = this.patientData.age;
      patient.weight = 'N/A';
      patient.height = 'N/A';
      console.log("patiMru", patient)
      obj = {
        "visit_id": this.appointmentData.VisitId,
        "clinic_id": null,
        "read_only": false,
        "date": this.appointmentData.visit_date,
        "appointmentId": this.appointmentData.id,
        "action": "share",
        "patientId": this.patientId,
        "patient": patient,
        "otherIdNo": this.patientData.otherIdNo,
        "medicines": this.allMedicines, //pending
        "general_instruction": general_instruction,
        "covidQues": "",
        "suggested_investigation": '',
        "chief_complaints": chief_complaints,
        "next_vaccination": nextFollowUp,
        "expirationDate": '',
        "on_examination": on_examination,
        "complaint_diagnosis": complaint_diagnosis,
        "savePrescriptionParams": {},
        //  "printingFields":this.printingFields,
        'newVersion': true,
        "medicinePreview": '',//pending
        "language": this.selectedLanguage.language,
        "nutrition_diet_advice": nutrition_diet_advice,
        "templateName": this.templateName,
        "favApptName": this.favApptName,
      }
      this.allMedicines.map((ins:any) => {
        if (ins.dosage2) {

          ins.dosage2 = ins.dosage2.split(",").join(" - ");
        }
        // ins.dosage2 = ins.dosage2.replaceAll(',', ' - ')


      })
      let savePrescriptionParams = {
        visit: {
          complaint_diagnosis: obj.complaint_diagnosis,
          general_instruction: obj.general_instruction,
          chief_complaints: obj.chief_complaints,
          next_vaccination: obj.next_vaccination,
          complaint_examination: obj.on_examination,
          suggested_investigation: obj.suggested_investigation,

         covidQues: null as string | null,
          expirationDate: obj.expirationDate,
          nutrition_diet_advice: obj.nutrition_diet_advice,
        },
        patient_id: obj.patientId,
        visit_id: obj.visit_id,
        medicine_list: this.allMedicines,

        deleted_medicines: obj.deletedMedicines,
      }
      obj.savePrescriptionParams = savePrescriptionParams;
      // var arr = this.selectFieldsArray.filter(field => { if (field.selected) { return field } });
      // arr = arr.map(field => { return field.value });
      obj.printingFields = this.printingFields;
      console.log("RETRO>>>>>>", JSON.stringify(obj.next_vaccination));


      if (this.appointmentId && this.appointmentId != '') {
        //if coming from appointment already created call V1 of printpresription()
        this.patientService.printPrescription(obj).subscribe((response:any) => {
          if (response) {

            console.log('PRESCRIPTION PDF Version 1', response)
            let url = response.url;
            //window.open(url);
            this.showLoading = false;

            sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));

            this.router.navigate([`/doctor/patients/prescriptions/preview`], { queryParams: { prescrip_url: url, patientId: this.patientId } });

          } else {
            this.showLoading = false;
          }
        });
      } else {
        console.log("NO APPOINTMENT ID FOUND !!!! CANNOT CALL generate PDF api");
      }

    }


  }

  onInputCustomComponent = (value:any) => {
    this.folloupMessage = value
  }


  setComponentValue = (data:any) => {
    console.log("data", data)

    this.selectedComplaints = data.selectedComplaints;
    this.selectedOnExamination = data.selectedOnExamination;
    this.selectedDiagnosis = data.selectedDiagnosis;
    this.selectedGeneralInstructions = data.selectedGeneralInstructions;
    this.selectedDietaryAdvices = data.selectedDietaryAdvices;
    this.deletedMedicineIds = data.deletedMedicineArr;
    console.log("deleted",this.deletedMedicineIds)

  }
  inputEvent(event:any) {
    // Return date object 
    console.log("cd", event.value);
  }
  changeEvent(event:any) {
    // Return date object
    console.log("zcz", event.value);
  }

  checkFollowUpData = () => {

    if (this.nextAppointmentDays) {
      console.log("app", this.nextAppointmentDays)
      this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + this.nextAppointmentDays));

    }
    else {
      this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + 5));
    }

    if (this.followUpData && this.followUpData.next_vaccination.length) {

      if (this.appointmentFlag == 2) {
        this.followupType = "Visit"
      }

      localStorage.setItem("followupType", this.followupType)
      var data: any = this.followUpData.next_vaccination ? this.followUpData.next_vaccination : ''
      data = data.split(':');

      let message = '';
      data.map((ins:any) => {
        if (ins.includes("Visit") || ins.includes("Call") || ins.includes("Appointment")) {
          this.followupType = ins
        }
        else if (ins.includes('Wed') || ins.includes('Mon') || ins.includes('Sun') || ins.includes('Tue') || ins.includes('F') || ins.includes('Sat')) {
          this.date = ins
          var date: any = this.reverseDate(this.date);
          var fromDate = moment(new Date(this.parseDate(date))).format("MM/DD/YYYY");
          this.date = fromDate
          this.followupDateNew = new Date(this.date)
        }
        else {
          console.log('RSVP03', ins)
          message = ins;
        }
      });

      this.langFollowupMessage.appendTexttoBox(message)
    }


  }
  onDateChangeForVacc(event:any){
    // var vaccDate: any = this.reverseDate(event.value);
    // vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
    this.vacDate=event.value
  }

  onDateChange(event:any){
    console.log("date",event)
    var followupDate: any = this.reverseDate(event.value);
    followupDate = moment(new Date(this.parseDate(followupDate))).format("MM/DD/YYYY");
    localStorage.setItem("followupDate",followupDate)
    console.log("dateFo",followupDate)
  }

  onInput = (key:any, value:any) => {

    switch (key) {
      case 'selectedComplaints':
        this.selectedComplaints = value;
        break;

      case 'selectedOnExamination':
        this.selectedOnExamination = value
        break;

      case 'selectedDiagnosis':
        this.selectedDiagnosis = value
        break;

      case 'selectedGeneralInstructions':
        this.selectedGeneralInstructions = value
        break;

      case 'selectedDietaryAdvices':
        this.selectedDietaryAdvices = value
        break;

      default:
        break;
    }
    console.log(key, value)
  }

  onDropdownSelectLanguage(key:any, value:any) {


    let lang = "";

    switch (value) {
      case 'English':
        lang = "en"
        break;

      case 'Hindi':
        lang = "hi"
        break;

      case 'Marathi':
        lang = "mr"
        break;

      case 'Kannada':
        lang = "kn"
        break;

      default:
        break;
    }

    this.selectedLanguage = { name: value, language: lang };

    localStorage.setItem("doctorsLanguage",JSON.stringify(this.selectedLanguage));
  }

  onDropdownSelectTemplate(key:any, value:any) {
      this.templateName = value
      localStorage.setItem("selectDoctorsTemplate",  this.templateName);
    
  }
  onDropdownSelectFollowUpForQ(key:any) {


    this.followupType = key
    localStorage.setItem("selectApptfollowupType",key);
    localStorage.setItem("bookAppointment", this.followupType);
    if (this.followupType == "Visit") {
      this.appointmentBook = false
      console.log("data:", this.followupType)
    }

  }

  onDropdownSelectFollowUpTypes(key:any, value:any) {
      this.followupType = value
  localStorage.setItem("selectApptfollowupType",value);
      if (value == "Appointment") {
        this.appointmentBook = true
      
      }
  }
  matOptionMethod(item:any){
   
      this.selectedFielfFlag=true
    
   
    
  }

  selectModalFieldValue = (item:any) => {
 let selectedFieldsArray=selectFields
 selectedFieldsArray.map(ins=>ins.selected=false)
 selectedFieldsArray.map(ins=>{
   item.map((itr:any)=>{
     if(ins.name==itr.name){
       ins.selected=true
     }
   })
 })
 var data =  selectedFieldsArray;

 this.selectFieldsArray=selectedFieldsArray
  console.log("item",selectedFieldsArray)
  var arr = data.filter(field => { if (field.selected) { return field} });
  this.printingFields = arr.map(field => { return field.value } );
  
    // if (key == 'selectFieldsArray') {
    //   var data = item;
    //   var arr = data.filter(field => { if (field.selected) { return field}
 
    // });
    //   this.printingFields = arr.map(field => { return field.value });
    
    //   console.log("----",this.isAllSelected)

    // }
    // else {
    //   var arr = this.selectFieldsArray.filter(field => { if (field.selected) { return field } });
    //   this.printingFields = arr.map(field => { return field.value });
     

    // }


  }

  updatePrescriptionOfComponent() {

    let editedValues = this.editViewPrescriptioncomponent.geteditedValues();
    console.log("edit", editedValues)
    this.setComponentValue(editedValues);

    this.previewPrescriptionPDF(1);
  }

  backToEdit() {
    this.editViewPrescriptioncomponent.addAnotherMedicine();

  }

  toggleFavouriteBox() {
    if (this.favaddFavPrescriptionTextBox) {
      this.favApptName = "";
      this.favaddFavPrescriptionTextBox = false;
    } else {

      setTimeout(() => {
        const favInputElement = document.getElementById('favInput');
        if (favInputElement) { // Check if otpElement is not null
          favInputElement.focus();
        }
      }, 500);
      // this.favInput.nativeElement.focus();
      this.favaddFavPrescriptionTextBox = true

    }
  }



}