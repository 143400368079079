import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { CdkColumnDef } from '@angular/cdk/table';
import { PatientService } from '../../../services/patient.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../../../util/utilService'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import moment from 'moment';
import { refreshService } from '../../../services/refersh.service';
import { ActivatedRoute } from '@angular/router';

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
@Component({
  selector: 'app-patient-receipt',
  templateUrl: './patient-receipt.component.html',
  styleUrls: ['./patient-receipt.component.scss'],
  providers: [MessageService, CdkColumnDef, { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})
export class PatientReceiptComponent implements OnInit {

  @ViewChild("viewReceiptModal") viewReceiptModal!: ElementRef;
  @ViewChild("receiptContent_modal") receiptContent_modal!: ElementRef;
  visitReasonArr = [{ reason: 'Consultation', reasonAmount: "", selected: false }, { reason: 'Tele-Consultation', reasonAmount: "", selected: false }, { reason: 'Vaccination', reasonAmount: "", selected: false }, { reason: 'Consultation+Vaccination', reasonAmount: "", selected: false }, { reason: 'Nebulization', reasonAmount: "", selected: false }, { reason: 'Dressing/Plaster charges', reasonAmount: "", selected: false }, { reason: "Other", reasonAmount: "", selected: false, otherReason: "other" }]
  visitReason: any = []
  otherReason: any = ""
  displayedColumns: string[] = ['date', 'amount', 'action', 'view'];
  paymentMethod = ['Cash', 'UPI/G-Pay/Paytm/PhonePay', 'NEFT', 'Other']
  paymentMethodName = ""
  selectedTemplate: any = [];
  amount: any = 0
  moment: any = moment;
  amountInWords: any;
  receiptPdfURL: any
  receiptURL: any
  receiptNo: any
  allReceiptArr: any = []
  receiptDate: any
  payerName: any
  minDate: any;
  pramPatientId: any;
  patientName = ""
  selectedReceipt: any
  showLoading: boolean = false
  templateName: any = "Speciality";
  isPediatric = false
  @ViewChild('container') private container!: ElementRef;

  @Input() doctorObj: any
  @Input() patientObj: any;
  @Input() patientType: any
  constructor(public dialog: MatDialog, private refreshservice: refreshService, private util: UtilService, private sanitizer: DomSanitizer, private messageService: MessageService, private patientService: PatientService, private route: ActivatedRoute) {
    this.receiptDate = new Date();
    this.minDate = new Date()
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params:any) => {
      this.pramPatientId = this.pramPatientId ? params.patientId : params.patientId
    })
    const patientDataString = localStorage.getItem('patientMoreData');

    let patientDataFromLocal: any;

    if (patientDataString) {
      patientDataFromLocal = JSON.parse(patientDataString);
    } else {
      // Handle the case when there is no data found for the given key
      patientDataFromLocal = {}; // or set to null, or any default value
      console.warn('No data found for key: patientMoreData');
    }


    if (this.patientType) {
      this.isPediatric = true
    }
    console.log("apr6", this.patientObj)
    this.patientName = patientDataFromLocal.firstName + " " + patientDataFromLocal.lastName;
    console.log("patientName", this.patientName);

    setTimeout(() => {
      this.getAllReceipt()
    }, 100)
    this.templateName = 'default';
    console.log("docObj", this.doctorObj)
    var services = JSON.parse(this.doctorObj.services);
    if (services.template_names) {
      this.templateName = services.template_names[0];
      this.selectedTemplate = services.template_names;
    }
    else {
      this.selectedTemplate.push('default');
    }


  }

  addMoreRow = () => {

    this.visitReasonArr.push({ "reason": "", "reasonAmount": '', selected: false, otherReason: 'other' })
  }

  setReceiptItem(receipt: any) {
    this.selectedReceipt = receipt;
  }
  selectVisitReasonValue(item: any, index: any) {
    if (this.visitReasonArr[index].selected == true) {
      this.visitReasonArr[index].selected = false;
    }
    else {
      this.visitReasonArr[index].selected = true;
      this.visitReason.push({ "reason": item.reason, "reasonAmount": item.reasonAmount, selected: true })
    }
  }
  onClickBillDelete() {

    this.patientService.deleteReceipt(this.selectedReceipt).subscribe((response:any) => {
      if (response) {
        this.closeDialog();
        this.getAllReceipt();
      }
    });
  }
  onDropdownSelectTemplate = (item: any) => {
    this.templateName = item
  }
  getTotal(item: any) {

    let total = 0

    this.visitReasonArr.map(item => {
      if (item && item.reasonAmount != "") {
        item.selected = true
        total += Number(item.reasonAmount)


      }
    })
    this.amount = total
  }
  validateNumber(e: any, usedFor: any) {

    if (e.charCode === 13 && usedFor === 'receipt') {

    }

    if (usedFor === 'receipt') {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9)
    } else {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 110) || (e.keyCode == 46)
    }
  }
  onClickViewReceipt(receipt: any) {

    this.receiptPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(receipt.billUrl);
    this.receiptURL = receipt.billUrl;
    this.openDialog(this.viewReceiptModal);
    //this.isViewRecipt = true;
  }
  openDialog(content: any) {
    this.dialog.open(content);
  }
  closeDialog() {

    this.dialog.closeAll();
  }
  previewReceipt() {
    this.amount = this.amount

    this.amountInWords = this.util.numberToWord(this.amount);
    if (this.templateName == "Speciality") {
      // alert("hello")
      this.messageService.add({ severity: 'custom', detail: 'Please specify speciality before proceeding' });


    }
    else if (this.visitReasonArr.length == 0) {
      this.messageService.add({ severity: 'custom', detail: 'Please specify visit reason before proceeding' });

    }
    else if (this.amount === 0) {
      this.messageService.add({ severity: 'custom', detail: 'Please specify amount before proceeding' });

    }
    else {
      this.openDialog(this.receiptContent_modal)
    }
  }

  printReceipt(action: any) {
    this.amount = this.amount

    this.amountInWords = this.util.numberToWord(this.amount)

    var date: any = new Date(this.receiptDate);
    date = date ? date : ''
    var nextDate: any = date.toDateString();

    if (this.templateName == "Speciality") {
      // alert("hello")
      this.messageService.add({ severity: 'custom', detail: 'Please specify speciality before proceeding' });


    }
    else if (this.visitReasonArr.length == 0) {
      this.messageService.add({ severity: 'custom', detail: 'Please specify visit reason before proceeding' });

    }
    else if (this.amount === 0) {
      this.messageService.add({ severity: 'custom', detail: 'Please specify amount before proceeding' });

    }

    else if (this.visitReason === 'Other') {
      this.messageService.add({ severity: 'custom', detail: 'Please specify visit reason before proceeding' });

    }
    else {


      var postData: any = {
        patient_id: this.patientObj.id ? this.patientObj.id : this.pramPatientId,
        type: 'Receipt',
        billDate: nextDate,
        billNo: this.receiptNo,
        amount: this.amount,
        amountInWords: this.amountInWords.charAt(0).toUpperCase() + this.amountInWords.slice(1),
        action: action,
        payerName: this.payerName,
        paymentMethodName: this.paymentMethodName ? this.paymentMethodName : "",
        templateName: this.templateName
      };

      // Check if otherReason is provided and set visitReason accordingly
      if (this.otherReason !== "") {
        postData.visitReason = this.otherReason;
        console.log("postData.visitReason set from otherReason:", postData.visitReason);
      } else {
        postData.visitReason = "Other";
        console.log("postData.visitReason set to 'Other':", postData.visitReason);
      }

      // Iterate through visitReasonArr to ensure no blank reasons
      this.visitReasonArr.forEach(item => {
        if (item.reason === '') {
          item.reason = 'Other';
        }
      });

      // Convert the array to JSON string
      postData.visitReason = JSON.stringify(this.visitReasonArr);

      this.showLoading = true;
      console.log("printReceipt postData:", postData.visitReason);


      this.patientService.printReceipt(postData).subscribe((response:any) => {
        if (response) {
          this.showLoading = false;
          // this.modalService.dismissAll();
          this.receiptPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
          this.receiptURL = response.url;

          if (action === 'preview') {
            this.openDialog(this.viewReceiptModal);
          }
          this.getAllReceipt();
          this.closeAddReceiptModal()
        }
      });
    }
  }
  onClickBillShare(billUrl: any) {
    var postData = {
      patient_id: this.patientObj.id ? this.patientObj.id : this.patientObj._id,
      url: billUrl
    }
    this.patientService.shareReceipt(postData).subscribe((response:any) => {
      if (response) {

      }
    });
  }
  closeAddReceiptModal() {
    this.paymentMethodName = "";
    this.payerName = ""
    this.receiptDate = new Date();
    this.amount = 0;
    this.receiptNo = '';
    this.visitReason = '';
    let visits = this.visitReasonArr
    visits.map(ins => {
      if (ins.selected = true) {
        ins.selected = false
        ins.reasonAmount = ""

      }
      if (ins.otherReason == "other") {
        ins.reason = ""
      }
    })
    this.visitReasonArr = visits
  }
  getAllReceipt() {

    this.showLoading = true;
    var postData = {
      patient_id: this.patientObj.id ? this.patientObj.id : this.pramPatientId,
    }
    console.log("getAllReceipt postdata", postData, this.patientObj.id);

    this.patientService.getAllReceipt(postData,).subscribe((response:any) => {
      this.showLoading = false;
      if (response) {
        console.log("res", response)
        for (let index = 0; index < response.bills.length; index++) {
          const element = response.bills[index];

          element.date = moment(element.date).format('DD/MM/YYYY')
        }
        this.allReceiptArr = response.bills
        this.allReceiptArr.map((ins: any) => {
          var matches = ins.visitReason.match(/\[(.*?)\]/);
          if (matches) {
            ins.visitReason = JSON.parse(ins.visitReason)
          }
          else {
            ins.visitReason = [{ "reason": ins.visitReason }];
          }
        })

        this.allReceiptArr = new MatTableDataSource(this.allReceiptArr)

      }

    });
    // this.refreshservice.documentRefershed()
  }
  onDropdownSelecPayment(value: any) {
    this.paymentMethodName = value

  }
}
