

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { UtilService } from '../../../util/utilService';
import * as _ from "lodash"
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { refreshService } from '../../../services/refersh.service'
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { debounce } from 'lodash';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { LanguageSelectTextboxComponent } from '../../../components/language-select-textbox/language-select-textbox.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
interface patientInterface {
  id: number;
  formattedDOB: string;
  vaccinationStartDate: string;
  createdAt: string;

  User: {
    dob: string,
    gender: string
  }
}
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'patient-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.css'],
  providers: [ { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})

export class ImmunizationComponent implements OnInit {
  confirmVaccDate: boolean = false
  selectedRowId:any = null
  vaccineBrandsOrg: any = []
  barndQuantity:any = null
  barndName:any = ""
  @Input() patientObj!: patientInterface;
  @Input() doctorObj: any = [];
  @Input() specialVaccineInfo: any = {};
  @Input() specialSituationVaccines: any = [];
  pendingVaccinationDate:any;
  pendingVaccination: any = [];
  vaccineList: any = [];
  upcomingVaccineList: any = [];
  patientDataFromLocal:any;
  patientId:any;
  showInfo: boolean = false;
  @Input() splVaccFlag!:any;
  patientVaccinationDate:any
  minVaccinationDate: any;
  patientDoB!:Date;
 setDOBForMorePData:any;
 setVaccDateForMorePData:any;
 OldDOB:any;
  changedDOB:any;
  @Output() appReady = new EventEmitter<void>();

  @ViewChild("previewVaccineModal") previewVaccineModal:any;
  @ViewChild("specialVaccineModal") specialVaccineModal:any;
  @ViewChild("newSpecialVaccineModal") newSpecialVaccineModal:any;
  @ViewChild("bulkVaccineUpdate") bulkVaccineUpdate:any;
  @ViewChild("confirmVacStartDate") confirmVacStartDate:any;
  @ViewChild("dateFormat_modal") dateFormat_modal:any;  
  @ViewChild('langInstructions') langInstructions!: LanguageSelectTextboxComponent;
   @ViewChild("infoPopUp") infoPopUp !: TemplateRef<any>;
   @ViewChild("pendingVaccination_modal") pendingVaccination_modal!: ElementRef;


  selectedVaccIndex: any = ""
  saveSpecialVaccButton = false;
  unformattedVaccineList: any[] = [];
  vaccineScheduleListWithVaccinedata: any = [];
  splVaccineName = "";
  displayedColumnsGOVT: string[] = ['visit', 'vaccine', 'receivedDate']
  displayedColumns1: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate'];
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate', 'status', 'receivedDate', 'brand', 'action'];
  selectedVacRecord: object = {};
  selectedVacVisit: any = {};

  selectedVacIndex:any = 0;
  selectedBrandIndex:any = null;

  selectedDate = "";
  receivedDate : Date;
  viewOrRecordClicked:string;
  dateError: boolean = false;
  selectedBrand: any = {};
  selectedVaccine: any = {};

  vaccineBrands:any[] = [];
  selectedVaccination: any = []

  ageInYear: any = null
  patientInfoArray: any = [];
  patientMoreData: any = {};

  allAdministeredVaccines: any = [];
  flagForCertificateVacc: boolean = false
  maxDate:any = null;
  minDate:any = null;

selectedVaccineLabels:any[] = [];
  selectedLanguage: any = "en"
  pendingVaccines:any[] = [];

  allTranscripts: any = [];
  transcriptIndex: number = 0;
  govtVaccineList: any = []
  constructor(private router: Router, private messageService: MessageService, private patientService: PatientService, private util: UtilService, public dialog: MatDialog, private refreshservice: refreshService,private cdRef: ChangeDetectorRef,private route:ActivatedRoute) { 
    this.receivedDate = new Date();
  }

  ngOnInit(): void {
    const storedPatientData = localStorage.getItem("patientData");
    if (storedPatientData) {
      this.patientDataFromLocal = JSON.parse(storedPatientData);
    } else {
      this.patientDataFromLocal = null; // Handle the case where no data is found
    }
    this.route.queryParams
      .subscribe((params:any) => {
console.log("paramsparams",params.patientId);
this.patientId = params.patientId

      })
      this.loadPendingVaccination();
    let today = new Date()
    this.maxDate = this.util.reverseDate(today);


    this.getPatientInfoForVac();
    this.getPatientsVaccination();
    this.groupbyscheduleforasha()
console.log("patientMoreData",this.patientMoreData);

    if (this.splVaccFlag) {
      this.confirmVaccDate = true
      this.selectedVacIndex = 0;
      setTimeout(() => {
        this.openDialog(this.newSpecialVaccineModal, 'newSpecialVaccineModal')
      }, 500)

    }
    // setTimeout(() => {
    //   var elmnt = document.getElementById(this.selectedRowId);
    //   elmnt.scrollIntoView({behavior: 'smooth', block: 'center', inline : 'center'});

    // }, 1000);


  }
  isValidDate(date: any): boolean {
    return date && !isNaN(new Date(date).getTime());
  }
  
  onModelChange(event: any) {
    if (event) {
      this.receivedDate = moment(event, 'DD/MM/YYYY').toDate(); // Convert to Date object
      console.log("Updated Date:", this.receivedDate);
      this.cdRef.detectChanges(); // Force UI update
    }
  }
  formattedReceivedDate: string = '';
  onDateChange(event: any) {
    if (event) {
      this.receivedDate = event.value; // Update model with Date object
      this.formattedReceivedDate = moment(event.value).format('DD/MM/YYYY'); // Format date

      console.log("Updated Date:", this.receivedDate, "Formatted Date:", this.formattedReceivedDate);

      // Trigger UI update manually
      this.cdRef.detectChanges();
    }
  }
  
  

  
  
  groupbyscheduleforasha() {
    this.govtVaccineList = []

    this.patientService.groupbyscheduleforasha(this.patientObj.id? this.patientObj.id : this.patientMoreData._id).subscribe((response:any) => {
      if (response) {
        // this.allVaccines = response;
        console.log("JUNgov30", response)
        this.govtVaccineList = response;
        for (let i = 0; i < this.govtVaccineList.length; i++) {
          if (this.isJson(this.govtVaccineList[i].name)) {
            let scheduleNames = JSON.parse(this.govtVaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach((langpart:any) => {
                if (langpart.lang == 'en') {
                  this.govtVaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray:any[] = [];
        let newArray:any[] = [];

        let tempArr:any[] = [];

        console.log('ExperimentalJun30', this.govtVaccineList)

        this.govtVaccineList.map((ins:any) => {

          ins.data.map((item:any, idx:any) => {

            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }
            if (idx % 2 == 0) {
              item["indexValue"] = "even"
            }
            else {
              item["indexValue"] = "odd"
            }

            item['visitName'] = ins.vaccinationName
              ;
            item['index'] = idx;
            item['brandvaccine'] = item.brandvaccine ? item.brandvaccine : ""

            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });
        });

        var govtVaccineList: any = []
        //  this.unformattedVaccineList = this.govtVaccineList;
        this.govtVaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        // this.alltestVac = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        govtVaccineList = vaccineArray;
        console.log("Govt", this.govtVaccineList)

        for (let i = 0; i < govtVaccineList.length; i++) {
          if (this.isJson(govtVaccineList[i].name)) {
            let scheduleNames = JSON.parse(govtVaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach((langpart:any) => {
                if (langpart.lang == 'en') {
                  govtVaccineList[i].vaccinationName = langpart.text;
                }
              });
            }
          }
        }
        govtVaccineList.map((ins:any) => {
          if (ins.administeredDate != null) {
            this.govtVaccineList.push(ins)
          }
        })

        this.govtVaccineList = govtVaccineList
        console.log("govVacccc", this.govtVaccineList)
      }

    })
  }
  getDob(){
    this.dateError=false
    var patientDoB= moment((this.patientMoreData.DOB)).format('DD/MM/YYYY')
    this.patientDoB=new Date(this.patientMoreData.DOB)
    console.log("DOB Date Before Change ",this.patientDoB)
    this.OldDOB = moment((this.patientDoB)).format('DD/MM/YYYY')
    console.log("OldDOB",this.OldDOB);
   // let vacDate = moment(this.patientMoreData.vaccinationStartDate).format('DD/MM/YYYY')
    this.patientVaccinationDate=new Date(this.patientMoreData.vaccinationStartDate)
    
  }
  getPatientInfoForVac() {
    var patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData")??'null');
    console.log("may1555555", patientMoreData)
    var patientDoB= moment((patientMoreData.DOB)).format('DD/MM/YYYY')
    this.patientDoB=new Date(patientDoB)
    console.log("DOB",this.patientDoB)
    let vacDate = moment(patientMoreData.vaccinationStartDate).format('DD/MM/YYYY')
    this.patientVaccinationDate=new Date(vacDate)

    this.ageInYear = patientMoreData.age.years
    this.ageInYear = this.ageInYear + " " + "Year"
    console.log("AgeInYear", this.ageInYear)

    let formattedVacStartDate = moment(patientMoreData.vaccinationStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    patientMoreData['formattedVacStartDate'] = formattedVacStartDate;

    this.selectedLanguage = patientMoreData.parent.selectedLanguage ? patientMoreData.parent.selectedLanguage : "en";

    let additionalInfo: any = patientMoreData.additionalInfo ? JSON.parse(patientMoreData.additionalInfo) : null;

    setTimeout(() => {
      if (additionalInfo?.specialVaccination) {

        additionalInfo.specialVaccination.map((ins:any, ind:any) => {
          ins['visitName'] = "Special Vaccines";
          if (ind == 0) {
            ins['vaccineVisit'] = "Special Vaccines";
            ins['index'] = ind
          } else {
            ins['index'] = ind
          }

          if (this.specialVaccineInfo[ins.name]) {
            ins['description'] = this.specialVaccineInfo[ins.name]
          }
        });

        let specialVaccines = { name: "Special Vaccines", data: additionalInfo.specialVaccination }
        this.unformattedVaccineList.push(specialVaccines);
        this.vaccineScheduleListWithVaccinedata = [...additionalInfo.specialVaccination, ...this.vaccineScheduleListWithVaccinedata];

        console.log("Vaccines", this.vaccineScheduleListWithVaccinedata)
      } else {
        let additionalInfo: any = {};
        additionalInfo['specialVaccination'] = [
          {
            administeredDate: "",
            administeredStatus: null,
            name: "",
            vaccineVisit: "Special Vaccines",
            visitName: "Special Vaccines"
          }
        ];
        // this.vaccineList = [...additionalInfo.specialVaccination, ...this.vaccineList]
      }
    }, 500);

    let patInformationArray = [];

    let birth = {
      key: 'Birth Date',
      value: moment(patientMoreData.formattedDOB, 'DD/MM/YYYY').format('DD/MM/YY')
    };

    this.minDate = this.util.reverseDate(moment(patientMoreData.formattedDOB, 'DD/MM/YYYY').format('MM/DD/YY'));
    console.log('MINDATE', this.minDate)

    let vacStartDate = {
      key: 'Vac Start Date',
      value: moment(patientMoreData.vaccinationStartDate).format('DD/MM/YY')
    }

    let joiningDate = {
      key: 'Joining Date',
      value: moment(patientMoreData.createdAt).format('DD/MM/YY')
    }

    var y = moment(patientMoreData.formattedDOB, 'DD/MM/YYYY')
    var x = moment(patientMoreData.createdAt)

    let ageObj = moment.duration(x.diff(y))
    let ageAtJoining = {
      key: 'Age at Joining',
      value: `${Math.floor(ageObj.years())}y ${Math.floor(ageObj.months())}m ${Math.floor(ageObj.days())}d`
    }

    patInformationArray.push(birth, vacStartDate, joiningDate, ageAtJoining);
    this.patientInfoArray = patInformationArray;
    console.log("AUGGG30",this.patientInfoArray)
    this.patientMoreData = patientMoreData;
  }

  getPatientsVaccination = () => {
    this.vaccineScheduleListWithVaccinedata = []
    let patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData")??'null');
    console.log("Patient More Data", patientMoreData)
    let joiningDate = moment(patientMoreData.createdAt).format('DD MMM, YYYY')

    this.patientService.getPatientsVaccination(this.patientObj.id? this.patientObj.id : this.patientMoreData._id).subscribe((response:any) => {
      let vaccineList: any = []
      if (response) {
        this.vaccineScheduleListWithVaccinedata = response;
        console.log("maayddddd", this.vaccineScheduleListWithVaccinedata)
        for (let i = 0; i < this.vaccineScheduleListWithVaccinedata.length; i++) {
          if (this.isJson(this.vaccineScheduleListWithVaccinedata[i].name)) {
            let scheduleNames = JSON.parse(this.vaccineScheduleListWithVaccinedata[i].name);

            console.log('Schedule Names', scheduleNames)

            if (scheduleNames && scheduleNames.length > 0) {

              scheduleNames.forEach((langpart:any) => {
                if (langpart.lang == 'en') {
                  this.vaccineScheduleListWithVaccinedata[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray:any[] = [];
        let newArray:any[] = [];
        let tempArr:any[] = [];
        let administeredVaccines:any[] = [];
        let pendingVaccines:any[] = []


        this.vaccineScheduleListWithVaccinedata.map((ins:any, index:any) => {
          ins.data.map((item:any, idx:any) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }
            if (index % 2 == 0) {
              item["indexValue"] = "even"
            }
            else {
              item["indexValue"] = "odd"
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            if (moment(item.dueDate).isBefore(joiningDate)) {
              item['beforeJoining'] = true;
            }
            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines
            if (item.administeredStatus) {
              administeredVaccines.push(item);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true;

              if (!item.administeredStatus) {
                pendingVaccines.push(item);
              }

            }
         vaccineArray.push(item);
          });

        });

        this.unformattedVaccineList = this.vaccineScheduleListWithVaccinedata;
        // this.vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.vaccineScheduleListWithVaccinedata = vaccineArray;
        this.pendingVaccines = pendingVaccines;
        this.allAdministeredVaccines = administeredVaccines;

        // if(this.vaccineScheduleListWithVaccinedata.some(itr=>itr.administeredStatus)){}
        let atleastOneVacGiven = this.vaccineScheduleListWithVaccinedata.some((itr:any) => {
          return itr.administeredStatus;
        });

        console.log('patientmoredata', patientMoreData)
        if (!atleastOneVacGiven && patientMoreData.formattedDOB.includes("01/01") && patientMoreData.age.years <= 10) {
          if (this.confirmVaccDate == false) {
            setTimeout(() => {
              this.dialog.open(this.confirmVacStartDate, {});
              console.log("thismodal called");
              
            }, 500);
          }
        }

        this.confirmVacStartDate=false;


        this.vaccineScheduleListWithVaccinedata.map((ins:any, idx:any) => {
          ins["idx"] = idx
        })
        this.vaccineScheduleListWithVaccinedata.map((ele:any) => {
          if (ele.vaccineVisit.includes(this.ageInYear)) {
            this.selectedRowId = ele.idx
          }

        })
        vaccineList.map((ins: any) => {
          ins.data.map((item: any, idx: any) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines for today
            if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
              administeredVaccines.push(item.name);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });

        });
         // this.unformattedVaccineList = vaccineList;
         vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
         vaccineList = vaccineArray;
         this.vaccineList = vaccineList
         console.log('Administered Vaccines', administeredVaccines);
         console.log('Vaccine List', vaccineList);
        console.log(this.selectedRowId)
        console.log('Vaccine List', this.vaccineScheduleListWithVaccinedata)
      }
    });
  }
  loadPendingVaccination() {
    let pendingVaccines = JSON.parse(localStorage.getItem("pendingVaccinationForPrint") || "[]");

    console.log("🛢️ All Pending Vaccines:", pendingVaccines);
    console.log("🆔 Current Patient ID:", this.patientId);

    const patientData = pendingVaccines.find((p: any) => p.patientId == this.patientId);

    if (patientData) {
        this.pendingVaccinationDate = patientData.suggestedDate;
        this.selectedVaccination = [...patientData.data]; // ✅ Load checked vaccines
        console.log("✅ Loaded Vaccinations for Patient:", this.selectedVaccination);
    } else {
        this.selectedVaccination = []; // ❌ Reset list if no data
        console.warn("⚠️ No pending vaccinations found for this patient.");
    }
}
isVaccineSelected(vaccine: any): boolean {
  let pendingVaccines = JSON.parse(localStorage.getItem("pendingVaccinationForPrint") || "[]");
  let patientData = pendingVaccines.find((p: any) => p.patientId === this.patientId);

  return patientData?.data.some((v: any) => v.name === vaccine.name && v.visitName === vaccine.visitName) || false;
}
getVaccData(element: any, event: MatCheckboxChange) {
  if (event.checked) {
      // Add to selected list if not already present
      if (!this.selectedVaccination.some((v:any) => v.name === element.name && v.visitName === element.visitName)) {
          this.selectedVaccination.push(element);
      }
  } else {
      // Remove if unchecked
      this.selectedVaccination = this.selectedVaccination.filter((v:any) => v.name !== element.name || v.visitName !== element.visitName);
  }

  console.log("✅ Updated Selected Vaccination List:", this.selectedVaccination);
}


  selectedRowIndex = -1;
  selectedRows(item:any) {
    this.selectedRowIndex = item.id;

  }
  savePendingVaccination() {
    console.log("🔍 Selected Vaccination Before Saving:", this.selectedVaccination, this.pendingVaccinationDate);
        // Check if no vaccine is selected
        if (this.selectedVaccination.length === 0) {
          this.messageService.add({ severity: 'custom', detail: 'Please Select Pending Vaccine'});
          return;
        }
        if (!this.pendingVaccinationDate || this.pendingVaccinationDate === null || this.pendingVaccinationDate === undefined) {
          this.messageService.add({ severity: 'custom', detail: 'Please Select Date' });
          return;
      }
      
        this.closeDialog();

    var patientId = this.patientId;

    // Retrieve existing pending vaccines
    let pendingVaccines = JSON.parse(localStorage.getItem("pendingVaccinationForPrint") || "[]");

    // Find if patient already exists
    const existingIndex = pendingVaccines.findIndex((p: any) => p.patientId === patientId);

    if (existingIndex !== -1) {
        if (this.selectedVaccination.length === 0) {
            // ❌ Remove patient if no vaccines are selected
            pendingVaccines.splice(existingIndex, 1);
        } else {
            // 🔄 Replace old vaccines with selected ones
            pendingVaccines[existingIndex].data = [...this.selectedVaccination];
            pendingVaccines[existingIndex].suggestedDate = this.pendingVaccinationDate; // Update date
        }
    } else if (this.selectedVaccination.length > 0) {
        // 🆕 Add new patient entry if not found
        pendingVaccines.push({ patientId, suggestedDate: this.pendingVaccinationDate, data: [...this.selectedVaccination] });
    }

    // Save back to localStorage
    localStorage.setItem("pendingVaccinationForPrint", JSON.stringify(pendingVaccines));

    console.log("✅ Saved Pending Vaccines:", pendingVaccines);
}







  onDateChangeForVacc(event: any) {
    this.pendingVaccinationDate = event.value
  }
  selectFollowUpMessage(event: any) {

    // aaleenm
    // var followupmessage = this.langInstructions.getValue()
    // if (followupmessage == event.value) {

    // }
    // else {
    //   this.langInstructions.appendTexttoBox(event.value)

    // }

console.log("vaccineList12345",this.vaccineList);

    if (true) {
      this.vaccineList.map((ins: any) => {
        if (ins.isOverdue) {
          this.pendingVaccination.push(ins)
        }
        else if (!ins.administeredStatus) {
          this.upcomingVaccineList.push(ins)
        }
      })
      this.pendingVaccination = this.pendingVaccination.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.upcomingVaccineList = this.upcomingVaccineList.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);


      if(event == 0){
        // this.openDialog(this.pendingVaccination_modal, 'pendingVaccination_modal');
      }

    }


  }



  
  
  

  isJson(str:any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


 

  openNewSpecialVaccine = () => {
    this.receivedDate = null;
    this.confirmVaccDate = true
    this.selectedVacIndex = 0;
    this.openDialog(this.newSpecialVaccineModal, 'newSpecialVaccineModal')
  }

  openBulkVaccineUpdate = () => {
    let allTranscripts = localStorage.getItem("allTranscripts");
    if (allTranscripts) {
      allTranscripts = JSON.parse(localStorage.getItem("allTranscripts")??'null');
    }

    this.allTranscripts = allTranscripts;

    console.log('ALL TRANSCRIPTS', allTranscripts)

    this.openDialog(this.bulkVaccineUpdate, 'bulkVaccineUpdate');
  }

  changeTranscriptIndex(type:any) {
    let ind = this.transcriptIndex;

    if (type == 1) {

      if (ind >= this.allTranscripts.length - 1) {
        this.transcriptIndex = ind - 1
      }

    } else {

      if (ind < this.allTranscripts.length - 1) {
        this.transcriptIndex = ind + 1
      }

    }
  }

  closeDialog() {
    this.selectedDate = "";
    // this.receivedDate = "";
    this.selectedBrand = {};
    this.selectedBrandIndex = null;
    this.selectedVaccIndex = null
    // this.selectedVacVisit = {}
    this.dialog.closeAll()
  }

  getBrandsByVaccineId = () => {
    let vaccineIds:any[] = [];
    this.selectedVacVisit.data.map((ins:any) => {
      if (!ins.administeredStatus) {
        vaccineIds.push(ins.id)
      }
    })

    let postData = {
      vaccineIds: vaccineIds
    }

    this.patientService.getBrandsByVaccineId(postData).subscribe((response:any) => {
      if (response) {
        this.vaccineBrandsOrg = response;

        this.selectVaccine(0, this.selectedVaccine);
      }
    });
  }

  navigateToCertificate() {
    localStorage.setItem("isForImmunization", "true")
    this.refreshservice.OnPatTopNav_MenuSelected(this.patientObj.id + "", this.refreshservice.EventName_Certificate);
  }

  uploadBulkVaccination = () => {

    let dueVaccineArray = this.pendingVaccines;
    let selectedVacArray:any[] = []

    dueVaccineArray.forEach(item => {
      let newObj = {}
      if (item.selected) {
        newObj = {
          "patVacId": item.patVacId,
          "dueDate": item.dueDate,
          "patientId": this.patientObj.id? this.patientObj.id : this.patientMoreData._id,
          "brand": ""
        }

        selectedVacArray.push(newObj);
      }
    });

    if (selectedVacArray.length) {

      console.log('Finally Array', selectedVacArray);

      this.patientService.uploadBulkVaccination(selectedVacArray).subscribe((response:any) => {
        console.log('RESPONSE VACCINE', response)
        if (response) {

          setTimeout(() => {
            // this.getPatientInfoForVac();
            this.getPatientsVaccination();
          }, 500);

          this.closeDialog();
        }
      })

    } else {
      alert("Please select vaccination records to be administered");

    }

  }
  setSelectedVaccinationRecord = (element:any) => {
    // aaleen
    this.selectedVacIndex = null
console.log("setSelectedVaccinationRecord element",element.administeredStatus);
if(element.administeredStatus){
  this.viewOrRecordClicked = 'viewClicked';

}else{
  this.viewOrRecordClicked = 'recordClicked'
}
    setTimeout(() => {
      this.openDialog(this.previewVaccineModal, 'previewVaccineModal');
    }, 500);
    this.selectedVacRecord = element;

    loopRecord: for (let index = 0; index < this.unformattedVaccineList.length; index++) {
      const visit = this.unformattedVaccineList[index];
      if (element.visitName == visit.name) {
        this.selectedVacVisit = visit;
        this.selectedVacIndex = element.index;
        //  this.selectedVaccIndex = element.index
        // this.selectedVacIndex = element.idx;
        this.selectedDate = this.selectedVacVisit.data[this.selectedVacIndex].administeredDate;
        this.selectedVaccine = this.selectedVacVisit.data[this.selectedVacIndex];
        this.getBrandsByVaccineId();
        break loopRecord;
      }
    }
    let vaccineLabels:any[] = []
    this.selectedVacVisit?.data.map((ins:any) => {
      if (ins.imageUrl !== 'https://copious-opd-prod.s3.amazonaws.com/images/vaccines/' && ins.imageUrl !== 'https://copious-opd-prod.s3.amazonaws.com/images/vaccines/null') {
        vaccineLabels.push(ins)
      }
    });

    let newData = _.uniqBy(vaccineLabels, function (e) {
      return e.imageUrl;
    });

    this.selectedVaccineLabels = newData;

  }
  async handleVaccineSave() {
    console.log("handleVaccineSave viewOrRecordClicked", this.viewOrRecordClicked);

    try {
      //  Only validate required fields when "Record" is clicked
      if (this.viewOrRecordClicked === 'recordClicked') {
        if (this.selectedBrandIndex == null || !this.selectedBrand) {
          this.messageService.add({ severity: 'warn', detail: 'Please select a vaccine brand before saving.' });
          return;
        }

        if (!this.receivedDate) {
          this.messageService.add({ severity: 'warn', detail: 'Please select a received date before saving.' });
          return;
        }
      }

      // Step 2: Check if any pending vaccine is checked
      let isPendingVaccinationSelected = this.selectedVaccination && this.selectedVaccination.length > 0;
      let isPendingVaccinationDateSelected = this.pendingVaccinationDate != null && this.pendingVaccinationDate !== '';

      // Step 3: If pending vaccine is checked but no date is selected, show error
      if (isPendingVaccinationSelected && !isPendingVaccinationDateSelected) {
        this.messageService.add({ severity: 'warn', detail: 'Please select a date for the pending vaccination before saving.' });
        return;
      }

      // Step 4: If pending vaccine date is selected but no pending vaccine is checked, show error
      if (!isPendingVaccinationSelected && isPendingVaccinationDateSelected) {
        this.messageService.add({ severity: 'warn', detail: 'Please select a pending vaccine before saving.' });
        return;
      }

      // Step 5: Determine which function(s) to call
      let shouldCallMarkVaccineAsTaken = this.selectedVaccine && this.receivedDate && this.selectedBrand;
      let shouldCallSavePendingVaccination = isPendingVaccinationSelected && isPendingVaccinationDateSelected;

      // Step 6: Call functions based on conditions
      if (shouldCallMarkVaccineAsTaken && shouldCallSavePendingVaccination) {
        console.log("calling group A");
        await this.markVaccineAsTaken();
        await this.savePendingVaccination();
        this.refreshVaccinationList();
      } else if (shouldCallMarkVaccineAsTaken) {
        console.log("calling group B");
        await this.markVaccineAsTaken();
        this.refreshVaccinationList();
      } else if (shouldCallSavePendingVaccination) {
        console.log("calling group C");
        await this.savePendingVaccination();
        this.refreshVaccinationList();
      }

    } catch (error) {
      console.error("Error in handleVaccineSave:", error);
      this.messageService.add({ severity: 'error', detail: 'Something went wrong. Please try again.' });
    }
  }
  async refreshVaccinationList() {
    console.log("Refreshing vaccination list...");

    // Fetch updated data from the server
    setTimeout(() => {
      this.getPatientsVaccination();
    }, 2000);

    // Ensure change detection runs
    this.cdRef.detectChanges();
  }
  markVaccineAsTaken() {
    console.log("receivedDate showing invalid", moment(this.util.parseDate(this.receivedDate)).format('DD-MMM-YYYY'));
    let formattedDate = moment(this.receivedDate, 'DD/MM/YYYY').format('DD-MMM-YYYY');
    console.log("Formatted receivedDate:", formattedDate);
    let id = this.selectedVaccine.id
    let name = this.selectedVaccine.name
    let description = this.selectedVaccine.description
    let due = this.selectedVaccine.due
    let dueDate = this.selectedVaccine.dueDate

    let postData = {
      "id": id,
      "name": name,
      "administeredDate": formattedDate,
      "administeredStatus": true,
      "description": description,
      "due": due,
      "dueDate": dueDate,
      "imageUrl": "",
      "notes": "",
      "comboVaccineId": this.selectedBrand.id,
    };

    console.log("Debounce working..", postData)

    this.patientService.markVaccineAsTaken(postData, this.patientObj.id ? this.patientObj.id : this.patientMoreData._id).subscribe((response: any) => {
      console.log('RESPONSE VACCINE', response)
      if (response) {
        this.barndQuantity = response.reducedInventory.quantity
        this.barndName = response.reducedInventory.brandName

        this.messageService.add({ severity: 'custom', detail: this.barndName + ":" + this.barndQuantity + " " + "Remaining" });


        setTimeout(() => {
          this.getPatientsVaccination();
          this.getPatientInfoForVac();

        }, 200);
        this.patientMoreData.vaccinationStartDate = this.setVaccDateForMorePData;
        console.log("AUGGG30", this.patientInfoArray);
        this.changedDOB = moment((this.patientMoreData.DOB)).format('DD/MM/YYYY');
        this.patientMoreData.formattedDOB = this.changedDOB;
        console.log("ChangedDOB", this.changedDOB);

        let patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData") ?? 'null');
        var y = moment(patientMoreData.formattedDOB, 'DD/MM/YYYY')
        var x = moment(patientMoreData.createdAt)

        let ageObj = moment.duration(x.diff(y))
        let ageAtJoining = {
          key: 'Age at Joining',
          value: `${Math.floor(ageObj.years())}y ${Math.floor(ageObj.months())}m ${Math.floor(ageObj.days())}d`
        }
        let joiningDate = moment(patientMoreData.createdAt).format('DD MMM, YYYY')
        this.patientInfoArray.push(this.changedDOB, this.setVaccDateForMorePData, joiningDate, ageAtJoining);
        localStorage.setItem("patientMoreData", JSON.stringify(this.patientMoreData));

        this.closeDialog();

      }
    })
  }
  setSelectedSpecialVaccinationRecord = (element:any) => {
    setTimeout(() => {
      this.openDialog(this.specialVaccineModal, 'specialVaccineModal');
    }, 200);

    this.selectedVaccine = element;

    loopRecord: for (let index = 0; index < this.unformattedVaccineList.length; index++) {
      const visit = this.unformattedVaccineList[index];
      if (element.visitName == visit.name) {
        this.selectedVacVisit = visit;
        this.selectedVacIndex = element.index;
        this.selectedVaccIndex = element.index


        this.selectedDate = this.selectedVacVisit.data[this.selectedVacIndex].administeredDate;
        this.selectedVaccine = this.selectedVacVisit.data[this.selectedVacIndex]
        break loopRecord;
      }
    }
  }


 
  hideShowIAPGuidelines() {
    this.showInfo = !this.showInfo;
}
  selectVaccine = (idx:any, vaccine:any) => {
    //aaleen
    var vaccBarnd: any = []
    this.vaccineBrands = []
    this.selectedVaccine = vaccine;
    this.selectedVaccIndex = vaccine.index
    if (vaccine.index) {
      this.selectedVacIndex = vaccine.index;
    }
    else {
      this.selectedVacIndex = idx;
    }

    this.selectedDate = this.selectedVacVisit.data[idx].administeredDate;



    //logic for highlighting vaccines on the basis of BRANDS
    let brands:any = this.vaccineBrandsOrg;

    brands.map((item:any) => {
      var vaccines = item.mastervaccineids;
      var vaccinesArray = vaccines.split(',');
      let newArr = [];

      vaccinesArray.map((ins:any) => {
        if (ins != '') {
          newArr.push(Number(ins))
        }
      });

      item['highlighted'] = false;
      vaccinesArray.map((ins:any) => {
        if (ins != "" && ins == this.selectedVaccine.id) {
          item['highlighted'] = true
        }
      })

    });

    this.vaccineBrandsOrg = brands;
    this.vaccineBrandsOrg.map((ins:any) => {
      if (ins.highlighted) {
        vaccBarnd.push(ins)
      }
    })
    console.log(vaccBarnd)
    this.vaccineBrands = vaccBarnd
  }

  selectPendingVaccine = (idx:any, vaccine:any) => {
    //aaleen
    let pendingVaccines = this.pendingVaccines;
    if (!pendingVaccines[idx].selected) {
      pendingVaccines[idx].selected = true;
    } else {
      pendingVaccines[idx].selected = false;
    }
  }

  openVaccineInventory() {
    this.router.navigate([`/doctor/vaccineInventory`])
  }

  selectBrand = (idx:any, brand:any) => {
    this.selectedBrandIndex = idx;
    this.selectedBrand = brand;


    //logic for highlighting vaccines on the basis of BRANDS
    let pendingVaccines = this.selectedVacVisit.data;
    if (!brand.due) {
      var selectedVaccines = brand.mastervaccineids;
      var arr:any = selectedVaccines.split(',');
      let coversArray:any[] = []
      let covers = false;
      arr.map((ins:any) => {
        if (ins != "") {
          coversArray.push({ id: ins, covers: false })
        }
      })

      let selected = -1;

      loop1: for (let i = 0; i < pendingVaccines.length; i++) {
        loop2: for (let j = 0; j < coversArray.length; j++) {
          if (pendingVaccines[i].id == coversArray[j].id && selected != idx) {
            pendingVaccines[i]['covers'] = true;
            covers = true;
            continue loop1;
          } else {
            pendingVaccines[i]['covers'] = false;
          }
        }
      }

      this.selectedVacVisit.data = pendingVaccines;
      // this.setState({
      // selected = selected == idx ? -1 : idx;
      // let selectedComboVaccineId = brand.id;
    }
  }

  saveVaccineRecord = () => {
    const params = {
      patientId: this.patientObj.id? this.patientObj.id : this.patientMoreData._id,
    };
  }

  saveSpecialVaccine = () => {
    this.saveSpecialVaccButton= true;
    let postData = this.patientMoreData;
    let parsed:any = null
    if (postData.additionalInfo == "") {
      parsed = null
    }
    else {
      parsed = JSON.parse(postData.additionalInfo);
    }


    let specialVacObj = {
      name: this.specialSituationVaccines[this.selectedVacIndex].name == "Other" ? this.splVaccineName : this.specialSituationVaccines[this.selectedVacIndex].name,
      administeredDate: moment(this.util.parseDate(this.receivedDate)).format('DD-MMM-YYYY'),
      administeredStatus: true
    }

    if (parsed) {
      if (parsed.specialVaccination && parsed.specialVaccination.length) {

        parsed.specialVaccination.push(specialVacObj)
      } else {
        parsed['specialVaccination'] = [specialVacObj]
      }
    } else {
      parsed = {};
      parsed['specialVaccination'] = [specialVacObj]
    }
    if(parsed.specialVaccination){
      this.patientMoreData['addtionalInfo']=parsed.specialVaccination +parsed
    }

    postData.additionalInfo = JSON.stringify(parsed);
    postData.visit = postData.visit ? postData.visit : [];
    this.patientMoreData['additionalInfo'] = JSON.stringify(parsed)

    const data = {
      HealthHistoryAdult: "HealthHistoryAdult",
      blood_group: this.patientMoreData.blood_group,
      additionalInfo: JSON.stringify(parsed)
      // patient: postData,
      // patientCreatedAt: this.patientMoreData.createdAt
    };

    this.patientService.patientpartialupdate(data, this.patientMoreData._id).subscribe((response:any) => {
      if (response) {
        this.saveSpecialVaccButton = false;
        console.log("saveSpecialVaccButtonsaveSpecialVaccButton");
        
        setTimeout(() => {
          this.getPatientInfoForVac();
          this.getPatientsVaccination();
        }, 500);

        localStorage.setItem("patientMoreData", JSON.stringify(this.patientMoreData));


        this.closeDialog();
        // this.getPatientsVaccination();
        //   this.getPatientInfoForVac();





      }

    })
  }
  parseDate(dateObject:any) {
    if (dateObject?.year) {
      return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
    } else {
      return `${dateObject}`
    }
  }
  formatDate(event: any) {
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    // Check if the input matches the date pattern
    if (event.match(datePattern)) {
      this.patientDoB = event; // Save formatted date
      this.dateError = false;
    } else {
      this.dateError = true; // Set error if format is incorrect
    }
  }
onDobChange() {
  // Delay the execution to wait for `patientDoB` to update
  setTimeout(() => {
    console.log("onDobChange fired");
    if (this.patientDoB) {
      // Calculate the minimum vaccination date (2 days after DOB)
      const minDate = new Date(this.patientDoB);
      minDate.setDate(minDate.getDate() + 2);
      this.minVaccinationDate = minDate;
      // Set `patientVaccinationDate` to exactly two days after `patientDoB`
      this.patientVaccinationDate = new Date(minDate);
      console.log("this.patientDoB:", this.patientDoB);
      console.log("this.patientVaccinationDate:", this.patientVaccinationDate);
      // Trigger change detection to update UI
      this.cdRef.detectChanges();
    } else {
      // Reset minVaccinationDate and patientVaccinationDate if DOB is cleared
      this.minVaccinationDate = null;
      this.patientVaccinationDate = null;
      this.cdRef.detectChanges();
    }
  }, 0); // This allows the DOM event to finish before running the rest of the code
}

  formatVaccinationDate(event: any) {
    console.log(" laxus formatVaccinationDate fired");

    const parsedDate = moment(event, 'DD/MM/YYYY', true);
    if (parsedDate.isValid()) {
      this.patientVaccinationDate = parsedDate.toDate();

      // Log in DD/MM/YYYY to confirm format
      console.log("laxus Formatted patientVaccinationDate:", parsedDate.format('DD/MM/YYYY'));
    }
  }
  openDialog(content:any, index:any) {
   
    this.dialog.open(content);
  }
  resetVaccination(){
    this.confirmVaccDate = true;
    console.log("docUserIdAug27",this.patientMoreData.doctors[0].userId)
    var patInformationArray:any []=[]
 console.log("this.patientDoBthis.patientDoB",this.patientDoB);
 this.setDOBForMorePData =  moment(this.parseDate(this.patientDoB)).format("DD-MMM-YYYY")
 console.log("this.this.patientVaccinationDate.",this.patientVaccinationDate);
 
    var postData={
      dob: moment(this.parseDate(this.patientDoB)).format("YYYY-MM-DD"),
      vaccinationStartDate:moment(this.parseDate(this.patientVaccinationDate)).format("YYYY-MM-DD"),
      patientid:this.patientMoreData._id,
      docUserId: this.patientMoreData.doctors[0].userId
    }
    this.setVaccDateForMorePData = postData.vaccinationStartDate;
    console.log("patientresetVaccinationSchedule postdata",postData);
    
   this.patientService.patientresetVaccinationSchedule(postData).subscribe((response:any) => {
    if(response){
      this.dateError=false
      console.log(response)
      this.closeDialog();
      this.getPatientInfoForVac();
      this.getPatientsVaccination();
      this.groupbyscheduleforasha()
      console.log("NOV66666",this.patientMoreData)

     let formatedDob = moment(this.parseDate(postData.dob)).format("DD/MM/YYYY")
     
       this.patientInfoArray = patInformationArray;
       this.patientMoreData = {
        ...this.patientMoreData,
        formattedVacStartDate:postData.vaccinationStartDate,
        formattedDOB: formatedDob,
        DOB:this.setDOBForMorePData
      };
      this.patientMoreData.vaccinationStartDate = this.setVaccDateForMorePData;


      console.log("AUGGG30",this.patientInfoArray);
      this.changedDOB = this.patientMoreData.formattedDOB;
      console.log('patientmoredata', this.patientMoreData)

    console.log("ChangedDOB",this.changedDOB);

    let birth = {
      key: 'Birth Date',
      value: moment(this.parseDate(postData.dob)).format("DD/MM/YYYY")
    };
    let vacStartDate = {
      key: 'Vac Start Date',
      value: moment(this.parseDate(postData.vaccinationStartDate)).format("DD/MM/YYYY")
    }

    let joiningDate = {
      key: 'Joining Date',
      value: moment(this.patientMoreData.createdAt).format('DD/MM/YY')
    }

    var y = moment(this.patientMoreData.formattedDOB, 'DD/MM/YYYY')
    var x = moment(this.patientMoreData.createdAt)
console.log("this.patientMoreData.formattedDOB",this.patientMoreData.formattedDOB);

    let ageObj = moment.duration(x.diff(y))
    let ageAtJoining = {
      key: 'Age at Joining',
      value: `${Math.floor(ageObj.years())}y ${Math.floor(ageObj.months())}m ${Math.floor(ageObj.days())}d`
    }
 
    patInformationArray.push(birth, vacStartDate, joiningDate, ageAtJoining);
  
      // this.refreshservice.immunisationRefreshed();
    }
   })     
  setTimeout(() => {
    this.openDialog(this.infoPopUp,0)
  }, 3500);

  }
}

