<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>

<div class="row">
  <div class="row" style="background-color: #fff; width: 95%; padding: 16px; border-radius: 6px;">
    <div class="col-lg-12 inventoryDetails">
      <div class="row" style="margin-top: -4px;">
        <div class="col-lg-12">
          <div class="row appointmentHead">
            <div class="col-12 pl-2 ml-1">
              <div class="inventory-outer fs-18 mb-0 pb-0">
                <div class="inventory-outer  pl-1 fs-18 mb-0 pb-0">

                  <div class="heading" style="margin-left:0px">Patients</div>

                  <!-- SEARCH BOX -->
                  <!-- <div class="appointment float-right searchIcon active"
                    style="width: 97%; margin-right: 28px; border-radius: 4px; border-color: #cccc; background-color: #F3F6FA;">
                    <div class="form-group searchField pull-left" style="width: 85%; background-color: #F3F6FA;">           
                      <input type="text" placeholder="Type name/phone number, press enter" class="form-control"
                        #searchInput [(ngModel)]="searchString" (keyup)="keyPressForSearch($event)"
                        style="font-size: 15px; border-radius: 4px !important; border-right: 0px solid #000 !important; padding: 8px !important; background-color: #F3F6FA !important;" />
                      <a type="button" (click)="closeSearch()" style="padding-left: 6px;
                    padding-right: 6px;
                    font-size: 34px; top: -4px;">×</a>
                    </div>

                    <div class="searchbtn" style="width: 15%;">
                      <button (click)="searchPatientFromDb()"><img src="assets/images/searchIcon.svg" alt="" /></button>
                    </div>
                  </div> -->

                </div>

              </div>

              <!-- <div *ngIf="searchPatientData && searchPatientData.length == 0" style="display: flex;
                flex-direction: row;
                background-color: #E5EFFA;
                border: 1px solid #B9DAFF;
                border-radius: 8px;
                padding: 10px;
                margin-top: 16px;
                width: 92%;
                ">

                <div
                  style=" width:80px; border-radius: 8px; display: flex; justify-content: center; align-items: center;">
                  <img src="assets/images/info_icon_new.svg" alt="" />
                </div>

                <div style="margin-left: 8px;">
                  <div style="color: #024085; font-weight: bolder; font-size: 18px;">Patients list empty</div>
                  <div style="color: #024085; font-size: 16px; line-height: 16px; margin-top: -4px;">Please use top
                    search box to search your patients.</div>
                </div>
              </div> -->
              <app-alertbox *ngIf="searchPatientData && searchPatientData.length == 0" [title]="'Patients list empty'"
                [subtitle]="'Please use top
                search box to search your patients.'"
                [type]="'Info'"></app-alertbox>
            </div>

          </div>
        </div>
        <div class="col-lg-12" style="margin-top: -7px;">
          <div class="mt-3 pl-1 no-shadow inventory-list">
            <div class="appointments searchPatientData pr-4">
              <ul style="display: flex;flex-direction: column;padding-bottom: 35px;">
                <a *ngFor="let patient of searchPatientData; let i = index">

                  <li (click)="openProfileSummary(patient);profileSummaryModal(patient);" class="clearfix"
                    *ngIf="patient.Patient && patient.Patient.User">
                    <a>
                      <div style="width: 100%;">

                        <div *ngIf="patient.Patient.User.Customer.fcmtoken" title="Health app installed"
                          class="healthIndicatorInstall"></div>
                        <div *ngIf="!patient.Patient.User.Customer.fcmtoken" title="Health app is not installed"
                          class="healthIndicatorUnInstall"></div>

                        <div>
                          <figure *ngIf="patient.Patient.User.gender?.toLowerCase() === 'male'" style="text-align: center;">
                            <img height="55px" width="55px"
                              style="border: solid 1px #ccc;border-radius: 12px; margin-top: 0px;"
                              *ngIf="patient.Patient.User.profile_photo" src="{{patient.Patient.User.profile_photo}}"
                              alt="" />
                            <img height="55px" width="55px"
                              style="border: solid 1px #ccc;border-radius: 12px; margin-top: 0px; padding: 4px;"
                              *ngIf="!patient.Patient.User.profile_photo" src="assets/images/person_male.png" alt="" />
                          </figure>
                          <figure *ngIf="patient.Patient.User.gender?.toLowerCase() === 'female'">
                            <img height="55px" width="55px" style="border: solid 1px #D0D0D0;border-radius: 12px;"
                              *ngIf="patient.Patient.User.profile_photo" src="{{patient.Patient.User.profile_photo}}"
                              alt="" />
                            <img height="55px" width="55px"
                              style="border: solid 1px #ccc;border-radius: 12px; text-align: center; padding: 4px;"
                              *ngIf="!patient.Patient.User.profile_photo" src="assets/images/person_female.png"
                              alt="" />
                          </figure>

                        </div>

                        <div style="display: flex; justify-items: center; margin-left: 10px;">
                          <figcaption style="padding-left: 0px; width: 60%; margin-left: 12px; display: flex;
                          flex-direction: column;
                          justify-content: center;">
                            <span *ngIf="isPediatric">

                            </span>
                            <!-- First Name -->
                            <div>
                              <span
                                *ngIf="!patient.hasOwnProperty('isLinked') || patient.hasOwnProperty('isLinked') && patient.isLinked"
                                class="capitalize" (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">{{
                                patient.Patient.User
                                &&
                                patient.Patient.User.first_name}}</span>

                              <span *ngIf="patient.hasOwnProperty('isLinked') && !patient.isLinked" class="capitalize"
                                (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">{{

                                patient.Patient.User
                                &&
                                this.util.concealText(patient.Patient.User.first_name, 1)}}</span>

                              <!-- Middle Name -->
                              <span
                                *ngIf="!patient.hasOwnProperty('isLinked') || patient.hasOwnProperty('isLinked') && patient.isLinked"
                                class="capitalize" (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">
                                {{
                                patient.Patient.User &&
                                patient.Patient.User.middle_name}}</span>

                              <span *ngIf="patient.hasOwnProperty('isLinked') && !patient.isLinked" class="capitalize"
                                (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">
                                {{
                                patient.Patient.User &&
                                this.util.concealText(patient.Patient.User.middle_name, 1)}}</span>

                              <!-- Last Name -->
                              <span
                                *ngIf="!patient.hasOwnProperty('isLinked') || patient.hasOwnProperty('isLinked') && patient.isLinked"
                                class="capitalize" (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">
                                {{
                                patient.Patient.User &&
                                patient.Patient.User.last_name}}</span>

                              <span *ngIf="patient.hasOwnProperty('isLinked') && !patient.isLinked" class="capitalize"
                                (click)="openSearchPatient(patient.Patient)"
                                style="font-size: 18px; font-weight: bold; color: #333; font-family: 'Mukta', sans-serif;">
                                {{
                                patient.Patient.User &&
                                this.util.concealText(patient.Patient.User.last_name, 1)}}</span>


                            </div>
                            
                            <div style="color: rgb(94, 91, 91); font-size: 16px; font-weight: 600;"> {{patient.Patient.User.locality}}</div>

                            <!-- <span>(DOB: {{patient.Patient.User.dob | date: 'dd/MM/yyyy'}})</span> -->
                            <div *ngIf="patient.Patient.User.Customer && patient.Patient.User.Customer.phone_number">
                              <span
                                *ngIf="!patient.hasOwnProperty('isLinked') || patient.hasOwnProperty('isLinked') && patient.isLinked"
                                style="font-size: 16px; color: grey; margin-top: -4px;" class="phone_number"><span
                                  (click)="openSearchPatient(patient.Patient)">Primary: {{patient.Patient.User.Customer
                                  && patient.Patient.User.Customer.phone_number | slice:0:2
                                  }}{{patient.Patient.User.Customer && patient.Patient.User.Customer.phone_number |
                                  slice:2}}</span>
                              </span>

                              <span *ngIf="patient.hasOwnProperty('isLinked') && !patient.isLinked"
                                style="font-size: 16px; color: grey; margin-top: -4px;" class="phone_number"><span
                                  (click)="openSearchPatient(patient.Patient)">Primary: {{patient.Patient.User.Customer
                                  && this.util.concealText(patient.Patient.User.Customer.phone_number, 2) | slice:0:2
                                  }}{{patient.Patient.User.Customer &&
                                  this.util.concealText(patient.Patient.User.Customer.phone_number, 2) |
                                  slice:2}}</span>
                              </span>
                            </div>

                            <div
                              *ngIf="patient.Patient.User.Customer && patient.Patient.User.Customer.spousecontactno && patient.Patient.User.Customer.spousecontactno.length > 10">


                              <div *ngIf="patient.Patient.User.Customer.spousecontactno">
                                <span
                                  *ngIf="!patient.hasOwnProperty('isLinked') || patient.hasOwnProperty('isLinked') && patient.isLinked"
                                  style="font-size: 16px; color: grey; margin-top: -4px;" class="phone_number"><span
                                    (click)="openSearchPatient(patient.Patient)">Secondary:
                                    {{patient.Patient.User.Customer
                                    && patient.Patient.User.Customer.spousecontactno | slice:0:2
                                    }}{{patient.Patient.User.Customer&& patient.Patient.User.Customer.spousecontactno |
                                    slice:2}}</span>

                                </span>
                                <span *ngIf="patient.hasOwnProperty('isLinked') && !patient.isLinked"
                                  style="font-size: 16px; color: grey; margin-top: -4px;" class="phone_number"><span
                                    (click)="openSearchPatient(patient.Patient)">Secondary:
                                    {{patient.Patient.User.Customer
                                    && this.util.concealText(patient.Patient.User.Customer.spousecontactno, 2) |
                                    slice:0:2
                                    }}{{this.util.concealText(patient.Patient.User.Customer&&
                                    patient.Patient.User.Customer.spousecontactno, 2) |
                                    slice:2}}</span>

                                </span>
                              </div>
                            </div>

                          </figcaption>

                          <figure style=" width:29%; display: flex; justify-content: flex-end; ">





                            <a ngbTooltip="Add New Prescription" placement="bottom" type="button"
                              (click)="openPrescriptions(patient);clickModal($event);"><img style="
                              /* box-shadow: 3px 3px 3px #ccc; */
                              border: 1px solid #ccc;
                              z-index: 9;
                              background-color: #fff;
                              border-radius: 8px;
                          margin: 6px 3px;
                          padding: 6px;
                          width: 40px;
                          height: 40px;" src="assets/images/home_prescription_icon.svg" alt=""></a>

                            <a ngbTooltip="Add New Appointment" placement="bottom" type=" button"
                              *ngIf="isAppointmentEnabled == '1'" (click)="addAppointment(patient, $event);"><img style="
  /* box-shadow: 3px 3px 3px #ccc; */
 border: 1px solid #ccc;
 background-color: #fff;
 border-radius: 8px;
 margin: 6px 3px;
 padding: 6px;
 width: 40px;
height: 40px;" height="36" src="assets/images/home_appointment_icon.svg" alt=""></a>


                            <a ngbTooltip="Add In Queue" placement="bottom" type=" button"
                              *ngIf="isAppointmentEnabled == '2'"
                              (click)="checkifPatientAlreadyinQueue(patient);openDialog(addQueueModal, 0);clickModal($event);addAppointment(patient, $event);clearAllSelection()"><img
                                style="
                              /* box-shadow: 3px 3px 3px #ccc; */
                              background-color: #fff;
                              border: 1px solid #ccc;
                              border-radius: 8px;
                          margin: 6px 3px;
                          padding: 6px;
                          width: 40px;
                          height: 40px;" src="assets/images/home_queue_icon.svg" alt=""></a>
                          </figure>
                        </div>
                      </div>
                    </a>
                  </li>


                </a>

              </ul>
              <div class="row patient-pagination" *ngIf="totalPatients > pageSize">
                <ngb-pagination [collectionSize]="totalPatients" [pageSize]="pageSize" [(page)]="currentPage"
                  (pageChange)="changePage(currentPage)" aria-label="Default pagination"></ngb-pagination>
              </div>
            </div>


          </div>

        </div>

      </div>

    </div>
  </div>
  <!-- <div class="row"
    style="background-color: #fff; width: 58%; padding: 16px; box-shadow: 0 0 8px #ccc; margin-left: 24px; border-radius: 6px;"> -->
  <!-- APPOINTMENT SECTION -->
  <!-- <app-appointments (appointmentData)="addAppointmentData($event)" style="width: 100%;"></app-appointments> -->
  <!-- </div> -->
</div>
<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>

<ng-template #ViewAllCases let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Patient cases</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-6">
            <div class="form-group checkbox withLabel">
              <label>
                <input class="filled-in" type="checkbox" [(ngModel)]="otherCases" (change)="showOtherCases(otherCases)">
                <span class="mt-1">Show other cases</span>
              </label>
            </div>
          </div>
          <div class="col-6 text-right createNewCaseBtn">
            <a class="btn btn-primary opd-primary-btn transformNormal btn-lg" (click)="createNewCase(d);d()">Create
              New Case</a>
          </div>
        </div>
        <div class="appointments inventory">
          <ul>
            <li>
              <ul class="head clearfix">
                <li>&nbsp;</li>
                <li style="padding-left: 13px;">Visits</li>
                <li style="padding-left: 12px;">Doctor name</li>
                <li style="padding-left: 7px;">Last Visited </li>
                <li style="padding-left: 4px;">Status</li>
                <li class="pl-0 pr-0">&nbsp;</li>
                <li class="pl-0 pr-0">&nbsp;</li>
              </ul>
            </li>
            <li class="body">
              <ul class="noDataMessage mt-2 mb-2" *ngIf="!cases || cases.length == 0">No past cases found</ul>
              <ul class="morningSessionDetails clearfix clickable" *ngFor="let caseVisit of cases; let i = index">
                <li class="pt-2" (click)="toggleIsOpen(caseVisit.id)">
                  <span>
                    <!-- *ngIf="inventory.lastVisited1 || inventory.lastVisited2" -->
                    <div *ngIf="caseVisit.Visits && caseVisit.Visits.length > 1"><i
                        class="fa {{ caseVisit.id == openCaseId ? 'fa-angle-down' : 'fa-angle-right' }} icon"
                        placement="bottom" [ngbTooltip]=" caseVisit.id !== openCaseId ? 'open' : 'close'"
                        aria-hidden="true"></i></div>
                  </span>
                </li>
                <!-- <li class="pt-2"><span placement="bottom" ngbTooltip="Open"><i class="fa fa-angle-right icon" aria-hidden="true"></i></span></li> -->
                <li (click)="toggleIsOpen(caseVisit.id)">{{caseVisit.Visits.length}}</li>
                <li (click)="toggleIsOpen(caseVisit.id)">
                  <span *ngIf="caseVisit.Visits[0] && caseVisit.Visits[0].Doctor">
                    {{caseVisit.Visits[0].Doctor.User.first_name}}
                    {{caseVisit.Visits[0].Doctor.User.last_name}}</span>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index;">
                      <span *ngIf="i != 0" class="capitalize">{{visit.Doctor.User.first_name}}
                        {{visit.Doctor.User.last_name}}</span>
                    </p>
                  </div>
                </li>
                <li (click)="toggleIsOpen(caseVisit.id)">
                  <p class="mb-2" (click)="toggleIsOpen(caseVisit.id)">
                    {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date: 'dd/MM/yyyy'}}</p>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index;">
                      <span *ngIf="i != 0">{{visit && visit.createdAt | date: 'dd/MM/yyyy'}}</span>
                    </p>
                  </div>
                </li>
                <li>{{caseVisit && caseVisit.status}}</li>
                <!--&& caseVisit.Doctor && caseVisit.Doctor.User.id == userID-->
                <li (click)="toggleIsOpen(caseVisit.id)"><a *ngIf="caseVisit.status == 'Open'"
                    (click)="createNewVisit(caseVisit.id,d)" placement="bottom" ngbTooltip="Add visit"><img
                      src="assets/images/plusIcon.svg" alt="" /></a></li>
                <li>
                  <div>
                    <p class="" (click)="viewVisitDetails(caseVisit.id,caseVisit.Visits[0].id,d)"><a placement="bottom"
                        ngbTooltip="View visit"><img class="pt-2" src="assets/images/eyeGray.svg" alt="" /></a></p>
                  </div>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index"
                      (click)="viewVisitDetails(caseVisit.id,visit.id,d)"><span *ngIf="i != 0"><a placement="bottom"
                          ngbTooltip="View visit"><img src="assets/images/eyeGray.svg" alt="" /></a></span></p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addNewPatient let-c="close" let-d="dismiss" class="addnewpatient">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title" style="text-transform: capitalize;">Patient Information</h6>
      <button type="button" class="close" aria-label="Close" (click)="c()" style="margin-right: 0px;">
        <span aria-hidden="true" style="font-size: 34px;" alt="">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-12">
            <!-- <div class="form-group animateLabel">
                  <input type="text" class="form-control animateInput" [ngClass]="{'error-field':error}"  [textMask]="{mask: mask,guide:false}" maxlength="12" [(ngModel)]="user.phone_number" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                  <label>Mobile Number</label>
                  <p class="errors" *ngIf="errors.phone_number">{{errors.phone_number}}</p>
                </div> -->
            <div class="form-group mobNumber clearfix">
              <label class="newInputStyleLabel">Your Mobile Number <span class="required">*</span></label>
              <div class="stdCode" style="padding-top: 2px !important;">
                <span class="stdCodeInputSign">+</span>
                <input class="stdCodeInput form-control newInputBox" type="text" maxlength="2"
                  (keypress)="validateNumber($event)" [(ngModel)]="stdCode" readonly>
                <p class="errors" *ngIf="errors.stdCode">{{errors.stdCode}}</p>
              </div>
              <div class="animateLabel" style="padding-top: 2px !important;">
                <input type="text" class="form-control newInputBox" [ngClass]="{'error-field':error}"
                  [textMask]="{mask: mask,guide:false}" maxlength="10" [(ngModel)]="user.phone_number_dummy"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" readonly>

                <p class="errors" *ngIf="errors.phone_number">{{errors.phone_number}}</p>
              </div>
            </div>
            <!-- <div class="form-group checkbox withLabel mb-0">
                  <label class="mb-4">
                    <input class="filled-in" type="checkbox" checked>
                    <span>This is patient's own number</span>
                  </label>
                </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="newInputStyleLabel">First and Middle Name <span class="required">*</span></label>
              <input type="text" class="capitalize newInputBox" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.first_name" maxlength="25"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                placeholder="Enter first name and middle name"
                onkeypress="return (event.charCode > 64 &&
                  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9) || (event.which == 32)|| (event.charCode == 39)|| (event.charCode == 45)">

              <p class="errors" *ngIf="errors.first_name">{{errors.first_name}}</p>
            </div>
          </div>
          <!-- <div class="col-6">
              <div class="form-group">
                <label class="newInputStyleLabel">Middle Name </label>
                <input type="text" class="capitalize newInputBox" [ngClass]="{'error-field':error}"
                  [(ngModel)]="user.middle_name" maxlength="25"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                  placeholder="Enter middle name"
                  onkeypress="return (event.charCode > 64 &&
                  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)">
  
                <p class="errors" *ngIf="errors.middle_name">{{errors.first_name}}</p>
              </div>
            </div> -->
        </div>
        <div class="row">
          <div class="col-12">
            <!-- <div class="form-group animateLabel">
                <input type="text" class="form-control animateInput capitalize" [ngClass]="{'error-field':error}"
                  [(ngModel)]="user.last_name" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                  maxlength="25" onkeypress="return (event.charCode > 64 &&
                  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)
                  ">
                <label>Last Name <span class="required">*</span></label>
                <p class="errors" *ngIf="errors.last_name">{{errors.last_name}}</p>
              </div> -->
            <div class="form-group ">
              <label class="newInputStyleLabel">Last Name <span class="required">*</span></label>
              <input type="text" class="form-control  capitalize newInputBox" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.last_name" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                maxlength="25"
                onkeypress="return (event.charCode > 64 &&
                  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9) || (event.which == 32) || (event.charCode == 39)|| (event.charCode == 45)"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                placeholder="Enter last name">

              <p class="errors" *ngIf="errors.last_name">{{errors.last_name}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group selectDropDown calendarIcon calender onFocus">
              <label style="margin-top: 4px !important; margin-bottom: 4px !important;" class="newInputStyleLabel">Date
                of Birth <span class="required">*</span></label>
              <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="minDate"
                [ngClass]="{'error-field':error}" id="dobInput" class="form-control newInputBox" [(ngModel)]="user.dob"
                (ngModelChange)="getAge()" ngbDatepicker #d="ngbDatepicker" (document:click)="closeCalender($event, d)"
                (focus)=" d.open()" readonly
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">

              <img (click)="d.toggle()" class="calendarbg" style="top: 27px; right: 8px;"
                src="assets/images/calendarIcon.svg" alt="" />
              <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p>
            </div>
          </div>
          <div class="col-6 age">
            <div class="row">
              <div class="col-12 onFocus">
                <label class="ifnoLabel newInputStyleLabel">Age</label>
              </div>
              <div class="col-12">
                <div class="form-group onFocus">

                  <input disabled [(ngModel)]="user.age.years" class="form-control newInputBox" placeholder="00"
                    style="width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Yrs</label>
                  <input disabled [(ngModel)]="user.age.months" class="form-control newInputBox" placeholder="00"
                    style="margin-left: 2px;width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Mths</label>
                  <input disabled [(ngModel)]="user.age.weeks" class="form-control newInputBox" placeholder="00"
                    style="margin-left: 2px;width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Wks</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pr-0">
            <div class="form-group mb-3 genderLabel onFocus genderHeight">
              <label class="newInputStyleLabel">Gender <span class="required">*</span></label>
              <div class="basicinfoGender1">
                <div class="form-check-inline">
                  <!-- <label class="custom-control custom-radio" [ngClass]="{'active-gender': user.gender == 'male'}">
                      <input (ngModelChange)="genderToggle('no')" class="custom-control-input" checked type="radio"
                        name="gender" [(ngModel)]="user.gender" value="male">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description">Male</span>
                    </label> -->

                  <label class="container-radio" style="margin: 6px 15px 5px 4px;
                color: #333; padding-top: 2px; font-size: 16px">Male
                    <input type="radio" (ngModelChange)="genderToggle('no')" checked name="gender" value="male"
                      [(ngModel)]="user.gender">
                    <span class="checkmark-radio"></span>
                  </label>

                </div>
                <div class="form-check-inline mr-0">
                  <!-- <label class="custom-control custom-radio" [ngClass]="{'active-gender': user.gender == 'female'}">
                      <input (ngModelChange)="genderToggle('no')" class="custom-control-input" type="radio" name="gender"
                        [(ngModel)]="user.gender" value="female">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description">Female</span>
                    </label> -->
                  <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
                color: #333; padding-top: 2px; font-size: 16px">Female
                    <input type="radio" (ngModelChange)="genderToggle('no')" name="gender" value="female"
                      [(ngModel)]="user.gender">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <!-- <div class="form-check-inline mr-0 ">
              

                    <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
                color: #333; padding-top: 2px; font-size: 16px">Non-specified
                      <input type="radio" (ngModelChange)="genderToggle('yes')" name="gender" value="non_specified"
                        [(ngModel)]="user.gender_type">
                      <span class="checkmark-radio"></span>
                    </label>
                  </div> -->
              </div>
              <p class="errors" *ngIf="errors.gender && user.gender_type != 'non_specified'">{{errors.gender}}</p>
            </div>
          </div>
        </div>
        <div class="row " *ngIf="user.gender_type == 'non_specified'">
          <div class="col-12">
            <div class="form-group mb-3 genderLabel d-flex onFocus basicinfoGender">
              <label class="align-self-center newInputStyleLabel">Final Gender is : </label>
              <div class="pl-3">
                <div class="form-check-inline">
                  <label class="container-radio">
                    <input class="custom-control-input" type="radio" name="gender_type" [(ngModel)]="user.gender"
                      value="Male">
                    <span class="checkmark-radio"></span>
                    <span class="custom-control-description">Male</span>
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="container-radio">
                    <input class="custom-control-input" type="radio" name="gender_type" [(ngModel)]="user.gender"
                      value="Female">
                    <span class="checkmark-radio"></span>
                    <span class="custom-control-description">Female</span>
                  </label>
                </div>
                <p class="errors clearfix" *ngIf="errors.gender && user.gender_type == 'non_specified'">
                  {{errors.gender}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" style="margin-top: 10px;">
          <div class="row">
            <div class="col-7 pl-0">
              <div class="form-group selectDropDown bloodGroup onFocus">
                <label class="newInputStyleLabel">Blood Group </label>
                <select class="newInputBox"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;"
                  [ngClass]="{'ifnoLabel': !patient.Patient.blood_group}" [(ngModel)]="patient.Patient.blood_group">
                  <option disabled value="null">Select</option>
                  <option *ngFor="let bloodGroup of bloodGroups; let i = index" value="{{bloodGroup}}">{{bloodGroup}}
                  </option>
                </select>
                <p class="errors" *ngIf="errors.blood_group">{{errors.blood_group}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="display: flex; justify-content: flex-end;">
          <div class="col-12 mt-3 mb-3" style="display: flex; justify-content: flex-end;">
            <button class="newBtnStyle" style="text-transform: capitalize;" type="button"
              (click)="updatePatient(c)">Update
              Patient</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!---******addAppointmentModal*********-->


<ng-template #addAppointmentModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">Add Appointment</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog('Cross click');closeAddAppoinmentModal();clearAllSelection()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div style="width: 98%;padding-left: 5px;margin-top: 14px; margin-bottom: 4px; margin-left: 8px;">
        <div class="row">
          <label class="fieldsetlable" style="margin-bottom: -14px;">Patient Name</label>
          <input type="text" class="form-control"
            style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
            placeholder="Purpose Of Visit" [(ngModel)]="paitentName" [disabled]="true">

        </div>
      </div>
      <!-- 
      <custom-text-input [disabled]="true" [paitentName]="paitentName" label="Patient Name" placeholder={{paitentName}} style="width: 98%;">
      </custom-text-input> -->

      <div>
        <!-- <app-reusabledisgnforms></app-reusabledisgnforms> -->
        <!-- <div style="margin-left: 5px;">
           
          <label class="fieldsetlable"
             >Patient Name</label>
          <input type="text" class="form-control newInputBox " maxlength="15"
              style="border-radius: 4px !important; color: #949494 !important; font-size: 1rem !important;"
              [(ngModel)]="paitentName">
            
      </div>  -->
        <!-- <div class="form-group selectDropDown calendarIcon calender onFocus"
              style="width: 23%;padding-left: 5px;margin-bottom: 5px; margin-top: 6px;"> -->
        <div style="margin-left: 1px;width: 551px; margin-top: 5px;">
          <label class="fieldsetlable">Select Date <span class="required">*</span></label>

          <input placeholder="" type="text" [minDate]="minDateForAppointment" [maxDate]="maxDateForAppointment" id="dobInput" class="form-control "
          [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker" readonly (click)="d.toggle()"
            (ngModelChange)="getAllClinicsTiming()"
          style="border-radius: 4px !important;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">
        
        <img (click)="d.toggle()" class="calendarbg" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
            margin-top: -51px;
            margin-left: 518px;" src="assets/images/calendarIcon.svg" alt="" />
        </div>

        <!-- </div> -->
        <div style=" display: flex; margin-top: -15px;">
          <!-- <custom-text-input  [selectedSlotStartType]="selectedSlotStartType" label="From Time" placeholder="{{selectedSlotStartType}}" placeholder="Enter" (click)="open(addSelectSlotModal); clearAllSelection();">
          </custom-text-input> -->
          <div>

            <label class="fieldsetlable">From Time</label>
            <input type="text" class="form-control newInputBox " style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;width: 269px;cursor: pointer;
             margin-left: 5px;" placeholder="Enter From Time" [(ngModel)]="selectedSlotStartType"
              (click)="openDialog(addSelectSlotModal); clearAllSelection();">

          </div>
          <div>

            <label style="  margin-bottom: -16px;
          z-index: 100;
          margin-left: 31px;
          overflow: hidden;
          background-color: #fff;
          font-size: 16px;
          padding-left: 8px;
          padding-right: 8px;font-size: 14px;
    color: #333;">To Time</label>
            <input type="text" placeholder="Enter To Time" class="form-control " style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;width: 269px;cursor: pointer;
              margin-left: 13px;" [(ngModel)]="selectedSlotEndType"
              (click)="openDialog(addSelectSlotModal); clearAllSelection();">

          </div>
        </div>





        <div style="display: flex; margin-top: 5px;">
          <div style="border-radius: 4px !important;
      color: #656565 !important;
      font-size: 1rem !important;
      border: 1px solid  #d4d1d1;
      width: 269px;
      height: 37px;
      margin-top: 11px;
      margin-left: 5px;    padding-top: 5px;
    padding-left: 10px;">

            <div class="item">


              <div class="item-checkbox">
                <label style="font-size: 14px; color: #8C8B8C;margin-top: -8px;"><input type="checkbox"
                    (click)=open(addSelectSlotPriorityModal);clearAllSelection();checkcode(); tabindex="0"> Priority
                  Time</label>
              </div>


            </div>

          </div>
          <div style="border-radius: 4px !important;
    color: #656565 !important;
    font-size: 1rem !important;
    border: 1px solid  #d4d1d1;
    width: 269px;
    height: 37px;
    margin-top: 11px;
    margin-left: 14px;    padding-top: 5px;
  padding-left: 10px;">


            <div class="item">


              <div class="item-checkbox">
                <label style="font-size: 14px; color: #8C8B8C;margin-top: -8px;"><input type="checkbox"
                    [(ngModel)]="isTeleCall" tabindex="0"> Schedule Video Call?</label>
              </div>


            </div>

          </div>
        </div>
        <div style="width: 98%;padding-left: 5px;margin-top: 14px; margin-bottom: 4px; margin-left: 8px;">
          <div class="row">
            <label class="fieldsetlable" style="margin-bottom: -14px;">Purpose Of Visit </label>
            <input type="text" class="form-control"
              style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
              placeholder="Purpose Of Visit" [(ngModel)]="comment">

          </div>
        </div>


      </div>
      <button *ngIf="!bookNextAppointmentFlag" type="submit" class="opd-primary-btn btn-md w125 newBtnStyle"
        (click)="bookAppointment();clearAllSelection();closeDialog(); closeAddAppoinmentModal();"
        style="width: 169px;margin-left: 70%;margin-top: 5%;text-transform: capitalize;">Book Appointment</button>
        <button *ngIf="bookNextAppointmentFlag" type="submit" class="opd-primary-btn btn-md w125 newBtnStyle"
        (click)="bookAppointment();"
        style="width: 169px;margin-left: 70%;margin-top: 5%;text-transform: capitalize;">Book Next Appointment</button>



    </div>

  </div>
</ng-template>


<ng-template #addQueueModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">Join Queue?</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div style="color: #555; font-size: 18px;" [innerHTML]="QueueAddingPopupMessage">
      </div>

      <div style="padding-top: 16px; display: flex; justify-content: flex-end;">

        <button (click)="closeDialog()" style="background-color: #eee;
        padding: 8px 16px;
        border-radius: 6px;
        color: #fff;
        font-weight: bold;
        border: 0px;
        margin: 8px;
        font-size: 18px;
        color: #444;
        outline: none;">Cancel</button>

        <button (click)="bookAppointmentForQueue();clearAllSelection();closeDialog(); closeAddAppoinmentModal();" style="background-color: #5D86CC;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              font-size: 18px;
              margin: 8px 0px;
            outline: none;">Join</button>
      </div>




    </div>

  </div>
</ng-template>



<ng-template #addQueueSuccessModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">
        Joined Queue</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div style="color: #555; font-size: 18px;">
        {{paitentName.replace(null,'')}} joined with token no - <b>{{appointmentLength}}</b>
      </div>

      <!-- <div style="display:flex; width: 100%;">

        <button type="submit" class="opd-primary-btn btn-md w125 newBtnStyle" (click)="closeDialog();"
          style="width: 100px;margin-left: 40%;margin-top: 5%;text-transform: capitalize;">OK</button>

      </div> -->

      <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog();" style="background-color: #5D86CC;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              font-size: 18px;
              margin: 8px 0px;
            outline: none;">Ok</button>
      </div>




    </div>

  </div>
</ng-template>



<!---------*********addSelectSlotModal***********-->


<ng-template #addSelectSlotModal let-modal>
<div style="padding: 20px;overflow-y: scroll;height: 100vh;overflow-x: hidden; width: 80vw;">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Select Slot</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="addSelectSlotModalPress();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <div style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; width: 75vw;">




      <div>
        <div *ngIf="morningSlots.length" style="border-radius: 6px; margin-left: 13px;
        background-color: #ffffff;  font-size: 17px; color: #7e7e7f;">Morning slots -{{morningSlots[0].start}} -
      {{morningSlots[morningSlots.length -1].end }}
    </div>


    <div *ngIf="!morningSlots.length" style="border-radius: 6px; margin-left: 13px;
        background-color: #ffffff;  font-size: 17px; color: #7e7e7f;"> No Slots Morning
    </div>
      </div>

     <div style="width: 45%; display: flex; flex-direction: row; justify-content: right;">


      <div type="button" class="btn btn" style=" margin-right:10px; margin-bottom: 16px;height: 20px; padding-bottom: 29px; margin-top: -7px;
      background-color: #dfefff;  border: 1px solid#dfefff; width: 130px; font-size: 16px; color: #1d9dff;">
         Total-{{morningSlots.length}}
       </div>
       <div type="button" class="btn btn" style="margin-bottom: 16px;height: 20px; padding-bottom: 29px; margin-top: -7px;
    background-color: #dbffec;  border: 1px solid#dbffec; width: 130px; font-size: 16px; color: #1bdb9a;">
         Available-{{morningSlots.length-morningAppointmentCount}}
       </div>
     </div>
     </div>

        <!-- <div type="button" class="btn btn" style="margin-right: 9px;height: 20px; padding-bottom: 29px; margin-left: 976px; margin-top: -57px;
        background-color: #dbffec;  border: 1px solid#dbffec; width: 130px; font-size: 16px; color: #1bdb9a;">Available-0
         
          </div> -->
      <form>

        <div class="form-group">

          <div>

            <div
              style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: -2px;  width: 80vw; margin-left: 0px;  ">


              <div *ngFor="let item of morningSlots; let i = index" class="searchable-component-style">



                <div *ngIf="item.count" style="
                background-color: #93d7fb;
                text-align: center;
                width: 20px;
                margin-left: 111px;
color: #FFF;
border-color: #000;
border-radius: 50px;
border-width: 1px;
position: absolute;">{{item.count}}</div>


                <!-- <div (click) ="switchSlotType()" [ngClass]="(item.selected)? 'topright' :'toprighthide' " >
                <div  style="align-self:center;
                text-align:center;
                color:#4687BF">1</div>
              </div> -->
                <!-- <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" style="width: 10px;" /> -->

                <!-- <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" /> -->

                <div *ngIf="item.showPlusButton" (click)=" switchSlotType(i);addSelectSlotModalPress();"
                  [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'" type="button" class="btn btn"
                  style="border-radius: 6px;margin-right: 9px;margin-bottom: 16px;
              width: 130px; font-size: 16px; "> {{item.start}}</div>
              </div>
            </div>
          </div>
        </div>
      </form>


      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;   width: 75vw;">


        <!-- <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
        class="tick-icon" /> -->

        <!-- <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
        class="tick-icon" /> -->
        <div *ngIf="eveningSlots.length" style="border-radius: 6px; margin-left: 13px;margin-top: -19px;
      background-color: #ffffff;  font-size: 17px; color: #7e7e7f;">Evening Session {{eveningSlots[0].start}} -
          {{eveningSlots[eveningSlots.length -1].end}}</div>

          <div style="width: 45%; display: flex; flex-direction: row; justify-content: right;">
        <div type="button" class="btn btn" style="margin-right: 10px; margin-bottom: 16px;height: 20px; padding-bottom: 29px; margin-top: -29px;
        background-color: #dfefff;  border: 1px solid#dfefff; width: 130px; font-size: 16px; color: #1d9dff;">
               Total-{{eveningSlots.length}}</div>
             <div type="button" class="btn btn" style="margin-bottom: 16px;height: 20px; padding-bottom: 29px; margin-top: -29px;
      background-color: #dbffec;  border: 1px solid#dbffec; width: 130px; font-size: 16px; color: #1bdb9a;">
               Available-{{eveningSlots.length-eveningAppointmentCount}}
             </div>
       </div>

      </div>
      <form>

        <div class="form-group">

          <div>
            <div
              style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 78vw; margin-left: -6px;  ">

              <div *ngFor="let item of eveningSlots; let i = index" class="searchable-component-style">
                <!-- <div>
                  <div (click)="switchSlotEvenType(i)" [ngClass]="(item.selected)? 'topright' :'toprighthide' ">
                    <div style="align-self:center;
            text-align:center;
            color:#4687BF">1</div>
                  </div>
                </div> -->
                <!-- <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
            class="tick-icon" /> -->

                <!-- <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
            class="tick-icon" /> -->
                <div *ngIf="item.count" style="
                    background-color: #93d7fb;
                    text-align: center;
                    width: 20px;
                    margin-left: 111px;
    color: #FFF;
    border-color: #000;
    border-radius: 50px;
    border-width: 1px;
    position: absolute;">{{item.count}}</div>
                <div *ngIf="item.showPlusButton" (click)="switchSlotEvenType(i);addSelectSlotModalPress();"
                  [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'" type="button" class="btn btn"
                  style="border-radius: 6px;margin-right: 9px;margin-bottom: 16px;
           width: 130px; font-size: 16px;"> {{item.start}}</div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>


    <!-- </div> -->
  </div>
</div>
</ng-template>

<ng-template #addSelectSlotPriorityModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Select Priority Slot</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="modal.dismiss('Cross click'); clearAllSelection();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 37vw;   ">


        <!-- <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
        class="tick-icon" /> -->

        <!-- <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
        class="tick-icon" /> -->
        <div *ngIf="priorityTimeSlots.length" style="border-radius: 6px; margin-left: 13px;
      background-color: #ffffff;  font-size: 17px; color: #7e7e7f;">Priority Time Slots &nbsp;
          {{priorityTimeSlots[0].start}} -
          {{priorityTimeSlots[priorityTimeSlots.length -1].end}}</div>


      </div>

      <form>

        <div class="form-group">

          <div>
            <div
              style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 26px;  width: 82vw; margin-left: 0px;  ">

              <div *ngFor="let item of priorityTimeSlots; let i = index" class="searchable-component-style">
                <div *ngIf="item.count" style="
                background-color: #93d7fb;
                text-align: center;
                width: 20px;
                margin-left: 111px;
color: #FFF;
border-color: #000;
border-radius: 50px;
border-width: 1px;
position: absolute;">{{item.count}}</div>
                <!-- <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" style="width: 10px;" /> -->

                <!-- <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" /> -->
                <div *ngIf="item.showPlusButton" (click)="switchSlotPriorityType(i);modal.dismiss()"
                  [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'" type="button" class="btn btn"
                  style="border-radius: 6px;margin-right: 9px;margin-bottom: 16px;
              width: 130px; font-size: 16px; "> {{item.start}}</div>
              </div>
            </div>
          </div>
        </div>
      </form>





    </div>


    <!-- </div> -->
  </div>
</ng-template>


<ng-template #showPrescription_modal let-modal>


  <h4>Create Prescription</h4>
  <div style="color: grey; width: 50vh; line-height: 16px;">You are almost done! Before sharing Rx with your patient
    please specify
    the following fields.</div>
  <hr>


  <div style="display: flex;flex-direction: column;">





    <mat-radio-group aria-label="Select an option" style="margin: 0px 0px;"
      *ngFor="let item of createPescription;let i=index" (click)="selectPrescriptionValue(i)">
      <div [ngClass]="(item.selected) ?  'highlightedBoxPer' :'UnhighlightedBoxPer'">

        <mat-radio-button value="true" [checked]="item.selected">&nbsp;{{item.name}}</mat-radio-button><br><br>
      </div>

      <!-- <div style=" margin-bottom: 12px;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;">
      <mat-radio-button value="2">&nbsp;Custom Prescription</mat-radio-button>
    </div> -->

    </mat-radio-group>

    <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;" *ngIf="customPerscription">
      <mat-label>Select Custom Prescription</mat-label>
      <mat-select [value]="dose" (selectionChange)="onDropdownSelectFavMed(favArray,$event.value)">
        <mat-option *ngFor="let item of favArray" [value]="item">{{item.favApptName}}</mat-option>
      </mat-select>
    </mat-form-field>



  </div>

  <!-- <div style="color: red; padding-top: 16px;">Fields marked and highlighted are compulsory*</div> -->

  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 8px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="openPrescriptions();closeDialog()" style="background-color:#3f51b5;
      padding: 8px 16px;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      border: 0px;
      margin: 8px 0px;
    outline: none;">Select</button>

  </div>
</ng-template>
<ng-template #share_Link_App let-modal>
  <div style="display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex; flex-direction: column; justify-content: center;">

      <div class="heading">Share Health App Link</div>
      <div class="subheading"> Enter complete mobile number of patient</div>

    </div>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body">


    <mat-form-field appearance="fill" style="width: 25vw;">
      <mat-label style="font-size: 18px;">Phone number</mat-label>
      <input matInput (keypress)="validateNumber($event)" [(ngModel)]="mobileNumber" maxlength="10">

    </mat-form-field>

    <!-- <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of patient</label>
        <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
          [(ngModel)]="mobileNumber">

      </div>
    </div> -->
  </div>
  <div class="modal-footer">
    <div (click)="shareLinkApp();" style="cursor: pointer;" class="new_btn">Send</div>
  </div>
</ng-template>


<ng-template #patient_Link let-modal>
  <div style="display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex; flex-direction: column; justify-content: center;">

      <div class="heading"> Enter Otp </div>
      <div class="subheading">Consent SMS sent to patients registered mobile number</div>

    </div>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body">


    <mat-form-field appearance="fill" style="width: 25vw;">
      <mat-label>Enter OTP</mat-label>
      <input matInput (keypress)="validateNumber($event)" [(ngModel)]="consentOtp" maxlength="6">

    </mat-form-field>

    <div style="display: flex;flex-direction: row;">
      <div> Did not receive OTP?</div>
      <div *ngIf="!time==0" style="margin: 0px 5px;cursor: pointer;
      text-decoration: underline;"> Resend OTP</div>
      <div *ngIf="time==0" (click)="resendOtp()" style="margin: 0px 5px;cursor: pointer;
        text-decoration: underline; color: #026a9e;"> Resend OTP</div>
      <!-- <div  style="color: #026a9e; margin: 0px 5px;
        "> {{time}}</div> -->
    </div>

    <!-- <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of patient</label>
        <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
          [(ngModel)]="mobileNumber">

      </div>
    </div> -->
  </div>
  <div class="modal-footer">
    <div (click)="validateOTP();" style="cursor: pointer;" class="new_btn">Confirm</div>
  </div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
        <!-- <img (click)="goBackpage()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                  src="assets/images/exclamation-circle-blue.svg" alt="" /> -->
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>