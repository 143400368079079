<div style="margin: 12px;">
  <div *ngIf="currentStage == '3'">
    <div class="clinician-detailed-wrapper-outer">
      <div class="row">
        <div class="col-12">
          <label class="ifnoLabel upprcase ifnoDetail">Select the clinic you would want to include on your
            letterhead</label>
        </div>
      </div>
      <div class="salutationSpecialization stage3">

        <div class="row grayBottomBorder pb-4" *ngFor="let clinic of clinicArray; let i = index"
          [hidden]="clinic.name == null && clinic.address_line1 == null && clinic.address_line2 == null && clinic.address_line3 == null && clinic.city == null && clinic.pincode == null">

          <div class="col-xl-6 col-lg-5">
            <label class="ifnoLabel ifnoDetail mb-2">{{clinic.name}}<br /><span class="subIfnoLabel"
                *ngIf="clinic.multispeciality">(Multispeciality)</span></label>
            <p class="ifnoLabel">{{clinic.address_line1}} {{clinic.address_line2}}<br /> {{clinic.address_line3}}
              {{clinic.city}} {{clinic.pincode}}</p>
          </div>
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group checkbox withLabel">
                  <label>

                    <input [disabled]="clinic.primary == 1" [(ngModel)]="clinic.include_on_letterhead"
                      [checked]="clinic.include_on_letterhead" (ngModelChange)="addToLetterhead(clinic,i)"
                      class="filled-in" type="checkbox">
                    <span>Include on letterhead</span>
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="custom-control custom-radio mt-2">
                    <input id="radio1" [(ngModel)]="primaryClinicForLetterHead" [value]="i"
                      (click)="radioChange(clinic, i)" class="custom-control-input" name="primary_clinic_temp"
                      type="radio" checked="{{clinic.primary}}">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">Mark as primary</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row pb-4 pt-4">
          <div class="col-12">
            <p class="ifnoLabel">The primary clinic will appear on the header section of your letterhead template by
              default</p>
          </div>
        </div>
      </div>
      <div class="buttons pl-4" style="padding-left: 12rem !important;">
        <button class="btn btn-primary opd-primary-btn transformNormal btn-lg" (click)="previewLetterhead()">Preview
          Letterhead </button>
        <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase" *ngIf="path == 'editprofile'"
          routerLink="/doctor/profileView">Cancel</button>
        <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase" *ngIf="path !== 'editprofile'"
          (click)="setStage('5')">Skip</button>
      </div>
    </div>
  </div>
  <div *ngIf="currentStage == '4'">
    <div class="clinician-detailed-wrapper-outer">
      <div class="row">
        <div class="col-12">
          <label class="ifnoLabel upprcase ifnoDetail mb-3">Letterhead</label>
        </div>
      </div>

      <table style="border:1px solid; width:530px">
        <tr>
          <td>
            <table style="width:100%;">
              <tr>
                <td style="padding:15px 5px 5px 15px;width:320px;font-size:17px;font-weight:500px">
                  <strong>{{primaryClinic.name}}</strong></td>
                <td style="padding:15px 5px 5px 15px;font-size:17px;font-weight:500px"><strong>Dr. {{user.first_name}}
                    {{user.last_name}}</strong></td>
              </tr>
              <tr>
                <td style="padding:0px 5px 0px 15px;border-right:1px solid;font-size:12px">
                  {{primaryClinic.address_line1}}
                  {{primaryClinic.address_line2}} {{primaryClinic.address_line3}} {{primaryClinic.city}}
                  {{primaryClinic.pincode}}</td>
                <td style="padding:0px 5px 0px 15px;font-size:12px">{{degreeArray[user.doctor.degree]}}</td>
              </tr>
              <tr>
                <td style="padding:0px 10px 0px 15px;width:300px;font-size:11px"><strong>For Appointment:</strong> <span
                    *ngFor="let contact of primaryClinic.ClinicContacts; let i = index">
                    {{contact.phone}}<span *ngIf="i != primaryClinic.ClinicContacts.length - 1">, </span>
                  </span></td>
                <td style="padding:0px 10px 0px 15px;font-size:11px">Regn. No. {{user.doctor.registration_number}}</td>
              </tr>
              <!-- <tr> 
                    <td style="padding:5px 10px 0px 15px;font-size:11px"><strong>Timings:</strong> 12:00 AM to 11:59 AM & 12:00 PM to 11:59 PM</td> 
                  </tr> -->
              <tr>
                <td style="padding:15px 15px 0px 15px;" colspan="2">
                  <table width="100%" style="padding:0;border-top: 1px solid;">
                    <tr>
                      <td style="padding:10px 0 0 0;font-size:11px;width:65.5%;"><strong>Prescription No.:</strong>
                        PGC/Rx/022016/10970</td>
                      <td style="padding:10px 10px 0px 0;font-size:12px"><strong>Date:</strong> 17/02/2016</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:11px 15px 0px 15px;">
            <table style="padding:0;border: 1px solid;">
              <tr>
                <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>Patient’s Name:</strong>Netra Sarjerao Mali
                  (Female/10 Y, 8 M, 24 D)</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tr>
                      <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>M:</strong> 17</td>
                      <td style="padding:5px 10px 0px 120px;font-size:13px"><strong>Height (CM):</strong> 17</td>
                      <td style="padding:5px 10px 0px 100px;font-size:13px"><strong>Weight: </strong> 17</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>Chief Complaint:</strong> Cold, Cough,
                  Cold,
                  Cough, Cold, Cough</td>
              </tr>
              <tr>
                <td style="padding:5px 10px 10px 15px;font-size:13px"><strong>On Examination:</strong> Chest, Throat:
                  PND,
                  Ears: Normal, Nose+, No-Lad, Jdj small in insig</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td colspan="2" style="padding: 10px 15px;">
                  Vaccination <strong>Hep-B (Dose 1/3)</strong> administered today.
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <table>
                    <tr>
                      <td style="padding: 10px 0 10px 15px;vertical-align: text-bottom;width: 30%;">Vaccination details:
                      </td>
                      <td style="padding: 10px 15px 10px 0px;">Protects child against the hepatitis B virus, Which can
                        lead
                        to liver damage.
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px"><strong>Diagnosis:</strong> UTRI</td>
                <td></td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Erithromycin</strong> 10 mg | 1/2 | 2 times | 5 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Crocin</strong> 1/2 spoon | 1 times | 3 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Erithromycin</strong> 10 mg | 1/2 | 2 times | 5 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Crocin</strong> 1/2 spoon | 1 times | 3 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  Next vaccination for <strong>DTwP/DTaP (Dose 1/3)</strong> is due on <strong>14 June 2019</strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style="margin-left:15px;border-bottom:1px solid">
              <tr>
                <td style="padding:40px 10px 0px 0px;font-size:12px"><strong>General Instructions:</strong>
                  <p style="margin-bottom:0px">1. Marathi Fonts, 2. Stream inhalation at night</p>
                </td>

              </tr>
              <tr>
                <td style="padding:5px 10px 0px 0px;font-size:12px"><strong>Next Appointment:</strong> -</td>
                <td style="padding:5px 10px 0px 140px;font-size:12px">Dispensed by<br><strong
                    style="margin-left:-22px">Dr. Rohan Kadam</strong></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td *ngFor="let clinic of letterheadClinics"
                  style="padding:5px 5px 15px 15px;font-size:12px;width:250px">
                  <strong>{{clinic.name}}</strong><br>
                  <span>- {{clinic.address_line1}} {{clinic.address_line2}} {{clinic.address_line3}} {{clinic.city}}
                    {{clinic.pincode}}</span>
                </td>

              </tr>
            </table>
          </td>
        </tr>
      </table>

      <!--<div class="row">
          <div class="col-6">
            <div class="letterHedOpt">
              <div class="row grayBottomBorder m-0">
                <div class="col-6 p-0 pb-2">
                  <label class="ifnoLabel lLabel ifnoDetail">{{primaryClinic.name}}</label>
                  <p class="sLabel">
                    <span *ngIf="primaryClinic.address_line1"> {{primaryClinic.address_line1}},</span>
                    <span *ngIf="primaryClinic.address_line2"> {{primaryClinic.address_line2}},</span>
                    <span *ngIf="primaryClinic.address_line3"> {{primaryClinic.address_line3}},</span>
                    <br />
                    <span *ngIf="primaryClinic.city"> {{primaryClinic.city}},</span>
                    <span *ngIf="primaryClinic.pincode"> {{primaryClinic.pincode}}</span>
                  </p>
                  <p class="mLabel mt-1 mb-1" *ngIf="primaryClinic.groupedSessions">For Appointment:
                    <span *ngFor="let contact of primaryClinic.ClinicContacts; let i = index">
                        {{contact.phone}}<span *ngIf="i != primaryClinic.ClinicContacts.length - 1">, </span>
                    </span>
                  </p>
                 
                </div>
                <div class="col-6">
                  <div class="textAlingRight">
                    <label class="ifnoDetail lLabel mb-0">Dr. {{user.first_name}} {{user.last_name}}</label>
                    <p class="sLabel">{{degreeArray[user.doctor.degree]}}</p>
                    <label class="mLabel mt-1 mb-1">{{user.doctor.speciality}}</label>
                    <p class="sLabel">Regn. No. {{user.doctor.registration_number}}</p>
                  </div>
                </div>
              </div>
              <div class="row prescription pt-1 pb-1">
                <div class="col-6">
                    <p class="boldRegular"><span>Prescription No.:</span> PGC/Rx/022016/10970 </p>
                </div>
                <div class="col-6 textAlingRight">
                    <p><span>Date:</span> 17/02/2016</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class=" grayBorder">
                    <div class="row grayBottomBorder m-0">
                      <div class="col-6 grayRightBorder pt-2 pl-3 pb-2">
                        <p class="sLabel">Patient's Name</p>
                        <p class="lLabel upprcase">Netra Sarjerao Mali</p>
                        <p class="sLabel upprcase">Karvenagar pune 411052</p>
                        <p class="sLabel">M: 9623386163</p>
                      </div>
                      <div class="col-3 pt-2 pl-3 pb-2 grayRightBorder">
                        <p class="sLabel">Gender</p>
                        <p class="lLabel upprcase">Female</p>
                        <p class="sLabel boldRegular upprcase"><span>Weight:</span> 39.3 </p>
                      </div>
                      <div class="col-3 pt-2 pl-3 pb-2">
                          <p class="sLabel">Gender</p>
                          <p class="lLabel upprcase">Age 10Y, 8M, 24D</p>
                          <p class="sLabel boldRegular upprcase"><span>Height (CM):</span> 136.5</p>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-6 grayRightBorder  pt-2 pl-3 pb-2">
                        <p class="sLabel">Chief Complaint</p>
                        <p class="lLabel upprcase">Cold, Cough</p>
                      </div>
                      <div class="col-6  pt-2 pl-3 pb-2">
                        <p class="sLabel">On Examination</p>
                        <p class="lLabel upprcase">Chest clear, Throat: PND, Ears: Normal, Nose+, No-Lad, JDJ Small in insig</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <p class="boldRegular pt-2 pb-2"><span>Diagnosis:</span> UTRI</p>
                  <p class="lLabel">Rx</p>
                  <ol class="pl-3">
                    <li>Solvin Cols - ls Exp (AMBROXOL) <br />Dosage: 5 ML---5 ML---5 ML for 5 days, Total QtyL 75 ML</li>
                    <li>STEPTILIN (AYURVEDIC)<br /> Dosage: 5 ML---5 ML---5 ML for 5 days, Total QtyL 75 ML</li>
                  </ol>
                  <p>General Instructions</p>
                  <ol class="pl-3">
                    <li>Marathi Font:</li>
                    <li>Steam inhalation at night</li>
                  </ol>
                  <p class="boldRegular upprcase mb-2"><span>Review After:</span> 2 DAYS</p>
                </div>
                <div class="col-12 clearfix">
                  <div class="dispensedBy pull-right">
                    <p class="m-0 sLabel">Dispensed by</p>
                    <p class="mLabel">Dr. {{user.first_name}} {{user.last_name}}</p>
                  </div>
                </div>
                <!--<div class="col-12 clearfix">
                  <div class="grayBottomBorder pb-2 mt-6">
                    <p class="m-0 mLabel">Trustee: Children's Liver Trst, India</p>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-6" *ngFor="let clinic of letterheadClinics" >
                    <p class="mLabel capitalize">{{clinic.name}}
                        <span>- {{clinic.address_line1}} {{clinic.address_line2}} {{clinic.address_line3}} {{clinic.city}} {{clinic.pincode}}</span>
                      </p>

                
                  <p class="boldRegular">PH:
                      <span *ngFor="let contact of clinic.ClinicContacts; let i = index">
                          {{contact.phone}}<span *ngIf="i != clinic.ClinicContacts.length - 1">, </span>
                        </span>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>-->
      <div class="row">
        <div class="col-12 mt-3">
          <div class="form-group checkbox withLabel">
            <label>
              <input class="filled-in" type="checkbox" [(ngModel)]="agreeTerms">
              <span>I agree to the layout and content of the letterhead and prescription for patients</span>
            </label>
            <p class="errors" *ngIf="!agreeTerms && isSubmit">Please accept terms and conditions</p>
          </div>
        </div>
      </div>
      <div class="center buttons">
        <button (click)="seveTemplateDetails()"
          class="btn btn-primary opd-primary-btn transformNormal btn-lg upprcase">Save &amp; Next</button>
        <!-- <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase">Clear Form</button> -->
        <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase" (click)="currentStage = 3">Go
          back</button>
      </div>
    </div>
  </div>
  <div *ngIf="currentStage == '5'">
    <div class="col-12">
      <div class="thankYouSection">
        <div class="successOuer">
          <div class="success">
            <span></span>
          </div>
        </div>
        <h2 class="inner-header">Success</h2>
        <p class="mb-0">Now you are set to start your practice with COPIOUS 'Care'.</p>
        <p>Kids are waiting for you to take 'Care'!!</p>
        <p><img src="assets/images/signature.png" alt="" /></p>
        <p class="upprcase"><b>Copious 'Care'</b></p>
        <div class="buttons mt-2 btn-relative">
          <a class="btn-primary opd-primary-btn btn-md" routerLink="/doctor/appointments">Go to dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>