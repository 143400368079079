import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { openClose } from '../../animations'
import $ from 'jquery';
import { cloneDeep } from 'lodash'
import { UserService } from '../../services/user.service';
import { DoctorService } from '../../services/doctor.service';
import { PatientService } from "../../services/patient.service";

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../util/utilService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { first } from 'rxjs/operators';
import { debounce } from 'lodash';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewPrescriptions',
  templateUrl: './viewPrescriptions.component.html',
  styleUrls: ['./viewPrescriptions.component.scss'],
  animations: [openClose]

})

export class ViewPrescriptionsComponent implements OnInit, OnDestroy {

  @ViewChild("searchIcon") searchIcon!: ElementRef
  @ViewChild("searchInput") searchInput!: ElementRef;
  @ViewChild('appmtPopUp') appmtPopUp!: ElementRef;
  @ViewChild("todaysBday") todaysBday!:ElementRef;

  searchString: string = ""
  modalRef: any = null
  status: boolean = false;
  user: any = {}
  errors: any = {}
  userId: any
  clinics: any = []
  searchby_name_number: string = ""
  searchby_from_date: any = ""
  searchby_to_date: any = ""
  editMode = false
  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  selectedAppointment:any = null
  appointmentCollection: any = [];
  originalCopy: any = [];
  closeResult: any;
  users:any[] = []
  usersArray:any[] = []
  number = {}
  firstName = {}
  lastName = {}
  minDate = { year: 1900, month: 1, day: 1 }
  today:any = null
  showLoading = false
  order:any[] = []
  isUserSelectedFromList = false
  isTeleAppointMent = '1'
  patientData: any
  appointmentDataForPresc: any
  billAmount: string = ""
  patientEmail: string = ""
  pathArray: any;
  cases: any;

  selectedAppointmentIdToUnlock:any = null;

  constructor(private modalService: NgbModal,
    private userService: UserService,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private util: UtilService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private patientService: PatientService,
    public dialog: MatDialog,

  ) {
    this.pathArray = [
      {
        id: 1,
        name: "Basic Information",
      },
      {
        id: 2,
        name: "Past History",
      },
      {
        id: 3,
        name: "Vitals",
      },
      {
        id: 4,
        name: "Complaints",
      },
      {
        id: 5,
        name: "Treatment",
      },
      {
        id: 6,
        name: "Investigations",
      },
      {
        id: 7,
        name: "Growth & Milestone",
      },
      {
        id: 8,
        name: "Next Appointment",
      }
    ]
  }

  ngOnInit() {
    this.userId = this.authService.getUser()
    this.today = this.util.reverseDate(new Date());
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.getDoctorDetails()
    this.getAppointments();

  }

  // sort = debounce((model, param, order) => {
  //   if (model)
  //     this.order = [[model, param, order]]
  //   else
  //     this.order = [[param, order]]

  //   this.getAppointments()
  // }, 300)

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.close()
    }
  }

  initPatientView() {
    var req_param = { patient_id: localStorage.getItem('patientId'), doctor_id: this.userId };
    this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.cases = response.patientCase;
        this.open(this.todaysBday, 'todaysBdayPopups')
      }
    });
  }
  onclicktime() {

  }

  onChange(index:any) {
    var caseObj = this.cases[index]
    localStorage.setItem("viewCaseId", caseObj.id);
    localStorage.setItem("viewVisitId", caseObj.Visits[0].id);

    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
    // this.getVisits(caseObj.id, localStorage.getItem('patientId'));
  }
  goToLink(path:any, d:any) {
    console.log(path);

    if (path.id === 1) {
      this.router.navigate([`/doctor/patients/patientBasicInfo/view`])
    } else if (path.id === 2) {
      this.router.navigate([`/doctor/patients/pastHistory/view`])
    } else if (path.id === 3) {
      this.router.navigate([`/doctor/patients/vitals/view`]);
    } else if (path.id === 4) {
      this.router.navigate([`/doctor/patients/complaints/view`])
    } else if (path.id === 5) {
      this.router.navigate([`/doctor/patients/treatment/view`])
    } else if (path.id === 6) {
      this.router.navigate([`/doctor/patients/investigations/view`])
    } else if (path.id === 7) {
      this.router.navigate([`/doctor/patients/growthnutrition/view`])
    } else if (path.id === 8) {
      this.router.navigate([`/doctor/patients/nextAppointment/view`])
    }

    // if (d) {
    //   d("close modal");
    // }
  }

  initAppointment() {
    var from = new Date();
    from.setMinutes(from.getMinutes() + 30);
    var to = new Date();
    to.setMinutes(to.getMinutes() + 40);
    this.user = {}
    this.number = {}
    this.editMode = false
    this.appmtPopUp.nativeElement.click();
    var retrievedPatientObject:any = localStorage.getItem('patientData');
    this.patientData = JSON.parse(retrievedPatientObject);
    this.user.mobile_number = `+${this.patientData.User.phone_number.substring(0, 2)} ${this.patientData.User.phone_number.substring(2)} ${this.patientData.User.first_name} ${this.patientData.User.last_name}`
    this.user.dob = this.patientData.User.dob ? this.util.reverseDate(this.patientData.User.dob) : null
    this.user.gender = this.patientData.User.gender
    this.user.patientId = this.patientData.id
    this.user.appointment_from = from;
    this.user.appointment_to = to;
    this.user.appointment_date = "";
  }

  openDialog(content:any, index:any) {
    this.dialog.open(content);
  }

  closeDialog() {
    this.dialog.closeAll();
  }


  updateAppointmentToUnhideRx() {
    // alert(JSON.stringify(appointment));
    let reqVars = {
      id: this.selectedAppointmentIdToUnlock,
      updateParam: { hidePrescription: 0 }
    }

    this.showLoading = true

    this.doctorService.updateAppointmentByIdAndParams(reqVars).subscribe((response:any) => {
      if (response) {
        this.showLoading = false;
        this.getAppointments();
      }
    });
  }

  setSelectedPrescription(appointment:any){
    this.selectedAppointmentIdToUnlock = appointment.id
  }


  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }
  clickEvent() {
    this.status = !this.status;
    if (this.status) {
      this.searchInput.nativeElement.focus();
    }
  }

  getUsers(number:any) {
    if (number.length > 3) {
      this.errors['mobile_number'] = ""
      this.userService.searchUsers(number).subscribe((response:any) => {
        if (response.length) {
          response.forEach((user:any) => {
            user.label = `+${user.contactNo.substring(0, 2)} ${user.contactNo.substring(2)} ${user.firstName} ${user.lastName}`
          });
          this.users = response;
          this.usersArray = this.users
        } else {
          this.users = []
          this.usersArray = this.users
        }
      })
    }
    else {
      this.errors['mobile_number'] = "Please enter atleast 4 digits"
    }
  }

  searchUsers(e:any, type:any) {
    this.users = this.filterUsers(e.query, this.usersArray, type)
  }

  filterUsers(query:any, list:any, type:any) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let user = list[i];
      if (user[type].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }
    return filtered
  }

  openQues(appointmentData:any) {
    this.billAmount = ""
    this.patientEmail = ""
    this.appointmentDataForPresc = appointmentData;
  }

  submitform() {
    let form = document.forms.namedItem("form1") as HTMLFormElement;
    let answers = "[COVID] Age of patient: " + (form.elements.namedItem("age") as HTMLInputElement).value;
    answers += " || Symptoms: ";

    for (let i = 1; i <= 9; i++) {
      let symptomInput = form.elements.namedItem(`symptoms${i}`) as HTMLInputElement;
      if (symptomInput && symptomInput.checked) {
        answers += (i === 1 ? "" : ", ") + symptomInput.value;
      }
    }

    answers += " || Conditions: ";

    for (let i = 1; i <= 10; i++) {
      let conditionInput = form.elements.namedItem(`conditions${i}`) as HTMLInputElement;
      if (conditionInput && conditionInput.checked) {
        answers += (i === 1 ? "" : ", ") + conditionInput.value;
      }
    }

    answers += " || International Travel: " + (form.elements.namedItem("international") as HTMLInputElement).value;
    answers += " || Infected area visited: " + (form.elements.namedItem("area") as HTMLInputElement).value;
    answers += " || Exposure to COVID-19: " + (form.elements.namedItem("exposure") as HTMLInputElement).value;
    answers += " || Worked in hospital: " + (form.elements.namedItem("works") as HTMLInputElement).value;
    answers += " || Conclusion for COVID-19: " + (form.elements.namedItem("conclusion") as HTMLInputElement).value;
    answers += "[COVID]";

    localStorage.setItem("covidques", answers);
    this.onClickPrescription(this.appointmentDataForPresc, 'covid');
  }

  symptomnoneselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;

    const symptomIds = ["symptoms2", "symptoms3", "symptoms4", "symptoms5", "symptoms6", "symptoms7", "symptoms8", "symptoms9"];

    symptomIds.forEach((id) => {
      const symptomElement = form.elements.namedItem(id) as HTMLInputElement;
      if (symptomElement) {
        symptomElement.checked = false;
      }
    });
  }

  conditionnoneselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;

    const conditionIds = [
      "conditions1",
      "conditions2",
      "conditions3",
      "conditions4",
      "conditions5",
      "conditions6",
      "conditions7",
      "conditions8",
      "conditions9"
    ];

    conditionIds.forEach((id) => {
      const conditionElement = form.elements.namedItem(id) as HTMLInputElement;
      if (conditionElement) {
        conditionElement.checked = false;
      }
    });
  }

  conditionitemselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;
    const conditionElement = form.elements.namedItem("conditions10") as HTMLInputElement;

    if (conditionElement) {
      conditionElement.checked = false;
    }
  }

  symptomitemselected() {
    const form = document.forms.namedItem("form1") as HTMLFormElement;
    const symptomElement = form.elements.namedItem("symptoms1") as HTMLInputElement;

    if (symptomElement) {
      symptomElement.checked = false;
    }
  }


  selectUser(user:any) {
    this.firstName = user
    this.lastName = user
    this.user.mobile_number = user.contactNo
    this.user.dob = user.DOB ? this.util.reverseDate(user.DOB) : null
    this.user.gender = user.gender
    this.user.patientId = user._id
    this.isUserSelectedFromList = true
  }

  changeTime() {
    let minutes = this.user['appointment_from'].getMinutes() + 10
    let toTime = new Date(this.user['appointment_from'])
    toTime = new Date(toTime.setMinutes(minutes))
    this.user['appointment_to'] = toTime
  }

  onClickPrescription(appointmentData:any, isFrom:any) {
    localStorage.setItem('appointmentData', JSON.stringify(appointmentData.comepleteData));
    if (isFrom != 'covid') {
      localStorage.setItem("covidques", '');
    }
    this.router.navigate(['/doctor/patients/treatment/view'])
  }

  onClickPatientName(isFrom:any, data:any) {
    if (isFrom == 'appointment') {
      localStorage.setItem('patientId', data.comepleteData.PatientId);
      this.searchString = data.fullName;
      this.searchby_from_date = "";
      this.searchby_to_date = "";
      this.searchAppointmentByNameNumber();
    }
    else {
      var retrievedPatientObject:any = localStorage.getItem('patientData');
      this.patientData = JSON.parse(retrievedPatientObject);
      localStorage.setItem('patientId', this.patientData.id);
      this.searchString = this.patientData.User.first_name + " " + this.patientData.User.last_name;
      this.searchby_from_date = "";
      this.searchby_to_date = "";
      localStorage.removeItem('patientData');
      this.searchAppointmentByNameNumber();
    }
  }

  onClickStartCall(appointmentData:any, string:any) {
    this.showLoading = true;
    this.doctorService.startTeleMeeting(appointmentData.comepleteData).subscribe((response:any) => {
      this.showLoading = false;
      if (string != 'fromInvite') {
        window.open(response.startUrl);
      } else {
        this.toastr.success("Invite sent to patient successfully")
      }
    });
  }

  onClickPrescriptionAfterDay(appointmentData:any) {
    if (appointmentData.comepleteData.prescriptionPDFURL && appointmentData.comepleteData.prescriptionPDFURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPDFURL);
    } else if (appointmentData.comepleteData.prescriptionPhotoURL && appointmentData.comepleteData.prescriptionPhotoURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPhotoURL);
    }
    else {
      this.toastr.warning("No prescription was created for this appointment")
    }
  }

  searchAppointmentByNameNumber() {
    if (this.searchString.length > 0) {
      var arr = [];
      for (let index = 0; index < this.originalCopy.length; index++) {
        const element = this.originalCopy[index].comepleteData;
        var appointmentObj :any;
        let d = new Date()
        if (element.Patient.UserId != null && element.Patient.User) {
          if (this.originalCopy[index].fullName.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.phone_number.includes(this.searchString)) {
            var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentObj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
            };
            var visited = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(visited).getTime() > d.getTime()) {
              appointmentObj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentObj.showStartCall = true
            }
            if (element.VisitId == null && new Date(appointmentObj.visitedOn).getTime() > d.getTime()) {
              appointmentObj.showCancel = true;
            }
            if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
              appointmentObj.cancelled = true

            }
            arr.push(appointmentObj)
          }
        }

      }
      this.appointmentCollection = arr;
    } else {
      this.appointmentCollection = this.originalCopy;
    }
  }

  searchAppointmentByDates() {
    var arr = [];
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    var from = this.util.parseDate(this.searchby_from_date);
    var to = this.util.parseDate(this.searchby_to_date);
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);
    for (let index = 0; index < this.originalCopy.length; index++) {
      const element = this.originalCopy[index].comepleteData;
      var appointmentObj:any;
      let d = new Date()
      if (element.Patient.UserId != null && element.Patient.User) {
        if (new Date(element.visit_date).getTime() > new Date(from).getTime() && new Date(element.visit_date).getTime() < new Date(to).getTime()) {
          var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
          var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

          appointmentObj = {
            id: element.id,
            visitedOn: element.visit_date,
            from: element.from,
            to: element.to,
            mobile: element.Patient.User.phone_number,
            name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
            clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
            deleted: element.deleted,
            comepleteData: element,
            clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
            comment: element.comment,
            fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
            status: element.status,
            userId: element.Patient.User.id,
            showStartCall: false,
            zoomStartURL: element.zoomStartURL,
            PatientId: element.PatientId,
            showCancel: false,
            showCovid: false,
          };
          var visited:any = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
          if (new Date(visited).getTime() > d.getTime()) {
            appointmentObj.showCovid = true;
          }
          if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
            appointmentObj.showStartCall = true
          }
          if (element.VisitId == null && new Date(appointmentObj.visitedOn).getTime() > d.getTime()) {
            appointmentObj.showCancel = true;
          }
          if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
            appointmentObj.cancelled = true

          }
          arr.push(appointmentObj)
        }
      }

    }
    this.appointmentCollection = arr;
  }


  onClick(event:any) {
    if (!this.searchIcon.nativeElement.contains(event.target)) {
      this.status = false
    }
  }
  closeSearch() {
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.searchby_to_date = null;
    this.searchString = '';
    this.getAppointments();
  }
  open(content:any, customClass: any = '') {
    this.user = {}
    this.errors = {}
    this.editMode = false
    this.modalRef = this.modalService.open(content, { size: customClass })
    this.modalRef.result.then((result:any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      this.closeResult = `Dismissed ${reason}`;
    });

  }



  saveAppointment(c:any) {
    if (!this.editMode) {
      // if (this.firstName['first_name']) {
      //   this.user['first_name'] = this.firstName['first_name']
      // } else if (typeof this.firstName == 'string') {
      //   this.user['first_name'] = this.firstName
      // } else {
      //   this.user['first_name'] = null
      // }

      // if (this.lastName['last_name']) {
      //   this.user['last_name'] = this.lastName['last_name']
      // } else if (typeof this.lastName == 'string') {
      //   this.user['last_name'] = this.lastName
      // } else {
      //   this.user['last_name'] = null
      // }

      // if (this.number['contactNo']) {
      //   this.user['mobile_number'] = this.number['contactNo']
      // } else if (typeof this.number == 'string') {
      //   this.user['mobile_number'] = this.number
      // } else {
      //   this.user['mobile_number'] = null
      // }

      // this.user['first_name'] = this.user['first_name'] ? this.user['first_name'].trim() : null
      // this.user['last_name'] = this.user['last_name'] ? this.user['last_name'].trim() : null

      /*this.user['first_name'] = this.firstName['first_name'] ? this.firstName['first_name'] : null
      this.user['last_name'] = this.lastName['last_name'] ? this.lastName['last_name'] : null
      this.user['mobile_number'] = this.number['phone_number'] ? this.number['phone_number'] : null*/
    }
    this.errors = {};
    let date:any;
    let from
    let to
    let h
    let m
    if (this.user['appointment_date']) {

      date = this.util.parseDate(this.user['appointment_date'])
      from = this.user['appointment_from'] ? this.user['appointment_from'] : null
      to = this.user['appointment_to'] ? this.user['appointment_to'] : null
      h = from ? from.getHours() : null
      m = from ? from.getMinutes() : null
      if (from) {
        date.setHours(h)
        date.setMinutes(m)
      }

    }
    console.log(date)
    /*if (!this.user['dob']) {
      this.errors['dob'] = "Please select patient date of birth"
    }else if (!this.user['gender']) {
      this.errors['gender'] = "Please select gender"
    }*/ if (!this.user['appointment_date']) {
      this.errors['appointment_date'] = "Please select appointment date"
    } else if (!this.user['appointment_from']) {
      this.errors['appointment_from'] = "Please select appointment time"
    } else if (date < new Date()) {
      this.errors['appointment_from'] = "Invalid time"
    } else if (!this.user['appointment_to']) {
      this.errors['appointment_to'] = "Please select appointment time"
    } else if (this.user['appointment_to'] <= this.user['appointment_from']) {
      this.errors['appointment_to'] = "Invalid time"
    }
    // else if (!this.user['mobile_number'] || !Number(this.user['mobile_number'])) {
    //   this.errors['mobile_number'] = "Please enter patient mobile number"
    // } else if (this.user['mobile_number'].length < 11) {
    //   this.errors['mobile_number'] = "Please enter mobile number with country code"
    // }
    //   else if (!this.user['first_name'] || this.user['first_name'] == "") {
    //     this.errors['first_name'] = "Please enter patient first name"
    //   } else if (!this.user['last_name']  || this.user['last_name'] == "") {
    //   this.errors['last_name'] = "Please enter patient last name"
    // }
    else if (!['isTeleAppointMent']) {
      this.errors['isTeleAppointMent'] = "Please select if its a TeleAppointment"
    } else {
      //  var time = new Date(this.user['appointment_time'])

      //let localDate =  new Date( Date.parse(`${time} UTC`));

      let reqVars = {
        appointment_id: this.user.appointment_id,
        doctor_id: this.userId,
        from: from,
        to: to,
        appointment_date: date,
        clinic_id: this.user['clinic_id'],
        comment: this.user['comment'],
        patientId: this.patientData.id,
        isTeleCall: this.isTeleAppointMent,
      }
      this.showLoading = true
      c('close modal')

      this.doctorService.addNewAppointment(reqVars).subscribe((response:any) => {
        this.showLoading = false
        localStorage.removeItem('patientData')
        this.user = {}
        this.firstName = {}
        this.lastName = {}
        this.number = {}
        this.editMode = false
        // this.sort(null, 'id', 'desc')
        this.getAppointments()
        this.toastr.success(response.successMessage)
        this.editMode = false
      })
    }
  }

  submitBillGenerateForm(c:any) {
    if (this.billAmount == "") {
      this.toastr.error('Please enter valid bill amount')
    } else {
      let reqVars = {}
      reqVars =
      {
        patientId: this.appointmentDataForPresc.comepleteData.PatientId,
        appointmentId: this.appointmentDataForPresc.comepleteData.id,
        patientemail: this.patientEmail,
        amount: this.billAmount
      }
      this.showLoading = true
      this.doctorService.generatePaymentInvoice(reqVars).subscribe((response:any) => {
        this.showLoading = false
        c('close modal')
        if (response.success) {
          this.getAppointments()
          this.toastr.success('Generated and sent bill to patient successfully')
        } else {
          this.toastr.error('Something went wrong,please try again')
        }

      })
    }
  }


  updateStatus(event:any, appointmentObj:any) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: 'Are you sure you want to update the status?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        appointmentObj.status = 1
        appointmentObj.user_id = this.userId
        this.doctorService.updateAppointment(appointmentObj).subscribe((response:any) => { })
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
    });

  }

  addLabelAutoComplete(e:any) {
    $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelAutoComplete(e:any) {

    if (e.target.value == "") {
      $(e.target.parentElement.parentElement.parentElement.children[1]).removeClass('animatedLabel')
    } else {
      $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
    }

  }

  selectAppointment(appointmentData:any) {
    this.selectedAppointment = appointmentData
    this.editMode = true
    this.user.dob = appointmentData.comepleteData.Patient.User.dob ? this.util.reverseDate(appointmentData.comepleteData.Patient.User.dob) : null
    this.user.gender = appointmentData.comepleteData.Patient.User.gender
    this.user.appointment_date = this.util.reverseDate(appointmentData.comepleteData.visit_date)
    this.user.appointment_from = new Date(appointmentData.comepleteData.from)
    this.user.appointment_to = new Date(appointmentData.comepleteData.to)
    this.user.appointment_id = appointmentData.id
    this.user.mobile_number = appointmentData.comepleteData.Patient.User.phone_number
    this.user.first_name = appointmentData.comepleteData.Patient.User.first_name
    this.user.last_name = appointmentData.comepleteData.Patient.User.last_name
    this.user.clinic_id = appointmentData.clinicId
    this.user.comment = appointmentData.comment
    this.user.userId = appointmentData.userId
  }

  onClickLeftArrow() {
    var lessOneDate = this.util.parseDate(this.searchby_from_date);
    lessOneDate.setDate(lessOneDate.getDate() - 1);
    this.searchby_from_date = this.searchby_from_date = { year: new Date(lessOneDate).getFullYear(), month: new Date(lessOneDate).getMonth() + 1, day: new Date(lessOneDate).getDate() };;
    this.getAppointments();
  }

  onClickRightArrow() {
    var moreOneDate = this.util.parseDate(this.searchby_from_date);
    moreOneDate.setDate(moreOneDate.getDate() + 1);
    this.searchby_from_date = this.searchby_from_date = { year: new Date(moreOneDate).getFullYear(), month: new Date(moreOneDate).getMonth() + 1, day: new Date(moreOneDate).getDate() };;
    this.getAppointments();
  }

  getAppointments() {
    //this.showLoading = true

    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    let that = this
    let d = new Date()
    that.appointmentCollection = []
    this.showLoading = true

    this.doctorService.getAppointments(reqVars).subscribe((response:any) => {
      var appointmentboj: any = {}
      this.showLoading = false
      if (response.appointments) {
        response.appointments.forEach(function (element:any) {
          if (element.Patient && element.Patient.User && element.prescriptionPDFURL && element.prescriptionPDFURL != null) {
            var age = element.Patient.User.dob ? that.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentboj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
              hidePrescription: element.hidePrescription
            };
            var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(visited).getTime() > d.getTime()) {
              appointmentboj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentboj.showStartCall = true
            }
            if (element.VisitId == null && new Date(appointmentboj.visitedOn).getTime() > d.getTime()) {
              appointmentboj.showCancel = true;
            }
            if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
              appointmentboj.cancelled = true

            }
            that.appointmentCollection.push(appointmentboj)
          }
        })

        this.originalCopy = this.appointmentCollection;
        localStorage.removeItem("covidques");
        // this.searchAppointmentByDates();
      }
    })

  }

  closeCalender(e:any, d:any) {
    if (this.user['appointment_date']) {
      this.errors['appointment_date'] = "";
    }
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }


  delete(c:any) {
    this.selectedAppointment.comepleteData['deleted'] = 1
    this.doctorService.updateAppointment(this.selectedAppointment.comepleteData).subscribe((response:any) => {
      this.editMode = false
      c('close modal')
      this.getAppointments()
      this.toastr.success("Appointment has been cancelled successfully")
    })

  }

  getDoctorDetails() {
    let req_vars = { userId: this.userId }
    this.doctorService.getDoctorDetails(req_vars).subscribe((response:any) => {
      this.clinics = response.clinics
      this.user.clinic_id = this.clinics[0].id;
    })
  }
  parseDate(dateObject:any) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
  }

  editAppointment() {
    console.log("hello..")
  }

  trimInput(user:any, name:any) {
    user[name] = user[name].trim()
  }
}
