
  <!-- <div *ngIf="isEditFlag && showSaveButton" style="display: flex; justify-content: end"> <span  (click)="closeDialog();" aria-hidden="true" style="font-size: 34px;cursor: pointer; margin-left: 10px;">&times;</span></div> -->
  <div class="headingflex" style="align-items: center; justify-content: space-between;  padding: 20px;">
      
    <div *ngIf="!isEditFlag" style="font-weight: 600; font-size: 20px; padding-left: 30px; ">Add Vitals</div>
    <div *ngIf="isEditFlag" style="font-weight: 600; font-size: 20px; padding-left: 30px; ">Edit Vitals</div>
   
    <div  style="display:flex; flex-direction: row; ">
    <div style="margin-bottom: 0 10px 30px 0;">
        <mat-form-field  *ngIf="addVisit"  appearance="outline"  style="width: 150px; margin-top: -4px;" >
            <input matInput  [matDatepicker]="picker" [max]="todayDate" 
                [(ngModel)]="selectedVisitdate" placeholder="Choose a date" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
    
        </mat-form-field>
    </div>

        <div *ngIf="showSaveButton && isEditFlag && !visitDeleteFlag">
            <button 
            (click)="opensDialog(delete_modal);" class="btnDelete btn-secondaryDelete" 
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">
            <mat-icon [ngClass]="'red-icon'">
               delete
            </mat-icon>
            <span style="margin-left: 4px;">Delete</span>
        </button>
            <!-- <button (click)="saveVisit();closeDialog();" class="btn btn-secondary" style="float:right" >Save</button> -->
          </div>
        <div *ngIf="showSaveButton">
            <button 
            (click)="saveVisit();closeDialog();" [disable]="vitalsSaveFlag" class="btn btn-secondary" 
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">
            <mat-icon [ngClass]="'blue-icon'">
                save
            </mat-icon>
            <span style="margin-left: 4px;">Save</span>
        </button>
            <!-- <button (click)="saveVisit();closeDialog();" class="btn btn-secondary" style="float:right" >Save</button> -->
          </div>
       
          <div *ngIf="showSaveButton "> <span  (click)="closeDialog();" aria-hidden="true" style="font-size: 40px;cursor: pointer; margin-left: 10px; color: #000;">&times;</span></div>
        
          <!-- <div *ngIf='!isEditableVisit'>
            <button (click)="closeModal();" class="btn-confirm" style="float:right" >Back</button>
          </div> -->

        
     
    </div>
  </div>
  <div *ngIf="isEditFlag && addVisit" style="display: flex;flex-direction: row; justify-content: space-between;">
    <div (click)="addNewVitalsLink('newVisit');"
        style="color:#F57F7F;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; margin: 0px 29px; ">
        Do you want to add NEW vitals?
    </div>
  
<!-- <div *ngIf="!visitDeleteFlag" 
style="flex-direction: row;display:flex; width: 15%;   ">
<img (click)="opensDialog(delete_modal);"
    style="height: 20px;" src="assets/images/delete_red.svg" />
</div> -->
</div>
<div style="display: flex; height: 100%; width: 100%; ">

<mat-tab-group dynamicHeight animationDuration="0ms" class="custom-tab-group" (selectedTabChange)="tabClick($event)" >
    <mat-tab *ngIf="!isEditFlag && latestVisitWithValues.length" label="Frequent" class="custome-tab-lable">
    

        <div style="height: 88vh; width: 100%; display: flex;flex-direction: row;justify-content: center; ">
            <!-- MilestoneByType : All -->
            <div style="height: 80vh; width: 100%; margin-left: 0px; margin-top: 15px;">
                <div style="display:flex; justify-content: space-between; width: 84%; ">
                   
                     <div *ngIf="isPediatric" style="font-size: 18px;  width: 57%;">MIN</div>
                     <div *ngIf="isPediatric"  style="font-size: 18px; ">MAX</div>
                    
                  </div>
                  <div style="display:flex; justify-content: space-between; width: 89%; ">
                  
                    <div *ngIf="isPediatric" style="font-size: 16px; font-weight: 500; width: 57%;">(3rd percentile)</div>
                    <div *ngIf="isPediatric" style="font-size: 16px; font-weight: 500;">(97th percentile )</div>
                   
                 </div>
            
             
            <div >
            <!-- <div>9.3</div> -->
            <div >
           
                <div *ngIf="latestVisitWithValues.includes('weight')">
                    <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;" >
                        <div *ngIf="isPediatric" style="font-size:16px ;" >{{minMaxPercentile.weight.min}}</div>
                        <div>
                        <mat-form-field appearance="outline" ngbTooltip="Previous Record &#13;Date:{{previousVitals.weight.date |  date: 'dd/MM/yyyy'}}&#13;Weight:{{previousVitals.weight.value ? previousVitals.weight.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" >
                            <mat-label>Weight</mat-label>
                            <input (change)="emitHtWt();" matInput placeholder="Weight" maxlength="6" (keypress)="validateNumber($event)"  [(ngModel)]="selectedVisit.weight">
                          </mat-form-field>
                        </div>
                          <div *ngIf="isPediatric" style="font-size:16px ;">{{minMaxPercentile.weight.max}}</div>
                    </div>
                </div>
           
            <div *ngIf="latestVisitWithValues.includes('height')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                    <div *ngIf="isPediatric" style="font-size:16px ;">{{minMaxPercentile.height.min}}</div>
                    <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;Date:{{previousVitals.height.date |  date: 'dd/MM/yyyy'}}&#13;height:{{previousVitals.height.value ? previousVitals.weight.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Height</mat-label>
                        <input  
                        (change)="emitHtWt();" matInput placeholder="height" maxlength="6" (keypress)="validateNumber($event)"  [(ngModel)]="selectedVisit.height">
                      </mat-form-field>
                    </div>
                      <div *ngIf="isPediatric" style="font-size:16px ;">{{minMaxPercentile.height.max}}</div>
                </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('hc')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                    <div *ngIf="showHcAndCC" style="font-size:16px ;"> {{minMaxPercentile["hc"].min}}</div>
                    <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.hc.date |  date: 'dd/MM/yyyy'}}&#13;HC:{{previousVitals.hc.value ? previousVitals.hc.value:'NA'}}" appearance="outline" >
                        <mat-label>Head Circumference</mat-label>
                        <input  placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"  matInput placeholder="Head Circumference" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.hc">
                      </mat-form-field>
                    </div>
                      <div *ngIf="isPediatric" style="font-size:16px ;">{{minMaxPercentile["hc"].max}}</div>
                </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('cc')" style="width: 29%;">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                    <div *ngIf="isPediatric && visitDateDiff" style="font-size:16px ;">{{visitDateDiff.years()
                        <= 0 && visitDateDiff.months() < 9 && visitDateDiff.days()> 0 ? "> 1" : "<
                                1"}}</div>
                                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.cc.date |  date: 'dd/MM/yyyy'}}&#13;CC:{{previousVitals.cc.value ? previousVitals.cc.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>CC</mat-label>
                        <input matInput    placeholder="CC" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.cc">
                      </mat-form-field>
                    </div>
                    <div *ngIf="isPediatric" style="font-size:16px ;"> Actual</div>
                </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Temp')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Temp.date |  date: 'dd/MM/yyyy'}}&#13;Temp:{{previousVitals.Temp.value ? previousVitals.Temp.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Temp(F)</mat-label>
                        <input matInput placeholder="Temp(F)" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Temp">
                      </mat-form-field>
                </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('spo2')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.spo2.date |  date: 'dd/MM/yyyy'}}&#13;SPO2:{{previousVitals.spo2.value ? previousVitals.spo2.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>SpO2</mat-label>
                       
                        <input matInput placeholder="SpO2" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.spo2">
                      </mat-form-field>
                </div>
            </div>
           

            <div *ngIf="latestVisitWithValues.includes('bpSys')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.bpSys.date |  date: 'dd/MM/yyyy'}}&#13;BpSys:{{previousVitals.bpSys.value ? previousVitals.bpSys.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>BP(Sys)</mat-label>
                    <input matInput placeholder="mmHg" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.bpSys">
                  </mat-form-field>
                  </div>
            </div>

            <div *ngIf="latestVisitWithValues.includes('bpDia')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.bpDia.date |  date: 'dd/MM/yyyy'}}&#13;BpDia:{{previousVitals.bpDia.value ? previousVitals.bpDia.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>BP(Dia)</mat-label>
                    <input matInput placeholder="mmHg" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.bpDia">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Heartrate')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Heartrate.date |  date: 'dd/MM/yyyy'}}&#13;HeartRate:{{previousVitals.Heartrate.value ? previousVitals.Heartrate.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Heart Rate</mat-label>
                    <input matInput placeholder="/min" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Heartrate">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Pulse')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Pulse.date |  date: 'dd/MM/yyyy'}}&#13;Pulse:{{previousVitals.Pulse.value ?previousVitals.Pulse.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Pulse</mat-label>
                    <input matInput placeholder="/min" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Pulse">
                  </mat-form-field>
                  </div>
            </div>

            <div *ngIf="latestVisitWithValues.includes('Bloodhaemoglobin')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodhaemoglobin.date |  date: 'dd/MM/yyyy'}}&#13;Bloodhaemoglobin:{{previousVitals.Bloodhaemoglobin.value ? previousVitals.Bloodhaemoglobin.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"  appearance="outline" >
                    <mat-label>Hemoglobin</mat-label>
                    <input matInput placeholder="g/gL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodhaemoglobin">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('BloodSugarRandom')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.BloodSugarRandom.date |  date: 'dd/MM/yyyy'}}&#13;BloodSugarRandom:{{previousVitals.BloodSugarRandom.value ? previousVitals.BloodSugarRandom.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>BloodSugar(Random)</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.BloodSugarRandom">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Bloodsugar_F')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodsugar_F.date |  date: 'dd/MM/yyyy'}}&#13;Bloodsugar_F:{{previousVitals.Bloodsugar_F.value ? previousVitals.Bloodsugar_F.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Bloodsugar(F)</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodsugar_F">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Bloodsugar_PP')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodsugar_PP.date |  date: 'dd/MM/yyyy'}}&#13;Bloodsugar_PP:{{previousVitals.Bloodsugar_PP.value ? previousVitals.Bloodsugar_PP.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Bloodsugar(PP)</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodsugar_PP">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Blood_Hba1c')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Blood_Hba1c.date |  date: 'dd/MM/yyyy'}}&#13;Blood(HbA1c):{{previousVitals.Blood_Hba1c.value ? previousVitals.Blood_Hba1c.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Blood(HbA1c)</mat-label>
                    <input matInput placeholder="%" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Blood_Hba1c">
                  </mat-form-field>
                  </div>
            </div>

            <div *ngIf="latestVisitWithValues.includes('Chol_HDL')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Chol_HDL.date |  date: 'dd/MM/yyyy'}}&#13;Cholesterol HDL:{{previousVitals.Chol_HDL.value ? previousVitals.Chol_HDL.value:'NA'}}"  placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"  appearance="outline" >
                    <mat-label>Cholesterol(HDL)</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Chol_HDL">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Chol_LDL')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Chol_LDL.date |  date: 'dd/MM/yyyy'}}&#13;Cholesterol LDL:{{previousVitals.Chol_LDL.value ? previousVitals.Chol_LDL.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Cholesterol(LDL)</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Chol_LDL">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('TotalCholesterol')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.TotalCholesterol.date |  date: 'dd/MM/yyyy'}}&#13;Total Cholesterol:{{previousVitals.TotalCholesterol.value ? previousVitals.TotalCholesterol.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"appearance="outline" >
                    <mat-label>Total Cholesterol</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.TotalCholesterol">
                  </mat-form-field>
                  </div>
            </div>
            <div *ngIf="latestVisitWithValues.includes('Triglycerides_LFT')">
                <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Triglycerides_LFT.date |  date: 'dd/MM/yyyy'}}&#13;Triglycerides:{{previousVitals.Triglycerides_LFT.value ? previousVitals.Triglycerides_LFT.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"appearance="outline" >
                    <mat-label>Triglycerides</mat-label>
                    <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Triglycerides_LFT">
                  </mat-form-field>
                  </div>
            </div>
        </div>
            <!-- <div>9.3</div> -->
            </div>
              
            </div>
            <!-- MilestoneByType : All -->
        </div>
    </mat-tab>

    <mat-tab label="Vitals" class="custome-tab-lable">
       
        <div   style="height: 88vh; width: 100%; display: flex;flex-direction: row;justify-content: center; margin-top: 20px; ">
            <!-- MilestoneByType : All -->
            <div class="my-container" [style.height]="containerHeight">
              <div style="display:flex; justify-content: space-between; width: 100%; ">
           
               <div *ngIf="isPediatric" style="font-size: 18px;  width: 35%;">MIN</div>
               <div *ngIf="isPediatric"  style="font-size: 18px;  width: 35%;">MAX</div>
              
            </div>
            <div style="display:flex; justify-content: space-between; width: 100%; ">
                
                <div *ngIf="isPediatric" style="font-size: 16px; font-weight: 500; width: 35%;">(3rd percentile)</div>
                <div *ngIf="isPediatric"  style="font-size: 16px; font-weight: 500; width: 35%;">(97th percentile )</div>
               
             </div>

             

            <div >
            <!-- <div>9.3</div> -->
            <div >
            <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;" >
                <div *ngIf="isPediatric" style="font-size:16px ;" class="minMaxHeadings">{{minMaxPercentile.weight.min }}</div>
                <div>
                    <mat-form-field appearance="outline" 
                    ngbTooltip="Previous Record &#13;Date:{{previousVitals.weight.date | date: 'dd/MM/yyyy'}}&#13;Weight:{{previousVitals.weight.value ? previousVitals.weight.value:'NA'}}" 
                    tooltipClass="ngtooltip" 
                    placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" 
                    >
                    <mat-label>Weight</mat-label>
                    <input  
                     (change)="emitHtWt();" matInput placeholder="Weight" maxlength="6" 
                     (keypress)="validateNumber($event)"  [(ngModel)]="selectedVisit.weight">
                  </mat-form-field>                    </div>
                  <div *ngIf="isPediatric" style="font-size:16px ;" class="minMaxHeadings">{{minMaxPercentile.weight.max}}</div>
            </div>
            <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div *ngIf="isPediatric" style="font-size:16px;" class="minMaxHeadings">{{minMaxPercentile.height.min}}</div>
                <div>
                <mat-form-field appearance="outline"  ngbTooltip="Previous Record &#13;{{previousVitals.height.date |  date: 'dd/MM/yyyy'}}&#13;Height:{{previousVitals.height.value ? previousVitals.height.value:'NA'}}" tooltipClass="ngtooltip" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'">
                    <mat-label>Height</mat-label>
                    <input  (change)="emitHtWt();" matInput placeholder="Height" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.height"
                    
                      matTooltipShowDelay="5000">
                  </mat-form-field>
                </div>
                  <div *ngIf="isPediatric" style="font-size:16px ;" class="minMaxHeadings">{{minMaxPercentile.height.max}}</div>
            </div>
            <div *ngIf="showHcAndCC" style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div *ngIf="isPediatric" style="font-size:16px; " class="minMaxHeadings"> {{minMaxPercentile["hc"].min}}</div>
                <div>
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.hc.date |  date: 'dd/MM/yyyy'}}&#13;HC:{{previousVitals.hc.value ? previousVitals.hc.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline"  >
                    <mat-label>Head Circumference</mat-label>
                    <input matInput placeholder="Head Circumference" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.hc">
                  </mat-form-field>
                </div>
                  <div *ngIf="isPediatric" style="font-size:16px ;" class="minMaxHeadings">{{minMaxPercentile["hc"].max}}</div>
            </div>
            <div *ngIf="showHcAndCC" style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div *ngIf="isPediatric && visitDateDiff" style="font-size:16px ;" class="minMaxHeadings">{{visitDateDiff.years()
                    <= 0 && visitDateDiff.months() < 9 && visitDateDiff.days()> 0 ? "> 1" : "<
                            1"}}</div>
                            <div>
                <mat-form-field appearance="outline"  >
                    <mat-label>CC</mat-label>
                    <input matInput  ngbTooltip="Previous Record &#13;{{previousVitals.cc.date |  date: 'dd/MM/yyyy'}}&#13;CC:{{previousVitals.cc.value ? previousVitals.cc.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'"   placeholder="CC" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.cc">
                  </mat-form-field>
                </div>
                <div *ngIf="isPediatric" style="font-size:16px ;" class="minMaxHeadings"> Actual</div>
            </div>
            <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div>
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Temp.date |  date: 'dd/MM/yyyy'}}&#13;Temp:{{previousVitals.Temp.value ? previousVitals.Temp.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>Temp(F)</mat-label>
                    <input matInput placeholder="Temp(F)" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Temp">
                  </mat-form-field>
                  </div>
            </div>
            <div style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div>
                <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.spo2.date |  date: 'dd/MM/yyyy'}}&#13;SPO2:{{previousVitals.spo2.value ? previousVitals.spo2.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                    <mat-label>SpO2</mat-label>
                    <input matInput placeholder="SpO2" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.spo2">
                  </mat-form-field>
                  </div>
            </div>

            <div *ngIf="showLMP" style="display: flex;flex-direction: row; width: 100%; justify-content: space-evenly;">
                <div>
                <mat-form-field  [style.width]="width" ngbTooltip="Previous Record &#13;{{previousVitals.lmp.date |  date: 'dd/MM/yyyy'}}&#13;LMP Date:{{previousVitals.lmp.value ? previousVitals.lmp.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline"  class="LmpFieldWidth">
                    <mat-label >LMP</mat-label>
                    <input matInput  [matDatepicker]="picker" [max]="todayDate" 
                    [(ngModel)]="lmpDate" placeholder="DD-MM-YYYY" (dateChange)="onDateChange($event)"  >
                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  </div>
            </div>

        </div>
            <!-- <div>9.3</div> -->
            </div>
              
            </div>
            <!-- MilestoneByType : All -->
        </div>
    </mat-tab>
    <mat-tab label="BP & Heart" class="custome-tab-lable">
        
            <div style="width: 100%; display:flex;flex-direction: row;justify-content:center; margin-top: 20px;">
                <!-- <div>9.3</div> -->
                <div style="display:flex;flex-direction:column">
                <div>
                    <mat-form-field 
                    ngbTooltip="Previous Record &#13;{{previousVitals.bpSys.date | date: 'dd/MM/yyyy'}}&#13;BpSys:{{previousVitals.bpSys.value ? previousVitals.bpSys.value:'NA'}}" 
                    tooltipClass="ngtooltip" 
                    placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" 
                     
                    appearance="outline">
                    <mat-label>BP(Sys)</mat-label>
                    <input 
                      matInput 
                      placeholder="mmHg" 
                      maxlength="5" 
                      (keypress)="validateNumber($event)" 
                      [(ngModel)]="selectedVisit.bpSys">
                  </mat-form-field>
                  
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.bpDia.date |  date: 'dd/MM/yyyy'}}&#13;BpDia:{{previousVitals.bpDia.value ? previousVitals.bpDia.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>BP(Dia)</mat-label>
                        <input matInput placeholder="mmHg" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.bpDia">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Heartrate.date |  date: 'dd/MM/yyyy'}}&#13;HeartRate:{{previousVitals.Heartrate.value ? previousVitals.Heartrate.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Heart Rate</mat-label>
                        <input matInput placeholder="/min" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Heartrate">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Pulse.date |  date: 'dd/MM/yyyy'}}&#13;Pulse:{{previousVitals.Pulse.value ?previousVitals.Pulse.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Pulse</mat-label>
                        <input matInput placeholder="/min" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Pulse">
                      </mat-form-field>
                </div>
               

            </div>
                
        </div>
    </mat-tab>
    <mat-tab label="Blood Analysis(Sugar)	" class="custome-tab-lable">
      

        <div style="height: 88vh; width: 100%; margin-top: 20px;">
            <div style="width: 100%; display:flex;flex-direction: row;justify-content:center;">
                <!-- <div>9.3</div> -->
                <div style="display:flex;flex-direction:column">
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodhaemoglobin.date |  date: 'dd/MM/yyyy'}}&#13;Bloodhaemoglobin:{{previousVitals.Bloodhaemoglobin.value ? previousVitals.Bloodhaemoglobin.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline"  >
                        <mat-label>Hemoglobin</mat-label>
                        <input matInput placeholder="g/gL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodhaemoglobin">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.BloodSugarRandom.date |  date: 'dd/MM/yyyy'}}&#13;BloodSugarRandom:{{previousVitals.BloodSugarRandom.value ? previousVitals.BloodSugarRandom.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>BloodSugar(Random)</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.BloodSugarRandom">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodsugar_F.date |  date: 'dd/MM/yyyy'}}&#13;Bloodsugar_F:{{previousVitals.Bloodsugar_F.value ? previousVitals.Bloodsugar_F.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Bloodsugar(F)</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodsugar_F">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Bloodsugar_PP.date |  date: 'dd/MM/yyyy'}}&#13;Bloodsugar_PP:{{previousVitals.Bloodsugar_PP.value ? previousVitals.Bloodsugar_PP.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Bloodsugar(PP)</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Bloodsugar_PP">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Blood_Hba1c.date |  date: 'dd/MM/yyyy'}}&#13;Blood(HbA1c):{{previousVitals.Blood_Hba1c.value ? previousVitals.Blood_Hba1c.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Blood(HbA1c)</mat-label>
                        <input matInput placeholder="%" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Blood_Hba1c">
                      </mat-form-field>
                </div>

            </div>
                <!-- <div>9.3</div> -->
                </div>
               
        
        </div>
    </mat-tab>

    <mat-tab label="Lipid Profile" class="custome-tab-lable">
        

        <div style="height: 88vh; width: 100%; margin-top: 20px;">
            <div style="width: 100%; display:flex;flex-direction: row;justify-content:center;">
                <!-- <div>9.3</div> -->
                <div style="display:flex;flex-direction:column">
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Chol_HDL.date |  date: 'dd/MM/yyyy'}}&#13;Cholesterol HDL:{{previousVitals.Chol_HDL.value ? previousVitals.Chol_HDL.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline"  >
                        <mat-label>Cholesterol(HDL)</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Chol_HDL">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Chol_LDL.date |  date: 'dd/MM/yyyy'}}&#13;Cholesterol LDL:{{previousVitals.Chol_LDL.value ? previousVitals.Chol_LDL.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Cholesterol(LDL)</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Chol_LDL">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.TotalCholesterol.date |  date: 'dd/MM/yyyy'}}&#13;Total Cholesterol:{{previousVitals.TotalCholesterol.value ? previousVitals.TotalCholesterol.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Total Cholesterol</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.TotalCholesterol">
                      </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngbTooltip="Previous Record &#13;{{previousVitals.Triglycerides_LFT.date |  date: 'dd/MM/yyyy'}}&#13;Triglycerides:{{previousVitals.Triglycerides_LFT.value ? previousVitals.Triglycerides_LFT.value:'NA'}}" placement="top" [openDelay]="200"  [closeDelay]="200" [container]="'body'" appearance="outline" >
                        <mat-label>Triglycerides</mat-label>
                        <input matInput placeholder="mg/dL" maxlength="5" (keypress)="validateNumber($event)" [(ngModel)]="selectedVisit.Triglycerides_LFT">
                      </mat-form-field>
                </div>
              

            </div>
                <!-- <div>9.3</div> -->
                </div>
               
        
        </div>
    </mat-tab>
    <!-- <mat-tab disabled class="custome-tab-lable"  >
        <ng-template mat-tab-label style="margin-left: -33px">
            <button mat-raised-button color="primary" style="background-color: #5D86CC; font-weight: bold;" (click)="saveVisit()">Save</button>
        </ng-template>
       
        </mat-tab>
    -->

</mat-tab-group>

</div>
<ng-template #delete_modal>
    <h4>Delete Visit?</h4>
    <div style="color: #555; font-size: 16px;">Are you sure you want to delete Visit?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" class="cancelBtnUi">Cancel</button>
        <button (click)="onClickDeleteVisit()" class="deleteBtn">Delete</button>
    </div>
</ng-template>