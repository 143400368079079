import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import { AuthService } from '../../../services/auth.service'
import { UserService } from '../../../services/user.service'
import { Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service'

interface Patients {
  fathersName: string;
  childsName: string;
  lastName: string;
  mothersName: string;
  contactNo: number;
  status: string;
  fcmtoken: string;
  gender: string;

}

const allPatients: Patients[] = [];


@Component({
  selector: 'app-appdownloads',
  templateUrl: './appdownloads.component.html',
  styleUrls: ['./appdownloads.component.scss']
})
export class AppDownloadsComponent implements OnInit {

  patients = allPatients;
  patientsCopy = allPatients;
  searchString: string = "";
  isLoading = false;


  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private userService: UserService, private toastr: ToastrService, private globalService: GlobalService) {

  }

  ngOnInit() {
    this.getAllPatients();
  }

  getAllPatients() {
    this.isLoading = true;
    this.globalService.getPatientHealthAppDownloadReport().subscribe((response:any) => {
      if (response) {
        this.patients = response;
        this.patientsCopy = response;
        this.isLoading = false;
      }
    }, (err:any) => {
      console.error(err);
      alert('Something went wrong')
      this.isLoading = false;

    })

  }

  goBack() {
    this.router.navigateByUrl('/doctor/homepage')
  }

  searchAppointmentByNameNumber() {
    if (this.searchString.length > 0) {
      let patientList = this.patientsCopy;
      let filteredPatientList:any[] = [];

      patientList.map(ins => {
        if (ins.childsName.toLowerCase().includes(this.searchString.toLowerCase()) || ins.lastName.toLowerCase().includes(this.searchString.toLowerCase()) || String(ins.contactNo).includes(this.searchString)) {
          filteredPatientList.push(ins)
        }



      })

      this.patients = filteredPatientList;

    } else {
      this.patients = this.patientsCopy;
    }
  }








}