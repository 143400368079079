import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { UtilService } from '../../../util/utilService';
import * as _ from "lodash"
import { MatDialog } from '@angular/material/dialog';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { Highcharts } from '../highcharts-setup'; 
import { Chart } from 'angular-highcharts';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

//1 - international, 2 - kerala
interface patientInterface {
  id: number;
  User: {
    dob: string
  }
}

@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.css']
})

export class MilestoneComponent implements OnInit {
  chart!: Chart;
  

  @Input() patientObj!: patientInterface;
  @Input() doctorObj: any;
  @Input () milestoneFlag:any
  displayedColumns: string[] = ['status', 'age', 'action', 'recordedOn', 'comments'];
  milestoneTabs: any = [];
  @Output() appReady = new EventEmitter<void>();
  allMilestones: any = [];
  patientsMilestones: any = [];
  milestoneByType: any = [];
  paramPatientId:any;
  developmentalMilestones: any = [];
  languageMilestones: any = [];
  grossMotorMilestones: any = [];
  fineMotorAdaptiveMilestones: any = [];
  personalSocialMilestones: any = [];
  patientDataForBirthDate:any;
  patientAgeInMonths: number = 0;
  selectedMilestoneType: any = 1;
  selectedMilestone: any = {};
  selectedDate:any = "";
  comments = "";
  searchMilestones: string = "";

  formattedMilestones:any[] = [];
  developmental:any[] = [];
  language:any[] = [];

  grossMotor:any[] = [];
  fineMotor:any[] = [];
  personalSocial:any[] = [];
  status: number = 60;
  selectedRowId: any=null;
  @ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<
    ElementRef<HTMLTableRowElement>
  >;


  constructor(private patientService: PatientService, private util: UtilService, public dialog: MatDialog,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe((params:any) => {
      this.paramPatientId = params.patientId;
    })
    // this.initGraph();
    this.getAgeInMonths();
   
      this.getMasterMilestones();
    
  
      
    if(this.milestoneFlag){
      setTimeout(() => {
        var elmnt:any = document.getElementById(this.selectedRowId);
        elmnt.scrollIntoView({behavior: 'smooth', block: 'center', inline : 'center'});
  
      }, 1000);
    }
   
    
    
 
  } 
  getAgeInMonths() {
    this.patientDataForBirthDate=  JSON.parse(localStorage.getItem('patientMoreData')??'null');
    console.log("patientDataForBirthDate", this.patientDataForBirthDate);
    
    var today = new Date();
    var birthDate = new Date(this.patientDataForBirthDate.DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    age = age * 12 + m;
    this.patientAgeInMonths = age;
    console.log("ageMay10",this.patientAgeInMonths)
  }

  openDialog(content:any, index:any) {
    console.log('proper', this.selectedMilestone);
    this.comments = this.selectedMilestone.description;
    this.selectedDate = this.selectedMilestone.date;
    const momentDate = moment(this.selectedDate); 
    this.selectedDate = {
        year: momentDate.year(),
        month: momentDate.month() + 1,
        day: momentDate.date()
    };
    this.dialog.open(content, { disableClose: true });
}

  closeDialog() {
    this.comments = "";
    this.selectedDate = "";
    this.dialog.closeAll()
  }

  setSelectedMilestone = (milestone:any) => {
    this.selectedMilestone = milestone;
  }

  getMasterMilestones = () => {
    const params = {}
    this.patientService.getMasterMilestones(params).subscribe((response:any) => {
      if (response && response.masterMilestones.length) {
        this.allMilestones = response.masterMilestones
        this.getMilestonesOfPatient();
      }
    })
  }

  tabChanged(tabChangeEvent:any): void {

    let condition = this.milestoneTabs[tabChangeEvent.index - 1]

    if (tabChangeEvent.index == 0) {
      this.formatDataForGraph(this.formattedMilestones, "All")
    } else {
      switch (condition) {
        case 'Developmental':
          this.formatDataForGraph(this.developmental, condition)
          break;

        case 'LANGUAGE':
        case 'Language':
          this.formatDataForGraph(this.language, condition)
          break;

        case 'GROSS MOTOR':
          this.formatDataForGraph(this.grossMotor, condition)
          break;

        case 'FINE MOTOR-ADAPTIVE':
          this.formatDataForGraph(this.fineMotor, condition)
          break;

        case 'PERSONAL-SOCIAL':
          this.formatDataForGraph(this.personalSocial, condition)
          break;

        default:
          break;
      }
    }
  }


  formatMilestoneByType() {
    let tabs:any[] = [];
    let formattedMilestones:any[] = [];
    let developmental:any[] = [];
    let language:any[] = [];

    let grossMotor:any[] = [];
    let fineMotor:any[] = [];
    let personalSocial:any[] = [];

    this.allMilestones.map((item:any) => {
        if (item.type == this.selectedMilestoneType) {
            // Getting sub type of milestone (to show in tab)
            if (!tabs.includes(item.sub_type)) {
                tabs.push(item.sub_type);
            }

            // Adding object-data from patient milestones into main milestone-by-type list
            this.patientsMilestones.map((element:any) => {
                if (element.MasterMilestoneId == item.id) {
                    item['condition_met'] = element.condition_met;
                    item['date'] = element.date;
                    item['description'] = element.description;
                    item['status'] = element.status;
                }
            });

            item.status = item.status ? item.status : this.getMilestoneStatus(item.start_month, item.end_month);
            item.edit = false;

            // Avoid duplicates by checking for existing items with the same name
            const isDuplicate = formattedMilestones.some(existingItem => existingItem.name === item.name);
            if (!isDuplicate) {
                if (item.sub_type.toLowerCase() == 'developmental') {
                    developmental.push(item);
                } else if (item.sub_type.toLowerCase() == 'language') {
                    language.push(item);
                } else if (item.sub_type.toLowerCase() == 'gross motor') {
                    grossMotor.push(item);
                } else if (item.sub_type.toLowerCase() == 'fine motor-adaptive') {
                    fineMotor.push(item);
                } else if (item.sub_type.toLowerCase() == 'personal-social') {
                    personalSocial.push(item);
                } else {
                    item['forAllMilestones'] = true;
                }
                formattedMilestones.push(item);
            }
        }
    });

    // Sorting the milestones
    formattedMilestones = formattedMilestones.sort((a, b) => a.end_month - b.end_month || a.start_month - b.start_month);

    // Adding index-based ID and selecting the row
    formattedMilestones.map((ins, idx) => {
        ins["idValue"] = idx;
    });

    formattedMilestones.map(ele => {
        if (ele.status == "3") {
            this.selectedRowId = ele.idValue;
        }
    });

    console.log(this.selectedRowId);

    // Assigning processed data to respective variables
    this.milestoneTabs = tabs;
    this.formattedMilestones = formattedMilestones;
    this.developmental = developmental;
    this.language = language;
    this.grossMotor = grossMotor;
    this.fineMotor = fineMotor;
    this.personalSocial = personalSocial;

    this.milestoneByType = new MatTableDataSource(formattedMilestones);
    this.developmentalMilestones = new MatTableDataSource(developmental);
    this.languageMilestones = new MatTableDataSource(language);
    this.grossMotorMilestones = new MatTableDataSource(grossMotor);
    this.fineMotorAdaptiveMilestones = new MatTableDataSource(fineMotor);
    this.personalSocialMilestones = new MatTableDataSource(personalSocial);

    console.log("personalSocialMilestones", this.personalSocialMilestones);

    // Setting filter predicates
    this.setFilterPredicates();

    this.formatDataForGraph(formattedMilestones, "All");
}
setFilterPredicates() {
  this.milestoneByType.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  console.log("12345milestoneByType",this.milestoneByType);
  
  this.developmentalMilestones.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  this.languageMilestones.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  this.grossMotorMilestones.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  this.fineMotorAdaptiveMilestones.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  this.personalSocialMilestones.filterPredicate = (data: { name: string }, filterValue: string) => data.name.trim().toLowerCase().indexOf(filterValue) !== -1;

}

  formatDataForGraph(data:any, title:any) {
    let yAxisData:any[] = [];
    let xAxisData:any[] = [];

    data.map((ins:any) => {

      console.log('INS', ins)

      let birth = this.patientAgeInMonths;
      let color = '#1E77B4';
      if (ins.condition_met) {
        color = "#165724"
      } else {
        if (birth >= ins.start_month && birth <= ins.end_month) {
          color = "#FF7F0F" //"Due"
        }
        else if (birth > ins.start_month && birth > ins.end_month) {
          color = "#D62827" //"Overdue"
        } else {
          color = "#1E77B4" //"Upcoming"
        }

      }

      yAxisData.push({ low: ins.start_month, high: ins.end_month, color: color })
      xAxisData.push(ins.name + ' (' + ins.sub_type + ')')
    });

    // {y: 95.6,color:'yellow'}
    yAxisData.push([{ low: 0, high: 30, color: 'yellow' }])

    this.initGraph(xAxisData, yAxisData, title);
  }

  initGraph(xAxisData:any, yAxisData:any, title:any) {
    let chart = new Chart({
      chart: {
        type: 'columnrange',
        inverted: true,
        // zoomType: 'x',
        panKey: 'shift',
        // pinchType:'x'
        panning: {
          enabled: true,

        }
      },

      title: {
        text: title + ' Milestones'
      },

      // subtitle: {
      //   text: 'Observed in Vik i Sogn, Norway, 2017'
      // },

      xAxis: {
        categories: xAxisData,
        labels: {
          enabled: false,
        },

        reversed: false
      },

      yAxis: {
        title: {
          text: 'Age in Months'
        },

      },

      exporting: { enabled: true },
tooltip: {
        valueSuffix: ' M',
        headerFormat: '<span style="font-size: 16px; font-weight:bold; color:#000 ">{point.key}</span><br/>'
      },

      plotOptions: {
        columnrange: {
          dataLabels: {
            enabled: true,
            format: '{y} M',
          },

          borderRadius: 4,
          color: '#ccc'
        }
      },

      legend: {
        enabled: false
      },

      series: [{
        name: 'Age',
        type: 'columnrange',
        data: yAxisData,

      }]
    });
    this.chart = chart;
    chart.ref$.subscribe(console.log);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

    // Repeatitive work
    this.milestoneByType.filter = filterValue;

    this.developmentalMilestones.filter = filterValue;
    this.languageMilestones.filter = filterValue;
    this.grossMotorMilestones.filter = filterValue;
    this.fineMotorAdaptiveMilestones.filter = filterValue;
    this.personalSocialMilestones.filter = filterValue;
  }

  getMilestoneStatus = (start_month:any, end_month:any) => {

    //1 - overdue, 2 - prompt, 3 - due, 4 - upcoming
    let birth = this.patientAgeInMonths;
    let status = "4";
    if (birth >= start_month && birth <= end_month) {
      status = "3" //"Due"
    }
    else if (birth > start_month && birth > end_month) {
      status = "1" //"Overdue"
    } else {
      status = "4" //"Upcoming"
    }
    return status;
  }

  getMilestonesOfPatient() {
    const params = {
      patientId: this.patientObj.id? this.patientObj.id: this.paramPatientId
    }
    this.patientService.getPatientMilestones(params).subscribe((response:any) => {
      if (response && response.milestones.length) {
        this.patientsMilestones = response.milestones;
        let masterMilestoneId = response.milestones[0].MasterMilestoneId;
        let type = 1
        this.allMilestones.map((ins:any) => {
          if (ins.id == masterMilestoneId) {
            type = ins.type
          }
        })
        this.selectedMilestoneType = type;
        this.formatMilestoneByType();
      } else {
        this.selectedMilestoneType = this.doctorObj.milestone_type ? this.doctorObj.milestone_type : 2;
        this.formatMilestoneByType();

      }
    })
  }

  savePatientMilestone = () => {
    const params = {
      patientId: this.paramPatientId,
      masterMilestoneId: this.selectedMilestone.id,
      date: this.util.parseDate(this.selectedDate),
      comments: this.comments,
      status: "2",
      condition_met: "1"
    };
console.log("this.patientObj.id",this.patientObj.id,this.paramPatientId);

    this.patientService.savePatientMilestone(params).subscribe((response:any) => {
      this.getMasterMilestones();
      this.closeDialog();
    })
  }

  onSearchMilestones = (event: Event) => {
    this.applyFilter(this.searchMilestones);
  }

}
