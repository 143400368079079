import { MatStepper } from '@angular/material/stepper';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Inject, Pipe, PipeTransform, AfterViewChecked, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
// import { smoothHeight } from '../../../../animations';
import $ from 'jquery';
import moment from 'moment';
import { PatientService } from '../../../../services/patient.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { refreshService } from '../../../../services/refersh.service';
import { AuthService } from '../../../../services/auth.service';
import { DoctorService } from '../../../../services/doctor.service';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MatDateFormats, MatOption, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageSelectTextboxComponent } from '../../../../components/language-select-textbox/language-select-textbox.component';
import { PrescriptionLivePreviewComponent } from '../../prescription-live-preview/prescription-live-preview.component';
import { DatePipe } from '@angular/common';
import { AddVitalsComponent } from '../../add-vitals/add-vitals.component';


// const all_complaints = ["Abd Pain", "Back Pain", "Bed Wetting", "Blood In Urine", "Boil", "Burning Micturation", "Burning While Urination ", "Burns", "Chest Pain", "Cold", "Crying", "Cuts", "Decresed Oral Intake", "Dental Caries", "Dizzying", "Driblling Of Urine", "Dry Cough", "Dry Cough With Expectoation", "Dry Itchy Rash", "Dry Skin", "Dysuria", "Ear Ache", "Ears Itching", "Eneuresis", "Excessive Crying ", "Exclusive Breast Feeding", "Eye Discharge", "Eye Redness", "Fever", "Fever With Chills", "Flat Feet", "Fore Skin Ballooning", "Foul Smell", "Gasses Distension", "Green Sticky Flood Mucus", "Gritting Of Teeth", "Hard Stools", "Head Banging", "Head Injury", "Headache", "Hernia", "Hydrocoel ", "Hydrocole ", "Hyperactive", "Inguinal", "Insect Bite", "Irregular Bowels", "Irritable ", "Jaundice", "Leg Pains", "Lesions  ", "Loose Motions", "Loose Weight Gain", "Maculo Papular Rash", "Mixed Feed", "Mouth Odur", "Mouth Ulcers", "Mucoid", "Nail Biting", "Nasal Blockade", "Nausea", "Neck Glands Swelling", "Nephritis Acute", "Noisy Resperation", "Nose Bleed", "Pica", "Pilo Nephritis", "Poly Urea", "Poor Appetite/ Fussy Eater", "Poor Attention Span", "Poor Consantration", "Poor Wt And Ht", "Prickly Heat", "Rash", "Rash On Face", "Recurent Infections", "Restless At Night", "Runny Nose", "Slow In School", "Sneezing", "Sore Throat", "Stomach Pain", "Strain While Urination And Motion", "Sweating ++ ", "Teeth Grinding ", "Teething", "Temper Tantrums", "Thumb Sucking", "Tongue Coated", "Tooth Ache", "Trauma", "Umblical Hernia", "Urine Albumin", "Vomitting", "Well Visit", "Wheezing"];
let createPrescription = [{ name: "Start a Fresh", selected: true, value: "" }, { name: "Favourite Prescription", selected: false, value: "" }, { name: "Refill Prescription", selected: false, value: "" }]
let tabForOnBording = [{ name: "Chief Complaints", selected: true, value: "", highlighted: true }, { name: "On Examination", selected: false, value: "", highlighted: false }, { name: "Diagnosis", selected: false, value: "", highlighted: false }, { name: "Medicines", selected: false, value: "", highlighted: false }, { name: "Dietary Advice", selected: false, value: "", highlighted: false }, { name: "General Instructions", selected: false, value: "", highlighted: false }]
let chief_complaint = [{ name: 'Abd Pain', selected: false }, { name: 'Back Pain', selected: false }, { name: 'Cold', selected: false }, { name: 'Cough', selected: false }, { name: 'Dry Cough', selected: false }, { name: 'Fever', selected: false }, { name: 'Fever With Chills', selected: false }, { name: 'Eye Redness', selected: false }, { name: 'Vommitting ', selected: false }]
let diagnosis = [{ name: 'Flu', value: "Fever|Bodyache|Weakness ", selected: false }, { name: 'viral Fever', value: "Fever|Bodyache ", selected: false }, { name: 'Covid-19', value: "Fever|Bodyache|weakness|Cough cold", selected: false }, { name: 'Covid-19', value: "Fever|Bodyache|weakness|Cough cold ", selected: false }, { name: 'Covid-19', value: "Fever|Bodyache|weakness|Cough cold ", selected: false }];
let suggested_complaint = [{ name: 'Abd Pain', selected: false }, { name: 'Back Pain', selected: false }]
let selected_complaint = [{ name: 'Fever', selected: false }, { name: 'Back Pain', selected: false }, { name: 'Cold', selected: false }]
let dietary_advices = [{ name: 'Balanced diet', selected: false }, { name: 'Stop milk and milk products diet', selected: false }, { name: 'Full adult diet - 8 months onwards', selected: false }, { name: 'Nourishing oats', selected: false }, { name: 'Vegeterian protein sources', selected: false }, { name: 'Self feeding and soft brushing and gum massage', selected: false }]
let medicineList = [{ name: "DOLOPAR CC TABLET (PARACETAMOL / ACETAMINOPHEN (NA), CAFFEINE (NA), P)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }, { name: "HIMALAYA ANTI-DANDRUFF SHAMPOO (ANTI-DANDRUFF SHAMPOO)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }, { name: "COLD CHECK PLUS TABLET (CAFFIENE 2MG - CHLORO)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }]
let allMeds = [{ name: "DOLOPAR CC TABLET (PARACETAMOL / ACETAMINOPHEN (NA), CAFFEINE (NA), P)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }, { name: "HIMALAYA ANTI-DANDRUFF SHAMPOO (ANTI-DANDRUFF SHAMPOO)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }, { name: "COLD CHECK PLUS TABLET (CAFFIENE 2MG - CHLORO)", dosage: "2 Tablet - Evening - 3 Day(s), After Food", selected: false }]


let duration = ['-', '1 Day(s)', '2 Day(s)', '3 Day(s)', '4 Day(s)', '5 Day(s)', '6 Day(s)', '7 Day(s)', '8 Day(s)', '9 Day(s)', '10 Day(s)', '11 Day(s)', '12 Day(s)', '13 Day(s)', '14 Day(s)', '15 Day(s)', '1 Week(s)', '2 Week(s)', '3 Week(s)', '1 Month', '2 Months', '3 Months', 'Until Finished', "Until cured"];
let units = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15];
let frequency = ["-", "Morning", "Afternoon", "Evening", "Night", "Once a week", "Once a month", "Anytime", "If Required"];
let frequencyWithVal = [{ name: "Morning", value: '' }, { name: "Afternoon", value: '' }, { name: "Evening", value: '' }, { name: "Night", value: '' }, { name: "Once a week", value: '' }, { name: "Once a month", value: '' }, { name: "Anytime", value: '' }, { name: "If Required", value: '' }];


let whenToTake = ["-", "Before food", "After food", "With food", "With Milk", "With fruit juice", "At bed time", "Early morning", "Empty stomach", "If required", "As needed"];
let drugTypeSelection = ["Tablet", "Syrup", "Liquid", "Suspension", "Drop", "Capsule", "Cream", "Gel", "Jelly", "Lotion", "Oil", "Ointment", "Powder", "Sachet", "Granules", "Inhaler", "Spray", "Respule", "Injection", "Vial", "Soap", "Suppository"];
let drugType = [{ name: "Tablet", selected: false, value: "" }, { name: "Syrup", selected: false, value: "" }, { name: "Drop", selected: false, value: "" }, { name: "Capsule", selected: false, value: "" }, { name: "More", selected: false, value: "" }]
let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "OnExamination", selected: true, value: "showOnExamination" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }, { name: "Next Vaccination", selected: true, value: "showNextVaccination" },];
let printingFieldsArray = ["showVitals", "showComplaints", "showOnExamination", "showDiagnosis", "showPrescription", "showGeneralIntructions", "showDiet", "showNextFollowup", "showNextVaccination"]
let followupArray = [{ name: "Call", selected: false }, { name: "Report", selected: false }, { name: "Visit", selected: false }]
let drugUnitTypes = ["Unit", "Fingertip", "Fingertip,Apply-Locally", "Drop", "ml", "Puff", "Sachet", "Scoop", "Bottle", "Strip", "Spoon", "Device"]
// let printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
//   "showHeartrate","showLmp"];
let selectedVitalsFields = [{ name: "weight", selected: true, value: "showWeight" }, { name: "height", selected: true, value: "showHeight" }, { name: "BP", selected: true, value: "showBP" },
{ name: "Pulse", selected: true, value: "showPulse" }, { name: "Temp", selected: true, value: "showTemp" }, { name: "SPO2", selected: true, value: "showSPO2" }, { name: "Haemoglobin", selected: true, value: "showHaemoglobin" }, { name: "LMP", selected: true, value: "showLmp" },
{ name: "Blood Sugar (R)", selected: true, value: "showBloodSugar(R)" }, { name: "Blood Sugar (F)", selected: true, value: "showBloodSugar(F)" }, { name: "Blood Sugar (PP)", selected: true, value: "showBloodSugar(PP)" },
{ name: "Blood (Hb1ac)", selected: true, value: "showBlood(Hb1ac)" }, { name: "Cholesterol (HDL)", selected: false, value: "showCholesterol(HDL)" }, { name: "Cholestrol (LDL) (mg/dL)", selected: false, value: "showCholestrol(LDL)(mg/dL)" },
{ name: "Total Cholesterol (mg/dL)", selected: false, value: "showTotalCholesterol(mg/dL)" }, { name: "Tryglycerides (mg/dL)", selected: false, value: "showTryglycerides(mg/dL)" },
{ name: "Heartrate", selected: true, value: "showHeartrate" }]

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}

function checkForNull(value: any) {
  if (value && value != '' && value != '0') {
    return false
  } else {
    return true;
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-quick.prescription.component',
  templateUrl: './quick.prescription.component.component.html',
  styleUrls: ['./quick.prescription.component.component.css'],

  
  providers: [MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
  // animations: [smoothHeight]
})




export class QuickPrescriptionComponentComponent implements OnInit, AfterViewChecked {
  specialVisitTypeFlag: boolean = false
  printingVitalArray: any;
  selectedVitalsField: any;
  printingVitals: any;
  sinceFeverArray: any = [{ name: "1 day", selected: false }, { name: " 2 days", selected: false }, { name: " 3 days", selected: false }, { name: " 4 days", selected: false }, { name: "", selected: false }]
  feverArray = [{ name: "mild", selected: false }, { name: "Highgrade", selected: false }, { name: "with chilld", selected: false }, { name: "with rigors", selected: false }]
  searchMed: any;
  labTests:any=[]
  selectVitalsField: any = selectedVitalsFields
  doctorObj: any = {}
  detailsComplaintsFlag: boolean = false
  complaintDetails: any = []
  complaintsDetailsArray: any = []
  CurrentApptVisit: any;
  medDuplicateindex: any = ""
  isPediatric: boolean = false
  selectFieldsValue: any = "All Fields"
  isAllSelected: boolean = true
  customPer: any = new FormControl('', [Validators.required]);
  // FrequencyField: any = new FormControl('', [Validators.required]);
  UnitField: any = new FormControl('', [Validators.required]);
  FrequencyField: any = new FormControl('', [Validators.required]);
  FrequencyFieldEdit: any = new FormControl('', [Validators.required]);
  DurationField: any = new FormControl('', [Validators.required]);
  WhentotakeField: any = new FormControl('', [Validators.required]);
  DrugUnitField: any = new FormControl('', [Validators.required])
  drugTypeField: any = new FormControl('', [Validators.required]);
  searchTextField: any = new FormControl('', [Validators.required]);
  myForm!: FormGroup;
  selectedInstructionsForAsPerLangaugae: any
  UnitFieldNewMed: any = new FormControl('', [Validators.required]);
  FrequencyFieldNewMed: any = new FormControl('', [Validators.required]);
  DurationFieldNewMed: any = new FormControl('', [Validators.required]);
  WhentotakeFieldNewMed: any = new FormControl('', [Validators.required]);
  selectFieldsArray: any = selectFields;

  vaccinationName: any = ""
  vaccinationFlag: boolean = false
  assumptionDesignForm!: FormGroup;

  editedValues: any = {};
  isTeleCall:any = null
  patientAppLanguage: string = "";
  @ViewChild('prescritionpreviewcomponent') editViewPrescriptioncomponent!: PrescriptionLivePreviewComponent;
  @ViewChild("followup_modal") followup_modal!: ElementRef;
  @ViewChild("pendingVaccination_modal") pendingVaccination_modal!: ElementRef;

  @ViewChild("noMedicinesInDbModal") noMedicinesInDbModal!: TemplateRef<any>;
  @ViewChild("incomplete_data_modal") incomplete_data_modal!: TemplateRef<any>;
  @ViewChild("add_new_drug_modal") add_new_drug_modal!: TemplateRef<any>;
  @ViewChild("edit_prescription_modal") edit_prescription_modal!: TemplateRef<any>;
  @ViewChild("more_medicine_type_modal") more_medicine_type_modal!: TemplateRef<any>;
  @ViewChild("viewPrescriptionModal") viewPrescriptionModal!: ElementRef;
  @ViewChild("redirect_milestones_modal") redirect_milestones_modal!: TemplateRef<any>;
  @ViewChild('langVaccDue') langVaccDue!: LanguageSelectTextboxComponent
  @ViewChild("redirect_vaccination_modal") redirect_vaccination_modal!: TemplateRef<any>;
  @ViewChild("previous_medicines_modal") previous_medicines_modal!: ElementRef;
  @ViewChild('typeAhead') typeAhead!: ElementRef;
  @ViewChild('searchFavourites') searchFavourites!: ElementRef;

  @ViewChild('select') select!: MatSelect;
  @ViewChild("showPrescription_modalPer") showPrescription_modalPer!: ElementRef;

  @ViewChild('stepper') private stepper!: MatStepper;

  @ViewChild("lngMedInstr") lngMedInstr!: LanguageSelectTextboxComponent;
  @ViewChild("lngDietaryInst") lngDietaryInst!: LanguageSelectTextboxComponent;
  @ViewChild("lngGenInst") lngGenInst!: LanguageSelectTextboxComponent;
  @ViewChild("medLangIns") medLangIns!: LanguageSelectTextboxComponent;
  @ViewChild("langFollowupMessage") langFollowupMessage!: LanguageSelectTextboxComponent;
  @ViewChild("AddVitalsComponent") AddVitalsComponent!: AddVitalsComponent;
  @ViewChild("delete_modal") delete_modal!: ElementRef;
  currentPopularMedObj: any = {}
  allSelectedComplaints: any = []
  selectedVaccination: any = []
  newSelectedComplaints: any = []
  pendingVaccination: any = []
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate'];
  favMediciensArray: any = [];
  myControl = new FormControl();
  myControlFav = new FormControl();
  titleOfSelectPer = "Select Custom Prescription"
  titleOfpreviouslyMed = "Select Refill Prescription"
  selectedPreviouslyMed: any = ""
  selectedPreviouslyMedArray: any = []
  Tounit = "Unit"
  toFrequency = "Frequency";
  Duration = "Duration";
  Whentotake = "When to take";
  drugType: any = 'Drug Type';
  searchText = "Start typing a drug name"
  selectedPer: any = ""
  diagnosisCollectionArray: any[] = []
  diagnosisCollectionArrayOrg: any[] = []
  allMedicienOrg: any = [];
  customPrescription = false
  previouslyMed = false
  createPrescription: any = [];
  ashwin: any = { "firstName": "My first name" };
  visitId: any
  favArraySelected: any
  prescriptionPreviewArray: any = []
  patientId: any = null;
  patientIdFromLoacal:any = null;
  saveButtonDisabledFlag: boolean = false;
  savePreviewButtonFlag: boolean = false;
  selectedVisit: any = {}
  isSaving: boolean = false;


  selectedIndex:any = null;
  selectedIndexOnTab: number = 0;
  duration = duration;
  units: any = units;
  submitted = false;
  frequency = frequency;
  frequencyWithVal = frequencyWithVal;
  whenToTake = whenToTake;
  drugTypeSelection = drugTypeSelection;
  searchStringOnExamination: any = ""
  searchStringDiagnosis: any = ""
  searchStringDietaryAdvice: any = ""
  searchStringGeneralInstructions: any = "";
  isAddMedicine = true; //commenting here
  isAddButton = false
  visitFlag = false
  startFresh = false
  searchString: any = "";
  doseCount: any = [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
  searchedMeds: any = [];
  unselectedDrugName = false;
  specialCases = [
    { case: "New Born" },
    { case: "Well Child" },
    { case: "Vaccination" },
    { case: "Milestones" }
  ];
  doctorSpeciality:any;
  showTopDataHeading:boolean = false;
  editMedicineText = '';
  editMedicineTextDuration = '';
  editMedicineTextInstructions = '';
  hasMedicineFlag = false;
  appointmentData: any
  prescriptionData: any
  selectedUnit = '';
  specialVaccination: any = []
  selectedDrugType: any = '';
  selectedDuration = '';
  selectedWhenToTake = '';
  selectedFrequency: any = [];
  selectedInstructions: any = "";
  allFinalExamination: any = [];
  QuickRxTabArray: any = [];
  allComplaints: any = [];
  allDignosis: any[] = [];
  allDignosisOg: any = []
  allOnExamination: any = [];
  allOnExaminationOg: any = [];
  allDietaryAdvices: any = [];
  allGeneralInstructions: any[] = [];
  allGeneralInstructionsOg: any = [];
  allMedicineInstructions: any = [];
  allMedicineInstructionsOg: any = [];
  allMedicineInstructionsImmutable: any = [];
  allMedicines: any = [];
  allMedicinesForDiagnosis: any = [];
  drugTypeList: any = [];
  drugArray: any = [];
  allMeds: any = allMeds;
  customComplaints = "";
  medicineName: any = {};
  customOnExamination: any = "";
  customDiagnosis: any = ""
  customDietaryAdvice: any = "";
  selectedComplaints: any = [];
  selectedCom: any = []
  selectedSpecialVaccination: any = []
  medicineList: any = [];
  allComplaintsOrg: any = [];
  selectedDiagnosis: any = [];
  selectDiagnosisId: any = [];
  selectedMedicines: any = [];
  selectedDietaryAdvices: any = [];
  selectedOnExamination: any = [];
  selectedGeneralInstructions: any = [];
  moreSymptoms = false
  chief_complaints: string = '';
  selectedVisitdate: any;
  lastvisitIdForPdf: any;
  working_diagnosis: string = '';
  medicine_instructions: string = '';
  observations: string = '';
  notes: string = '';
  printingFields = printingFieldsArray;
  prescriptionPdfURL: any;
  prescriptionreportsURL!: string;
  general_instructions: string = '';
  dietary_advices: string = '';
  patientSelectedLanguage: any = ""
  investigations: string = '';
  referral: string = '';
  showLoading = false;
  suggestComplaints: any = [];
  suggestOnExamination: any = []
  isChiefComplaints = false;
  isMedicine = false;
  isDiagnosis = false
  isDietaryAdvice = false;
  isOnExamination = false
  isGeneralInstruction = false
  selectedMenuToTake: any;
  passedValue:any[] = [];
  diagnosisArr: any = [];
  searchArray: any = [];
  favArray: any = [];
  favArrayNames!: Observable<string[]>;
  patientVisitData: any
  drugTypes:any;
  drugTypesOg: any = [];
  selectedFavMed: any = [];
  selectedPreviousMed:any[] = [];
  selectedPreviousFavMed: any = [];
  currentFavMedObj: any = {};
  patientData: any;
  selectedVaccDueText:any;
  previousMedsArr: any = [];
  ageObj: any = {};
  fullName: any = "";
  gender: any = "";
  drugMolecularFlag: any = ""
  dob: any = "";
  isEditPrescription = false
  contactNo: any = "";
  showAge: any = "";
  age: any = "";
  patient: any = null;
  visits: any = null;
  vitalsArray: any = [];
  weight: any = "NA";
  height: any = "NA";
  doctor: any = {};
  updatedValues: any = {};
  allSelectedField = false;
  onlySelectedField = false;
  oldComplaints: any = [];
  createPerText: any = ""
  selectedPreviousMedDate = '';
  userId: any;
  searchedMedicineObj: any = {};

  allDietaryAdvicesOg: any = []
  languageArray: any = [{ name: 'English', language: 'en' }, { name: 'Marathi', language: 'mr' }, { name: 'Hindi', language: 'hi' }, { name: 'Kannada', language: 'kn' }];
  selectedLanguage: any = { name: "English", language: 'en' };
  templateName: any = "Custom Prescription";
  selectedTemplate: any = [];

  nextFollowUp: any;
  followupType: any = ""
  folloupMessage: any = "";
  followupDate: any;
  patientDataFromLocal: any;
  backToEdit: boolean = false;

  medicineLoader: boolean = false;

  vacDate: any
  showLocality: any
  favMedicineArray: any = []
  drugTypeAddNewDrugs: string = '';
  drugCompanyAddNewDrugs: string = '';
  genericDrugNameAddNewDrugs: string = '';
  drugNameAddNewDrugs: string = '';
  l_patientid: any
  vaccineList: any[] = []
  sess_patid: any;
  unitForNewMed: any = '';
  frequencyForNewMed: any = '';
  frequencyForEditMedicine: any = [];
  durationForNewMed: any = '';
  whenToTakeForNewMed: any = '';
  instructionsForNewMed: any = '';
  allMedicinesForDiagnosisOrg: any = [];
  pdfUrlForViewPrescription: any
  drugUnit: any = null;
  selectedMedicinesSet = new Set<string>();
  drugTypeUnit: string = "";
  editMedicineDosage: string = "";

  frequencyCollapsibleVariable = 4;
  medicineSectionToggle = 1;

  addFollowUpFlag: boolean = true;
  addNextAppointmentFlag: boolean = true;

  appointmentFlag: any = "";

  followupArray = [{ name: "Call", selected: false }, { name: "Appointment", selected: false }]
  followupArrayForQ = [{ name: "Call", selected: false }, { name: "Visit", selected: false }]

  favaddFavPrescriptionTextBox: any = false;
  favApptName: any = "";
  followUpMessageForLanguage: any = [];

  upcomingVaccineList: any[] = []
  appointmentId:any = null;
  currentAppointment:any = null;
  showNextAppointment:boolean = false;
  printRequisition:boolean = false;
  followupDateNew: any;
  nextAppointmentDate:any;
  appointmentDateFromRoute:any;
  appointmentSlotStart:any;
  nextAppointmentDateTime:any;
  nextAppointmentDateTimeUI:any;

  showOtherId: any;
  nextAppointmentDays: any;
  followupDateField: any = new FormControl('', [Validators.required]);

  appointmentBook: any = false;
  languageLoaded!: Promise<boolean>

  drugUnitTypes = drugUnitTypes;

  selectedDrugUnit = '';
  changedHtWt: any = null;

  editFavName: any = ""
  sinceDays: any = "1"
  hideShowFlags: {
    rxTabs: { [key: string]: boolean };
    topNavTabs: { [key: string]: boolean };
} = {
    rxTabs: {},
    topNavTabs: {}
};

  complaintDetailsForFever: any = []
  vitalsSaveFlag!: boolean;
  paramPatientId: any;
  appionmentMessage:any=""
  appoinmentFlag:any
  isTooltipDisabled:boolean=false;
  private VitalsRefreshEventDestroy$ = new Subject<void>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<QuickPrescriptionComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private patientService: PatientService, private fb: FormBuilder, private messageService: MessageService,
    private doctorService: DoctorService, private authService: AuthService, private refreshservice: refreshService, private router: Router, private modalService: NgbModal, private route: ActivatedRoute, public dialog: MatDialog,) {

    this.QuickRxTabArray = tabForOnBording;
    this.allComplaints = chief_complaint
    this.allDignosis = diagnosis;
    // this.suggestComplaints = suggested_complaint;
    this.allDietaryAdvices = dietary_advices
    //this.suggestComplaints=selected_complaint;
    // this.medicineList = medicineList;
    //this.selectFieldsArray = selectFields;
    this.allMedicines = [];
    this.userId = this.authService.getUser();
    this.createPrescription = createPrescription
    // this.initassumpationForm();
  }
  // initassumpationForm() {
  //   this.assumptionDesignForm = this.fb.group({
  //     assumptionDataType: ['', Validators.compose([Validators.required])],
  //   })
  //   console.log("newForm", this.assumptionDesignForm)
  // }

  addFavPrescription(e: any) {
    if (e.target.checked) {
      this.favaddFavPrescriptionTextBox = true
    }
    else {
      this.favaddFavPrescriptionTextBox = false
    }
  }

  toggleFavouriteBox() {
    if (this.favaddFavPrescriptionTextBox) {
      this.favApptName = "";
      this.favaddFavPrescriptionTextBox = false;
    } else {
      this.favaddFavPrescriptionTextBox = true;
      setTimeout(() => {
        const favInputElement = document.getElementById("favInput");
        if (favInputElement) {
          favInputElement.focus();
          favInputElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  toggleFrequencyCollapse() {
    if (this.frequencyCollapsibleVariable == 4) {
      this.frequencyCollapsibleVariable = 9;
    } else {
      this.frequencyCollapsibleVariable = 4;
    }
  }


  toggleMedicineSection = (value:any) => {

    if (value == 3) {
      setTimeout(() => {
        if (this.searchFavourites) {
          this.searchFavourites.nativeElement.focus();
        }
      }, 300);
    }

    this.medicineSectionToggle = value
  }

  openDialog(content:any, index:any) {
    this.selectedIndexOnTab = index;
    this.selectedIndex = index;
    this.dialog.open(content);
  }

  closeModal(type:any) {
    this.dialogRef.close(true)

  }

  setIndex(event: any) {
    console.log("Tab Changed:", event);

    let selectedIndex = event.selectedIndex;
    let previouslySelectedIndex = event.previouslySelectedIndex;

    console.log("Selected Index:", selectedIndex, "Previous Index:", previouslySelectedIndex);

    // Define the tabs with visibility
    let allTabs = [
        { key: 'complaints', visible: this.hideShowFlags.rxTabs['complaints'] },
        { key: 'vitals', visible: this.hideShowFlags.rxTabs['vitals'] },
        { key: 'onExaminations', visible: this.hideShowFlags.rxTabs['onExaminations'] },
        { key: 'diagnosis', visible: this.hideShowFlags.rxTabs['diagnosis'] },
        { key: 'medicines', visible: this.hideShowFlags.rxTabs['medicines'] },
        { key: 'dietaryAdvice', visible: this.hideShowFlags.rxTabs['dietaryAdvice'] },
        { key: 'instruction', visible: this.hideShowFlags.rxTabs['instruction'] }
    ];

    // Filter out hidden tabs to get the correct order
    let visibleTabs = allTabs.filter(tab => tab.visible).map(tab => tab.key);

    // Get selected tab name
    let selectedTab = visibleTabs[selectedIndex];
    console.log("Selected Tab:", selectedTab);

    // Fire function based on the selected tab
    switch (selectedTab) {
        case 'complaints':
          this.searchString = "";
      this.getComplaintsOfDoctor();
            console.log("Complaints tab clicked");
            break;

        case 'vitals':
          console.log("Jan23 vitasl clicked ");
          this.isSaving = false;
          
          this.VitalOldRxClicked();
            break;

        case 'diagnosis':
            this.getDiagnosisOfDoctorAsPerComplaints();
            break;

        case 'onExaminations':
            this.getExaminationOfDoctorAsPerDiagnosis();
            break;

        case 'medicines':
            
      setTimeout(() => {
        this.typeAhead?.nativeElement.focus();
      }, 200);
      this.selectedInstructions = ""
      if (!this.hasMedicineFlag) {
        this.hasMedicineFlag = true;
        this.getMedicinesAsPerDiagnosis();

      }
            break;
            case 'dietaryAdvice':
              this.selectedInstructions = ""
              this.getDietAdviceOfDoctorAsPerDiagnosis()
            break;
            case 'instruction':
              console.log("instruction tab clicked");
              this.selectedInstructions = ""
              this.getGeneralInstructionsOfDoctor()
              break;

        default:
            console.log("No matching tab function found.");
            break;
    }
}


  closeDialog() {
    this.dialog.closeAll();
    this.allMedicineInstructions = this.allMedicineInstructionsOg
  }

  deleteAction() {
    // this.allMedicines.splice(this.selectedIndex, 1);
    this.clearAllMedicines(0);
    this.dialog.closeAll()
  }




  open(content:any, type:any) {

    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
    });
  }

  // goForward(stepper: MatStepper) {
  //   stepper.next();
  // }

  ngOnInit(): void {
    
    this.loadHideShowTabsSettings();
    const storedPatientData = localStorage.getItem("patientData");

    if (storedPatientData) {
      this.patientDataFromLocal = JSON.parse(storedPatientData);
    } else {
      this.patientDataFromLocal = null; // Handle the case where no data is found
    }
    
    this.patientIdFromLoacal = this.patientDataFromLocal.id;
    console.log("inoninit this.patientId ", this.patientDataFromLocal);

    //trying out AUTO REFRESH when version is NEW
    this.authService.getVersion().subscribe((response:any) => {
      console.log(response, localStorage.getItem("version"));
      let currentwebapp:any = "";
      if (localStorage.getItem("version")) {
        currentwebapp = localStorage.getItem("version");
      } 1189
      if (response.webapp != currentwebapp) {
        console.log("Need to be refreshed");
        localStorage.setItem("version", response.webapp);
        window.location.reload();
      }
    });


    setTimeout(() => {
      this.frequencyWithVal.map(element => {
        element.value = '';
      });
    }, 300);
    this.selectedVisit = {
      weight: '',
      height: '',
      Temp: '',
      spo2: '',
      // lmp:null,
      Bloodhaemoglobin: '',
      bpSys: '',
      bpDia: '',
      Heartrate: '',
      Pulse: '',
      BloodSugarRandom: '',
      Bloodsugar_F: '',
      Bloodsugar_PP: '',
      Blood_Hba1c: '',
      Chol_HDL: '',
      Chol_LDL: '',
      TotalCholesterol: '',
      Triglycerides_LFT: '',
      addedBy: 'doctor',
      date: this.reverseDate(new Date())

    };

    this.selectedPer = "Start a Fresh"
    this.selectedInstructionsForAsPerLangaugae = {
      selectedInstructions: ''
    }

    this.selectPrescriptionValue(0);
    //this.getComplaintsOfDoctor();
    this.getDoctorDetails();

    this.templateName = 'default';
    this.route.queryParams
      .subscribe((params:any) => {
        const storedDateTime = localStorage.getItem("nextAppointmentDateTime");

        if (params.selectedNextAppinDate || storedDateTime) {
          if (params.selectedNextAppinDate) {
            setTimeout(() => {
              this.openDialog(this.followup_modal, 'followup_modal');
            }, 200);
          }
          // Assign from route if available, otherwise use stored date
          this.appointmentDateFromRoute = params.selectedNextAppinDate || storedDateTime?.split("T")[0]; // Extract only the date part
          this.appointmentSlotStart = params.slotStart || storedDateTime?.split("T")[1]?.slice(0, 5); // Extract only the time part (hh:mm)

          if (params.selectedNextAppinDate) {
            // Convert to ISO format only if a new appointment date is provided
            this.nextAppointmentDateTime = moment(`${this.appointmentDateFromRoute} ${this.appointmentSlotStart}`, "DD-MM-YYYY hh:mm a")
              .format("YYYY-MM-DDTHH:mm:ss");
            // Store the new date in localStorage
            localStorage.setItem("nextAppointmentDateTime", this.nextAppointmentDateTime);
            
            const formatedAppoitmentDate = moment(this.appointmentDateFromRoute, "DD-MM-YYYY").format("DD/MM/YYYY");
            

            this.nextAppointmentDateTimeUI = `On ${formatedAppoitmentDate}, ${this.appointmentSlotStart}`;
          } else {
            this.nextAppointmentDateTime = storedDateTime;
            // Format appointment date to DD-MM-YYYY
            const formatedAppoitmentDate = moment(this.appointmentDateFromRoute, "YYYY-MM-DD").format("DD/MM/YYYY");
            // Format appointment slot to 12-hour format (hh:mm A)
            const formatedAppoitmentSlot = moment(this.appointmentSlotStart, "HH:mm").format("hh:mm A");
            // Assign formatted values to UI
            this.nextAppointmentDateTimeUI = `On ${formatedAppoitmentDate}, ${formatedAppoitmentSlot}`;
          }

          this.appointmentSlotStart = moment(this.appointmentSlotStart, "HH:mm").format("hh:mm A");
          this.appointmentDateFromRoute = this.appointmentDateFromRoute.split('-').join('/');
        }
        console.log("log20Sep", params); // { orderby: "price" }
        // if(params.patientId){
        //   this.patientId = params;
        // }
        // this.patientId = params.patientId;
        this.backToEdit = params.backToEdit ? params.backToEdit : false;

        // used previously for showing vaccines given in instructions
        // now handling this logic from backend
        this.getPatientsVaccination();

        if (this.backToEdit) {
          //Workaround!

          setTimeout(() => {
            this.stepper.next()
            this.stepper.next()
            this.stepper.next()

          }, 200);
          

        } else {

        }

        //this.getPatientInfo();
        this.resetFromState()

      }
      )



    this.isChiefComplaints = true;
    console.log("dignosis", this.allDignosis);
    var data = [];
    this.allDignosis.map((ins:any) => {
      if (ins.value) {
        this.diagnosisArr.push(ins.value);
      }

    })

    this.route.queryParams
      .subscribe((params:any) => {
        this.patientId = params.patientId;
        // this.appointmentId = params.appointment_id;
        // let passedappointId = params.appointment_id;
        var sess_patid = sessionStorage.getItem("qrx_patientid");
        console.log("oct266666",this.patientId)
        if (sess_patid && sess_patid == this.patientId) {

          //this.resetFromState()

          // this.closeDialog();
          //this.resetFromState()

        } else {
          // this.selectPrescriptionValue(0)
          

          console.log("patientId", params)
        }
      })
    console.log("data", this.diagnosisArr)
    

    this.getPrescriptionMedicines().then(()=>{
      //If appointment check if its exists via getPrescriptionMedicines() which sets this.appointmentId
      if (this.appointmentId != null ) {
        // Appointment already created or exists
        console.log("Appointment already created. with appointment Id",this.appointmentId);
        this.getPatientInfo();
      } else {
        this.createAppoinment().then(()=>{
          this.getPrescriptionMedicines().then(()=>{
            this.getPatientInfo()
          });
        });
        console.log("Appointment already not created. need to create appointment");
      
      }
    })
    this.getAllRequisitions();
    

    setTimeout(() => {
      this.getComplaintsOfDoctor()
    }, 500)

    setTimeout(() => {
      this.getAllRequiredConstants();
    }, 500)

    this.getFavApptsOfDoctor();
    this.getAllDoctorPrescriptions();

    // this.selectModalFieldValue(0);
    this.patientData = localStorage.getItem("patientData");
 if(this.hideShowFlags.rxTabs['vitals']){
    setTimeout(() => {
      //vitals: do not show save button since save in on proceed call.
     
      this.AddVitalsComponent.hideSaveButton();


      this.AddVitalsComponent.VitalsDataChangeWtHtEvent.subscribe(changedvalues => {
        console.log(changedvalues, " ht wt changed ....")
        this.changedHtWt = changedvalues;
        console.log(this.changedHtWt, " new changed ....")
      })

    }, 500)
      }
    this.refreshservice.VitalsRefreshEvent.pipe(takeUntil(this.VitalsRefreshEventDestroy$)) // Auto-unsubscribes on destroy
    .subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      this.getPatientInfo();
    });
    this.loadPendingVaccination();
  }
  loadHideShowTabsSettings() {
    const savedData = localStorage.getItem('hideShowTabs');

    if (savedData) {
        const parsedData = JSON.parse(savedData);

        this.hideShowFlags = {
            rxTabs: parsedData.rxTabs.reduce((acc: any, tab: any) => {
                acc[tab.value] = tab.selected;
                return acc;
            }, {}),
            topNavTabs: parsedData.topNavTabs.reduce((acc: any, tab: any) => {
                acc[tab.value] = tab.selected;
                return acc;
            }, {})
        };
    } else {
        // Default values if nothing is found in localStorage
        this.hideShowFlags = {
            rxTabs: {
                complaints: true,
                vitals: true,
                onExaminations: true,
                diagnosis: true,
                medicines: true,
                dietaryAdvice: true,
                instruction: true
            },
            topNavTabs: {
                investigation:true,
                healthProfile: true,
                documents: true
                
            }
        };
    }
}
  ngOnDestroy() {
    this.VitalsRefreshEventDestroy$.next();
    this.VitalsRefreshEventDestroy$.complete();
    console.log("VitalsRefreshEvent auto-unsubscribed!");
  }

  disableTooltip() {
    this.isTooltipDisabled = true;
  }

  enableTooltip() {
    this.isTooltipDisabled = false;
  }
  createAppoinment(){
    return new Promise((resolve, reject) => {
      if(this.appointmentId!=null){
        resolve("no action");
      }
      else {
        let currentDate = moment(); // This will capture today's date and current time
        let startTime = moment(currentDate, "hh:mm a");
        startTime = moment(startTime).set({ second: 0, millisecond: 0 });
        let endTime = moment(currentDate, "hh:mm a");
        endTime = moment(endTime).set({ second: 0, millisecond: 0 });
        var postData: any = {
          doctor_id: this.userId,
          appointment_date: currentDate.format("YYYY-MM-DD"), // Format today's date
          from: currentDate.format("YYYY-MM-DD") + startTime.format('THH:mm:ssZ'), // Today's date + selected start time
          to: currentDate.format("YYYY-MM-DD") + endTime.format('THH:mm:ssZ'),
          // appointment_date: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD"),     //"2020-04-05T19:46:12.491Z",
          // from: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD") + startTime.format('THH:mm:ssZ'),//"2020-04-05T19:46:12.898Z",
          // to: moment(new Date(this.parseDate(this.selectedDate))).format("YYYY-MM-DD") + endTime.format('THH:mm:ssZ'),//"2020-04-05T19:56:12.898Z",
          clinic_id: null,
          isTeleCall: this.isTeleCall ? "1" : "0",
          patientId: this.patientId,
  
          comment: "",
        }
        console.log("SEPlogPOstData", postData)
  
        this.doctorService.addNewAppointment(postData).subscribe((response:any) => {
  
          //  this.getAllAppointments();
          if (response) {
            
            resolve("new appoontment created");
            //this.getPrescriptionMedicines();
  
            console.log("bookapsep23", response);
          }
        });
      }
    });
    

  }

// commented because next vacc due removed from here 

 getPatientsVaccination = () => {


    // Attempt to retrieve and parse the patient data from localStorage
    const storedPatientData = localStorage.getItem("patientData");

    if (storedPatientData) {
      try {
        this.patientDataFromLocal = JSON.parse(storedPatientData);
        console.log("Patient Data:", this.patientDataFromLocal);
      } catch (error) {
        console.error("Error parsing JSON data:", error);
        this.patientDataFromLocal = null; // Set to null or handle as necessary
      }
    } else {
      console.log("No patient data found.");
      this.patientDataFromLocal = null; // Handle absence of data as needed
    }

    console.log("patientDataFromLocal", this.patientDataFromLocal);
    var patientId = this.patientDataFromLocal.id
    this.patientService.getPatientsVaccination(this.patientId ? this.patientId : patientId).subscribe((response:any) => {

      let vaccineList: any = []
      if (response) {
        vaccineList = response;
        for (let i = 0; i < vaccineList.length; i++) {
          if (this.isJson(vaccineList[i].name)) {
            let scheduleNames = JSON.parse(vaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach((langpart: any) => {
                if (langpart.lang == 'en') {
                  vaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        console.log('VACCCC', vaccineList)

        let vaccineArray: any[] = [];
        let newArray: any[] = [];
        let tempArr: any[] = [];
        let administeredVaccines: any[] = [];


        vaccineList.map((ins: any) => {
          ins.data.map((item: any, idx: any) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines for today
            if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
              administeredVaccines.push(item.name);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });

        });

        // this.unformattedVaccineList = vaccineList;
        vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        vaccineList = vaccineArray;
        this.vaccineList = vaccineList
        console.log('Administered Vaccines', administeredVaccines);
        console.log('Vaccine List', vaccineList);
      }
    });
  }


  isJson(str:any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  selectFollowUpMessage(event:any) {
    console.log("selectFollowUpMessage123",event);
    
    var followupmessage = this.langFollowupMessage.getValue()
    if (followupmessage == event.value) {

    }
    else {
      this.langFollowupMessage.appendTexttoBox(event.value)

    }


    if (event.value == "Next Vaccination due:") {
      console.log("selectFollowUpMessage123 may26", this.vaccineList)
      this.vaccineList.map((ins:any) => {
        if (ins.isOverdue) {
          this.pendingVaccination.push(ins)
        }
        else if (!ins.administeredStatus) {
          this.upcomingVaccineList.push(ins)
        }
      })

      console.log("pendingVaccination", this.pendingVaccination)
      this.pendingVaccination = this.pendingVaccination.reduce((unique:any, o:any) => {
        if (!unique.some((obj:any) => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.upcomingVaccineList = this.upcomingVaccineList.reduce((unique:any, o:any) => {
        if (!unique.some((obj:any) => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);


      this.openDialog(this.pendingVaccination_modal, 'pendingVaccination_modal');

    }


  }


  getAllRequiredConstants = () => {

    // var postData:any={
    //   lang:'mr'
    // }
    this.patientService.getAllRequiredConstants(this.patientSelectedLanguage).subscribe((response:any) => {
      if (response) {
        console.log("june9999", response)
        this.allGeneralInstructions = response.data.generalInstructions.map((ins:any) => { return { name: ins.name, selected: false } });
        this.allGeneralInstructionsOg = response.data.generalInstructions.map((ins:any) => { return { name: ins.name, selected: false } });
        this.allMedicineInstructions = response.data.medicineInstructions.map((ins:any) => { return { name: ins.name, selected: false } });
        this.allMedicineInstructionsOg = response.data.medicineInstructions.map((ins:any) => { return { name: ins.name, selected: false } });
        this.complaintDetails = response.data.complaintDetails

        this.followUpMessageForLanguage = response.data.nextFollowupMessages;
console.log("response.data",response.data);

        console.log("MedIns", this.allMedicineInstructions)




        // this.allMedicineInstructionsImmutable = response.data.medicineInstructions.map((ins:any) => { return { name: ins.name, selected: false } });

        // this.drugTypes = response.data.drugTypes.map((ins:any) => { return { name: ins.name, selected: false } });
        // this.drugTypesOg = response.data.drugTypes.map((ins:any) => { return { name: ins.name, selected: false } });

        this.drugTypes = response.data.drugTypes.map((ins:any) => { return ins.name });
        this.drugTypesOg = response.data.drugTypes.map((ins:any) => { return ins.name });

        //here
        this.drugTypes = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );

        console.log("general", this.drugTypes)
      }
    })
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.drugTypesOg.filter((option:any) => option.toLowerCase().includes(filterValue));
  }

  private _filterFavourites(value: any): string[] {
    console.log('Value', value)

    let filterValue = ''

    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.value.toLowerCase();
    }


    return this.favArray.filter((option:any) => option.value.toLowerCase().includes(filterValue) || option.selectedDiagnosis.toLowerCase().includes(filterValue));
  }



  switchMenu(index:any) {

    let array = this.QuickRxTabArray;

    this.QuickRxTabArray[index].selected = true

    array.map((ins:any, ind:any) => {

      if (ind <= index) {
        array[index].selected = true
        if (ins.selected == true && ins.highlighted == false) {
          ins.highlighted = true
        }
        else {
          ins.highlighted = false
        }
        this.selectedMenuToTake = ins.name;
        this.QuickRxTabArray.map((ins:any, ind:any) => {

          if (index != ind) {
            // the one not clicked
            if (ind < index) {
              ins.highlighted = false;
              ins.selected = true

            } else {
              ins.highlighted = false;
              ins.selected = false
            }

          } else {
            //the one clicked
            ins.highlighted = true;
            ins.selected = true;
          }

        })




        if (this.selectedMenuToTake == 'Chief Complaints') {
          this.isChiefComplaints = true
          this.isDiagnosis = false
          this.isMedicine = false
          this.isDietaryAdvice = false
          this.isOnExamination = false
          this.isGeneralInstruction = false;
          this.getComplaintsOfDoctor();

        }
        else if (this.selectedMenuToTake == 'Diagnosis') {
          this.isDiagnosis = true
          this.isChiefComplaints = false;
          this.isMedicine = false
          this.isDietaryAdvice = false
          this.isOnExamination = false
          this.isGeneralInstruction = false;
          this.getDiagnosisOfDoctorAsPerComplaints();
        }
        else if (this.selectedMenuToTake == 'Dietary Advice') {
          this.selectedInstructions = ""
          this.isDietaryAdvice = true
          this.isDiagnosis = false
          this.isChiefComplaints = false;
          this.isMedicine = false
          this.isOnExamination = false
          this.isGeneralInstruction = false;
          this.getDietAdviceOfDoctorAsPerDiagnosis();
        }
        else if (this.selectedMenuToTake == 'Medicines') {
          this.selectedInstructions = ""
          this.isMedicine = true
          this.isChiefComplaints = false;
          this.isDiagnosis = false
          this.isDietaryAdvice = false
          this.isOnExamination = false
          this.isGeneralInstruction = false;


          if (!this.hasMedicineFlag) {
            this.hasMedicineFlag = true;
            this.getMedicinesAsPerDiagnosis();
          }

        }
        else if (this.selectedMenuToTake == 'On Examination') {
          this.isOnExamination = true
          this.isMedicine = false
          this.isChiefComplaints = false;
          this.isDiagnosis = false
          this.isDietaryAdvice = false
          this.isGeneralInstruction = false;
          this.getExaminationOfDoctorAsPerDiagnosis();
          // this.customComplaints=""

        }
        else if (this.selectedMenuToTake == 'General Instructions') {
          this.selectedInstructions = ""
          this.isGeneralInstruction = true;
          this.isOnExamination = false
          this.isMedicine = false
          this.isChiefComplaints = false;
          this.isDiagnosis = false
          this.isDietaryAdvice = false
        }


      }

    })

  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }


  getPrescriptionMedicines() {

    return new Promise((resolve, reject) => {
      var patientID = this.patientDataFromLocal.id;
      this.selectFieldsArray = this.printRequisition 
      ? [...selectFields, { name: "Print Requisition", selected: true, value: "showRequisition" }]
      : [...selectFields];
      var obj = {
        "patientId": this.patientId ? this.patientId : patientID,
        "selectfield": this.selectFieldsArray
      }
      console.log(" - Updated selectFieldsArray:", this.selectFieldsArray);
      console.log("this.patientId in get PrescriptionMedicines", this.patientId, obj);
  
  
      this.patientService.getPrescriptions(obj).subscribe((response:any) => {
  
        if (response) {
  
          console.log("Prescription response ??", response);
          this.appionmentMessage = response.message
          console.log("testtttttttsep20", this.appionmentMessage)
          console.log("aaptSEp22", this.appointmentId)
          // alert('Response --- ' + JSON.stringify(response));
  
          if (response.appointment) {
            this.appointmentId = response.appointment.id;
            this.currentAppointment = response.appointment;
            console.log("aaptSEp22", this.appointmentId)
            this.editFavName = response.appointment.favApptName
          }
          if (response.visit && response.appointment && response.appointment.prescriptionPDFURL != null && !this.backToEdit) {
            this.showLoading = false;
  
            //  Commenting this as we have now discarded edit prescription screen.
            // this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { patientId: this.patientId } });
  
          }
  
          if (response.visit) {
  
            if (response.visit.chief_complaints || response.visit.complaint_examination
              || response.visit.complaint_diagnosis || response.visit.Medicines.length > 0
              || response.visit.nutrition_diet_advice || response.visit.general_instruction) {
              //basically if any of these 6 fields are coming from database, the is stored and we set from 
              // db values else we let original sessions values stay as it is
  
  
  
              this.selectedComplaints = response.visit.chief_complaints ? response.visit.chief_complaints.split(',') : [];
              this.selectedComplaints = this.selectedComplaints.map((item:any) => item.trim());
              console.log("comple", this.selectedComplaints)
              this.selectedDiagnosis = response.visit.complaint_diagnosis ? response.visit.complaint_diagnosis.split(',') : [];
              this.selectedGeneralInstructions = response.visit.general_instruction ? response.visit.general_instruction.split(',') : [];
              this.selectedDietaryAdvices = response.visit.nutrition_diet_advice ? response.visit.nutrition_diet_advice.split(',') : [];
              this.selectedOnExamination = response.visit.complaint_examination ? response.visit.complaint_examination.split(',') : [];
              this.selectedOnExamination = this.selectedOnExamination.map((item:any) => item.trim());
              this.nextFollowUp = response.visit.next_vaccination;
  
              let data = response.visit.Medicines;
  
              this.allMedicines = [];
  
              data.map((ins:any) => {
  
                if (ins.Drug) {
  
  
  
                  let obj: any = {}
                  if (!this.drugMolecularFlag) { //if no molecule flag set, then no show molecule
                    obj.name = ins.Drug.product_name ? ins.Drug.product_name : ins.Drug.product_name;
                  }
                  else {
                    obj.name = ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name;
                  }
  
                  obj.Drug = ins.Drug;
                  obj.dosagePreviewMed = ins.dosage2;
                  obj.dosage2 = ins.dosage2;
                  obj.duration2 = ins.duration2
                  obj.duration3 = ''//this.days;
                  obj.duration4 = ins.frequency2;
                  obj.finalPreview = ins.dosage2;
                  obj.frequency2 = ins.frequency2;
                  obj.instructions = ins.instructions;
                  obj.quantity = ins.quantity ? ins.quantity : ''
                  obj.whenToTake = ins.whenToTake ? ins.whenToTake : '',
                    obj.dosage = ins.dosage ? ins.dosage : ins.dosage2
                  obj.id = ins.id;
                  obj.selected = true;
  
                  let element = {
                    whenToTake: ins.duration2,
                    medicineName: ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name,
                    dosage2: ins.dosage2,
                    instructions: ins.instructions,
                    id: ins.id,
                  }
  
                  // element.dosage2 = element.whenToTake ? element.dosage2.replace('--', `- ${element.whenToTake} -`) : element.dosage2
  
                  // when we press backto edit delete medicines not getting reflected
                  //so updateing medicines from session not by api
                  //need to check later 
                  this.allMedicines = this.allMedicines ? this.allMedicines : []
  
                  this.allMedicines.push(obj);
  
                  // this.allMedicines = this.allMedicines.reduce((unique, o) => {
                  //   if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
                  //     unique.push(o);
                  //   }
                  //   return unique;
                  // }, []);
                  // this.allMedicines.map((ins:any) => {
                  //   // if (this.drugMolecularFlag == 1) {
                  //   //   ins.Drug.generic_name = ""
                  //   // }
                  // })
                  this.prescriptionPreviewArray.push(element);
                }
              });
  
              // this.saveState();
            }
  
  
          }
          else {
            this.showLoading = false;
            //no prescription
            var sess_patid = sessionStorage.getItem("qrx_patientid");
            if (sess_patid && sess_patid == this.patientId) {
            } else {
  
              //   this.openDialog(this.showPrescription_modalPer, 0)
            }
  
          }
          
          resolve("get Prescription Medicines done");

        }
      });
    });

   
  }
  isSelectedComplaint(complaint: string): boolean {
    return this.selectedComplaints.some((selected: string) => selected?.includes(complaint));
  }
  isSelectedOnExamination(onExamination: string | null | undefined): boolean {
    if (!onExamination) return false; // Return false if undefined or null
    // Ensure selectedOnExamination always contains trimmed values
    this.selectedOnExamination = this.selectedOnExamination.map((item: any) => item.trim());
    const trimmedExam = onExamination.trim(); // Trim input before comparison
    return this.selectedOnExamination.includes(trimmedExam); // Direct comparison with trimmed values
  }
  isSelectedDiagnosis(diagnosis: string): boolean {
    return this.selectedDiagnosis.some((selected: string) => selected.includes(diagnosis));
  }
  isSelectedMedicines(diagnosisCollectionArray: string): boolean {
    return this.allMedicines.some((selected: string) => selected === diagnosisCollectionArray);
  }
  
  isSelectedDietAdvice(dietAdvice: string): boolean {
    return this.selectedDietaryAdvices.some((selected: string) => selected.includes(dietAdvice));
  }
  isSelectedGeneralInstruction(instruction: string): boolean {


    return this.selectedGeneralInstructions.some((selected: string) => selected.includes(instruction));
  }


  getPatientInfo = () => {
    console.log("this.patientId get PatientInfo", this.patientId ? this.patientId : this.patientIdFromLoacal);
    var patientID = this.patientId ? this.patientId : this.patientIdFromLoacal
    this.specialVaccination = []
    this.selectedSpecialVaccination = []
    this.patientService.getPatientInfo(patientID).subscribe((response:any) => {
      if (response) {
        console.log("Response Patients ???????????????????????????????????", response);
        this.patientVisitData = response
        this.patientData = response
        // Call the filtering function
        this.filterSpecialCases(this.patientVisitData.age);
        //comment#1
        // this.patientAppLanguage = response.parent.selectedLanguage;
        // this.patientSelectedLanguage = response.parent.selectedLanguage
        console.log('Patient2', this.patientSelectedLanguage);
        this.isPediatric = response.patientTypes.length === 2 ? true : false;
        


        var additionalInfo = response.additionalInfo ? JSON.parse(response.additionalInfo) : {}

        var specialVaccination = additionalInfo ? additionalInfo.specialVaccination : []

        if (specialVaccination) {
          var todayDate = new Date()
          let todayDateNew = moment(todayDate).format('DD-MMM-YYYY')
          console.log("dateJune19", todayDateNew)

          specialVaccination.map((ins:any) => {
            if (ins.administeredDate == todayDateNew) {
              var splVacc = ""
              this.specialVaccination.push(ins)
              if (ins.name.includes(',')) {
                splVacc = ins.name
              }
              else {
                splVacc = "" + ins.name
              }

              this.selectedSpecialVaccination.push(splVacc)

            }
          })



        }
        console.log("splllJune19", this.specialVaccination)
        // }
        //visits edit component init
       if(this.hideShowFlags.rxTabs['vitals']){
        this.AddVitalsComponent.loadPatientData(this.patientId);

       }

        //set langaue of the text boxes
      
        this.lngMedInstr?.setLanguage(this.patientSelectedLanguage);
        this.lngDietaryInst?.setLanguage(this.patientSelectedLanguage);
        this.lngGenInst?.setLanguage(this.patientSelectedLanguage);     
       
        var d1 = new Date().toISOString().slice(0, 10);
        //console.log("todayDate",d1)

        this.fullName = response.firstName + " " + response.lastName;
        this.gender = response.gender.toLowerCase() == 'male' ? 'M' : 'F';
        this.dob = response.DOB
        this.ageObj = response.age;
        this.contactNo = response.contactNo;

        let lang = "English";

        //comment#2
        switch (response.parent.selectedLanguage) {
          case 'en':
            lang = "English"
            break;

          case 'hi':
            lang = "Hindi"
            break;

          case 'mr':
            lang = "Marathi"
            break;

          case 'kn':
            lang = "Kannada"
            break;

          default:
            break;
        }
        this.patientAppLanguage = lang;

        // this.selectedLanguage = { name: lang, language: response.parent.selectedLanguage };
        // console.log("may266", this.selectedLanguage)
        if (this.ageObj.years <= 2) {
          this.specialVisitTypeFlag = true
        }
        if (this.ageObj.years < 1) {
          // this.ageObj.years="Under 1 Year";
          this.showAge = true;
        }
        else {
          this.ageObj.years;
          this.showAge = false;
        }
        this.age = moment().diff(this.dob, 'years');

        this.patient = response;
        this.visits = response.visits && response.visits.length ? response.visits : [];
        console.log("visits", this.visits)
        this.visits.map((element:any, index:any) => {

          console.log("Ashwin 2223====", element);

          if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear() &&
          element.updatedByName !== "SYSTEM DOC" ) {
          // if(element._id == this.currentAppointment.VisitId) {
            this.CurrentApptVisit = element
            //call add vitals component and select this visit in it
            console.log("Ashwin 2223====", "setting visit",this.CurrentApptVisit);
            // this.AddVitalsComponent.onSelectVisit(this.CurrentApptVisit,0);

            console.log("dateElem", this.CurrentApptVisit)
            if (checkForNull(element.weight) || checkForNull(element.height) || checkForNull(element.Temp) || checkForNull(element.hc)
              || checkForNull(element.cc) || checkForNull(element.bpSys) || checkForNull(element.bpDia) || checkForNull(element.Bloodsugar_F)
              || checkForNull(element.BloodSugarRandom)
              || checkForNull(element.Bloodsugar_PP)
              || checkForNull(element.Blood_Hba1c)
              || checkForNull(element.Bloodhaemoglobin)
              || checkForNull(element.Chol_LDL)
              || checkForNull(element.Chol_HDL)
              || checkForNull(element.Triglycerides_LFT)
              || checkForNull(element.TotalCholesterol)
              || checkForNull(element.spo2)
              || checkForNull(element.Heartrate)
              || checkForNull(element.Pulse)
            ) {
              this.visitFlag = true
              
              console.log('dummy', 'TRUE CONDITION')
            } else {
              this.visitFlag = false
              console.log('dummy', 'ELSE CONDITION')
            }
            console.log('Element-123', element)

            // var visitElement:any=[]
            // visitElement.push(element)
            // visitElement.map(ins=>{
            //   if(ins!="-" ||ins!=""|| ins!=0 || ins!=null){
            //     this.visitFlag = true
            //   }
            //   else{
            //     this.visitFlag = false
            //   }
            // })


            if (element._id === "synthetic_record") {
              this.visits.splice(index, 1);
            }
            // else if (element.weight == 0) {
            //   this.visitFlag = false
            // }
            // else if (element.weight) {
            //   this.visitFlag = true
            // }
          }
          // if (d1==date) {
          //   this.visitFlag = true

          // }
          // else {
          //   this.visitFlag = false
          // }
        });


        this.showLoading = false;
        // this.getPatientAgeCategory();

        if (response.age.years >= 8 && response.gender == "Female") {
          console.log("with lmppppppppppppppp");

          this.printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showLmp", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
            "showHeartrate"];

        } else {
          console.log("without lmppppppppppppppp");

          this.printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
            "showHeartrate"];
        }
        this.selectedVitalsField = this.printingVitalArray;
        this.printingVitals = this.printingVitalArray;

      } else {
        this.showLoading = false;
        alert('Something went wrong')
      }
    });

  }
  filterSpecialCases(age: { years: number; months: number; days: number }) {
    this.showTopDataHeading = true;
    
    this.vaccineList.map((ins:any) => {
      if (ins.isOverdue) {
        this.pendingVaccination.push(ins)
      }
      else if (!ins.administeredStatus) {
        this.upcomingVaccineList.push(ins)
      }
    })    
    this.specialCases = [
      { case: "New Born", condition: age.years === 0 && age.months < 2 },
      { case: "Well Child", condition: age.years < 12 },
      { case: "Vaccination", condition: age.years < 12, pendingVaccines: this.pendingVaccination?.length || 0 },
      { case: "Milestones", condition: age.years < 6 }
  ].filter(caseItem => caseItem.condition); 
}
  selectPrescriptionValue(index:any) {
    let array = this.createPrescription;
    array.map((ins:any, ind:any) => {
      if (ind == index) {
        array[index].selected = true;


        this.selectedPer = ins.name;
        if (this.selectedPer == "Start a Fresh") {

          this.customPrescription = false

          this.previouslyMed = false
        }
        else if (this.selectedPer == "Refill Prescription") {
          this.customPrescription = false
          this.previouslyMed = true
        }
        else if (this.selectedPer == "Favourite Prescription") {
          this.customPrescription = true
          this.previouslyMed = false
        }
        else {
          this.customPrescription = false
          this.previouslyMed = false
        }
        // this.daysWeeks='Weeks'
      } else {
        ins.selected = false;
        //this.daysWeeks='weeks'
        // this.daysWeeks='Weeks'
      }

    });

    console.log("selectePer", this.createPrescription)
  }
  previousMed() {


    console.log("previousMed", this.previousMedsArr);

    this.selectedPreviousMedDate = this.previousMedsArr[0].value;
    this.selectedPreviousMed = this.previousMedsArr[0].medicine;
    this.pdfUrlForViewPrescription = this.previousMedsArr[0].pdfUrl
    console.log("date", this.selectedPreviousMedDate)


  }
  onDropdownSelectPreviousmed(item:any) {
    this.titleOfpreviouslyMed = ""
    console.log("itemData", item);
    this.selectedPreviouslyMed = item.value
    this.selectedPreviousMed = item.medicine
    this.selectedPreviousMed = this.selectedPreviousMed.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.selectedPreviousMed.map((ins:any) => {
      ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
      ins.dosage = ins.dosage2 + ' ' + ins.duration;
      delete ins['id'];
      this.selectedPreviouslyMedArray.push(ins)

    })
  }

  onDropdownSelectFavMedicien = (item:any) => {
    this.titleOfSelectPer = ""

    console.log("newData", item)
    var data: any = item
    this.selectedPreviousFavMed = item.value;
    this.selectedFavMed = item.medicine;
    // this.selectedComplaints=item.selectedComplaints.split(" ")
    this.selectedFavMed = this.selectedFavMed.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.selectedFavMed.map((ins:any) => {
      ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
      ins.dosage = ins.dosage2 + ' ' + ins.duration;
      delete ins['id'];
      this.favMediciensArray.push(ins);

    })

    console.log("selectedComplaints", this.selectedComplaints)



    this.selectedDiagnosis = item.selectedDiagnosis ? item.selectedDiagnosis.split() : []
    this.selectedDietaryAdvices = item.selectedDietaryAdvices ? item.selectedDietaryAdvices.split() : [];
    this.selectedGeneralInstructions = item.selectedGeneralInstructions ? item.selectedGeneralInstructions.split() : []
    this.nextFollowUp = item.nextFollowUp ? item.nextFollowUp.split() : []
  }
  openPrescriptions() {



    if (this.selectedPer == "Start a Fresh") {
      this.titleOfSelectPer = " "
      this.titleOfpreviouslyMed = ""

      // localStorage.setItem('patientData', JSON.stringify(this.patientData));
      this.closeDialog();
    }
    else {
      if (this.titleOfSelectPer) {
        this.customPer.markAsTouched();

      }
      else {
        if (this.selectedPreviousFavMed) {
          this.closeDialog();
          this.previewPrescriptionPDF('preview')
        }
      }
      if (this.titleOfpreviouslyMed) {
        this.customPer.markAsTouched();
      }
      else {
        if (this.selectedPreviouslyMed) {
          this.closeDialog();
          this.previewPrescriptionPDF('preview')
        }
      }
    }






  }


  selectDietaryAdviceslValue(item:any, index:any) {
    if (this.allDietaryAdvices[index].selected == true) {

    } else {
      this.allDietaryAdvices[index].selected = true;
      this.selectedDietaryAdvices.push(item.dietAdvice)
      console.log("addMay", this.selectedDietaryAdvices)
      this.searchStringDietaryAdvice = "";
      this.isAddButton = false;
      //this.allDietaryAdvices = this.allDietaryAdvicesOg
      this.selectDiagnosisId.push(item.id)

    }
    this.saveState();

  }

  selectGeneralInstructionsValue(item:any, index:any) {
    if (this.allGeneralInstructions[index].selected == true) {

      // this.allGeneralInstructions[index].selected = false;
      // this.searchStringGeneralInstructions = "";
      // this.isAddButton = false;
      // // this.allGeneralInstructions = this.allGeneralInstructionsOg
      // this.selectedGeneralInstructions.map((ins:any) => {
      //   if (ins == item.generalInstruction) {

      //     var itemList = this.selectedGeneralInstructions.indexOf(ins);

      //     this.selectedGeneralInstructions.splice(itemList, 1)
      //   }
      // })
    } else {
      this.allGeneralInstructions[index].selected = true;
      this.selectedGeneralInstructions.push(item.generalInstruction)
      this.searchStringGeneralInstructions = "";
      this.isAddButton = false;
      // this.allGeneralInstructions = this.allGeneralInstructionsOg


    }
    this.saveState();
  }
  selectDignosisModalValue(item:any, index:any) {
    this.hasMedicineFlag = false;
    if (this.allDignosis[index].selected == true) {

      // this.allDignosis[index].selected = false;
      // this.searchStringDiagnosis = ""
      // this.isAddButton = false
      // // this.allDignosis=this.allDignosisOg
      // this.selectedDiagnosis.map((ins:any) => {
      //   if (ins == item.diagnosis) {

      //     var itemList = this.selectedDiagnosis.indexOf(ins);

      //     this.selectedDiagnosis.splice(itemList, 1)
      //   }
      // })
    } else {
      this.allDignosis[index].selected = true;
      this.selectedDiagnosis.push(item.diagnosis)
      this.searchStringDiagnosis = ""
      this.isAddButton = false
      // this.allDignosis=this.allDignosisOg
      this.selectDiagnosisId.push(item.id)

    }

    this.saveState();
  }
  drugTypeSelect(item:any, index:any) {
    console.log("item", item)
    if (this.drugTypeList[index].selected == true) {
      this.drugTypeList[index].selected == false
    }
    else {
      this.drugTypeList[index].selected == true
    }
  }

  formatArray(elemet:any) {
    var moreComplaints:any[] = []

    var moredata = {}
    elemet.map((ins:any) => {
      moredata = {
        name: ins,
        selected: false
      }
      moreComplaints.push(moredata)
    })
    return moreComplaints

  }
  selectComplaintModalValue(item: any, index: any, type: string) {
    console.log(`Before Toggle (${type}):`, item);

    if (item?.complaint === "Vaccination") {
        this.dialog.open(this.redirect_vaccination_modal);
        return;
    }
    let existingComplaintIndex = this.selectedComplaints.findIndex((c: any) => c.includes(item.complaint));
    let formattedComplaint = existingComplaintIndex !== -1 ? this.selectedComplaints[existingComplaintIndex] : item.complaint;

    // Remove if already exists, so we can re-add it at the end
    if (existingComplaintIndex !== -1) {
        this.selectedComplaints.splice(existingComplaintIndex, 1);
    }

    // Push complaint to the end
    this.selectedComplaints.push(formattedComplaint);
    console.log("Updated Selected Complaints:", this.selectedComplaints);

    // Clear suggested complaints
    this.suggestComplaints = [];

    const complaintDetailsMap: { [key: string]: string[] } = {
        Fever: this.complaintDetails.fever,
        Cough: this.complaintDetails.cough,
        Vomitting: this.complaintDetails.vomitting,
        Toothache: this.complaintDetails.toothache,
        Headache: this.complaintDetails.headache,
        "Abdominal Pain": this.complaintDetails["abdominal pain"],
        Cold: this.complaintDetails.cold,
        "Loose Motions": this.complaintDetails["loose motions"],
        Rash: this.complaintDetails.rash,
        Bodyache: this.complaintDetails["body ache"],
        Depression: [
            "Low Mood", "Anhedonia", "Fatiguibility", "Sed attention and concentration",
            "Guilt", "Hopelessness", "Helplessness", "Worthlessness", "Sleep", "Appetite"
        ]
    };

    this.complaintDetailsForFever = this.formatArray(complaintDetailsMap[item.complaint] || []);
    this.detailsComplaintsFlag = this.complaintDetailsForFever.length > 0;
    
    console.log("Complaint Details for Fever:", this.complaintDetailsForFever);

    this.complaintDetailsForFever = this.complaintDetailsForFever.map((detail: any) => ({
        ...detail,
        selected: this.selectedComplaints.some((c: any) => c.includes(detail.name) && c.includes(item.complaint))
    }));

    console.log("Updated Complaint Details:", this.complaintDetailsForFever);

    this.allComplaints[index].selected = true;

    const suggestedComplaintIds = (item.suggestedComplaintIds || "")
        .split(",")
        .map((id: any) => id.trim())
        .filter((id: any) => id !== "" && !isNaN(id));

    this.suggestComplaints = this.allComplaints.filter((complaint: any) =>
        suggestedComplaintIds.includes(complaint.id.toString())
    );

    console.log("Suggested Complaints:", this.suggestComplaints);

    const containsDetailedComplaint = this.selectedComplaints.some(
        (c: any) => c.includes(item.complaint) && c.includes("-")
    );

    if (!this.selectedComplaints.includes(item.complaint) && !containsDetailedComplaint) {
        this.selectedComplaints.push(item.complaint);
    } else {
        console.log(`Skipping duplicate complaint: ${item.complaint}`);
    }

    this.allSelectedComplaints.push(item.complaint);
    this.complaintsDetailsArray.push(item.complaint);
    this.selectedCom.push(item.id);

    this.suggestComplaints = suggestedComplaintIds
        .map((id: any) => this.allComplaints.find((c: any) => c.id.toString() === id))
        .filter(Boolean);

    this.moreSymptoms = this.suggestComplaints.length > 0;

    console.log("Final Suggested Complaints:", this.suggestComplaints);

    // Ensure selected complaints update properly
    this.selectedComplaints = [...this.selectedComplaints];

    if (this.selectedComplaints.length === 0) {
        this.allComplaints.forEach((complaint: any) => complaint.selected = false);
       
        this.complaintDetailsForFever = [];
        this.detailsComplaintsFlag = false;
    }

    this.saveState();
    
    this.suggestComplaints = [...this.suggestComplaints];
   
    this.changeDetectorRef.detectChanges();
}
handleSuperComplaintSelection(item: any) {
  this.previewPrescriptionPDF('saveonly')
  console.log("Handling Super Complaint:", item);
  // gotoMilstone
  if(item.case =='Milestones'){
    this.dialog.open(this.redirect_milestones_modal);
  }
  // gotoVaccination
  if(item.case =='Vaccination'){
    this.dialog.open(this.redirect_vaccination_modal);
  }
   // gotoNewBorn
   if(item.case =='New Born'){
    // this.dialog.open(this.redirect_vaccination_modal);
  }
    // gotoWellChild
    if(item.case =='Well Child'){
      // this.dialog.open(this.redirect_vaccination_modal);
    }
}
  
  saveState() {
    if (!this.backToEdit) {

      //all... vars to push to SessionStorage
      var l_patientid = sessionStorage.getItem("qrx_patientid");
      if (l_patientid && l_patientid != this.patientId) {
        //purge all previous data
        sessionStorage.setItem("qrx_patientid", '');
        sessionStorage.setItem("qrx_selectedComplaints", '');
        sessionStorage.setItem("qrx_selectedOnExamination", '');
        sessionStorage.setItem("qrx_selectedDiagnosis", '');
        sessionStorage.setItem("qrx_selectedDietaryAdvices", '');
        sessionStorage.setItem("qrx_selectedGeneralInstructions", '');
        sessionStorage.setItem("qrx_allMedicines", '');
        sessionStorage.setItem("qrx_allMedicinesForDiagnosis", '');



      }
      sessionStorage.setItem("qrx_patientid", this.patientId);
      sessionStorage.setItem("qrx_selectedComplaints", JSON.stringify(this.selectedComplaints));
      sessionStorage.setItem("qrx_selectedOnExamination", JSON.stringify(this.selectedOnExamination));
      sessionStorage.setItem("qrx_selectedDiagnosis", JSON.stringify(this.selectedDiagnosis));
      sessionStorage.setItem("qrx_selectedDietaryAdvices", JSON.stringify(this.selectedDietaryAdvices));
      sessionStorage.setItem("qrx_selectedGeneralInstructions", JSON.stringify(this.selectedGeneralInstructions));
      sessionStorage.setItem("qrx_allMedicines", JSON.stringify(this.allMedicines));
      sessionStorage.setItem("qrx_allMedicinesForDiagnosis", JSON.stringify(this.allMedicinesForDiagnosis));
    }
  }
 
  hasSinceDaysComplaint(): boolean {
    console.log("Selected Complaints:", this.selectedComplaints);
    
    return this.selectedComplaints.some((complaint: string) => 
      complaint?.trim().toLowerCase().includes('since')  // Trim spaces before checking
    );
  } 

  //DEBUG
  resetFromState() {
    // return;
    this.l_patientid = sessionStorage.getItem("qrx_patientid");
    if (this.l_patientid && this.l_patientid != this.patientId) {
      //purge all previous data
      sessionStorage.setItem("qrx_patientid", '');
      sessionStorage.setItem("qrx_selectedComplaints", '');
      sessionStorage.setItem("qrx_selectedOnExamination", '');
      sessionStorage.setItem("qrx_selectedDiagnosis", '');
      sessionStorage.setItem("qrx_selectedDietaryAdvices", '');
      sessionStorage.setItem("qrx_selectedGeneralInstructions", '');
      sessionStorage.setItem("qrx_allMedicines", '');
      sessionStorage.setItem("qrx_allMedicinesForDiagnosis", '');
    } else {
      if (sessionStorage.getItem("qrx_selectedComplaints")) {
        this.selectedComplaints = JSON.parse(sessionStorage.getItem("qrx_selectedComplaints") || '[]');
      }
      if (sessionStorage.getItem("qrx_selectedOnExamination")) {
        this.selectedOnExamination = JSON.parse(sessionStorage.getItem("qrx_selectedOnExamination") || '[]');
      }
      if (sessionStorage.getItem("qrx_selectedDiagnosis")) {
        this.selectedDiagnosis = JSON.parse(sessionStorage.getItem("qrx_selectedDiagnosis") || '[]');
      }
      if (sessionStorage.getItem("qrx_selectedDietaryAdvices")) {
        this.selectedDietaryAdvices = JSON.parse(sessionStorage.getItem("qrx_selectedDietaryAdvices") || '[]');

      }
      if (sessionStorage.getItem("qrx_selectedGeneralInstructions")) {
        this.selectedGeneralInstructions = JSON.parse(sessionStorage.getItem("qrx_selectedGeneralInstructions") || '[]');

      }
      if (sessionStorage.getItem("qrx_allMedicines")) {

        this.allMedicines = JSON.parse(sessionStorage.getItem("qrx_allMedicines") || '[]');
        this.allMedicines = this.allMedicines ? this.allMedicines : []

        if (!this.allMedicines.length) {

        } else {
          this.allMedicines.map((ins:any) => { return ins.selected = true });
        }
      }
      if (sessionStorage.getItem("qrx_allMedicinesForDiagnosis")) {
        let parsedData = sessionStorage.getItem("qrx_allMedicinesForDiagnosis");
      }

    }

  }
// Step 1: Selecting a Complaint
onSelectComplaint(complaint: any) {
  console.log("Selected Complaint:", complaint);

  // Avoid duplicate complaints
  if (!this.selectedComplaints.includes(complaint)) {
    this.selectedComplaints.push(complaint);
  }

  console.log("Updated selectedComplaints:", this.selectedComplaints);
}
// Step 2: Selecting Days Since the Complaint Started
onSelectSinceComplaintsDays(sinceDay: any) {
    console.log("Updating complaint with days:", sinceDay);

    if (this.selectedComplaints.length === 0) return;

    let lastIndex = this.selectedComplaints.length - 1;
    let lastComplaint = this.selectedComplaints[lastIndex];

    if (!lastComplaint.includes("-- Since")) {
      this.selectedComplaints[lastIndex] = `${lastComplaint} -- Since ${sinceDay} day(s)`;
    }

    console.log("Updated selectedComplaints with days:", this.selectedComplaints);

    this.changeDetectorRef.detectChanges();
  }



// Step 3: Selecting Severity/Modifiers

onSelectSinceComplaints(item: any, index: number) {
  console.log("Before Toggle:", this.complaintDetailsForFever[index]);

  // Ensure item is selected once, but do not allow unselection
  if (!this.complaintDetailsForFever[index].selected) {
    this.complaintDetailsForFever[index].selected = true;  // Select it
  }

  // Check if the complaint is already in selectedComplaints
  let isAlreadySelected = this.selectedComplaints.some((complaint:any) =>
    complaint.includes(item.name)
  );

  if (isAlreadySelected) {
    console.log("Item already exists. Skipping:", item.name);
    return;  // Prevent adding duplicates
  }

  if (this.selectedComplaints.length === 0) return;

  let lastIndex = this.selectedComplaints.length - 1;
  let lastComplaint = this.selectedComplaints[lastIndex];

  console.log("Applying severity/modifier:", item.name);
  console.log("Last Selected Complaint Before:", lastComplaint);

  // If last complaint already has a "Since" condition, update it
  if (lastComplaint.includes("-- Since")) {
    let [complaint, duration] = lastComplaint.split(" -- Since");
    this.selectedComplaints[lastIndex] = `${item.name} - ${complaint.trim()} -- Since${duration}`;
  } 
  // If last complaint does NOT have a "Since" modifier, add it
  else {
    this.selectedComplaints[lastIndex] = `${item.name} - ${lastComplaint}`;
  }

  console.log("Updated selectedComplaints:", this.selectedComplaints);

  // Ensure UI updates properly
  this.complaintDetailsForFever = [...this.complaintDetailsForFever];
  this.selectedComplaints = [...this.selectedComplaints];

  // Force change detection in Angular
  this.changeDetectorRef.detectChanges();
}



onSelectSuggestedComplaints(item: any, index: number) {
  console.log("Before Toggle: ", this.suggestComplaints[index]); // Debugging

  // Ensure selected property exists
  if (this.suggestComplaints[index].selected === undefined) {
      this.suggestComplaints[index].selected = false;
  }

  // Toggle selection state
  this.suggestComplaints[index].selected = !this.suggestComplaints[index].selected;

  console.log("After Toggle: ", this.suggestComplaints[index]); // Debugging

  // Update selected complaints array
  if (this.suggestComplaints[index].selected) {
      this.selectedComplaints.push(item.complaint);
  } else {
      this.selectedComplaints = this.selectedComplaints.filter((c: any) => c !== item.complaint);
  }

    if (this.suggestComplaints[index].selected == true) {


      // this.suggestComplaints[index].selected = false;
      // this.selectedComplaints.map((ins:any) => {
      //   if (ins == item.complaint) {

      //     var itemList = this.selectedComplaints.indexOf(ins);

      //     this.selectedComplaints.splice(itemList, 1)


      //   }

      // })

    }
    else {
      this.suggestComplaints[index].selected = true;
      console.log("this.suggestComplaints", this.suggestComplaints);

      this.selectedComplaints.push(item.complaint)
      console.log("this.suggestComplaints", this.suggestComplaints);

    }


  }



  selectSuggestedOnExamination(item:any, index:any) {

    if (this.suggestOnExamination[index].selected == true) {


      // this.suggestOnExamination[index].selected = false;
      // this.selectedOnExamination.map((ins:any) => {
      //   if (ins == item.examination) {
      //     var itemList = this.selectedOnExamination.indexOf(ins);
      //     this.selectedOnExamination.splice(itemList, 1)
      //   }
      // })

    }
    else {
      this.suggestOnExamination[index].selected = true;
      this.selectedOnExamination.push(item.examination)
    }

  }
  selectOnExaminationValue(item:any, index:any) {
    this.suggestOnExamination = [];
    if (this.allOnExamination[index].selected) {


      var suggArry: any = item.suggestedExaminationIds ? item.suggestedExaminationIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el:any) {
        return el != "";
      });
      filteredSuggestedComplaints.map((el:any) => {
        this.allOnExaminationOg.map((ins:any) => {
          if (ins.id == el) {
            this.suggestOnExamination.push(ins);
            this.moreSymptoms = true
          }
        })
      })

      // this.allOnExamination[index].selected = false;
      // this.searchStringOnExamination = "";
      // this.isAddButton = false
      // this.moreSymptoms = false;


      // this.selectedOnExamination.map((ins:any) => {
      //   if (ins == item.examination) {
      //     var itemList = this.selectedOnExamination.indexOf(ins);
      //     this.selectedOnExamination.splice(itemList, 1)
      //   }
      // })

    }
    else {
      this.allOnExamination[index].selected = true;
      this.selectedOnExamination.push(item.examination)

      this.searchStringOnExamination = "";
      this.isAddButton = false
      // this.allOnExamination=this.allOnExaminationOg
      var suggArry: any = item.suggestedExaminationIds ? item.suggestedExaminationIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el:any) {
        return el != "";
      });
      filteredSuggestedComplaints.map((el:any) => {
        this.allOnExaminationOg.map((ins:any) => {
          if (ins.id == el) {
            this.suggestOnExamination.push(ins);
            this.moreSymptoms = true
          }
        })
      })

    }
    this.saveState();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allMedicines, event.previousIndex, event.currentIndex);
  }
  removeSelectedComplaints(item: any) {
    console.log("Removing complaint:", item);

    // Extract main complaint (handles cases like "Mild - Fever -- Since 1 day(s)")
    let complaint = item.split(" - ")[1]?.split(" -- ")[0] || item;

    // Remove from selectedComplaints
    this.selectedComplaints = this.selectedComplaints.filter((c: any) => c !== item);

    // Unselect the complaint in `allComplaints`
    this.allComplaints.forEach((complaintObj: any) => {
      if (complaintObj.complaint === complaint) {
        complaintObj.selected = false;
      }
    });

    // Unselect corresponding complaint details in `complaintDetailsForFever`
    this.complaintDetailsForFever.forEach((detail: any) => {
      if (item.includes(detail.name)) {
        detail.selected = false;
      }
    });

    // **Unselect complaints in `specialCases`**
    this.specialCases = this.specialCases.map((special: any) => {
      if (special.case === complaint) {
        return { ...special, selected: false };
      }
      return special;
    });

    // Ensure Angular updates UI properly
    this.allComplaints = [...this.allComplaints];
    this.complaintDetailsForFever = [...this.complaintDetailsForFever];
    this.specialCases = [...this.specialCases]; // Ensure UI detects the change
    this.changeDetectorRef.detectChanges();

    console.log("Updated selectedComplaints:", this.selectedComplaints);

    console.log("Updated allComplaints:", this.allComplaints);
    console.log("Updated specialCases:", this.specialCases);
  }
  removeSelectedDietaryAdvice(item:any) {
    var itemList = this.selectedDietaryAdvices.indexOf(item);
    this.selectedDietaryAdvices.splice(itemList, 1);
    this.allDietaryAdvices.map((ins:any) => {
      if (ins.dietAdvice == item) {

        ins.selected = false
      }
    })

  }
  removeSelectedDiagnosis(item:any) {
    console.log("item", item)
    var itemList = this.selectedDiagnosis.indexOf(item);
    this.selectedDiagnosis.splice(itemList, 1);
    this.allDignosis.map((ins:any) => {
      if (ins.diagnosis == item) {

        ins.selected = false
      }
    })

  }

  removeSelectedOnExamination(item:any) {
    var itemList = this.selectedOnExamination.indexOf(item);
    this.selectedOnExamination.splice(itemList, 1);
    this.allOnExamination.map((ins:any) => {
      if (ins.examination == item) {

        ins.selected = false
      }
    })

  }
  removeSelectedGeneralInstructions(item:any) {
    var itemList = this.selectedGeneralInstructions.indexOf(item);
    this.selectedGeneralInstructions.splice(itemList, 1);
    this.allGeneralInstructions.map((ins:any) => {
      if (ins.generalInstruction == item) {

        ins.selected = false
      }
    })
  }



  isMedicineSelected(item: any): boolean {
    return this.allMedicines.some((med:any) => med.name === item.name);
  }
  
  selectMedicine(item: any, index: any) {
    console.log("Selected Medicine:", item);
    console.log("All Medicines Data:", this.allMedicines);
  
    // Toggle selection state
    const existingIndex = this.allMedicines.findIndex((med:any) => med.name === item.name);
    
    if (existingIndex !== -1) {
      // Medicine already exists, remove it
      this.allMedicines.splice(existingIndex, 1);
    } else {
      // Medicine not in list, add it
      this.allMedicines.push(item);
    }
  }

  replaceValue(e:any) {
    this.customComplaints = e;



  }
  replaceValueExamination(e:any) {
    this.customOnExamination = e
  }
  replaceValueDiagnosis(e:any) {
    this.customDiagnosis = e;
  }
  replaceValueDietaryAdvice(e:any) {
    this.customDietaryAdvice = e;
  }
  goToVital(action:any) {
    // localStorage.setItem('action', JSON.stringify(action))
    localStorage.setItem('patientDataForVitals', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientData._id, visit: true, action: action, RxFlag: true } });
    // this.refreshservice.appointrefreshed();
  }
  // medInsFunc(data){
  //   this.medLangIns.appendTexttoBox(data)

  // }
  editMedicineDose(index:any, $event:any) {

    //- before update

    this.editMedicineDosage = "";
    this.allMedicines.forEach((ins:any) => {
      ins.editing = false;
    });

    console.log('Target', this.allMedicines[index]);


    // setTimeout(function () {
    // var div = document.getElementById('editableMedicineDiv');
    // comment for new edit
    // div.focus();
    // }, 500);

    this.dropdownSelect('drugType', this.allMedicines[index].drugType)

    this.editMedicineText = this.allMedicines[index].dosage2;
    this.editMedicineTextDuration = this.allMedicines[index].duration2;
    this.editMedicineTextInstructions = this.allMedicines[index].instructions;
    setTimeout(() => {
      this.medLangIns.appendTexttoBox(this.editMedicineTextInstructions)
    }, 200)


    // this.selectedDrugType = this.allMedicines[index].drugType;
    // this.selectedDuration = this.allMedicines[index].duration2;
    // this.selectedWhenToTake = this.allMedicines[index].whenToTake;


    // this.allMedicines[index].drugType = "";

    this.drugTypeField.value = this.allMedicines[index].drugType;
    this.selectedDrugType = this.allMedicines[index].drugType;

    const finalDosage = this.convertDoseUnitToString("" + this.allMedicines[index]?.dosageValue)

    this.UnitField.value = finalDosage;
    this.selectedUnit = finalDosage;

    this.FrequencyField.value = "";

    this.WhentotakeField.value = this.allMedicines[index].whenToTake;
    this.selectedWhenToTake = this.allMedicines[index].whenToTake;

    this.DurationField.value = this.allMedicines[index].duration2;
    this.selectedDuration = this.allMedicines[index].duration2;

    this.editMedicineDosage = finalDosage;
    this.drugTypeUnit = this.allMedicines[index].unitType ? this.allMedicines[index].unitType : this.drugTypeUnit;

    if (this.allMedicines[index].frequency && this.allMedicines[index].frequency.length > 0) {
      this.FrequencyFieldEdit.setValue(Array.isArray(this.allMedicines[index].frequency) ? this.allMedicines[index].frequency : this.allMedicines[index].frequency.split(", "));
      this.selectedFrequency = this.allMedicines[index].frequency
    }

    if (this.allMedicines[index].editing == true) {
      this.allMedicines[index].editing = false;

    } else {
      this.allMedicines[index].editing = true;
    }

  }

  updateMedicineDose(index:any, $event:any) {

    // - after update
    let unitType = '';

    if (this.drugTypeUnit == 'ml' || this.drugTypeUnit == 'Puff' || this.drugTypeUnit == 'Sachet'
    ) {
      unitType = this.drugTypeUnit
    }

    let unitDose = this.selectedUnit == 'Fingertip' ? this.selectedUnit : this.convertDoseUnitToUnicode(this.editMedicineDosage)

    // if (this.editMedicineDosage && this.editMedicineDosage != "" && this.selectedUnit && this.selectedUnit != "" && this.selectedDrugType && this.selectedDrugType != "" && this.selectedFrequency && this.selectedFrequency.length > 0 && this.selectedDuration && this.selectedDuration != "" && this.selectedWhenToTake && this.selectedWhenToTake != "") {
    if (this.selectedUnit && this.selectedUnit != "" && this.selectedDrugType && this.selectedDrugType != "" && this.selectedFrequency && this.selectedFrequency.length > 0 && this.selectedDuration && this.selectedDuration != "") {
      this.allMedicines[index].dosage = `${unitDose} ${unitType} - ${this.selectedDrugType} - ${Array.isArray(this.selectedFrequency) ? this.selectedFrequency.join(', ') : this.selectedFrequency} - ${this.selectedDuration}`;
      this.allMedicines[index].dosage2 = `${unitDose} ${unitType} - ${this.selectedDrugType} - ${Array.isArray(this.selectedFrequency) ? this.selectedFrequency.join(', ') : this.selectedFrequency}`;
      this.allMedicines[index].duration2 = this.selectedDuration;
      //this.allMedicines[index].instructions = this.editMedicineTextInstructions;
      var medIns = this.medLangIns.getValue();
      this.allMedicines[index].instructions = medIns;
      this.allMedicines[index].whenToTake = this.selectedWhenToTake;

      this.allMedicines[index].dosageValue = unitDose;
      this.allMedicines[index].unitType = unitType;
      this.allMedicines[index].frequency = this.selectedFrequency;


      this.allMedicines[index].drugType = this.selectedDrugType;

      if (this.allMedicines[index].editing == true) {
        this.allMedicines[index].editing = false;

      } else {
        this.allMedicines[index].editing = true;
      }

    } else {

      this.drugTypeField.markAsTouched()
      this.UnitField.markAsTouched()
      this.FrequencyField.markAsTouched();
      // this.WhentotakeField.markAsTouched();
      this.DurationField.markAsTouched();


      this.dialog.open(this.incomplete_data_modal)
    }


  }

  closeEdit = (index:any) => {
    this.allMedicines[index].editing = false;
  }

  deleteMedicine(index:any, $event:any) {
    this.allMedicines.splice(index, 1);
  }

  addNewMedicine($event:any) {

    if (!this.isAddMedicine) {

      setTimeout(() => {
        this.UnitField.setErrors(null);
        this.FrequencyField.setErrors(null)
        this.WhentotakeField.setErrors(null)
        this.drugTypeField.setErrors(null)
        this.DurationField.setErrors(null)
      }, 500);

      this.clearAddMedicine()
    }

    this.isAddMedicine = true;

  }

  getComplaintsOfDoctor() {
    console.log("highlight complaint, getComplaintsOfDoctor");
    
    var postData = {}
    var splComplaints = []
    this.patientService.getComplaintsOfDoctor(postData).subscribe((response:any) => {
      if (response) {
        console.log("getCompl", response)
        // splComplaints= response.complaints
        this.allComplaints = response.complaints


        console.log("allCom", this.allComplaints)
        

        this.allComplaints.filter((complaint:any) =>{
          if(complaint.specialVisitType == 1){
            
            console.log("this.allComplaints.specialVisitType",complaint);

          }
          
          
        }
        
        );

        console.log("KKKKKK1 ageAug7", this.ageObj)
if(this.doctorSpeciality == 'psychiatrist'){
  let specialComplaint = this.allComplaints.filter((complaint: any) => complaint.createdAt !== null);
  console.log("specialComplaint",specialComplaint, this.doctorSpeciality);
  this.allComplaints = specialComplaint;
}
        


        this.allComplaints.map((ins:any) => {
        console.log("KKKKKK1 befre if", this.allComplaints,this.doctorSpeciality)

          if (this.ageObj.years > 6 ) {
        console.log("KKKKKK1 inside if", this.allComplaints)

            var itemList = this.allComplaints.indexOf(ins);
            // this.allComplaints.splice(itemList, 1)
          }
        })


        console.log("KKKKKK1 after if",this.doctorSpeciality ,this.allComplaints)
        const size: any = 8

        const items = this.allComplaints.slice(0, size)
        console.log("size", items)
        //  if(this.splVacc==true){
        //   this.allComplaints=this.allComplaints.splice(0,3)
        //  }

        this.allComplaintsOrg = this.allComplaints;
        this.selectedComplaints = this.selectedComplaints ? this.selectedComplaints : [];
        console.log("before specialAllComplaints allComplaints",this.allComplaints);
        
        if (this.doctorSpeciality == 'psychiatrist') {
          let specialAllComplaints = this.allComplaints.filter((complaints: any) => complaints.createdAt !== null);
          console.log("specialAllComplaints", specialAllComplaints, this.doctorSpeciality);
          this.allComplaints = specialAllComplaints;
        }
        console.log("specialAllComplaints allDignosisallDignosis",this.allComplaints);
        this.selectedComplaints.map((ins:any) => {
          this.allComplaints.map((item:any) => {
            if (ins == item.complaint) {
              item.selected = true
            }
          })
          
        })
      }
    })

  }

  getGeneralInstructionsOfDoctor() {
    var dignosisIds: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    var postData = {
      diagnosisCollection: dignosisIds ? dignosisIds : [],
      lang: this.patientSelectedLanguage ? this.patientSelectedLanguage : ''
    }
    this.patientService.getGeneralInstructionsOfDoctor(postData).subscribe((response:any) => {
      if (response) {
        if (!response.response.resultArr || response.response.resultArr.length == 0) {

          var data: any = []
          response.response.remainingArr.map((eachGInstFromDB:any) => {
            for (let i = 0; i < dignosisIds.length; i++) {
              let searchableGIstId = "," + dignosisIds[i] + ",";
              if (eachGInstFromDB.diagnosisCollection && eachGInstFromDB.diagnosisCollection.includes(searchableGIstId)) {
                data.push(eachGInstFromDB);
              }
            }
          })

          this.allGeneralInstructions = data;
          console.log("dietData", data)
          this.allGeneralInstructionsOg = response.response.remainingArr
        }
        else {
          this.allGeneralInstructions = response.response.resultArr
          this.allGeneralInstructionsOg = response.response.remainingArr.concat(this.allGeneralInstructions)
        }

        this.selectedGeneralInstructions.map((ins:any) => {
          this.allGeneralInstructions.map(item => {
            if (ins == item.generalInstruction) {
              item.selected = true;
            }
          })
        })

        this.searchGeneralInstructions('', 'generalInstructions');

      }
    })
  }

  getDiagnosisOfDoctorAsPerComplaints() {
    let testArr:any[] = [];
    this.allComplaintsOrg.map((ins:any) => {
      if (ins.selected) {

        testArr.push(ins.id);
      }
    });
    console.log("testArray", testArr)

    var postData = {
      complaint_collection: testArr ? testArr : []
    }
    console.log("postdata", postData)
    this.patientService.getDiagnosisOfDoctorAsPerComplaints(postData).subscribe((response:any) => {
      if (response) {
        console.log("getDiagnosisFeb21", response)
        if (!response.response.resultArr.length) {
          this.allDignosis = response.response.remainingArr
          this.allDignosisOg = response.response.remainingArr;

          if (this.doctorSpeciality == 'psychiatrist') {
            let specialAllDignosis = this.allDignosis.filter((dignosis: any) => dignosis.createdAt !== null);
            console.log("specialAllDignosis", specialAllDignosis, this.doctorSpeciality);
            this.allDignosis = specialAllDignosis;
          }
          console.log("specialAllDignosis allDignosisallDignosis",this.allDignosis);

        }
        else {
          this.allDignosis = response.response.resultArr
          this.allDignosisOg = response.response.remainingArr.concat(this.allDignosis)
          if (this.doctorSpeciality == 'psychiatrist') {
            let specialAllDignosis = this.allDignosis.filter((dignosis: any) => dignosis.createdAt !== null);
            console.log("specialAllDignosis", specialAllDignosis, this.doctorSpeciality);
            this.allDignosis = specialAllDignosis;
          }
          console.log("specialAllDignosis allDignosisallDignosis",this.allDignosis);

        }
// by commenting local storage line we are getting allDignosis data initially 
        // this.allDignosis = JSON.parse(localStorage.getItem("allDignosis") || '[]');

        console.log("allDignosis", this.allDignosisOg);
        console.log("resultArrDignosis", this.allDignosis);



        //this.allComplaintsOrg=this.allComplaints;
        this.selectedDiagnosis.map((ins:any) => {
          this.allDignosis.map(item => {
            if (ins == item.diagnosis) {
              item.selected = true;
            }
          })
        })
      }
    })
  }

  getDietAdviceOfDoctorAsPerDiagnosis() {
    var dignosisIds: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    console.log("dignosisIds", dignosisIds)


    var postData = {
      diagnosisCollection: dignosisIds ? dignosisIds : [],
      lang: this.patientSelectedLanguage ? this.patientSelectedLanguage : ''

    }

    this.patientService.getDietAdviceOfDoctorAsPerDiagnosis(postData).subscribe((response:any) => {
      if (response) {
        console.log("resp", response)
        if (!response.response.resultArr || response.response.resultArr.length == 0) {

          var returndiets: any = []
          response.response.remainingArr.map((eachDietFromDB:any) => {
            for (let i = 0; i < dignosisIds.length; i++) {
              let searchablediagnosisId = "," + dignosisIds[i] + ",";
              if (eachDietFromDB.diagnosisCollection && eachDietFromDB.diagnosisCollection.includes(searchablediagnosisId)) {
                returndiets.push(eachDietFromDB);
              }
            }
          })
          //set search array filtered
          this.allDietaryAdvices = returndiets;
          console.log("dietData", this.allDietaryAdvices)

          //keep original array 
          this.allDietaryAdvicesOg = response.response.remainingArr;

        }
        else {
          this.allDietaryAdvices = response.response.resultArr
          this.allDietaryAdvicesOg = response.response.remainingArr.concat(this.allDietaryAdvices)
        }

        console.log("allDietary", this.allDietaryAdvices)
        console.log("allORG", this.allDietaryAdvicesOg)

        this.selectedDietaryAdvices.map((ins:any) => {
          this.allDietaryAdvices.map((item:any) => {
            if (ins == item.dietAdvice) {
              item.selected = true

            }
          })
        })

        //calling search to show everything
        this.searchDietaryAdvice("", 'dietaryAdvice');

      }
    }, (error:any) => {
      console.log(error)
    });

  }


  getExaminationOfDoctorAsPerDiagnosis() {
    let testArr:any[] = [];
    this.allComplaintsOrg.map((ins:any) => {
      if (ins.selected) {

        testArr.push(ins.id);
      }
    });
    var postData = {
      complaint_collection: testArr ? testArr : []

    }
    this.patientService.getExaminationOfDoctorAsPerDiagnosis(postData).subscribe((response:any) => {
      if (response) {
        console.log("OnExamination", response)
        var remainingData = response.response.remainingArr
        console.log("remainingData", remainingData)

        if (!response.response.resultArr.length) {
        
          
          this.allOnExamination = response.response.remainingArr;
    
          this.allOnExaminationOg = response.response.remainingArr;
         
          if (this.doctorSpeciality == 'psychiatrist') {
            let specialOnExamination = this.allOnExamination.filter((examination: any) => examination.createdAt !== null);
            console.log("specialOnExamination", specialOnExamination, this.doctorSpeciality);
            this.allOnExamination = specialOnExamination;
          }
          console.log("allOnExaminationallOnExamination",this.allOnExamination);

        }
        else {
          this.allOnExamination = response.response.resultArr
          this.allOnExaminationOg = response.response.remainingArr.concat(this.allOnExamination)
          if (this.doctorSpeciality == 'psychiatrist') {
            let specialOnExamination = this.allOnExamination.filter((examination: any) => examination.createdAt !== null);
            console.log("specialOnExamination", specialOnExamination, this.doctorSpeciality);
            this.allOnExamination = specialOnExamination;
          }
          console.log("allOnExaminationallOnExamination",this.allOnExamination);
        }




        console.log("alldata", this.allOnExamination)




        this.selectedOnExamination.map((ins:any) => {
          this.allOnExamination.map((item:any) => {
            if (ins == item.examination) {
              item.selected = true
            }
          })
        })
      }
    })

  }

  deleteMedicineFromPreview(drugId:any) {
    this.allMedicienOrg.forEach((element:any, idx:any) => {
      if (element.Drug.id == drugId) {
        this.allMedicienOrg[idx].selected = false;
      }
    });
  }

  getMedicinesAsPerDiagnosis() {
    this.allMedicinesForDiagnosis = []

    this.drugTypeList = []
    var dignosisId: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    })

    var postData = {
      diagnosisCollection: dignosisId
    }
    console.log("postdatadign", postData)
    this.patientService.getMedicinesOfDoctorAsPerDiagnosis(postData).subscribe((response:any) => {
      if (response) {
        let result: any = response.finalResult;


        this.drugArray = result
        console.log("medResult", result)


        var diagnosis = this.allDignosisOg
        // var obj = {
        //   name: 'All Medicines',
        //   value: ',No,',
        //   selected: false
        // }

        // this.diagnosisCollectionArray.push(obj)

        // diagnosis.map((ins:any) => {
        //   result.map(item => {
        //     if ("," + ins.id + "," == item.diagnosisCollection) {
        //       this.diagnosisCollectionArray.push({ name: ins.diagnosis, value: item.diagnosisCollection, selected: false })
        //     }

        //   })

        // })

        this.diagnosisCollectionArray = this.diagnosisCollectionArray.reduce((unique, o) => {
          if (!unique.some((obj:any) => obj.name == o.name)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.diagnosisCollectionArrayOrg = this.diagnosisCollectionArray

        //console.log("digFeb22", this.diagnosisCollectionArray)

        result.map((item:any, index:any) => {
          //ins.drugs.map(item => {

          let Drug = {
            id: item.id,
            product_name: item.product_name,
            generic_name: item.generic_name
          }

          // dosage = dosage.length ? dosage + ', ' + `${item.unit} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}, ${item.whenToTake ? item.whenToTake : ""}` : `${item.unit} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}, ${item.whenToTake ? item.whenToTake : ""}`;
          // dosage2 = dosage2.length ? dosage2 + ', ' + `${item.unit} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}` : `${item.unit} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}`;



          let whenToTakeFormatted = item.advisedWhenToTake ? item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1) : "";

          let formattedFrequencyArr:any[] = [];
          let formattedFrequencyStr = ""

          if (item.advisedFrequency) {
            item.advisedFrequency.split(', ').map((ins:any) => {
              console.log(ins.charAt(0).toUpperCase() + ins.slice(1));
              formattedFrequencyArr.push(ins.charAt(0).toUpperCase() + ins.slice(1))
            });
          }

          formattedFrequencyStr = formattedFrequencyArr.join(', ');

          let obj:any = {};
          obj['name'] = item.product_name;
          obj['genric_name'] = item.generic_name;
          //obj['dosage'] = item.dosage + ' ' + item.duration;
          //obj['dosage2'] = item.dosage;
          // obj['id'] = item.drugId
          obj['duration2'] = item.advisedDurationUnit ? item.advisedDuration + " " + item.advisedDurationUnit : item.advisedDuration;
          obj['instructions'] = item.advisedInstructions;
          obj['drugType'] = item.product_type;
          obj['whenToTake'] = whenToTakeFormatted
          obj['frequency'] = formattedFrequencyStr;
          obj['diagnosisCollection'] = item.diagnosisCollection
          // obj['unit'] = item.advisedDosage;


          obj['dosage'] = item.advisedDosage && item.advisedDosage != "" && item.advisedDosage.toLowerCase() != "null" ? `${item.advisedDosage} ${item.unit ? item.unit.toLowerCase() : ''} - ${item.product_type} - ${formattedFrequencyStr} - ${item.advisedDuration}, ${whenToTakeFormatted}` : `${item.product_type} - ${formattedFrequencyStr} - ${item.advisedDuration}, ${whenToTakeFormatted}`;
          // obj['dosage2'] = item.advisedDosage && item.advisedDosage != "" && item.advisedDosage.toLowerCase() != "null" ? `${item.advisedDosage} ${item.unit ? item.unit.toLowerCase() : ''} - ${item.product_type} - ${formattedFrequencyStr} - ${whenToTakeFormatted}` : `${item.product_type} - ${formattedFrequencyStr} - ${whenToTakeFormatted}`
          obj['dosage2'] = item.advisedDosage;
          obj['dosageValue'] = item.advisedDosage;


          obj['Drug'] = Drug;
          obj['MedGroupDrug_id'] = item.MGD_Id
          obj['updatedAt'] = item.updatedAt
          obj['selected'] = false;
          //console.log("allMedPop", obj)

          // dummy
          // this.allMedicines.push({ name: item.drugName, dosage: item.dosage })
          // this.allMedicines.push(obj)

          this.allMedicinesForDiagnosis.push(obj);


          // this.drugArray.push({ id: item.drugId, product_name: item.drugName })

        })


        this.allMedicienOrg = this.allMedicinesForDiagnosis
        this.allMedicinesForDiagnosisOrg = this.allMedicinesForDiagnosis

        //console.log("dddd", this.allMedicienOrg)

        this.allMedicinesForDiagnosis.map((ins:any, idx:any) => {
          ins.idx = idx;
          var tabletsArray: any = this.allMedicinesForDiagnosis
          var data: any = []
          tabletsArray.map((ins:any, index:any) => {
            if (ins.drugType && ins.drugType.includes('tablets')) {
              ins.drugType = ins.drugType.replace('tablets', 'tablet')
              data.push({ name: ins });
            }
            if (tabletsArray[index].drugType) {
              tabletsArray[index].drugType = tabletsArray[index].drugType.toLowerCase()
            }

          })

          this.drugTypeList.push({ name: "All", selected: false })
          if (ins.drugType) {
            this.drugTypeList.push({ name: ins.drugType, selected: false })
          }


        });

        this.drugTypeList = this.drugTypeList.reduce((unique:any, o:any) => {
          if (!unique.some((obj:any) => obj.name == o.name)) {
            unique.push(o);
          }
          return unique;
        }, []);


      }
      //   this.allMedicinesForDiagnosis = this.allMedicinesForDiagnosis.reduce((unique, o) => {
      //     if (!unique.some(obj => obj.name === o.name && obj.dosageValue === o.dosageValue)) {
      //       unique.push(o);
      //     }
      //     return unique;
      //   }, []);
    })
    console.log("MMMMM", this.allMedicinesForDiagnosis)
    this.saveState();
  }


  toggle(ref: any): void {
    console.log("ref object:", ref);

    let med: any = [];
    const refValue = ref._value;

    // Manually toggle selection if 'toggleSelected()' is unavailable
    ref._selected = !ref._selected;

    // Always reset the list to the full dataset before filtering
    this.allMedicinesForDiagnosis = [...this.allMedicienOrg];

    // If "All" or "All Medicines" is selected, reset the list to show all items
    if (ref._value.name === 'All Medicines' || ref._value.name === 'All') {
        console.log("Resetting to all medicines");
        return; // No need to filter, just reset the list
    }
    this.drugTypeList.forEach((ins:any)=>{
      console.log("ins",ins);
      console.log("refffff",ref._value.name)
      if(ins.name==ref._value.name){
        console.log("truedsdjsdj")
        ins.selected=true
      }
      // if (drugType === refName || diagnosisCollection === refValueCollection) {
      //   med.push(ins);
    
    })

    // Apply filter based on drugType or diagnosisCollection
    this.allMedicienOrg.forEach((ins:any) => {
        const drugType = ins.drugType ? ins.drugType.trim().toLowerCase() : '';
        const diagnosisCollection = ins.diagnosisCollection ? ins.diagnosisCollection.trim().toLowerCase() : '';
        const refName = refValue.name ? refValue.name.trim().toLowerCase() : '';
        const refValueCollection = refValue.value ? refValue.value.trim().toLowerCase() : '';

        // Ensure case-sensitivity or trim any extra spaces to avoid mismatches
        if (drugType === refName || diagnosisCollection === refValueCollection) {
            med.push(ins);
        }
    });

    console.log("Filtered list:", med);

    // Assign the filtered list back to 'allMedicinesForDiagnosis'
    this.allMedicinesForDiagnosis = med;

    console.log("Final Selection State:", ref._selected);
}




  parseFraction(str:any) {
    if (str.includes("½")) {
      // Convert specific fraction like "2½" to a float
      const parts = str.split("½");
      const wholeNumber = parts[0] ? parseInt(parts[0]) : 0;
      const fractionValue = 0.5; // "½" is equivalent to 0.5
      return wholeNumber + fractionValue;

    }
    else if (str.includes("¼")) {
      // Convert specific fraction like "2½" to a float
      const parts = str.split("¼");
      const wholeNumber = parts[0] ? parseInt(parts[0]) : 0;
      const fractionValue = 0.25; // "½" is equivalent to 0.5
      return wholeNumber + fractionValue;
    }
    else if (str.includes("¾")) {
      // Convert specific fraction like "2½" to a float
      const parts = str.split("¾");
      const wholeNumber = parts[0] ? parseInt(parts[0]) : 0;
      const fractionValue = 0.75; // "½" is equivalent to 0.5
      return wholeNumber + fractionValue;
    }

    else if (str.includes("/")) {
      // Convert a generic fraction like "1/2" to a float
      const fractionParts = str.split("/");
      return parseInt(fractionParts[0]) / parseInt(fractionParts[1]);
    } else {
      return parseFloat(str);
    }
  }


  onSelectMedicine = (index:any, type:any) => {
    this.medDuplicateindex = ""

    //type 1 - All Medicines; type 2 - All Medicines for Diagnosis (Popular); 3 - Favourite medicines; 4 - Previous medicines

    // this.allMedicines[index].selected = !this.allMedicines[index].selected;

    if (type == 1) {
      if (this.allMedicines[index].selected) {
        this.allMedicines[index].selected = false
      } else {
        this.allMedicines[index].selected = true;

        if (
          this.allMedicines[index].drugType == 'Tablet' || this.allMedicines[index].drugType == 'Capsule'
          || this.allMedicines[index].Drug.product_type === "capsules" ||
          this.allMedicines[index].Drug.product_type === "Capsule" ||
          this.allMedicines[index].Drug.product_type === "Tablet"
        ) {
          const doses = this.allMedicines[index].dosage.split(", ");
          const durationDays = parseInt(this.allMedicines[index].duration2.split(" ")[0]);
          let dailyDosageSum = 0;

          doses.forEach((dose:any) => {
            // Extract the dosage value from the string (e.g., "1 - Tablet - Morning")
            const dosageValue = this.parseFraction(dose.split(" ")[0]);

            // Sum up the dosage value
            if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue;
            }
          });

          const totalTablets = dailyDosageSum * durationDays;
          this.allMedicines[index]["quantity"] = totalTablets;
          console.log("quantity", this.allMedicines[index]["quantity"]);
        }
      }
    } else if (type == 2) {
      //  this.allMedicines.map(ins=>{
      //     this.allMedicienOrg.map(ele=>{
      //       if(ins.Drug.id==ele.Drug.id){
      //        // ele.selected=true
      //       }
      //       else{
      //         // ele.selected=false
      //       }

      //     })
      //    })


      if (this.allMedicienOrg[index].selected == true) {
        this.allMedicienOrg[index].selected = false


      } else {

        //  
        if (
          this.allMedicienOrg[index].drugType == 'Tablet' || this.allMedicienOrg[index].drugType == 'Capsule'
          || this.allMedicienOrg[index].Drug.product_type === "capsules" ||
          this.allMedicienOrg[index].Drug.product_type === "Capsule" ||
          this.allMedicienOrg[index].Drug.product_type === "Tablet"
        ) {
          const doses = this.allMedicienOrg[index].dosage.split(", ");
          const durationDays = parseInt(this.allMedicienOrg[index].duration2.split(" ")[0]);
          let dailyDosageSum = 0;

          doses.forEach((dose:any) => {
            // Extract the dosage value from the string (e.g., "1 - Tablet - Morning")
            const dosageValue = this.parseFraction(dose.split(" ")[0]);

            // Sum up the dosage value
            if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue;
            }
          });

          const totalTablets = dailyDosageSum * durationDays;
          this.allMedicienOrg[index]["quantity"] = totalTablets;
          console.log("quantity", this.allMedicienOrg[index]["quantity"]);
        }
        this.allMedicienOrg[index].selected = true;


      }
    } else if (type == 3) {
      if (this.selectedFavMed[index].selected == true) {
        this.selectedFavMed[index].selected = false
      } else {

        this.selectedFavMed[index].selected = true;

       
        
      }
    } else if (type == 4) {
      //aaleee
      if (this.selectedPreviousMed[index].selected == true) {
        this.selectedPreviousMed[index].selected = false
      } else {
        this.selectedPreviousMed[index].selected = true;
      }
    }

    this.saveState()
  }
  addDuplicateMed() {
    // this.medDuplicate=true
    // this.closeDialog()
    this.allMedicienOrg.map((ins:any) => {

      if (ins.idx == this.medDuplicateindex) {
        ins.selected = true
      }



    })
    this.saveModalValues("copyPopularMedicines")

    this.closeDialog();
  }

  selectModalValue(index:any, type:any) {

    if (type == 'drugTypes') {

      if (this.drugTypes[index].selected) {
        this.drugTypes[index].selected = false;
        this.drugTypesOg[index].selected = false;
      } else {
        this.drugTypes[index].selected = true;
        this.drugTypes.map((ins:any, ind:any) => {
          if (index != ind) {
            this.drugTypes[ind].selected = false;
          }
        })
      }

    } else {

      if (this.allMedicineInstructions[index].selected) {
        this.allMedicineInstructions[index].selected = false;
        this.allMedicineInstructionsOg[index].selected = false;

        this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name + ',', '');
        this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name, '');
      } else {
        this.allMedicineInstructions[index].selected = true;
      }

      this.allMedicineInstructions = this.allMedicineInstructionsOg
      this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique:any, o:any) => {
        if (!unique.some((obj:any) => obj.name.toLowerCase() == o.name.toLowerCase())) {
          unique.push(o);
        }
        return unique;
      }, []);

    }

  }


  onSearchChange(searchValue: string, type: string): void {

    let data = [];
    let newData = [];

    if (type == 'drugTypes') {
      data = this.drugTypesOg;
    } else {
      data = this.allMedicineInstructionsOg;
    }

    if (searchValue.length) {
      newData = data.filter((item:any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase())
      });
    } else {
      newData = data;
    }

    if (type == 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allMedicineInstructions = newData;
      this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique:any, o:any) => {
        if (!unique.some((obj:any) => obj.name.toLowerCase() == o.name.toLowerCase())) {
          unique.push(o);
        }
        return unique;
      }, []);
    }

  }


  searchComplaints(searchValue: string, type: string) {
    let data = [];
    let newData = [];

    // Assign the data source based on the type
    if (type === 'complaints') {
        data = this.allComplaintsOrg || []; // Ensure it's not null
    } else {
        data = this.allComplaints || [];
    }

    if (searchValue.length) {
        this.searchString = searchValue.trim().toLowerCase();

        // Filter the data safely with null checks
        newData = data.filter((item: any) => 
            item?.complaint?.toLowerCase().includes(this.searchString)
        );

        // Check if any complaint matches exactly
        const isExactMatch = data.some(
            (item: any) => item?.complaint?.toLowerCase() === this.searchString
        );

        this.isAddButton = !isExactMatch;
        this.searchArray = newData;
    } else {
        this.searchArray = [...data]; // Reset the data when search is empty
        this.isAddButton = false;
    }

    this.allComplaints = [...this.searchArray]; // Ensure UI updates
    console.log("Filtered Complaints:", this.allComplaints);
}

  
  
  searchComplaintAdd() {

    if (this.searchArray) {
      if (this.searchString == "") {

      }
      else {
        this.selectedComplaints.push(this.searchString)
        var postData = {
          complaint: this.searchString
        }
        console.log("SearchArray", postData)
        this.patientService.createNewComplaintOfDoctor(postData).subscribe((response:any) => {
          if (response) {
            console.log("customComplaints", response);
          }

        })

        this.searchString = "";
        this.allComplaints = this.allComplaintsOrg
        console.log("allComFeb25", this.allComplaints)
        this.isAddButton = false
      }

    }
  }
  searchDataDignosisAdd() {

    if (this.searchArray) {
      if (this.searchStringDiagnosis == "") {

      }
      else {
        this.selectedDiagnosis.push(this.searchStringDiagnosis)
        var postData = {
          diagnosis: this.searchStringDiagnosis
        }
        console.log("SearchArray", postData)
        this.patientService.createNewDiagnosisOfDoctor(postData).subscribe((response:any) => {
          if (response) {
            console.log("diagnosis", response);
          }

        })

        this.searchStringDiagnosis = "";
        this.allDignosis = this.allDignosisOg
        this.isAddButton = false
      }

    }
  }


  searchDiagnosis(searchValue: string, type: string) {
    let data = [];
    let newData = [];
    
    // Assign the data source based on the type
    if (type === 'diagnosis') {
      data = this.allDignosisOg;
    } else {
      data = this.allDignosis;
    }
  
    if (searchValue.length) {
      this.searchStringDiagnosis = searchValue;
  
      // Filter the data based on the search value
      newData = data.filter((item:any)=>
        item.diagnosis.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      // Check if any complaint matches the searchValue exactly
      const isExactMatch = data.some(
        (item:any) => item.diagnosis.toLowerCase() === searchValue.toLowerCase()
      );
  
      // Set isAddButton based on whether there is an exact match
      this.isAddButton = !isExactMatch;
  
      this.searchArray = newData;
    } else {
      // Reset to original data when searchValue is empty
      newData = data;
      this.isAddButton = false;
    }
  
    // Update the appropriate array based on the type
    if (type === 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allDignosis = newData;
    }
  }
  searchDietaryAdvice(searchValue: string, type: string) {
    let data = [];
    let newData = [];
    
    // Assign the data source based on the type
    if (type === 'dietaryAdvice') {
      data = this.allDietaryAdvicesOg;
    } else {
      data = this.allDietaryAdvices;
    }
  
    if (searchValue.length) {
      this.searchStringDietaryAdvice = searchValue;
  
      // Filter the data based on the search value
      newData = data.filter((item:any) =>
        item.dietAdvice.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      // Check if any complaint matches the searchValue exactly
      const isExactMatch = data.some(
        (item:any) => item.dietAdvice.toLowerCase() === searchValue.toLowerCase()
      );
  
      // Set isAddButton based on whether there is an exact match
      this.isAddButton = !isExactMatch;
  
      this.searchArray = newData;
    } else {
      // Reset to original data when searchValue is empty
      newData = data;
      this.isAddButton = false;
    }
  
    // Update the appropriate array based on the type
    if (type === 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allDietaryAdvices = newData;
    }
  }
  addDietaryAdvice() {
    this.searchStringDietaryAdvice = this.lngDietaryInst.getValue();
    var dignosisIds: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    if (this.searchArray) {
      if (this.searchStringDietaryAdvice == "") {

      }
      else {
        if (dignosisIds != 0) {
          dignosisIds = "," + dignosisIds.join(',') + ","
        }
        else {
          dignosisIds = ""
        }
        this.selectedDietaryAdvices.push(this.searchStringDietaryAdvice)
        var postData = {
          dietAdvice: this.searchStringDietaryAdvice,
          diagnosisCollection: dignosisIds
        }
        console.log("SearchArray", postData)
        this.patientService.createNewDietAdviceOfDoctor(postData).subscribe((response:any) => {
          if (response) {
            console.log("custom1Complaints", response);
          }

        })

        this.searchStringDietaryAdvice = "";
        this.lngDietaryInst.clearTextBox()
        //this.allDietaryAdvices = this.allDietaryAdvicesOg
        this.isAddButton = false
      }

    }

  }
 private originalMedicines = this.allMedicinesForDiagnosis;
 filterMedicines() {
  const originalMedicines = this.allMedicinesForDiagnosis;
  console.log('Search Term:', this.searchMed);

  // Check if search term is empty (after backspace or clearing input)
  if (!this.searchMed) {
    console.log('Search box cleared. Resetting list...');
    // Restore the original list when search term is empty
    this.allMedicinesForDiagnosis = this.allMedicinesForDiagnosisOrg;
    return;
  }

  // Convert search term to lowercase
  const search = this.searchMed.toLowerCase();

  // Filter the list based on the search term
  this.allMedicinesForDiagnosis = originalMedicines.filter((medicine: any) =>
    (typeof medicine === 'string' ? medicine : medicine.name).toLowerCase().includes(search)
  );

  console.log('Filtered List:', this.allMedicinesForDiagnosis);
}


 
  searchOnExamination(searchValue: string, type: string) {
    let data = [];
    let newData = [];
    
    // Assign the data source based on the type
    if (type === 'OnExamination') {
      data = this.allOnExaminationOg;
    } else {
      data = this.allOnExamination;
    }
  
    if (searchValue.length) {
      this.searchStringOnExamination = searchValue;
  
      // Filter the data based on the search value
      newData = data.filter((item:any)=>
        item.examination.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      // Check if any complaint matches the searchValue exactly
      const isExactMatch = data.some(
        (item:any) => item.examination.toLowerCase() === searchValue.toLowerCase()
      );
  
      // Set isAddButton based on whether there is an exact match
      this.isAddButton = !isExactMatch;
  
      this.searchArray = newData;
    } else {
      // Reset to original data when searchValue is empty
      newData = data;
      this.isAddButton = false;
    }
  
    // Update the appropriate array based on the type
    if (type === 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allOnExamination = newData;
    }
  }

  searchGeneralInstructions(searchValue: string, type: string) {
    let data = [];
    let newData = [];
    
    // Assign the data source based on the type
    if (type === 'generalInstructions') {
      data = this.allGeneralInstructionsOg;
    } else {
      data = this.allGeneralInstructions;
    }
  
    if (searchValue.length) {
      this.searchStringGeneralInstructions = searchValue;
  
      // Filter the data based on the search value
      newData = data.filter((item:any) =>
        item.generalInstruction.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      // Check if any complaint matches the searchValue exactly
      const isExactMatch = data.some(
        (item:any) => item.generalInstruction.toLowerCase() === searchValue.toLowerCase()
      );
  
      // Set isAddButton based on whether there is an exact match
      this.isAddButton = !isExactMatch;
  
      this.searchArray = newData;
    } else {
      // Reset to original data when searchValue is empty
      newData = data;
      this.isAddButton = false;
    }
  
    // Update the appropriate array based on the type
    if (type === 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allGeneralInstructions = newData;
    }
  }
  searchDataOnExamination() {
    if (this.searchArray) {
      if (this.searchStringOnExamination == "") {

      }
      else {
        this.selectedOnExamination.push(this.searchStringOnExamination)
        var postData = {
          examination: this.searchStringOnExamination
        }
        console.log("SearchArray", postData)
        this.patientService.createNewExaminationOfDoctor(postData).subscribe((response:any) => {
          if (response) {
            console.log("examination", response);
          }

        })

        this.searchStringOnExamination = "";
        this.allOnExamination = this.allOnExaminationOg
        console.log("allExam", this.allOnExamination)
        this.isAddButton = false
      }

    }


  }
  addGeneralInstruction() {
    this.searchStringGeneralInstructions = this.lngGenInst.getValue();
    var dignosisId: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    })
    if (this.searchArray) {
      if (this.searchStringGeneralInstructions == "") {

      }
      else {
        if (dignosisId != 0) {
          dignosisId = "," + dignosisId.join(',') + ","
        }
        else {
          dignosisId = ""
        }
        this.selectedGeneralInstructions.push(this.searchStringGeneralInstructions)
        var postData = {
          generalInstruction: this.searchStringGeneralInstructions,
          diagnosisCollection: dignosisId
        }
        console.log("SearchArray", postData)
        this.patientService.createGeneralInstructionsOfDoctor(postData).subscribe((response:any) => {
          if (response) {

          }

        })

        this.searchStringGeneralInstructions = "";
        this.lngGenInst.clearTextBox()
        //this.allGeneralInstructions = this.allGeneralInstructionsOg
        this.isAddButton = false
      }
    }

  }
  clearAddMedicine() {

    this.selectedUnit = '';
    this.selectedDrugType = '';
    this.selectedFrequency = [];
    this.selectedDuration = '';
    this.selectedWhenToTake = '';
    this.medicineName = "";
    this.doseCount = [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
    this.searchedMedicineObj = {}
    this.selectedInstructions = "";
    this.drugUnit = null;

    this.UnitField.value = "";
    this.FrequencyField.value = "";
    this.WhentotakeField.value = "";
    this.drugTypeField.value = ""
    this.DurationField.value = "";
    this.DrugUnitField.value = "";

    this.drugTypeUnit = "";



    this.allMedicineInstructions = this.allMedicineInstructions.map((ins:any) => { return { name: ins.name, selected: false } });
    this.allMedicineInstructionsOg = this.allMedicineInstructions;

    this.lngMedInstr.clearTextBox();
  }

  addAnotherDose() {

    let index = this.doseCount.length - 1;

    let dose = { unit: "", drugType: this.selectedDrugType, frequency: "", duration: "", whenToTake: this.selectedWhenToTake }
    // let dose = { unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }

    // setTimeout(() => {
    this.doseCount.push(dose);
    // }, 5000);
    // this.doseCount.push(dose);
    // this.doseCount.splice(0, 1)

    setTimeout(() => {
      this.selectedFrequency = [];
    }, 500);

    this.doseCount.forEach((element: any, idx:any) => {
      if (element.unit == '' && element.drugType == "") {

        // this.doseCount.splice(idx, 1);
      }
    });
  }

  convertDoseUnitToUnicode(dose:any) {
    const dosesVariant:any = {
      "5": "\u00BD",
      "50": "\u00BD",
      "25": "\u00BC",
      "75": "\u00BE"
    }

    const doseUnit = ("" + dose).split('.');
    const newUnit = doseUnit[0];
    const decimalUnit = doseUnit[1];
    if (dosesVariant[decimalUnit]) {
      return (Number(newUnit) > 0 ? newUnit : "") + dosesVariant[decimalUnit]
    } else {
      return dose;
    }
  }

  convertDoseUnitToString(dose:any) {
    dose = dose.replace("½", ".5");
    dose = dose.replace("¼", ".25");
    dose = dose.replace("¾", ".75");

    return dose
  }

  onInputCustomComponent = (value:any) => {
    this.selectedInstructions = value
  }
  addNextAppointment(){
    this.dialog.closeAll();
  const followupDate = localStorage.getItem("followupDate"); // Read from localStorage
  if (followupDate) {
    const followupDateNew = new Date(followupDate);
    var nextAppDate = `${followupDateNew.getDate().toString().padStart(2, '0')}-${(followupDateNew.getMonth() + 1).toString().padStart(2, '0')}-${followupDateNew.getFullYear()}`;
  
    console.log("followupDateNew followupDate:", nextAppDate);
  } else {
    console.log("No followupDateNew found in localStorage");
  }
  this.router.navigate(['/doctor/homepage'], { 
    queryParams: { 
      patientId: this.patientId, 
      bookNextAppointment:true
    }
  });
  } 


  addToList() {

    var dignosisId: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    });

    let dosage = '';
    let frequencyArr: any = [];
    let frequency = ''
    this.frequencyWithVal.map(eachFrequency => {
      let dose: any = ''
      // if(eachFrequency.value==""){
      //   dose=""
      // }
      if (eachFrequency.value && eachFrequency.value != '0' && eachFrequency.value != 'NULL') {


        let frequency = '';


        if (this.drugTypeUnit == 'ml') {
          dose = (eachFrequency.value + "")

        }
        else {
          dose = this.convertDoseUnitToUnicode(eachFrequency.value + "")

        }

        let doseValue = ""
        if (this.drugTypeUnit == "Fingertip,Apply-Locally") {
          this.drugUnit = this.drugTypeUnit
        }
        if (this.drugUnit == "Fingertip") {
          this.drugTypeUnit = this.drugUnit
        }


        doseValue = this.drugTypeUnit == 'ml' ? dose + ' ml' : dose;
        let unit = this.drugTypeUnit == 'Unit' || this.drugTypeUnit == 'ml' ? this.selectedDrugType : this.drugTypeUnit;
        frequency = frequency + `${doseValue} - ${unit} - ${eachFrequency.name}`
        frequencyArr.push(frequency);
      }
    });

    let name = "";
    if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedDrugType != "") {

      let unitType = '';
      let newUnitFinal = ""
      if (this.drugTypeUnit == 'ml' ||
        this.drugTypeUnit == 'Puff'
        || this.drugTypeUnit == 'Sachet') {
        unitType = this.drugTypeUnit
      }
      if (this.drugTypeUnit == 'ml') {
        newUnitFinal = this.selectedUnit
      }
      else {
        newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
      }

      if (this.drugMolecularFlag == 1) {
        console.log("drugFlag", this.drugMolecularFlag)

        name = this.medicineName.product_name && this.medicineName.product_name;
        console.log("name", name)
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }
      else {
        name = this.medicineName.product_name && this.medicineName.product_name;
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }


      // Drug molecular flag.
      // if (this.drugMolecularFlag == 1) {
      //   name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;

      // }
      // else {
      //   name = this.medicineName.product_name && this.medicineName.product_name;
      // }
      // END


      let Drug = {
        id: this.searchedMedicineObj.id,
        product_name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

      }
      console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
      var dosageValue: any = ""
      if (frequencyArr.length == 0) {
        dosageValue = newUnitFinal
      }
      else {
        dosageValue = frequencyArr.join(', ')
      }
      let obj = {
        // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
        name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

        dosage: dosageValue,
        //dosage2: frequencyArr.join(', '),
        dosage2: dosageValue,
        duration2: this.selectedDuration,
        whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
        drugType: this.selectedDrugType,
        duration: this.selectedDuration,
        unitType: unitType,
        dosageValue: newUnitFinal,
        frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
        Drug,
        //instructions: this.selectedInstructions,
        instructions: this.lngMedInstr.getValue(),
        selected: true
      }
      this.allMedicines.push(obj);
      console.log("newMed", Drug, "all", this.allMedicines)
      this.allMedicines.map((ins:any) => {
        if (ins.drugType == 'Tablet' || ins.drugType == 'Capsule' || ins.Drug.product_type
          == '"capsules"' || ins.Drug.product_type
          == 'Tablet') {
          if (ins.duration2 === 'Until Finished' || ins.duration2 === 'Until cured' || ins.duration2 === '-' || ins.duration2 === "") {

            ins["quantity"] = '';
            console.log("quantity", this.allMedicines);
            return; // Exit the current iteration
          }

          let durationText = ins.duration2.split(' ')[0]; // Extract the numeric part
          let durationUnit = ins.duration2.split(' ')[1]; // Extract the unit (e.g., week, month)
          let durationDays;

          // Check for duration in weeks or months and convert to days
          if (durationUnit.includes("Week(s)") || durationUnit.includes("Week(s)")) {
            durationDays = parseInt(durationText) * 7; // Convert weeks to days
          } else if (durationUnit.includes("Month") || durationUnit.includes("Months")) {
            durationDays = parseInt(durationText) * 30; // Convert months to days
          } else {
            durationDays = parseInt(durationText); // Already in days
          }



          const doses = ins.dosage.split(', ');
          //  const durationDays = parseInt(ins.duration2.split(' ')[0]);
          let dailyDosageSum = 0;
          doses.forEach((dose:any) => {
            // Extract the dosage value from the string (e.g., "1 - Tablet - Morning")
            const dosageValue = this.parseFraction(dose.split(' ')[0]);

            // Sum up the dosage value
            if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue;
            }
          });
          const totalTablets = dailyDosageSum * durationDays;
          ins["quantity"] = Math.ceil(totalTablets);
          console.log("quntity", this.allMedicines)
        }
        else {

        }


      })

      //save this drug and doage to doctor's popular used
      var postData: any = {
        drugObj: obj,
        commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
      }
      this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe((response:any) => {
        if (response) {
          console.log("saved 1 drug to doc popular for diag", response);
          this.getMedicinesAsPerDiagnosis();

          this.frequencyWithVal.map(eachFrequency => {
            if (eachFrequency.value && eachFrequency.value != '0') {
              eachFrequency.value = "";
            }
          });
        }
      })

      this.clearAddMedicine();
    } else {
      if (!this.medicineName.product_name && this.medicineName.length > 2) {
        this.proceedAddMedicine();
        this.dialog.open(this.add_new_drug_modal);
      } else {
        this.dialog.open(this.incomplete_data_modal);
      }

      this.UnitField.markAsTouched();
      this.DurationField.markAsTouched();
      this.drugTypeField.markAsTouched();
      this.searchTextField.markAsTouched();
      this.DrugUnitField.markAsTouched();

    }

    setTimeout(() => {
      const typeaheadElement = document.getElementById('typeahead-format');
      if (typeaheadElement) { // Check if otpElement is not null
        typeaheadElement.focus();
      }
    }, 500);

  }

  addFollowUp(e:any) {
    if (e.target.checked) {
      this.addFollowUpFlag = true
    }
    else {
      localStorage.removeItem("followupDate")
      this.addFollowUpFlag = false
    }

  }
  addnextAppointmentCheckBox(e:any) {
    console.log("eeeeeeee this.addNextAppointmentFlag",e.target.checked);
    
    if (e.target.checked) {
      this.addNextAppointmentFlag = true;
      console.log("this.addNextAppointmentFlag",this.nextAppointmentDateTime,this.addNextAppointmentFlag);
    }
    else {
      this.addNextAppointmentFlag = false;
      console.log("this.addNextAppointmentFlag",this.nextAppointmentDateTime,this.addNextAppointmentFlag);
    }
  }

  addToListOld() {


    // return;

    var dignosisId: any = [];
    this.allDignosis.map((ins:any) => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    });

    let dosage = '';
    let frequencyArr:any[] = [];
    let frequency = ''
    this.frequencyWithVal.map(eachFrequency => {
      if (eachFrequency.value && eachFrequency.value != '0') {

        let frequency = ''
        frequency = frequency + `${eachFrequency.value} - ${this.selectedDrugType} - ${eachFrequency.name}`
        frequencyArr.push(frequency);
        // dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
      }
    });

    console.log('Frequency---', frequencyArr.join(', ') + ' - ' + this.selectedWhenToTake + ' - ' + this.selectedDuration)

    let name = "";
    // let dosage = '';
    // if (this.medicineName.product_name && this.medicineName.product_name != "" && (this.selectedUnit != "" && this.UnitField.value !="") && (this.selectedDrugType != "" && this.drugTypeField.value !="") && (this.selectedFrequency.length && this.FrequencyField.value != "") && (this.selectedDuration != "" && this.DurationField.value !="") && (this.selectedWhenToTake != "" && this.WhentotakeField.value != "")) {
    if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedUnit != "" && this.selectedDrugType != "") {

      let unitType = '';
      let newUnitFinal = ""
      if (this.drugTypeUnit == 'ml' ||
        this.drugTypeUnit == 'Puff'
        || this.drugTypeUnit == 'Sachet') {
        unitType = this.drugTypeUnit
      }
      if (this.drugTypeUnit == 'ml') {
        newUnitFinal = this.selectedUnit
      }
      else {
        newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
      }


      if (this.drugMolecularFlag == 1) {
        console.log("drugFlag", this.drugMolecularFlag)

        name = this.medicineName.product_name && this.medicineName.product_name;
        console.log("name", name)
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }


      }
      else {
        name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
      }


      let Drug = {
        id: this.searchedMedicineObj.id,
        product_name: name
      }
      console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
      let obj = {
        // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
        name: name,
        dosage: frequencyArr.join(', '),
        dosage2: frequencyArr.join(', '),
        duration2: this.selectedDuration,
        whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
        drugType: this.selectedDrugType,
        duration: this.selectedDuration,
        unitType: unitType,
        dosageValue: newUnitFinal,
        frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
        Drug,
        //instructions: this.selectedInstructions,
        instructions: this.lngMedInstr.getValue(),
        selected: true
      }
      this.allMedicines.push(obj);
      console.log("newMed", Drug, "all", this.allMedicines)

      this.allMedicines.map((ins:any) => {
        if (ins.drugType == 'Tablet' || ins.drugType == 'Capsule' || ins.Drug.product_type
          == '"capsules"' || ins.Drug.product_type
          == 'Tablet') {


          const doses = ins.dosage.split(', ');
          const durationDays = parseInt(ins.duration2.split(' ')[0]);
          let dailyDosageSum = 0;
          doses.forEach((dose:any) => {
            // Extract the dosage value from the string (e.g., "1 - Tablet - Morning")
            const dosageValue = parseInt(dose.split(' ')[0]);

            // Sum up the dosage value
            if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue;
            }
          });
          const totalTablets = dailyDosageSum * durationDays;
          ins["quantity"] = totalTablets
          console.log("quntity", this.allMedicines)
        }
      })


      //save this drug and doage to doctor's popular used
      var postData: any = {
        drugObj: obj,
        commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
      }
      this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe((response:any) => {
        if (response) {
          console.log("saved 1 drug to doc popular for diag", response);
          this.getMedicinesAsPerDiagnosis();
        }
      })


      this.clearAddMedicine();
    } else {
      if (!this.medicineName.product_name && this.medicineName.length > 2) {
        this.proceedAddMedicine();
        this.dialog.open(this.add_new_drug_modal);
      } else {
        this.dialog.open(this.incomplete_data_modal);
      }


      this.UnitField.markAsTouched();
      this.DurationField.markAsTouched();
      this.drugTypeField.markAsTouched();
      this.searchTextField.markAsTouched();

    }







    return;


    if (this.doseCount.length > 1) { //for MULTIPLE dosage
      let name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;
      let dosage = ""
      let duration2 = '';
      let dosage2 = '';

      let unitType = '';
      let frequency = '';

      if (this.drugTypeUnit == 'ml' || this.drugTypeUnit == 'Puff' || this.drugTypeUnit == 'Sachet') {
        unitType = this.drugTypeUnit
      }

      this.doseCount.map((item:any) => {


        const newUnitFinal = this.convertDoseUnitToUnicode(item.unit);
        if (item.unit != "" && item.drugType != "" && item.frequency != "") {
          dosage = dosage.length ? dosage + ', ' + `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}, ${item.whenToTake ? item.whenToTake : ""}` : `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}, ${item.whenToTake ? item.whenToTake : ""}`;
          dosage2 = dosage2.length ? dosage2 + ', ' + `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}` : `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${item.frequency} - ${item.duration}`;
          duration2 = item.whenToTake ? item.whenToTake : "";
          frequency = item.frequency
        }
      });

      let Drug = {
        id: this.searchedMedicineObj.id,
        product_name: name
      }

      this.selectedFrequency = this.selectedFrequency && this.selectedFrequency.length

      console.log("lng text box value", this.lngMedInstr.getValue());

      let obj = {
        // id: this.searchedMedicineObj.id,
        name: name,
        dosage: dosage,
        dosage2: dosage2,
        // duration2: this.selectedDuration,
        duration: this.selectedDuration,
        Drug,
        selected: true,
        //instructions: this.selectedInstructions,
        instructions: this.lngMedInstr.getValue(),

        whenToTake: this.selectedWhenToTake,
        drugType: this.selectedDrugType,
        unitType: unitType,
        dosageValue: this.selectedUnit,
        frequency: this.selectedFrequency,
      }

      console.log("obj", obj)

      if (obj.name != "" && obj.dosage != "") {
        this.allMedicines.push(obj);

        var postData: any = {
          drugObj: obj,
          commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
        }
        this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe((response:any) => {
          if (response) {
            console.log("saved 1 drug to doc popular for diag", response);
          }
        })
        this.clearAddMedicine()
      }

    } else {
      let name = "";
      let dosage = '';
      // if (this.medicineName.product_name && this.medicineName.product_name != "" && (this.selectedUnit != "" && this.UnitField.value !="") && (this.selectedDrugType != "" && this.drugTypeField.value !="") && (this.selectedFrequency.length && this.FrequencyField.value != "") && (this.selectedDuration != "" && this.DurationField.value !="") && (this.selectedWhenToTake != "" && this.WhentotakeField.value != "")) {
      if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedUnit != "" && this.selectedDrugType != "") {

        let unitType = '';
        let newUnitFinal = ""
        if (this.drugTypeUnit == 'ml' ||
          this.drugTypeUnit == 'Puff'
          || this.drugTypeUnit == 'Sachet') {
          unitType = this.drugTypeUnit
        }
        if (this.drugTypeUnit == 'ml') {
          newUnitFinal = this.selectedUnit
        }
        else {
          newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
        }


        if (this.drugMolecularFlag == 1) {
          console.log("drugFlag", this.drugMolecularFlag)

          name = this.medicineName.product_name && this.medicineName.product_name;
          console.log("name", name)
          if (this.selectedFrequency.length) {
            dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

          }
          else {
            dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

          }


        }
        else {
          name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;
          if (this.selectedFrequency.length) {
            dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

          }
          else {
            dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

          }



        }


        let Drug = {
          id: this.searchedMedicineObj.id,
          product_name: name
        }
        console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
        let obj = {
          // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
          name: name,
          dosage: dosage,
          dosage2: `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency && this.selectedFrequency.join(', ')} - ${this.selectedWhenToTake ? this.selectedWhenToTake : ""} - `,
          duration2: this.selectedDuration,
          whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
          drugType: this.selectedDrugType,
          duration: this.selectedDuration,
          unitType: unitType,
          dosageValue: newUnitFinal,
          frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
          Drug,
          //instructions: this.selectedInstructions,
          instructions: this.lngMedInstr.getValue(),
          selected: true
        }
        this.allMedicines.push(obj);
        console.log("newMed", Drug, "all", this.allMedicines)

        //save this druf and doage to doctor's popular used
        var postData: any = {
          drugObj: obj,
          commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
        }
        this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe((response:any) => {
          if (response) {
            console.log("saved 1 drug to doc popular for diag", response);
            this.getMedicinesAsPerDiagnosis();
          }
        })


        this.clearAddMedicine();
      } else {
        // if (this.drugType == 0) {
        if (!this.medicineName.product_name && this.medicineName.length > 2) {
          // this.dialog.open(this.noMedicinesInDbModal); 
          this.proceedAddMedicine();
          this.dialog.open(this.add_new_drug_modal);
        } else {
          this.dialog.open(this.incomplete_data_modal);
        }


        this.UnitField.markAsTouched();
        //  this.FrequencyField.markAsTouched();
        //   this.WhentotakeField.markAsTouched();
        this.DurationField.markAsTouched();
        this.drugTypeField.markAsTouched();
        this.searchTextField.markAsTouched();
        // this.validate();

        // }

        //   this.dialog.open(this.incomplete_data_modal)
        // alert('Please fill all the required fields to save medicine')
      }

    }
    var drugIdData: any = []
    this.allMedicines.map((ins:any) => {
      if (ins.Drug.id) {
        var drugId = ins.Drug.id
        drugIdData.push(drugId)

      }
    })

    this.saveState();

  }

  proceedAddMedicine() {
    this.drugNameAddNewDrugs = this.medicineName;
    this.drugTypeAddNewDrugs = this.selectedDrugType;
    this.unitForNewMed = this.drugUnit;

    this.FrequencyFieldNewMed.value = this.FrequencyField.value;
    this.frequencyForNewMed = this.FrequencyField.value;

    this.DurationFieldNewMed.value = this.DurationField.value
    this.durationForNewMed = this.DurationField.value

    this.WhentotakeFieldNewMed.value = this.WhentotakeField.value;
    this.whenToTakeForNewMed = this.WhentotakeField.value;

    this.instructionsForNewMed = this.lngMedInstr.getValue();
  }

  dropdownSelect(key:any, value:any) {
    this.drugType = value

    console.log('key', value);
    switch (key) {

      case 'unitNewMed':
        this.unitForNewMed = value;
        break;

      case 'frequencyNewMed':
        this.frequencyForNewMed = value;
        break;

      case 'durationNewMed':
        this.durationForNewMed = value
        break;

      case 'whenToTakeNewMed':
        this.whenToTakeForNewMed = value;
        break;

      case 'unit':
        this.Tounit = "";
        if (this.doseCount.length > 1) {

          this.doseCount[this.doseCount.length - 1].unit = value

        } else {
          this.selectedUnit = value

        }
        break;

      case 'drugType':


        switch (value) {
          case "Tablet":
            this.units = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15];
            this.drugTypeUnit = "Unit";
            this.selectedDrugType = value
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Syrup":
          case "Liquid":
          // case "Lotion":
          case "Suspension":
            this.units = ["1 ml", "1.5 ml", "2 ml", "2.5 ml", "3 ml", "3.5 ml", "4 ml", "4.5 ml", "5 ml", "5.5 ml", "6.5 ml", "7 ml", "7.5 ml", "8 ml", "8.5 ml", "9 ml", "9.5 ml", "10 ml"]
            this.drugTypeUnit = "ml";
            this.selectedDrugType = value
            this.selectedDrugUnit = "ml";
            this.DrugUnitField.value = "ml"
            break;

          case "Drop":
            this.units = ["1", "2", "3", "4", "5", "10", "15", "20"]
            this.drugTypeUnit = "Drop";
            this.selectedDrugUnit = "Drop"
            this.DrugUnitField.value = "Drop"
            this.selectedDrugType = "Drop"


            break;

          case "Cream":
          case "Gel":
          case "Ointment":
          case "Oil":
          case "Jelly":
          case "Lotion":
          case "Soap":
          case "Suppository":


            this.units = ['Fingertip']
            this.drugUnit = "Fingertip";


            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Fingertip";
              this.selectedDrugUnit = "Fingertip"
            } else {
              this.selectedDrugUnit = "Fingertip"
              this.doseCount[0].unit = "Fingertip"
            }

            this.DrugUnitField.value = "Fingertip"

            break;

          case "Inhaler":
            this.units = ['1 Puff', '2 Puff', '3 Puff', '4 Puff', '5 Puff', '6 Puff', "7 Puff", "8 Puff", "9 Puff", "10 Puff"]
            this.drugTypeUnit = "Puff";
            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Puff"

            } else {
              // this.selectedDrugUnit = "Puff"
              this.doseCount[0].unit = "Puff"
            }

            this.DrugUnitField.value = "Puff"
            break;

          case "Powder":
          case "Granules":
            this.units = ['1 Sachet', '2 Sachet', '3 Sachet', '4 Sachet', '5 Sachet', '6 Sachet', "10 Sachet"];
            this.drugTypeUnit = "Sachet";
            this.selectedDrugType = value

            this.selectedDrugUnit = "Sachet"
            this.DrugUnitField.value = "Sachet"
            break;

          case "Vial":
            this.units = ['2.5', '5', '10', '20', '30', '40', '50']
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Capsule":
          case "Sachet":
          case "Spray":
          case "Injection":
            this.selectedDrugType = value
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;

          default:
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;
        }


        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value

        } else {
          this.selectedDrugType = value
        }
        break;

      case 'frequency':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].frequency = value
        } else {
          this.selectedFrequency = value;
          this.doseCount[0].frequency = value
        }
        break;
      case 'duration':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].duration = value
          this.selectedDuration = this.selectedWhenToTake;
        } else {
          this.doseCount[0].duration = value
          this.selectedDuration = value
        }
        break;

      case 'whenToTake':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].whenToTake = value
        } else {
          this.selectedWhenToTake = value;
          this.doseCount[0].whenToTake = value
        }
        break;

      case 'drugType':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value
        } else {
          this.selectedDrugType = value;
        }

      case 'drugUnit':
        this.selectedUnit = value;

        this.drugTypeUnit = value;
        this.DrugUnitField.value = value
        break;

      default:
        break;
    }
  }

  incrementDosage(index:any) {
    // this.UnitField.value = this.UnitField.value+1;
    this.doseCount[index].unit = Number(this.doseCount[index].unit) + 1;
  }
  preventNegativeInput(event: KeyboardEvent): void {
    // Check if the pressed key is the minus key
    if (event.key === '-' || event.key === '−') {
      event.preventDefault(); // Prevent the default action (typing the minus sign)
    }
  }

  onUnitChange(value:any, index:any) {


    console.log('Value', value + ' ' + index)
    // console.log('Value-1', document.getElementById("drugUnit_0").value)
    // console.log('Value-2', document.getElementById("drugUnit_1").value)
    // console.log('Value-3', document.getElementById("drugUnit_2").value)
    if (index < 0) {
      console.error('Invalid index:', index);
      return; // Exit the function early if the index is negative
    }
    if (this.doseCount.length > 1) {

      this.doseCount[this.doseCount.length - 1].unit = value

    } else {

      this.selectedUnit = value;
      this.doseCount[0].unit = value
    }
  }

  getSearchedMeds = (searchString:any) => {
    var searchDrugOnDoctorId: any = []
    if (searchString.length >= 3) {
      this.medicineLoader = true;
      var obj = { "query": searchString };
      this.patientService.getDrugs(obj).subscribe((response:any) => {
        console.log('Response', response);
        this.medicineLoader = false;
        this.searchedMeds = response.drugs.reduce((unique:any, o:any) => {
          if (!unique.some((obj:any) => obj.product_name.replaceAll(' ', '').toLowerCase() === o.product_name.replaceAll(' ', '').toLowerCase() && obj.product_type === o.product_type)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.searchedMeds.map((ins:any) => {
          if (ins.doctorId == this.doctorObj.id || ins.doctorId == null) {
            searchDrugOnDoctorId.push(ins)
          }
        })
        this.searchedMeds = searchDrugOnDoctorId


        console.log(this.doctorObj)
        // this.searchedMeds = this.searchedMeds.splice(0, 5);

      }
      )
    } else {
      this.searchedMeds = [];
      this.medicineLoader = false;
    }
  }

  updateMedicineInstructions = (searchString:any) => {
    this.selectedInstructions = searchString;
  }

  updateNewMedInstructions = (str:any) => {
    this.instructionsForNewMed = str;
  }


  searchDrugsByTyping = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.searchedMeds?.filter((v:any) => JSON.stringify(v)))
    )




  onChangeDrugName = () => {
    this.unselectedDrugName = false;
  }

  handleDrugType(productType:any) {
    var drugType = "";
    if (productType.toLowerCase().includes('cap')) {
      drugType = "Capsule"
    }
    else if (productType.toLowerCase().includes('cre')) {
      drugType = "Cream"
    }
    else if (productType.toLowerCase().includes('tab')) {
      drugType = "Tablet"
    }
    else if (productType.toLowerCase().includes('stri')) {
      drugType = "Strip"
    }
    else if (productType.toLowerCase().includes('syru')) {
      drugType = "Syrup"
    }
    else if (productType.toLowerCase().includes('drop')) {
      drugType = "Drop"
    } else if (productType.toLowerCase().includes('inhale')) {
      drugType = "Inhaler"
    } else if (productType.toLowerCase().includes('liqui')) {
      drugType = "Liquid"
    } else if (productType.toLowerCase().includes('powd')) {
      drugType = "Powder"
    } else if (productType.toLowerCase().includes('sach')) {
      drugType = "Sachet"
    } else if (productType.toLowerCase().includes('spray')) {
      drugType = "Spray"
    } else if (productType.toLowerCase().includes('susp')) {
      drugType = "Suspension"
    } else if (productType.toLowerCase().includes('vial')) {
      drugType = "Vial"
    }
    return drugType || productType
  }

  validateStringInArray = (array:any, text:any) => {
    if (array.indexOf(text) > -1) {
      return true;
    } else {
      return false;
    }
  }

  updateFrequencyWithVal = (index:any) => {
    frequencyWithVal[index].value = frequencyWithVal[index].value == '' || frequencyWithVal[index].value == '0' ? '1' : '';
  }

  setSelectMedicine =({ item }: { item: any }) => {

    console.log('ITEM', item);

    //

    let arr = item.advisedFrequency ? item.advisedFrequency.split(', ') : [];


    this.frequencyWithVal.map(eachFrequency => {

      arr.map((innerFrequency:any) => {
        if (eachFrequency.name.includes(innerFrequency)) {
          eachFrequency.value = item.advisedDosage
        }
      })

    })

    this.searchedMedicineObj = item;
    this.selectedInstructions = item.advisedInstructions ? item.advisedInstructions : ""
    setTimeout(() => {
      this.lngMedInstr.appendTexttoBox(this.selectedInstructions)
    }, 200)

    console.log("ins", this.selectedInstructions)
    console.log("medData", this.searchedMedicineObj)


    var drugType = this.handleDrugType(item.product_type);

    if (drugType && drugType != "") {
      // this.drugTypeField.setErrors(null)
    }
    // this.selectedDrugType = drugType

    let index = this.doseCount.length - 1;

    if (this.drugTypeSelection.includes(drugType)) {
      this.doseCount[index].drugType = drugType;
      this.selectedDrugType = drugType;
      this.dropdownSelect('drugType', this.selectedDrugType);
      this.drugTypeField.setErrors(null);
    } else {
      this.doseCount[index].drugType = "";
      this.selectedDrugType = ""
    }
    // this.drugTypeSelection.map((ins:any) => {
    //   if (ins.includes(drugType) || drugType.includes(ins)) {
    //     this.doseCount[index].drugType = ins;
    //     this.selectedDrugType = ins
    //     this.drugTypeField.setErrors(null);
    //   }else{

    //   }
    // })

    //DOSE
    // if (item.advisedDosage && item.advisedDosage.length) {
    //   item.advisedDosage = (item.advisedDosage.match(/[\d\.]+/g)[0])
    // }
    // else{
    //   item.advisedDosage=item.advisedDosage
    // }


    if (item.advisedDosage && parseInt(item.advisedDosage)) {
      this.doseCount[index].unit = item.advisedDosage
      this.selectedUnit = item.advisedDosage;
      // this.drugUnit = item.advisedDosage;
      let name = document.getElementById('drugUnit_0');
      this.drugUnit = item.advisedDosage;

      // name = item.advisedDosage

      this.UnitField.setErrors(null);
    } else {
      // this.doseCount[index].unit = ""
      // this.selectedUnit = "";
      this.UnitField.setErrors(null);
    }

    // FREQUENCY
    if (item.advisedFrequency && item.advisedFrequency != "" && item.advisedFrequency.toLowerCase() != "null") {

      let formattedFrequency = item.advisedFrequency.split(', ').map((ins:any) =>
        ins.charAt(0).toUpperCase() + ins.slice(1)
      );

      this.doseCount[index].frequency = formattedFrequency;
      this.selectedFrequency = formattedFrequency;
      this.FrequencyField.setErrors(null);
    } else {
      this.doseCount[index].frequency = "";
      this.selectedFrequency = "";
    }


    //DURATION
    if (item.advisedDuration && item.advisedDuration != "" && item.advisedDuration.toLowerCase() != "null") {

      this.DurationField.setErrors(null)
      if (item.advisedDurationUnit) {
        this.doseCount[index].duration = item.advisedDuration + ' ' + item.advisedDurationUnit
        this.selectedDuration = item.advisedDuration + ' ' + item.advisedDurationUnit
      } else {
        if (item.advisedDuration == "1") {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        } else {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        }
      }
    } else {
      this.doseCount[index].duration = "";
      this.selectedDuration = "";
    }

    //WHEN TO TAKE
    if (item.advisedWhenToTake && item.advisedWhenToTake != "" && item.advisedWhenToTake.toLowerCase() != "null" && whenToTake.includes(item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1))) {

      // element.drugType.charAt(0).toUpperCase() + element.drugType.slice(1) : " ";
      this.doseCount[index].whenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.selectedWhenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.WhentotakeField.setErrors(null);

    } else {
      this.doseCount[index].whenToTake = ""
      this.selectedWhenToTake = ""
    }

    var duration = "";
    this.doseCount.map((ins:any) => {
      if (ins.duration == '') {
        duration = ins
      }
    });
    setTimeout(() => {
      // this.DrugUnitField.value = this.drugTypeUnit;
      // this.drugTypeUnit = this.DrugUnitField.value;
      this.selectedDrugUnit = this.drugTypeUnit;
      this.DrugUnitField.setErrors(null)
    }, 200);

  }

  formatter = (result: any) => result.product_name;
  formatMatches = (result: any) => result.product_name ? result.product_name : '';

  closeAddMedicine($event:any) {
    $event.stopPropagation();
    this.isAddMedicine = false;
    this.clearAddMedicine();

  }

  removeDose(index:any, event:any) {
    this.doseCount.splice(index, 1)
  }

  getFavApptsOfDoctor = () => {
    // this.showRequisitionLoading = true;
    this.patientService.getFavApptsOfDoctor().subscribe((response:any) => {
      if (response) {
        // this.currentFavMedObj=response;
        this.favArray = [];
        this.selectedFavMed = [];
        this.favMedicineArray = response

        //let myid=this.currentFavMedObj.id;
        this.currentFavMedObj = response.length ? response[0] : {};
        console.log("favappt1", this.currentFavMedObj);
        console.log("favappt", response)
        localStorage.setItem("doctorsFavTemplate", this.currentFavMedObj);

        // this.showRequisitionLoading = false;
        for (let ins = 0; ins < response.length; ins++) {

          const element = response[ins];
          var obj: any = {}


          obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
          if (element.Visit && element.Visit != null) {
            var med: any = {};
            med.id = element.id;
            med.value = element.favApptName;
            med.medicine = element.Visit.Medicines;
            med.selectedComplaints = element.Visit.chief_complaints ? element.Visit.chief_complaints : '';
            med.selectedDiagnosis = element.Visit.complaint_diagnosis ? element.Visit.complaint_diagnosis : '';
            // med.selectedGeneralInstructions = element.Visit.general_instruction ? element.Visit.general_instruction : '';
            // med.selectedDietaryAdvices = element.Visit.nutrition_diet_advice ? element.Visit.nutrition_diet_advice : '';
            // med.nextFollowUp = element.Visit.next_vaccination;
            //here
            this.favArray.push(med);
          }


          console.log('FAV-ARRAY', this.favArray)



        }

        this.favArrayNames = this.favArray.map((ins:any) => { return ins.value });
        console.log("favMarch1", this.favArrayNames)

        this.favArrayNames = this.myControlFav.valueChanges.pipe(
          startWith(''),
          map(value => this._filterFavourites(value))
        );



        console.log("favArrar", this.favArrayNames);
        // localStorage.setItem("doctorsFavTemplate", this.favArray);
        // this.selectedFavMed.map(ins=>{
        //   if (ins.Drug) {
        //     ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
        //     ins.medicineName = ins.Drug.product_name;
        //   }

        // })
        // this.selectedPreviousFavMed="choose"

        // commenting auto selecting first favourite medicine
        // if (this.favArray.length > 0) {
        //   this.selectedFavMed = this.favArray[0].medicine
        //   this.selectedPreviousFavMed = this.favArray[0].value;
        // }

        console.log("sele", this.selectedFavMed)



        this.selectedFavMed.map((ins:any) => {

          console.log("logggg", ins.value)
          if (ins.Drug) {
            ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
            ins.medicineName = ins.Drug.product_name;
          }
        })


      }
    })
  }

  onDropdownSelectFavMed = (item:any) => {
    setTimeout(() => {
      this.selectedPreviousFavMed = item.value
      this.selectedFavMed = item.medicine;
      this.currentFavMedObj = item;
    }, 200);


  }
  openPreviousMed() {
    this.openDialog(this.previous_medicines_modal, 0)

  }

  validate = () => {
    var drugtype: any = "";

    this.doseCount.map((ins:any) => {
      drugtype = ins.drugType
    })

    if (Object.keys(this.medicineName).length === 0) {
      this.unselectedDrugName = true;
    }
    if (this.drugType == '') {

    }
  }
  onDropdownSelect = (item:any) => {
    // this.selectedPreviousMedDate = item?.value?.value;

    this.selectedPreviousMed = item?.value?.medicine;

    this.pdfUrlForViewPrescription = item?.value?.pdfUrl




  }


  closefavper() {
    this.selectedPreviousFavMed = ""
    this.selectedFavMed = [];
    //set focus on textbox
    setTimeout(() => {
      const favinputtextboxElement = document.getElementById('favInput');
      if (favinputtextboxElement) { // Check if otpElement is not null
        favinputtextboxElement.focus();
      }
    }, 500);


  }

  calculateQuantity(ins:any) {
    console.log("inssssss",ins)
    // Check if it's a valid medicine type for quantity calculation
    if (ins.Drug.product_type === 'Tablet' || ins.Drug.product_type === 'capsules') {
      
      // Skip quantity calculation for 'Until Finished' or 'Until cured'
      if (ins.duration2 && (ins.duration2 === 'Until Finished' || ins.duration2 === 'Until cured')) {
        ins["quantity"] = ''; // No quantity needed
        return;
      }
  
      // Ensure duration2 and dosage2 are present for calculation
      if (ins.duration2 && ins.dosage2) {
        // 1. Extract the numeric part of the duration (e.g., "3" from "3 Day(s)")
        let durationText = ins.duration2.split(' ')[0];
        let durationDays = parseInt(durationText, 10); // Convert to integer
  
        // 2. Split the dosage2 into parts based on "- " to separate different times of day
        const doses = ins.dosage2.split(' - ');  // Example: ["1", "Tablet", "Morning", "1", "Tablet", "Afternoon", "1", "Tablet", "Night"]
  
        // 3. Sum the total number of tablets taken in one day
        let dailyDosageSum = 0;
        doses.forEach((dose:any, index:any) => {
          // Every third element contains the dosage (e.g., "1")
          if (index % 3 === 0) {
            const dosageValue = parseInt(dose.trim()); // Extract the number (e.g., "1" from "1 - Tablet - Morning")
            if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue;  // Add to daily total
            }
          }
        });
  
        // 4. Calculate total quantity: total daily dosage * number of days
        const totalQuantity = dailyDosageSum * durationDays;
        ins["quantity"] = Math.ceil(totalQuantity);  // Round up the total quantity
        console.log("Calculated quantity for", ins.Drug.product_name, ":", ins["quantity"]);
      } else {
        console.error("Missing dosage");
      }
    }
  }
          


  calculateQuantityForPreviews(ins: any) {
    console.log("inssssssss1",ins)
    const isTabletOrCapsule = ins.dosage2.toLowerCase().includes('tablet') || 
                              ins.dosage2.toLowerCase().includes('capsule');

    // If it's neither a Tablet nor a Capsule, set quantity to 0 and return
    if (!isTabletOrCapsule) {
        ins.quantity = "";
        return; // Exit the function
    }
   
  // Extracting the duration in days from duration2 (e.g., "3 Day(s)")
  const durationMatch = ins.duration2.match(/(\d+)\s*Day/);
  const durationDays = durationMatch ? parseInt(durationMatch[1]) : 0;

  // Initialize daily dosage sum
  let dailyDosageSum = 0;

  // Check if dosage2 is defined and split it into individual dosages
  if (ins.dosage2) {
      // Split by ' - ' to separate each dosage instruction
      const doses = ins.dosage2.split('-').map((dose:any) => dose.trim());

      // Iterate over each dose to calculate the total daily dosage
      doses.forEach((dose:any) => {
          // Extract the dosage value (first part of the dose string)
          const dosageParts = dose.split(' ');
          const dosageValue = this.parseFraction(dosageParts[0]); // Get the numeric part

          // If the dosageValue is a valid number, add it to dailyDosageSum
          if (!isNaN(dosageValue)) {
              dailyDosageSum += dosageValue; // Sum all the daily dosages
          }
      });
  }

  // Calculate total quantity for the total duration
  ins.quantity = Math.ceil(dailyDosageSum * durationDays);
  
}
  
  

  saveModalValues(type: string) {

    let closeDialogFlag = true;

    switch (type) {

      case 'copyMedicines':

        //aaleee
        // COMMENT
        this.selectedPreviousMed = this.selectedPreviousMed.reduce((unique, o) => {
          if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.selectedPreviousMed.map((ins:any) => {

          if (ins.selected) {
            // // ins.id = null;
            // // ins.medicineName = ins.Drug.product_name;
            // this.allMedicines.push({ name: ins.medicineName ? ins.medicineName : ins.Drug.product_name, dosage: ins.dosage2 })
            ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
            // ins.dosage = ins.dosage2 + ' ' + ins.duration2;
            ins.dosage = ins.dosage2 + ' ' + ins.duration2;
           

            // Call calculateQuantity to compute the correct quantity
           this.calculateQuantityForPreviews(ins);

           
           
            delete ins['id'];

            this.allMedicines.push(ins);

           
          }
        });
        this.allMedicines = this.allMedicines.reduce((unique:any, o:any) => {
          if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.selectedPreviousMed.map(med => {
          med.selected = false;
        })

       

        console.log('COPIED MEDICINES', this.allMedicines)

        // this.prescriptionPreviewArray = [...this.selectedPreviousMed, ...this.prescriptionPreviewArray];
        // this.medicine_list = [...this.selectedPreviousMed, ...this.medicine_list]

        break;
      case 'copyMedicinesFav':
        console.log("allMed", this.allMedicines)
        //  this.allMedicines.map(ind=>{
        //   this.selectedFavMed.map((ins:any) => {
        //   })
        //  })




        this.selectedFavMed.map((ins:any) => {
          console.log("kkkkkkkkkkkkkkoct16",ins)


          if (this.allMedicines.length) {
            this.allMedicines.map((itr:any) => {
              if (ins.Drug.id == itr.Drug.id) {

              }
              else {
                if (ins.selected) {
                  ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
                  if (!ins.duration) {
                    ins.duration = ""
                    ins.dosage = ins.dosage2 + ' ' + ins.duration;
                  }
                  else {
                    ins.dosage = ins.dosage2 + ' ' + ins.duration;
                  }
                  console.log("mruuuuOct1",ins)
                 this.calculateQuantityForPreviews(ins);
                  delete ins['id'];
                  this.allMedicines.push(ins);

                 
                  this.allMedicines = this.allMedicines.reduce((unique:any, o:any) => {
                    if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
                      unique.push(o);
                    }
                    return unique;
                  }, []);


               

                }
              }

            })

          }
          else {
            if (ins.selected) {
              ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
              if (!ins.duration) {
                ins.duration = ""
                ins.dosage = ins.dosage2 + ' ' + ins.duration;
              }
              else {
                ins.dosage = ins.dosage2 + ' ' + ins.duration ? ins.duration : '';
              }
             //  ins.quantity=""
              // if(!ins.whenToTake){
              //   ins.whenToTake=''
              // }
              console.log("mruuuuOct2",ins)
             this.calculateQuantityForPreviews(ins);
              delete ins['id'];
              this.allMedicines.push(ins);


           
             
            }

          }
        })
        
        break;

      case 'medicine_instructions':
        var instructions = this.lngMedInstr.getValue();
        this.allMedicineInstructionsOg.map((ins:any) => {

          if (ins.selected && !this.selectedInstructions.includes(ins.name)) {
            //this.selectedInstructions = this.selectedInstructions.length ? this.selectedInstructions + ', ' + ins.name : ins.name

            if (instructions != undefined || instructions != "") {
              this.lngMedInstr.setTextBoxValue(instructions.length ? instructions + ', ' + ins.name : ins.name);
            }
            else {
              this.lngMedInstr.setTextBoxValue(ins.name);
            }

          }

        });
        break;

      case 'drugTypesAddNewDrug':

        if (this.drugNameAddNewDrugs != "" && this.drugTypeAddNewDrugs != "") {
          this.addNewDrugs();
        } else {
          closeDialogFlag = false;
          alert('Please specify drug name and type.')
        }

        break;

      case 'copyPopularMedicines':

        // for (let index = 0; index < this.allMedicinesForDiagnosis.length; index++) {
        //   const element = this.allMedicinesForDiagnosis[index];
        //   this.allMedicines.push(element);
        // }


        this.allMedicienOrg.forEach((element:any) => {

          if (element.selected) {
            element.dosage = element.dosage ? element.dosage : " ";
            element.dosage2 = element.dosage2 ? element.dosage2 : " "
            element.drugType = element.drugType ? element.drugType.charAt(0).toUpperCase() + element.drugType.slice(1) : " ";
            element.duration2 = element.duration2 ? element.duration2 : " ";
            element.instructions = element.instructions ? element.instructions : " ";
            element.whenToTake = element.whenToTake ? element.whenToTake : " "

            this.allMedicines.push(element);
          }



        });

        let uniq = [...Array.from(new Set(this.allMedicines))];

        //this.allMedicines = uniq;
        this.allMedicines = this.allMedicines.reduce((unique:any, o:any) => {
          if (!unique.some((obj:any) => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);


        this.allMedicines.map((ins:any) => {
          if (ins.drugType == 'Tablet' || ins.drugType == 'Capsule' || ins.Drug.product_type
            == '"capsules"' || ins.Drug.product_type
            == 'Tablet') {
            if (ins.duration2 === 'Until Finished' || ins.duration2 === 'Until cured') {

              ins["quantity"] = '';
              console.log("quantity", this.allMedicines);
              return; // Exit the current iteration
            }

            let durationText = ins.duration2.split(' ')[0]; // Extract the numeric part
            let durationUnit = ins.duration2.split(' ')[1]; // Extract the unit (e.g., week, month)
            let durationDays;

            // Check for duration in weeks or months and convert to days
            if (durationUnit.includes("Week(s)") || durationUnit.includes("Week(s)")) {
              durationDays = parseInt(durationText) * 7; // Convert weeks to days
            } else if (durationUnit.includes("Month") || durationUnit.includes("Months")) {
              durationDays = parseInt(durationText) * 30; // Convert months to days
            } else {
              durationDays = parseInt(durationText); // Already in days
            }



            const doses = ins.dosage.split(', ');
            //  const durationDays = parseInt(ins.duration2.split(' ')[0]);
            let dailyDosageSum = 0;
            doses.forEach((dose:any) => {
              // Extract the dosage value from the string (e.g., "1 - Tablet - Morning")
              const dosageValue = this.parseFraction(dose.split(' ')[0]);

              // Sum up the dosage value
              if (!isNaN(dosageValue)) {
                dailyDosageSum += dosageValue;
              }
            });
            const totalTablets = dailyDosageSum * durationDays;
            ins["quantity"] = Math.ceil(totalTablets);
            console.log("quntity", this.allMedicines)
          }
        });


        break;

      default:
        break;
    }

    if (closeDialogFlag) {
      //this.dialog.closeAll()
    }
    this.saveState();


  }

  addNewDrugs = () => {
    // this.drugCompanyAddNewDrugs = '';
    // this.genericDrugNameAddNewDrugs = '';
    // this.drugTypeAddNewDrugs = '';
    // this.drugNameAddNewDrugs = '';

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let otp = "";
    for (let i = 0; i < 4; i++) {
      //changed to 6 from 4
      otp += numbers[Math.floor(Math.random() * 8) + 1];
    }

    // let aobj = {
    //   advisedDosage: this.unitForNewMed,
    //   advisedFrequency: this.frequencyForNewMed.join(', '),
    //   advisedDuration: this.durationForNewMed.split(' ')[0],
    //   advisedWhenToTake: this.whenToTakeForNewMed,
    //   advisedDurationUnit: this.durationForNewMed.split(' ')[1],
    //   advisedInstructions: this.instructionsForNewMed
    // }

    // console.log('OBJ-->', aobj);

    var obj = {
      "product_name": this.drugNameAddNewDrugs,
      "product_company": this.drugCompanyAddNewDrugs,
      "product_type": this.drugTypeAddNewDrugs,
      "product_composition": this.genericDrugNameAddNewDrugs,
      "product_id": `${this.drugNameAddNewDrugs.substring(0, 3).trim() + otp}`,

      "advisedDosage": this.unitForNewMed,
      "advisedFrequency": this.frequencyForNewMed.length ? this.frequencyForNewMed.join(', ') : '',
      "advisedDuration": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[0] : '',
      "advisedDurationUnit": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[1] : '',
      "advisedWhenToTake": this.whenToTakeForNewMed,
      "advisedInstructions": this.instructionsForNewMed
    }


    console.log('Object', obj)

    this.patientService.addNewDrug(obj).subscribe((result:any) => {
      if (result.Drug) {

        // To open type ahead after adding drug
        // Dispatch event on input element that NgbTypeahead is bound to
        // this.typeAhead.nativeElement.dispatchEvent(new Event('input'));
        // Ensure input has focus so the user can start typing
        // this.typeAhead.nativeElement.focus();

        this.messageService.add({ severity: 'success', summary: 'Drug added successfully', detail: 'Drug has been successfully added to your records.' });


        console.log('Saved New Drug');
        this.searchedMeds = result.Drug;
        console.log("newDrug", this.searchedMeds);
        this.medicineName = result.Drug;
        this.setSelectMedicine({ item: this.searchedMeds });
        this.addToList();
        this.drugCompanyAddNewDrugs = '';
        this.genericDrugNameAddNewDrugs = '';
        this.drugTypeAddNewDrugs = '';
        this.drugNameAddNewDrugs = '';
      } else {
        alert('Something went wrong');
      }
    });
  }

  onClickDeleteFavMed() {
    console.log("favId", this.currentFavMedObj);
    console.log("favMes", this.selectedDiagnosis)




    let postData = {
      appointmentId: this.currentFavMedObj.id
    }
    this.patientService.removeFavApptOfDoctor(postData).subscribe((response:any) => {
      // if (confirm("Are you sure to delete ")) {
      console.log("delete", response);


      this.getFavApptsOfDoctor();
      this.selectedPreviousFavMed = "";
      this.modalService.dismissAll();
    });
    console.log("", this.favArrayNames)
    this.selectedDiagnosis.map((item:any) => {
      if (this.currentFavMedObj && this.currentFavMedObj.Visit && this.currentFavMedObj.Visit.complaint_diagnosis == item) {
        var itemIndex = this.selectedDiagnosis.splice(item, 1)
      }
    })

    this.dialog.closeAll()

  }



  getAllDoctorPrescriptions = () => {
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData") ?? 'null');

    var obj = {
      "patientId": this.patientId ? this.patientId : this.patientDataFromLocal.id,
      "isForHistory": true,
    }

    console.log("satya this.patientId", obj);


    this.patientService.getAppointmentsByPatientId(obj).subscribe((response:any) => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if (response.success) {
        console.log("mmm1", response)
        if (response.historyArr.length) {

          var medArr = [];
          var arr: any = [];
          for (let index = 0; index < response.historyArr.length; index++) {
            const element = response.historyArr[index];
            var obj: any = {}
            obj.createdAt = element.from;
            obj.from = element.from;
            obj.to = element.to;
            obj.id = element.id;
            obj.favApptName = element.favApptName;
            obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
            obj.Visit = element.Visit && element.Visit != null ? element.Visit : null;
            obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
            obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
            obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
            if (element.Visit && element.Visit != null && element.Visit.Medicines && element.Visit.Medicines.length > 0) {
              var med: any = {};
              if (new Date(element.from).getDate() == new Date().getDate() && new Date(element.from).getMonth() == new Date().getMonth() && new Date(element.from).getFullYear() == new Date().getFullYear()) {

              }
              else {
                med.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                med.medicine = element.Visit.Medicines;
                med.complaints = element.Visit.chief_complaints;
                med.diagnosis = element.Visit.complaint_diagnosis;
                if (element.prescriptionPDFURL != null) {
                  med.pdfUrl = element.prescriptionPDFURL
                }

                medArr.push(med);
              }
            }
            arr.push(obj);
            // console.log("pdf", arr)

          }



          if (medArr && medArr.length > 0) {
            this.previousMedsArr = medArr.reverse()
            console.log("May3333", this.previousMedsArr)

            this.selectedPreviousMed = medArr[0].medicine;

            this.selectedPreviousMedDate = medArr[0].value;
            this.pdfUrlForViewPrescription = medArr[0].pdfUrl
            // this.selectedPreviousFavMed=medArr[0].favApptName

            this.selectedPreviousMed.map((ins:any) => {
              if (ins.Drug) {
                ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
                ins.medicineName = ins.Drug.product_name;
              }
            })
          }
        }

      }
    });
  }

  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  onDropdownSelectFollowUpForQ(key:any) {


    this.followupType = key
    localStorage.setItem("selectApptfollowupType", key);
    localStorage.setItem("bookAppointment", this.followupType);
    if (this.followupType == "Visit") {
      this.appointmentBook = false
      console.log("data:", this.followupType)
    }
  }
 
   proceedSaveVitals() {
    this.AddVitalsComponent.saveVisit();
    this.previewPrescriptionPDF('saveonly');
  }
  saveVitals() {

    // Prevent calling saveVisit if a save operation is already in progress
    // if (this.isSaving) {
    //   console.log('Jan23 vitals allredy saved no need to save again ',this.isSaving);
    //   return;
    // }
    // Mark that the save operation is in progress
    // this.isSaving = true;
    console.log('Jan23 saving vitals',this.isSaving);
    // Call saveVisit method
    this.AddVitalsComponent.saveVisit();

  }
  async vitalsSaveOnly() {
    this.saveButtonDisabledFlag = true;
    try {

      await this.saveVitals();


      await this.previewPrescriptionPDF('saveonly')
    } catch (error) {

    }


  }




  // saveVitals() {

  //   if(this.visits  ){
  //   this.visits.map((element, index) => {
  //     if (element._id && element._id === "synthetic_record") {
  //     console.log("log in synthetic_record3");

  //       this.visits.splice(index, 1);
  //     }


  //   });
  // }

  //   this.saveButtonDisabledFlag = true;

  //   setTimeout(() => {
  //     this.saveButtonDisabledFlag = false;
  //   }, 3000);
  //   this.AddVitalsComponent.saveVisit();
  // }
  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      console.log('Doctor Response', response.doctor)
      this.appointmentFlag = response.doctor.isAppointmentEnabled
      this.doctorObj = response.doctor

      //  this.currentDoctorObj = response.doctor
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);

          this.showOtherId = services && services.showAnotherId ? services.showAnotherId : ''
          this.showLocality = services && services.showLocality ? services.showLocality : false;
          // for swithcing next follow up to next appointment 
          console.log("servicesservices",services);
          this.doctorSpeciality = response.doctor.speciality;
          console.log("doctorSpeciality", this.doctorSpeciality);
          if(services.nextAppointment == 1){
            this.showNextAppointment = true;
          }
          if (services.printRequisition == 1) {
            this.printRequisition = true;
          
            // Ensure "Print Requisition" is added only once
            this.selectFieldsArray = [...selectFields, { name: "Print Requisition", selected: true, value: "showRequisition" }];
            this.printingFields = this.selectFieldsArray
            .filter((item:any) => item.selected)
            .map((item:any) => item.value);
      
            
          } else {
            this.selectFieldsArray = [...selectFields];
    
          }
          
          // Ensure `printingFields` reflects the latest 
  
          console.log("selectFieldsselectFields",this.selectFieldsArray);
          
          console.log("services", services)
          // this.selectedTemplate=JSON.parse(response.doctor.ser)
          this.drugMolecularFlag = services && services.drugMoleculeFlag ? services.drugMoleculeFlag : false
          var language = services && services.prescriptionLang ? services.prescriptionLang : 'en'
          var localLanguage: any = JSON.parse(localStorage.getItem("doctorsLanguage") ?? 'null');

          this.nextAppointmentDays = services && services.nextApptDays ? services.nextApptDays : ''

          if (this.nextAppointmentDays) {
                          this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + this.nextAppointmentDays));

  
              // let formatedDate = new DatePipe('en-US').transform(this.followupDateNew, 'dd/MM/yyyy')
              // this.followupDateNew = formatedDate;
              // console.log("FORMATTED DATE", formatedDate);
  
              localStorage.setItem("followupDate", this.followupDateNew)
          }
          else {
              this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + 5));
              this.nextAppointmentDate = moment(this.nextAppointmentDate, "DD/MM/YYYY").toDate();
              console.log("RETRO>>>>>> followupDateNew1234" , this.followupDateNew);
              
            localStorage.setItem("followupDate", this.followupDateNew)
          }
          this.followupDateField.setErrors(null)


          var selectedLanguage = {}
          this.languageArray.map((ins:any) => {
            if (ins.language == language) {
              selectedLanguage = { name: ins.name, language: ins.language }
            }
          })
          if (this.backToEdit) {
            this.selectedLanguage = localLanguage ? localLanguage : selectedLanguage
          }
          else {
            this.selectedLanguage = selectedLanguage
          }



          if (services.template_names) {
            // this.tabs.push('Receipts');
            this.templateName = 'Custom Prescription';
            this.selectedTemplate = services.template_names;
            // here
            let temp = localStorage.getItem("selectDoctorsTemplate");

            if (temp) {
              this.templateName = temp
            } else {
              this.templateName = services.template_names[0];
            }
          }
          else {
            this.selectedTemplate.push('default');
          }
        }
        else {
          this.templateName = 'default';
          this.selectedTemplate.push('default');
        }
      }
    })
  }
  loadPendingVaccination() {
    let pendingVaccines = JSON.parse(localStorage.getItem("pendingVaccinationForPrint") || "[]");

    console.log("All Pending Vaccines:", pendingVaccines);
    console.log("Current Patient ID:", this.patientId);

    const patientData = pendingVaccines.find((p: any) => p.patientId == this.patientId);

    if (patientData) {
      let pendingVaccinationDate = patientData.suggestedDate || null;
      this.selectedVaccination = patientData.data || [];

      // Convert date to dd-mm-yyyy format if valid
      let formattedDate = pendingVaccinationDate ? new Date(pendingVaccinationDate).toLocaleDateString('en-GB') : "Unknown Date";

      // Format the text: "1). VisitName - Vaccine Name"
      let vaccineDetails = this.selectedVaccination.length
        ? this.selectedVaccination.map((v: any, index: number) =>
          `${index + 1}). ${v.visitName} - ${v.name}`
        ).join(", ")
        : "No pending vaccinations.";

      // Planed for 22/03/2025
      // Final formatted text
      this.selectedVaccDueText = `Planned for ${formattedDate}<br>${vaccineDetails}`;
    } else {
      // this.selectedVaccDueText = "No pending vaccinations.";
    }

    console.log("is.patientDataFromLoc selectedVaccDueText", this.selectedVaccDueText);

    // Append text to the input box
    setTimeout(() => {
      if (this.langVaccDue) {
        this.langVaccDue.appendTexttoBox(this.selectedVaccDueText);
      }
    }, 100);
  }


  previewPrescriptionPDF = (actionparam:any) => {


    console.log("allMed", this.allMedicines)




    this.savePreviewButtonFlag = true;
    setTimeout(() => {

      this.savePreviewButtonFlag = false;
    }, 4000);

    this.editedValues = this.editViewPrescriptioncomponent.geteditedValues();

    let flag = 0;
    // flag comes 0 for continue without favourite

    if (this.selectedPer == 'Favourite Prescription') {
      this.favMediciensArray.map((ins:any) => {
        ins.selected = true;
      });
      this.allMedicines = this.favMediciensArray;

    } else if (this.selectedPer == 'Refill Prescription') {
      this.selectedPreviouslyMedArray.map((ins:any) => {
        ins.selected = true
      })
      this.allMedicines = this.selectedPreviouslyMedArray
    }

    if (this.favaddFavPrescriptionTextBox && this.favApptName == "" && flag) {
      //test
      // this.dialog.open(this.confirm_favourite_modal)
    } else {
      this.closeDialog()
      this.showLoading = true;
      if (this.followupDateNew != null) {
        var newFollowUpDate: any = new Date(this.followupDateNew);
        newFollowUpDate = newFollowUpDate ? newFollowUpDate : ''
        var nextFollowUpDate: any = newFollowUpDate.toDateString();
              }
      var nextFollowUp = "";
      var folloupMessage: any = this.langFollowupMessage ? this.langFollowupMessage.getValue() : ""

      localStorage.setItem("followUpMessage", folloupMessage)
      folloupMessage = folloupMessage.split(',  ')
      folloupMessage = folloupMessage.join(':')

      if (this.addFollowUpFlag == true) {
        nextFollowUp = (nextFollowUpDate ? nextFollowUpDate : "") + ":" + this.followupType + ":" + folloupMessage
      }

      localStorage.setItem("bookAppointment", nextFollowUp)
      console.log("nextVac", nextFollowUp)

      this.allMedicines.map((ins:any) => {
        // if (!this.drugMolecularFlag) {
        //   ins.Drug.generic_name = "";
        // }

      })

      var chief_complaints = this.editedValues.selectedComplaints
      var on_examination = this.editedValues.selectedOnExamination
      var complaint_diagnosis = this.editedValues.selectedDiagnosis
      var general_instruction = this.editedValues.selectedGeneralInstructions;
      var nutrition_diet_advice = this.editedValues.selectedDietaryAdvices

      let obj: any = {};
      var patient: any = {};
      var Drug: any = {}

      patient.first_name = this.patientData.firstName;
      console.log("this.patientData.firstName", this.patientData);

      patient.last_name = this.patientData.lastName;
      patient.gender = this.patientData.gender;
      patient.phone_number = this.patientData.contactNo;
      this.lastvisitIdForPdf = this.patientData.visits[this.patientData.visits.length - 1]._id;
      console.log("lastvisitIdForPdf", this.lastvisitIdForPdf);

      // if (this.showOtherId == 1) {
      //   patient.otherIdNo = this.patientData.otherIdNo

      // }
      // if(this.showLocality){
      //   patient.locality=this.patientData.locality
      // }

      let finalMedicines = [];

      this.allMedicines.map((ins:any) => {
        if (ins.selected && ins.dosage2) {
          finalMedicines.push(ins);

        }
      })


      patient.age = this.patientData.age;
      patient.weight = 'N/A';
      patient.height = 'N/A';
      console.log("patiMru", patient)
      obj = {
        // "visit_id": null,
        "visit_id": obj.visit_id ? obj.visit_id : this.lastvisitIdForPdf,

        "clinic_id": null,
        "read_only": false,
        "date": null,
        "appointmentId": null,
        // "visit_id": this.appointmentData.VisitId,
        // "date": this.appointmentData.visit_date,
        "action": "share",
        "patientId": this.patientId,
        "patient": patient,

        "medicines": this.allMedicines, //pending
        "general_instruction": general_instruction,
        "covidQues": "",
        "suggested_investigation": '',
        "chief_complaints": chief_complaints,
        "next_vaccination": nextFollowUp,
        "expirationDate": '',
        "on_examination": on_examination,
        "complaint_diagnosis": complaint_diagnosis,
        "labTests":this.labTests ? this.labTests.join(','):'',
        "savePrescriptionParams": {},
        //  "printingFields":this.printingFields,
        'newVersion': true,
        "medicinePreview": '',//pending
        "language": this.selectedLanguage.language,
        "nutrition_diet_advice": nutrition_diet_advice,
        "templateName": this.templateName,
        "favApptName": this.favApptName,
        "newVersionValue": 'a',
        "specialVaccination": this.selectedSpecialVaccination.join(',')
      }
      if (actionparam == 'saveonly') {
        obj.action = "saveonly";
      }
      this.allMedicines.map((ins:any) => {
        if (ins.dosage2) {

          ins.dosage2 = ins.dosage2.split(",").join(" - ");
        }

      })
      this.allMedicines.map((ins:any, ind:any) => {
        ins.sortId = ind
        console.log("sordId", ins.sortId)

      })


      let savePrescriptionParams = {
        visit: {
          complaint_diagnosis: obj.complaint_diagnosis,
          general_instruction: obj.general_instruction,
          chief_complaints: obj.chief_complaints,
          next_vaccination: obj.next_vaccination,
          complaint_examination: obj.on_examination,
          suggested_investigation: obj.suggested_investigation,

         covidQues: null as string | null,
          expirationDate: obj.expirationDate,
          nutrition_diet_advice: obj.nutrition_diet_advice,
        },
        patient_id: obj.patientId,
        visit_id: obj.visit_id ? obj.visit_id : this.lastvisitIdForPdf,
        medicine_list: this.allMedicines,
      }
      console.log("obj.visit_id", obj.visit_id);

      console.log("visitData to print", savePrescriptionParams);
      console.log("savePrescriptionParams", savePrescriptionParams);

      if(this.addNextAppointmentFlag && this.nextAppointmentDateTime){
        obj.nextAppointmentDate =  this.nextAppointmentDateTime;  
        
      }else{
        delete obj.nextAppointmentDate;
        // delete obj.next_vaccination;
        if(this.showNextAppointment){
          delete obj.next_vaccination;
        } 
      }   
      obj.savePrescriptionParams = savePrescriptionParams;
      // var arr = this.selectFieldsArray.filter(field => { if (field.selected) { return field } });
      // arr = arr.map(field => { return field.value });
      obj.printingFields = this.printingFields;
      obj.printingVitalsField = this.printingVitals
      console.log(this.printingVitals)
      console.log("RETRO>>>>>>", JSON.stringify(obj.next_vaccination), obj);


      // if (this.appointmentId && this.appointmentId != '') {
      //if coming from appointment already created call V1 of printpresription()
      console.log("this.patientDataFromLocal.id", this.lastvisitIdForPdf, obj.next_vaccination);

      this.patientService.printPrescriptionVersion2(obj).subscribe((response:any) => {
        if (response) {

          console.log('PRESCRIPTION PDF Version 1', response)
          let url = response.url;
          let appointmentId = response.appointmentId
          //window.open(url);
          this.showLoading = false;

          sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));

          if (actionparam == 'saveonly') {

            this.getPrescriptionMedicines()
            this.allMedicienOrg.forEach((ins:any) => {
              if (ins.selected) {
                ins.selected = false;
              }

            })
          } else {

            this.router.navigate([`/doctor/patients/prescriptions/preview`], { queryParams: { prescrip_url: url, patientId: this.patientId, appointmentId: appointmentId } });
          }


        } else {
          this.showLoading = false;
        }
      });
      // } else {
      //   console.log("NO APPOINTMENT ID FOUND !!!! CANNOT CALL generate PDF api");
      // }
    }
  }

  VitalOldRxClicked() {
    console.log("VitalOldRxClicked clicked");
    // this.refreshservice.setFlag("Try")

  }

  clearAllMedicines(allMed:any) {

    this.allMedicines = []
    sessionStorage.removeItem('qrx_allMedicines');
  }

  showModalPreview() {
    // alert('ytest')
    this.dialog.open(this.edit_prescription_modal)
  }

  onUpdatePreviewPrescription = (updatedValues:any) => {
    this.updatedValues = updatedValues;
  }

  updatePrescription = () => {
    console.log('Aala', this.updatedValues)

  }

  clearAllSelection() {
    var newData = []
    this.allMedicineInstructions = this.allMedicineInstructionsOg;

    // var unique = this.allMedicineInstructions.filter((v, i, a) => a.indexOf(v) === i);
    // this.allMedicineInstructions=unique

    this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj.name.toLowerCase() == o.name.toLowerCase())) {
        unique.push(o);
      }
      return unique;
    }, []);
  }


  onDropdownSelectLanguage(key:any, value:any) {
    // if (key == 'language') {
    //   this.selectedLanguage = value
    // }



    let lang = "";

    switch (value) {
      case 'English':
        lang = "en"
        break;

      case 'Hindi':
        lang = "hi"
        break;

      case 'Marathi':
        lang = "mr"
        break;

      case 'Kannada':
        lang = "kn"
        break;

      default:
        break;
    }

    this.selectedLanguage = { name: value, language: lang };
    console.log("lang", this.selectedLanguage)

  }

  onDropdownSelectTemplate(key:any, value:any) {
    if (key == 'item') {
      this.templateName = value

      localStorage.setItem("doctorsTemplate", value);
    }
    console.log("template", this.templateName)
  }
  onDropdownSelectFollowUpTypes(key:any, value:any) {
    if (key == "item") {
      this.followupType = value
      console.log("data:", this.followupType)
    }


  }
  closeSearch() {
    this.searchString = ''
    this.allComplaints = this.allComplaintsOrg;
    this.isAddButton = false
  }

  clearFavouriteText() {
    this.selectedPreviousFavMed = "";
    setTimeout(() => {
      this.searchFavourites.nativeElement.focus();
    }, 300);
  }

  closeSearchOnExamination() {
    this.searchString = ''
    this.allOnExamination = this.allOnExaminationOg;
    this.isAddButton = false
  }
  closeSearchDiagnosis() {
    this.searchString = ''
    this.allDignosis = this.allDignosisOg;
    this.isAddButton = false
  }
  closeSearchDietaryAdvice() {
    this.searchStringDietaryAdvice = ''
    this.allDietaryAdvices = this.allDietaryAdvicesOg;
    this.isAddButton = false
  }
  closeSearchGeneralInstructions() {
    this.searchStringGeneralInstructions = ''
    this.allGeneralInstructions = this.allGeneralInstructionsOg;
    this.isAddButton = false
  }

  // selectModalFieldValue = (key, item) => {
  //   if (key == 'selectFieldsArray') {
  //     var data = item;
  //     var arr = data.filter(field => { if (field.selected) { return field } });
  //     this.printingFields = arr.map(field => { return field.value });

  //   }
  //   else {
  //     var arr = this.selectFieldsArray.filter(field => { if (field.selected) { return field } });
  //     this.printingFields = arr.map(field => { return field.value });
  //   }



  // }

  onClickViewPrescription = () => {
    if (this.pdfUrlForViewPrescription) {


      this.prescriptionPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlForViewPrescription);
      this.prescriptionreportsURL = this.pdfUrlForViewPrescription;
      this.openDialog(this.viewPrescriptionModal, 'viewPrescriptionModal');
      // this.isViewRecipt = true;
    }
  }


  selectDiagnosisFavModal = (diagnosis:any) => {
    console.log(diagnosis);
    this.selectedPreviousFavMed = diagnosis.trim();
    setTimeout(() => {
      this.searchFavourites.nativeElement.focus();
    }, 500);
  }
  gotoMilstone(){
    this.dialog.closeAll();
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId,openMilstone:true, isSpecialCase: true  } })
  }
  gotoVaccination = () => {
    this.dialog.closeAll();
    localStorage.setItem('patientDataForVitals', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId, isSpecialCase: true } });
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Immunization);
      console.log("Runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

    }, 500);
  }
  getVaccData(element:any) {
    this.selectedVaccination.push(element)
    console.log("selectFollowUpMessage123 may26", this.selectedVaccination)

  }
  selectedRowIndex = -1;
  selectedRows(item:any) {
    this.selectedRowIndex = item.id;

  }
  getLangTextBoxData() {

    if (this.backToEdit) {
      var followupMessage:any = localStorage.getItem("followUpMessage") ? localStorage.getItem("followUpMessage") : ""
      setTimeout(() => {
        this.langFollowupMessage.appendTexttoBox(followupMessage)
      }, 200)
      this.followupType = localStorage.getItem("selectApptfollowupType") ? localStorage.getItem("selectApptfollowupType") : ""
      this.templateName = localStorage.getItem("selectDoctorsTemplate") ? localStorage.getItem("selectDoctorsTemplate") : this.templateName
      const doctorsLanguageData = localStorage.getItem("doctorsLanguage");
      this.selectedLanguage = doctorsLanguageData ? JSON.parse(doctorsLanguageData) : this.selectedLanguage;
      
      var followUpdate = localStorage.getItem("followupDate") ? localStorage.getItem("followupDate") : this.followupDateNew

      this.followupDateNew = new Date(followUpdate)
    }
    if (this.vaccinationFlag) {
      var data = this.langFollowupMessage.getValue();
      setTimeout(() => {
        this.langFollowupMessage.appendTexttoBox(data)
      }, 1000)

      console.log("apr4", data)
    }
  }
  savePendingVaccination() {
    console.log("Dec7", this.selectedVaccination)
      if (!this.vacDate) {
        this.messageService.add({ severity: 'custom', detail: 'Please Select Date' });
        return; 
    }
    this.closeDialog();
    var mastervaccId: any = []
    this.vaccinationFlag = true
    var vaccinationName: any = ""
    var data = this.langFollowupMessage.getValue();
    console.log("apr4", data)
    if (this.vacDate != undefined) {
      var vaccDate: any = this.reverseDate(this.vacDate);
      vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
      this.langFollowupMessage.appendTexttoBox(vaccDate + " " + "-" + " ")
      this.selectedVaccination.map((ins:any) => {
        if (ins.name) {
          mastervaccId.push(ins.id)
          this.langFollowupMessage.appendTexttoBox(' ' + " " + ins.name + "");

        }
      })

      this.getLangTextBoxData();
      setTimeout(() => {

        this.openDialog(this.followup_modal, 'followup_modal')
      }, 500)

    }

    else {

    }
    var nextVaccDate: any = this.reverseDate(this.vacDate);
    var params = {
      nextVaccinationDate: moment(new Date(this.parseDate(nextVaccDate))).format("YYYY-MM-DD"),
      patientid: this.patientId,
      masterVaccId: mastervaccId
    }

    this.patientService.updateVaccinationRecord(params).subscribe((response:any) => {

      if (response) {
        console.log("updatevacc", response)
      }

    })


  }
  onDateChangeForVacc(event:any) {
    this.vacDate = event.value
  }
  onDateChange(event:any) {
    var followupDate: any = this.reverseDate(event.value);
    followupDate = moment(new Date(this.parseDate(followupDate))).format("MM/DD/YYYY");
    localStorage.setItem("followupDate", followupDate)

  }

  openPanel(isOpen: boolean) {
    if (isOpen) {
      if(this.printRequisition){
        this.selectFieldsArray = 
        [...selectFields, { name: "Print Requisition", selected: true, value: "showRequisition" }];
      }else{
        this.selectFieldsArray = 
        [...selectFields]
      }
      this.printingFields = this.selectFieldsArray
        .filter((item:any) => item.selected)
        .map((item:any) => item.value);
  
     
    }
  }
  selectModalVitalFieldValue = (item:any) => {


    let selectedFieldsArray = selectedVitalsFields
    let selectedVitals: any = []

    selectedFieldsArray.map((ins:any) => {
      item.map((itr:any) => {
        if (ins.value == itr) {
          ins.selected = true
          selectedVitals.push(ins)
        }
      })
    })
    var data = selectedVitals;

    this.selectFieldsArray = this.printRequisition 
    ? [...selectFields, { name: "Print Requisition", selected: true, value: "showRequisition" }]
    : [...selectFields];
    console.log(" - Updated selectFieldsArray:", this.selectFieldsArray);

    var arr = data.filter((field:any) => { if (field.selected) { return field } });
    this.printingVitals = arr.map((field:any) => { return field.value });

    this.selectFieldsArray = this.printRequisition 
    ? [...selectFields, { name: "Print Requisition", selected: true, value: "showRequisition" }]
    : [...selectFields];
  }
  selectModalFieldValue(selectedValues: string[]) {
    // Update selection states
    this.selectFieldsArray.forEach((item: any) => {
      item.selected = selectedValues.includes(item.value);
    });

    // Update the selected values array (including Print Requisition if needed)
    this.printingFields = this.selectFieldsArray
      .filter((item:any) => item.selected)
      .map((item:any) => item.value);
      console.log(" - Updated selectFieldsArray:", this.selectFieldsArray);
    console.log("Updated printingFields:", this.printingFields);
  }
  popularMedicineDelete(element:any) {
    this.currentPopularMedObj = element

  }
  deletePopularMedicine() {

    let postData = {
      id: this.currentPopularMedObj.MedGroupDrug_id

    }
    this.patientService.deletePopularDrugId(postData).subscribe((response:any) => {
      if (response) {
        console.log(response)

        this.getMedicinesAsPerDiagnosis();
      }

    })

  }
  getspecialVaccination(event:any, item:any) {
    console.log("element", event)
    if (event.checked == true) {
      var name = ""
      if (item.name.includes(',')) {
        name = item.name
      }
      else {
        name = item.name + " ,"
      }

      this.selectedSpecialVaccination.push(name)
      //  console.log("ele1", this.selectedSpecialVaccination.join(" "))
    }
    if (event.checked == false) {

      var itemList = this.selectedSpecialVaccination.indexOf(item.name);
      this.selectedSpecialVaccination.splice(itemList, 1);
      console.log("ele2", this.selectedSpecialVaccination)
    }
    this.selectedSpecialVaccination = this.selectedSpecialVaccination.reduce((unique:any, o:any) => {
      if (!unique.some((obj:any) => obj == o)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }

  getAllRequisitions(){
    var postData = {
      patient_id: this.patientId

    }
    this.patientService.getAllRequisitions(postData).subscribe((response:any) => {

      if (response) {
        console.log("Feb12",response)
        const today = new Date().toISOString().split('T')[0]; // '2025-02-12'

// Filter data where createdAt matches today's date
const todaysRequisitions = response.requisition.filter((item:any) => item.createdAt.startsWith(today));


       

// Extract the tests into a separate array
const todaysTests = todaysRequisitions.flatMap((item:any) => item.tests.split('|').map((test:any) => test.trim()));

console.log("Today's Requisitions:", todaysRequisitions);
console.log("Today's Tests:", todaysTests);
this.labTests=todaysTests

      }
    })
  }

}



