import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-progress-card',
  templateUrl: './appointment-progress-card.component.html',
  styleUrls: ['./appointment-progress-card.component.css']
})
export class AppointmentProgressCardComponent implements OnInit {

  @Input() appointmentData: any;
  @Input () isPdfUrl:any
 
  @Input() i: any;
  @Input() isAppointmentEnabled: any;
  // @Output() openBillReceipt;
  @Output() openBillReceipt = new EventEmitter<object>();
  @Output() onClickAppointmentStatus = new EventEmitter();
  @Output() onCancelAppointment = new EventEmitter();
  @Output() onClickPatientInfo = new EventEmitter();
  @Output() onClickDoneAppointment = new EventEmitter();
  isReceipt=false
   

  ngOnInit(): void {
    console.log('IS APPOINTMENT ENABLED', this.isAppointmentEnabled)
   console.log("march31",this.appointmentData)
   console.log("NOVVV3",this.isPdfUrl)
 

  
   
    
        
   
  }

  onPressBillReceipt() {
    this.openBillReceipt.emit(this.appointmentData);
  }

  onPressAppointmentStatus() {
    this.onClickAppointmentStatus.emit(this.appointmentData)
  }

  onPressCancelAppointment() {
    this.onCancelAppointment.emit(this.appointmentData);
  }

  onPressPatientInfo() {
    this.onClickPatientInfo.emit(this.appointmentData);
  }
  onPressDoneAppointment() {
    this.onClickDoneAppointment.emit(this.appointmentData)
  }



}
