import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Highcharts } from '../highcharts-setup'; 
import { Chart } from 'angular-highcharts';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { HeightGraphComponent } from '../height-graph/height-graph.component';
import { HcGraphComponent } from '../hc-graph/hc-graph.component';
import { WeightGraphComponent } from '../weight-graph/weight-graph.component';
import { TargetHeightGraphComponent } from '../target-height-graph/target-height-graph.component';
import { BmiGraphComponent } from '../bmi-graph/bmi-graph.component';
import { BpGraphComponent } from '../bp-graph/bp-graph.component';

@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.css']
})
export class GraphContainerComponent implements OnInit {
  @ViewChild("weightGraph") weightGraph!:GraphContainerComponent;
  @ViewChild("appWeighGraph") appWeighGraph!:WeightGraphComponent;
  @ViewChild("appHeighGraph") appHeighGraph!:HeightGraphComponent;
  @ViewChild("appHCGraph") appHCGraph!:HcGraphComponent;
  @ViewChild("appWeighGraph1") appWeighGraph1!:WeightGraphComponent;
  @ViewChild("appHeighGraph1") appHeighGraph1!:HeightGraphComponent;
  @ViewChild("appHCGraph1") appHCGraph1!:HcGraphComponent;
  @ViewChild("heightGraph") heightGraph!:HeightGraphComponent;
  @ViewChild("hcGraph") hcGraph!:HcGraphComponent;
  @ViewChild("targetHeightGraph") targetHeightGraph!:TargetHeightGraphComponent;
  @ViewChild("BMIGraph") BMIGraph!:BmiGraphComponent;
  @ViewChild("BPGraph") BPGraph!:BpGraphComponent;

  @Input() graphName!: '';

  chart!: Chart;
  showWeightGraph = false;
  showHeightGraph = false;
  showHcGraph = false;
  showTargetHeightGraph = false;
  showBMIGraph = false;
  showBPGraph = false;
  width = window.innerWidth - 100;
  height = window.innerHeight - 70;
  patient: any = { gender: 'Male' }
  showBMI = false;
  isAdult = false;
  show4Graphs = true;
  graphArr = ['Upto Today', 'PreTerm'];
  selectedWeightWeeks = 'Upto Today';
  selectedHeightWeeks = 'Upto Today';
  selectedHCWeeks = 'Upto Today';
  weightPretermChecked = false;
  heightPretermChecked = false;
  hcPretermChecked = false;
  isPreTerm = false;

  hasHeightData = false;
  hasWeightData = false;
  hasHcData = false;
  hasBpData = false

  constructor(public dialog: MatDialog,) {

  }

  nullEmptyCheck=(vital:any)=>{
    if(vital != "" && vital != 'null' && vital != 'null' && vital !='0'){
      return true
    }else{
      return false;
    }
  }

  hideShowGraphAsPerData = () => {
    try {
      if (this.patient.visits.length) {
        this.patient.visits.map((visit:any) => {

          if(this.nullEmptyCheck(visit.weight)){
            this.hasWeightData = true
          }

          if(this.nullEmptyCheck(visit.height)){
            this.hasHeightData = true
          }

          if(this.nullEmptyCheck(visit.hc)){
            this.hasHcData = true
          }
          if(this.nullEmptyCheck(visit.bpSys) || this.nullEmptyCheck(visit.bpDia)){
            this.hasBpData = true
          }

        })
      }
    } catch (e) {
      console.error('Error', e)
    }

  }

  ngOnInit() {
    this.patient = JSON.parse(localStorage.getItem('patientData')?? 'null');
    this.hideShowGraphAsPerData();

    console.log("patient data", this.patient)

    if (Number(this.patient.age.years) > 2) {
      this.showBMI = true;
    }

    if (Number(this.patient.age.years) >= 18) {
      this.isAdult = true;
      this.show4Graphs = false;
    }


    const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
    const actualDate = moment(this.patient.DOB, PATIENT_DOB_FORMAT); // sept 18 2018
    const expectedDate = moment(this.patient.eDOB, PATIENT_DOB_FORMAT);// oct 15 2018
    const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
    const diffWeeks = actualDate.diff(refDate, "days"); // 253

    var weeks = this.calculateWeek(new Date(this.patient.DOB).getTime(), new Date(this.patient.eDOB).getTime());
    if (weeks < 17 && weeks >= 13) {
      this.isPreTerm = true;
    } else if (weeks < 13 && weeks > 2) {
      this.isPreTerm = true;
    }
    else {
      this.isPreTerm = false;
    }

    if (this.graphName != '') {

      if (this.graphName == 'Weight') {
        this.setShowWeightGraph();
      } else if (this.graphName == 'Height') {
        this.setShowHeightGraph();
      }
      else if (this.graphName == 'HC') {
        this.setShowHcGraph();
      }
      else if (this.graphName == 'BP (Dia)') {
        this.setShowBPGraph();
      }
      else if (this.graphName == 'BP (Sys)') {
        this.setShowBPGraph();
      }
    }
  }


  calculateWeek(d1:any, d2:any) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  onChange(value: any, isFrom: any) {
    console.log('Current Graph:', this.appWeighGraph);

    const graphValue = value === 'Upto Today' ? null : 40;

    if (isFrom === 'Weight') {
      this.appWeighGraph.getGraphs(graphValue);
      this.appWeighGraph1.getGraphs(graphValue);
    } else if (isFrom === 'Height') {
      this.appHeighGraph.getGraphs(graphValue);
      this.appHeighGraph1.getGraphs(graphValue);
    } else {
      this.appHCGraph.getGraphs(graphValue);
      this.appHCGraph1.getGraphs(graphValue);
    }
  }


  onChange32(isFrom:any) {

    if (isFrom == 'Weight') {
      this.weightPretermChecked = !this.weightPretermChecked;
      this.appWeighGraph.getGraphs(this.selectedWeightWeeks == 'Upto Today' ? null : this.weightPretermChecked ? 33 : 40);
      this.appWeighGraph1.getGraphs(this.selectedWeightWeeks == 'Upto Today' ? null : this.weightPretermChecked ? 33 : 40);
    } else if (isFrom == 'Height') {
      this.heightPretermChecked = !this.heightPretermChecked;
      this.appHeighGraph.getGraphs(this.selectedHeightWeeks == 'Upto Today' ? null : this.heightPretermChecked ? 33 : 40);
      this.appHeighGraph1.getGraphs(this.selectedWeightWeeks == 'Upto Today' ? null : this.weightPretermChecked ? 33 : 40);
    }
    else {
      this.hcPretermChecked = !this.hcPretermChecked;
      this.appHCGraph.getGraphs(this.selectedHCWeeks == 'Upto Today' ? null : this.hcPretermChecked ? 33 : 40);
      this.appHCGraph1.getGraphs(this.selectedWeightWeeks == 'Upto Today' ? null : this.weightPretermChecked ? 33 : 40);
    }


  }

  addPoint() {
    if (this.chart) {
      this.chart.addPoint(Math.floor(Math.random() * 10));
    } else {
      alert('init chart, first!');
    }
  }

  toggle4Graphs(flag:any) {
    this.show4Graphs = flag;
  }

  setShowWeightGraph() {
    setTimeout(() => {
      this.openDialog(this.weightGraph, 'weightGraph');
    }, 300);
    this.showWeightGraph = true;
  }

  setShowHeightGraph() {
    setTimeout(() => {
      this.openDialog(this.heightGraph, 'heightGraph');
    }, 300);
    this.showHeightGraph = true;
  }

  setShowHcGraph() {
    setTimeout(() => {
      this.openDialog(this.hcGraph, 'hcGraph');
    }, 300);
    this.showHcGraph = true;
  }

  setShowTargetHeightGraph() {
    this.openDialog(this.targetHeightGraph, 'targetHeightGraph');

    this.showTargetHeightGraph = true;
  }

  setShowBMIGraph() {
    this.openDialog(this.BMIGraph, 'BMIGraph');

    this.showBMIGraph = true;
  }

  setShowBPGraph() {
    setTimeout(() => {
      this.openDialog(this.BPGraph, 'BPGraph');
    }, 300);

    this.showBPGraph = true;
  }

  closeDialog() {
    this.selectedWeightWeeks = 'Upto Today';
    this.selectedHeightWeeks = 'Upto Today';
    this.selectedHCWeeks = 'Upto Today';
    this.weightPretermChecked = false;
    this.heightPretermChecked = false;
    this.hcPretermChecked = false;
    this.dialog.closeAll()
  }
  // {
  //   "chart": { "type": "line", "marginRight": 10, "backgroundColor": "#CCFFFF", "spacingLeft": 0, "spacingTop": 0, "spacingRight": 0, "spacingBottom": 0, "plotBorderColor": "#C0C0C0", "plotBorderWidth": 1 }, "tooltip": {
  //     "crosshairs": { "color": "red", "dashStyle": "solid" },
  //     "formatter": function formatter() { if (this.series.name == 'Current') { return 'Age: <b>' + '<br/>' + '</b>Percentile: <b>' + '<br/>' + '</b>' + ': <b>' + this.y + '</b>' + '<br/>Date: <b>' + '</b>'; } else { return null; } }, "positioner": function positioner() { return { x: 80, y: 60 }; },
  //     "shadow": false, "borderWidth": 0, "backgroundColor": "transparent"
  //   }, "credits": { "enabled": false }, "title": { "text": "" }, "xAxis": [{
  //     "plotLines": [{ "color": "#0000ff", "width": 2, "value": 40 }, { "color": "#0000ff", "width": 2, "value": 300 }],
  //     "gridLineWidth": 1, "gridLineColor": "lightgray", "tickInterval": 1, "minorTickInterval": 0.142, "min": 40, "opposite": true, "title": { "text": "Gestational Age / Weeks", "style": { "fontSize": "15px", "fontWeight": "bold" } }, "labels": { "style": { "color": "#555555", "fontSize": "15px", "enabled": true } }
  //   },
  //   { "title": { "text": "Age", "style": { "fontSize": "15px", "fontWeight": "bold" } }, "opposite": false, "categories": ["", "1w", "2w", "3w", "4w", "1m5d", "1m12d", "1m19d", "1m26d", "2m3d", "2m10d", "2m17d", "2m24d", "3m1d", "3m8d", "3m15d", "3m22d", "3m29d", "4m6d", "4m13d", "4m20d", "4m27d", "5m4d", "5m11d", "5m18d", "5m25d", "6m2d", "6m9d", "6m16d", "6m23d", "7m", "7m7d", "7m14d", "7m21d", "7m28d", "8m5d", "8m12d", "8m19d", "8m26d", "9m3d", "9m10d", "9m17d", "9m24d", "10m1d", "10m8d", "10m15d", "10m22d", "10m29d", "11m6d", "11m13d", "11m20d", "11m27d", "12m4d", "1y", "1y", "1y", "1y", "1y1m", "1y1m", "1y1m", "1y1m", "1y2m"], "min": 0, "max": 17 }],
  //   "plotOptions": { "series": { "states": { "hover": { "enabled": false }, "inactive": { "opacity": 1 } }, "lineWidth": 1 } }, "yAxis": {
  //     "min": 0, "gridLineWidth": 1, "minorTickInterval": "auto", "gridLineColor": "#C5EEFA", "title": { "text": "Weight (Kg.)", "style": { "fontSize": "15px", "fontWeight": "bold" } }, "labels": {
  //       "color": "#555555", "fontSize": "15px", "enabled": true, "step": 1,
  //       "formatter": function formatter() { return this.value; }
  //     }
  //   }, "legend": { "enabled": true, "margin": 0, "itemMarginTop": 6, "itemMarginBottom": 8, "itemStyle": { "fontSize": "12px" } }, "navigation": { "buttonOptions": { "enabled": false } }, "series": [{ "marker": { "enabled": false }, "name": "3rd", "color": "red", "data": [[27, 0.45], [28, 0.57], [29, 0.7], [30, 0.85], [31, 1], [32, 1.17], [33, 1.34], [34, 1.51], [35, 1.7], [36, 1.88], [37, 2.07], [38, 2.26], [39, 2.45], [40, 2.64], [44, 3.4], [48, 4.4], [53, 5.1], [57, 5.6], [61, 6.1]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] },
  //   {
  //     "marker": { "enabled": false }, "name": "10th", "color": "blue", "data": [[27, 0.51], [28, 0.64], [29, 0.79], [30, 0.94], [31, 1.11], [32, 1.29], [33, 1.47], [34, 1.66], [35, 1.86], [36, 2.06], [37, 2.26], [38, 2.46], [39, 2.67], [40, 2.87], [44, 3.8], [48, 4.7], [53, 5.5], [57, 6], [61, 6.5]],
  //     "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976]
  //   },
  //   { "marker": { "enabled": false }, "name": "25th", "color": "black", "data": [[27, 0.58], [28, 0.73], [29, 0.88], [30, 1.05], [31, 1.23], [32, 1.43], [33, 1.62], [34, 1.83], [35, 2.04], [36, 2.25], [37, 2.47], [38, 2.69], [39, 2.91], [40, 3.12], [44, 4.1], [48, 5.1], [53, 5.9], [57, 6.5], [61, 7]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] },
  //   { "marker": { "enabled": false }, "name": "50th", "color": "black", "data": [[27, 0.67], [28, 0.83], [29, 1], [30, 1.19], [31, 1.39], [32, 1.6], [33, 1.81], [34, 2.04], [35, 2.26], [36, 2.5], [37, 2.73], [38, 2.96], [39, 3.2], [40, 3.43], [44, 4.5], [48, 5.6], [53, 6.4], [57, 7], [61, 7.5]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] },
  //   { "marker": { "enabled": false }, "name": "75th", "color": "black", "data": [[27, 0.77], [28, 0.95], [29, 1.14], [30, 1.34], [31, 1.56], [32, 1.79], [33, 2.02], [34, 2.26], [35, 2.51], [36, 2.76], [37, 3.01], [38, 3.27], [39, 3.52], [40, 3.77], [44, 4.9], [48, 6], [53, 6.9], [57, 7.6], [61, 8.1]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] },
  //   { "marker": { "enabled": false }, "name": "90th", "color": "black", "data": [[27, 0.88], [28, 1.07], [29, 1.27], [30, 1.5], [31, 1.73], [32, 1.98], [33, 2.23], [34, 2.49], [35, 2.75], [36, 3.02], [37, 3.29], [38, 3.57], [39, 3.84], [40, 4.11], [44, 5.3], [48, 6.5], [53, 7.4], [57, 8.1], [61, 8.6]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] },
  //   { "marker": { "enabled": false }, "name": "97th", "color": "black", "data": [[27, 0.99], [28, 1.2], [29, 1.43], [30, 1.67], [31, 1.92], [32, 2.18], [33, 2.46], [34, 2.74], [35, 3.02], [36, 3.31], [37, 3.6], [38, 3.89], [39, 4.18], [40, 4.47], [44, 5.7], [48, 7], [53, 7.9], [57, 8.6], [61, 9.2]], "seriesX": [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 48, 53, 57, 61, 66, 70, 74, 79, 83, 87, 92, 96, 100, 105, 109, 113, 118, 122, 126, 131, 135, 139, 144, 148, 152, 157, 161, 165, 170, 174, 178, 183, 187, 191, 196, 200, 204, 209, 213, 217, 222, 226, 230, 235, 239, 243, 248, 252, 256, 261, 265, 269, 274, 278, 282, 287, 291, 295, 300, 326, 352, 378, 404, 430, 456, 482, 508, 534, 560, 586, 612, 638, 664, 690, 716, 742, 768, 794, 820, 846, 872, 898, 924, 950, 976] }, { "marker": { "enabled": true, "symbol": "circle", "radius": 3 }, "color": "green", "name": "Current", "data": [{ "x": 47.14, "y": 5, "extra": { "date": "24/09/2021", "age": "1m 2w 5d ", "percentile": "10-25", "value": "weight" }, "color": "green" }] }]
  // }


  openDialog(content:any, index:any) {

    this.dialog.open(content);
  }

  init() {
    let chart = new Chart();
    chart.addPoint(4);
    this.chart = chart;
    chart.addPoint(5);
    setTimeout(() => {
      chart.addPoint(6);
    }, 2000);

    chart.ref$.subscribe(console.log);
  }

}

