import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'custom-text-input',
  templateUrl: './customtextinput.component.html',
  styleUrls: ['./customtextinput.component.css']
})
export class CustomtextinputComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() public label!: string;
  @Input() placeholder!: string;
  @Input() patientName:any;
  @Input() callBack!: (args: any, args2: any) => void;
  @Input() validNum!:(arg:any)=>any;
  @Input() identifier:any=0;
  @Input() keyboard_type: string = "email";
  @Input() maxLength!: number;
  @Input() isNumeric!: boolean; 
  @Input() disabled: boolean=false;
  @Input()isEditableVisit:boolean=false;
  searchString = "";
  message! : string;

  constructor() { }

  ngOnInit(): void {
  
  }
  onTextChange(value:any)
  {
    this.searchString = value;
    if(this.searchString == '')
    {
      this.message="Textbox is empty !!!";
    }
    
  }
  toggleDisable() {
    this.isEditableVisit = !this.isEditableVisit ;
  }
  numberOnly(event:any): boolean {  
    if(!this.isNumeric) return true;  
    const charCode = (event.which) ? event.which : event.keyCode;  
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !=46 ) {  
      return false;  
    }  
    return true;  
  }  
  inputText(label:any) {
    this.callBack(this.searchString, this.identifier);
   // this.searchString="0"
  }
 

}