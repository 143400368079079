<div (click)="onPress(item)" *ngIf="!styleflag" 
     [ngClass]="{
       'selectedCard': selected && !specialVisitType, 
       'selectedCardSpecial': selected && specialVisitType, 
       'unSelectedCard': !selected
     }" 
     class="searchable-component-style" >
 
  <img  style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
       src="assets/images/newAddButton.svg" class="largerCheckbox"  />
  <div style="font-size: 18px; font-family: 'Mukta', sans-serif;line-height: 16px;">{{item}} </div>
</div>

<!-- <div *ngIf="styleflag" [ngClass]="(selected) ?  'selectedCardActive' :'gridCardUnactive'"
  class="gridCard mr-0px" >
  <input *ngIf="selected != true" type="checkbox" style="margin: 0px 8px 0px 0px;width: 22px; " class="largerCheckbox">
  <img *ngIf="selected==true" style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                          src="assets/images/checkbox_material.svg" class="largerCheckbox"  />
  <div style="font-size: 18px; font-family: 'Mukta', sans-serif; line-height: 16px;">{{item}} </div>
</div> -->
